import axios from 'axios';

const api = axios.create({
    //baseURL: 'http://localhost:3020/'
    //baseURL: 'http://74.208.137.47:3020/api'
    baseURL: 'https://e-xpertum.com.mx/adminfile/'
});

export default api;

