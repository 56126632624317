import React, { Component } from 'react';
import api from '../../../../app/Config/api';
import Parse from 'html-react-parser';
import Skeleton from '@material-ui/lab/Skeleton';

class PLZCFDI extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recorset: [],
            skeleton: true,
        }
    }
    componentDidMount() {
        this.getdata();
    }
    getdata = async () => {
        let pareme = JSON.parse(localStorage.getItem("parameters"));
        let result_ = await api.post("getdetailsdebehaber", pareme);
        let { data } = await result_;
        this.setState({ recorset: data.info, skeleton: false })
    }
    render() {
        return (
            <>
                {
                    this.state.skeleton ? (
                        <div className="card">
                            <Skeleton />
                            <Skeleton animation={false} height={400} />
                            <Skeleton animation="wave" />
                        </div>
                    ) : (
                            <div className="card">
                                <div className="card-body">
                                    {
                                        (this.state.recorset || []).map((o, p) => (
                                            <div key={p}>
                                                {Parse(o.descripcion)}
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>

                        )
                }
            </>
        );
    }
}

export default PLZCFDI;