import React, { Component } from 'react';
class informacion extends Component {
    constructor(props) {
        super(props);
        //console.log(props.data)

        this.state = {
            customer: {},
            customercontact: {},
            customersuscription: {},
            nombre_pais: '',
            nombre_estado: '',
            nombre_municipio: '',
            nombre_localidad: '',
            nombre_servicio: ''
        };
    }
    componentDidMount() {
        console.log(this.props.data.customersuscription)
        var nombrePais = ((this.props.data.customer).PaisData).filter((i) => i.clave === this.props.data.customer.pais_id);
        var nombreEstado = ((this.props.data.customer).EstadoData).filter((i) => i.claves === this.props.data.customer.estado_id);
        var nombreMunicipio = ((this.props.data.customer).MuncipioData).filter((i) => i.clave === this.props.data.customer.municipio_id);
        var nombreLocalidad = ((this.props.data.customer).LocalidadesData).filter((i) => i.id === this.props.data.customer.localidad_id);
        var nombreServicio = ((this.props.data.customersuscription).servicios).filter((i) => i.value === this.props.data.customersuscription.servicio_id);
        this.setState({
            customer: this.props.data.customer,
            nombre_pais: nombrePais[0].nombre,
            nombre_estado: nombreEstado[0].nombre,
            nombre_municipio: nombreMunicipio[0].nombre,
            nombre_localidad: nombreLocalidad[0].nombre,
            nombre_servicio: nombreServicio[0].label,
            customercontact: this.props.data.customercontact,
            customersuscription: this.props.data.customersuscription
        });
    }
    render() {
        return (
            <>
                <div className="card">
                    <div className="card-header bg-transparent border-bottom header-elements-inline">
                        <h6 className="card-title" style={{ color: '#FF7043' }}>Datos fiscales</h6>
                    </div>

                    <div className="card-body">
                        <div className="d-md-flex flex-md-wrap">
                            <table className="table table-lg">
                                <tbody>
                                    <tr>
                                        <td>Razon social / Nombre:</td>
                                        <td colSpan={2}>{this.state.customer.razon_social}</td>
                                        <td>RFC:</td>
                                        <td colSpan={2}>{this.state.customer.rfc}</td>
                                    </tr>
                                    <tr>
                                        <td>Colonia:</td>
                                        <td>{this.state.customer.colonia}</td>
                                        <td>Calle:</td>
                                        <td>{this.state.customer.calle}</td>
                                        <td>Código postal:</td>
                                        <td>{this.state.customer.codigo}</td>
                                    </tr>
                                    <tr>
                                        <td>Número exterior:</td>
                                        <td>{this.state.customer.externo}</td>
                                        <td>Número interno:</td>
                                        <td>{this.state.customer.interno}</td>
                                        <td>País:</td>
                                        <td>{this.state.nombre_pais}</td>
                                    </tr>
                                    <tr>
                                        <td>Estado:</td>
                                        <td>{this.state.nombre_estado}</td>
                                        <td>Municipio:</td>
                                        <td>{this.state.nombre_municipio}</td>
                                        <td>Localidad:</td>
                                        <td>{this.state.nombre_localidad}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="card-header bg-transparent border-bottom header-elements-inline">
                        <h6 className="card-title" style={{ color: '#FF7043' }}>Contacto</h6>
                    </div>
                    <div className="card-body">
                        <div className="d-md-flex flex-md-wrap">
                            <table className="table table-lg">
                                <tbody>
                                    <tr>
                                        <td>Nombre(s):</td>
                                        <td colSpan={3}>{this.state.customercontact.nombre}</td>
                                    </tr>
                                    <tr>
                                        <td>Apellido Paterno:</td>
                                        <td>{this.state.customercontact.ape_paterno}</td>
                                        <td>Apellido Materno:</td>
                                        <td>{this.state.customercontact.ape_materno}</td>
                                    </tr>
                                    <tr>
                                        <td>Teléfono:</td>
                                        <td>{this.state.customercontact.telefono}</td>
                                        <td>Correo:</td>
                                        <td>{this.state.customercontact.correo}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="card-header bg-transparent border-bottom header-elements-inline">
                        <h6 className="card-title" style={{ color: '#FF7043' }}>Contacto</h6>
                    </div>
                    <div className="card-body">
                        <div className="d-md-flex flex-md-wrap">
                            <table className="table table-lg">
                                <tbody>
                                    <tr>
                                        <td>Fecha Inicio:</td>
                                        <td>{this.state.customersuscription.fecha_inicio}</td>
                                        <td>Fecha Fin:</td>
                                        <td>{this.state.customersuscription.fecha_fin}</td>
                                    </tr>
                                    <tr>
                                        <td>Número de usuarios:</td>
                                        <td>{this.state.customersuscription.numero_usuario}</td>
                                        <td>Número de empresas:</td>
                                        <td>{this.state.customersuscription.numero_empresa}</td>
                                    </tr>
                                    <tr>
                                        <td>Servicio:</td>
                                        <td colSpan={2}>{this.state.nombre_servicio}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default informacion;