import React, { Component } from 'react';
import { Tooltip, } from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
import { PacmanLoader } from "react-spinners";
import api from '../../Config/apifiles';
import SalariesWorkPapers from './papel-trabajo/salaries-work-papers';
function getMinMaxTime(points) {
    if (!points) return undefined;
    if (!points.length) return undefined; // not array
    if (points.length < 0) return null;
    var min = points[0].ejercicio;
    var max = points[0].ejercicio;
    for (var index in points) {
        if (points[index].ejercicio > max) max = points[index].ejercicio;
        if (points[index].ejercicio < min) min = points[index].ejercicio;
    }
    return { min: min, max: max };
}
const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
const namemonth = ["", "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
export default class WagesSalaries extends Component {
    constructor(props) {
        super(props)
        let objects = JSON.parse(localStorage.getItem("datacompanyall"));
        this.state = {
            sumisrretenido: 0,
            sumisrdeclarados: 0,
            sumDiferencias: 0,
            sumSubsidio: 0,
            sumSubsidioCfdi: 0,
            total_cfdis_: 0,
            detailsumisrretenido: 0,
            detailsumisrdeclarados: 0,
            detailsumDiferencias: 0,
            detailsumSubsidio: 0,
            detailsumSubsidioCfdi: 0,
            empresa_id: objects.id,
            nombrecompany: objects.nombre,
            cargandospanol: true,
            details: false,
            viewcfdis: false, nameperiodo: '',
            exejercicio: 0,
            viewcfdis: '',
            facturas: [], declaracion: [], filterdeclaracion: []
        }
    }
    componentDidMount() {
        this.getdataaudite();
    }
    getdataaudite = async () => {
        let _config_data_tmp_ = await api.post('getdataadmindata', { empresa_id: this.state.empresa_id });
        let data_ = await api.post('list-data-cfdis-declaraciones', { empresa_id: this.state.empresa_id });
        let { facturas, declaracion } = await data_.data;
        //console.log(facturas);
        //console.log(declaracion)
        let sumisrretenido = 0, sumSubsidioCfdi = 0, sumSubsidiop = 0,
            sumisrdeclarados = 0,
            sumDiferencias = 0;
        if (facturas.length > 0) {
            let exercises = getMinMaxTime(facturas);
            let exercise_add = [];
            let _report_init_ = [];
            for (let init = parseInt(exercises.min); init <= exercises.max; init++) {
                exercise_add.push(init);
                var sumRetIVA = ((facturas).filter((s) => parseInt(s.ejercicio) === init)).reduce(function (total, currentValue) {
                    return total + currentValue.deducciones_total_impuestos_retenidos;
                }, 0);
                var sumSubsidio = ((facturas).filter((s) => parseInt(s.ejercicio) === init)).reduce(function (total, currentValue) {
                    return total + currentValue.subsidio;
                }, 0);

                let result_data_all = (declaracion).filter((s) => parseInt(s.ejercicio) === init);
                // console.log(result_data_all)
                filterToYearSum(result_data_all, (t) => {
                    console.log(t)
                    _report_init_.push({
                        ejercicio: init,
                        import_cfdi: sumRetIVA,
                        sumAcuse: t.isr,
                        diferencia: (sumRetIVA - t.isr),
                        subsidioDeclarado: t.subsi,
                        subsidiocfdi: sumSubsidio,
                        empresa_id: this.state.empresa_id
                    });
                    sumisrdeclarados = sumisrdeclarados + sumRetIVA;
                    sumisrretenido = sumisrretenido + sumRetIVA;
                    sumDiferencias = sumDiferencias + (sumRetIVA - t.isr)
                    sumSubsidioCfdi = sumSubsidioCfdi + sumSubsidio;
                    sumSubsidiop = sumSubsidiop + (t.subsi)

                });

            }
            this.setState({
                cargandospanol: false, datafactura: facturas, datadeclaraciones: declaracion,
                dataexercise: _report_init_, clave: _config_data_tmp_.data,
                sumisrdeclarados: sumisrdeclarados,
                sumisrretenido: sumisrretenido,
                sumDiferencias: sumDiferencias,
                sumSubsidioCfdi: sumSubsidioCfdi,
                sumSubsidio: sumSubsidiop,
                facturas: facturas,
                declaracion: declaracion
            });
        }
        else {
            this.setState({
                cargandospanol: false,
            })
        }
    }
    viewdetailsbody = async (x) => {

        let listExercise = (this.state.facturas).filter((xk) => parseInt(xk.ejercicio) === x.ejercicio);
        let listExerciseDeclaracion = (this.state.declaracion).filter((xk) => parseInt(xk.ejercicio) === x.ejercicio);

        filterToMonthSum(listExerciseDeclaracion, (ds) => {
            console.log(ds)
            let _data_app_ = [];
            let detailsumisrretenido = 0, total_cfdis_ = 0,
                detailsumisrdeclarados = 0,
                detailsumDiferencias = 0,
                detailsumSubsidio = 0,
                detailsumSubsidioCfdi = 0;
            (ds.details).map((d, ki) => {
                let f_p_ = (listExercise).filter((xs) => xs.period === d.period);
                ///console.log(f_p_)
                if (f_p_.length > 0) {
                    d.ejercicio = x.ejercicio;
                    d.deducciones_total_impuestos_retenidos = f_p_[0].deducciones_total_impuestos_retenidos;
                    d.comprobantes = f_p_[0].comprobantes;
                    d.diferencias = parseFloat(f_p_[0].deducciones_total_impuestos_retenidos - d.isr_retenciones_por_asimilados_a_salarios)
                    detailsumisrretenido = detailsumisrretenido + f_p_[0].deducciones_total_impuestos_retenidos;
                    detailsumisrdeclarados = detailsumisrdeclarados + d.isr_retenciones_por_asimilados_a_salarios;
                    detailsumDiferencias = detailsumDiferencias + (parseFloat(f_p_[0].deducciones_total_impuestos_retenidos - d.isr_retenciones_por_asimilados_a_salarios));
                    detailsumSubsidio = detailsumSubsidio + d.totalsubsidio;
                    detailsumSubsidioCfdi = detailsumSubsidioCfdi + f_p_[0].subsidio;
                    total_cfdis_ = total_cfdis_ + f_p_[0].comprobantes;
                    _data_app_.push(d);
                }
            })
            this.setState({

                details: true,
                exejercicio: x.ejercicio,
                detailsdatatoexercise: _data_app_,
                detailsumisrretenido: detailsumisrretenido,
                detailsumisrdeclarados: detailsumisrdeclarados,
                detailsumDiferencias: detailsumDiferencias,
                detailsumSubsidio: detailsumSubsidio,
                detailsumSubsidioCfdi: detailsumSubsidio,
                total_cfdis_: total_cfdis_
            });
        });
    }
    handleViewFilter = async (jk) => {
        /// console.log(jk);
        // console.log(this.state.declaracion)
        let listExerciseDeclaracion = (this.state.declaracion).filter((xk) => parseInt(xk.ejercicio) == jk.ejercicio && xk.period == jk.period);
        ///  console.log(listExerciseDeclaracion)
        this.setState({
            nameperiodo: jk.name_period,
            filterdeclaracion: listExerciseDeclaracion
        });
        window.$("#modalviewdeclaraciones").modal("show");
    }
    handleViewPDFDeclaracion = (t) => {
        let linkpdf = 'https://e-xpertum.com.mx/adminfile/api/displayfilepdf/bd54bba3f9d85ea1426942775518b90b6/ecb868faf29054fedf1ac6cbac5fc63/' + t.directorio;
        var win = window.open(linkpdf, '_blank');
        if (win) {
            win.focus();
        } else {
            alert('Please allow popups for this website');
        }
    }
    handleFilterViewCfdis = async (q) => {
        this.setState({
            viewcfdis: true,
            nameperiodo: q.name_period,
            viewcfdis: <SalariesWorkPapers dt={q} />
        })
    }
    handleViewPDF = () => {
        let linkpdf = 'https://e-xpertum.com.mx/adminfile/api/export-pdf-view-nomina/' + this.state.rfc + '/' + this.state.nombrecompany + '/' + this.state.clave;
        var win = window.open(linkpdf, '_blank');
        if (win) {
            win.focus();
        } else {
            alert('Please allow popups for this website');
        }
    }
    handledownloadfiles = () => {
        window.open("https://e-xpertum.com.mx/invoces/api/export-excel-sat-nomina/" + this.state.empresa_id);
    }
    render() {
        return (
            <>
                {
                    (!this.state.details) ?
                        (<div className="card">
                            <div className="card-header bg-transparent border-bottom header-elements-inline py-0">
                                <h6 className="card-title py-3">
                                    Sueldos y salarios + asimilados a salarios
                                </h6>
                                <div className="header-elements">
                                    <div>
                                        <button type="button" className="btn btn-light" onClick={this.handledownloadfiles} style={{ margin: '0 10px' }}><i className="icon-file-excel mr-2 text-success"></i> Excel</button>
                                        <button type="button" className="btn btn-light" onClick={this.handleViewPDF}><i className="icon-file-pdf mr-2 text-success"></i> PDF</button>
                                    </div>
                                </div>

                            </div>
                            <div className="card-body">
                                {
                                    (this.state.cargandospanol) ? (
                                        <div>
                                            <Skeleton variant="text" />
                                            <div style={{
                                                margin: 40,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}>
                                                <PacmanLoader color={"#ff7043"} loading={this.state.cargandospanol} size={25} margin={2} />
                                            </div>
                                            <Skeleton variant="circle" animation="wave" width={40} height={40} />
                                            <Skeleton variant="rect" animation="wave" style={{ width: "100%" }} height={118} />
                                        </div>
                                    ) : (
                                        <>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th style={{ textAlign: 'center' }}>Ejercicio</th>
                                                        <th style={{ textAlign: 'center' }}>ISR Retenido CFDI</th>
                                                        <th style={{ textAlign: 'center' }}>Subsidio entregado CFDI</th>
                                                        <th style={{ textAlign: 'center' }}>ISR declarados</th>
                                                        <th style={{ textAlign: 'center' }}>Subsidio aplicado</th>
                                                        <th style={{ textAlign: 'center' }}>Diferencias</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        (this.state.dataexercise || []).map((h, l) => (
                                                            <tr key={l}>
                                                                <td>
                                                                    <Tooltip title="Clic para ver comparativo mensual" placement="top" arrow>
                                                                        <button onClick={() => this.viewdetailsbody(h)} type="button" className="btn btn-outline-primary btn-lg" style={{ width: '100%' }}><i className="icon-unlink mr-2"></i>{h.ejercicio}</button>
                                                                    </Tooltip>
                                                                </td>
                                                                <td style={{ textAlign: 'right' }}>{h.import_cfdi.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                                <td style={{ textAlign: 'right' }}>{h.subsidiocfdi.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                                <td style={{ textAlign: 'right' }}>{h.sumAcuse.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                                <td style={{ textAlign: 'right' }}>{h.subsidioDeclarado.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                                <td style={{ textAlign: 'right' }}>
                                                                    {
                                                                        (h.diferencia.toFixed(0) > 0) ?
                                                                            (
                                                                                <span className="text-danger" style={{ fontSize: 12, textAlign: 'right' }}>{h.diferencia.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>
                                                                            ) : <span className="text-success" style={{ fontSize: 12, textAlign: 'right' }}>{h.diferencia.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td style={{ textAlign: 'right' }}></td>
                                                        <td style={{ textAlign: 'right' }}>{this.state.sumisrdeclarados.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                        <td style={{ textAlign: 'right' }}>{this.state.sumSubsidioCfdi.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                        <td style={{ textAlign: 'right' }}>{this.state.sumisrdeclarados.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                        <td style={{ textAlign: 'right' }}>{this.state.sumSubsidio.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                        <td>
                                                            {this.state.sumDiferencias === 0 ? '' : (
                                                                <>
                                                                    {
                                                                        (this.state.sumDiferencias < 0) ? (
                                                                            <a className="btn bg-transparent text-danger-100 border-success ml-1" style={{ textAlign: 'right', width: '100%' }}>
                                                                                <span className='text-success'>{(this.state.sumDiferencias * -1).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>
                                                                            </a>
                                                                        ) : (
                                                                            <a className="btn bg-transparent text-danger-100 border-warning ml-1" style={{ textAlign: 'right', width: '100%' }}>
                                                                                <span className='text-danger'>{this.state.sumDiferencias.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>
                                                                            </a>
                                                                        )
                                                                    }
                                                                </>
                                                            )}
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </>
                                    )
                                }
                            </div>
                        </div>) : (
                            <div className="card">

                                <div className="card-header bg-transparent border-bottom header-elements-inline py-0">
                                    {
                                        (!this.state.viewcfdis) ? (
                                            <>
                                                <h6 className="card-title py-3">Sueldos y salarios + asimilados a salarios de {this.state.exejercicio}</h6>
                                                <div className="header-elements">
                                                    <button type="button" className="btn btn-outline-primary" onClick={() => this.setState({
                                                        details: false,
                                                        viewcfdis: false
                                                    })}><i className="icon-arrow-left16 mr-3 icon-2x  text-succces"></i>Inicio</button>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <h6 className="card-title py-3">Listado de CFDIS de sueldos y salarios de {this.state.nameperiodo + " de " + this.state.exejercicio}</h6>
                                                <div className="header-elements">
                                                    <button type="button" className="btn btn-outline-primary" onClick={() => this.setState({
                                                        details: true,
                                                        viewcfdis: false
                                                    })}><i className="icon-arrow-left16 mr-3 icon-2x  text-succces"></i>Detalle mensual</button>
                                                </div>
                                            </>
                                        )
                                    }

                                </div>
                                <div className="card-body">

                                    {
                                        (!this.state.viewcfdis) ? (
                                            <> <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th style={{ textAlign: 'center' }}>Mes</th>
                                                        <th>Comprobantes</th>
                                                        <th>ISR</th>
                                                        <th>Subsidio entregado</th>
                                                        <th>ISR Cargo</th>
                                                        <th>Subsidio aplicado</th>
                                                        <th>Diferencia</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        (this.state.detailsdatatoexercise || []).map((ex, hy) => (
                                                            <tr key={hy}>
                                                                <td style={{ textAlign: 'center' }}>
                                                                    <Tooltip title="Clic para ver las facturas " placement="top" arrow>
                                                                        <button onClick={() => this.handleFilterViewCfdis(ex)} type="button" className="btn btn-outline-primary btn-lg" style={{ width: '100%' }}><i className="icon-file-xml mr-2"></i>{ex.name_period + " "}{this.state.exejercicio}</button>
                                                                    </Tooltip>
                                                                </td>
                                                                <td style={{ textAlign: 'center' }}>{(ex.comprobantes === 0 ? '' : ex.comprobantes)}</td>
                                                                <td style={{ textAlign: 'right' }}>{parseFloat(ex.deducciones_total_impuestos_retenidos).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                                <td style={{ textAlign: 'right' }}>{parseFloat(ex.subsidio).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                                <td style={{ textAlign: 'right' }}>
                                                                    <Tooltip title="Clic para ver las declaraciones de ISR" placement="top" arrow>
                                                                        <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => this.handleViewFilter(ex)} style={{ width: '100%' }} ><i className="icon-stack mr-2"></i>{ex.isr_retenciones_por_asimilados_a_salarios.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</button>
                                                                    </Tooltip>
                                                                </td>
                                                                <td style={{ textAlign: 'right' }}>{parseFloat(ex.totalsubsidio).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                                <td style={{ textAlign: 'right' }}>

                                                                    {(ex.diferencias === '0.00' ? '' :
                                                                        (ex.diferencias < 0 ? (<span className="text-success">{parseFloat(ex.diferencias).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>) :
                                                                            (<span className="text-danger">{parseFloat(ex.diferencias).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>)
                                                                        ))}
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td style={{ textAlign: 'right' }}>{this.state.detailsumisrretenido.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                        <td style={{ textAlign: 'right' }}>{this.state.detailsumSubsidioCfdi.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                        <td style={{ textAlign: 'right' }}>{this.state.detailsumisrdeclarados.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                        <td style={{ textAlign: 'right' }}>{this.state.detailsumSubsidio.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                        <td style={{ textAlign: 'right' }}>{this.state.detailsumDiferencias === 0 ? '' : (<span className="badge badge-danger d-block">{this.state.detailsumDiferencias.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>)}</td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                                <div id="modalviewdeclaraciones" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                                                    <div className="modal-dialog modal-full">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title">Listado de declaraciones de sueldos y salarios de {this.state.nameperiodo + " de " + this.state.exejercicio}</h5>
                                                                <button type="button" className="close" data-dismiss="modal">×</button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <table className="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>ISR A Cargo</th>
                                                                            <th>Subsidio</th>
                                                                            <th>Número de operación</th>
                                                                            <th>Tipo declaración</th>
                                                                            <th>Fecha de presentación</th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            (this.state.filterdeclaracion || []).map((g, k) => (
                                                                                <tr key={k}>
                                                                                    <td>{(g.isr_retenciones_por_asimilados_a_salarios + g.isr_retenciones_por_salarios).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                                                    <td>{g.subsidio.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                                                    <td>{g.numero_de_operacion}</td>
                                                                                    <td>{g.tipo_declaracion}</td>
                                                                                    <td>{g.fecha_de_presentacion}</td>
                                                                                    <td><button type="button" className="btn btn-light" onClick={() => this.handleViewPDFDeclaracion(g)}><i className="icon-file-pdf text-success"></i></button></td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                {this.state.viewcfdis}
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        )
                }
            </>
        )
    }
}
function filterToMonthSum(data, callback) {
    //console.log(data)
    let import_retenciones_iva = 0;
    let subsidio_ = 0;
    let details_to_period = [];
    months.map((f, d) => {
        //console.log((data).filter((k) => String(k.period) === String(f)), 'xxxxxxxxx', f)
        let resultado_ = (data).filter((k) => String(k.period) === String(f) && (k.isr_retenciones_por_asimilados_a_salarios + k.isr_retenciones_por_salarios) > 0);
        let resultado_subsidio = data.filter((k) => String(k.period) === String(f) && (k.totalsubsidio) > 0);
        let jsonData = filter_last_data_(resultado_, "numero_de_operacion");
        let data_subsidio_ = filter_last_data_(resultado_subsidio, "numero_de_operacion");
        let filter_retenciones_por_mes = jsonData.filter((elem, index, self) => {
            return index === self.findIndex((t) => (
                t.tipo_declaracion === elem.tipo_declaracion
            ));
        });
        if (filter_retenciones_por_mes.length > 1) {
            let filter_retenciones_por_mesx = (filter_retenciones_por_mes).filter((e) => e.tipo_declaracion.includes("Complementaria"))
            //import_retenciones_iva = import_retenciones_iva + filter_retenciones_por_mesx[0].isr_retenciones_por_asimilados_a_salarios + filter_retenciones_por_mesx[0].isr_retenciones_por_salarios;
            import_retenciones_iva = filter_retenciones_por_mesx[0].isr_retenciones_por_asimilados_a_salarios + filter_retenciones_por_mesx[0].isr_retenciones_por_salarios;
            //console.log(import_retenciones_iva, '---------------------more one -----------------------', f);
        }
        else if (filter_retenciones_por_mes.length === 1) {
            //import_retenciones_iva = import_retenciones_iva + filter_retenciones_por_mes[0].isr_retenciones_por_asimilados_a_salarios + filter_retenciones_por_mes[0].isr_retenciones_por_salarios;
            import_retenciones_iva = filter_retenciones_por_mes[0].isr_retenciones_por_asimilados_a_salarios + filter_retenciones_por_mes[0].isr_retenciones_por_salarios;
            //console.log(import_retenciones_iva, '-------------- solo one------------------------------', f);
        }

        if (data_subsidio_.length > 0) {
            subsidio_ = subsidio_ + data_subsidio_[0].totalsubsidio;
        } else
            subsidio_ = subsidio_ + 0;

        details_to_period.push({
            ejercicio: 0,
            period: f,
            comprobantes: 0,
            name_period: (namemonth[parseInt(f)]),
            deducciones_total_impuestos_retenidos: 0,
            isr_retenciones_por_asimilados_a_salarios: import_retenciones_iva,
            subsidio: 0,
            totalsubsidio: subsidio_,
            diferencias: 0
        });
        import_retenciones_iva = 0;
    });
    callback({ isr: import_retenciones_iva, subsi: subsidio_, details: details_to_period });
}
function filterToYearSum(data, callback) {
    //console.log(data)
    let import_retenciones_iva = 0;
    let subsidio_ = 0;
    let details_to_period = [];
    months.map((f, d) => {
        //console.log((data).filter((k) => String(k.period) === String(f)), 'xxxxxxxxx', f)
        let resultado_ = (data).filter((k) => String(k.period) === String(f) && (k.isr_retenciones_por_asimilados_a_salarios + k.isr_retenciones_por_salarios) > 0);
        let resultado_subsidio = data.filter((k) => String(k.period) === String(f) && (k.totalsubsidio) > 0);
        let jsonData = filter_last_data_(resultado_, "numero_de_operacion");
        let data_subsidio_ = filter_last_data_(resultado_subsidio, "numero_de_operacion");
        let filter_retenciones_por_mes = jsonData.filter((elem, index, self) => {
            return index === self.findIndex((t) => (
                t.tipo_declaracion === elem.tipo_declaracion
            ));
        });
        if (filter_retenciones_por_mes.length > 1) {
            let filter_retenciones_por_mesx = (filter_retenciones_por_mes).filter((e) => e.tipo_declaracion.includes("Complementaria"))
            import_retenciones_iva = import_retenciones_iva + filter_retenciones_por_mesx[0].isr_retenciones_por_asimilados_a_salarios + filter_retenciones_por_mesx[0].isr_retenciones_por_salarios;
            //import_retenciones_iva = filter_retenciones_por_mesx[0].isr_retenciones_por_asimilados_a_salarios + filter_retenciones_por_mesx[0].isr_retenciones_por_salarios;
            //console.log(import_retenciones_iva, '---------------------more one -----------------------', f);
        }
        else if (filter_retenciones_por_mes.length === 1) {
            import_retenciones_iva = import_retenciones_iva + filter_retenciones_por_mes[0].isr_retenciones_por_asimilados_a_salarios + filter_retenciones_por_mes[0].isr_retenciones_por_salarios;
            //import_retenciones_iva = filter_retenciones_por_mes[0].isr_retenciones_por_asimilados_a_salarios + filter_retenciones_por_mes[0].isr_retenciones_por_salarios;
            //console.log(import_retenciones_iva, '-------------- solo one------------------------------', f);
        }

        if (data_subsidio_.length > 0) {
            subsidio_ = subsidio_ + data_subsidio_[0].totalsubsidio;
        } else
            subsidio_ = subsidio_ + 0;

        details_to_period.push({
            ejercicio: 0,
            period: f,
            comprobantes: 0,
            name_period: (namemonth[parseInt(f)]),
            deducciones_total_impuestos_retenidos: 0,
            isr_retenciones_por_asimilados_a_salarios: import_retenciones_iva,
            subsidio: 0,
            totalsubsidio: subsidio_,
            diferencias: 0
        });
        //import_retenciones_iva = 0;
    });
    callback({ isr: import_retenciones_iva, subsi: subsidio_, details: details_to_period });
}
function filter_last_data_(MYJSON, prop) {
    return MYJSON.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    })
}