import React, { Component } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import api from '../../../app/Config/api';
import MaskedInput from 'react-text-mask';
const _rfc_pattern_pm = "^(([A-ZÑ&]{3})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\\d])|[3][01])([A-Z0-9]{3}))|" +
    "(([A-ZÑ&]{3})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\\d])|[3][0])([A-Z0-9]{3}))|" +
    "(([A-ZÑ&]{3})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\\d])([A-Z0-9]{3}))|" +
    "(([A-ZÑ&]{3})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$";
// patron del RFC, persona fisica
const _rfc_pattern_pf = "^(([A-ZÑ&]{4})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\\d])|[3][01])([A-Z0-9]{3}))|" +
    "(([A-ZÑ&]{4})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\\d])|[3][0])([A-Z0-9]{3}))|" +
    "(([A-ZÑ&]{4})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\\d])([A-Z0-9]{3}))|" +
    "(([A-ZÑ&]{4})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$";


function ValidaRFC(rfc) {
    if (rfc.match(_rfc_pattern_pm) || rfc.match(_rfc_pattern_pf)) {
        return true;
    } else {
        alert("La estructura de la clave de RFC es incorrecta.");
        return false;
    }
}
class NewProspect extends Component {
    constructor(props) {
        super(props);
        var objec = JSON.parse(localStorage.getItem('id_token'));
        this.state = {
            prospecto: {
                correo: '',
                telefono: '',
                nombre: '',
                apellido_paterno: '',
                apellido_materno: '',
                rfc: '',
                status: '',
                cargo: '',
                estados_id: '',
                municipios_id: '',
                colonia: '',
                calle: '',
                localidades_id: '',
                paises_id: '',
                codigo_postal: '',
                vendedor_id: objec.id,
            },
            EstadoData: [],
            Municipio: [],
            Localidad: [],
            CatalogoGeneral: [],
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
        this.getcatalogoEstados();
        this.getcatalogoGeneral();
    }
    handleEmails = (i) => {
        console.log(i)
        i.persist();
        this.setState(prevState => ({

            prospecto: {
                ...prevState.prospecto,
                correo: i.target.value
            }
        }));
    }
    handleChangeData = (i) => {
        i.persist();
        if (i.target !== undefined) {

            if (i.target.name != "apellidos") {

                if (i.target.name !== null) {
                    this.setState(prevState => ({

                        prospecto: {
                            ...prevState.prospecto,
                            [i.target.name]: i.target.value
                        }
                    }));
                }
            }
            else {
                let result = i.target.value.split(' ');
                if (result.length > 1)
                    this.setState(prevState => ({
                        prospecto: {
                            ...prevState.prospecto,
                            apellido_paterno: result[0],
                            apellido_materno: result[1]
                        }
                    }));
                else
                    this.setState(prevState => ({
                        prospecto: {
                            ...prevState.prospecto,
                            apellido_paterno: result[0],
                            //apellido_materno: result[1]
                        }
                    }));
            }
        }
    }
    getcatalogoEstados = async () => {
        let _estados_ = await api.get('catestados?pais=MEX');
        let { data } = await _estados_;
        this.setState({
            EstadoData: data,
        });
    }
    getcatalogoGeneral = async () => {
        let _catalogos_ = await api.post('listadoestatuscontacto');
        let { data } = await _catalogos_;
        this.setState({
            CatalogoGeneral: data,
        });
    }
    ChangeCity = (e, values) => {
        if (values !== null) {
            this.setState(prevState => ({
                prospecto: {
                    ...prevState.prospecto,
                    estados_id: values.nombre
                }
            }));
            api.get('catmunicipios?estado=' + values.claves).then(response => {
                this.setState({
                    Municipio: response.data
                });
            });
        }
    }
    ChangeMunicipio = (e, values) => {

        if (values !== null) {
            this.setState(prevState => ({
                prospecto: {
                    ...prevState.prospecto,
                    municipios_id: values.nombre
                }
            }));
            api.get('catlocalidades?municipio=' + values.clave).then(response => {
                this.setState({
                    Localidad: response.data
                });
            });
        }
    }
    handleInputChange = (e, values) => {
        this.setState(prevState => ({
            prospecto: {
                ...prevState.prospecto,
                status: values.clave
            }
        }));
    }
    handleSubmit(event) {
        let form = new FormData();
        form.append("prospecto", JSON.stringify(this.state.prospecto))
        api.post("newprospect", form).then((i) => {
            console.log(i)

            this.props.close();
        })
        event.target.reset();
        event.preventDefault();
    }
    render() {
        return (
            <>
                <form onSubmit={this.handleSubmit} autoComplete="off">
                    <div className="modal-body">

                        <div className="row">
                            <div className="col-md-12">
                                <div className="card-body">

                                    <div className="form-group row">
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <input type="text" autoComplete="off" className="form-control" name="correo" onChange={this.handleEmails} placeholder="Correo" />
                                                </div>
                                                <div className="col-md-6">

                                                    <MaskedInput className="form-control" placeholder="Teléfono" autoComplete="off" name="telefono" onChange={this.handleChangeData}
                                                        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                    />                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="form-group">
                                        <input type="text" required className="form-control" autoComplete="off" onChange={this.handleChangeData} name="nombre" placeholder="Nombre(s)" />
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <input type="text" className="form-control" autoComplete="off" onChange={this.handleChangeData} name="apellidos" placeholder="Apellido(s) eje: Morales Hernandez" />
                                                </div>
                                                <div className="col-md-6">
                                                    <input type="text" className="form-control" autoComplete="off" onChange={this.handleChangeData} name="rfc" placeholder="Nombre de la compañia" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <Autocomplete
                                                        id="catalogo"
                                                        options={this.state.CatalogoGeneral}
                                                        autoHighlight
                                                        getOptionLabel={(option) => option.descripcion}
                                                        renderOption={(option) => (
                                                            <div>
                                                                <span> {option.descripcion}</span>
                                                            </div>
                                                        )}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label="Selecciona el estatus" value={this.state.municipios_id} variant="outlined" inputProps={{ ...params.inputProps, autoComplete: 'new-password', }} margin="dense" fullWidth required variant="outlined" />

                                                        )}
                                                        onChange={this.handleInputChange} />
                                                </div>
                                                <div className="col-md-6">
                                                    <input type="text" className="form-control" autoComplete="off" onChange={this.handleChangeData} name="cargo" placeholder="Cargo" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="form-group row">
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <Autocomplete
                                                        id="estado"
                                                        options={this.state.EstadoData}
                                                        autoHighlight
                                                        getOptionLabel={(option) => option.nombre}
                                                        renderOption={(option) => (
                                                            <div>
                                                                <span>{option.claves} - {option.nombre}</span>
                                                            </div>
                                                        )}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label="Selecciona el estado" value={this.state.estados_id} variant="outlined" inputProps={{ ...params.inputProps, autoComplete: 'new-password', }} margin="dense" fullWidth required variant="outlined" />

                                                        )}
                                                        onChange={this.ChangeCity} />
                                                </div>
                                                <div className="col-md-6">
                                                    <Autocomplete
                                                        id="municipio"
                                                        options={this.state.Municipio}
                                                        autoHighlight
                                                        getOptionLabel={(option) => option.nombre}
                                                        renderOption={(option) => (
                                                            <div>
                                                                <span> {option.nombre}</span>
                                                            </div>
                                                        )}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label="Selecciona el municipio/ciudad" value={this.state.municipios_id} variant="outlined" inputProps={{ ...params.inputProps, autoComplete: 'new-password', }} margin="dense" fullWidth required variant="outlined" />

                                                        )}
                                                        onChange={this.ChangeMunicipio} />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-link" data-dismiss="modal">Cancelar</button>
                        <button type="submit" className="btn bg-primary">Guardar Contacto <i className="icon-paperplane ml-2"></i></button>
                    </div>
                </form>
            </>
        );
    }
}

export default NewProspect;