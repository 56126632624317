import React, { Component } from 'react';
import API from '../../../app/Config/api';
import LayoutNomina from '../../../client/Dashboard/nomina_/index';
import { Alert, AlertTitle } from '@material-ui/lab';
import Collapse from '@material-ui/core/Collapse';
import Skeleton from '@material-ui/lab/Skeleton';
function LayoutInformaciOn() {
    return (
        <div className="page-content">
            <div className="content-wrapper">
                <div className="content pt-0">
                    <br></br>
                    <br></br>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="card">
                                <div className="card-body text-center">
                                    <i className="icon-book icon-2x text-success-400 border-success-400 border-3 rounded-round p-3 mb-3 mt-1"></i>
                                    <h5 className="card-title">Jurídico</h5>
                                    <p className="mb-3">
                                        Es una plataforma especializada en resolver cualquier acto jurídico que necesiten nuestros clientes.
                                    </p>
                                    <ul style={{ textAlign: 'left' }}>
                                        <li>Fiscal</li>
                                        <li>Laboral</li>
                                        <li>Corporativo</li>
                                    </ul>
                                    <a href="https://e-xpertum.com/" target="_blank" className="btn bg-success-400">Adquirir</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="card">
                                <div className="card-body text-center">
                                    <i className="icon-lifebuoy icon-2x text-warning-400 border-warning-400 border-3 rounded-round p-3 mb-3 mt-1"></i>
                                    <h5 className="card-title">Auditoría de la contabilidad</h5>
                                    <p className="mb-3">Verifica que la contabilidad electrónica no contenga errores antes de ser enviada a la autoridad.</p>
                                    <a href="https://e-xpertum.com/" target="_blank" className="btn bg-success-400">Adquirir</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="card">
                                <div className="card-body text-center">
                                    <i className="icon-bug2 icon-2x text-warning-400 border-warning-400 border-3 rounded-round p-3 mb-3 mt-1"></i>
                                    <h5 className="card-title">Validador</h5>
                                    <p className="mb-3">Audita a los proveedores y clientes. Disminuye el riesgo de hacer negocios con empresas fraudulentas.</p>
                                    <a href="https://e-xpertum.com/" target="_blank" className="btn bg-success-400">Adquirir</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="card">
                                <div className="card-body text-center">
                                    <i className="icon-folder6 icon-2x text-danger-300 border-danger-300 border-3 rounded-round p-3 mb-3 mt-1"></i>
                                    <h5 className="card-title">Administrador de archivos</h5>
                                    <p className="mb-3">Organiza y administra los documentos importantes para tu empresa o de forma personal.</p>
                                    <a href="https://e-xpertum.com/" className="btn bg-success-400">Adquirir</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
class IndexNomina extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isservice: false,
            days: 0,
            viewalert: false,
            alert: false,
            skeleton: true,
        }
    }
    componentDidMount() {
        var objecturl = (window.location.href).split('#');
        const _data_ = JSON.parse(localStorage.getItem("id_token"));
        API.post('validacionactivacionend', _data_).then((o) => {
            var resultd = (o.data).filter((p) => p.link === objecturl[1]);
            if (resultd.length > 0) {
                if (resultd[0].dia < 15) {
                    this.setState({ skeleton: false, isservice: true, days: resultd[0].dia, viewalert: true, alert: true });
                    setTimeout(() => {
                        this.setState({ isservice: true, days: resultd[0].dia, viewalert: false, alert: false });
                    }, 3000);
                }
                else
                    this.setState({ skeleton: false, isservice: true, days: resultd[0].dia, viewalert: false, alert: false });
            }
        })
    }

    render() {
        return (
            <>
                {
                    this.state.skeleton ? (
                        <div className="page-content">
                            <div className="content-wrapper">
                                <div className="content pt-0">
                                    <br></br>
                                    <br></br>
                                    <div className="card">
                                        <Skeleton animation="wave" variant="text" height={40} />
                                        <Skeleton animation="wave" variant="circle" width={80} height={80} />
                                        <Skeleton animation="wave" variant="text" height={40} />
                                        <div className="row">
                                            <div className="col-lg-4"><Skeleton variant="rect" height={150} /></div>
                                            <div className="col-lg-4"><Skeleton variant="rect" height={150} /></div>
                                            <div className="col-lg-4"><Skeleton variant="rect" height={150} /></div>
                                        </div>
                                        <div style={{ height: 20 }}></div>
                                        <div className="row">
                                            <div className="col-lg-4"><Skeleton variant="rect" height={150} /></div>
                                            <div className="col-lg-4"><Skeleton variant="rect" height={150} /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                            <>
                                {
                                    (parseInt(this.state.days) < 15 && this.state.isservice === true) ? (
                                        <>
                                            {
                                                this.state.alert ? (
                                                    <div className="content-wrapper">
                                                        <div className="page-header border-bottom-0">
                                                            <div className="page-header-content header-elements-md-inline">
                                                                <div className="page-title d-flex">
                                                                    <h4><i className="icon-arrow-left52 mr-2"></i> <span className="font-weight-semibold">Components</span> - Modal Dialogs</h4>
                                                                    <a href="#" className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></a>
                                                                </div>

                                                                <div className="header-elements d-none mb-3 mb-md-0">
                                                                    <div className="d-flex justify-content-center">
                                                                        <a href="#" className="btn btn-link btn-float text-default"><i className="icon-bars-alt"></i><span>Statistics</span></a>
                                                                        <a href="#" className="btn btn-link btn-float text-default"><i className="icon-calculator"></i> <span>Invoices</span></a>
                                                                        <a href="#" className="btn btn-link btn-float text-default"><i className="icon-calendar5"></i> <span>Schedule</span></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="content pt-0">
                                                            <div className="card">

                                                                <Collapse in={this.state.viewalert}>
                                                                    <Alert severity="info">
                                                                        <AlertTitle>Notificación</AlertTitle>
  Estimado: Usuario su servicio está próximo a vencer, favor de ponerse en contacto con el area de ventas, para que siga difrutando del servicio.
                                </Alert>
                                                                </Collapse>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (<LayoutNomina />)
                                            }


                                        </>
                                    ) : (
                                            <>
                                                { (this.state.isservice ? <LayoutNomina /> : <LayoutInformaciOn />)}
                                            </>
                                        )
                                }
                            </>)
                }
            </>
        );
    }
}

export default IndexNomina;