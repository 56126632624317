import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Configuracion from './validador';
///import ConfigNotify from './config-notificacion';
///import ValidadorXP from './details/panelvalidador';
import api from '../../app/Config/api';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));
const ViewValidacion = (i) => {
    var resultado = JSON.parse(localStorage.getItem("datavalidate"));
    resultado.tipo_validacion = i;
    api.post('updatetypeimportvalidate', resultado).then((u) => {
        //console.log(u)
        localStorage.removeItem("datavalidate");
        localStorage.setItem("datavalidate", JSON.stringify(u.data.result))
    });
};
export default function LabTabs() {
    var objec = JSON.parse(localStorage.getItem('id_token'));
    const [perfil] = React.useState(objec.perfil)
    const [value, setValue] = React.useState('1');
    const [result, setValueInfo] = React.useState({});
    const [viewReport, setValueView] = React.useState('');
    const [viewImport, setValueViewImport] = React.useState('');
    const [viewNotifyConfig, setViewNC] = React.useState('');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    /****************************confgiruacion */
    const [objects, setChangeType] = useState('');
    const [checkimportacion, setChangeImportacion] = useState(false);
    const [checkefirma, setChangeEfirma] = useState(false);
    const handleRadioChange = (event) => {
        setChangeType(ViewValidacion(event.target.value))
    }
    const [statuscked, setChangeStatus] = useState(false);
    const [statusckednot, setChangeStatusNot] = useState(false);
    const [nameCompany, setNameCompany] = useState('');

    useEffect(() => {

        var resultado = JSON.parse(localStorage.getItem("datavalidate"));
        setNameCompany(resultado.nombre);

        if (resultado.tipo_validacion === "EFIRMA") {
            setChangeImportacion(false);
            setChangeEfirma(true);
            setChangeType(ViewValidacion("EFIRMA"))
        }
        else {
            setChangeImportacion(true);
            setChangeEfirma(false);
            setChangeType(ViewValidacion("IMPORTACION"))
        }
        api.post("datedownloadblacklist").then((response) => response.data).then((res) => {
            setValueInfo(res[0]);
        }).catch((error) => {
        })
    }, []);

    const validacion_ = (xd) => {

        ViewValidacion(xd.target.value)
        if (xd.target.value === "IMPORTACION") {
            setChangeStatus(true);
            setChangeStatusNot(false);
            setChangeImportacion(true);
            setChangeEfirma(false);
        }
        else if (xd.target.value === "EFIRMA") {
            setChangeStatus(false);
            setChangeStatusNot(true);
            setChangeImportacion(false);
            setChangeEfirma(true);
        }
    }
    const clearviewall = () => {
        setValueView('');
        setValueViewImport('');
    }
    const _setViewNotifyConfig_ = () => {
        setViewNC('');
        ///setViewNC(<ConfigNotify />);
    }
    const { descripcion, fecha } = result;
    return (
        <>
            <div className="content-wrapper">
                <div className="page-header border-bottom-0">
                    <div className="page-header-content header-elements-md-inline">
                        <div className="page-title d-flex">
                            {
                                (perfil === "GOBIERNO") ? (<a href="#/app/ui/69bgob" style={{ color: 'white' }}> <h4><i className="icon-arrow-left52 mr-2"></i> <span className="font-weight-semibold">Listado </span> empresas</h4></a>) :
                                    (<a href="#/app/ui/69b" style={{ color: 'white' }}> <h4><i className="icon-arrow-left52 mr-2"></i> <span className="font-weight-semibold">Listado </span> empresas</h4></a>)
                            }

                            <a href="#" className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></a>
                        </div>
                        <h5 className="title" style={{ paddingLeft: '25%' }}>{nameCompany}</h5>
                    </div>

                </div>
                <div className="content pt-0">
                    <div className="content pt-3">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <ul className="nav nav-tabs nav-justified">
                                            <li className="nav-item"><a href="#basic-justified-tab0" className="nav-link active" data-toggle="tab" onClick={clearviewall}>Mensajes / información / configuración</a></li>
                                            <li className="nav-item"><a href="#basic-justified-tab1" className="nav-link" data-toggle="tab" onClick={() => setValueViewImport(<Configuracion />)}>Importación</a></li>
                                        </ul>
                                        <div className="tab-content">
                                            <div className="tab-pane fade show active " id="basic-justified-tab0">
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <form>
                                                                    <div className="form-group">
                                                                        <label className="d-block">Seleccione la forma de validar los CFDIs</label>
                                                                        <div className="form-check form-check-inline">
                                                                            <label className="form-check-label">
                                                                                <div className="uniform-choice">
                                                                                    <span className={(checkefirma === true && checkimportacion === false) ? "checked" : ""}>
                                                                                        <input type="radio" name="gender2" className="form-input-styled" onClick={validacion_} value="EFIRMA" data-fouc="" />
                                                                                    </span>
                                                                                </div>Validación automática</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <label className="form-check-label">
                                                                                <div className="uniform-choice">
                                                                                    <span className={(checkimportacion === true && checkefirma === false) ? "checked" : ""}>
                                                                                        <input type="radio" name="gender2" className="form-input-styled" onClick={validacion_} value="IMPORTACION" data-fouc="" />
                                                                                    </span>
                                                                                </div>Captura manual</label>
                                                                        </div>
                                                                        {
                                                                            checkefirma ? (
                                                                                <div style={{ marginTop: 10 }}>
                                                                                    <div className="card card-body border-top-danger">
                                                                                        <p className="mb-3 text-muted">Precaución con la e.firma.</p>
                                                                                        <hr className="mt-0" />
                                                                                        <blockquote className="blockquote blockquote-bordered py-2 pl-3 mb-0">
                                                                                            <p className="mb-1" style={{ fontSize: 13 }}>La e.firma es un archivo digital que debe ser confidencial y protegerse contra el mal uso porque identifica al contribuyente al realizar trámites y servicios ante el Gobierno y en especial ante el SAT.</p>
                                                                                            <hr className="mt-0" />
                                                                                            <p className="mb-1" style={{ fontSize: 13 }}>Para evitar el uso de la e.firma, en agosto del 2018 el SAT creo una nueva plataforma de descarga denominada "Web Service SAT" que no requiere de la e.firma sino de un archivo PFX.</p>
                                                                                            <hr className="mt-0" />
                                                                                        </blockquote>
                                                                                    </div>
                                                                                    <div className="card card-body border-top-danger">
                                                                                        <p className="mb-3 text-muted">Conversión de la e.firma en archivo PFX</p>
                                                                                        <hr className="mt-0" />
                                                                                        <blockquote className="blockquote blockquote-bordered py-2 pl-3 mb-0">
                                                                                            <p className="mb-1" style={{ fontSize: 13 }}>El sistema convierte el Certificado (.cer) y la Clave privada (.key) y la contraseña privada en un solo archivo con extensión PFX.</p>
                                                                                            <hr className="mt-0" />
                                                                                            <p className="mb-1" style={{ fontSize: 13 }}>No guardamos el Certificado ni la Clave privada. Los borramos inmediatamente después de generar el archivo PFX.</p>
                                                                                            <hr className="mt-0" />
                                                                                        </blockquote>
                                                                                    </div>

                                                                                </div>

                                                                            ) : null
                                                                        }
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="card">
                                                            <div className="card">
                                                                <div className="card-header">
                                                                    <h6 className="card-title">Actualización de Listas Negras</h6>
                                                                </div>
                                                                <div className="card-body">
                                                                    <table className="table">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>{descripcion}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Fecha en la que se actualizó a e-xpertum: {fecha}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    {
                                                                        checkefirma ? (
                                                                            <div style={{ marginTop: 10 }}>
                                                                                <div className="card card-body border-top-danger">
                                                                                    <p className="mb-3 text-muted">Ventajas de utiizar el archivo PFX en lugar de la e.firma</p>
                                                                                    <hr className="mt-0" />
                                                                                    <blockquote className="blockquote blockquote-bordered py-2 pl-3 mb-0">
                                                                                        <p className="mb-1" style={{ fontSize: 13 }}> Permite descargas masivas. Para administrar los CFDI y la información del contribuyente de manera rutinaria por medio de robots.</p>
                                                                                        <hr className="mt-0" />
                                                                                        <p className="mb-1" style={{ fontSize: 13 }}> Los archivos PFX tienen las siguientes características de seguridad:
                                                                                            <ul className='list'>
                                                                                                <li>La contraseña, el certificado (.cer) y la clave privada (.key) que proporciona el usuario son borradas de inmediato, después de generar un archivo pfx.pfx</li>
                                                                                                <li>El archivo pfx.pfx se almacena en un servidor protegido en un archivo oculto, y para incrementar la seguridad, no se puede exportar.</li>
                                                                                                <li>El archivo pfx,pfx éstá cifrado. No puede ser leído por terceras personas, ni siquiera por el personal que administra el servicio.</li>
                                                                                                <li>El nombre del archivo no permite identificar al contribuyente. Todos se llaman pfx.pfx.</li>
                                                                                                <li>Los archivos pfx.pfx únicamente sirven para autenticar al contribuyente al descargar los CFDIs a través de los servicios de descarga del Portal del SAT. NO funcionan para ningún otro trámite.</li>
                                                                                                <li>Los archivos PFX requieren una contraseña para abrirlos. Esto significa que los archivos PFX protegen contra hackers, aplicaciones maliciosas y terceros sin autorización para acceder a los recursos del sistema.</li>
                                                                                                <li>Los archivos PFX pueden ser inutilizadas por el contribuyente con solo cambiar la contraseña de la e.firma</li>

                                                                                            </ul></p>
                                                                                        <hr className="mt-0" />
                                                                                    </blockquote>
                                                                                </div>
                                                                            </div>

                                                                        ) : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="basic-justified-tab1">
                                                {viewImport}
                                            </div>
                                            <div className="tab-pane fade" id="basic-justified-tab2">
                                                {viewNotifyConfig}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
