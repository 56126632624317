import React, { Component } from 'react';
import axios from 'axios';
import api from '../../../../app/Config/apifiles';
import { withRouter } from "react-router-dom";
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';

import CloseIcon from '@material-ui/icons/Check';


const _rfc_pattern_pm = "^(([A-ZÑ&]{3})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\\d])|[3][01])([A-Z0-9]{3}))|" +
    "(([A-ZÑ&]{3})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\\d])|[3][0])([A-Z0-9]{3}))|" +
    "(([A-ZÑ&]{3})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\\d])([A-Z0-9]{3}))|" +
    "(([A-ZÑ&]{3})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$";
// patron del RFC, persona fisica
const _rfc_pattern_pf = "^(([A-ZÑ&]{4})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\\d])|[3][01])([A-Z0-9]{3}))|" +
    "(([A-ZÑ&]{4})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\\d])|[3][0])([A-Z0-9]{3}))|" +
    "(([A-ZÑ&]{4})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\\d])([A-Z0-9]{3}))|" +
    "(([A-ZÑ&]{4})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$";


function ValidaRFC(rfc) {
    if (rfc.length === 12 || rfc.length === 13) {
        if (rfc.match(_rfc_pattern_pm) || rfc.match(_rfc_pattern_pf)) {
            return true;
        } else {
            alert("La estructura de la clave de RFC es incorrecta.");
            return false;
        }
    } else {
        alert("El rfc debe de cumplir con una estructura de persona fisica o moral.");
        return false;
    }
}

class NewTabajadore extends Component {
    constructor(props) {
        super(props);
        var datacompany = JSON.parse(localStorage.getItem("setdatanomina"));
        this.state = {
            open: false,
            nombres: '',
            apellidos: '',
            rfc: '', curp: '', estadocivil: '', fechanacimiento: '',
            nss: '', puesto: '', actividad: '', escolaridad: '',
            correo: '', beneficiario: '', calle: '', numextrerior: '', codigopostal: '',
            colonia: '', municipio: '', estado: '', genero: '',
            empresa_id: datacompany.id, rfcempresa: datacompany.rfc, namecompany: datacompany.nombre,
            direccion: '', notaria_: '', representante_lega: '', salario: 0
        }
        this.handlesaveData = this.handlesaveData.bind(this);
        this.handlesaveDataCompany = this.handlesaveDataCompany.bind(this);
    }
    componentDidMount() {
        this._get_data_company_();
    }
    _get_data_company_ = async () => {
        let _admin_list_ = await api.post('getdata-company-all', { empresa_id: this.state.empresa_id });
        //let _admin_list_ = await axios.post('http://localhost:3020/api/getdata-company-all', { empresa_id: this.state.empresa_id });
        let info_ = await _admin_list_.data;
        if (info_.length > 0) {
            let _resulta_dt_ = (info_[0]);
            this.setState({
                rfcempresa: _resulta_dt_.rfc,
                namecompany: _resulta_dt_.nombre_company,
                direccion: _resulta_dt_.direccion,
                notaria_: _resulta_dt_.notaria_,
                representante_lega: _resulta_dt_.representante_lega,
                salario: _resulta_dt_.salario,
            });
        }
    }
    handelsetdatavalue = async (t) => {
        this.setState({
            [t.target.name]: t.target.value
        });
    }
    handlesaveData = async (event) => {
        event.preventDefault();
        let _admin_list_ = await api.post('save-trabajador-db', this.state);
        ///let _admin_list_ = await axios.post('http://localhost:3020/api/save-trabajador-db', this.state);
        this.setState({ open: true })
        setTimeout(() => {
            this.props.history.push('/app/ui/adminnominastatus');
        }, 3000);
    }
    handlesaveDataCompany = async (event) => {
        event.preventDefault();
        let _admin_list_ = await axios.post('save-datos_company-nomina', this.state);
        //let _admin_list_ = await axios.post('http://localhost:3020/api/save-datos_company-nomina', this.state);
        window.$("#modallargedatacompany").modal("hide")
    }
    render() {
        return (
            <div className="content-inner">
                <div className="page-header">
                    <div className="page-header-content header-elements-lg-inline">
                        <div className="page-title d-flex">
                            <a href="#/app/ui/adminnominastatus" style={{ color: 'white' }}>    <h4><i className="icon-arrow-left52 mr-2" /> <span className="font-weight-semibold">Listado de trabajadores</span></h4></a>
                            <a href="#" className="header-elements-toggle text-body d-lg-none"><i className="icon-more" /></a>
                        </div>

                        <div className="header-elements d-none mb-3 mb-lg-0">
                            <div className="d-flex justify-content-center">
                                <a href="#" className="btn btn-link btn-float text-body" style={{ visibility: 'hidden' }}><i className="icon-calculator" /> <span>Invoices</span></a>
                                <a href="#" className="btn btn-link btn-float text-body" data-toggle="modal" data-target="#modallargedatacompany"><i className="icon-office" /><span>Empresa</span></a>
                                <a href="#" className="btn btn-link btn-float text-body" style={{ visibility: 'hidden' }}><i className="icon-calendar5" /> <span>Schedule</span></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="content pt-0">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">Datos del trabajador</h5>

                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <form onSubmit={this.handlesaveData}>
                                        <Collapse in={this.state.open}>
                                            <Alert severity="success"
                                                action={
                                                    <IconButton
                                                        aria-label="close"
                                                        color="inherit"
                                                        size="small"
                                                        onClick={() =>
                                                            this.setState({ open: false })
                                                        }
                                                    >
                                                        <CloseIcon fontSize="inherit" />
                                                    </IconButton>
                                                }
                                            >
                                                Los datos se han guardado correctamente, se ha generado el contrato!
                                            </Alert>
                                        </Collapse>
                                        <label>Datos personales</label>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" placeholder="Nombre(s)" value={this.state.nombres} name="nombres" onChange={this.handelsetdatavalue} required />
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" className="form-control" placeholder="Curp" value={this.state.curp} name="curp" onChange={this.handelsetdatavalue} required />
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" placeholder="Apellido(s)" value={this.state.apellidos} name="apellidos" onChange={this.handelsetdatavalue} required />
                                                </div>
                                                <div className="form-group">
                                                    <select className="form-control" value={this.state.estadocivil} name="estadocivil" onChange={this.handelsetdatavalue} required>
                                                        <option>Seleccione el estado civil</option>
                                                        <option>Casado</option>
                                                        <option>Soltero</option>
                                                        <option>Unión libre</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" placeholder="RFC" onBlur={(e) => ValidaRFC(e.target.value)} style={{ textTransform: 'uppercase' }} maxLength={13} autoComplete="off" value={this.state.rfc} name="rfc" onChange={this.handelsetdatavalue} required />
                                                </div>
                                                <div className="form-group">
                                                    <input type="date" className="form-control" placeholder="Fecha de nacimiento" value={this.state.fechanacimiento} name="fechanacimiento" onChange={this.handelsetdatavalue} required />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <select className="form-control" value={this.state.genero} name="genero" onChange={this.handelsetdatavalue} required>
                                                        <option>Seleccione el genero</option>
                                                        <option>MASCULINO</option>
                                                        <option>FEMENINO</option>
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" className="form-control" placeholder="Actividad" value={this.state.actividad} name="actividad" onChange={this.handelsetdatavalue} required />
                                                </div>
                                            </div>
                                            <div className="col-lg-4">

                                                <div className="form-group">
                                                    <input type="text" className="form-control" placeholder="Número de seguridad social" value={this.state.nss} name="nss" onChange={this.handelsetdatavalue} required />
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" className="form-control" placeholder="Escolaridad" value={this.state.escolaridad} name="escolaridad" onChange={this.handelsetdatavalue} required />
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" placeholder="Puesto" value={this.state.puesto} name="puesto" onChange={this.handelsetdatavalue} required />
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" className="form-control" placeholder="Correo electrónico" value={this.state.correo} name="correo" onChange={this.handelsetdatavalue} required />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" placeholder="Beneficiario" value={this.state.beneficiario} name="beneficiario" onChange={this.handelsetdatavalue} required />
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                            </div>
                                            <div className="col-lg-4">
                                            </div>
                                        </div>
                                        <label>Domicilio actual</label>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" placeholder="Calle" value={this.state.calle} name="calle" onChange={this.handelsetdatavalue} required />
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" className="form-control" placeholder="Colonia" value={this.state.colonia} name="colonia" onChange={this.handelsetdatavalue} required />
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" placeholder="Número exterior" value={this.state.numextrerior} name="numextrerior" onChange={this.handelsetdatavalue} required />
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" className="form-control" placeholder="Minicipio" value={this.state.municipio} name="municipio" onChange={this.handelsetdatavalue} required />
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" placeholder="Código postal" value={this.state.codigopostal} name="codigopostal" onChange={this.handelsetdatavalue} required />
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" className="form-control" placeholder="Estado" value={this.state.estado} name="estado" onChange={this.handelsetdatavalue} required />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-right">
                                            <button type="submit" className="btn btn-primary">Enviar <i className="icon-paperplane ml-2" /></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                <div id="modallargedatacompany" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Información de la empresa</h5>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={this.handlesaveDataCompany}>
                                    <label></label>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="form-group">
                                                <input type="text" className="form-control" placeholder="RFC" value={this.state.rfcempresa} required name="rfcempresa" onChange={this.handelsetdatavalue} />
                                            </div>
                                            <div className="form-group">
                                                <input type="text" className="form-control" placeholder="Salario" value={this.state.salario} required name="salario" onChange={this.handelsetdatavalue} />
                                            </div>
                                        </div>
                                        <div className="col-lg-8">
                                            <div className="form-group">
                                                <input type="text" className="form-control" placeholder="Nombre empresa" value={this.state.namecompany} required name="namecompany" onChange={this.handelsetdatavalue} />
                                            </div>
                                            <div className="form-group">
                                                <input type="text" className="form-control" placeholder="Representante legal" value={this.state.representante_lega} required name="representante_lega" onChange={this.handelsetdatavalue} />
                                            </div>
                                        </div>
                                    </div>
                                    <label></label>
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <div className="form-group">
                                                <textarea type="text" className="form-control" placeholder="Datos fiscales" rows={4} value={this.state.direccion} required name="direccion" onChange={this.handelsetdatavalue} />
                                            </div>
                                        </div>
                                        <div className="col-lg-7">
                                            <div className="form-group">
                                                <textarea type="text" className="form-control" placeholder="Datos de la notaria" rows={4} value={this.state.notaria_} required name="notaria_" onChange={this.handelsetdatavalue} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-right">
                                        <button type="submit" className="btn btn-primary">Enviar <i className="icon-paperplane ml-2" /></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default withRouter(NewTabajadore);