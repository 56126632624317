import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Configuracion from './validador';
import ConfigMails from './Mails/index';
///import ValidadorXP from './details/panelvalidador';
import api from '../../app/Config/api';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));
const ViewValidacion = (i) => {
    var resultado = JSON.parse(localStorage.getItem("datavalidate"));
    resultado.tipo_validacion = i;
    api.post('updatetypeimportvalidate', resultado).then((u) => {
        //console.log(u)
        localStorage.removeItem("datavalidate");
        localStorage.setItem("datavalidate", JSON.stringify(u.data.result))
    });
};
export default function LabTabs() {
    var objec = JSON.parse(localStorage.getItem('id_token'));
    const [perfil] = React.useState(objec.perfil)
    const [value, setValue] = React.useState('1');
    const [result, setValueInfo] = React.useState({});
    const [viewReport, setValueView] = React.useState('');
    const [viewImport, setValueViewImport] = React.useState('');
    const [viewNotifyConfig, setViewNC] = React.useState(<ConfigMails />);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    /****************************confgiruacion */
    const [objects, setChangeType] = useState('');
    const [checkimportacion, setChangeImportacion] = useState(false);
    const [checkefirma, setChangeEfirma] = useState(false);
    const handleRadioChange = (event) => {
        setChangeType(ViewValidacion(event.target.value))
    }
    const [statuscked, setChangeStatus] = useState(false);
    const [statusckednot, setChangeStatusNot] = useState(false);
    const [nameCompany, setNameCompany] = useState('');

    useEffect(() => {

        var resultado = JSON.parse(localStorage.getItem("datavalidate"));
        setNameCompany(resultado.nombre);

        if (resultado.tipo_validacion === "EFIRMA") {
            setChangeImportacion(false);
            setChangeEfirma(true);
            setChangeType(ViewValidacion("EFIRMA"))
        }
        else {
            setChangeImportacion(true);
            setChangeEfirma(false);
            setChangeType(ViewValidacion("IMPORTACION"))
        }
        api.post("datedownloadblacklist").then((response) => response.data).then((res) => {
            setValueInfo(res[0]);
        }).catch((error) => {
        })
    }, []);

    const validacion_ = (xd) => {

        ViewValidacion(xd.target.value)
        if (xd.target.value === "IMPORTACION") {
            setChangeStatus(true);
            setChangeStatusNot(false);
            setChangeImportacion(true);
            setChangeEfirma(false);
        }
        else if (xd.target.value === "EFIRMA") {
            setChangeStatus(false);
            setChangeStatusNot(true);
            setChangeImportacion(false);
            setChangeEfirma(true);
        }
    }
    const clearviewall = () => {
        setValueView('');
        setValueViewImport('');
    }
    const _setViewNotifyConfig_ = () => {
        setViewNC('');
        setViewNC(<ConfigMails />);
    }
    const { descripcion, fecha } = result;
    return (
        <>
            <div className="content-wrapper">
                <div className="page-header border-bottom-0">
                    <div className="page-header-content header-elements-md-inline">
                        <div className="page-title d-flex">
                            {
                                (perfil === "GOBIERNO") ? (<a href="#/app/ui/69bgob" style={{ color: 'white' }}> <h4><i className="icon-arrow-left52 mr-2"></i> <span className="font-weight-semibold">Listado </span> empresas</h4></a>) :
                                    (<a href="#/app/ui/69b" style={{ color: 'white' }}> <h4><i className="icon-arrow-left52 mr-2"></i> <span className="font-weight-semibold">Listado </span> empresas</h4></a>)
                            }

                            <a href="#" className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></a>
                        </div>
                        <h5 className="title" style={{ paddingLeft: '25%' }}>{nameCompany}</h5>
                    </div>

                </div>
                <div className="content pt-0">
                    <div className="content pt-3">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <ul className="nav nav-tabs nav-justified">

                                            <li className="nav-item"><a href="#basic-justified-tab0" className="nav-link active" data-toggle="tab" onClick={clearviewall}>Mensajes / información / configuración</a></li>
                                            <li className="nav-item"><a href="#basic-justified-tab1" className="nav-link" data-toggle="tab" onClick={() => setValueViewImport(<Configuracion />)}>Importación</a></li>
                                            <li className="nav-item"><a href="#basic-justified-tab2" className="nav-link" data-toggle="tab" onClick={() => _setViewNotifyConfig_}>Configuración proveedores</a></li>
                                        </ul>
                                        <div className="tab-content">
                                            <div className="tab-pane fade show active " id="basic-justified-tab0">
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <form>
                                                                    <div className="form-group">
                                                                        <label className="d-block">Seleccione la forma de validar los CFDIs</label>
                                                                        <div className="form-check form-check-inline">
                                                                            <label className="form-check-label">
                                                                                <div className="uniform-choice">
                                                                                    <span className={(checkefirma === true && checkimportacion === false) ? "checked" : ""}>
                                                                                        <input type="radio" name="gender2" className="form-input-styled" onClick={validacion_} value="EFIRMA" data-fouc="" />
                                                                                    </span>
                                                                                </div>Validación automática</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <label className="form-check-label">
                                                                                <div className="uniform-choice">
                                                                                    <span className={(checkimportacion === true && checkefirma === false) ? "checked" : ""}>
                                                                                        <input type="radio" name="gender2" className="form-input-styled" onClick={validacion_} value="IMPORTACION" data-fouc="" />
                                                                                    </span>
                                                                                </div>Captura manual</label>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="card">
                                                            <div className="card">
                                                                <div className="card-header">
                                                                    <h6 className="card-title">Actualización de Listas Negras</h6>
                                                                </div>
                                                                <div className="card-body">
                                                                    <table className="table">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>{descripcion}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Fecha en la que se actualizó a e-xpertum: {fecha}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="basic-justified-tab1">
                                                {viewImport}
                                            </div>
                                            <div className="tab-pane fade" id="basic-justified-tab2">
                                                {viewNotifyConfig}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
