import React, { Component } from 'react';
import api from '../../../../app/Config/apifiles';
import { Tooltip, } from "@material-ui/core";
import ReactDatatable from '@ashvin27/react-datatable';
import Skeleton from '@material-ui/lab/Skeleton';

class CuentaContable extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "fecha",
                text: "Fecha",
                className: "fecha",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <div className="text-left">
                            {
                                <p style={{ fontSize: 12 }}>{record.fecha}</p>
                            }</div>
                    );
                }
            },
            {
                key: "rfc",
                text: "RFC",
                className: "rfc",
                align: "left",
                sortable: true, cell: record => {
                    return (
                        <div className="text-left">
                            {
                                <p style={{ fontSize: 12 }}>{record.rfc}</p>
                            }</div>
                    );
                }
            },
            {
                key: "nombre",
                text: "Nombre",
                className: "nombre",
                cell: record => {
                    return (
                        <div className="text-left">
                            {
                                <p style={{ fontSize: 12 }}>{record.nombre}</p>
                            }</div>
                    );
                }
            },
            {
                key: "uuid",
                text: "UUID",
                className: "uuid",
                cell: record => {
                    return (
                        <div className="text-left">
                            {
                                <p style={{ fontSize: 12 }}>{record.uuid}</p>
                            }</div>
                    );
                }
            },
            {
                key: "t",
                text: "Tipo",
                className: "t",
                cell: record => {
                    return (
                        <div className="text-center">
                            {
                                <p className="text-succcess" style={{ fontSize: 14 }}>{record.t}</p>
                            }</div>
                    );
                }
            },
            {
                key: "total",
                text: "Total",
                className: "total",
                align: "center",
                cell: record => {
                    return (
                        <div className="text-right">
                            {
                                <p className="text-danger" style={{ fontSize: 14 }}>{(record.total) === 0 ? '-' : (record.total).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                            }</div>
                    );
                }
            }
        ];
        this.config = {
            key_column: 'account_number',
            page_size: 100,
            length_menu: [100, 200, 500],
            button: {
                excel: true,
                print: true,
                extra: false,
            },
            "language": {
                "length_menu": "Mostrar _MENU_ registros por página",
                "filter": "Buscar en registros ...",
                "info": "Mostrando _START_ a _END_ de _TOTAL_ entradas",
                "pagination": {
                    "first": "Primero",
                    "previous": "Previa",
                    "next": "próximo",
                    "last": "Última"
                }
            }
        }

        this.state = {
            recorset: [],
            skeleton: true,
        }
    }
    componentDidMount() {
        this.getdata();
    }
    getdata = async () => {
        let pareme = JSON.parse(localStorage.getItem("parameterscompany"));
        let result_ = await api.post("cfdi-nocontabilizado-polices", pareme);
        let { data } = await result_;
        this.setState({ recorset: data, skeleton: false });
    }
    render() {
        return (
            <>
                {
                    this.state.skeleton ? (
                        <div className="card">
                            <Skeleton />
                            <Skeleton animation={false} height={400} />
                            <Skeleton animation="wave" />
                        </div>
                    ) : (
                        <ReactDatatable
                            config={this.config}
                            records={(this.state.recorset || [])}
                            columns={this.columns}
                        />
                    )
                }
            </>
        );
    }
}

export default CuentaContable;