import React, { Component } from 'react'
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import MaskedInput from 'react-text-mask'
import api from '../../Config/api';
import './style.css'
class contactoedit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            nombre: '',
            apellido_paterno: '',
            apellido_materno: '',
            telefono: '',
            correo: '',
            proviene: 'Contacto',
            open: false,
            message: ''
        };
    }
    componentDidMount() {
        this.setState(this.props.contacto)
    }
    handleChangeForm = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });
    }
    handleUpdateContact = () => {
        api.post("UpdateContacto", this.state)
            .then((up) => up.data)
            .then((y) => {
                if (y.status === 200) {
                    this.setState({ open: true, message: y.message });
                    setTimeout(() => {
                        this.setState({ open: false })
                    }, 3000);
                }

            })
            .catch((x) => {
                console.log(x)
            });
    }
    render() {
        return (
            <>
                <div className="card">
                    <div className="card-body">
                        <Collapse in={this.state.open}>
                            <Alert> {this.state.message} </Alert>
                        </Collapse>
                        <div autoComplete="off">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group row">
                                        <div className="col-lg-12">
                                            <label>Nombre</label>
                                            <input type="text" className="form-control" value={this.state.nombre} placeholder="Nombre(s)" name="nombre" onChange={this.handleChangeForm} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>Apellido paterno</label>
                                            <input type="text" placeholder="Apellido paterno" value={this.state.apellido_paterno} className="form-control" name="apellido_paterno" id="ape_paterno" onChange={this.handleChangeForm} />
                                        </div>
                                        <div className="col-md-6">
                                            <label>Apellido materno</label>
                                            <input type="text" placeholder="Apellido materno" value={this.state.apellido_materno} className="form-control" name="apellido_materno" id="ape_materno" onChange={this.handleChangeForm} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>Teléfono</label>
                                            <MaskedInput className="form-control" placeholder="Teléfono" value={this.state.telefono}
                                                mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label>Correo electrónico</label>
                                            <input type="text" placeholder="Correo electrónico" value={this.state.correo} className="form-control" name="correo" id="correo" onChange={this.handleChangeForm} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-right">
                                <button type="submit" className="btn btn-primary" onClick={this.handleUpdateContact}>Actualizar<i className="icon-paperplane ml-2"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default contactoedit;