import React, { Component } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import api from '../Config/api';
import NumberFormat from 'react-number-format';

class ServicioPrecio extends Component {
    constructor(props) {
        super(props);

        this.state = {
            servicios: [],
            viewAuditoria: false,
            viewValidador: false,
            viewNomina: false,
            viewAdministrador: false,
            rango: [20, 37],
            listconfig: [],
            tipopersona: '',
            clave: '',
            descripcion: '',
            importe: 0,
            estatusmensaje: false,
            id: 0
        }
        this.handleSubmitConfig = this.handleSubmitConfig.bind();
    }
    componentDidMount() {
        this.listarrayservices();
        this.listModulosAd();
    }
    listarrayservices = async () => {
        let servicios = await api.post("getlistservicioconfig");
        let listServicio = await servicios.data;
        this.setState({ listconfig: listServicio });
    }
    listModulosAd = async () => {
        let servicios = await api.get("listModulo");
        let listServicio = await servicios.data;
        for (let j = 0; j < listServicio.length; j++) {
            listServicio[j].isChecked = false;
        }
        this.setState({ servicios: listServicio });

    }
    handleChange = (event) => {
        if (event.target.name === "1000")
            this.setState({ viewAuditoria: event.target.checked, clave: event.target.name })
        else if (event.target.name === "4000")
            this.setState({ viewValidador: event.target.checked, clave: event.target.name })
        else if (event.target.name === "5000")
            this.setState({ viewNomina: event.target.checked, clave: event.target.name })
        else if (event.target.name === "6000")
            this.setState({ viewAdministrador: event.target.checked, clave: event.target.name })

        let lisservicios = this.state.servicios
        lisservicios.forEach(servicio => {
            if (servicio.parent_id === event.target.name)
                servicio.isChecked = event.target.checked
        })
        this.setState({ servicios: lisservicios })
        //
    };
    /*handleChangeFactura = (event, newValue) => {
        this.setState({ rango: newValue, descripcion: JSON.stringify(newValue) })
    }*/
    handleChangeFactura = (event) => {
        this.setState({ descripcion: (event.target.value) })
    }
    handleChangeText = (y) => {
        console.log(y.target.name)
        console.log(y.target.value)
        this.setState({ [y.target.name]: y.target.value })
    }

    handleSubmitConfig = async (e) => {
        e.preventDefault();
        let objetosend = {
            clave: this.state.clave,
            descripcion: this.state.descripcion,
            importe: (this.state.importe).replace(/,/g, ''),
            tipopersona: this.state.tipopersona
        }
        let result_ = await api.post("saveconfigservicio", objetosend);
        let result_admin = await result_.data;
        if (result_admin.estatus === 200) {
            let resularray = this.state.servicios;
            for (let j = 0; j < resularray.length; j++) {
                resularray[j].isChecked = false;
            }
            this.setState({
                estatusmensaje: true, clave: '',
                descripcion: '',
                importe: 0, servicios: resularray, viewAuditoria: false,
                viewValidador: false,
                viewNomina: false,
                viewAdministrador: false
            });
            this.listarrayservices();
            setTimeout(() => {
                this.setState({ estatusmensaje: false });
            }, 4000);
        }
    }
    handledatadelete = (j) => {
        this.setState({ id: j.id })
        window.$("#modaldelete").modal("show");
    }
    handleAceptarDelete = async () => {
        let obj = await api.post("deletefromserviceconfig", this.state);
        this.listarrayservices();
        window.$("#modaldelete").modal("hide");
    }
    render() {
        return (
            <>
                <div className="content-wrapper">
                    <div className="page-header border-bottom-0">
                        <div className="page-header-content header-elements-md-inline">
                            <div className="page-title d-flex">
                                <h4><i className="icon-arrow-left52 mr-2"></i> <span className="font-weight-semibold">Inicio</span></h4>
                                <a href="#" className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></a>
                            </div>

                            <div className="header-elements d-none mb-3 mb-md-0">
                                <div className="d-flex justify-content-center">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="content pt-0">
                        <div className="card">
                            <div className="card-body">
                                <ul className="nav nav-tabs nav-tabs-top nav-justified">
                                    <li className="nav-item"><a href="#top-justified-tab1" className="nav-link active" data-toggle="tab">Listado servicios</a></li>
                                    <li className="nav-item"><a href="#top-justified-tab2" className="nav-link" data-toggle="tab">Configuración de los servicios</a></li>

                                </ul>
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" id="top-justified-tab1">
                                        <div className="card">
                                            <div className="card-body">
                                                <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper no-footer">

                                                    <div className="datatable-scroll">
                                                        <table className="table datatable-basic dataTable no-footer" id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info">
                                                            <thead>
                                                                <tr role="row">
                                                                    <th>Servicio</th>
                                                                    <th>Descripción</th>
                                                                    <th>Tipo de persona</th>
                                                                    <th>Precio</th>
                                                                    <th className="text-center" style={{ width: '200px' }}>Acción</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    (this.state.listconfig || []).map((d, s) => (
                                                                        <tr key={s}>
                                                                            <td>{d.servicio}</td>
                                                                            <td>{d.descripcion}</td>
                                                                            <td>{d.tipopersona === "PM" ? "Persona Moral" : "Persona Fisica"}</td>
                                                                            <td className="text-right">{d.importe.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                                            <td className="text-center"><a style={{ cursor: 'pointer' }} onClick={() => this.handledatadelete(d)}><i className="icon-cross2"></i></a></td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="top-justified-tab2">
                                        <div className="card">
                                            <div className="card-body">
                                                <form onSubmit={this.handleSubmitConfig}>
                                                    {
                                                        (this.state.estatusmensaje) ? (
                                                            <div className="alert alert-success alert-styled-right alert-arrow-right alert-dismissible">

                                                                <span className="font-weight-semibold">Información!</span> Se ha guardado correctamente el registro!!
                                                            </div>
                                                        ) : null
                                                    }
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <fieldset>
                                                                <legend className="font-weight-semibold"><i className="icon-reading mr-2" /> Listado de servicios</legend>
                                                                <div className="form-group">
                                                                    <FormControl component="fieldset">
                                                                        <FormGroup>
                                                                            {(this.state.servicios).map((i, o) =>
                                                                            (
                                                                                <FormControlLabel key={o} control={<Checkbox checked={i.isChecked} name={i.parent_id} onChange={this.handleChange} />}
                                                                                    label={<span style={{ color: 'white' }}>{i.descripcion}</span>}
                                                                                />
                                                                            ))}

                                                                        </FormGroup>
                                                                    </FormControl>
                                                                </div>

                                                            </fieldset>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <fieldset data-select2-id={46}>
                                                                <legend className="font-weight-semibold"><i className="icon-truck mr-2" />Configuración servicios</legend>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="form-group">
                                                                            <label>Tipo de persona:</label>
                                                                            <select className="form-control" name="tipopersona" onChange={this.handleChangeText}>
                                                                                <option value="">Seleccione el tipo de persona</option>
                                                                                <option value="PM">Persona Moral</option>
                                                                                <option value="PF">Persona Fisica</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {this.state.viewAuditoria ? (
                                                                    <div className="row">
                                                                        <div className="col-md-11">
                                                                            <div className="form-group">
                                                                                <label>Rango de facturación anual:</label>
                                                                                <input className="form-control" type="text" placeholder="ejem: 1 a 5000 registros" onChange={this.handleChangeFactura} />

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : null}
                                                                {this.state.viewNomina ? (
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className="form-group">
                                                                                <label>Número de trabajadores:</label>
                                                                                <input type="text" placeholder="Ingrese el número de trabajadores" className="form-control" name="descripcion" onChange={this.handleChangeText} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : null}
                                                                {(this.state.viewValidador) ? (
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className="form-group">
                                                                                <label>Descripción:</label>
                                                                                <input type="text" placeholder="Ingrese el tipo de empresas" className="form-control" name="descripcion" onChange={this.handleChangeText} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : null}
                                                                {(this.state.viewAdministrador) ? (
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className="form-group">
                                                                                <label>Descripción:</label>
                                                                                <input type="text" placeholder="Ingrese el tipo de empresas" className="form-control" name="descripcion" onChange={this.handleChangeText} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : null}
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="form-group">
                                                                            <label>Precio:</label>
                                                                            <NumberFormat thousandSeparator={true} prefix={''} className="form-control" value={this.state.importe} name="importe" onChange={this.handleChangeText} />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </fieldset>
                                                        </div>
                                                    </div>
                                                    <div className="text-right">
                                                        <button type="submit" onClick={this.handleSubmitConfig} className="btn btn-primary">Enviar cotización <i className="icon-paperplane ml-2" /></button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modaldelete" className="modal fade" tabIndex="-1" aria-hidden="true" style={{ "display": "none" }}>
                    <div className="modal-dialog modal-sm">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Confirmación de eliminación</h5>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>

                            <div className="modal-body">
                                <p>Está de acuerdo en eliminar el registro?</p>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-link" data-dismiss="modal">Cancelar</button>
                                <button type="button" className="btn bg-primary" onClick={this.handleAceptarDelete}>Aceptar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ServicioPrecio;