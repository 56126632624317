import React, { Component } from 'react'
import api from '../../app/Config/apifiles';
import { Tooltip } from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import Skeleton from '@material-ui/lab/Skeleton';
import { PacmanLoader } from "react-spinners";
import ReactJson from 'react-json-view';
import { validatetipos_relaciones } from "./columns";
import './style.css'
class ReportCFDIAllAcreditable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            skeleton: true,
            loading: true,
            page: 1,
            lastPage: 1,
            anticipo_ingreso: 0,
            descuento: 0,
            exenta_importacion: 0,
            iva_trasladado_0: 0,
            iva_trasladado_8: 0,
            iva_trasladado_16: 0,
            sub_total: 0,
            total_impuesto_trasladado: 0,
            venta_iva_no_objeto_impuesto: 0

        }
    }
    componentDidMount() {
        this.fetchData();
    }
    fetchData = async () => {
        let result_ = await api.post(this.props.link, this.props.params);
        //let result_ = await api.post("v2-report-iva-list-invoce-c", { empresa_id: this.props.empresa_id, anio: this.props.anio, month: this.props.month, number: this.state.page });
        console.log(result_.data, 'ok');
        const { pagination, data, sumoperation } = await result_.data;

        this.setState({
            data: data,
            skeleton: false,
            loading: false,
            page: pagination.startpage,
            lastPage: pagination.lastpages,
            anticipo_ingreso: sumoperation.anticipo_ingreso,
            descuento: sumoperation.descuento,
            exenta_importacion: sumoperation.exenta_importacion,
            iva_trasladado_0: (sumoperation.iva_trasladado_0).toFixed(2),
            iva_trasladado_8: (sumoperation.iva_trasladado_8).toFixed(2),
            iva_trasladado_16: (sumoperation.iva_trasladado_16).toFixed(2),
            sub_total: (sumoperation.sub_total).toFixed(2),
            total_impuesto_trasladado: (sumoperation.total_impuesto_trasladado).toFixed(2),
            venta_iva_no_objeto_impuesto: (sumoperation.venta_iva_no_objeto_impuesto).toFixed(2),
        });

    }
    handlePageChange = async (event, value) => {
        this.setState({ skeleton: true, loading: true, page: value });
        setTimeout(() => {
            this.fetchData();
        }, 300);

    }
    viewpdfcfdi_ = async (t) => {
        window.$('#viewpdfcfdi').modal('show');
        this.setState({
            loading: true
        })
        let _link_ = 'https://e-xpertum.com.mx/adminfile/api/genera_pdf_cfdi/' + t.listado_cfdi_recibido_id + '/RECIBIDO&' + this.state.rfc
        this.setState({
            link: _link_,
            uuid: t.uuid
        });
    }
    handleLoading = async () => {
        this.setState({
            loading: false
        })
    }
    handleLoadXML = async (t) => {
        window.$("#viewxmlcfdi").modal("show")
        let _data_ = await api.post('genera_xml_cfdi', { id: t.listado_cfdi_recibido_id, tipo: 'RECIBIDO' });
        this.setState({
            loading: false,
            uuid: t.uuid,
            cfdi: <ReactJson src={JSON.parse(_data_.data)} enableClipboard={false} theme={"monokai"} displayDataTypes={false} displayObjectSize={false} />
        })
    }
    render() {

        return (
            <>
                {
                    this.state.skeleton ? (
                        <div className="card">
                            <Skeleton />
                            <div style={{
                                margin: 40,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <PacmanLoader color={"#ff7043"} loading={this.state.loading} size={25} margin={2} />
                            </div>
                            <Skeleton variant="circle" animation="wave" width={40} height={40} />
                            <Skeleton variant="rect" animation="wave" width={"100%"} height={118} />
                            <Skeleton animation="wave" />
                        </div>
                    ) : (
                        <>
                            <table className='table' style={{ width: "50%" }}>
                                <thead>
                                    <tr>
                                        <th className='text-center' colSpan={3}>Tasa</th>
                                        <th className='text-center'></th>
                                        <th className='text-center'></th>
                                        <th className='text-center'></th>
                                    </tr>
                                    <tr>
                                        <th className='text-center'>0%</th>
                                        <th className='text-center'>8%</th>
                                        <th className='text-center'>16%</th>
                                        <th className='text-center'>Subtotal</th>
                                        <th className='text-center'>Descuento</th>
                                        <th className='text-center'>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='text-right'>{parseFloat(this.state.iva_trasladado_0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                        <td className='text-right'>{parseFloat(this.state.iva_trasladado_8).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                        <td className='text-right'>{parseFloat(this.state.iva_trasladado_16).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                        <td className='text-right'>{parseFloat(this.state.sub_total).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                        <td className='text-right'>{parseFloat(this.state.descuento).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                        <td className='text-right'>{parseFloat(parseFloat(this.state.sub_total) + parseFloat(this.state.iva_trasladado_8) + parseFloat(this.state.iva_trasladado_16) - this.state.descuento).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                            <div class="view-report">
                                <div class="wrapper-report">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Fecha</th>
                                                <th>Tipo de relación</th>
                                                <th>Método de Pago</th>
                                                <th>RFC</th>
                                                <th>Nombre</th>
                                                <th className='text-center'>16%</th>
                                                <th className='text-center'>8%</th>
                                                <th className='text-center'>0%</th>
                                                <th className='text-center'>Total del Iva</th>
                                                <th className='text-center'>Subtotal</th>
                                                <th className='text-center'>Descuento</th>
                                                <th className='text-center'>Neto</th>
                                                <th className='sticky-col first-col'></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (this.state.data || []).map((record, index) => (
                                                    <tr key={Math.random()}>
                                                        <td className='text-left' style={{ width: "10%" }}>{String(record.fecha).slice(0, 10)}</td>
                                                        <td className='text-center'>{validatetipos_relaciones(record.type_relations)}</td>
                                                        <td className='text-center'>{(record.metodo_pago)}</td>
                                                        <td className='text-left'>{(record.rfc)}</td>
                                                        <td className='text-left'>{(record.nombre)}</td>
                                                        <td className='text-right'>{(record.tasa_16).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                        <td className='text-right'>{(record.tasa_8).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                        <td className='text-right'>{(record.tasa_0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                        <td className='text-right'>{(record.total_impuesto_trasladado).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                        <td className='text-right'>{(record.sub_total).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                        <td className='text-right'>{(record.descuento).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                        <td className='text-right'>{(record.total).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                        <td className='sticky-col first-col'>
                                                            <button className='btn btn-default btn-icon' onClick={() => this.viewpdfcfdi_(record)}><Tooltip title="Click para ver cfdi completo" placement="top" arrow><span className="badge badge-flat  text-success"><i className="icon-file-pdf"></i></span></Tooltip></button>
                                                            <button className='btn btn-default btn-icon' onClick={() => this.handleLoadXML(record)}><Tooltip title="Click para ver el xml completo" placement="top" arrow><span className="badge badge-flat  text-success"><i className="icon-file-xml"></i></span></Tooltip></button>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                    <br />
                                    <br />
                                </div>
                            </div>
                            <div style={{ float: 'right' }}>
                                <Pagination
                                    count={this.state.lastPage}
                                    page={this.state.page}
                                    siblingCount={1}
                                    boundaryCount={1}
                                    variant="text"
                                    shape="round"
                                    onChange={this.handlePageChange}
                                />
                            </div>
                            <div id="viewpdfcfdi" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                                <div className="modal-dialog modal-full">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">{this.state.uuid}</h5>
                                            <button type="button" className="close" data-dismiss="modal" onClick={() => this.setState({ link: '' })}>×</button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="embed-container">
                                                {
                                                    (this.state.loading) ?
                                                        (
                                                            <div>
                                                                <Skeleton variant="text" />
                                                                <Skeleton variant="circle" animation="wave" width={40} height={40} />
                                                                <Skeleton variant="rect" animation="wave" width={510} height={118} />
                                                            </div>
                                                        ) : null
                                                }
                                                <iframe src={this.state.link} onLoad={this.handleLoading}
                                                    width="100%" title='ok'
                                                    frameBorder="0" allowFullScreen
                                                >
                                                </iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="viewxmlcfdi" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                                <div className="modal-dialog modal-full">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">{this.state.uuid}</h5>
                                            <button type="button" className="close" data-dismiss="modal" onClick={() => this.setState({ link: '' })}>×</button>
                                        </div>
                                        <div className="modal-body">
                                            <div>
                                                {
                                                    (this.state.loading) ?
                                                        (
                                                            <div>
                                                                <Skeleton variant="text" />
                                                                <Skeleton variant="circle" animation="wave" width={40} height={40} />
                                                                <Skeleton variant="rect" animation="wave" width={510} height={118} />
                                                            </div>
                                                        ) : null
                                                }
                                                {this.state.cfdi}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </>
                    )
                }
            </>
        )
    }
}


export default ReportCFDIAllAcreditable;