import React from "react";
import { Route, Switch, Redirect, withRouter, } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import Header from "../Header";
import Sidebar from "../Sidebar";
import Dashboard from "../../pages/dashboard";
import PanelConfigUser from "../../app/Configure/Config-Admin/index";
import PanelDahsboard from '../../app/Dashboard/panel-services/index';
import PanelNominaUser from '../../app/Dashboard/panel-services/details-nomina';
/****
 * Lo que agregue para la navegación
 */
import Perfil from "../../client/Perfil";
import NotificationsMails from "../../client/Modulos/config-notificacion";
//import TaskCitas from "../../app/Citas";
//import Task from "../../client/task";
/******************
 * NOTIFICATIONS 
 */
//import Federal from "../../app/Notificaciones/impuestofederal";
//import PanelAdmin from "../../client/Dashboard";
import PanelClient from "../../client/Client/dahsboard";
import Gobierno from "../../client/Client/dahsboardgober";
/************
 * 
 * AQUI VAN LAS URLS PARA INGRESAR AL LOS MODULOS
 * ******************* */
import ListEmpresasServicios from '../../app/Panel/Servicios/index';
import Validate_CFDIS from '../../app/Panel/auditoria-fiscal-contable/index';
import _CFDIS_APP_CFDI_ from '../../app/Panel/history-download-cfdi/index';
import ListEmpresasServiciosAdmin from '../../app/Panel/Servicios/listadminfile';
/********************
 * PARA CATALOGOS
 */
import Clientesx from "../../app/Costumer/index";
//import Clientes from "../../app/Clientes/list";
import Sistemas from "../../app/Sistemas/Table";
import Servicios from "../../app/Servicios/Table";
import ConfigureServices from '../../app/Configure/servicesprecis';
/*************
 * LINKS CLIENTES URL
 */
import Users from '../../client/Dashboard/adminuser/index'
import DetailsValidadorGobierno from '../../client/Modulos/details/listcompanygober';
import DetailsValidador from '../../client/Dashboard/validador_/index';
import AuditoriContable from '../../client/Dashboard/auditor_/topindex';
import Juridico from '../../client/Dashboard/juridico/index';
import LayoutNomina from '../../client/Dashboard/nomina_/topindex';
import LayoutNominaAdmin from '../../client/Dashboard/nomina_/indexnomina';
import Contabilidad from "../../client/Dashboard/contabilidad/index";
import AdminFiles from "../../client/Dashboard/adminfiles/index";
import ImportacionDocs from '../../client/Modulos/indexvalidador';
import ConfigVendors from '../../client/Modulos/ConfigValidate';
import AdminNomina from '../../client/admin/nomina/index';
import ManipulacionDocs from '../../client/admin/nomina/details/index';
/************************
 * childrenn pages
 */
import StatusProcess from '../../client/Dashboard/nomina_/statusNomina';
import NewNomina from '../../client/Dashboard/nomina_/New/Index';
import ListCompanyJuridico from '../../client/Dashboard/juridico/details/indexfile';
import AdminJuridico from '../../client/admin/juridico/index';
import AdminDetailJuridico from '../../client/admin/juridico/details/index';
import AdminValidadorSystem from '../../client/admin/validador/index';
/******************************************DETALLE DEL VALIDADOR */
import Direct_Report_validador from '../../client/Modulos/details/panelvalidador';
/***********help modulos */
/*******************History */
import Help_auditor_contable_ from '../../help/auditor_contable/index';
/*********Catálogos de usuarios dentro del sistema */
import Usuarios_admin from '../../app/Admin/User/index';
import Vendors from '../../app/Prospects/index';
import DetailsVendors from '../../app/Prospects/details/index';
import Sales from '../../app/Admin/User/listvendedores';
import DetailsSales from '../../app/Admin/User/listvendors';
import MonitorSales from '../../app/Sales/index';
import MonitorSalesDetails from '../../app/Sales/details/index';
import ListCostumerVendors from '../../app/Admin/User/listcostumervendors';
import ListMailInboxAdmin from '../../app/Admin/Mails/index';
/*********gobierno */
import DetailsCostumer from '../../app/Costumer/screen-filter/index';
/***********
 * Importacion
 */
import Timeline from '../../client/Dashboard/auditor_/timeline';
/***********
 * Importacion
 */
import ReportsEmitidos from '../../app/Reports/index';
/************
 * Catas
 */
import Proveedores from '../../app/Catalogs/Proveedores';
import ProveedoresGob from '../../app/Catalogs/ProveedoresGob';
import ProveedoresGobDetails from '../../app/Catalogs/details-vendors-gob/index';
import InicioDashboard from '../../app/Dashboard/index';
//import ChartsOK from '../../client/Chart/index';

import ConfigUserAdmin from '../../client/Perfil/v1-config/index';
import AppContabilidadReport from '../../../src/v2-contabilidad/index';

import ReportMetadataAll from '../../../src/v2-contabilidad/view-reports/index';
import BuroV2 from "../../v2-buro";
/****************
 * corporativo legal
 */
import CorporativeLegalAudit from "../../v2_corporate_legal_audit";
import MenuCorporativeLegalAudit from "../../v2_corporate_legal_audit/details";
/**********
 * financial 
 */
import Financial from "../../financial";

/****
 * Reports IVA
 */
import ReportIVA from "../../report-iva";
import ReportIVAAcreditable from "../../report-iva/index-iva-acreditable";

import CedulaA from "../../report-iva/screen-pepers-works/cedula-a";

import Acreditable from "../../report-iva/acreditable";
import IndexTraslado from "../../report-iva/index-trasladado";

var ejercicio = (new Date()).getFullYear();
function Layout(props) {
  var objec = JSON.parse(localStorage.getItem('id_token'));

  const { perfil, id } = objec;
  return (
    <>
      <Header history={props.history} />
      <div className="page-content">
        <Sidebar />
        <div className="content-wrapper">
          <Switch>
            {
              (perfil === "GOBIERNO") ? (
                <>
                  <Route path="/app/ui/validadorgobierno" component={Gobierno} />
                </>

              ) : (
                <Route path="/app/dashboard" component={
                  (
                    (perfil === "ADMIN")
                      ? (Dashboard) : (
                        perfil === "VENTAS" ? Usuarios_admin :
                          (perfil === "VENDEDOR" ? Vendors : (
                            (perfil === "ADMINISTRADORNOMINA" || perfil === "ADMINISTRADORJURIDICO" || perfil === "ADMINISTRADORAUDITOR") ? PanelDahsboard :
                              PanelClient
                          ))
                      ))
                } />
              )
            }
            <Route exact path="/app/ui" render={() => <Redirect to="/app/ui/dashboard" />} />
            <Route path="/app/ui/financial" component={Financial} />
            <Route path="/app/ui/perfil" component={Perfil} />
            <Route path="/app/ui/config-notification" component={NotificationsMails} />
            <Route path="/app/ui/69bgob" component={DetailsValidadorGobierno} />
            <Route path="/app/ui/listempresas" component={ListEmpresasServicios} />
            <Route path="/app/ui/listadminfilescompany" component={ListEmpresasServiciosAdmin} />
            <Route path="/app/ui/clientsadmin" component={Clientesx} />
            {/*<Route path="/app/ui/clients" component={Clientes} />*/}
            <Route path="/app/ui/sistemas" component={Sistemas} />
            <Route path="/app/ui/services" component={Servicios} />
            <Route path="/app/ui/69b" component={DetailsValidador} />
            <Route path="/app/client/sistem" component={AuditoriContable} />
            <Route path="/app/ui/constitucion" component={Juridico} />
            <Route path="/app/ui/nomina" component={LayoutNomina} />
            <Route path="/app/ui/contabilidad" component={Contabilidad} />
            <Route path="/app/ui/admin" component={AdminFiles} />
            <Route path="/app/ui/users" component={Users} />
            <Route path="/app/ui/adminnomina" component={LayoutNominaAdmin} />
            <Route path="/app/ui/adminnominastatus" component={StatusProcess} />
            <Route path="/app/panel/new" component={NewNomina} />
            <Route path="/app/ui/filesimport" component={ImportacionDocs} />
            <Route path="/app/ui/config-vendors" component={ConfigVendors} />
            <Route path="/app/ui/reportvalidate" component={Direct_Report_validador} />
            <Route path="/app/ui/adminnoministas" component={AdminNomina} />
            <Route path="/app/ui/sydeyte93ijd248" component={ManipulacionDocs} />
            <Route path="/app/ui/&ydud90" component={ListCompanyJuridico} />
            <Route path="/app/ui/adminjuridico" component={AdminJuridico} />
            <Route path="/app/ui/admin&details1838juridico" component={AdminDetailJuridico} />
            <Route path="/app/ui/validador" component={AdminValidadorSystem} />
            <Route path="/app/ui/helpauditorcontable" component={Help_auditor_contable_} />
            <Route path="/app/user/admin" component={Usuarios_admin} />
            <Route path="/app/filter/liststatus" component={DetailsCostumer} />
            <Route path="/app/filter/vendors" component={Vendors} />
            <Route path="/app/filter/details-vendors" component={DetailsVendors} />
            <Route path="/app/filter/Sales" component={Sales} />
            <Route path="/app/filter/details-sales" component={DetailsSales} />
            <Route path="/app/auditor/details-auditor" component={Timeline} />

            <Route path="/app/filter/monitor" component={MonitorSales} />
            <Route path="/app/filter/monitordetails" component={MonitorSalesDetails} />
            <Route path="/app/filter/listcostumer" component={ListCostumerVendors} />
            <Route path="/app/config/serviceconfiguration" component={ConfigureServices} />
            <Route path="/app/mail/mailsinbox" component={ListMailInboxAdmin} />

            <Route path="/app/catalogs/proveedores" component={Proveedores} />
            <Route path="/app/catalogs/vendorscompany" component={ProveedoresGob} />
            <Route path="/app/catalogs/details-vendors" component={ProveedoresGobDetails} />

            <Route path="/app/report/audite" component={ReportsEmitidos} />
            <Route path="/app/panel/company" component={InicioDashboard} />

            <Route path="/app/panel/validate-cfdis" component={Validate_CFDIS} />
            <Route path="/app/panel/history-cfdis" component={_CFDIS_APP_CFDI_} />
            <Route path="/app/panel/config-user-company" component={PanelConfigUser} />
            <Route path="/app/panel/nomina-panel" component={PanelNominaUser} />
            <Route path="/app/config/company-user" component={ConfigUserAdmin} />
            <Route path="/app/report/contabilidad" component={AppContabilidadReport} />
            <Route path="/app/report/invoces-compare" component={ReportMetadataAll} />
            <Route path="/app/report/buro-v2" component={BuroV2} />

            <Route path="/app/report/calulate-iva-v2" component={ReportIVA} />
            <Route path="/app/report/calulate-iva-acreditable" component={ReportIVAAcreditable} />
            <Route path="/app/papers-works/cedula-a" component={CedulaA} />

            <Route path="/app/audit/corporate-legal-audit" component={CorporativeLegalAudit} />
            <Route path="/app/audit/dashboard-corporate-legal-audit" component={MenuCorporativeLegalAudit} />


            <Route path="/app/report/list-acreditable" component={Acreditable} />
            {/****
             * Links de pruebas
             */}
            <Route path="/app/report/compare-iva-traslado" component={IndexTraslado} />
          </Switch>
          <div className="navbar navbar-expand-lg navbar-light" style={{
            position: 'fixed', width: "96%", bottom: 0
          }}>
            <div className="text-center d-lg-none w-100">
              <button type="button" className="navbar-toggler dropdown-toggle" data-toggle="collapse" data-target="#navbar-footer">
                <i className="icon-unfold mr-2"></i>
                Pie de página
              </button>
            </div>

            <div className="navbar-collapse collapse" id="navbar-footer">
              <span className="navbar-text">
                <span style={{ fontSize: '15px' }}> &copy;</span> {ejercicio}  Mercanetica estrategias S.A de C.V.
              </span>

              <ul className="navbar-nav ml-lg-auto">
                <li className="nav-item"><a href="https://e-xpertum.com.mx/webadmin/read-android" className="navbar-nav-link"><Tooltip arrow placement="top" title="Click en el icono para descargar el APP para Android"><span style={{ color: 'green' }}><i style={{ fontSize: '20px' }} className="icon-android"></i></span></Tooltip> </a></li>
                <li className="nav-item"><a href="https://e-xpertum.com.mx/webadmin/read-ios" className="navbar-nav-link"><Tooltip arrow placement="top" title="Click en el icono para descargar el APP para IOS i-Phn"><span className="text-info-400"><i style={{ fontSize: '20px' }} className="icon-apple2"></i></span></Tooltip></a></li>
                <li className="nav-item"><a href="https://e-xpertum.com.mx/webadmin/api/documents/api/help/Manual_E_xpertum_INICIO_V1.pdf" className="navbar-nav-link" target="_blank"><span className="text-warning-400"><i className="icon-question6"></i>  Manual</span></a></li>
                <li className="nav-item"><a href="https://e-xpertum.com.mx/adminfile/preguntas-frecuentes" className="navbar-nav-link" target="_blank"><span className="text-warning-400"><i className="icon-lifebuoy mr-2"></i> Preguntas frecuentes</span></a></li>
                <li className="nav-item"><a href="https://e-xpertum.com.mx/adminfile/terminosycondiciones" className="navbar-nav-link" target="_blank"><span className="text-warning-400"><i className="icon-file-text2 mr-2"></i> Términos y condiciones</span></a></li>
                <li className="nav-item"><a href="https://e-xpertum.com.mx/adminfile/avisoprivacidad" className="navbar-nav-link" target="_blank"><span className="text-warning-400"><i className="icon-clipboard3 mr-2"></i> Aviso de privacidad</span></a></li>
                <li className="nav-item"><a href="https://e-xpertum.com/" className="navbar-nav-link" target="_blank"><span className="text-warning-400"><i className="icon-cart2 mr-2"></i> Comprar</span></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(Layout);
