import React, { Component } from 'react';
class ExtensionFiles extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        ///console.log(props)
    }
    render() {
        var i = this.props.ext;
        if (i.type === "directory") {
            if (i.children.length === 0)
                return (
                    <i className="icon-folder text-blue-400 icon-5x"></i>
                )
            else
                return (
                    <i className="icon-folder-open text-blue-400 icon-5x"></i>
                )
        }
        else if (i.type === "file") {
            if (i.extension === ".xls" || i.extension === ".ods" || i.extension === ".xlsx" || i.extension === ".xlsx#" || i.extension === ".xls#" || i.extension === ".csv")
                return (
                    <i className="icon-file-excel text-success-400 icon-5x"></i>
                )
            else if (i.extension === ".docx" || i.extension === ".docx#" || i.extension === ".doc")
                return (
                    <i className="icon-file-word text-blue-400 icon-5x"></i>
                )
            else if (i.extension === ".pptx")
                return (
                    <i className="icon-file-presentation text-warning-400 icon-5x"></i>
                )
            else if (i.extension === ".pdf")
                return (
                    <i className="icon-file-pdf text-danger-400 icon-5x"></i>
                )
            else if (i.extension === ".zip" || i.extension === ".tar.gz" || i.extension === ".gz")
                return (
                    <i className="icon-file-zip text-success-400 icon-5x"></i>
                )
            else if (i.extension === ".xml")
                return (
                    <i className="icon-file-xml text-success-400 icon-5x"></i>
                )
            else if (i.extension === ".sql")
                return (
                    <i className="icon-database text-blue-400 icon-5x"></i>
                )
            else if (i.extension === ".rar")
                return (
                    <i className="icon-file-zip text-blue-400 icon-5x"></i>
                )
            else if (i.extension === ".txt")
                return (
                    <i className="icon-file-text2 text-blue-400 icon-5x"></i>
                )
            else if (i.extension === ".cer" || i.extension === ".pfx" || i.extension === ".key")
                return (
                    <i className="icon-file-empty2 text-blue-400 icon-5x"></i>
                )
            else if (i.extension === ".json")
                return (
                    <i className="icon-file-css text-warning-400 icon-5x"></i>
                )
            else if (i.extension === ".png" || i.extension === ".jpg" || i.extension === ".jpeg")
                return (
                    <i className="icon-images3 text-blue-400 icon-5x"></i>
                )
            else
                return (
                    <i className="icon-folder text-blue-400 icon-2x"></i>
                )
        }
        else {
            return (
                <i className="icon-folder text-blue-400 icon-2x"></i>
            )
        }
    }
}

export default ExtensionFiles;