import React, { Component } from 'react';
import ConfigAdmin from '../Config/config.json';
import api from '../../app/Config/api';
import ReactDatatable from '@ashvin27/react-datatable';
import NuevoProspecto from './details/addprospects';
import { withRouter } from 'react-router-dom';
import { Tooltip, } from "@material-ui/core";
import MailSend from '../Sales/details/mailcreate';
class Prospects extends Component {
    constructor(props) {
        super(props);
        var objec = JSON.parse(localStorage.getItem('id_token'));
        let data_contacto_ = JSON.parse(localStorage.getItem('_contact_admin_'));

        this.columns = [
            {
                key: "nombre_completo",
                text: "Nombre completo",
                className: "nombre_completo",
                align: "left",
                sortable: true,
            },
            {
                key: "rfc",
                text: "Compañia",
                className: "rfc",
                sortable: true
            },
            {
                key: "correo",
                text: "Correo",
                className: "postcode"
            },

            {
                key: "status",
                text: "Estatus",
                className: "action",

                align: "left",
                sortable: false,
                cell: t => {
                    return (
                        <div>

                            {
                                (t.status === "New") ?
                                    (
                                        <>

                                            <span className="badge badge-mark bg-info border-info align-self-center mr-2"></span>
                                            {t.descripcion}
                                        </>
                                    ) : (
                                        (t.status === "Open") ? (
                                            <>
                                                <span className="badge badge-mark bg-info border-danger align-self-center mr-2"></span>
                                                {t.descripcion}
                                            </>
                                        ) :
                                            (
                                                (t.status === "Inprogress" || t.status === "Unrated") ? (
                                                    <>
                                                        <span className="badge badge-mark bg-info border-success align-self-center mr-2"></span>
                                                        {t.descripcion}
                                                    </>
                                                ) : (
                                                        <>
                                                            <span className="badge badge-mark bg-warning border-info align-self-center mr-2"></span>
                                                            {t.descripcion}
                                                        </>
                                                    )
                                            )
                                    )
                            }
                        </div>
                    );
                }
            },
            {
                key: "action",
                text: "Acción",
                className: "action",
                width: 120,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <div className="text-center">
                            <Tooltip title="Cambiar el estatus" placement="top" arrow>
                                <a className="list-icons-item text-blue-300" style={{ cursor: 'pointer' }} onClick={() => this.handleChangeStatus(record)}>
                                    <i className="icon-reply-all"></i>
                                </a>
                            </Tooltip>
                            &nbsp; &nbsp;
                            <Tooltip title="Seguimiento del contacto" placement="top" arrow>
                                <a className="list-icons-item text-blue-300" style={{ cursor: 'pointer' }} onClick={() => this.handleViewDetails(record)}>
                                    <i className="icon-list"></i>
                                </a>
                            </Tooltip>
                            &nbsp; &nbsp;
                            <Tooltip title="Eliminar el contacto" placement="top" arrow>
                                <a className="list-icons-item text-blue-300" style={{ cursor: 'pointer' }} onClick={() => this.handleDeleteInfo(record)}>
                                    <i className="icon-cross3" style={{ fontSize: '19px' }}></i>
                                </a>
                            </Tooltip>
                        </div>
                    );
                }
            }
        ];
        this.config = {
            key_column: 'id',
            "page_size": 10,
            "length_menu": [
                10,
                20,
                50
            ],
            "show_filter": true,
            "show_pagination": true,
            "button": {
                "excel": true,
                "print": true,
                "extra": true
            },
            "language": {
                "length_menu": "Mostrar _MENU_ registros por página",
                "filter": "Buscar en registros ...",
                "info": "Mostrando _START_ a _END_ de _TOTAL_ entradas",
                "pagination": {
                    "first": "Primero",
                    "previous": "Previa",
                    "next": "próximo",
                    "last": "Última"
                }
            }
        };
        this.extraButtons = [
            {
                className: "btn btn-light",
                title: "Export TEst",
                children: [
                    <span>
                        Nuevo Contacto<i className="icon-play3 ml-2" aria-hidden="true"></i>
                    </span>
                ],
                onClick: (event) => {
                    window.$("#modal_full").modal("show");
                }
            },
        ]
        this.state = {
            recordsets: [],
            vendedor_id: (objec.perfil === "VENDEDOR" ? objec.id : (data_contacto_.usuario_id)),
            perfil: objec.perfil,
            status: 0,
            comments: '',
            prospecto_id: 0,
            id: 0,
            mail: ''
        }
    }
    componentDidMount() {
        this.getquerydata();
        this.getlistventasadmin();
    }
    getquerydata = async () => {
        let getinformacion = await api.post("lisprospetsvendors", this.state);
        let { data } = await getinformacion;

        this.setState({
            recordsets: data,
            status: 0,
            comments: '',
            prospecto_id: 0,
            id: 0
        });

    }
    getlistventasadmin = async () => {
        let getinformacion = await api.post("filtervendedoradmin", this.state);
        let { data } = await getinformacion;
        localStorage.removeItem("_data_ventas_admin_")
        localStorage.setItem("_data_ventas_admin_", JSON.stringify(data[0]));
    }
    handleViewDetails = (id) => {
        localStorage.removeItem("_data_contact_")
        localStorage.setItem("_data_contact_", JSON.stringify(id));
        this.props.history.push('/app/filter/details-vendors');
    }
    handleViewDelete = (object) => {
        window.$("#modaleditar").modal("show");
    }
    closemodal = () => {
        this.getquerydata();
        window.$("#modal_full").modal("hide");
    }
    handleChangeStatus = (i) => {
        localStorage.setItem("filterprospecto", JSON.stringify(i))
        this.setState({
            mail: <MailSend close={this.handleclosemodal} />,
            id: i.pros_id, prospecto_id: i.pros_id, status: 0,
            comments: '',
            prospecto_id: 0,
            id: 0
        })
        window.$("#modal_backdrop").modal("show");
    }
    handlechangeData = (o) => {
        this.setState({ [o.target.name]: o.target.value })
    }
    handleSaveToStatus = async () => {
        let getinformacion = await api.post("newcomentarios", this.state);
        this.getquerydata();

        window.$("#modal_backdrop").modal("hide");
    }
    handleclosemodal = () => {
        this.setState({
            mail: '', id: 0, prospecto_id: 0, status: 0,
            comments: '', prospecto_id: 0, id: 0
        });
        window.$("#modal_backdrop").modal("hide");
    }
    handleDeleteInfo = (k) => {
        this.setState({
            prospecto_id: k.pros_id,
            id: k.id,
        })
        window.$("#modal_deletefrom").modal("show");

    }
    handleConfirmDelete = async () => {
        let array_dat = {
            pros_id: this.state.prospecto_id,
            id: this.state.id
        }
        let getinformacion = await api.post("deletepropspectsperson", array_dat);
        this.getquerydata();

        window.$("#modal_deletefrom").modal("hide");
    }
    render() {
        return (
            <>
                <div className="page-content">
                    <div className="content-wrapper">
                        <div className="page-header border-bottom-0">
                            <div className="page-header-content header-elements-md-inline">
                                <div className="page-title d-flex">
                                    {
                                        (this.state.perfil === "ADMIN") ?
                                            (
                                                <>
                                                    <a href="#/app/filter/details-sales" style={{ color: 'white' }}> <h4><i className="icon-arrow-left52 mr-2"></i> <span className="font-weight-semibold">Listado de vendedores</span></h4></a>
                                                    <a href="#" className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></a>
                                                </>
                                            ) :
                                            (
                                                (this.state.perfil === "VENTAS") ?
                                                    (
                                                        <>
                                                            <a href="#/app/user/admin" style={{ color: 'white' }}> <h4><i className="icon-arrow-left52 mr-2"></i> <span className="font-weight-semibold">Listado de vendedores</span></h4></a>
                                                            <a href="#" className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></a>
                                                        </>
                                                    ) :
                                                    (<>
                                                        <a href="#" style={{ color: 'white' }}> <h4><i className="icon-arrow-left52 mr-2"></i> <span className="font-weight-semibold">Inicio</span></h4></a>
                                                        <a href="#" className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></a>
                                                    </>)
                                            )
                                    }

                                </div>
                                <h6 className="text-title">Listado de contactos / prospectos</h6>
                                <div className="header-elements d-none mb-3 mb-md-0">
                                    <div className="d-flex justify-content-center">

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content pt-0">
                            <div className="card">
                                <div className="card-body">
                                    <ReactDatatable
                                        config={this.config}
                                        records={this.state.recordsets}
                                        columns={this.columns}
                                        extraButtons={this.extraButtons}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal_full" className="modal fade" tabIndex="-1" aria-hidden="true" style={{ "display": "none" }}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title text-center" style={{ textAlign: 'center' }}>Nuevo contacto</h5>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>
                            <NuevoProspecto close={this.closemodal} />
                        </div>
                    </div>
                </div>
                <div id="modal_backdrop" className="modal fade" tabIndex="-1" aria-hidden="true" style={{ "display": "none" }}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Cambiar de estatus el contacto</h5>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>

                            <div className="modal-body">
                                {this.state.mail}
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal_deletefrom" className="modal fade" tabIndex="-1" aria-hidden="true" style={{ "display": "none" }}>
                    <div className="modal-dialog modal-sm">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Confirma la eliminación</h5>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>

                            <div className="modal-body">
                                Está de acuerdo en eliminar el registro?
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-link" data-dismiss="modal">Cancelar</button>
                                <button type="button" class="btn bg-primary" onClick={this.handleConfirmDelete}>Aceptar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(Prospects);