import React, { Component } from 'react';
import { Tooltip, } from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
import api from '../../../Config/apifiles';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactJson from 'react-json-view';
import Backdrop from "@material-ui/core/Backdrop";
import ReactDatatable from '@ashvin27/react-datatable';
class IMSSALL extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "fecha_emision",
                text: "Fecha",
                className: "name",
                cell: record => {
                    return (
                        <div className="text-center">
                            {record.fecha_emision.substr(0, 10)}
                        </div>
                    );
                }
            },
            {
                key: "tipo_de_comprobante",
                text: "Tipo comprobante",
                cell: record => {
                    return (
                        <div className="text-center">
                            {tipoComprobante(record.efecto_comprobante)}
                        </div>
                    );
                }
            },
            {
                key: "monto",
                text: "Total",
                className: "postcode",
                cell: y => {
                    return (
                        <div className="text-center">
                            {(y.monto < 0 ? (
                                <span className="text-danger">({y.monto.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1, ')})</span>
                            ) : <>{y.monto.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1, ')}</>
                            )}
                        </div>
                    );
                }
            }, {
                key: "rfc_emisor",
                text: "RFC",
                className: "action",
                cell: y => {
                    return (
                        <div className="text-left">
                            {(y.rfc_emisor)}
                        </div>
                    );
                }
            }, {
                key: "nombre_emisor",
                text: "Nombre",
                className: "action",
                cell: y => {
                    return (
                        <div className="text-left">
                            {(y.nombre_emisor)}
                        </div>
                    );
                }
            },
            {
                cell: y => {
                    return (
                        <div className="text-center">
                            <a style={{ cursor: 'pointer' }} onClick={() => this.viewpdfcfdi_(y)}><Tooltip title="Click para ver cfdi completo" placement="top" arrow><span className="badge badge-flat  text-success"><i className="icon-file-pdf"></i></span></Tooltip></a>
                            &nbsp;
                            <a style={{ cursor: 'pointer' }} onClick={() => this.handleLoadXML(y)}><Tooltip title="Click para ver el xml completo" placement="top" arrow><span className="badge badge-flat  text-success"><i className="icon-file-xml"></i></span></Tooltip></a>
                        </div>
                    );
                }
            }
        ];
        this.config = {
            "page_size": 50,
            "length_menu": [50, 70, 90],
            "show_filter": true,
            "show_pagination": true,
            "button": {
                "excel": false,
                "print": false,
                "extra": false
            },
            "language": {
                "length_menu": "Mostrar _MENU_ registros por página",
                "filter": "Buscar en registros ...",
                "info": "Mostrando _START_ a _END_ de _TOTAL_ entradas",
                "pagination": {
                    "first": "Primero",
                    "previous": "Previa",
                    "next": "próximo",
                    "last": "Última"
                }
            }
        };
        this.state = {
            data: props.dt,
            uuid: '',
            tipo: '',
            loading: false,
            view: '',
            cfdi: ''
        }
    }
    viewpdfcfdi_ = async (x) => {
        window.$("#modalviewlistcfdis").modal("show");
        this.setState({
            loading: true,
            tipo: tipoComprobante(x.efecto_comprobante) + " # " + x.uuid
        })
        let _link_ = "https://e-xpertum.com.mx/adminfile/api/get-cfdi-pdf-filter/imss/" + x.uuid;
        this.setState({
            view: 'xml',
            link: _link_
        });
    }
    handleLoadXML = async (x) => {
        let _data_ = await api.post('get-cfd-json-xml', { uuid: x.uuid });
        let { json } = await _data_.data;
        this.setState({
            tipo: tipoComprobante(x.efecto_comprobante) + " # " + x.uuid,
            uuid: x.uuid,
            view: 'cfdi',
            cfdi: <ReactJson src={JSON.parse(json)} enableClipboard={false} theme={"monokai"} displayDataTypes={false} displayObjectSize={false} />
        })
        window.$("#modalviewlistcfdis").modal("show");
    }
    handleLoading = async () => {
        this.setState({
            loading: false
        })
    }
    render() {
        return (
            <>
                <ReactDatatable
                    config={this.config}
                    records={this.state.data}
                    columns={this.columns}
                />
                <div id="modalviewlistcfdis" className="modal fade" tabIndex={-1} style={{ display: 'none' }} aria-hidden="true">
                    <div className="modal-dialog modal-full">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{this.state.tipo}</h5>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>
                            <div className="modal-body">

                                {
                                    (this.state.loading) ?
                                        (
                                            <div>
                                                <Skeleton variant="text" />
                                                <Skeleton variant="circle" animation="wave" width={40} height={40} />
                                                <Skeleton variant="rect" animation="wave" width={510} height={118} />
                                            </div>
                                        ) : null
                                }
                                {
                                    this.state.view === 'cfdi' ? (
                                        <>
                                            {this.state.cfdi}
                                        </>
                                    ) : null
                                }
                                {
                                    this.state.view === 'xml' ? (
                                        <>
                                            <div className="embed-container">
                                                <iframe src={this.state.link} onLoad={this.handleLoading}
                                                    width="100%"
                                                    frameBorder="0" allowFullScreen
                                                >
                                                </iframe>
                                            </div>
                                        </>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default IMSSALL;
function tipoComprobante(key) {
    switch (key) {
        case 'I':
            return 'I - Ingreso'
            break;
        case 'E':
            return 'E - Egreso'
            break;
        case 'T':
            return 'T - Traslado'
            break;
        case 'P':
            return 'P - Recepción de Pagos'
            break;
        case 'N':
            return 'N - Nómina'
            break;
        case 'R':
            return 'Retenciones e Información de Pagos'
            break;
        default:
            return key
            break;
    }
}