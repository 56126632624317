import React, { Component } from 'react'
import $ from "jquery";
import api from '../../../app/Config/api';
//import axios from 'axios';
import MaskedInput from 'react-text-mask'
class index extends Component {
    constructor(props) {
        var objec = JSON.parse(localStorage.getItem('id_token'));

        super(props);
        this.state = {
            array_users: [],
            usuario: '',
            correo: '',
            nombre: '',
            apellido_paterno: '',
            apellido_materno: '',
            cliente_id: objec.cliente_id,
            datos_fiscales_id: objec.razon_id,
            telefono: '',
            perfil: '',
            persona_id: 0,
            usuario_id: 0,
            inter_id: 0,
            viewbutton: false,
            perfiluser: objec.perfil
        };
    }
    componentDidMount() {
        var options = {
            query: "SELECT p.id persona_id,u.id usuario_id,IU.id inter_id, P.nombre,CONCAT(P.apellido_paterno,' ',P.apellido_materno) apellidos,P.apellido_paterno,P.apellido_materno,U.usuario,P.correo,u.perfil,IU.datos_fiscales_id,P.telefono FROM usuario U INNER JOIN intermedia_usuarios_personas_sistema IU ON U.id=Iu.usuario_id INNER JOIN personas P ON IU.persona_id=P.id WHERE U.cliente_id='" + this.state.cliente_id + "'"
        }
        api.post("configtabledinamyc", options).then((res) => res.data).then((op) => {
            this.setState({
                array_users: op
            });

        });
        this.handleDataNumberUser();
    }
    handleDataNumberUser() {
        var objec = JSON.parse(localStorage.getItem('id_token'));
        var obje = {
            query: "SELECT numero_usuario FROM activaciones where cliente_id='" + objec.cliente_id + "'"
        }
        api.post('configtabledinamyc', obje).then((r) => r.data)
            .then((j) => {
                //var numero_usuarios_ =j[0];
                if (this.state.array_users.length >= j[0].numero_usuario)
                    this.setState({ viewbutton: true })

                else
                    this.setState({ viewbutton: false })
            }).catch((u) => {
                console.log(u)
            })
    }

    handlemodaledit = (j) => {
        this.setState(j);
        window.$('#edit_modal').modal('show');
    }
    handlemodaldelete = (j) => {
        this.setState(j);
        window.$('#remove_modal').modal('show');
    }
    handlenewuser = (i) => {
        this.setState({
            [i.target.name]: i.target.value
        });
    }
    handleSendData = () => {
        api.post("newuseradd", this.state).then((u) => u.data).then((i) => {
            if (i === "good") {
                this.setState({
                    usuario: '',
                    correo: '',
                    nombre: '',
                    apellido_paterno: '',
                    apellido_materno: '',
                    telefono: '',
                    perfil: '',
                    persona_id: 0,
                    usuario_id: 0,
                    inter_id: 0,
                });
                this.componentDidMount();
                window.$("#modal_default").modal("hide");
            }
        });

    }
    handledeletefrom = () => {
        var options = {
            query: " delete from intermedia_usuarios_personas_sistema where id=" + this.state.inter_id + "; " +
                " delete from Usuario where id=" + this.state.usuario_id + "; delete from personas where id=" + this.state.persona_id
        }
        api.post("configtabledinamyc", options).then((res) => res.data).then((op) => {
            this.componentDidMount();
        });
    }
    handleUpdateAll = () => {
        var options = {
            query: " UPDATE Usuario SET usuario='" + this.state.usuario + "',email='" + this.state.correo + "',perfil='" + this.state.perfil + "' where id=" + this.state.usuario_id + ";" +
                " UPDATE personas SET nombre='" + this.state.nombre + "',apellido_paterno='" + this.state.apellido_paterno + "',apellido_materno='" + this.state.apellido_materno + "',telefono='" + this.state.telefono + "',correo='" + this.state.correo + "' where id=" + this.state.persona_id
        }
        api.post("configtabledinamyc", options).then((res) => res.data).then((op) => {
            this.componentDidMount();
            window.$("#edit_modal").modal("hide");
        });
    }

    render() {
        return (
            <>
                <div className="page-header border-bottom-0">
                    <div className="page-header-content header-elements-md-inline">
                        <div className="page-title d-flex">
                            {this.state.perfiluser === "GOBIERNO" ? (<a href="#/app/ui/validadorgobierno" style={{ color: 'white' }}> <h4><i className="icon-arrow-left52 mr-2"></i> <span className="font-weight-semibold"></span> Inicio</h4></a>)
                                : (<a href="#" style={{ color: 'white' }}> <h4><i className="icon-arrow-left52 mr-2"></i> <span className="font-weight-semibold"></span> Inicio</h4></a>)}

                            <a href="#" className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></a>
                        </div>

                    </div>
                </div>
                <div className="content pt-0">
                    <div className="card">
                        <div className="card-header header-elements-inline">
                            <h5 className="card-title">Administración de usuarios</h5>
                        </div>

                        <div className="card-body">
                            {(!this.state.viewbutton) ? (
                                <button style={{ float: 'right' }} type="button" className="btn btn-light" data-toggle="modal" data-target="#modal_default">Nuevo usuario <i className="icon-play3 ml-2"></i></button>
                            ) : null}

                        </div>

                        <div className="table-responsive">

                            {
                                (this.state.perfiluser === "CLIENTE" || this.state.perfiluser === "GOBIERNO") ? (
                                    <table className="table table-bordered table-lg">
                                        <thead>
                                            <tr>
                                                <th>Nombre</th>
                                                <th>Apellidos</th>
                                                <th>Usuario</th>
                                                <th>Correo</th>
                                                <th>Perfil</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(this.state.array_users || []).map((t, k) => (
                                                <tr key={k}>
                                                    <td>{t.nombre}</td>
                                                    <td>{t.apellidos}</td>
                                                    <td>{t.usuario}</td>
                                                    <td>{t.correo}</td>
                                                    <td className="text-center">{t.perfil === 'CLIENTE' ? (
                                                        <>
                                                            <span className="badge badge-mark bg-danger border-danger align-self-center mr-2"></span> Admin
                                                    </>
                                                    ) : (
                                                        <>
                                                            <span className="badge badge-mark bg-info border-info align-self-center mr-2"></span>Capturista
                                                        </>
                                                    )} </td>
                                                    <td className="text-center">
                                                        <div className="list-icons">
                                                            {
                                                                t.perfil !== 'CLIENTE' ? (
                                                                    <>
                                                                        <a className="list-icons-item text-blue-300" style={{ cursor: 'pointer' }} onClick={() => this.handlemodaledit(t)}><i className="icon-pencil"></i></a>
                                                                        <a className="list-icons-item text-orange-300" style={{ cursor: 'pointer' }} onClick={() => this.handlemodaldelete(t)}><i className="icon-close2"></i></a>
                                                                    </>
                                                                ) : null
                                                            }

                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <>
                                        <div className="alert bg-warning alert-styled-left alert-dismissible">
                                            <button type="button" className="close" data-dismiss="alert"><span>×</span></button>
                                            <span className="font-weight-semibold">Ups!!!.</span> La cuenta no tiene el perfil de administrador para crear o eliminar a los usuarios.
                                            </div>
                                    </>
                                )
                            }

                            <p>Nota: En caso de solicitar un usuario adicional, ponerse en contacto con el area de ventas.</p>
                        </div>
                    </div>


                    <div id="edit_modal" className="modal fade" role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header bg-transparent">
                                    <h5 className="modal-title">Modificar : {this.state.nombre + ' ' + this.state.apellido_paterno + ' ' + this.state.apellido_materno}</h5>
                                    <button type="button" className="close" data-dismiss="modal">×</button>
                                </div>

                                <div className="modal-body">
                                    <div className="card">
                                        <div className="card-body">
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>Nombre(s):</label>
                                                            <input type="text" className="form-control" placeholder="Nombre(s)" value={this.state.nombre} name="nombre" onChange={this.handlenewuser} autoComplete="off" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Apellido paterno:</label>
                                                            <input type="text" className="form-control" placeholder="Apellido paterno" value={this.state.apellido_paterno} name="apellido_paterno" onChange={this.handlenewuser} autoComplete="off" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Apellido materno:</label>
                                                            <input type="text" className="form-control" placeholder="Apellido materno" value={this.state.apellido_materno} name="apellido_materno" onChange={this.handlenewuser} autoComplete="off" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Usuario:</label>
                                                            <input type="text" className="form-control" placeholder="Usuario" value={this.state.usuario} name="usuario" onChange={this.handlenewuser} autoComplete="off" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Correo:</label>
                                                            <input type="text" className="form-control" placeholder="Correo" value={this.state.correo} name="correo" onChange={this.handlenewuser} autoComplete="off" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Perfil:</label>
                                                            <select className="form-control" name="perfil" value={this.state.perfil} onChange={this.handlenewuser}>
                                                                <option value="CLIENTE">Admin</option>
                                                                <option value="CAPTURISTA">Capturista</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Teléfono:</label>
                                                            <MaskedInput className="form-control" placeholder="Teléfono" value={this.state.telefono}
                                                                mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} name="telefono" onChange={this.handlenewuser} autoComplete="off"
                                                            />

                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer bg-transparent">
                                    <div className="d-flex justify-content-end align-items-center">
                                        <button type="button" className="btn btn-light" data-dismiss="modal">Cancelar</button>
                                        <button type="submit" className="btn bg-blue ml-3" onClick={this.handleUpdateAll}>Enviar cambios<i className="icon-paperplane ml-2"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="remove_modal" className="modal fade" role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Confirmación eliminar</h5>
                                    <button type="button" className="close" data-dismiss="modal">×</button>
                                </div>
                                <div className="modal-body">Está seguró de eliminar el usuario?</div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.handledeletefrom}>Si, Eliminar</button>
                                    <button type="button" className="btn btn-light" data-dismiss="modal">No, Gracias</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="modal_default" className="modal fade" role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Nuevo usuario</h5>
                                    <button type="button" className="close" data-dismiss="modal">×</button>
                                </div>

                                <div className="modal-body">
                                    <div className="card">
                                        <div className="card-body">
                                            <form ref="forms">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>Nombre(s):</label>
                                                            <input type="text" className="form-control" placeholder="Nombre(s)" value={this.state.nombre} name="nombre" onChange={this.handlenewuser} autoComplete="off" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Apellido paterno:</label>
                                                            <input type="text" className="form-control" placeholder="Apellido paterno" value={this.state.apellido_paterno} name="apellido_paterno" onChange={this.handlenewuser} autoComplete="off" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Apellido materno:</label>
                                                            <input type="text" className="form-control" placeholder="Apellido materno" value={this.state.apellido_materno} name="apellido_materno" onChange={this.handlenewuser} autoComplete="off" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Usuario:</label>
                                                            <input type="text" className="form-control" placeholder="Usuario" value={this.state.usuario} name="usuario" onChange={this.handlenewuser} autoComplete="off" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Correo:</label>
                                                            <input type="text" className="form-control" placeholder="Correo" value={this.state.correo} name="correo" onChange={this.handlenewuser} autoComplete="off" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Perfil:</label>
                                                            <select className="form-control" name="perfil" value={this.state.perfil} onChange={this.handlenewuser}>
                                                                <option value="CLIENTE">Admin</option>
                                                                <option value="CAPTURISTA">Capturista</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Teléfono:</label>
                                                            <MaskedInput className="form-control" placeholder="Teléfono" value={this.state.telefono}
                                                                mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} name="telefono" onChange={this.handlenewuser} autoComplete="off"
                                                            />

                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <div className="d-flex justify-content-end align-items-center">
                                        <button type="button" className="btn btn-light" data-dismiss="modal">Cancelar</button>
                                        <button type="submit" className="btn bg-blue ml-3" onClick={this.handleSendData}>Enviar <i className="icon-paperplane ml-2"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default index;
