import React, { Component } from 'react';
import api from '../../../../app/Config/api';
import { Tooltip, } from "@material-ui/core";
import ReactDatatable from '@ashvin27/react-datatable';
import Skeleton from '@material-ui/lab/Skeleton';
import Auxiliar from './auxiliar';
class BLZComprobacion extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "parent_grouping_code",
                text: "Código agrupador",
                className: "parent_grouping_code",
                align: "center",
            },
            {
                key: "numero_de_cuenta",
                text: "Cuenta contable",
                className: "numero_de_cuenta",
                align: "left",
                sortable: true,
            },
            {
                key: "descripcion_cuenta",
                text: "Descripción",
                className: "descripcion_cuenta",
                align: "left",
                sortable: true
            },
            {
                key: "saldo_inicial_deudor",
                text: "S.I. Deudor",
                className: "saldo_inicial_deudor",
                cell: record => {
                    return (
                        <div className="text-right">
                            {
                                (record.type !== undefined && record.type === "foot") ?
                                    (
                                        <>
                                            {
                                                ((record.saldo_inicial_deudor).toFixed(2) === (record.saldo_inicial_acreedor).toFixed(2)) ? (
                                                    <p className="text-success" style={{ fontSize: 14 }}>{(record.saldo_inicial_deudor) === 0 ? '-' : (record.saldo_inicial_deudor).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                                                ) :
                                                    (
                                                        <p className="text-danger" style={{ fontSize: 14 }}>{(record.saldo_inicial_deudor) === 0 ? '-' : (record.saldo_inicial_deudor).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                                                    )
                                            }

                                        </>
                                    ) :
                                    (
                                        <p style={{ fontSize: 14 }}>{(record.saldo_inicial_deudor) === 0 ? '-' : (record.saldo_inicial_deudor).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                                    )
                            }

                        </div>
                    );
                }
            },
            {
                key: "saldo_inicial_acreedor",
                text: "S.I. Acreedor",
                className: "saldo_inicial_acreedor",
                cell: record => {
                    return (
                        <div className="text-right">
                            {
                                (record.type !== undefined && record.type === "foot") ?
                                    (
                                        <>
                                            {
                                                ((record.saldo_inicial_deudor).toFixed(2) === (record.saldo_inicial_acreedor).toFixed(2)) ? (
                                                    <p className="text-success" style={{ fontSize: 14 }}>{(record.saldo_inicial_acreedor) === 0 ? '-' : (record.saldo_inicial_acreedor).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                                                ) :
                                                    (
                                                        <p className="text-danger" style={{ fontSize: 14 }}>{(record.saldo_inicial_acreedor) === 0 ? '-' : (record.saldo_inicial_acreedor).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                                                    )
                                            }

                                        </>
                                    ) :
                                    (
                                        <p style={{ fontSize: 14 }}>{(record.saldo_inicial_acreedor) === 0 ? '-' : (record.saldo_inicial_acreedor).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                                    )
                            }
                        </div>
                    );
                }
            }
            ,
            {
                key: "debe",
                text: "Debe",
                className: "debe",
                cell: record => {
                    return (
                        <div className="text-right">
                            {
                                (record.type !== undefined && record.type === "foot") ?
                                    (
                                        <>
                                            {
                                                ((record.debe).toFixed(2) === (record.haber).toFixed(2)) ? (
                                                    <p className="text-success" style={{ fontSize: 14 }}>{(record.debe) === 0 ? '-' : (record.debe).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                                                ) :
                                                    (
                                                        <p className="text-danger" style={{ fontSize: 14 }}>{(record.debe) === 0 ? '-' : (record.debe).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                                                    )
                                            }

                                        </>
                                    ) :
                                    (
                                        <p style={{ fontSize: 14 }}>{(record.debe) === 0 ? '-' : (record.debe).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                                    )
                            }
                        </div>
                    );
                }
            },
            {
                key: "haber",
                text: "Haber",
                className: "haber",
                cell: record => {
                    return (
                        <div className="text-right">
                            {
                                (record.type !== undefined && record.type === "foot") ?
                                    (
                                        <>
                                            {
                                                ((record.debe).toFixed(2) === (record.haber).toFixed(2)) ? (
                                                    <p className="text-success" style={{ fontSize: 14 }}>{(record.haber) === 0 ? '-' : (record.haber).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                                                ) :
                                                    (
                                                        <p className="text-danger" style={{ fontSize: 14 }}>{(record.haber) === 0 ? '-' : (record.haber).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                                                    )
                                            }

                                        </>
                                    ) :
                                    (
                                        <p style={{ fontSize: 14 }}>{(record.haber) === 0 ? '-' : (record.haber).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                                    )
                            }

                        </div>
                    );
                }
            },
            {
                key: "saldo_final_deudor",
                text: "S.F. Deudor",
                className: "saldo_final_deudor",
                cell: record => {
                    return (
                        <div className="text-right">
                            {
                                (record.type !== undefined && record.type === "foot") ?
                                    (
                                        <>
                                            {
                                                ((record.saldo_final_acreedor).toFixed(2) === (record.saldo_final_deudor).toFixed(2)) ? (
                                                    <p className="text-success" style={{ fontSize: 14 }}>{(record.saldo_final_deudor) === 0 ? '-' : (record.saldo_final_deudor).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                                                ) :
                                                    (
                                                        <p className="text-danger" style={{ fontSize: 14 }}>{(record.saldo_final_deudor) === 0 ? '-' : (record.saldo_final_deudor).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                                                    )
                                            }
                                        </>
                                    ) :
                                    (
                                        <p style={{ fontSize: 14 }}>{(record.saldo_final_deudor) === 0 ? '-' : (record.saldo_final_deudor).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                                    )
                            }

                        </div>
                    );
                }
            },
            {
                key: "saldo_final_acreedor",
                text: "S.F. Acreedor",
                className: "saldo_final_acreedor",
                cell: record => {
                    return (
                        <div className="text-right">
                            {
                                (record.type !== undefined && record.type === "foot") ?
                                    (
                                        <>
                                            {
                                                ((record.saldo_final_acreedor).toFixed(2) === (record.saldo_final_deudor).toFixed(2)) ? (
                                                    <p className="text-success" style={{ fontSize: 14 }}>{(record.saldo_final_acreedor) === 0 ? '-' : (record.saldo_final_acreedor).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                                                ) :
                                                    (
                                                        <p className="text-danger" style={{ fontSize: 14 }}>{(record.saldo_final_acreedor) === 0 ? '-' : (record.saldo_final_acreedor).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                                                    )
                                            }
                                        </>
                                    ) :
                                    (
                                        <p style={{ fontSize: 14 }}>{(record.saldo_final_acreedor) === 0 ? '-' : (record.saldo_final_acreedor).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                                    )
                            }

                        </div>
                    );
                }
            }];
        this.config = {
            key_column: 'numero_de_cuenta',
            page_size: 1000,
            length_menu: [1000, 2000, 5000],
            button: {
                excel: true,
                print: true,
                extra: false,
            },
            "language": {
                "length_menu": "Mostrar _MENU_ registros por página",
                "filter": "Buscar en registros ...",
                "info": "Mostrando _START_ a _END_ de _TOTAL_ entradas",
                "pagination": {
                    "first": "Primero",
                    "previous": "Previa",
                    "next": "próximo",
                    "last": "Última"
                }
            }
        }

        this.state = {
            recorset: [],
            recorsetfilter: [],
            skeleton: true,
            auxiliar: false,
            ctauxiliar: '',
            checkn: 'checked',
            checkf: ''
        }
    }
    componentDidMount() {
        this.getdata();
    }
    getdata = async () => {
        let pareme = JSON.parse(localStorage.getItem("parameters"));
        let result_ = await api.post("getbalanzacomprobacion", pareme);
        let { data } = await result_;
        if (data.info.length > 0) {
            let recorsetfilters = (data.info).filter((k) => (k.debe + k.haber + k.saldo_final_acreedor + k.saldo_final_deudor + k.saldo_inicial_acreedor + k.saldo_inicial_deudor) > 0)
            this.setState({
                recorset: data.info, skeleton: false, recorsetfilter: recorsetfilters
            });
        }
    }
    rowClickedHandler = (event, data, rowIndex) => {
        if (data.type === undefined) {
            if (data.debe > 0 || data.haber > 0) {
                let pareme = JSON.parse(localStorage.getItem("parameters"));
                let parameters = {
                    periodo: pareme.periodo,
                    ejercicio: pareme.ejercicio,
                    empresa_id: pareme.empresa_id,
                    cuenta_contable: data.numero_de_cuenta,
                    naturaleza: data.naturaleza
                }
                this.props.title('Auxiliar  del mes ' + convertName(pareme.periodo) + ' de la cuenta ' + data.numero_de_cuenta);
                localStorage.setItem("dataauxiliar", JSON.stringify(parameters));
                this.setState({ auxiliar: true, ctauxiliar: <Auxiliar title={this.handleReturnEstatus} titleprincipal={this.handleChangeTitle}></Auxiliar> })
            }
        }
    }
    handleChangeTitle = (y) => {
        this.props.title(y);
    }
    handleReturnEstatus = () => {
        let pareme = JSON.parse(localStorage.getItem("parameters"));
        this.props.title('Balanza de comprobación de ' + convertName(pareme.periodo) + ' del ' + pareme.ejercicio);
        this.setState({ auxiliar: false, ctauxiliar: '' });
    }
    handlecheckval = (i) => {
        if (i.target.value === "Normal" && this.state.checkf !== '') {
            this.setState({ skeleton: true, checkn: 'checked', checkf: '' })
            this.getdata();
        }
        else if (i.target.value === "Filter" && this.state.checkn !== '') {
            let filter = this.state.recorsetfilter;
            this.setState({ recorset: filter, checkn: '', checkf: 'checked' })
        }
    }
    render() {
        return (
            <>
                {
                    this.state.skeleton ? (
                        <div className="card">
                            <Skeleton />
                            <Skeleton animation={false} height={400} />
                            <Skeleton animation="wave" />
                        </div>
                    ) : (
                        <>
                            {
                                (this.state.auxiliar) ? (
                                    <>
                                        {this.state.ctauxiliar}
                                    </>
                                ) :
                                    (
                                        <div className="card">
                                            <div className="card-header">
                                                <div className="form-group mb-3 mb-md-2">

                                                    <div className="form-check form-check-inline">
                                                        <label className="form-check-label">
                                                            <div className="uniform-choice">
                                                                <span className={this.state.checkn}>
                                                                    <input type="radio" className="form-check-input-styled" name="radio-inline-left" checked="" data-fouc="" value="Normal" onChange={this.handlecheckval} />
                                                                </span>
                                                            </div>
                                                            Ver todas las cuentas
                                                        </label>
                                                    </div>

                                                    <div className="form-check form-check-inline">
                                                        <label className="form-check-label">
                                                            <div className="uniform-choice">
                                                                <span className={this.state.checkf}>
                                                                    <input type="radio" className="form-check-input-styled" name="radio-inline-left" data-fouc="" value="Filter" onChange={this.handlecheckval} />
                                                                </span>
                                                            </div>
                                                            Filtrar cuentas con importes
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <ReactDatatable
                                                    config={this.config}
                                                    records={(this.state.recorset || [])}
                                                    columns={this.columns}
                                                    onRowClicked={this.rowClickedHandler}
                                                />
                                            </div>
                                        </div>

                                    )
                            }
                        </>
                    )
                }
            </>
        );
    }
}

export default BLZComprobacion;
function convertName(key) {
    switch (key) {
        case "01":
            return "Enero"
            break;
        case "02":
            return "Febrero"
            break;
        case "03":
            return "Marzo"
            break;
        case "04":
            return "Abril"
            break;
        case "05":
            return "Mayo"
            break;
        case "06":
            return "Junio"
            break;
        case "07":
            return "Julio"
            break;
        case "08":
            return "Agosto"
            break;
        case "09":
            return "Septiembre"
            break;
        case "10":
            return "Octubre"
            break;
        case "11":
            return "Noviembre"
            break;
        case "12":
            return "Diciembre"
            break;

    }
}