import React, { Component } from 'react'
import ReactJson from 'react-json-view';
import api from '../../Config/apifiles'
import { Tooltip, } from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
import { PacmanLoader } from "react-spinners";
export class Income_Tax_Lease extends Component {
    constructor(props) {
        super(props)
        let objects = JSON.parse(localStorage.getItem("datacompanyall"));
        this.state = {
            data: [],
            rfc: objects.rfc,
            empresa_id: objects.id,
            title: 'Comparativo de isr de arrendamiento anuales: Facturados vs Declarados',
            loading: true,
            viewheader: true,
            viewleasexml: false,
            data_details: [],
            data_lease_xml: []
        }
    }
    componentDidMount() {
        this.getdata();
    }
    getdata = async () => {
        await api.post("compare-isr-arrendamiento", { empresa_id: this.state.empresa_id }).then((x) => {
            const sumfacturado = (x.data).reduce(function (total, objeto) {
                return total + objeto.facturado;
            }, 0);
            const sumdeclarado = (x.data).reduce(function (total, objeto) {
                return total + objeto.declaradado;
            }, 0);
            this.setState({
                data: x.data,
                loading: false,
                total_retencion_isr_head_factura: sumfacturado,
                total_diferencia_retencion_isr_head: (sumfacturado - sumdeclarado),
                total_retencion_isr_head_declaracion: sumdeclarado,
            })
        })
    }
    viewdetailsbody = async (d) => {
        const sumfacturado = (d.details).reduce(function (total, objeto) {
            return total + objeto.facturado;
        }, 0);
        const sumdeclarado = (d.details).reduce(function (total, objeto) {
            return total + objeto.declarado;
        }, 0);
        this.setState({
            viewbody: true,
            viewheader: false,
            title: "Comparativo de isr de arrendamiento mensuales: Facturados vs Declarados",
            data_details: d.details,
            total_retencion_isr_body_factura: sumfacturado,
            total_retencion_isr_body_declaracion: sumdeclarado,
            total_diferencia_retencion_isr_body: (sumfacturado - sumdeclarado),
        })
    }
    handleviewpdf = (dir) => {
        window.$("#modallargepdf").modal("show");
        this.setState({
            loadingfirts: true
        })
        setTimeout(() => {
            let url = 'https://e-xpertum.com.mx/adminfile/api/displaypdf?query={"directorio":"' + dir + '"}';
            this.setState({
                linkpdf: url,
                loadingfirts: false
            });

        }, 1000);

    }
    handleviewleasexml = async (d) => {
        this.setState({
            viewbody: false,
            viewheader: false,
            loading: true,
        })

        await api.post("get-list-lease-xml", { empresa_id: this.state.empresa_id, exercise: d.year, period: d.period }).then(x => {
            setTimeout(() => {
                this.setState({
                    title: "Efecto fiscal y contable de los CFDIs recibidos en " + d.month + " de " + d.year,
                    viewbody: false,
                    viewheader: false,
                    viewleasexml: true,
                    data_lease_xml: x.data,
                    loading: false,
                })
            }, 300);

        })
    }
    handleLoadXML = async (t) => {
        window.$("#viewxmlcfdi").modal("show");
        let _data_ = await api.post('genera_xml_cfdi', { id: t.recived_id, tipo: 'RECIBIDO' });
        this.setState({
            uuid: t.uuid,
            cfdi: <ReactJson src={JSON.parse(_data_.data)} enableClipboard={false} theme={"monokai"} displayDataTypes={false} displayObjectSize={false} />
        })

    }
    viewpdfcfdi_ = async (t) => {
        window.$('#viewpdfcfdi').modal('show');
        this.setState({
            loadingpdf: true
        })
        let _link_ = "";
        if (t.tipo_de_comprobante === "N")
            _link_ = 'https://e-xpertum.com.mx/adminfile/api/genera-pdf-cfdi-nomina/' + t.recived_id + '/nomina';
        else
            _link_ = 'https://e-xpertum.com.mx/adminfile/api/genera_pdf_cfdi/' + t.recived_id + '/RECIBIDO&' + this.state.rfc

        this.setState({
            link: _link_,
            uuid: t.uuid
        });
    }
    handleLoading = async () => {
        this.setState({
            loadingpdf: false
        })
    }
    render() {
        return (
            <div className="content pt-0">
                <div className="card">
                    <div className="card-header header-elements-inline">
                        <h6 className="card-title">{this.state.title}</h6>
                        {(this.state.viewbody) ? (
                            <div className="d-flex align-items-center" style={{ cursor: 'pointer' }} onClick={() => this.setState({
                                viewbody: false,
                                title: 'Comparativo de isr de arrendamiento anuales: Facturados vs Declarados',
                                viewheader: true,
                                viewleasexml: false
                            })}>
                                <i className="icon-arrow-left16 mr-3 icon-2x text-primary"></i>
                                <div className="text-primary">Inicio</div>
                            </div>
                        ) : null}
                        {(this.state.viewleasexml) ? (
                            <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}
                                onClick={() => this.setState({
                                    title: "Comparativo de isr de arrendamiento mensuales: Facturados vs Declarados",
                                    viewbody: true,
                                    viewheader: false,
                                    viewleasexml: false
                                })}>
                                <i className="icon-arrow-left16 mr-3 icon-2x text-primary"></i>
                                <div className="text-primary">Detalle</div>
                            </div>
                        ) : null}
                    </div>
                    <div className="card-body">
                        {
                            this.state.loading ? (
                                <div>
                                    <Skeleton variant="text" />
                                    <div style={{
                                        margin: 40,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                        <PacmanLoader color={"#ff7043"} loading={this.state.loading} size={25} margin={2} />
                                    </div>
                                    <Skeleton variant="circle" animation="wave" width={40} height={40} />
                                    <Skeleton variant="rect" animation="wave" width={"100%"} height={118} />
                                </div>
                            ) : (
                                <>
                                    {
                                        (this.state.viewheader) ?
                                            (
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ textAlign: 'center' }}>Ejercicio</th>
                                                            <th style={{ textAlign: 'center' }}>ISR arrendamiento facturados</th>
                                                            <th style={{ textAlign: 'center' }}>ISR arrendamiento declarados</th>
                                                            <th style={{ textAlign: 'center' }}>Diferencias</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            (this.state.data || []).map((h, l) => (
                                                                <tr key={l}>
                                                                    <td>
                                                                        <Tooltip title="Clic para ver comparativos mensuales" placement="top" arrow>
                                                                            <button onClick={() => this.viewdetailsbody(h)} type="button" className="btn btn-outline-primary btn-lg" style={{ width: '100%' }}><i className="icon-unlink mr-2"></i>{h.year}</button>
                                                                        </Tooltip>
                                                                    </td>
                                                                    <td style={{ textAlign: 'right' }}>{h.facturado.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                                    <td style={{ textAlign: 'right' }}>{h.declaradado.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>

                                                                    <td style={{ textAlign: 'right' }}>
                                                                        {
                                                                            ((h.facturado - h.declaradado).toFixed(0) > 0) ?
                                                                                (
                                                                                    <span className="text-danger" style={{ fontSize: 12, textAlign: 'right' }}>
                                                                                        {(h.facturado - h.declaradado).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                    </span>

                                                                                ) : ((h.facturado - h.declaradado) === 0 ? '' : (<span className="text-success" style={{ fontSize: 12, textAlign: 'right' }}>{((h.facturado - h.declaradado) * -1).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>))
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td style={{ textAlign: 'right' }}>Totales: </td>
                                                            <td style={{ textAlign: 'right' }}>{this.state.total_retencion_isr_head_factura.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                            <td style={{ textAlign: 'right' }}>{this.state.total_retencion_isr_head_declaracion.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                            <td style={{ textAlign: 'right' }}>
                                                                {this.state.total_diferencia_retencion_isr_head === 0 ? '' : (
                                                                    <>
                                                                        {
                                                                            (this.state.total_diferencia_retencion_isr_head < 0) ? (
                                                                                <button className="btn bg-transparent text-danger-100 border-success ml-1" style={{ textAlign: 'right', width: '100%' }}>
                                                                                    <span className='text-success'>{(this.state.total_diferencia_retencion_isr_head * -1).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>
                                                                                </button>
                                                                            ) : (
                                                                                <button className="btn bg-transparent text-danger-100 border-warning ml-1" style={{ textAlign: 'right', width: '100%' }}>
                                                                                    <span className='text-danger'>{this.state.total_diferencia_retencion_isr_head.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>
                                                                                </button>
                                                                            )
                                                                        }
                                                                    </>
                                                                )
                                                                }
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            ) : null
                                    }
                                </>
                            )
                        }
                        {
                            (this.state.viewbody) ?
                                (
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: 'center' }}>Mes</th>
                                                <th style={{ textAlign: 'center' }}>Efecto Fiscal en Ingresos</th>
                                                <th style={{ textAlign: 'center' }}>Ingresos declarados</th>
                                                <th style={{ textAlign: 'center' }}>Diferencias</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (this.state.data_details || []).map((h, l) => (
                                                    <tr key={l}>
                                                        <td style={{ textAlign: 'center' }}>
                                                            <Tooltip title="Clic para ver las facturas de arrendamiento" placement="top" arrow>
                                                                <button type="button" className="btn btn-outline-primary btn-lg" style={{ width: '100%' }} onClick={() => this.handleviewleasexml(h)}>
                                                                    <i className="icon-file-xml mr-2" style={{ float: 'left' }}></i> {h.month + " "}  {h.year}
                                                                </button>
                                                            </Tooltip>
                                                        </td>
                                                        <td style={{ textAlign: 'right' }}>{h.facturado.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                        <td style={{ textAlign: 'right' }}>
                                                            {h.directorio !== "" ? (
                                                                <Tooltip title="Clic para ver las declaraciones de arrendamiento" placement="top" arrow>
                                                                    <button type="button" className="btn btn-outline-primary btn-lg" style={{ width: '100%' }} onClick={() => this.handleviewpdf(h.directorio)}>
                                                                        <i className="icon-stack mr-2"></i>{h.declarado.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                    </button>
                                                                </Tooltip>
                                                            ) : <Tooltip title="No existe declaracion de arrendamiento" placement="top" arrow>
                                                                <button type="button" className="btn btn-outline-danger btn-lg" style={{ width: '100%' }}>
                                                                    <i className="icon-stack mr-2"></i>{h.declarado.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                </button>
                                                            </Tooltip>
                                                            }

                                                        </td>
                                                        <td style={{ textAlign: 'right' }}>
                                                            {
                                                                ((h.facturado - h.declarado).toFixed(0) > 0) ?
                                                                    (
                                                                        <span className="text-danger" style={{ fontSize: 12, textAlign: 'right' }}>{((h.facturado - h.declarado)).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>
                                                                    ) : ((h.facturado - h.declarado) === 0 ? '' : (<span className="text-success" style={{ fontSize: 12, textAlign: 'right' }}>{(h.facturado - h.declarado).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>))
                                                            }
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                        <tfoot>
                                            <tr>

                                                <td style={{ textAlign: 'right' }}>Totales: </td>
                                                <td style={{ textAlign: 'right' }}>{this.state.total_retencion_isr_body_factura.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                <td style={{ textAlign: 'right' }}>{this.state.total_retencion_isr_body_declaracion.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                <td>{this.state.total_diferencia_retencion_isr_body === 0 ? '' : (
                                                    <>
                                                        {
                                                            (this.state.total_diferencia_retencion_isr_body < 0) ? (
                                                                <button className="btn bg-transparent text-danger-100 border-success ml-1" style={{ textAlign: 'right', width: '100%' }}>
                                                                    <span className='text-success'>{(this.state.total_diferencia_retencion_isr_body * -1).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>
                                                                </button>
                                                            ) : (
                                                                <button className="btn bg-transparent text-danger-100 border-warning ml-1" style={{ textAlign: 'right', width: '100%' }}>
                                                                    <span className='text-danger'>{this.state.total_diferencia_retencion_isr_body.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>
                                                                </button>
                                                            )
                                                        }
                                                    </>
                                                )}
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                ) : null
                        }
                        {
                            (this.state.viewleasexml) ? (
                                <>
                                    <div className="content" style={{ width: "100%" }}>
                                        <div className='wrapper'>
                                            <table className='table' style={{ width: "1500px" }}>
                                                <thead>
                                                    <tr>
                                                        <th className='text-center'>Fecha</th>
                                                        <th className='text-center'>Método de pago</th>
                                                        <th>Nombre emisor</th>
                                                        <th>Rfc emisor</th>
                                                        <th className='text-center'>Retencion de ISR Arrendamiento</th>
                                                        <th className='text-center'>UUID</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        (this.state.data_lease_xml || []).map((sw, index) => (
                                                            <tr>
                                                                <td>{sw.fecha}</td>
                                                                <td>{sw.metodo_pago}</td>
                                                                <td>{getRfcName(sw.emisor, "")}</td>
                                                                <td>{getRfcName(sw.emisor, "RFC")}</td>
                                                                <td className='text-right'>{parseFloat(sw.retencion_isr).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                                <td>{sw.uuid}</td>
                                                                <td>
                                                                    <button className="btn btn-link" style={{ cursor: 'pointer' }} onClick={() => this.viewpdfcfdi_(sw)}><Tooltip title="Click para ver cfdi completo" placement="top" arrow><span className="badge badge-flat  text-success"><i className="icon-file-pdf"></i></span></Tooltip></button>
                                                                    &nbsp;
                                                                    <button className="btn btn-link" style={{ cursor: 'pointer' }} onClick={() => this.handleLoadXML(sw)}><Tooltip title="Click para ver el xml completo" placement="top" arrow><span className="badge badge-flat  text-success"><i className="icon-file-xml"></i></span></Tooltip></button>

                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </>
                            ) : null
                        }
                        <div id="modallargepdf" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                            <div className="modal-dialog modal-full">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Acuse del pago provisional</h5>
                                        <button type="button" className="close" data-dismiss="modal" onClick={() => this.setState({ linkpdf: '', loadingfirts: true })}>×</button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="embed-container">
                                            {
                                                (this.state.loadingfirts) ?
                                                    (
                                                        <div>
                                                            <Skeleton variant="text" />
                                                            <Skeleton variant="circle" animation="wave" width={40} height={40} />
                                                            <Skeleton variant="rect" animation="wave" width={510} height={118} />
                                                        </div>
                                                    ) : null
                                            }
                                            <iframe src={this.state.linkpdf} onLoad={this.handleLoading} title="Reporte de ingresos"
                                                width="100%"
                                                frameBorder="0" allowFullScreen
                                            >
                                            </iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="viewpdfcfdi" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                            <div className="modal-dialog modal-full">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">{this.state.uuid}</h5>
                                        <button type="button" className="close" data-dismiss="modal" onClick={() => this.setState({ link: '' })}>×</button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="embed-container">
                                            {
                                                (this.state.loadingpdf) ?
                                                    (
                                                        <div>
                                                            <Skeleton variant="text" />
                                                            <Skeleton variant="circle" animation="wave" width={40} height={40} />
                                                            <Skeleton variant="rect" animation="wave" width={510} height={118} />
                                                        </div>
                                                    ) : null
                                            }
                                            <iframe src={this.state.link} onLoad={this.handleLoading} title='Ver documentos pdf'
                                                width="100%"
                                                frameBorder="0" allowFullScreen
                                            >
                                            </iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="viewxmlcfdi" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                            <div className="modal-dialog modal-full">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">{this.state.uuid}</h5>
                                        <button type="button" className="close" data-dismiss="modal" onClick={() => this.setState({ link: '' })}>×</button>
                                    </div>
                                    <div className="modal-body">
                                        <div>
                                            {
                                                (this.state.loading) ?
                                                    (
                                                        <div>
                                                            <Skeleton variant="text" />
                                                            <Skeleton variant="circle" animation="wave" width={40} height={40} />
                                                            <Skeleton variant="rect" animation="wave" width={510} height={118} />
                                                        </div>
                                                    ) : null
                                            }
                                            {this.state.cfdi}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Income_Tax_Lease

function getRfcName(dt, type) {
    let d = JSON.parse(dt);
    if (type === "RFC") {

        return d["@Rfc"]
    }
    else {
        return d["@Nombre"]
    }
}