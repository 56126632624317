import React, { Component } from 'react'
import { FormControl, InputAdornment, OutlinedInput } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';

/*******
 * CONFIG CONEXION
 */
///import api from '../app/Config/api';
//import apiUpload from '../app/Config/apifiles';
import invoce from '../app/Config/audit-data';
/**************
 * NOTIFICATIONS
 */

//import './style/table.css';
import Skeleton from '@material-ui/lab/Skeleton';
import {
    ClimbingBoxLoader,
    ClockLoader, ClipLoader
} from "react-spinners";

class UploadEfirma extends Component {
    constructor(props) {
        super(props);
        const data = {
            id: 200
        } //JSON.parse(localStorage.getItem("datavalidate"));
        const _data_ = {
            perfil: '001'
        } //JSON.parse(localStorage.getItem("id_token"));
        this.state = {
            filercer: '',
            filekey: '',
            empresa_id: data.id,
            servicio_id: '',
            namecer: 'Certificado (.cer)',
            namekey: 'Clave privada (.key)',
            password: '',
            showPassword: false,
            isvalid: false,
            directorycer: '',
            directorykey: '',
            messagesuccess: false,
            messageerror: false,
            alert: '',
            show: false,
            rfc: '',
            data: [],
            delete: false,
            delete_id: 0,
            pathurl: '',
            perfil: _data_.perfil,
            pfxnombre: '',
            pfxrfc: '',
            pfxcaducidad: '',
            pfxstatus: '',
            viewlist: false,
            viewadd: false,
            loginx: "",
            params: '',
            result: {},
            skeleton: false,
            isvalidater: false,
            isvalidates: false,
            view_e_firma: true,
            messageexist: '',
            report_: false,
            fstartvigencia: '',
            fendvigencia: '',
            number_access: 0
        }
    }
    handleChangeFileCer = async (e) => {
        this.setState({ delete: true });
        const file = e.target.files[0];
        this.setState({ namecer: file.name, filercer: file });
        console.log(file)
        setTimeout(async () => {
            const formData = new FormData();
            formData.append('file', file)
            formData.append('id_', this.state.servicio_id)
            let _dt_ = await invoce.post('subirdocumentsvalidador', formData);
            const { path, url, status, name_, rfc, end_date } = await _dt_.data;
            if (status === 200) {
                this.setState({
                    directorycer: url,
                    viewlist: true,
                    pfxnombre: name_,
                    pfxrfc: rfc,
                    pfxcaducidad: end_date,
                    rfc: rfc,
                    pathurl: path,
                    delete: false
                });
            }
        }, 1000);

    }
    handleChangeFileKey = async (e) => {
        const file = e.target.files[0];
        this.setState({ namekey: file.name, filekey: file });
        const formData = new FormData();
        formData.append('file', file)
        formData.append('id_', this.state.servicio_id)
        formData.append('path', this.state.pathurl)
        let _result_ = await invoce.post('upload-file-key', formData);
        const { status, url } = await _result_.data;
        if (status === 200) {
            this.setState({ directorykey: url });
        }

    }
    handleChange = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };

    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };
    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    submitSave = async () => {
        this.setState({
            skeleton: true
        });
        let _send_valida_ = await invoce.post('save-and-validate-efirma', {
            name: this.state.pfxnombre,
            rfc: this.state.pfxrfc,
            password: this.state.password,
            directorykey: this.state.directorykey,
            directorycer: this.state.directorycer,
            id_: this.state.servicio_id,
            fecha_caducidad: this.state.pfxcaducidad
        })
        const { status, message, result_ } = await _send_valida_.data;
        if (status === 200) {
            if (result_.data.status === "EXIST") {
                this.setState({
                    messagesuccess: false,
                    messageerror: false,
                    isvalid: false,
                    skeleton: false,
                    isvalidater: true,
                    messageexist: 'Ya existe la empresa, favor de registrar otra!.'
                });
                setTimeout(() => {
                    this.setState({
                        isvalidater: false,
                        messageexist: ''
                    })
                }, 3000);
            } else {
                /*this.setState({
                    view_e_firma: true,
                    messagesuccess: false,
                    messageerror: false,
                    isvalid: false,
                    skeleton: false,
                    isvalidates: true,
                    messageexist: 'Se ha registrado correctamente!.'
                });
                setTimeout(() => {
                    this.setState({
                        isvalidates: false,
                        messageexist: ''
                    })
                }, 3000);*/
                let cont = 0
                const result_xxxx = setInterval(() => {
                    if (cont === 3) {
                        clearInterval(result_xxxx);
                        this.handlevalidateefirma();
                        setTimeout(() => {
                            this.handlegetalldata();
                        }, 9000);
                    }
                    console.log(cont)
                    cont++;
                }, 5000);

            }
        }

    }

    handleCloseMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ show: false });
    };
    componentDidMount() {

        this.setState({
            params: this.props.match.params.data
        })
        setTimeout(() => {
            this.handlegetalldata();
        }, 1000);
    }
    handlegetalldata = async () => {
        let result_ = await invoce.post('auth-select-demo', { params: this.state.params });
        let _ = await result_.data;
        this.setState({ result: _, servicio_id: _.id_, number_access: _.number_access });
        let data_company = await invoce.post('list-company-filter', { id_: _.id_ });
        let { status, data } = await data_company.data;
        if (status === 200 && data != undefined) {
            this.setState({
                /// view_e_firma: false,
                viewlist: true,
                pfxnombre: data.name,
                pfxrfc: data.rfc,
                pfxcaducidad: data.vigencia,
                view_e_firma: true,
                report_: (data.rep_status == "NOT" ? false : true),
                fendvigencia: data.ff,
                fstartvigencia: data.fi
            });
            this.handlevalidateefirma();
        } else {
            this.setState({ view_e_firma: false });
        }
    }

    handleDeleteRegistro = (i) => {
        this.setState({ delete: true, delete_id: i });
    }
    handlevalidateefirma = async () => {
        var params = { id_: this.state.servicio_id }
        let _ap = await invoce.post('validate-e-firma-with-sat', params);
        const { status, message } = await _ap.data;
        ///console.log('--------------------------------------------------', _ap.data);
        if (status === 200) {
            this.setState({
                messagesuccess: true,
                isvalid: true,
                textm: message
            })
        }
        else {
            this.setState({
                messagesuccess: false,
                isvalid: true,
                textm: message,
                messageerror: true
            });

        }
    }
    handleCloseDeleteRegistro = async () => {
        if (this.state.report_ === true) {
            let _up = await invoce.post("update-number-access-user-report",
                { id_: this.state.servicio_id });
        }
    }

    render() {
        const { nombre_completo, id_, name_user } = this.state.result;
        return (
            <>
                <div className="navbar navbar-expand-lg navbar-light navbar-static">
                    <div className="d-flex flex-1 d-lg-none">
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-mobile">
                            <i className="icon-paragraph-justify3" />
                        </button>
                        <button className="navbar-toggler sidebar-mobile-main-toggle" type="button">
                            <i className="icon-transmission" />
                        </button>
                    </div>
                    <div className="navbar-brand text-center text-lg-left">
                        <a href="index.html" className="d-inline-block">
                            <img src="logos/logo_expertumb.png" alt='' />
                        </a>
                    </div>
                    <div className="collapse navbar-collapse order-2 order-lg-1" id="navbar-mobile">
                        <span className="badge badge-success my-3 my-lg-0 ml-lg-3">Online</span>
                    </div>
                    <ul className="navbar-nav flex-row order-1 order-lg-2 flex-1 flex-lg-0 justify-content-end align-items-center">
                        <li className="nav-item nav-item-dropdown-lg dropdown dropdown-user h-100">
                            <a href="#" rel='' className="navbar-nav-link navbar-nav-link-toggler dropdown-toggle d-inline-flex align-items-center h-100" data-toggle="dropdown">
                                <img src="https://e-xpertum.com.mx/global_assets_dark/images/face11.png" className="rounded-pill" height={34} />
                                <span className="d-none d-lg-inline-block ml-2">{name_user}</span>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right">
                                <div className="dropdown-divider" />
                                <a href="#" className="dropdown-item"><i className="icon-switch2" /> Logout</a>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="page-content">
                    <div className="content-wrapper">
                        <div className="content-inner">
                            <div className='content pt-4'>
                                <div className="card">
                                    <div className="card-header bg-transparent border-bottom">
                                        <h5 className="mb-0 font-weight-semibold">
                                            Bienvenido a e-xpertum: {nombre_completo}
                                        </h5>
                                    </div>
                                    <div className="card-body">
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                <div className="d-lg-flex">
                                                    <ul className="nav nav-tabs nav-tabs-vertical flex-column mr-lg-3 wmin-lg-200 mb-lg-0 border-bottom-0">
                                                        <li className="nav-item"><a href="#vertical-left-tab1" className="nav-link active" data-toggle="tab"><i className="icon-certificate mr-2" />Credenciales</a></li>
                                                        <li className="nav-item"><a href="#vertical-left-tab2" className="nav-link" data-toggle="tab" onClick={() => this.handleCloseDeleteRegistro(890)}><i className="icon-file-pdf mr-2" /> Reporte</a></li>

                                                        <li className="nav-item"><a href="#vertical-left-tab3" className="nav-link" data-toggle="tab"><i className="icon-cogs mr-2" /> Licencia</a></li>
                                                    </ul>
                                                    <div className="tab-content flex-lg-fill">
                                                        <div className="tab-pane fade active show" id="vertical-left-tab1">

                                                            <div className='card'>
                                                                <div className="card-header">
                                                                    <h6 className="card-title">
                                                                        Para finalizar el proceso de registro y la funcionalidad del servicio seleccionado, registre la información requeridad.
                                                                    </h6>
                                                                    <br></br>
                                                                    <h6 className="card-title text-center">
                                                                        Registra tu credencial del Servicio de Administración Tributaria (SAT)
                                                                    </h6>
                                                                </div>
                                                                <div className="card-body">
                                                                    {
                                                                        this.state.delete ? (
                                                                            <div className="card">
                                                                                <Skeleton />
                                                                                <div style={{
                                                                                    margin: 40,
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'center',
                                                                                }}>
                                                                                    <ClimbingBoxLoader color={"#ff7043"} loading={this.state.delete} size={20} margin={2} />
                                                                                </div>
                                                                                <Skeleton variant="rect" animation="wave" width={"100%"} />
                                                                                <Skeleton animation="wave" />
                                                                            </div>
                                                                        ) : null
                                                                    }
                                                                    {(!this.state.view_e_firma) ? (
                                                                        <>
                                                                            {
                                                                                (this.state.skeleton === true && this.state.isvalid == false) ? (
                                                                                    <div className="card">
                                                                                        <Skeleton />
                                                                                        <div style={{
                                                                                            margin: 40,
                                                                                            display: 'flex',
                                                                                            alignItems: 'center',
                                                                                            justifyContent: 'center',
                                                                                        }}>
                                                                                            <ClockLoader color={"#ff7043"} loading={this.state.skeleton} size={50} margin={2} />
                                                                                        </div>
                                                                                        <Skeleton variant="rect" animation="wave" width={"100%"} />
                                                                                        <Skeleton animation="wave" />
                                                                                    </div>
                                                                                ) : ((this.state.skeleton === true && this.state.isvalid === true) ? (
                                                                                    <div className="card">
                                                                                        <Skeleton />
                                                                                        <div style={{
                                                                                            margin: 40,
                                                                                            display: 'flex',
                                                                                            alignItems: 'center',
                                                                                            justifyContent: 'center',
                                                                                        }}>

                                                                                            {
                                                                                                (this.state.messagesuccess === true && this.state.messageerror === false) ? (
                                                                                                    <>
                                                                                                        <ClockLoader color={"#72c076"} loading={this.state.skeleton} size={50} margin={2} />
                                                                                                        <div className="alert alert-success alert-styled-right alert-arrow-right alert-dismissible"
                                                                                                            style={{ position: 'absolute', marginTop: 150 }}>
                                                                                                            <span className="font-weight-semibold">Validación correcta!</span> {this.state.textm}.
                                                                                                        </div>
                                                                                                        <div style={{ height: 50 }}></div>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    (this.state.messagesuccess === false && this.state.messageerror === true) ? (
                                                                                                        <>
                                                                                                            <ClockLoader color={"#d05653"} loading={this.state.skeleton} size={50} margin={2} /><br></br>
                                                                                                            <div className="alert alert-danger alert-styled-right alert-dismissible" style={{ position: 'absolute', marginTop: 150 }}>
                                                                                                                <span className="font-weight-semibold">Oh no!</span> {this.state.textm}.
                                                                                                            </div>
                                                                                                            <div style={{ height: 50 }}></div>
                                                                                                        </>
                                                                                                    ) :
                                                                                                        <ClockLoader color={"#ff7043"} loading={this.state.skeleton} size={50} margin={2} />
                                                                                                )
                                                                                            }
                                                                                        </div>
                                                                                        <Skeleton variant="rect" animation="wave" width={"100%"} />
                                                                                        <Skeleton animation="wave" />
                                                                                    </div>
                                                                                ) : (
                                                                                    (!this.state.delete) ? (
                                                                                        <fieldset className="mb-3">

                                                                                            <div className="form-group row">
                                                                                                <div className="col-lg-12">
                                                                                                    <div className="uniform-uploader">
                                                                                                        <input type="file" className="form-control-uniform-custom" onChange={this.handleChangeFileCer} accept=".cer" />
                                                                                                        <span className="filename" style={{ userSelect: "none" }}>{this.state.namecer}</span>
                                                                                                        <span className={(this.state.directorycer != '') ? "action btn bg-success" : "action btn bg-warning"} style={{ userSelect: "none" }}>Buscar</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="form-group row">
                                                                                                <div className="col-lg-12">
                                                                                                    <div className="uniform-uploader">
                                                                                                        <input type="file" className="form-control-uniform-custom" onChange={this.handleChangeFileKey} accept=".key" />
                                                                                                        <span className="filename" style={{ userSelect: "none" }}> {this.state.namekey}</span>
                                                                                                        <span className={(this.state.directorykey != '') ? "action btn bg-success" : "action btn bg-warning"} style={{ userSelect: "none" }}>Buscar</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="form-group row">
                                                                                                <div className="col-lg-12">
                                                                                                    <FormControl variant="outlined" margin="dense" fullWidth>
                                                                                                        <OutlinedInput autoComplete="off" placeholder='Contraseña de la clave privada'
                                                                                                            id="outlined-adornment-password"
                                                                                                            type={this.state.showPassword ? 'text' : 'password'}
                                                                                                            value={this.state.password}
                                                                                                            onChange={this.handleChange('password')}
                                                                                                            endAdornment={
                                                                                                                <InputAdornment position="end">
                                                                                                                    <IconButton
                                                                                                                        aria-label="toggle password visibility"
                                                                                                                        onClick={this.handleClickShowPassword}
                                                                                                                        onMouseDown={this.handleMouseDownPassword}
                                                                                                                        edge="end">
                                                                                                                        {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                                                                                    </IconButton>
                                                                                                                </InputAdornment>
                                                                                                            }
                                                                                                            labelWidth={200}
                                                                                                        />
                                                                                                    </FormControl>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="text-right">
                                                                                                <button type="submit" className="btn btn-primary" onClick={this.submitSave}>Guardar<i className="icon-paperplane ml-2"></i></button>
                                                                                            </div>
                                                                                        </fieldset>
                                                                                    ) : null

                                                                                ))
                                                                            }
                                                                        </>
                                                                    ) : null
                                                                    }
                                                                    {
                                                                        (this.state.viewlist) ? (
                                                                            <div className='card'>
                                                                                <div className='card-body'>
                                                                                    {(this.state.isvalidater) ? (
                                                                                        <div className="alert alert-danger alert-styled-right alert-dismissible">
                                                                                            <span className="font-weight-semibold">Oh No!</span> {this.state.messageexist}.
                                                                                        </div>) : null}

                                                                                    {(this.state.isvalidates) ? (<div className="alert alert-success alert-styled-right alert-arrow-right alert-dismissible">
                                                                                        <span className="font-weight-semibold">Excelente!</span> {this.state.messageexist} <br></br> Dentro de las próximas 24 hrs, el resultado de la auditoría de cumplimiento fiscal (de acuerdo al servicio seleccionado).
                                                                                    </div>) : null}
                                                                                    <table className="table">
                                                                                        <caption>El usuario recibirá dentro de las próximas 24 hrs, el resultado de la auditoría de cumplimiento fiscal ( de acuerdo al servicio seleccionado) </caption>
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td>Razon social:</td>
                                                                                                <td>{this.state.pfxnombre}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>RFC</td>
                                                                                                <td>{this.state.pfxrfc}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Fecha caducidad</td>
                                                                                                <td>{this.state.pfxcaducidad}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td colSpan={2}>
                                                                                                    {
                                                                                                        (this.state.messagesuccess === true && this.state.messageerror === false) ? (
                                                                                                            <>
                                                                                                                <span className="badge badge-success d-block"><span className="font-weight-semibold">Validación correcta!</span> {this.state.textm}.</span>
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            (this.state.messagesuccess === false && this.state.messageerror === true) ? (
                                                                                                                <>
                                                                                                                    <span className="badge badge-danger d-block"><span className="font-weight-semibold">Oh No!</span> {this.state.textm}.</span>
                                                                                                                </>

                                                                                                            ) : null
                                                                                                        )
                                                                                                    }
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        ) : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="vertical-left-tab2">
                                                            <div className="card">
                                                                <div className="card-header bg-transparent border-bottom header-elements-inline">
                                                                    <h6 className="card-title text-center">Reporte especial de cumplimiento fiscal</h6>
                                                                </div>
                                                                <div className="card-body">
                                                                    {
                                                                        (!this.state.report_) ? (
                                                                            <div className="card">
                                                                                <table style={{ width: "100%" }}>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td colSpan={3}><Skeleton animation="wave" width={"100%"} /></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td colSpan={3}><Skeleton animation="wave" width={"100%"} /></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td><Skeleton animation="wave" width={"100%"} /></td>
                                                                                            <td style={{
                                                                                                margin: 'auto', textAlign: 'center',
                                                                                                padding: 10, width: "10%"
                                                                                            }}>
                                                                                                <ClipLoader color={"#ff7043"} loading={true} size={50} />
                                                                                            </td>
                                                                                            <td><Skeleton animation="wave" width={"100%"} /></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td colSpan={3}><Skeleton animation="wave" width={"100%"} /></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td colSpan={3}><Skeleton animation="wave" width={"100%"} /></td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        ) : (
                                                                            <>
                                                                                {
                                                                                    this.state.number_access < 2 ? (
                                                                                        <div className='containeriframe'>
                                                                                            <iframe className='responsiveiframeall scroll scroll1' title='Ver todos documentos' src="" frameBorder={0} allowFullScreen />
                                                                                        </div>
                                                                                    ) : <span className="badge badge-flat border-danger-100 text-danger d-block">Para poder seguir viendo más reportes, deberá adquir el servicio.</span>

                                                                                }

                                                                            </>

                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="vertical-left-tab3">
                                                            <div className="card">
                                                                <div className="card-header">
                                                                    <h5 className="card-title">
                                                                        <a href="#" className="text-body">
                                                                            <i className="icon-statistics mr-2"></i>
                                                                            Información de la licencia
                                                                        </a>
                                                                    </h5>
                                                                </div>

                                                                <div className="card-body">
                                                                    <p className="mb-3 font-weight-light">Una vez generado el reporte, el usuario sólo podrá ingresar solo una vez.</p>
                                                                    {(this.state.viewlist) ? (
                                                                        <ul className="list list-unstyled mb-0">
                                                                            <li>
                                                                                <i className="icon-checkmark-circle text-success mr-2"></i>
                                                                                Versión: Reporte especial de cumplimiento fiscal
                                                                            </li>
                                                                            <li>
                                                                                <i className="icon-checkmark-circle text-success mr-2"></i>
                                                                                Fecha de registro: {toTitleCase(String(this.state.fstartvigencia))}
                                                                            </li>
                                                                            <li>
                                                                                <i className="icon-checkmark-circle text-success mr-2"></i>
                                                                                Fecha de cancelación: {toTitleCase(String(this.state.fendvigencia))}
                                                                            </li>
                                                                        </ul>
                                                                    ) : (
                                                                        <ul className="list list-unstyled mb-0">
                                                                            <li>
                                                                                <i className="icon-checkmark-circle text-success mr-2"></i>
                                                                                Versión: Reporte especial de cumplimiento fiscal
                                                                            </li>
                                                                            <li>
                                                                                <i className="icon-checkmark-circle text-success mr-2"></i>
                                                                                Fecha de registro: <Skeleton animation="wave" width={"50%"} />
                                                                            </li>
                                                                            <li>
                                                                                <i className="icon-checkmark-circle text-success mr-2"></i>
                                                                                Fecha de cancelación: <Skeleton animation="wave" width={"50%"} />
                                                                            </li>
                                                                        </ul>
                                                                    )}

                                                                </div>

                                                                <div className="card-footer border-light text-center">
                                                                    <a href={undefined} className="btn btn-warning">
                                                                        <i className="icon-bubbles8 mr-2"></i>
                                                                        Comprar el servicio
                                                                    </a>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="navbar navbar-expand-lg navbar-light">
                    <div className="text-center d-lg-none w-100">
                        <button type="button" className="navbar-toggler dropdown-toggle" data-toggle="collapse" data-target="#navbar-footer">
                            <i className="icon-unfold mr-2"></i>
                            Pie de página
                        </button>
                    </div>
                    <div className="navbar-collapse collapse" id="navbar-footer">
                        <span className="navbar-text"> <span style={{ fontSize: '15px' }}> &copy;</span> {(new Date()).getFullYear()} Mercanetica estrategias S.A. de C.V.</span>
                        <ul className="navbar-nav ml-lg-auto">

                            <li className="nav-item"><a href="https://e-xpertum.com.mx/adminfile/preguntas-frecuentes" rel="noreferrer" className="navbar-nav-link" target="_blank"><span className="text-warning-400"><i className="icon-lifebuoy mr-2"></i> Preguntas frecuentes</span></a></li>
                            <li className="nav-item"><a href="https://e-xpertum.com.mx/adminfile/terminosycondiciones" rel="noreferrer" className="navbar-nav-link" target="_blank"><span className="text-warning-400"><i className="icon-file-text2 mr-2"></i> Términos y condiciones</span></a></li>
                            <li className="nav-item"><a href="https://e-xpertum.com.mx/adminfile/avisoprivacidad" rel="noreferrer" className="navbar-nav-link" target="_blank"><span className="text-warning-400"><i className="icon-clipboard3 mr-2"></i> Aviso de privacidad</span></a></li>

                        </ul>
                    </div>
                </div>
            </>
        );
    }
}
export default UploadEfirma;

function toTitleCase(word) {
    return String(word[0]).toUpperCase() + String(word).substr(1);
}