import React, { Component } from 'react'
import { Tooltip, } from "@material-ui/core";
import ReactDatatable from '@ashvin27/react-datatable';
export default class TaxComplace extends Component {
    constructor(props) {
        super(props)
        this.columns = [
            {
                key: "name",
                text: "Nombre",
                className: "name",
                cell: record => {
                    return (
                        <div className="text-left">
                            {record.name}
                        </div>
                    );
                }
            },
            {
                key: "rfc",
                text: "Rfc",
                cell: record => {
                    return (
                        <div className="text-center">
                            {record.rfc}
                        </div>
                    );
                }
            },
            {
                key: "result",
                text: "Estatus",
                className: "result",
                cell: y => {
                    return (
                        <>
                            {y.result === "positive" ?
                                (
                                    <div className="text-center text-success">
                                        {y.result === "positive" ? "Positivo" : "Negativo"}
                                    </div>
                                ) : (<div className="text-center text-danger">
                                    {y.result === "positive" ? "Positivo" : "Negativo"}
                                </div>)
                            }
                        </>
                    );
                }
            },
            {
                cell: y => {
                    return (
                        <div className='text-center'>
                            {y.result === "positive" ?
                                (
                                    <a onClick={() => this.viewpdfcfdi_(y)} style={{ cursor: 'pointer' }}> <Tooltip title="Click para ver cfdi completo" placement="top" arrow><span className="badge badge-flat  text-success"><i className="icon-file-pdf"></i></span></Tooltip></a>
                                ) : (<a onClick={() => this.viewpdfcfdi_(y)} style={{ cursor: 'pointer' }}> <Tooltip title="Click para ver cfdi completo" placement="top" arrow><span className="badge badge-flat  text-danger"><i className="icon-file-pdf"></i></span></Tooltip></a>)
                            }
                        </div>

                    );
                }
            }
        ];
        this.config = {
            key_column: 'rfc',
            "page_size": 50,
            "length_menu": [50, 70, 90],
            "show_filter": true,
            "show_pagination": true,
            "button": {
                "excel": false,
                "print": false,
                "extra": false
            },
            "language": {
                "length_menu": "Mostrar _MENU_ registros por página",
                "filter": "Buscar en registros ...",
                "info": "Mostrando _START_ a _END_ de _TOTAL_ entradas",
                "pagination": {
                    "first": "Primero",
                    "previous": "Previa",
                    "next": "próximo",
                    "last": "Última"
                }
            }
        };
        this.state = {
            data: [],
            ///data: props.data,
            link: ''
        }
    }
    componentDidMount() {
        this.setState({
            data: this.props.data
        });
    }
    viewpdfcfdi_ = async (data) => {
        let _dir_ = data.file;
        // 
        let link = 'https://e-xpertum.com.mx/adminfile/api/displaypdf?query={"directorio":"/mnt/drivevar/' + data.rfc + '/' + data["@type"] + '/' + _dir_.filename + '"}';
        //let link = 'https://e-xpertum.com.mx/adminfile/api/displaypdf?query={"directorio":"/var/servicios/panel/files/auditor/' + data.rfc + '/' + data["@type"] + '/' + _dir_.filename + '"}';
        this.setState({
            link: link
        })
        window.$("#modalfullconstancia").modal("show");
    }
    render() {
        return (
            <div className="card">
                <div className="card-body">
                    <ReactDatatable
                        config={this.config}
                        records={this.state.data}
                        columns={this.columns}
                    />
                    <div id="modalfullconstancia" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                        <div className="modal-dialog modal-full">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Constancia de cumplimiento</h5>
                                    <button type="button" className="close" data-dismiss="modal">×</button>
                                </div>
                                <div className="modal-body">
                                    <div className="embed-container">
                                        <iframe src={this.state.link} width="100%"
                                            frameBorder="0" allowFullScreen
                                        >
                                        </iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
