import React, { useEffect, useState } from 'react'
import { Tooltip, } from "@material-ui/core";
import ReactDatatable from '@ashvin27/react-datatable';

export default function BlackListReport(props) {
    const columns = [
        {
            align: "left",
            sortable: true,
            key: "nombre",
            text: "Nombre",
            className: "name",
            cell: record => {
                return (
                    <div className="text-left">
                        {record.nombre}
                    </div>
                );
            }
        },
        {
            align: "left",
            sortable: true,
            key: "rfc",
            text: "RFC",
            cell: record => {
                return (
                    <div className="text-left">
                        {record.rfc}
                    </div>
                );
            }
        },
        {
            align: "center",
            sortable: true,
            key: "fecha",
            text: "Fecha de factura",
            className: "fecha",
            cell: y => {
                return (
                    <div className='text-center'>{
                        String(y.fecha).slice(0, 10)
                    }
                    </div>
                );
            }
        },
        {
            align: "center",
            sortable: true,
            key: "fechas",
            text: "Fecha de publicación",
            className: "fechas",
            cell: y => {
                return (
                    <div className='text-center'>{
                        validateDate(y.fechas)
                    }
                    </div>
                );
            }
        },
        {
            align: "center",
            sortable: true,
            key: "x",
            text: "Tipo",
            className: "definitivo",
            cell: y => {
                return (
                    <div className='text-center'>
                        {
                            ValidateType(y.tipo)
                        }
                    </div>
                );
            }
        }
        ,
        {
            align: "center",
            key: "estatus",
            text: "Estatus",
            className: "estatus",
            cell: y => {
                return (
                    <div className='text-center'>
                        {
                            y.estatus
                        }
                    </div>
                );
            }
        }
    ];
    const config = {
        "page_size": 50,
        "length_menu": [50, 70, 90],
        "show_filter": true,
        "show_pagination": true,
        "button": {
            "excel": false,
            "print": false,
            "extra": false
        },
        "language": {
            "length_menu": "Mostrar _MENU_ registros por página",
            "filter": "Buscar en registros ...",
            "info": "Mostrando _START_ a _END_ de _TOTAL_ entradas",
            "pagination": {
                "first": "Primero",
                "previous": "Previa",
                "next": "próximo",
                "last": "Última"
            }
        }
    };
    const [data, setData] = useState(props.dta);
    useEffect(() => {
        setData(props.dta)
    })
    return (
        <div>
            <ReactDatatable
                config={config}
                records={data}
                columns={columns}
            />
        </div>
    )
    function validateDate(date) {
        let _ = String(date).split('-');

        ///validateMonth(_[2])
        return String(_[2]).slice(0, 2) + " de " + validateMonth(_[1]) + " de " + _[0];
    }
    function validateMonth(month) {
        switch (month) {
            case "01":
                return "Enero"
                break;
            case "02":
                return "Febrero"
                break;
            case "03":
                return "Marzo"
                break;
            case "04":
                return "Abril"
                break;
            case "05":
                return "Mayo"
                break;
            case "06":
                return "Junio"
                break;
            case "07":
                return "Julio"
                break;
            case "08":
                return "Agosto"
                break;
            case "09":
                return "Septiembre"
                break;
            case "10":
                return "Octubre"
                break;
            case "11":
                return "Noviembre"
                break;
            case "12":
                return "Diciembre"
                break;
            default:
                break;
        }
    }
    function ValidateType(tipo) {
        switch (tipo) {
            case "001":
                return "No localizado"
                break;
            case "002":
                return "Presuntos"
                break;
            case "003":
                return "Definitivos"
                break;
            case "004":
                return "Desvirtuados"
                break;
            default:
                break;
        }
    }
}
