import React, { Component } from 'react'
import ReactDatatable from '@ashvin27/react-datatable';
import { Tooltip, } from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
import { PacmanLoader } from "react-spinners";
import api from '../../../Config/apifiles';
import ReactJson from 'react-json-view';
function orderbydate(a, b) {
    return new Date(a.fecha).getTime() - new Date(b.fecha).getTime();
}
export default class SalariesWorkPapers extends Component {
    constructor(props) {
        super(props)
        let objects = JSON.parse(localStorage.getItem("datacompanyall"));

        this.columns = [
            {
                key: "fecha",
                text: "Fecha",
                className: "name",
                width: "150",
                align: 'center',
                cell: record => {
                    return (
                        <div className="text-center">
                            {record.fecha.substr(0, 10)}
                        </div>
                    );
                }
            },
            {
                key: "deducciones_concepto",
                text: "Concepto",
                width: "600",
                cell: record => {
                    return (
                        <div className="text-left">
                            {record.deducciones_concepto}
                        </div>
                    );
                }
            },
            {
                key: "deducciones_total_impuestos_retenidos",
                text: "Total impuesto retenido",
                align: 'center',
                width: "200",
                cell: y => {
                    return (
                        <div className="text-right">
                            {(y.deducciones_total_impuestos_retenidos < 0 ? (
                                <span className="text-danger">({y.deducciones_total_impuestos_retenidos.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1, ')})</span>
                            ) : <>{y.deducciones_total_impuestos_retenidos.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1, ')}</>
                            )}
                        </div>
                    );
                }
            },
            {
                key: "percepciones_total_exento",
                text: "Exento",
                align: 'center',
                width: "200",
                cell: y => {
                    return (
                        <div className="text-right">
                            {(y.percepciones_total_exento < 0 ? (
                                <span className="text-danger">({y.percepciones_total_exento.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1, ')})</span>
                            ) : <>{y.percepciones_total_exento.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1, ')}</>
                            )}
                        </div>
                    );
                }
            },
            {
                key: "percepciones_total_gravado",
                text: "Gravado",
                width: "200",
                align: 'center',
                cell: y => {
                    return (
                        <div className="text-right">
                            {(y.percepciones_total_gravado).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                        </div>
                    );
                }
            },
            {
                key: "percepciones_total_sueldos",
                text: "Sueldo",
                width: "200",
                align: 'center',
                cell: y => {
                    return (
                        <div className="text-right">
                            {y.percepciones_total_sueldos.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                        </div>
                    );
                }
            },
            {
                width: "200",
                cell: y => {
                    return (
                        <div className="text-center">
                            <a onClick={() => this.viewpdfcfdi_(y)} style={{ cursor: 'pointer' }}> <Tooltip title="Click para ver cfdi completo" placement="top" arrow><span className="badge badge-flat  text-success"><i className="icon-file-pdf"></i></span></Tooltip></a>
                            &nbsp;&nbsp;
                            <a onClick={() => this.viewxml(y)} style={{ cursor: 'pointer' }}><Tooltip title="Click para ver cfdi completo" placement="top" arrow><span className="badge badge-flat  text-success"><i className="icon-file-xml"></i></span></Tooltip></a>
                        </div>
                    );
                }
            }
        ];
        this.config = {
            "key_column": 'uuid',
            "page_size": 70,
            "length_menu": [70, 80, 90],
            "show_filter": true,
            "show_pagination": true,
            "button": {
                "excel": false,
                "print": false,
                "extra": false
            },
            "language": {
                "length_menu": "Mostrar _MENU_ registros por página",
                "filter": "Buscar en registros ...",
                "info": "Mostrando _START_ a _END_ de _TOTAL_ entradas",
                "pagination": {
                    "first": "Primero",
                    "previous": "Previa",
                    "next": "próximo",
                    "last": "Última"
                }
            }
        };
        this.state = {
            showloading: true,
            empresa_id: parseInt(objects.id),
            nombrecompany: objects.nombre,
            loading: false,
            loadingpdf: false,
            linkpdf: '',
            loadingfirts: false,
            sumimpuestoretenido: 0,
            sumexento: 0, sumgravado: 0, sumsueldo: 0,
            ejercicio: props.dt.ejercicio,
            periodo: props.dt.period
        }
    }
    viewpdfcfdi_ = async (t) => {
        this.setState({
            loadingpdf: true
        })
        let _link_ = "";
        _link_ = 'https://e-xpertum.com.mx/adminfile/api/genera-pdf-cfdi-nomina/' + t.cfdi_id + '/' + (t.uuid + "&" + this.state.rfc);
        this.setState({
            link: _link_,
            uuid: t.uuid
        });

        window.$('#viewpdfcfdi').modal('show');
    }
    handleLoading = async () => {
        this.setState({
            loadingpdf: false
        })
    }
    _viewInit_ = () => {
        this.setState({
            header: true,
            body: false,
        })
    }
    handleViewPDF = () => {
        let linkpdf = 'https://e-xpertum.com.mx/adminfile/api/export-pdf-view-nomina/' + this.state.rfc + '/' + this.state.nombrecompany + '/' + this.state.clave;
        var win = window.open(linkpdf, '_blank');
        if (win) {
            win.focus();
        } else {
            alert('Please allow popups for this website');
        }
    }
    componentDidMount() {
        this.handleChangeView()
    }
    handleChangeView = async () => {
        this.setState({ showloading: true });
        setTimeout(async () => {
            let data_ = await api.post('list-cfdis-nomina-imports',
                {
                    empresa_id: parseInt(this.state.empresa_id),
                    ejercicio: this.state.ejercicio,
                    periodo: this.state.periodo
                }
            );
            let _data_iteracion_ = data_.data;
            console.log(_data_iteracion_)
            let _local_sumimpuestoretenido = 0, _local_sumexento = 0, _local_sumgravado = 0, _local_sumsueldo = 0;
            for (let j = 0; j < _data_iteracion_.length; j++) {
                _local_sumimpuestoretenido = _local_sumimpuestoretenido + _data_iteracion_[j].deducciones_total_impuestos_retenidos;
                _local_sumexento = _local_sumexento + _data_iteracion_[j].percepciones_total_exento;
                _local_sumgravado = _local_sumgravado + _data_iteracion_[j].percepciones_total_gravado;
                _local_sumsueldo = _local_sumsueldo + _data_iteracion_[j].percepciones_total_sueldos;
            }
            this.setState({
                /// ejercicio: this.state.ejercicio,/// periodo: this.state.period,
                header: false,
                body: true,
                datacfdi: _data_iteracion_.sort(orderbydate),
                allcfdis: data_.data,
                showloading: false, sumimpuestoretenido: _local_sumimpuestoretenido.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
                sumexento: _local_sumexento.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'), sumgravado: _local_sumgravado.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'), sumsueldo: _local_sumsueldo.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
            });

        }, 200);

    }
    handleexportexcel = async () => {
        let da = await api.post("export-work-papers-nomina-post", { empresa_id: this.state.empresa_id, ejercicio: this.state.ejercicio, periodo: this.state.periodo });
        window.open("https://e-xpertum.com.mx/adminfile/api/download-report-nomina/" + da.data.dir + "/mm", "_blank")
        //window.open('http://74.208.137.47:3020/api/export-work-papers-nomina/' + this.state.empresa_id + '/' + this.state.ejercicio + '/' + this.state.periodo);
    }
    viewxml = async (t) => {
        window.$("#viewxmlcfdi").modal("show");
        let _data_ = await api.post('genera_xml_cfdi', { id: t.cfdi_id, tipo: 'EMITIDO' });
        this.setState({
            uuid: t.uuid,
            cfdi: <ReactJson src={JSON.parse(_data_.data)} enableClipboard={false} theme={"monokai"} displayDataTypes={false} displayObjectSize={false} />
        })

    }
    render() {
        return (
            <div className="media card-body flex-column flex-lg-row m-0">
                <div className="media-body">
                    <div className="form-group">
                        <div className="border p-3 rounded">
                            <div className="custom-control custom-radio custom-control-inline">
                                <input type="radio" className="custom-control-input" name="cr-i-l" id="cr_l_i_s" onClick={this.handleFilterretenciones} />
                                <label className="custom-control-label" htmlFor="cr_l_i_s">Solo CFDIs con impuesto retenido</label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input type="radio" className="custom-control-input" name="cr-i-l" id="cr_l_i_u" defaultChecked onClick={this.handlefilterall} />
                                <label className="custom-control-label" htmlFor="cr_l_i_u">Todos los CFDIs</label>
                            </div>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <button type="button" className="btn btn-light" onClick={this.handleexportexcel}><i className="icon-file-excel mr-2 text-success"></i> Excel</button>
                        </div>
                    </div>
                    {
                        (this.state.showloading) ? (
                            <div>
                                <Skeleton variant="text" />
                                <div style={{
                                    margin: 40,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                    <PacmanLoader color={"#ff7043"} loading={this.state.cargandospanol} size={25} margin={2} />
                                </div>
                                <Skeleton variant="circle" animation="wave" width={40} height={40} />
                                <Skeleton variant="rect" animation="wave" style={{ width: "100%" }} height={118} />
                            </div>
                        ) : (
                            <>
                                <div className="d-lg-flex flex-lg-wrap">
                                    <div className="pt-2 mb-3">
                                        <div className="table-responsive">
                                            <table className="table">
                                                <tbody>
                                                    <tr>
                                                        <th>Total impuesto retenido: </th>
                                                        <td style={{ textAlign: 'right' }}>{this.state.sumimpuestoretenido}</td>
                                                        <th>Exento: </th>
                                                        <td style={{ textAlign: 'right' }}>{this.state.sumexento}</td>
                                                        <th>Gravado: </th>
                                                        <td style={{ textAlign: 'right' }}>{this.state.sumgravado}</td>
                                                        <th>Sueldo: </th>
                                                        <td style={{ textAlign: 'right' }}>{this.state.sumsueldo}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="pt-2 mb-3 wmin-lg-400 ml-auto">
                                    </div>
                                </div>
                                <div className='table table-responsive'>
                                    <ReactDatatable
                                        config={this.config}
                                        records={this.state.datacfdi}
                                        columns={this.columns} />
                                </div>

                            </>
                        )
                    }

                    <div id="viewpdfcfdi" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                        <div className="modal-dialog modal-full">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">{this.state.uuid}</h5>
                                    <button type="button" className="close" data-dismiss="modal" onClick={() => this.setState({ link: '' })}>×</button>
                                </div>
                                <div className="modal-body">
                                    <div className="embed-container">
                                        {
                                            (this.state.loadingpdf) ?
                                                (
                                                    <div>
                                                        <Skeleton variant="text" />
                                                        <Skeleton variant="circle" animation="wave" width={40} height={40} />
                                                        <Skeleton variant="rect" animation="wave" width={510} height={118} />
                                                    </div>
                                                ) : null
                                        }
                                        <iframe src={this.state.link} onLoad={this.handleLoading}
                                            width="100%"
                                            frameBorder="0" allowFullScreen
                                        >
                                        </iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="viewxmlcfdi" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                        <div className="modal-dialog modal-full">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">{this.state.uuid}</h5>
                                    <button type="button" className="close" data-dismiss="modal" onClick={() => this.setState({ link: '' })}>×</button>
                                </div>
                                <div className="modal-body">
                                    <div>
                                        {
                                            (this.state.loading) ?
                                                (
                                                    <div>
                                                        <Skeleton variant="text" />
                                                        <Skeleton variant="circle" animation="wave" width={40} height={40} />
                                                        <Skeleton variant="rect" animation="wave" width={510} height={118} />
                                                    </div>
                                                ) : null
                                        }
                                        {this.state.cfdi}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="modallargepdf" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                        <div className="modal-dialog modal-full">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Reporte de sueldos y salarios</h5>
                                    <button type="button" className="close" data-dismiss="modal">×</button>
                                </div>
                                <div className="modal-body">
                                    <div className="embed-container">
                                        {
                                            (this.state.loadingfirts) ?
                                                (
                                                    <div>
                                                        <Skeleton variant="text" />
                                                        <Skeleton variant="circle" animation="wave" width={40} height={40} />
                                                        <Skeleton variant="rect" animation="wave" width={510} height={118} />
                                                    </div>
                                                ) : null
                                        }
                                        <iframe src={this.state.linkpdf} onLoad={this.handleLoading}
                                            width="100%" frameBorder="0" allowFullScreen
                                        >
                                        </iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
