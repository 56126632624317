import React, { useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Grid, Paper, Select, FormControl, InputLabel, Divider, DialogActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
/*******
 * LIBRARY UPLOAD FILES
 */
import * as ALLIcons from '@material-ui/icons';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
/*******
 * CONFIG CONEXION
 */
import api from '../../../app/Config/api';
/**************
 * NOTIFICATIONS
 */
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    cards: {
        minWidth: 275,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    }, paperop: {
        padding: theme.spacing(2),
        textAlign: 'right',
        color: theme.palette.text.secondary,
    },
    alinearencabezado: {
        textAlign: 'center',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 120,
        color: '#000',
    },
}));

export default function ResponsiveDialog(obj) {
    var ejercicioSistem = new Date();

    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [estatus, setAbrir] = useState(false);
    const [value, setAge] = useState('');
    const [messageall, setOpenMessage] = useState({
        success: false,
        vertical: 'top',
        horizontal: 'center',
        error: false,
        message: ''
    });
    const { vertical, horizontal, success, error, message } = messageall;

    const user = JSON.parse(localStorage.getItem("id_token"));
    const [info] = useState({
        rfc: '',
        ejercicio: ejercicioSistem.getFullYear(),
        cliente_id: user.cliente_id
    });
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const handleClickOpenNueva = () => {
        setAbrir(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCerrar = () => {
        setAbrir(false);
    };
    /************
     * procesos para subir el archivo
     */
    const [datafile, setFile] = useState({
        file: '',
        namefile: 'Seleccione la Constancia de Situación Fiscal',
        open: false
    });
    const el = useRef();
    const handleChangeFile = (e) => {
        setFile({ open: true })
        const file = e.target.files[0];
        if (file !== undefined) {
            setTimeout(() => {
                const formData = new FormData();
                formData.append('file', file)
                formData.append('ejercicio', ejercicioSistem.getFullYear())
                formData.append('cliente_id', info.cliente_id)
                api.post('/constaciasituacionfiscalvalidador', formData).then(res => {
                    var containers = res.data;

                    if (containers.status === 200) {
                        setFile({
                            open: false,
                            file: '',
                            namefile: 'Seleccione la Constancia de Situación Fiscal',
                        })
                        setOpenMessage({
                            success: true,
                            vertical: 'top',
                            horizontal: 'right',
                            error: false,
                            message: containers.message
                        });
                        setAbrir(false);
                        setOpen(false);
                        var contenido_ = obj.informacion;
                        contenido_.informacionObtenida(false);
                        //window.location.reload();
                        //resultData.informacion('Hola mundo....');
                    }
                    else if (containers.status === 404) {
                        setFile({
                            open: false,
                            file: '',
                            namefile: 'Seleccione la Constancia de Situación Fiscal',
                        })
                        setOpenMessage({
                            success: false,
                            vertical: 'top',
                            horizontal: 'right',
                            error: true,
                            message: containers.message
                        });
                        el.current.value = "";
                        setTimeout(() => {
                            setOpenMessage({
                                error: false
                            });
                        }, 4000);
                    }

                }).catch(err => console.log(err));
            }, 1000);

        }
    }

    const uploadFileSend = () => {

        if (datafile.file !== '') {
            if (datafile.file.type === "application/pdf") {
                setFile({ open: true })
                const formData = new FormData();
                formData.append('file', datafile.file)
                formData.append('ejercicio', ejercicioSistem.getFullYear())
                formData.append('cliente_id', info.cliente_id)
                api.post('/constaciasituacionfiscalvalidador', formData).then(res => {
                    var containers = res.data;

                    if (containers.status === 200) {
                        setFile({
                            open: false,
                            file: '',
                            namefile: 'Seleccione la Constancia de Situación Fiscal',
                        })
                        setOpenMessage({
                            success: true,
                            vertical: 'top',
                            horizontal: 'right',
                            error: false,
                            message: containers.message
                        });
                        setAbrir(false);
                        setOpen(false);
                        var contenido_ = obj.informacion;
                        contenido_.informacionObtenida(false);
                        //window.location.reload();
                        //resultData.informacion('Hola mundo....');
                    }
                    else if (containers.status === 404) {
                        setFile({
                            open: false,
                            file: '',
                            namefile: 'Seleccione la Constancia de Situación Fiscal',
                        })
                        setOpenMessage({
                            success: false,
                            vertical: 'top',
                            horizontal: 'right',
                            error: true,
                            message: containers.message
                        });
                        el.current.value = "";
                        setTimeout(() => {
                            setOpenMessage({
                                error: false
                            });
                        }, 4000);
                    }

                }).catch(err => console.log(err));
            }
            else {
                setFile({
                    open: false,
                    file: '',
                    namefile: 'Seleccione la Constancia de Situación Fiscal',
                })
                setOpenMessage({
                    success: false,
                    vertical: 'top',
                    horizontal: 'right',
                    error: true,
                    message: 'El archivo importado no corresponde al documento a importar.'
                });
                el.current.value = "";
                setTimeout(() => {
                    setOpenMessage({
                        error: false
                    });
                }, 4000);
            }
        }
        else {
            setOpenMessage({ success: false, vertical: 'top', horizontal: 'right', error: true, message: 'Para poder continuar debe de seleccionar el documento!!!' });
            setTimeout(() => {
                setOpenMessage({
                    error: false
                });
            }, 4000);
        }



    }


    const handleCloseMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenMessage({
            success: false,
            vertical: 'top',
            horizontal: 'right',
            error: false,
            message: ''
        });
    };
    /*const pruebsenddata = () => {
        var contenido_ = obj.informacion;
      //  console.log(contenido_);
        contenido_.informacionObtenida('Hola mundo....');
    }*/

    return (
        <>

            <button type="button" className="btn btn-light" onClick={handleClickOpenNueva}>Subir constancia fiscal <i className="icon-play3 ml-2"></i></button>

            <Snackbar open={success} autoHideDuration={6000} onClose={handleCloseMessage} anchorOrigin={{ vertical, horizontal }}>
                <Alert onClose={handleCloseMessage} severity="success">
                    {message}
                </Alert>
            </Snackbar>

            <Dialog maxWidth="sm" fullWidth={fullScreen} open={estatus} onClose={handleCerrar} aria-labelledby="responsive-dialog-title" >
                <DialogTitle id="responsive-dialog-title" className={classes.alinearencabezado}><span style={{ color: "white" }}>Crear nueva empresa</span> </DialogTitle>
                <Divider />
                <div className="content pt-0" style={{ backgroundColor: "#262d3c" }}>
                    <div className="card-body">
                        {
                            error ? (<div class="alert alert-warning alert-styled-right alert-dismissible">
                                <span class="font-weight-semibold">Usuario!</span> {message}
                            </div>) : null
                        }
                        <div className="form-group">
                            <div className="uniform-uploader">
                                <input type="file" className="form-input-styled" data-fouc="" ref={el} onChange={handleChangeFile} accept=".pdf" />
                                <span className="filename" style={{ userSelect: 'none' }}> {datafile.namefile}</span>
                                <span className="action btn bg-warning-400" style={{ userSelect: 'none' }}>Seleccione el archivo</span></div>
                            <span className="form-text text-muted">Formato aceptado: pdf.</span>
                        </div>
                    </div>
                </div>
                <DialogActions>
                    <button type="button" onClick={handleCerrar} class="btn btn-link" >Cancelar</button>
                </DialogActions>
            </Dialog>
            <Backdrop className={classes.backdrop} open={datafile.open} onClick={handleClose}>
                <CircularProgress color="inherit" />
            </Backdrop>

        </>
    );

}
