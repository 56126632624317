import React, { Component } from 'react';
import { Grid, Avatar } from "@material-ui/core";
import api from '../../../app/Config/api';

class ViewPanelAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numeronolocalizados: 0,
            numeropresuntos: 0,
            numerodefinitivos: 0,
            numerodesvirtuados: 0,
            ejercicio: 0,
            fecha: '',
        }
    }
    componentDidMount() {
        const data = JSON.parse(localStorage.getItem("datavalidate"));

        if (data.tipo_validacion === 'EFIRMA') {
            var objects = {
                ejercicio: this.props.informacion.ejercicio,
                empresa_id: data.id
            };
            api.post('listadetailsmetadata', objects).then((response) => {
                var datoInformacion_ = response.data;
                var nolocalizadocont = (datoInformacion_.filter((k) => k.tipo === '001')).length;
                var presuntoscont = (datoInformacion_.filter((k) => k.tipo === '002')).length;
                var definitivoscont = (datoInformacion_.filter((k) => k.tipo === '003')).length;
                var desvirtuadoscont = (datoInformacion_.filter((k) => k.tipo === '004')).length;
                this.setState({
                    numeronolocalizados: nolocalizadocont,
                    numeropresuntos: presuntoscont,
                    numerodefinitivos: definitivoscont,
                    numerodesvirtuados: desvirtuadoscont,
                    ejercicio: this.props.informacion.ejercicio,
                    fecha: this.props.informacion.fecha,
                });
            });
        }
        else if (data.tipo_validacion === 'IMPORTACION') {
            var objects = {
                ejercicio: this.props.informacion.ejercicio,
                empresa_id: parseInt(data.id),
                id: parseInt(data.id)
            };
            api.post('listadetailscfdisend', objects).then((response) => {

                var datoInformacion_ = response.data;
                var nolocalizadocont = (datoInformacion_.filter((k) => k.tipo === '001')).length;
                var presuntoscont = (datoInformacion_.filter((k) => k.tipo === '002')).length;
                var definitivoscont = (datoInformacion_.filter((k) => k.tipo === '003')).length;
                var desvirtuadoscont = (datoInformacion_.filter((k) => k.tipo === '004')).length;
                this.setState({
                    numeronolocalizados: nolocalizadocont,
                    numeropresuntos: presuntoscont,
                    numerodefinitivos: definitivoscont,
                    numerodesvirtuados: desvirtuadoscont,
                    ejercicio: this.props.informacion.ejercicio,
                    fecha: this.props.informacion.fecha,
                });
            });

        }

    }

    handleClickNolocalizado = () => {
        if (this.state.numeronolocalizados > 0)
            this.props.informacion.click({ ejercicio: this.state.ejercicio, tipo: '001', descripcion: 'Relación de contribuyentes incumplidos – No localizados' });
    }
    handleClickPresuntos = () => {
        if (this.state.numeropresuntos > 0)
            this.props.informacion.click({ ejercicio: this.state.ejercicio, tipo: '002', descripcion: 'Relación de contribuyentes incumplidos – Presuntos' });
    }
    handleClickDefinitivos = () => {
        if (this.state.numerodefinitivos > 0)
            this.props.informacion.click({ ejercicio: this.state.ejercicio, tipo: '003', descripcion: 'Relación de contribuyentes incumplidos – Definitivos' });
    }
    handleClickDesvirtuados = () => {
        if (this.state.numerodesvirtuados > 0)
            this.props.informacion.click({ ejercicio: this.state.ejercicio, tipo: '004', descripcion: 'Relación de contribuyentes incumplidos – Desvirtuado' });
    }
    handleViewCfdisModal = () => {
        this.props.informacion.preview({ ejercicio: this.state.ejercicio })
    }
    render() {
        return (<>
            <Grid item xs={6} sm={4}>
                <div className="card" style={{ color: 'white' }}>
                    <div className="card-header">
                        <div className="media">
                            <div className="mr-3">
                                <Avatar aria-label="recipe" style={{ backgroundColor: '#5c6bc0' }}>69B</Avatar>
                            </div>

                            <div className="media-body">
                                <div className="font-weight-semibold">{"Ejercicio - " + this.state.ejercicio}</div>
                                <span className="text-muted">{this.state.fecha}</span>
                            </div>


                        </div>
                    </div>
                    <div className="card card-body">
                        <div className="row text-center">

                            {this.state.numeronolocalizados > 0 ? (
                                <div className="col-4" onClick={this.handleClickNolocalizado} style={{ cursor: 'pointer' }}>
                                    <p><i className="fas fa-tired fa-3x d-inline-block text-orange-300"></i></p>
                                    <h5 className="font-weight-semibold mb-0">{this.state.numeronolocalizados}</h5>
                                    <span className="text-muted font-size-sm">No localizados</span>
                                </div>
                            ) : (<div className="col-4">
                                <p><i className="fas fa-grin-alt  fa-3x d-inline-block text-success"></i></p>
                                <h5 className="font-weight-semibold mb-0"><i className="icon-checkmark4 text-success"></i></h5>
                                <span className="text-muted font-size-sm">No localizados</span>
                            </div>)}

                            {this.state.numeropresuntos > 0 ? (
                                <div className="col-4" onClick={this.handleClickPresuntos} style={{ cursor: 'pointer' }}>
                                    <p><i className="fas fa-grimace  fa-3x d-inline-block text-orange-300"></i></p>
                                    <h5 className="font-weight-semibold mb-0">{this.state.numeropresuntos}</h5>
                                    <span className="text-muted font-size-sm">Presuntos</span>
                                </div>
                            ) : (<div className="col-4">
                                <p><i className="fas fa-grin-alt  fa-3x d-inline-block text-success"></i></p>
                                <h5 className="font-weight-semibold mb-0"><i className="icon-checkmark4 text-success"></i></h5>
                                <span className="text-muted font-size-sm">Presuntos</span>
                            </div>)}

                            {this.state.numerodefinitivos > 0 ? (<div className="col-4" onClick={this.handleClickDefinitivos} style={{ cursor: 'pointer' }}>
                                <p><i className="fas fa-angry  fa-3x d-inline-block text-danger"></i></p>
                                <h5 className="font-weight-semibold mb-0">{this.state.numerodefinitivos}</h5>
                                <span className="text-muted font-size-sm">Definitivos</span>
                            </div>) : (<div className="col-4">
                                <p><i className="fas fa-grin-alt  fa-3x d-inline-block text-success"></i></p>
                                <h5 className="font-weight-semibold mb-0"><i className="icon-checkmark4 text-success"></i></h5>
                                <span className="text-muted font-size-sm">Definitivos</span>
                            </div>)}

                        </div>
                        <div className="row text-center">
                            {this.state.numerodesvirtuados > 0 ? (<div className="col-4" onClick={this.handleClickDesvirtuados} style={{ cursor: 'pointer' }}>
                                <p><i className="fas fa-frown-open  fa-3x d-inline-block text-warning-300"></i></p>
                                <h5 className="font-weight-semibold mb-0">{this.state.numerodesvirtuados}</h5>
                                <span className="text-muted font-size-sm">Desvirtuados</span>
                            </div>) : (<div className="col-4">
                                <p><i className="fas fa-grin-alt  fa-3x d-inline-block text-success"></i></p>
                                <h5 className="font-weight-semibold mb-0"><i className="icon-checkmark4 text-success"></i></h5>
                                <span className="text-muted font-size-sm">Desvirtuados</span>
                            </div>)}
                            <div className="col-3"></div>
                            <div className="col-5" onClick={this.handleViewCfdisModal} style={{ cursor: 'pointer', top: '34px' }}>
                                <div className="pt-0"></div>
                                <p><i className="icon-list2 icon-2x d-inline-block text-success"></i></p>
                                <span className="text-muted font-size-sm">Ver acreedores validados</span>
                            </div>
                        </div>
                    </div>
                </div>

            </Grid>
        </>);
    }
}

export default ViewPanelAdmin;