import React, { Component } from 'react';
import api from '../../../../app/Config/api';
import Parse from 'html-react-parser';
import Skeleton from '@material-ui/lab/Skeleton';

class CCN extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recorset: [],
            skeleton: true,
        }
    }
    componentDidMount() {
        this.getdata();
    }
    getdata = async () => {
        let pareme = {
            empresa_id: localStorage.getItem("idxm"),
            clave: 'COU01'
        };
        let result_ = await api.post("validacionnotificacioncuentas", pareme);
        let { data } = await result_;
        let _resultado_end_ = (data.info).filter((k) => k.parent_id !== "0");
        this.setState({ recorset: _resultado_end_, skeleton: false })
    }
    render() {
        return (
            <>
                {
                    this.state.skeleton ? (
                        <div className="card">
                            <Skeleton />
                            <Skeleton animation={false} height={400} />
                            <Skeleton animation="wave" />
                        </div>
                    ) : (
                            <div className="card">
                                <div className="list-group list-group-flush">
                                    {
                                        (this.state.recorset || []).map((o, p) => (
                                            <span className="list-group-item list-group-item-action">{o.descripcion}</span>


                                        ))
                                    }
                                </div>
                            </div>

                        )
                }
            </>
        );
    }
}

export default CCN;