import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import api from '../../app/Config/api';
import { useLayoutState, useLayoutDispatch, } from "../../context/LayoutContext";
import { useUserDispatch, signOut } from "../../context/UserContext";
export default function Header(props) {
  var userDispatch = useUserDispatch();
  var objectData = JSON.parse(localStorage.getItem("id_token"));
  var [dataInfo] = useState(objectData);
  const [resultnotificacion, setNotificacion] = useState([]);
  const { nombre, perfil } = dataInfo;
  useEffect(() => {
    //  _get_list_notificacion_();
  }, [])
  const handleUpdateStatus = () => {
    api.post("updatenotificationshare", dataInfo).then((res) => res.data).then((obj) => {
      setNotificacion(obj);
    }).catch((r) => {
      console.log(r)
    })
  }
  function _get_list_notificacion_() {
    api.post("listfilternotificacion", dataInfo).then((res) => res.data).then((obj) => {
      setNotificacion(obj);
    }).catch((r) => {
      console.log(r)
    })
  }
  const { img } = dataInfo;
  const link = (perfil === "GOBIERNO" ? "/app/ui/validadorgobierno" : (perfil === "FINANCIERA" ? "/app/ui/financial" : "/app/dashboard"));
  return (
    <>
      <div className="navbar navbar-expand-md navbar-light navbar-static">
        <div className="navbar-brand">
          {perfil === "GOBIERNO" ? (
            <Link to={link} className="d-inline-block">
              <img src="logos/logo_expertumb.png" alt="" style={{ height: '1.5rem' }} />
            </Link>
          ) : (
            <Link to={link} className="d-inline-block">
              <img src="logos/logo_expertumb.png" alt="" />
            </Link>
          )}

        </div>
        <div className="d-md-none">
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-mobile">
            <i className="icon-tree5"></i>
          </button>
          <button className="navbar-toggler sidebar-mobile-main-toggle" type="button">
            <i className="icon-paragraph-justify3"></i>
          </button>
        </div>

        <div className="collapse navbar-collapse" id="navbar-mobile">


          <span className="badge bg-success my-3 my-md-0 ml-md-3 mr-md-auto">Conectad@</span>
          <ul className="navbar-nav">
            {
              perfil === "ADMIN" ? (
                <li className="nav-item dropdown">
                  <a href="#" className="navbar-nav-link dropdown-toggle caret-0" data-toggle="dropdown">
                    <i className="icon-people"></i>
                    <span className="d-md-none ml-2">Users</span>
                  </a>

                  <div className="dropdown-menu dropdown-menu-right dropdown-content wmin-md-300">
                    <div className="dropdown-content-header">
                      <span className="font-weight-semibold">Users online</span>
                      <a href="#" className="text-default"><i className="icon-search4 font-size-base"></i></a>
                    </div>

                    <div className="dropdown-content-body dropdown-scrollable">
                      <ul className="media-list">
                        <li className="media">
                          <div className="mr-3">
                            <img src="global_assets_dark/images/demo/users/face18.jpg" width="36" height="36" className="rounded-circle" alt="" />
                          </div>
                          <div className="media-body">
                            <a href="#" className="media-title text-white font-weight-semibold">Jordana Ansley</a>
                            <span className="d-block text-muted font-size-sm">Lead web developer</span>
                          </div>
                          <div className="ml-3 align-self-center"><span className="badge badge-mark border-success"></span></div>
                        </li>

                        <li className="media">
                          <div className="mr-3">
                            <img src="global_assets_dark/images/demo/users/face24.jpg" width="36" height="36" className="rounded-circle" alt="" />
                          </div>
                          <div className="media-body">
                            <a href="#" className="media-title text-white font-weight-semibold">Will Brason</a>
                            <span className="d-block text-muted font-size-sm">Marketing manager</span>
                          </div>
                          <div className="ml-3 align-self-center"><span className="badge badge-mark border-danger"></span></div>
                        </li>

                        <li className="media">
                          <div className="mr-3">
                            <img src="global_assets_dark/images/demo/users/face17.jpg" width="36" height="36" className="rounded-circle" alt="" />
                          </div>
                          <div className="media-body">
                            <a href="#" className="media-title text-white font-weight-semibold">Hanna Walden</a>
                            <span className="d-block text-muted font-size-sm">Project manager</span>
                          </div>
                          <div className="ml-3 align-self-center"><span className="badge badge-mark border-success"></span></div>
                        </li>

                        <li className="media">
                          <div className="mr-3">
                            <img src="global_assets_dark/images/demo/users/face19.jpg" width="36" height="36" className="rounded-circle" alt="" />
                          </div>
                          <div className="media-body">
                            <a href="#" className="media-title text-white font-weight-semibold">Dori Laperriere</a>
                            <span className="d-block text-muted font-size-sm">Business developer</span>
                          </div>
                          <div className="ml-3 align-self-center"><span className="badge badge-mark border-warning-300"></span></div>
                        </li>

                        <li className="media">
                          <div className="mr-3">
                            <img src="global_assets_dark/images/demo/users/face16.jpg" width="36" height="36" className="rounded-circle" alt="" />
                          </div>
                          <div className="media-body">
                            <a href="#" className="media-title text-white font-weight-semibold">Vanessa Aurelius</a>
                            <span className="d-block text-muted font-size-sm">UX expert</span>
                          </div>
                          <div className="ml-3 align-self-center"><span className="badge badge-mark border-grey-400"></span></div>
                        </li>
                      </ul>
                    </div>

                    <div className="dropdown-content-footer">
                      <a href="#" className="text-white mr-auto">All users</a>
                      <a href="#" className="text-white"><i className="icon-gear"></i></a>
                    </div>
                  </div>
                </li>
              ) : null
            }
            {
              console.log(perfil)
            }

            <li className="nav-item dropdown">
              <a href="#" className="navbar-nav-link dropdown-toggle caret-0" data-toggle="dropdown" onClick={handleUpdateStatus}>
                <i className="icon-shield-notice"></i>
                <span className="d-md-none ml-2">Notificaciones</span>
                <span className="badge badge-pill bg-warning-400 ml-auto ml-md-0">{(resultnotificacion.filter((y) => y.estatus === 'START')).length > 0 ? (resultnotificacion.filter((y) => y.estatus === 'START')).length : null}</span>
              </a>

              <div className="dropdown-menu dropdown-menu-right dropdown-content wmin-md-350">
                <div className="dropdown-content-header">
                  <span className="font-weight-semibold">Aviso nueva notificación recibido</span>
                </div>

                <div className="dropdown-content-body dropdown-scrollable">
                  <ul className="media-list">
                    {
                      (resultnotificacion || []).map((dt, o) => (
                        <li className="media" key={o}>
                          <div className="mr-3 position-relative">
                            <a href="#" className="btn bg-transparent border-indigo-400 text-indigo-400 rounded-round border-2 btn-icon">
                              <i className="icon-comment"></i>
                            </a>
                          </div>

                          <div className="media-body">
                            <div className="media-title">
                              <a href="#">
                                <span className="font-weight-semibold text-white">Compartieron archivos</span>
                                <span className="text-muted float-right font-size-sm">{_validacion_tiempo_transcurrido_(dt)}</span>
                              </a>
                            </div>

                            <span className="text-muted">{dt.descripcion}</span>
                          </div>
                        </li>
                      ))
                    }
                  </ul>
                </div>
              </div>
            </li>

            <li className="nav-item dropdown dropdown-user">
              <a href="#" className="navbar-nav-link d-flex align-items-center dropdown-toggle" data-toggle="dropdown">
                <img src={(img === null ? "global_assets_dark/images/face11.png" : img)} className="rounded-circle mr-2" height="34" alt="" />
                <span>{nombre}</span>
              </a>

              <div className="dropdown-menu dropdown-menu-right">
                <Link to="/app/ui/perfil" className="dropdown-item"><i className="icon-user-plus"></i> Mi perfil</Link>
                <Link to="/app/ui/config-notification" className="dropdown-item"><i className="icon-cog3"></i> Configuración</Link>
                {
                  perfil === "CLIENTE" ?
                    (
                      <Link to="/app/config/company-user" className="dropdown-item"><i className="icon-city"></i>Grupos de empresas</Link>
                    ) : null
                }
                <div className="dropdown-divider"></div>
                <Link to="#" onClick={() => signOut(userDispatch, props.history)} className="dropdown-item"><i className="icon-switch2"></i> Logout</Link>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}


function _validacion_tiempo_transcurrido_(i) {
  if (i.anio === 0 && i.meses === 0 && i.semanas === 0 && i.dias === 0 && i.horas === 0 && i.minutos !== 0) {
    return "hace " + ((i.minutos) * -1) + " minutos ";
  }
  else if (i.anio === 0 && i.meses === 0 && i.semanas === 0 && i.dias === 0 && i.horas !== 0 && i.minutos !== 0) {
    if (((i.horas) * -1) === 1)
      return "hace " + ((i.horas) * -1) + " hora ";
    else
      return "hace " + ((i.horas) * -1) + " horas ";
  }
  else if (i.anio === 0 && i.meses === 0 && i.semanas === 0 && i.dias !== 0 && i.horas !== 0 && i.minutos !== 0) {
    if (((i.dias) * -1) === 1)
      return "hace " + ((i.dias) * -1) + " día ";
    else
      return "hace " + ((i.dias) * -1) + " días ";
  }
  else if (i.anio === 0 && i.meses === 0 && i.semanas !== 0 && i.dias !== 0 && i.horas !== 0 && i.minutos !== 0) {
    if (((i.semanas) * -1) === 1)
      return "hace " + ((i.semanas) * -1) + " semana ";
    else
      return "hace " + ((i.semanas) * -1) + " semanas ";
  }
  else if (i.anio === 0 && i.meses !== 0 && i.semanas !== 0 && i.dias !== 0 && i.horas !== 0 && i.minutos !== 0) {
    if (((i.meses) * -1) === 1)
      return "hace " + ((i.meses) * -1) + " mes ";
    else
      return "hace " + ((i.meses) * -1) + " meses ";
  }
  else if (i.anio !== 0 && i.meses !== 0 && i.semanas !== 0 && i.dias !== 0 && i.horas !== 0 && i.minutos !== 0) {
    if (((i.anio) * -1) === 1)
      return "hace " + ((i.anio) * -1) + " año ";
    else
      return "hace " + ((i.anio) * -1) + " años ";
  }
}