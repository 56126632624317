import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
const sorter = (a, b) => {
    if (a.ejercicio !== b.ejercicio) {
        return a.ejercicio - b.ejercicio;
    } else {
        return months.indexOf(a.periodo) - months.indexOf(b.periodo);
    };
};

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'none',
        }
    },
    tableRightBorder: {
        backgroundColor: "#262d3c"
    },
});

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const [link, setLink] = React.useState('');
    const classes = useRowStyles();

    const viewcomparativo = (i) => {
        /// console.log(i);
        props.viewreport({
            ejercicio: i.ejercicio,
            empresa_id: i.empresa_id,
            periodo: i.empresa_id,
        })
        // console.log(props)
    }

    return (
        <React.Fragment>
            <tr className={classes.root}
                hover
                tabIndex={-1}>
                <th>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </th>
                <th style={{ color: 'white' }} component="th" scope="row">{row.ejercicio}</th>
                <th style={{ color: 'white' }} align="center">{row.namemonth}</th>
                <th style={{ color: 'white' }} align="center"><button type="button" className="btn btn-link" onClick={() => viewcomparativo(row)}><i className="icon-file-xml mr-2" /> Ver comparativo</button></th>
            </tr>
            <tr>
                <td style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <span style={{ color: 'white', fontSize: 14 }}>Detalle de los errores</span>
                            <table className="table">
                                <thead>
                                    <tr style={{ color: '#43a76f' }}>
                                        <td>Descripción</td>
                                    </tr>
                                </thead>
                                <tbody>

                                    {(row.details != undefined) ? (
                                        (row.details != undefined).map((s, index) => (
                                            <tr key={index}>
                                                <td style={{ fontSize: 13 }}>{parse(s.report)}</td>
                                            </tr>
                                        ))
                                    ) : null
                                    }
                                </tbody>
                            </table>
                        </Box>
                    </Collapse>
                </td>
            </tr>
        </React.Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        ejercicio: PropTypes.string.isRequired,
        periodo: PropTypes.string.isRequired,
        namemonth: PropTypes.string.isRequired,
        details: PropTypes.array.isRequired,


    }).isRequired,
};

export default function CollapsibleTable(props) {
    let pareme = JSON.parse(localStorage.getItem("parameterscompany"));
    const classes = useRowStyles();
    const [status, setStatus] = React.useState(true);
    const [reports, setViewReport] = React.useState(false);
    const [urls, setUrls] = React.useState('');
    const [compan, setCompany] = React.useState(pareme);
    let rows = (props.dt);
    if (rows.length > 0) {
        (rows.sort(sorter))
        for (let za = 0; za < rows.length; za++) {
            rows[za].namemonth = (rows[za].periodo === "01" ? "Enero" : (rows[za].periodo === "02" ? "Febrero" : (rows[za].periodo === "03" ? "Marzo" : (rows[za].periodo === "04" ? "Abril" : (rows[za].periodo === "05" ? "Mayo" : (rows[za].periodo === "06" ? "Junio" : (rows[za].periodo === "07" ? "Julio" : (rows[za].periodo === "08" ? "Agosto" : (rows[za].periodo === "09" ? "Septiembre" : (rows[za].periodo === "10" ? "Octubre" : (rows[za].periodo === "11" ? "Noviembre" : "Diciembre")))))))))))
        }
    }
    useEffect(() => {
        ///setStatus(false);
    }, [])
    const viewcomparativo = (t) => {
        props.title('Compartivo de las balanzas generadas e importadas de ' + t.namemonth + ' de ' + t.ejercicio)
        setStatus(false);
        setViewReport(true);
        setUrls('https://e-xpertum.com.mx/invoces/blz-comparate?empresa_id=' + compan.id + '&ejercicio=' + t.ejercicio + '&periodo=' + t.periodo + '&id=' + compan.empresa_id);
        //setUrls('http://localhost:3004/blz-comparate?empresa_id=' + compan.id + '&ejercicio=' + t.ejercicio + '&periodo=' + t.periodo + '&id=' + compan.empresa_id);
    }
    return (
        <>
            {status ? (
                <div component={Paper} className="card-body">
                    <table aria-label="collapsible table" className="table">
                        <thead>
                            <tr>
                                <th></th>
                                <th style={{ color: 'white' }}>Ejercicio</th>
                                <th style={{ color: 'white' }} align="center">Periodo</th>
                                <th style={{ color: 'white' }} align="center"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {(rows || []).map((row, index) => (
                                <tr key={index}>
                                    <td></td>
                                    <th style={{ color: 'white' }} component="th" scope="row">{row.ejercicio}</th>
                                    <th style={{ color: 'white' }} align="center">{row.namemonth}</th>
                                    <th style={{ color: 'white' }} align="center"><button type="button" className="btn btn-link" onClick={() => viewcomparativo(row)}><i className="icon-file-xml mr-2" /> Ver Error</button></th>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : null
            }
            <div>
                {
                    reports ? (
                        <>
                            <div className='containeriframe'>
                                <iframe className='responsiveiframeall scroll scroll1'
                                    src={urls}
                                    frameBorder={0} allowFullScreen
                                />
                            </div>
                        </>
                    ) : null
                }
            </div>

        </>

    );
}
