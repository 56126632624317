import React, { Component } from 'react';
import apiFile from '../../Config/apifiles';
class ReadCfdiCounts extends Component {
    constructor(props) {
        super(props);
        let _data_ = JSON.parse(localStorage.getItem("panelconfig"));
        this.state = {
            reset: true,
            readcfdi: true,
            donwloadfiles: true,
            readfiles: true,
            acuses: true,
            rfc: _data_.rfc,
            id: _data_.empres_id,
            array_read_cfdi: 0,
        }
    }
    handleResetConfig = async () => {
        this.setState({ reset: false });
        setTimeout(() => {
            this.setState({ reset: true });
        }, 4000);
    }
    handleReadCfdis = async () => {
        this.setState({ readcfdi: 3 });
        let app = await apiFile.post('read-cfdis-json-invoce', this.state);
        console.log(app.data)
        const _app_clear = setInterval(() => {
            if (this.state.readcfdi === 2)
                clearInterval(_app_clear);
            this.getstatusall();
        }, 40000);
        // this.setState({ readcfdi: 2 });

    }
    handleDownloadFiles = async () => {
        this.setState({ donwloadfiles: false });
        let app = await apiFile.post('read-acuses-app-audite', this.state);
        console.log(app.data)
        setTimeout(() => {
            this.setState({ donwloadfiles: true });
        }, 4000);

    }
    handleMapearAcuses = async () => {
        let app = await apiFile.post('read-acuses-manual', this.state);
        console.log(app.data)
        this.setState({ acuses: false });
        setTimeout(async () => {
            let result_ = await apiFile.post("read-pdf-positive-balance", { id: this.state.id, rfc: this.state.rfc });
            console.log(result_.data)
            this.setState({ acuses: true });
        }, 4000);
    }
    componentDidMount() {
        this.getstatusall();
    }
    getstatusall = async () => {
        let app = await apiFile.post('status-read-cfdi-json-invoce', this.state);
        let _array_ = await app.data;
        if (_array_.length > 0) {
            if ((_array_.filter((t) => t.status === "START")).length > 0)
                this.setState({ readcfdi: 1 });
            if ((_array_.filter((t) => t.status === "END")).length > 0)
                this.setState({ readcfdi: 2 });
            if ((_array_.filter((t) => t.status === "PROCCESS")).length > 0)
                this.setState({ readcfdi: 3 });
        }
    }
    render() {
        return (
            <div className="content pt-0">
                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <div className="card">
                            <div className="card-header bg-transparent border-bottom">
                                <h6 className="card-title">
                                    <i className="icon-cog3 mr-2" />
                                    Activar tareas
                                </h6>
                            </div>
                            <div className="card-body">
                                <form>
                                    <div className="form-group">
                                        {
                                            this.state.reset ? (
                                                <button type="button" className="btn btn-light" onClick={this.handleResetConfig}>
                                                    <i className="icon-make-group mr-2" />Restaurar configuración
                                                </button>
                                            ) :
                                                (
                                                    <button type="button" className="btn btn-indigo btn-float">
                                                        <i className="icon-spinner4 spinner" /> <span>Restaurando ...</span>
                                                    </button>
                                                )
                                        }
                                    </div>
                                    <div className="form-group">
                                        {
                                            this.state.readcfdi === 1 ? (
                                                <button type="button" className="btn btn-success" onClick={this.handleReadCfdis}>
                                                    <i className="icon-make-group mr-2" /> Mapear los cfdi's
                                                </button>
                                            ) :
                                                (
                                                    this.state.readcfdi === 3 ?
                                                        (<button type="button" className="btn btn-indigo btn-float">
                                                            <i className="icon-spinner4 spinner" /> <span>Leyendo ...</span>
                                                        </button>) : (<button type="button" className="btn btn-light" onClick={this.handleReadCfdis}>
                                                            <i className="icon-make-group mr-2" /> Mapear los cfdi's
                                                        </button>)

                                                )
                                        }
                                    </div>
                                    <div className="form-group">
                                        {
                                            this.state.donwloadfiles ? (
                                                <button type="button" className="btn btn-light" onClick={this.handleDownloadFiles}>
                                                    <i className="icon-make-group mr-2" /> Descargar archivos
                                                </button>
                                            ) :
                                                (
                                                    <button type="button" className="btn btn-indigo btn-float">
                                                        <i className="icon-spinner4 spinner" /> <span>Descargando files ...</span>
                                                    </button>
                                                )
                                        }
                                    </div>
                                    <div className="form-group">
                                        {
                                            this.state.acuses ? (
                                                <button type="button" className="btn btn-light" onClick={this.handleMapearAcuses}>
                                                    <i className="icon-make-group mr-2" /> Mapear acuses
                                                </button>
                                            ) :
                                                (
                                                    <button type="button" className="btn btn-indigo btn-float">
                                                        <i className="icon-spinner4 spinner" /> <span>Mapeando acuses ...</span>
                                                    </button>
                                                )
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="card">
                            <div className="card-header bg-transparent border-bottom">
                                <h6 className="card-title">
                                    <i className="icon-cog3 mr-2" />
                                    Listado de actividades
                                </h6>
                            </div>
                            <div className="card-body">
                                <form>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default ReadCfdiCounts;