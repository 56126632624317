import React, { Component } from 'react';
import api from '../../Config/audit-data';
class BalanceAfter extends Component {
    constructor(props) {
        super(props);
        let objects = JSON.parse(localStorage.getItem("datacompanyall"));
        this.state = {
            empresa_id: objects.id,
            data: []
        }
    }
    componentDidMount() {
        this.get_balance();
    }
    get_balance = async () => {
        let _data = await api.post('get-report-annual-clousure', { empresa_id: this.state.empresa_id });
        this.setState({
            data: (_data.data).jsonData_posicion_financiera
        })
    }
    handledownloadfiles = () => {
        window.open("https://e-xpertum.com.mx/invoces/api/export-to-excel-data/" + this.state.empresa_id + "/SFP");
    }
    render() {
        return (
            <>
                <div className="card" key={"eoioiorer"}>
                    <div className="card-header bg-transparent border-bottom header-elements-inline py-0">
                        <h6 className="card-title py-3" style={{ textAlign: 'center' }}>
                            Balance general basado en las declaraciones anuales
                        </h6>
                        <div className="header-elements">
                            <button type="button" className="btn btn-light" onClick={this.handledownloadfiles} ><i className="icon-file-excel mr-2 text-success"></i> Excel</button>
                        </div>
                    </div>
                    <div className="card-body">
                        <table className='table'>
                            <tbody>
                                {
                                    (this.state.data || []).map((tr, index) => (
                                        <tr key={index}>
                                            {
                                                index > 1 ?
                                                    <>
                                                        {(tr.notas === "" && tr.ejercicio_2021 === "" && tr.ejercicio_2022 === "") ?
                                                            (
                                                                <td colSpan={3} className='text-center'> {tr.descripcion}</td>
                                                            ) :
                                                            (
                                                                <>
                                                                    <td>{tr.descripcion}</td>
                                                                    <td className='text-right'>{tr.ejercicio_2022 != "" ? ((!isNaN(tr.ejercicio_2022)) ? parseFloat(tr.ejercicio_2022).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : tr.ejercicio_2022) : ""}</td>
                                                                    <td className='text-right'>{tr.ejercicio_2021 != "" ? ((!isNaN(tr.ejercicio_2021)) ? parseFloat(tr.ejercicio_2021).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : tr.ejercicio_2021) : ""}</td>
                                                                </>
                                                            )}
                                                    </> : null
                                            }
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    }
}

export default BalanceAfter;