import React, { Component } from 'react'
import api from 'axios';
import Skeleton from '@material-ui/lab/Skeleton';
import { PacmanLoader } from "react-spinners";
import DetailsIvaTrasladado from './details-iva';
//import api from '../app/Config/apifiles';
class ReportIVAAcreditable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            dataToYear: [],
            viewyear: true,
            viewmonths: false,
            viewmonthsdetails: false,
            skeletonyear: true,
            skeletonmonth: false,
            empresa_id: 50395,
            params_: { key: "", month: "", year: 0 },
            year: 0,
            title: "Reporte de IVA Trasladado",
            url_pdf: ""
        }
    }
    componentDidMount() {
        this.handleGetData();
    }
    handleGetData = async () => {
        let _params_ = { empresa_id: this.state.empresa_id }
        await api.post('http://localhost:3020/api/get-report-to-general-iva-acreditable', _params_).then((result) => {
            let data_ = result.data;
            this.setState({ data: data_, skeletonyear: false });
        }).catch((e) => {
            console.log(e)
        });
    }

    handleGetDataToYear = async (exer) => {
        setTimeout(async () => {
            let _params_ = {
                empresa_id: this.state.empresa_id,
                year: exer.year
            }
            await api.post('http://localhost:3020/api/get-report-to-general-iva-acreditable-whit-year', _params_).then((result) => {
                let data_ = result.data;
                this.setState({
                    year: exer.year,
                    dataToYear: data_, viewyear: false,
                    skeletonyear: false, skeletonmonth: false
                });
            }).catch((e) => {
                console.log(e)
            })
        }, 2000);
    }
    handleViewPDF = async () => {
        this.setState({
            url_pdf: "https://e-xpertum.com.mx/adminfile/api/get-report-to-general-IVA/" + this.state.empresa_id + "/" + (Date.now()) + "/" + (Date.now())
        })
        window.$("#view_pdf_year_month").modal("show");
    }

    render() {
        return (
            <div className="content-wrapper">
                {/* Inner content */}
                <div className="content-inner">
                    {/* Page header */}
                    <div className="page-header border-bottom-0">
                        <div className="page-header-content header-elements-md-inline">
                            <div className="page-title d-flex">
                                <h4><i className="icon-arrow-left52 mr-2" /> <span className="font-weight-semibold">Inicio</span></h4>
                                <a href="#" className="header-elements-toggle text-body d-lg-none"><i className="icon-more" /></a>
                            </div>
                            <div className="header-elements d-none mb-3 mb-lg-0">
                                <div className="d-flex justify-content-center">
                                    {
                                        (this.state.viewyear || this.state.viewmonths) ? (
                                            <button className="btn btn-link btn-float text-body" onClick={this.handleViewPDF} >
                                                <i className="icon-file-pdf ml-2 text-danger" /> <span>PDF</span>
                                            </button>
                                        ) : null
                                    }
                                    {
                                        (this.state.viewmonthsdetails) ? (
                                            <button className="btn btn-link btn-float text-body"
                                                onClick={() => {
                                                    window.open("https://e-xpertum.com.mx/adminfile/api/get-create-excel-report-to-month/" + this.state.empresa_id + "/" + this.state.params_.key + "/" + this.state.year);
                                                }}>
                                                <i className="icon-file-excel ml-2 text-success" /> <span>Excel</span>
                                            </button>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content pt-0">
                        {/* Collapsible options */}

                        <div className="row">
                            <div className='col-lg-12'>
                                <div className="card">
                                    <div className="card-header bg-transparent border-bottom header-elements-inline py-0"
                                        style={{ height: "65px" }}>
                                        <h6 className="card-title">{this.state.title}</h6>
                                        <div className="header-elements">
                                            {
                                                (this.state.viewmonths) ? (
                                                    <button type="button" onClick={() => {
                                                        this.setState({
                                                            viewmonths: false,
                                                            viewyear: true, title: "Reporte de IVA Acreditable"
                                                        })
                                                    }} className="btn btn-link"><i className="icon-arrow-left16 mr-3 icon-2x"></i> Inicio</button>
                                                ) : null
                                            }
                                            {
                                                (this.state.viewmonthsdetails) ? (
                                                    <button type="button" onClick={() => {
                                                        this.setState({
                                                            viewmonths: true,
                                                            viewyear: false, title: "Reporte de IVA Acreditable de " + this.state.year,
                                                            viewmonthsdetails: false
                                                        })
                                                    }} className="btn btn-link"><i className="icon-arrow-left16 mr-3 icon-2x"></i> Detalle</button>
                                                ) : null
                                            }
                                        </div>
                                    </div>

                                    <div className="card-body">
                                        {
                                            this.state.viewyear ? (
                                                <div>
                                                    {
                                                        this.state.skeletonyear ? (
                                                            <div className="card">
                                                                <Skeleton />
                                                                <div style={{
                                                                    margin: 40,
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                }}>
                                                                    <PacmanLoader color={"#ff7043"} loading={this.state.skeletonyear} size={25} margin={2} />
                                                                </div>
                                                                <Skeleton variant="circle" animation="wave" width={40} height={40} />
                                                                <Skeleton variant="rect" animation="wave" width={"100%"} height={118} />
                                                                <Skeleton animation="wave" />
                                                            </div>
                                                        ) : (
                                                            <table className='table'>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Ejercicio</th>
                                                                        <th>Método de pago</th>
                                                                        <th>IVA Acreditable pago</th>
                                                                        <th>IVA Acreditable pendiente de pago</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        (this.state.data || []).map((d, index) => (
                                                                            <tr key={index}>
                                                                                <td>
                                                                                    <button type="button" style={{ width: "80%" }} onClick={() => {
                                                                                        this.setState({
                                                                                            viewyear: false,
                                                                                            viewmonths: true,
                                                                                            skeletonmonth: true,
                                                                                            title: "Reporte de IVA Acreditable de " + d.year
                                                                                        })
                                                                                        this.handleGetDataToYear(d)
                                                                                    }} className="btn btn-outline-primary btn-lg">
                                                                                        <i className="icon-file-xml mr-2"></i>{d.year}
                                                                                    </button>
                                                                                </td>
                                                                                <td>{d.metodo_pago}</td>
                                                                                <td className='text-right'>{parseFloat(d.iva_trasladado_cobrado).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                                                <td className='text-right'>{parseFloat(d.iva_trasladado_pendiente_cobro).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        )
                                                    }
                                                </div>
                                            ) : null
                                        }

                                        {
                                            (this.state.viewmonths) ? (
                                                <>
                                                    {
                                                        this.state.skeletonmonth ? (
                                                            <div className="card">
                                                                <Skeleton />
                                                                <div style={{
                                                                    margin: 40,
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                }}>
                                                                    <PacmanLoader color={"#ff7043"} loading={this.state.skeletonmonth} size={25} margin={2} />
                                                                </div>
                                                                <Skeleton variant="circle" animation="wave" width={40} height={40} />
                                                                <Skeleton variant="rect" animation="wave" width={"100%"} height={118} />
                                                                <Skeleton animation="wave" />
                                                            </div>
                                                        ) : (
                                                            <table className='table'>
                                                                <thead>
                                                                    <tr>
                                                                        <th></th>
                                                                        <th>Método de pago</th>
                                                                        <th>Clase cfdi</th>
                                                                        <th>IVA Acreditable pago</th>
                                                                        <th>IVA Acreditable pendiente de pago</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        (this.state.dataToYear || []).map((j, index) => (
                                                                            <>
                                                                                <tr>
                                                                                    <td rowSpan={2}>
                                                                                        <button type="button" style={{ width: "100%" }}
                                                                                            onClick={() => {
                                                                                                j.year = this.state.year
                                                                                                this.setState({
                                                                                                    viewyear: false,
                                                                                                    viewmonths: false,
                                                                                                    viewmonthsdetails: true,
                                                                                                    skeletonyear: false,
                                                                                                    skeletonmonth: false,
                                                                                                    params_: j,
                                                                                                    title: "Reporte de IVA Acreditable de  " + j.month + " de " + this.state.year
                                                                                                })
                                                                                            }}
                                                                                            className="btn btn-outline-primary btn-lg">
                                                                                            <i className="icon-file-xml mr-2" />
                                                                                            {j.month}
                                                                                        </button>
                                                                                    </td>
                                                                                    <td>{j.metodo_pago}</td>
                                                                                    <td>{j.tipo_de_comprobantePUE}</td>
                                                                                    <td className='text-right'>{parseFloat(j.iva_trasladado_cobrado_pue).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                                                    <td className='text-right'>{parseFloat(j.iva_trasladado_pendiente_cobro_pue).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>{j.metodo_pago}</td>
                                                                                    <td>{j.tipo_de_comprobantePPD}</td>
                                                                                    <td className='text-right'>{parseFloat(j.iva_trasladado_cobrado_ppd).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                                                    <td className='text-right'>{parseFloat(j.iva_trasladado_pendiente_cobro_ppd).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                                                </tr>
                                                                            </>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        )
                                                    }
                                                </>
                                            ) : null
                                        }
                                        {
                                            this.state.viewmonthsdetails ? (
                                                <DetailsIvaTrasladado params={this.state.params_} />
                                            ) : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="view_pdf_year_month" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                            <div className="modal-dialog modal-full">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Ver reporte</h5>
                                        <button type="button" className="close" data-dismiss="modal">×</button>
                                    </div>
                                    <div className="modal-body">
                                        <div className='containeriframe'>
                                            <iframe className='responsiveiframeall scroll scroll1' title='ver pdf'
                                                src={this.state.url_pdf}
                                                frameBorder={0} allowFullScreen
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{ height: 30 }}></div>
                    </div>
                </div>
            </div>

        )
    }
}
export default ReportIVAAcreditable;
