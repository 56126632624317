import React, { Component, Fragment } from 'react'
import ReactDatatable from '@ashvin27/react-datatable';
import api from '../../../app/Config/api';
//import './style.css'
class index extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "nombre",
                text: "Nombre, Denominación o razón social",
                className: "name",
                align: "left",
                sortable: true,
            },
            {
                key: "rfc",
                text: "RFC",
                className: "address",
                align: "left",
                sortable: true
            },
            {
                key: "descripcion",
                text: "Nombre del servicio",
                className: "postcode",
                sortable: true
            },
            {
                key: "action",
                text: "Acción",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                            <button
                                className="btn btn-info btn-sm"
                                onClick={() => this.navegationadmin(record)}>
                                <i className="fas fa-sign-in-alt"></i>
                            </button>
                        </Fragment>
                    );
                }
            }
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,
            button: {
                excel: true,
                print: true,
            },
            language: {
                length_menu: "Mostrar _MENU_ registros por página",
                filter: "Buscar en registros ...",
                info: "Mostrando _START_ a _END_ de _TOTAL_ entradas",
                pagination: {
                    first: "Primero",
                    previous: "Previa",
                    next: "próximo",
                    last: "Última"
                }
            },
            no_data_text: "No se encontraron registros"
        }

        this.state = {
            records: []
        }
        this.extraButtons = [
            {
                className: "btn btn-primary buttons-pdf",
                title: "Export TEst",
                children: [
                    <span>
                        <i className="glyphicon glyphicon-print fa fa-print" aria-hidden="true"></i>
                    </span>
                ],
                onClick: (event) => {
                    console.log(event);
                },
            },
            {
                className: "btn btn-primary buttons-pdf",
                title: "Export TEst",
                children: [
                    <span>
                        <i className="glyphicon glyphicon-print fa fa-print" aria-hidden="true"></i>
                    </span>
                ],
                onClick: (event) => {
                    console.log(event);
                },
                onDoubleClick: (event) => {
                    console.log("doubleClick")
                }
            },
        ]
        //this.state = {};
    }
    componentDidMount() {
        this.infodata();
    }
    async infodata() {

        const data_i = await api.post("/listcompanynomina", { url: '/app/ui/adminjuridico' });
        const { data } = await data_i;
        console.log(data)
        this.setState({ records: data });
    }
    navegationadmin(record) {
        localStorage.removeItem("setdatanomina");
        localStorage.setItem("setdatanomina", JSON.stringify(record));
        //const history = useHistory();
        const { history } = this.props;
        history.push('/app/ui/admin&details1838juridico');
    }
    render() {
        return (
            <>
                <div className="content-wrapper">
                    <div className="page-header border-bottom-0">
                        <div className="page-header-content header-elements-md-inline">
                            <div className="page-title d-flex">
                                <a href="#" style={{ color: 'white' }}> <h4><i className="icon-arrow-left52 mr-2"></i> <span className="font-weight-semibold">Panel</span></h4></a>
                                <a href="#" className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></a>
                            </div>
                        </div>
                    </div>
                    <div className="content pt-0">
                        <div className="card">
                            <div className="card-header header-elements-inline">
                                <h5 className="card-title">Listado de clientes "Jurídico"</h5>
                            </div>

                            <div className="card-body">
                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.records}
                                    columns={this.columns}
                                    extraButtons={this.extraButtons}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </>
        );
    }
}

export default index;