import React, { Component } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import Skeleton from '@material-ui/lab/Skeleton';
import api from '../Config/api'
//import ChartsA from './charts';
class Proveedor extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "nombre",
                text: "Nombre, Denominación o razón social",
                className: "nombre",
                align: "left",
                sortable: true,
            },
            {
                key: "rfc",
                text: "RFC",
                className: "rfc",
                align: "left",
                sortable: true
            }, {
                key: "importe",
                text: "Importes",
                className: "importe",
                sortable: true,
                cell: record => {
                    return (
                        <div className="text-right">
                            <p style={{ fontSize: 14 }}>{(record.importe) === 0 ? '-' : (record.importe).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>

                        </div>
                    );
                }
            }, {
                key: "estatus",
                text: "Estatus",
                className: "estatus",
                sortable: true
            },
            /* {
                 key: 'calificacion',
                 text: 'Calificación',
                 classNames: 'calificacion',
                 cell: record => {
                     return (
                         <div style={{ paddingLeft: 10 }}>
                             <ChartsA />
                         </div>
                     )
                 }
             }*/
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,
            button: {
                excel: true,
                print: true,
                //extra: true,
            },
            language: {
                length_menu: "Mostrar _MENU_ registros por página",
                filter: "Buscar en registros ...",
                info: "Mostrando _START_ a _END_ de _TOTAL_ entradas",
                pagination: {
                    first: "Primero",
                    previous: "Previa",
                    next: "próximo",
                    last: "Última"
                }
            },
            no_data_text: "No se encontraron registros"
        }
        this.state = {
            data: [],
            nombre: '',
            skeleton: true
        }
    }
    componentDidMount() {
        this.getlist();
    }
    getlist = async () => {
        let data_ = JSON.parse(localStorage.getItem("datavalidate"));

        let data = await api.post("catproveedores", { empresa_id: data_.id });
        this.setState({ data: data.data, nombre: data_.nombre, skeleton: false })
    }
    render() {
        return (
            <>
                <div className="content-wrapper">
                    <div className="page-header border-bottom-0">
                        <div className="page-header-content header-elements-md-inline">
                            <div className="page-title d-flex">
                                <a href="#/app/ui/69b" style={{ color: 'white' }}><h4><i className="icon-arrow-left52 mr-2" /> <span className="font-weight-semibold">Listado de empresas</span></h4></a>
                                <a href="#" className="header-elements-toggle text-default d-md-none"><i className="icon-more" /></a>
                            </div>
                            <h6>{this.state.nombre}</h6>
                            <div className="header-elements d-none mb-3 mb-md-0">
                                <div className="d-flex justify-content-center" style={{ display: 'none' }}>
                                    <a href="#" className="btn btn-link btn-float text-default"></a>
                                    <a href="#" className="btn btn-link btn-float text-default"></a>
                                    <a href="#" className="btn btn-link btn-float text-default"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        (this.state.skeleton) ?
                            (
                                <div className="page-content">
                                    <div className="content-wrapper">
                                        <div className="content pt-0">
                                            <br></br>
                                            <br></br>
                                            <div className="card">
                                                <Skeleton animation="wave" variant="text" height={40} />
                                                <Skeleton animation="wave" variant="text" height={40} />
                                                <Skeleton animation="wave" variant="text" height={40} />
                                                <Skeleton animation="wave" variant="text" height={40} />
                                                <Skeleton animation="wave" variant="text" height={40} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) :
                            (
                                <div className="content pt-0">
                                    <div className="card">
                                        <div className="card-header header-elements-inline">
                                            <h6 className="card-title">Listado de proveedores</h6>
                                            <div className="header-elements">
                                                <div className="list-icons">
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <ReactDatatable
                                                config={this.config}
                                                records={this.state.data}
                                                columns={this.columns}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                    }
                </div>
            </>
        );
    }
}

export default Proveedor;