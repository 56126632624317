import React, { Component } from 'react';
import api from '../../../../app/Config/api';
import Skeleton from '@material-ui/lab/Skeleton';

class DebeHaber extends Component {
    constructor(props) {
        super(props);


        this.state = {
            recorset: [],
            skeleton: true,
        }
    }
    componentDidMount() {
        this.getdata();
    }
    getdata = async () => {
        let pareme = JSON.parse(localStorage.getItem("parameters"));
        let result_ = await api.post("getdetailsdebehaber", pareme);
        let { data } = await result_;

        this.setState({ recorset: data.info, skeleton: false })

    }
    render() {
        return (
            <>
                {
                    this.state.skeleton ? (
                        <div className="card">
                            <Skeleton />
                            <Skeleton animation={false} height={400} />
                            <Skeleton animation="wave" />
                        </div>
                    ) : (
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="text-center">Balanza importada</th>
                                        <th className="text-center">Balanza generada</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (this.state.recorset || []).map((o, p) => (
                                            <tr>
                                                <td>{((o.descripcion).split('====='))[0]}</td>
                                                <td>{((o.descripcion).split('====='))[1]}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        )
                }
            </>
        );
    }
}

export default DebeHaber;