import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export default class Example extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            data: props.d,
        }
    }

    render() {
        return (
            <ResponsiveContainer width={"100%"} height={400}>
                <LineChart style={{ color: "black" }}
                    width={650}
                    height={350}
                    data={this.state.data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <XAxis dataKey="anio" name="Ejercicio" />
                    <YAxis />
                    <Tooltip formatter={(value) => new Intl.NumberFormat('en').format(value)} contentStyle={{ color: 'gray', fontSize: 13 }} />
                    <Legend />
                    <Line type="monotone" dataKey="total" name='Importe pagado' stroke="#82ca9d" activeDot={{ r: 8 }} />
                </LineChart>
            </ResponsiveContainer>
        );
    }
}
