import React, { Component } from 'react'
import api from '../../app/Config/apifiles';
import axios from 'axios';
import TableView from './table-view';
import TableViewFirts from './table-view-firts';
import ReportCFDIAll from '../screen-reports';
import { Tooltip } from "@material-ui/core";
//import ReportInvoceReceived from '../screen-reports/report-invoce-received';
import ReportTypesRelatons from '../screen-reports/report_types_relations';
//import ReportInvoceTreePay from '../screen-reports/report-tree';
import ReportInvoceTreePay from '../screen-reports/report-payment-bills';
import Skeleton from '@material-ui/lab/Skeleton';
import { PacmanLoader } from "react-spinners";
import './style.css';
const nameMonth = ["", "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
let years = [];
for (let l = 2017; l <= ((new Date()).getFullYear()); l++) {
    years.push(l)

}
export default class CedulaAnalitica extends Component {
    constructor(props) {
        super(props)
        let objects = JSON.parse(localStorage.getItem("datacompanyall"));
        this.state = {
            columns: [],
            data: [],
            header: [],
            body: [],
            level: 0,
            view: '',
            adminfiles: 'https://e-xpertum.com.mx/adminfile/',
            //adminfiles: 'http://localhost:3020/',
            years_: years,
            empresa_id: objects.id,
            name: objects.nombre,
            rfc: objects.rfc,
            anio: props.anio, //((new Date()).getFullYear()) - 5,
            subview: '',
            titletmp: '',
            title: 'Determinación del IVA Trasladado del ejercicio ',
            subtitle: '',
            skeleton: false, loading: false, month: '',
            reports: '', viewTypeReport: ''
        }
    }
    componentDidMount() {
        this.handleGetData(this.props.anio);
    }
    handleGetData = async (y) => {
        this.setState({ skeleton: true, loading: true, anio: y });
        let _params_ = { empresa_id: this.state.empresa_id, anio: y }
        //await axios.post('http://localhost:3020/api/v2-report-iva-with-year', _params_).then((result) => {
        await api.post('v2-report-iva-with-year', _params_).then((result) => {
            let { body, header } = result.data;

            this.setState({ columns: header });
            setTimeout(() => {
                this.setState({ data: body, skeleton: false, level: 0, loading: false });
            }, 1000);

        }).catch((e) => {
            console.log(e)
        });
        //https://e-xpertum.com.mx/adminfile/api/v2-report-papers-works/50460/2020
    }
    handleLevelZero = async (y) => {
        this.setState({ view: '', loading: true, skeleton: true, title: "Cedula de la determinación del IVA Trasladado de " + nameMonth[parseInt(y.month)] + " del ", month: y.month });
        let _params_ = { empresa_id: this.state.empresa_id, anio: this.state.anio, mes: y.month }
        await api.post('v2-report-iva-decula-d', _params_).then((result) => {
            let { body, header, titles } = result.data;
            this.setState({ header: header, body: body, skeleton: false, level: 1, subtitle: "Inicio" });
            setTimeout(() => {
                this.setState({
                    view: <TableView header={this.state.header} title={titles} body={this.state.body} handleviewDetails={this.handleDetailsFilter} />
                });
            }, 100);

        }).catch((e) => {
            console.log(e)
        });
    }
    handleDetailsFilter = async (y) => {
        this.setState({
            subview: '', skeleton: true,
            loading: true,
        });
        let _params_ = { empresa_id: this.state.empresa_id, anio: this.state.anio, mes: this.state.month }
        if (y.clave === "CEDULAA" || y.clave === "CEDULAB" || y.clave === "CEDULAC") {
            await api.post(y.endpoint, _params_).then((result) => {
                let { body, header, titles } = result.data;
                setTimeout(() => {
                    this.setState({
                        level: 2,
                        skeleton: false,
                        loading: false,
                        titletmp: y.descripcion + " de " + nameMonth[parseInt(this.state.month)] + " del " + this.state.anio,
                        title: y.descripcion + " de " + nameMonth[parseInt(this.state.month)] + " del " + this.state.anio,
                        subtitle: 'Regresar',
                        subview: <TableViewFirts header={header} body={body} titles={titles} viewreport={this.handleViewReport} />
                    });
                }, 100);
            }).catch((e) => {
                console.log(e)
            });
        }
    }
    handleChangeYear = async (y) => {
        this.handleGetData(y.target.value)
    }
    getDownloadExcel = async () => {
        let url = this.state.adminfiles + "api/get-create-excel-report-to-month/" + this.state.empresa_id + "/" + this.state.anio + "/" + this.state.month;
        window.open(url);
    }
    getDownloadCfdiAllExcel = async () => {
        let url = this.state.adminfiles + "api/v2-report-data-xml-parse-to-month/" + this.state.empresa_id + "/" + this.state.anio + "/" + this.state.month;
        window.open(url);
    }
    handelDownloadPapersWorks = () => {
        let url = this.state.adminfiles + "api/v2-report-papers-works/" + this.state.empresa_id + "/" + this.state.anio + "/" + this.state.name + "-" + this.state.rfc;
        window.open(url);
    }
    handleViewReport = async (r) => {
        this.setState({
            skeleton: true,
            loading: true,
            level: 0
        })
        if (r.clave === "EI0") {
            this.setState({
                skeleton: false,
                loading: false,
                level: 3,
                title: "Cedula analítica de cfdis emitidos de ingreso del mes ",
                viewTypeReport: <ReportCFDIAll link={r.link} empresa_id={this.state.empresa_id} anio={this.state.anio} month={this.state.month} />
            })
        }
        else if (r.clave === "EE-0") {
            this.setState({
                skeleton: false,
                loading: false,
                level: 3,
                title: "Cedula analítica de cfdis emitidos de egresos del mes ",
                viewTypeReport: <ReportCFDIAll link={r.link} empresa_id={this.state.empresa_id} anio={this.state.anio} month={this.state.month} />
            })
        }
        else if (r.clave === "EE-1") {
            await api.post('v2-report-iva-list-relations-types', { empresa_id: this.state.empresa_id, anio: this.state.anio, month: this.state.month }).then((result) => {
                // this.setState({ data: result.data });
                this.setState({
                    skeleton: false,
                    loading: false,
                    level: 3,
                    title: "Cedula analítica de cfdis " + r.descripcion + " del mes " + nameMonth[parseInt(this.state.month)] + " de " + this.state.anio,
                    viewTypeReport: <ReportTypesRelatons data={result.data} />
                })
            }).catch((error) => {
                console.log(error);
            })
        }
        else if (r.clave === "EP-1") {
            await api.post('v2-report-iva-list-invoce-b', { empresa_id: this.state.empresa_id, anio: this.state.anio, month: this.state.month }).then((result) => {
                // this.setState({ data: result.data });
                this.setState({
                    skeleton: false,
                    loading: false,
                    level: 3,
                    title: "Cedula analítica de cfdis " + r.descripcion + " del mes " + nameMonth[parseInt(this.state.month)] + " de " + this.state.anio,
                    viewTypeReport: <ReportInvoceTreePay data={result.data} />
                })
            }).catch((error) => {
                console.log(error);
            })
        }
        else {
            this.setState({
                skeleton: false,
                loading: false
            })
        }
    }
    render() {
        return (
            <div className="content-wrapper">
                <div className="content-inner">
                    <div className="content pt-3">
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className="card">
                                    <div className="card-header bg-transparent border-bottom header-elements-inline py-0"
                                        style={{ height: "65px" }}>
                                        {
                                            this.state.level === 0 ? (
                                                <>
                                                    <h6 className="card-title">{this.state.title + " " + this.state.anio}</h6>
                                                    <Tooltip title="Click para descargar el papel de trabajo" placement="top" arrow>
                                                        <button type="button" className="btn btn-outline-success" onClick={this.handelDownloadPapersWorks}>
                                                            <i className="icon-file-excel" />
                                                        </button>
                                                    </Tooltip>
                                                </>
                                            ) : null
                                        }
                                        {
                                            this.state.level === 1 ? (
                                                <>
                                                    <h6 className="card-title">{this.state.title + " " + this.state.anio}</h6>
                                                    <div className="header-elements">
                                                        <Tooltip title="Click para descargar el reporte de cfdis" placement="top" arrow>
                                                            <button type="button" className="btn btn-outline-success" onClick={this.getDownloadCfdiAllExcel}>
                                                                <i className="icon-file-excel" />
                                                            </button>
                                                        </Tooltip>
                                                        <button type="button" className="btn btn-link" onClick={() => this.setState({
                                                            level: 0, subview: '',
                                                            title: "Determinación del Impuesto al Valor Agregado de"
                                                        })}>
                                                            <i className="icon-arrow-left16 mr-3 icon-2x"></i> {this.state.subtitle}</button>
                                                    </div>
                                                </>
                                            ) : null
                                        }
                                        {
                                            this.state.level === 2 ? (
                                                <>
                                                    <h6 className="card-title">{this.state.title}</h6>
                                                    <div className="header-elements">
                                                        <button type="button" className="btn btn-link" onClick={() => this.setState({
                                                            level: 1, subview: '',
                                                            subtitle: "Inicio",
                                                            title: "Cedula de la determinación del IVA Trasladado de " + nameMonth[parseInt(this.state.month)] + " del "
                                                        })}><i className="icon-arrow-left16 mr-3 icon-2x"></i> {this.state.subtitle}</button>
                                                    </div>
                                                </>
                                            ) : null
                                        }
                                        {
                                            this.state.level === 3 ? (
                                                <>
                                                    <h6 className="card-title">{this.state.title}</h6>
                                                    <div className="header-elements">
                                                        <button type="button" className="btn btn-link" onClick={() => this.setState({
                                                            level: 2, viewTypeReport: '',
                                                            subtitle: "Inicio",
                                                            title: this.state.titletmp,
                                                        })}><i className="icon-arrow-left16 mr-3 icon-2x"></i> {this.state.subtitle}</button>
                                                    </div>
                                                </>
                                            ) : null
                                        }

                                    </div>

                                    <div className="card-body">
                                        {this.state.skeleton ? (
                                            <div className="card">
                                                <Skeleton />
                                                <div style={{
                                                    margin: 40,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}>
                                                    <PacmanLoader color={"#ff7043"} loading={this.state.loading} size={25} margin={2} />
                                                </div>
                                                <Skeleton variant="circle" animation="wave" width={40} height={40} />
                                                <Skeleton variant="rect" animation="wave" width={"100%"} height={118} />
                                                <Skeleton animation="wave" />
                                            </div>
                                        ) : (
                                            <>
                                                {
                                                    (this.state.level === 0) ? (
                                                        <div className="content" style={{ width: "100%" }}>
                                                            <div className='wrapper' style={{ marginLeft: "250px" }}>
                                                                <table className='table'>
                                                                    <thead>
                                                                        <tr>
                                                                            {
                                                                                (this.state.columns || []).map((tr, index) => (
                                                                                    (index === 0) ? (
                                                                                        <th className='fixed' style={{ left: "10px" }} key={index}>{tr.title}</th>
                                                                                    ) :
                                                                                        <th key={index}>{tr.subtitle}</th>
                                                                                ))
                                                                            }
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            (this.state.data || []).map((tbody, index) => (
                                                                                tbody.status ? (
                                                                                    <tr key={index} style={{ height: (String(tbody.description).length > 71 ? "60px" : 0) }}>
                                                                                        <td className='fixed' style={{ left: "10px" }}>{tbody.description}</td>
                                                                                        <td className='text-right'>{(tbody.enero === 0 ? '-' :
                                                                                            (tbody.clave === "DL43" ? <span className='text-success'> {(tbody.enero).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span> :
                                                                                                <Tooltip title="Click para ver detalle" placement="top" arrow>
                                                                                                    <button className='btn btn-link' onClick={() => this.handleLevelZero({ tbody, month: '01' })}> {(tbody.enero).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                    </button>
                                                                                                </Tooltip>
                                                                                            )
                                                                                        )}
                                                                                        </td>
                                                                                        <td className='text-right'>{(tbody.febrero === 0 ? '-' :
                                                                                            (tbody.clave === "DL43" ? <span className='text-success'> {(tbody.febrero).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span> :
                                                                                                <button className='btn btn-link' onClick={() => this.handleLevelZero({ tbody, month: '02' })}> {(tbody.febrero).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                </button>)
                                                                                        )}
                                                                                        </td>
                                                                                        <td className='text-right'>{(tbody.marzo === 0 ? '-' :
                                                                                            (tbody.clave === "DL43" ? <span className='text-success'> {(tbody.marzo).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span> :
                                                                                                <Tooltip title="Click para ver detalle" placement="top" arrow>
                                                                                                    <button className='btn btn-link' onClick={() => this.handleLevelZero({ tbody, month: '03' })}> {(tbody.marzo).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                    </button>
                                                                                                </Tooltip>
                                                                                            )
                                                                                        )}
                                                                                        </td>
                                                                                        <td className='text-right'>{(tbody.abril === 0 ? '-' :
                                                                                            (tbody.clave === "DL43" ? <span className='text-success'> {(tbody.abril).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span> :
                                                                                                <button className='btn btn-link' onClick={() => this.handleLevelZero({ tbody, month: '04' })}> {(tbody.abril).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                </button>)
                                                                                        )}
                                                                                        </td>
                                                                                        <td className='text-right'>{(tbody.mayo === 0 ? '-' :
                                                                                            (tbody.clave === "DL43" ? <span className='text-success'> {(tbody.mayo).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span> :
                                                                                                <Tooltip title="Click para ver detalle" placement="top" arrow>
                                                                                                    <button className='btn btn-link' onClick={() => this.handleLevelZero({ tbody, month: '05' })}> {(tbody.mayo).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                    </button>
                                                                                                </Tooltip>)
                                                                                        )}
                                                                                        </td>
                                                                                        <td className='text-right'>{(tbody.junio === 0 ? '-' :
                                                                                            (tbody.clave === "DL43" ? <span className='text-success'> {(tbody.junio).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span> :
                                                                                                <Tooltip title="Click para ver detalle" placement="top" arrow>
                                                                                                    <button className='btn btn-link' onClick={() => this.handleLevelZero({ tbody, month: '06' })}> {(tbody.junio).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                    </button>
                                                                                                </Tooltip>)
                                                                                        )}
                                                                                        </td>
                                                                                        <td className='text-right'>{(tbody.julio === 0 ? '-' :
                                                                                            (tbody.clave === "DL43" ? <span className='text-success'> {(tbody.julio).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span> :
                                                                                                <Tooltip title="Click para ver detalle" placement="top" arrow>
                                                                                                    <button className='btn btn-link' onClick={() => this.handleLevelZero({ tbody, month: '07' })}> {(tbody.julio).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                    </button>
                                                                                                </Tooltip>)
                                                                                        )}
                                                                                        </td>
                                                                                        <td className='text-right'>{(tbody.agosto === 0 ? '-' :
                                                                                            (tbody.clave === "DL43" ? <span className='text-success'> {(tbody.agosto).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span> :
                                                                                                <Tooltip title="Click para ver detalle" placement="top" arrow>
                                                                                                    <button className='btn btn-link' onClick={() => this.handleLevelZero({ tbody, month: '08' })}> {(tbody.agosto).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                    </button>
                                                                                                </Tooltip>)
                                                                                        )}
                                                                                        </td>
                                                                                        <td className='text-right'>{(tbody.septiembre === 0 ? '-' :
                                                                                            (tbody.clave === "DL43" ? <span className='text-success'> {(tbody.septiembre).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span> :
                                                                                                <Tooltip title="Click para ver detalle" placement="top" arrow>
                                                                                                    <button className='btn btn-link' onClick={() => this.handleLevelZero({ tbody, month: '09' })}> {(tbody.septiembre).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                    </button>
                                                                                                </Tooltip>)
                                                                                        )}
                                                                                        </td>
                                                                                        <td className='text-right'>{(tbody.octubre === 0 ? '-' :
                                                                                            (tbody.clave === "DL43" ? <span className='text-success'> {(tbody.octubre).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span> :
                                                                                                <Tooltip title="Click para ver detalle" placement="top" arrow>
                                                                                                    <button className='btn btn-link' onClick={() => this.handleLevelZero({ tbody, month: '10' })}> {(tbody.octubre).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                    </button>
                                                                                                </Tooltip>)
                                                                                        )}
                                                                                        </td>
                                                                                        <td className='text-right'>{(tbody.noviembre === 0 ? '-' :
                                                                                            (tbody.clave === "DL43" ? <span className='text-success'> {(tbody.noviembre).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span> :
                                                                                                <Tooltip title="Click para ver detalle" placement="top" arrow>
                                                                                                    <button className='btn btn-link' onClick={() => this.handleLevelZero({ tbody, month: '11' })}> {(tbody.noviembre).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                    </button>
                                                                                                </Tooltip>)
                                                                                        )}
                                                                                        </td>
                                                                                        <td className='text-right'>{(tbody.diciembre === 0 ? '-' :
                                                                                            (tbody.clave === "DL43" ? <span className='text-success'> {(tbody.diciembre).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span> :
                                                                                                <Tooltip title="Click para ver detalle" placement="top" arrow>
                                                                                                    <button className='btn btn-link' onClick={() => this.handleLevelZero({ tbody, month: '12' })}> {(tbody.diciembre).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                    </button>
                                                                                                </Tooltip>)
                                                                                        )}
                                                                                        </td>
                                                                                    </tr>
                                                                                ) : null
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            {
                                                                (this.state.level === 1) ? (
                                                                    <>
                                                                        {this.state.view}
                                                                    </>
                                                                ) : null
                                                            }
                                                        </>
                                                    )
                                                }
                                                {

                                                    (this.state.level === 2) ? (
                                                        <>

                                                            {
                                                                this.state.subview
                                                            }
                                                        </>

                                                    ) : null

                                                }
                                                {
                                                    (this.state.level === 3) ? (
                                                        <>
                                                            {
                                                                this.state.viewTypeReport
                                                            }
                                                        </>) : null
                                                }
                                            </>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
