import React, { useRef, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import api from '../../../app/Config/api';
export default function ResponsiveDialog(obj) {
    /************
     * procesos para subir el archivo
     */
    const [datafile, setFile] = useState({
        file: '',
        namefile: 'Seleccione el logo',
        open: false
    });
    const el = useRef();
    const handleChangeFile = (e) => {
        const file = e.target.files[0];
        setFile({ file: file, namefile: file.name, open: false });
        const formData = new FormData();
        formData.append('file', file)
        api.post('/NewProductIMG', formData).then(res => {
            obj.name(res.data)
        });
    }

    return (
        <div>
            <label htmlFor="upload-button">
                <IconButton color="primary" aria-label="upload picture" component="span">
                    <PhotoCamera />
                </IconButton>
                <span> {datafile.namefile} </span>
            </label>
            <input type="file" ref={el} id="upload-button" style={{ display: "none" }} onChange={handleChangeFile} accept="image/*" />
        </div>
    );

}
