import React, { Component } from 'react'
import Skeleton from '@material-ui/lab/Skeleton';
import { PacmanLoader, RotateLoader, ClockLoader } from "react-spinners";
import api from '../../../Config/apifiles';
import CollapsibleTable from './details';
export default class ListObligations extends Component {
    constructor(props) {
        super(props)
        let objects = JSON.parse(localStorage.getItem("datacompanyall"));
        this.state = {
            data: [],
            dataNext: [],
            now: [],
            rfc: objects.rfc,
            empresa_id: objects.id,
            loading: true,
            oblNoPresentada: ''
        }
    }
    componentDidMount() {
        this.getdata();
    }
    getdata = async () => {
        await api.post('configure-obligations-to-company',
            { rfc: this.state.rfc, empresa_id: this.state.empresa_id }
        ).then(async (result) => {
            //  await api.post('http://localhost:3020/api/report-compare-obligations', { rfc: this.state.rfc, empresa_id: this.state.empresa_id }).then((respon) => {
            //  console.log(respon.data);
            //

            await api.post('report-compare-obligations', { rfc: this.state.rfc, empresa_id: this.state.empresa_id }).then((respon) => {
                let results = (respon.data).catObligation;
                results.map((resultados) => {
                    let xpos = (((respon.data).obligacions).filter((x) => x.obligacion === resultados.descripcion))
                    resultados.total = xpos.length;
                    resultados.detaiil = xpos;
                });
                this.setState({
                    oblNoPresentada: <CollapsibleTable data={results} />,
                    data: results,//(result.data).prev,//.filter((x) => x.view === "YES"),
                    dataNext: (result.data).next,//.filter((x) => x.view === "YES")
                    now: (result.data).now,
                    loading: false
                });
            });
        });
    }
    render() {
        return (
            <div>
                <div className="content-wrapper">
                    {/* Inner content */}
                    <div className="content-inner">
                        {/* Page header */}
                        <div className="page-header page-header-light shadow">
                            <div className="page-header-content d-lg-flex">
                                <div className="d-flex">
                                    <h4 className="page-title mb-0">
                                        <span className="fw-normal">Listado de tareas</span>
                                    </h4>
                                    <a href="#page_header" className="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
                                        <i className="ph-caret-down collapsible-indicator ph-sm m-1" />
                                    </a>
                                </div>

                            </div>
                        </div>
                        <div className="content">
                            <div className="row">
                                <div className="col-xl-4">
                                    <div className="card">
                                        <div className="card-header d-sm-flex align-items-sm-center py-sm-0">
                                            <h5 className="py-sm-2 my-sm-1">Tareas atrasadas</h5>
                                        </div>
                                        <div className="card-body">

                                            {
                                                this.state.loading ? (
                                                    <div>
                                                        <Skeleton variant="text" />
                                                        <div style={{
                                                            margin: 40,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                        }}>
                                                            <PacmanLoader color={"#ff7043"} loading={this.state.loading} size={25} margin={2} />
                                                        </div>
                                                        <Skeleton variant="circle" animation="wave" width={40} height={40} />
                                                        <Skeleton variant="rect" animation="wave" width={"100%"} height={118} />
                                                    </div>
                                                ) : (
                                                    <div className="table-responsive">
                                                        {this.state.oblNoPresentada}
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4">
                                    <div className="card">
                                        <div className="card-header d-flex align-items-center">
                                            <h5 className="mb-0">Tareas para hoy</h5>
                                        </div>
                                        <div className="card-body">
                                            {
                                                this.state.loading ? (
                                                    <div>
                                                        <Skeleton variant="text" />
                                                        <div style={{
                                                            margin: 40,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                        }}>
                                                            <RotateLoader color={"#ff7043"} loading={this.state.loading} size={25} margin={2} />
                                                        </div>
                                                        <Skeleton variant="circle" animation="wave" width={40} height={40} />
                                                        <Skeleton variant="rect" animation="wave" width={"100%"} height={118} />
                                                    </div>
                                                ) : (
                                                    <>
                                                        {(this.state.now.length > 0) ? (
                                                            <div className="table-responsive">
                                                                <table className="table text-nowrap">

                                                                    <tbody>
                                                                        <tr className="table-danger">
                                                                            <td colSpan={3}>Obligaciones no presentadas</td>
                                                                        </tr>
                                                                        {
                                                                            (this.state.now || []).map((t, index) => (
                                                                                <tr key={index}>
                                                                                    <td className="text-center">
                                                                                        <h6 className="mb-0">{t.day}</h6>
                                                                                        <div className="fs-sm text-muted lh-1">{t.namemonthpre}</div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <span className="text-muted">{t.nombre}</span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <span className="text-muted"></span>
                                                                                    </td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        ) : null
                                                        }
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4">
                                    <div className="card">
                                        <div className="card-header d-flex align-items-center">
                                            <h5 className="mb-0">Tareas futuras</h5>
                                        </div>
                                        <div className="card-body">
                                            {
                                                this.state.loading ? (
                                                    <div>
                                                        <Skeleton variant="text" />
                                                        <div style={{
                                                            margin: 40,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                        }}>
                                                            <ClockLoader color={"#ff7043"} loading={this.state.loading} size={50} margin={2} />
                                                        </div>
                                                        <Skeleton variant="circle" animation="wave" width={40} height={40} />
                                                        <Skeleton variant="rect" animation="wave" width={"100%"} height={118} />
                                                    </div>
                                                ) : (
                                                    <div className="table-responsive">
                                                        <table className="table text-nowrap">
                                                            <tbody>
                                                                <tr className="table-success">
                                                                    <td colSpan={3}>Obligaciones pendientes</td>
                                                                </tr>
                                                                {
                                                                    (this.state.dataNext || []).map((t, index) => (
                                                                        <tr key={index}>
                                                                            <td className="text-center">
                                                                                <h6 className="mb-0">{t.day}</h6>
                                                                                <div className="fs-sm text-muted lh-1">{t.mesanterior}</div>
                                                                            </td>
                                                                            <td>
                                                                                <span className="text-muted">{t.nombre}</span>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
