import React, { Component } from "react";
import api from '../../../Config/api';
class Correos extends Component {
    constructor(props) {
        super(props)
        let objectinfo = JSON.parse(localStorage.getItem("_data_contact_"));
        let user = JSON.parse(localStorage.getItem("id_token"));
        console.log(objectinfo)
        this.state = {
            para: objectinfo.correo,
            nombre_: user.nombre + " " + user.apellido_paterno + " " + user.apellido_materno + " (" + user.email + ")",
            email: user.email,
            de: user.email,
            asunto: '',
            correo: '',
            prospecto_id: objectinfo.pros_id,
            name: objectinfo.nombre,
            lastname: objectinfo.apellido_paterno + ' ' + objectinfo.apellido_materno,
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChangeSystem = (i) => {
        this.setState({ [i.target.name]: i.target.value });
    }
    handleSubmit(event) {
        let form = new FormData();
        form.append("para", this.state.para)
        form.append("de", this.state.de)
        form.append("asunto", this.state.asunto)
        form.append("correo", this.state.correo)
        form.append("prospecto_id", this.state.prospecto_id)
        form.append("nombre", this.state.name)
        form.append("apellido", this.state.lastname)
        api.post("newcorreosend", form).then((i) => {
            this.setState({ para: '', de: '', asunto: '', })
            this.props.close(i.data);
        });
        event.preventDefault();
    }
    render() {
        return (
            <form onSubmit={this.handleSubmit} autoComplete="off">
                <div className="modal-body">
                    <div className="card">
                        <div className="card-body">
                            <div className="form-group row">
                                <label className="col-lg-2 col-form-label text-right">Para:</label>
                                <div className="col-lg-10">
                                    <input type="text" required className="form-control" value={this.state.para} onChange={this.handleChangeSystem} name="para" placeholder="Destinatario" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-lg-2 col-form-label text-right">De:</label>
                                <div className="col-lg-10">
                                    <input type="text" className="form-control" value={this.state.nombre_} onChange={this.handleChangeSystem} name="de" placeholder="Origen" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-lg-2 col-form-label text-right">Asunto:</label>
                                <div className="col-lg-10">
                                    <input type="text" required autoComplete="off" className="form-control" value={this.state.asunto} onChange={this.handleChangeSystem} name="asunto" placeholder="Asunto" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-lg-2 col-form-label text-right"></label>
                                <div className="col-lg-10">
                                    <textarea className="form-control" required rows={3} value={this.state.correo} onChange={this.handleChangeSystem} name="correo" placeholder="Describe el correo"></textarea>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="modal-footer">
                    <button type="button" className="btn btn-link" data-dismiss="modal">Cancelar</button>
                    <button type="submit" className="btn bg-primary">Enviar</button>
                </div>
            </form>
        );
    }
}

export default Correos;