import React, { Component } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import api from '../../../app/Config/api';
import SaldoInicial from './contabilidad/saldosiniciales';
import DebeHaberS from './contabilidad/debehaber';
import BLZVSCFDI from './contabilidad/blzcfdi';
import PLZVSCFDI from './contabilidad/plzcfdi';
import CuentasContables from './contabilidad/cuentascontables';
import BLZC from './contabilidad/balanza';
import PLZC from './contabilidad/polizas';
import NCC from './contabilidad/notificacioncuentascontables';
import CA from './contabilidad/cuentas_control';
import ConciliacionF from './contabilidad/conciliacion';
import BLZVSCONTRIBUCIONT from './contabilidad/blzvscontribuciones';
import CFDINOCONTA from './contabilidad/screen-cfdis-polices';
import POLPROVEEDOR from './contabilidad/policesproveedores';
import { Tooltip } from '@material-ui/core';
function diaSemana(x) {
    let date = new Date((x.substr(0, 10)).replace(/-+/g, '/'));
    let options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };
    return date.toLocaleDateString('es-MX', options);

}
class TimeLine extends Component {
    constructor(props) {
        super(props);
        let companyadd = JSON.parse(localStorage.getItem("parameterscompany"));

        this.state = {
            left: [],
            right: [],
            saldoinicial: '',
            debehaber: '',
            blzcfdi: '',
            plzcfdi: '',
            cuentascontables: '',
            polizas: '',
            balanza: '',
            description: '',
            titlepolizas: 'Pólizas de ',
            titlebalanza: 'Balanza de ',
            notificacioncuentacontable: '',
            result_not: [], skeleton: true,
            empresa_id: companyadd.empresa_id,
            id: companyadd.id,
            namecompany: companyadd.nombre,
            conciliacion: '',
            iteracionejericios: [],
            ejercicioselect: 0, titleinit: '',
            cfdinocontabilizado: '',
            polprovee: '',
            subtitlecc: 'Catálogo de cuentas'
        }
    }
    componentDidMount() {
        localStorage.setItem("idxm", this.state.empresa_id);

        this.getlistado();
    }
    getlistado = async () => {
        await api.post("update-access-all", { empresa_id: this.state.id });
        let operadors_ = await api.post("getoperadoresgenerales", { empresa_id: this.state.empresa_id });
        let { max, operador } = await operadors_.data;
        let array_exersice = [];
        for (let index = operador[0].ejercicio_fiscal; index <= max.ejercicio; index++) {
            array_exersice.push({
                value: index,
                decripcion: index
            })
        }
        let result_ = await api.post("listAuditoriaContable", { empresa_id: localStorage.getItem("idxm"), ejercicio: max.ejercicio });

        let notificacion_ = await api.post("getnotificacioncuentacontable", { empresa_id: localStorage.getItem("idxm"), ejercicio: max.ejercicio });
        let { data } = await result_;

        this.setState({
            ejercicioselect: max.ejercicio,
            titleinit: 'Importación de archivos XML de otras contabilidades ' + max.ejercicio,
            iteracionejericios: array_exersice,
            left: data.left,
            right: data.right,
            result_not: notificacion_.data,
            skeleton: false
        });
    }
    handlechangexersice = (i) => {
        this.setState({ skeleton: true });
        let ejercicio_ = i.target.value
        setTimeout(async () => {

            let result_ = await api.post("listAuditoriaContable",
                { empresa_id: localStorage.getItem("idxm"), ejercicio: ejercicio_ });
            let notificacion_ = await api.post("getnotificacioncuentacontable",
                { empresa_id: localStorage.getItem("idxm"), ejercicio: ejercicio_ });

            let { data } = await result_;
            this.setState({
                ejercicioselect: ejercicio_, titleinit: 'Importación de archivos XML de otras contabilidades ' + ejercicio_, left: data.left,
                right: data.right, result_not: notificacion_.data, skeleton: false
            });

        }, 500);

    }
    handleSaldosIniciales = (i) => {
        localStorage.setItem("parameters", JSON.stringify(i));
        this.setState({ saldoinicial: <SaldoInicial /> })
        window.$("#_modalsi_").modal("show");
    }
    handleSblzdebehaber = (i) => {
        localStorage.setItem("parameters", JSON.stringify(i));
        this.setState({ saldoinicial: '', debehaber: <DebeHaberS /> });
        window.$("#_modalblzdebehaber_").modal("show");
    }
    handleSblzcfdi = (i) => {
        i.claveblzdh = i.claveblzcfdi;
        localStorage.setItem("parameters", JSON.stringify(i));
        this.setState({ saldoinicial: '', debehaber: '', blzcfdi: <BLZVSCFDI /> });
        window.$("#_modalblzcfdi_").modal("show");
    }
    handleSpzcfdi = (i) => {
        i.claveblzdh = i.clavepz;
        localStorage.setItem("parameters", JSON.stringify(i));
        this.setState({ saldoinicial: '', debehaber: '', blzcfdi: '', plzcfdi: <PLZVSCFDI /> });
        window.$("#_modalpzcfdi_").modal("show");
    }
    handleViewInfodata = (i) => {
        i.claveblzdh = i.clvpolicescc;
        localStorage.setItem("parameters", JSON.stringify(i));
        this.setState({ saldoinicial: '', debehaber: '', blzcfdi: '', plzcfdi: '', titlebalanza: 'Cuentas contables inexistentes', balanza: <PLZVSCFDI /> });
        window.$("#_modalbalanza_").modal("show");
    }
    handlecuentas = () => {
        this.setState({ saldoinicial: '', debehaber: '', blzcfdi: '', plzcfdi: '', cuentascontables: <CuentasContables></CuentasContables> });
        window.$("#_modalcuentas_").modal("show");
    }
    handleblzvzcontribucion = () => {
        let params = {
            empresa_id: this.state.empresa_id,
            ejercicio: this.state.ejercicioselect,
            id: this.state.id
        }
        localStorage.setItem("datacompanysystem", JSON.stringify(params))
        this.setState({ saldoinicial: '', debehaber: '', blzcfdi: '', plzcfdi: '', cuentascontables: <BLZVSCONTRIBUCIONT /> });
        window.$("#_modalblzvscontribucion_").modal("show");
    }
    handlepolizas = (i) => {
        localStorage.setItem("parameters", JSON.stringify(i));
        this.setState({
            titlepolizas: 'Pólizas de ' + convertName(i.periodo) + ' del ' + i.ejercicio,
            description: (convertName(i.periodo) + ' del ' + i.ejercicio), saldoinicial: '',
            debehaber: '', blzcfdi: '', plzcfdi: '', cuentascontables: '',
            balanza: '', polizas: <PLZC title={this.handleChangeTitle}></PLZC>
        });
        window.$("#_modalpolizas_").modal("show");
    }
    handlebalanza = (i) => {
        localStorage.setItem("parameters", JSON.stringify(i));
        this.setState({ titlebalanza: 'Balanza de comprobación de ' + (convertName(i.periodo) + ' del ' + i.ejercicio), saldoinicial: '', debehaber: '', blzcfdi: '', plzcfdi: '', cuentascontables: '', balanza: <BLZC title={this.handleChangeTitleBalanza} /> });
        window.$("#_modalbalanza_").modal("show");
    }
    handleChangeTitle = (title) => {
        this.setState({ titlepolizas: title });
    }
    handleChangeTitleBalanza = (title) => {
        this.setState({ titlebalanza: title });
    }
    handleClose = () => {
        this.setState({
            saldoinicial: '', conciliacion: '',
            debehaber: '', blzcfdi: '',
            plzcfdi: '', cuentascontables: '',
            polizas: '', cfdinocontabilizado: '',
            balanza: '', notificacioncuentacontable: ''
        });
    }
    handlenotificacioncuentacontable = (i) => {
        console.log(i)
        if (i.clave === "COU02") {
            this.setState({ notificacioncuentacontable: <CA></CA>, subtitlecc: 'Cuentas de control con C.A. de registro' });
        }
        else if (i.clave === "COU01") {
            this.setState({ notificacioncuentacontable: <NCC></NCC>, subtitlecc: 'Cuentas contables' });
        }
        window.$("#_modalnotificacioncuentas_").modal("show");
    }
    handleConciliacionFiscalContable = (i) => {
        localStorage.setItem("parameters", JSON.stringify(i));
        this.setState({ conciliacion: <ConciliacionF />, description: (convertName(i.periodo) + ' del ' + i.ejercicio) });
        window.$("#_modalconciliacion_").modal("show");
    }
    handleviewcfidsnocontabilizado = () => {
        this.setState({ cfdinocontabilizado: <CFDINOCONTA /> });
        window.$("#modal_modalcfid_").modal("show");
    }
    handleViewPolProvee = (i) => {
        this.setState({ polprovee: <POLPROVEEDOR data={i} /> });
        window.$("#polproveedormodal").modal("show");
    }
    render() {
        return (
            <>
                {
                    this.state.skeleton ? (
                        <div className="content-wrapper">
                            <div className="page-header border-bottom-0">
                                <div className="page-header-content header-elements-md-inline">
                                    <div className="page-title d-flex">
                                        <a href="#/app/client/sistem" style={{ color: 'white' }}>  <h6><i className="icon-arrow-left52 mr-2"></i> <span className="font-weight-semibold">Listado de empresas</span></h6 ></a>
                                        <a href="#" className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></a>
                                    </div>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td><h5 className="text-center">{this.state.namecompany}</h5></td>
                                            </tr>
                                            <tr>
                                                <td><span className="text-center">{this.state.titleinit}</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="header-elements d-none mb-3 mb-md-0">
                                        <div className="d-flex justify-content-center">

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="content pt-0">
                                <div className="card card-body border-top-primary">
                                    <div className="card">
                                        <Skeleton height={100} />
                                        <div className="row">
                                            <div className="col-lg-1">
                                                <Skeleton animation="wave" variant="circle" width={40} height={40} />
                                            </div>
                                            <div className="col-lg-5">
                                                <Skeleton animation="wave" height={40} />
                                                <Skeleton animation="wave" height={40} />
                                            </div>
                                            <div className="col-lg-1">
                                                <Skeleton animation="wave" variant="circle" width={40} height={40} />
                                            </div>
                                            <div className="col-lg-5">
                                                <Skeleton animation="wave" height={40} />
                                                <Skeleton animation="wave" height={40} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <Skeleton animation="wave" height={200} />
                                            </div>
                                            <div className="col-lg-6">
                                                <Skeleton animation="wave" height={200} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                        :
                        (<div className="content-wrapper">
                            <div className="page-header border-bottom-0">
                                <div className="page-header-content header-elements-md-inline">
                                    <div className="page-title d-flex">
                                        <a href="#/app/client/sistem" style={{ color: 'white' }}> <h6><i className="icon-arrow-left52 mr-2"></i> <span className="font-weight-semibold">Listado de empresas</span></h6 ></a>
                                        <a href="#" className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></a>
                                    </div>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td><h5 className="text-center">{this.state.namecompany}</h5></td>
                                            </tr>
                                            <tr>
                                                <td><span className="text-center">{this.state.titleinit}</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="header-elements d-none mb-3 mb-md-0">
                                        <div className="d-flex justify-content-center">
                                            <select className="form-control" value={this.state.ejercicioselect} onChange={this.handlechangexersice}>
                                                <option value="">Seleccione el ejercicio</option>
                                                {
                                                    (this.state.iteracionejericios || []).map((k, y) => (
                                                        <option value={k.value} key={y}>{k.decripcion}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div >
                            </div>

                            <div className="content pt-0">
                                <div className="card card-body border-top-primary">
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <Tooltip title="Click Ver Catálogo de cuentas" arrow placeholder="top">
                                                <button type="button" className="btn btn-light btn-labeled btn-labeled-right" onClick={this.handlecuentas}><b><i className="icon-menu2 text-success"></i></b>Ver Catálogo</button>
                                            </Tooltip>
                                        </div>
                                        <div className="col-lg-4">
                                            {
                                                (this.state.result_not || []).map((y, i) => (
                                                    <div key={i}>
                                                        <p className="text-danger" style={{ fontSize: 14, cursor: 'pointer' }} onClick={() => this.handlenotificacioncuentacontable(y)} >{y.descripcion_catalogo}</p>
                                                    </div>
                                                ))
                                            }
                                            <p className="text-danger" style={{ fontSize: 14 }}>No ha importado el acuse de aceptación</p>
                                        </div>

                                        <div className="col-lg-2">
                                            <div className="btn-group">
                                                <Tooltip title="CFDI's no contabilizados en pólizas" arrow placeholder="top">
                                                    <button type="button" className="btn btn-light btn-labeled btn-labeled-right" onClick={this.handleviewcfidsnocontabilizado}><b><i className="icon-menu2 text-success"></i></b> CFDI's</button>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <Tooltip title="Click Validación Impuestos Federales" arrow placeholder="top">
                                                <button type="button" className="btn btn-light btn-labeled btn-labeled-right" onClick={this.handleblzvzcontribucion}><b><i className="icon-file-spreadsheet text-success"></i></b>Validación Impuestos Federales</button>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {
                                        (this.state.left.length) > 0 ? (
                                            <div className="col-lg-6">
                                                {
                                                    (this.state.left || []).map((i, j) => (
                                                        <div className="timeline timeline-left">
                                                            <div className="timeline-container">
                                                                <div className="timeline-row">
                                                                    <div className="timeline-icon">
                                                                        {
                                                                            (i.estatus_end === "SUCCCESS") ? (
                                                                                <div className="bg-success-400">
                                                                                    <i className="icon-checkmark4" />
                                                                                </div>
                                                                            ) :
                                                                                (
                                                                                    (i.estatus_end === "WARNING") ?
                                                                                        (<div className="bg-warning-400">
                                                                                            <i className="icon-warning" />
                                                                                        </div>) :
                                                                                        (
                                                                                            <div className="bg-danger-400">
                                                                                                <i className="icon-x" />
                                                                                            </div>)
                                                                                )
                                                                        }

                                                                    </div>
                                                                    <div className="timeline-time">
                                                                        {convertName(i.periodo) + " " + i.ejercicio}
                                                                        <div className="text-muted">
                                                                            {(i.estatus_polizas !== "WARNING" && i.estatus_balanza_comprobacion !== "WARNING") ? (" Prueba realizada") : ("Prueba pendiente, no se ha importado la información!!")}

                                                                        </div>
                                                                    </div>
                                                                    <div className={i.estatus_end === "SUCCCESS" ? "card border-left-3 border-left-success rounded-left-0" : (i.estatus_end === "WARNING" ? "card border-left-3 border-left-warning rounded-left-0" : "card border-left-3 border-left-danger rounded-left-0")}>
                                                                        <div className="card-body">
                                                                            <table style={{ width: "100%" }}>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td></td>
                                                                                        <td>
                                                                                            {i.estatus_polizas !== "WARNING" ? (
                                                                                                <button type="button" className="btn btn-light btn-labeled btn-labeled-right" onClick={() => this.handlepolizas(i)}><b><i className="icon-menu2 text-success"></i></b>Ver Pólizas</button>
                                                                                            ) : null
                                                                                            }
                                                                                        </td>
                                                                                        <td></td>
                                                                                        <td>
                                                                                            {i.estatus_balanza_comprobacion !== "WARNING" ? (
                                                                                                <button type="button" className="btn btn-light btn-labeled btn-labeled-right" onClick={() => this.handlebalanza(i)}><b><i className="icon-menu2 text-success"></i></b>Ver Balanza</button>
                                                                                            ) : null
                                                                                            }
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>

                                                                            <hr />
                                                                            <div className="d-sm-flex align-item-sm-center flex-sm-nowrap">
                                                                                <div>
                                                                                    {
                                                                                        (i.estatus_end === "SUCCCESS") ? (<>
                                                                                            <ul className="media-list">
                                                                                                <li className="media">
                                                                                                    <div className="mr-3">
                                                                                                        <a className="btn bg-transparent border-success-300 text-success-300 rounded-round border-2 btn-icon">
                                                                                                            <i className="icon-checkmark3 text-success"></i>
                                                                                                        </a>
                                                                                                    </div>

                                                                                                    <div className="media-body">
                                                                                                        <p></p>
                                                                                                        Pruebas realizadas correctamente!!
                                                                                                    </div>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </>
                                                                                        ) : (
                                                                                            <>
                                                                                                <h6 className="mb-0 text-left">Notificaciones</h6>
                                                                                                <ul className="list list-unstyled mb-0">

                                                                                                    {
                                                                                                        (i.claveSI !== undefined) ? (
                                                                                                            <li className="text-danger" style={{ cursor: 'pointer' }} onClick={() => this.handleSaldosIniciales(i)}>{i.si}</li>
                                                                                                        ) : null
                                                                                                    }
                                                                                                    {
                                                                                                        (i.blzimpor !== undefined) ? (
                                                                                                            <li className="text-danger">{i.descripcion_balanza}</li>
                                                                                                        ) : null
                                                                                                    }
                                                                                                    {
                                                                                                        (i.claveblzdh !== undefined) ? (
                                                                                                            <li className="text-danger" onClick={() => this.handleSblzdebehaber(i)} style={{ cursor: 'pointer' }}>{i.blzdebehaber}</li>
                                                                                                        ) : null
                                                                                                    }
                                                                                                    {
                                                                                                        (i.claveblzcfdi !== undefined) ? (
                                                                                                            <li className="text-danger" onClick={() => this.handleSblzcfdi(i)} style={{ cursor: 'pointer' }}>{i.blzcfdi}</li>
                                                                                                        ) : null
                                                                                                    }
                                                                                                    {
                                                                                                        (i.clavepzimpor !== undefined) ? (
                                                                                                            <li className="text-danger">{i.descripcion_poliza}</li>
                                                                                                        ) : null
                                                                                                    }
                                                                                                    {
                                                                                                        (i.polproveedores !== undefined) ? (
                                                                                                            <li className="text-danger" style={{ cursor: 'pointer' }} onClick={() => this.handleViewPolProvee(i)}>{i.polproveedores}</li>
                                                                                                        ) : null
                                                                                                    }
                                                                                                    {
                                                                                                        (i.ccpolizas !== undefined) ? (
                                                                                                            <li className="text-danger" onClick={() => this.handleViewInfodata(i)} style={{ cursor: 'pointer' }}>{i.ccpolizas}</li>
                                                                                                        ) : null
                                                                                                    }
                                                                                                    {
                                                                                                        (i.clavepz !== undefined) ? (
                                                                                                            <li className="text-danger" onClick={() => this.handleSpzcfdi(i)} style={{ cursor: 'pointer' }}>{i.polcfdi}</li>
                                                                                                        ) : null
                                                                                                    }
                                                                                                    {
                                                                                                        (i.estatusbalanzadebehaber !== '') ? (
                                                                                                            <li className="text-danger" style={{ cursor: 'pointer' }} onClick={() => this.handlebalanza(i)}>Balanza: Los movimientos de debe y haber no cuadran </li>
                                                                                                        ) : null
                                                                                                    }
                                                                                                    {
                                                                                                        (i.estatusbalanzasf !== '') ? (
                                                                                                            <li className="text-danger" style={{ cursor: 'pointer' }} onClick={() => this.handlebalanza(i)}>Balanza: Los saldos finales  no cuadran </li>
                                                                                                        ) : null
                                                                                                    }
                                                                                                    {
                                                                                                        (i.descripcionconciliacion !== undefined) ? (
                                                                                                            <li className="text-danger" onClick={() => this.handleConciliacionFiscalContable(i)} style={{ cursor: 'pointer' }}>{i.descripcionconciliacion}</li>
                                                                                                        ) : null
                                                                                                    }

                                                                                                    {
                                                                                                        (i.acuse_x !== undefined) ? (
                                                                                                            <li className="text-danger">{i.descrip_acuse}</li>
                                                                                                        ) : null
                                                                                                    }
                                                                                                </ul>
                                                                                            </>
                                                                                        )
                                                                                    }

                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <div className="card-footer d-sm-flex justify-content-sm-between align-items-sm-center border-light">
                                                                            <span>
                                                                                <span className="badge badge-mark border-danger mr-2" /> Fecha auditado: <cite className="Source Title">{diaSemana(i.fecha_hora_validacion)}</cite>
                                                                            </span>
                                                                            <span>
                                                                                <span className="badge badge-mark border-danger mr-2" /> Hora: <cite className="Source Title">{i.fecha_hora_validacion.substr(11, 5)}</cite>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }

                                            </div>
                                        ) : null
                                    }

                                    <div className="col-lg-6">
                                        {
                                            (this.state.right || []).map((i, j) => (
                                                <div className="timeline timeline-left">
                                                    <div className="timeline-container">
                                                        <div className="timeline-row">
                                                            <div className="timeline-icon">
                                                                {
                                                                    (i.estatus_end === "SUCCCESS") ? (
                                                                        <div className="bg-success-400">
                                                                            <i className="icon-checkmark4" />
                                                                        </div>
                                                                    ) :
                                                                        (
                                                                            (i.estatus_end === "WARNING") ?
                                                                                (<div className="bg-warning-400">
                                                                                    <i className="icon-warning" />
                                                                                </div>) :
                                                                                (
                                                                                    <div className="bg-danger-400">
                                                                                        <i className="icon-x" />
                                                                                    </div>)
                                                                        )
                                                                }

                                                            </div>
                                                            <div className="timeline-time">
                                                                {convertName(i.periodo) + " " + i.ejercicio}
                                                                <div className="text-muted">
                                                                    {(i.estatus_polizas !== "WARNING" && i.estatus_balanza_comprobacion !== "WARNING") ? (" Prueba realizada") : ("Prueba pendiente, no se ha importado la información!!")}

                                                                </div>
                                                            </div>
                                                            <div className={i.estatus_end === "SUCCCESS" ? "card border-left-3 border-left-success rounded-left-0" : (i.estatus_end === "WARNING" ? "card border-left-3 border-left-warning rounded-left-0" : "card border-left-3 border-left-danger rounded-left-0")}>
                                                                <div className="card-body">
                                                                    <table style={{ width: "100%" }}>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td></td>
                                                                                <td>
                                                                                    {i.estatus_polizas !== "WARNING" ? (
                                                                                        <button type="button" className="btn btn-light btn-labeled btn-labeled-right" onClick={() => this.handlepolizas(i)}><b><i className="icon-menu2 text-success"></i></b>Ver Pólizas</button>
                                                                                    ) : null
                                                                                    }
                                                                                </td>
                                                                                <td></td>
                                                                                <td>
                                                                                    {i.estatus_balanza_comprobacion !== "WARNING" ? (
                                                                                        <button type="button" className="btn btn-light btn-labeled btn-labeled-right" onClick={() => this.handlebalanza(i)}><b><i className="icon-menu2 text-success"></i></b>Ver Balanza</button>
                                                                                    ) : null
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>

                                                                    <hr />
                                                                    <div className="d-sm-flex align-item-sm-center flex-sm-nowrap">
                                                                        <div>
                                                                            {
                                                                                (i.estatus_end === "SUCCCESS") ? (<>
                                                                                    <ul className="media-list">
                                                                                        <li className="media">
                                                                                            <div className="mr-3">
                                                                                                <a className="btn bg-transparent border-success-300 text-success-300 rounded-round border-2 btn-icon">
                                                                                                    <i className="icon-checkmark3 text-success"></i>
                                                                                                </a>
                                                                                            </div>

                                                                                            <div className="media-body">
                                                                                                <p></p>
                                                                                                Pruebas realizadas correctamente!!
                                                                                            </div>
                                                                                        </li>
                                                                                    </ul>
                                                                                </>
                                                                                ) : (
                                                                                    <>
                                                                                        <h6 className="mb-0 text-left">Notificaciones</h6>
                                                                                        <ul className="list list-unstyled mb-0">

                                                                                            {
                                                                                                (i.claveSI !== undefined) ? (
                                                                                                    <li className="text-danger" style={{ cursor: 'pointer' }} onClick={() => this.handleSaldosIniciales(i)}>{i.si}</li>
                                                                                                ) : null
                                                                                            }
                                                                                            {
                                                                                                (i.blzimpor !== undefined) ? (
                                                                                                    <li className="text-danger">{i.descripcion_balanza}</li>
                                                                                                ) : null
                                                                                            }
                                                                                            {
                                                                                                (i.claveblzdh !== undefined) ? (
                                                                                                    <li className="text-danger" onClick={() => this.handleSblzdebehaber(i)} style={{ cursor: 'pointer' }}>{i.blzdebehaber}</li>
                                                                                                ) : null
                                                                                            }
                                                                                            {
                                                                                                (i.claveblzcfdi !== undefined) ? (
                                                                                                    <li className="text-danger" onClick={() => this.handleSblzcfdi(i)} style={{ cursor: 'pointer' }}>{i.blzcfdi}</li>
                                                                                                ) : null
                                                                                            }
                                                                                            {
                                                                                                (i.ccpolizas !== undefined) ? (
                                                                                                    <li className="text-danger" onClick={() => this.handleViewInfodata(i)} style={{ cursor: 'pointer' }}>{i.ccpolizas}</li>
                                                                                                ) : null
                                                                                            }
                                                                                            {
                                                                                                (i.clavepzimpor !== undefined) ? (
                                                                                                    <li className="text-danger">{i.descripcion_poliza}</li>
                                                                                                ) : null
                                                                                            }
                                                                                            {
                                                                                                (i.clavepz !== undefined) ? (
                                                                                                    <li className="text-danger" onClick={() => this.handleSpzcfdi(i)} style={{ cursor: 'pointer' }}>{i.polcfdi}</li>
                                                                                                ) : null
                                                                                            }

                                                                                            {
                                                                                                (i.estatusbalanzadebehaber !== '') ? (
                                                                                                    <li className="text-danger" onClick={() => this.handlebalanza(i)} style={{ cursor: 'pointer' }}>Balanza: Los movimientos de debe y haber no cuadran </li>
                                                                                                ) : null
                                                                                            }
                                                                                            {
                                                                                                (i.estatusbalanzasf !== '') ? (
                                                                                                    <li className="text-danger" onClick={() => this.handlebalanza(i)} style={{ cursor: 'pointer' }}>Balanza: Los saldos finales  no cuadran </li>
                                                                                                ) : null
                                                                                            }
                                                                                            {
                                                                                                (i.descripcionconciliacion !== undefined) ? (
                                                                                                    <li className="text-danger" onClick={() => this.handleConciliacionFiscalContable(i)} style={{ cursor: 'pointer' }}>{i.descripcionconciliacion}</li>
                                                                                                ) : null
                                                                                            }
                                                                                            {
                                                                                                (i.acuse_x !== undefined) ? (
                                                                                                    <li className="text-danger">{i.descrip_acuse}</li>
                                                                                                ) : null
                                                                                            }

                                                                                        </ul>
                                                                                    </>
                                                                                )
                                                                            }

                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div className="card-footer d-sm-flex justify-content-sm-between align-items-sm-center border-light">
                                                                    <span>
                                                                        <span className="badge badge-mark border-danger mr-2" /> Fecha auditado: <cite className="Source Title">{diaSemana(i.fecha_hora_validacion)}</cite>
                                                                    </span>
                                                                    <span>
                                                                        <span className="badge badge-mark border-danger mr-2" /> Hora: <cite className="Source Title">{i.fecha_hora_validacion.substr(11, 5)}</cite>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>

                            </div>
                        </div >)
                }


                {/*************Modales get data::::::::: */}
                <div id="_modalsi_" className="modal fade" tabIndex="-1" aria-hidden="true" style={{ "display": "none" }}>
                    <div className="modal-dialog modal-full">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Saldo Inicial</h5>
                                <button type="button" className="close" data-dismiss="modal" onClick={this.handleClose}>×</button>
                            </div>
                            <div className="modal-body">
                                {this.state.saldoinicial}
                            </div>
                        </div>
                    </div>
                </div>
                <div id="_modalblzdebehaber_" className="modal fade" tabIndex="-1" aria-hidden="true" style={{ "display": "none" }}>
                    <div className="modal-dialog modal-full">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Comparción de la balanza importada con la generada</h5>
                                <button type="button" className="close" data-dismiss="modal" onClick={this.handleClose}>×</button>
                            </div>

                            <div className="modal-body">
                                {this.state.debehaber}
                            </div>
                        </div>
                    </div>
                </div>
                <div id="_modalblzcfdi_" className="modal fade" tabIndex="-1" aria-hidden="true" style={{ "display": "none" }}>
                    <div className="modal-dialog modal-full">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Balanza vs CFDI's</h5>
                                <button type="button" className="close" data-dismiss="modal" onClick={this.handleClose}>×</button>
                            </div>
                            <div className="modal-body">
                                {this.state.blzcfdi}
                            </div>
                        </div>
                    </div>
                </div>
                <div id="_modalpzcfdi_" className="modal fade" tabIndex="-1" aria-hidden="true" style={{ "display": "none" }}>
                    <div className="modal-dialog modal-full">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">CFDI's referenciados en pólizas pero no importados</h5>
                                <button type="button" className="close" data-dismiss="modal" onClick={this.handleClose}>×</button>
                            </div>

                            <div className="modal-body">
                                {this.state.plzcfdi}
                            </div>
                        </div>
                    </div>
                </div>
                <div id="_modalcuentas_" className="modal fade" tabIndex="-1" aria-hidden="true" style={{ "display": "none" }}>
                    <div className="modal-dialog modal-full">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Catálogo de cuentas</h5>
                                <button type="button" className="close" data-dismiss="modal" onClick={this.handleClose}>×</button>
                            </div>

                            <div className="modal-body">
                                {this.state.cuentascontables}
                            </div>
                        </div>
                    </div>
                </div>
                <div id="_modalpolizas_" className="modal fade" tabIndex="-1" aria-hidden="true" style={{ "display": "none" }}>
                    <div className="modal-dialog modal-full">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title"> {this.state.titlepolizas}</h5>
                                <button type="button" className="close" data-dismiss="modal" onClick={this.handleClose}>×</button>
                            </div>

                            <div className="modal-body">
                                {this.state.polizas}
                            </div>
                        </div>
                    </div>
                </div>
                <div id="_modalbalanza_" className="modal fade" tabIndex="-1" aria-hidden="true" style={{ "display": "none" }}>
                    <div className="modal-dialog modal-full">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{this.state.titlebalanza}</h5>
                                <button type="button" className="close" data-dismiss="modal" onClick={this.handleClose}>×</button>
                            </div>

                            <div className="modal-body">
                                {this.state.balanza}
                            </div>
                        </div>
                    </div>
                </div>
                <div id="_modalnotificacioncuentas_" className="modal fade" tabIndex="-1" aria-hidden="true" style={{ "display": "none" }}>
                    <div className="modal-dialog modal-full">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{this.state.subtitlecc}</h5>
                                <button type="button" className="close" data-dismiss="modal" onClick={this.handleClose}>×</button>
                            </div>

                            <div className="modal-body">
                                {this.state.notificacioncuentacontable}
                            </div>
                        </div>
                    </div>
                </div>
                <div id="_modalconciliacion_" className="modal fade" tabIndex="-1" aria-hidden="true" style={{ "display": "none" }}>
                    <div className="modal-dialog modal-full">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Conciliación {this.state.description}</h5>
                                <button type="button" className="close" data-dismiss="modal" onClick={this.handleClose}>×</button>
                            </div>

                            <div className="modal-body">
                                {this.state.conciliacion}
                            </div>
                        </div>
                    </div>
                </div>
                <div id="_modalblzvscontribucion_" className="modal fade" tabIndex="-1" aria-hidden="true" style={{ "display": "none" }}>
                    <div className="modal-dialog modal-full">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Validación de impuestos federales del {this.state.ejercicioselect}</h5>
                                <button type="button" className="close" data-dismiss="modal" onClick={this.handleClose}>×</button>
                            </div>

                            <div className="modal-body">
                                {this.state.cuentascontables}
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal_modalcfid_" className="modal fade" tabIndex="-1" aria-hidden="true" style={{ "display": "none" }}>
                    <div className="modal-dialog modal-full">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Listado de cfdis no contabilizados</h5>
                                <button type="button" className="close" data-dismiss="modal" onClick={this.handleClose}>×</button>
                            </div>

                            <div className="modal-body">
                                {this.state.cfdinocontabilizado}
                            </div>
                        </div>
                    </div>
                </div>
                <div id="polproveedormodal" className="modal fade" tabIndex="-1" aria-hidden="true" style={{ "display": "none" }}>
                    <div className="modal-dialog modal-full">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Proveedores localizados en el 69B vs Pólizas</h5>
                                <button type="button" className="close" data-dismiss="modal" onClick={this.handleClose}>×</button>
                            </div>

                            <div className="modal-body">
                                {this.state.polprovee}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default TimeLine;

function convertName(key) {
    switch (key) {
        case "01":
            return "Enero"
            break;
        case "02":
            return "Febrero"
            break;
        case "03":
            return "Marzo"
            break;
        case "04":
            return "Abril"
            break;
        case "05":
            return "Mayo"
            break;
        case "06":
            return "Junio"
            break;
        case "07":
            return "Julio"
            break;
        case "08":
            return "Agosto"
            break;
        case "09":
            return "Septiembre"
            break;
        case "10":
            return "Octubre"
            break;
        case "11":
            return "Noviembre"
            break;
        case "12":
            return "Diciembre"
            break;

    }
}