import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from "react-router-dom";
import ModalFiles from './others/newcompany';
import api from '../../app/Config/api';
import apishare from '../../app/Config/apiconnect';
import ModalAdminiDocuments from './others/modaladmindocuments';
import ModalAuditoriaEnd from './auditor_/index';
/**********
 * style para listado de empresas
 */
import './styleinit.css';
//import ViewCfdis from '../../app/Notificaciones/screen-validador';
import ViewCfdis from '../../client/Modulos/details/view-report-list';
import ViewContribucionesFederales from '../../app/Notificaciones/impuestofederal';
import ViewDeclaracionesInformativas from '../../app/Notificaciones/screen-informativa';
import ViewContribucionesEstatales from '../../app/Notificaciones/screen-estatales';
import ViewMultas from '../../app/Notificaciones/screen-multa';

import apix from '../../app/Config/apifiles';

import { QRCode } from 'react-qrcode-logo';
const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));
function notitext(params) {

}
export default function CustomizedTables() {
    let redirect = useHistory();
    const classes = useStyles();
    const [infos, setData] = useState([]);
    const [container, setInfoContaniner] = useState('');
    var objec = JSON.parse(localStorage.getItem('id_token'));
    const [userData, setUserAdd] = useState(objec);
    const [cliente_id] = useState(objec.cliente_id);
    const [aceptoterminos] = useState(localStorage.getItem("terminosycondiciones"));
    const [modaleliminar, setDelete] = React.useState(false);
    const [nombreempresa, setNombreEmpresa] = React.useState({});
    const [open, setOpen] = React.useState(false);
    const [sharep, setSharefile] = React.useState(false);
    const [urlqr, setQR] = React.useState('https://e-xpertum.com.mx/adminfile/viewcharfiles/');
    const [nameperson, setNamePerson] = useState('');
    const [emailperson, setEmailPerson] = useState('');
    const [asuntoperson, setAsuntoPerson] = useState('');
    const [rfccompany, setRfcCompany] = useState('');
    /**************add view's */
    const [cfdi_, setViewCfdis] = useState('');
    const [contribucionfederal, setViewContribucionFederal] = useState('');
    const [contribucionestatal, setViewContribucionEstatal] = useState('');
    const [declaracioninformativa, setViewDeclaracionInformativa] = useState('');
    const [multa, setViewMulta] = useState('');
    const [notification, setNotification] = useState(false);
    async function getData() {
        var info = {
            cliente_id: cliente_id,
            cli: objec
        }
        const response = await api.post('listcompanysistemallcompanys', info)
        const data = await response.data;
        /*******************
         * 
         * 
         */
        //    api.post('listcompanymobil', info).then((response) => response.data).then((j) => {

        for (let xp = 0; xp < data.length; xp++) {
            ///   var result_ = (j.filter((tup) => tup.empresa_id === data[xp].empresa_id))
            /// if (result_.length > 0) {

            data[xp].contabilidad_electronica = parseInt(data[xp].contabilidad_electronica);
            data[xp].contribucion_federal = parseInt(data[xp].obligacion);
            data[xp].estatal = parseInt(data[xp].estatal);
            data[xp].informativa = parseInt(data[xp].informativa);
            ///data[xp].multa = (result_[0] != undefined ? parseInt(result_[0].multa) : 0);
            data[xp].validacion_cfdi = parseInt(data[xp].validacion_cfdi); //(result_[0] != undefined ? parseInt(result_[0].validacion_cfdi) : 0);
            data[xp].notificationcfdi = (parseInt(data[xp].validacion_cfdi) == 0 ? 'Aún no se ha configurado por primera vez.' : parseInt(data[xp].validacion_cfdi) == 1 ? 'No se ha iniciado la carga o validación.' : parseInt(data[xp].validacion_cfdi) == 2 ? '1 o más CFDIs en Listas Negras' : parseInt(data[xp].validacion_cfdi) == 3 ? 'Ningún CFDI en Listas Negras' : '');
            data[xp].notificationcontabilidad = (parseInt(data[xp].contabilidad_electronica) == 0 ? 'Aún no se ha configurado por primera vez.' : parseInt(data[xp].contabilidad_electronica) == 1 ? 'No se ha iniciado la importación.' : parseInt(data[xp].contabilidad_electronica) == 2 ? 'Hay omisiones y/o errores en pólizas, balanza o catálogo.' : parseInt(data[xp].contabilidad_electronica) == 3 ? 'Está todo importado y sin errores u omisiones' : '');
            //data[xp].notificationfederales = (result_[0].contribucion_federal == 0 ? 'No configurada.' : result_[0].contribucion_federal == 1 ? 'No se ha iniciado la captura de impuestos.' : result_[0].contribucion_federal == 2 ? 'No se ha capturado y/o pagado alguna contribución.' : result_[0].contribucion_federal == 3 ? 'Se encuentran todas las contribuciones cargadas y pagadas.' : '');
            data[xp].notificationfederales = (parseInt(data[xp].obligacion) == 0 ? 'No configurada.' : parseInt(data[xp].obligacion) == 1 ? 'No se ha iniciado la captura de impuestos.' : parseInt(data[xp].obligacion) == 2 ? 'No se ha capturado y/o pagado alguna contribución.' : parseInt(data[xp].obligacion) == 3 ? 'Se encuentran todas las contribuciones cargadas y pagadas.' : '');
            data[xp].notificationestatales = (parseInt(data[xp].estatal) == 0 ? 'No configurada.' : parseInt(data[xp].estatal) == 1 ? 'No se ha iniciado la captura de impuestos.' : parseInt(data[xp].estatal) == 2 ? 'No se ha capturado y/o pagado alguna contribución.' : parseInt(data[xp].estatal) == 3 ? 'Todas las contribuciones se encuentran pagadas.' : '');
            data[xp].notificationinformativa = (parseInt(data[xp].informativa) == 0 ? 'No configurada.' : parseInt(data[xp].informativa) == 1 ? 'No se han cargado las declaraciones.' : parseInt(data[xp].informativa) == 2 ? 'No se ha cumplido con la presentación de declaraciones a la fecha.' : parseInt(data[xp].informativa) == 3 ? 'Están presentadas todas las declaraciones informativas' : '')
            data[xp].notificationmulta = ""; /// (result_[0].multa == 0 ? 'No configurada.' : result_[0].multa == 1 ? 'Existen multas por pagar.' : result_[0].multa == 2 ? 'Falta de pago de alguna multa.' : result_[0].multa == 3 ? 'Todas las multas se encuentran pagadas.' : '')

        }
        setData(data);
        setOpen(false);
        ///   });
        /*****************
         * 
         * 
         */
    }
    useEffect(() => {
        setOpen(true);
        if (aceptoterminos === 'true') {
            getData();
            informacionObtenida('');
        }
        else
            setOpen(false);
    }, []);
    function informacionObtenida(i) {
        getData();
        var objec = JSON.parse(localStorage.getItem('id_token'));
        var obje = {
            query: "SELECT * FROM activaciones where cliente_id='" + objec.cliente_id + "' and modulo_id=14"
        }
        api.post('configtabledinamyc', obje).then((r) => r.data)
            .then((j) => {
                localStorage.removeItem("_numero_empresas_");
                localStorage.removeItem("_suscripcion_access_");
                /*var contador = 0;
                for (let po = 0; po < j.length; po++) {
                    contador = contador + parseInt(j[po].numero_empresa)
                }*/
                localStorage.setItem("_suscripcion_access_", j[0].acceso);
                localStorage.setItem("_numero_empresas_", j[0].numero_empresa);
            }).catch((u) => {
                console.log(u)
            })
    }

    function modalDinamyc(i) {
        setInfoContaniner('');
        var datainfo = {
            status: true,
            datos: i,
            clear: setInfoContaniner
        }
        setInfoContaniner(<ModalAdminiDocuments value={datainfo} />)
    }
    function modalAuditoria(i) {
        setInfoContaniner('');
        var datainfo = {
            status: true,
            datos: i,
            clear: setInfoContaniner
        }
        setInfoContaniner(<ModalAuditoriaEnd value={datainfo} />)
    }
    function handlerDelete(j) {
        setNombreEmpresa(j)
        window.$("#modal_small").modal("show");
    }
    function handlerCloseModal() {
        getData();
    }
    function handleConfirmacionDelete() {
        var object = {
            id: parseInt(nombreempresa.id)
        }
        api.post('deleteconfirmcompany', object).then(r => {
            getData();
            window.$("#modal_small").modal("hide");
        });
    }
    function handleNavigationContabilidad(params) {
        if (localStorage.getItem("_suscripcion_access_") === "SUSCRIPCION") {
            if (params.contabilidad_electronica === 2 || params.contabilidad_electronica === 3) {
                localStorage.setItem("parameterscompany", JSON.stringify(params));
                redirect.push("/app/report/contabilidad");
                //redirect.push("/app/auditor/details-auditor");
            }
        }
        else {
            setNotification(true);
            setTimeout(() => {
                setNotification(false);
            }, 3000);
        }
    }
    function addViewCfdis(params) {
        if (params.validacion_cfdi !== 1 && params.validacion_cfdi !== 0) {
            localStorage.setItem("_data_info_empresa_", JSON.stringify(params));
            setViewCfdis(<ViewCfdis />);
            window.$("#modalcfdi").modal("show");
        }

    }
    function addViewContribucionFederal(params) {
        if (localStorage.getItem("_suscripcion_access_") === "SUSCRIPCION") {
            //   if (params.contribucion_federal !== 0) {
            localStorage.setItem("_data_info_empresa_", JSON.stringify(params));
            setViewContribucionFederal(<ViewContribucionesFederales />);
            window.$("#modalcontribucionesfederales").modal("show");
            //  }
        } else {
            setNotification(true);
            setTimeout(() => {
                setNotification(false);
            }, 3000);
        }
    }
    function addViewDeclaracionesInformativas(params) {
        if (localStorage.getItem("_suscripcion_access_") === "SUSCRIPCION") {
            if (params.informativa !== 0) {
                localStorage.setItem("_data_info_empresa_", JSON.stringify(params));
                setViewDeclaracionInformativa(<ViewDeclaracionesInformativas />);
                window.$("#modalinformativas").modal("show");
            }
        } else {
            setNotification(true);
            setTimeout(() => {
                setNotification(false);
            }, 3000);
        }
    }
    function addViewContribucionesEstatales(params) {
        if (params.estatal !== 0) {
            localStorage.setItem("_data_info_empresa_", JSON.stringify(params));
            setViewContribucionEstatal(<ViewContribucionesEstatales closemodal={closemodal} />);
            window.$("#modalcontribucionesestatales").modal("show");
        }
    }
    function addViewMultas(params) {
        if (params.multa !== 0) {
            localStorage.setItem("_data_info_empresa_", JSON.stringify(params));
            setViewMulta(<ViewMultas />);
            window.$("#modalmultas").modal("show");
        }
    }
    const closemodal = () => {
        window.$("#modalcfdi").modal("hide");
        window.$("#modalcontribucionesestatales").modal("hide");
        window.$("#modalmultas").modal("hide");
        window.$("#modalcontribucionesfederales").modal("hide");
    }
    const handleClearObjects = () => {

        setViewMulta('');
        setViewContribucionEstatal('');
        setViewContribucionFederal('');
        setViewDeclaracionInformativa('');
        setViewCfdis('');
    }
    const _modalcodeqr_ = async (i) => {

        let data = {
            empresa_id: i.empresa_id
        }
        let result_ = await api.post('savecodeqr', data);
        let jkl = await result_.data;
        let urls = urlqr + jkl.ident;
        setQR(urls);
        setNombreEmpresa(i.nombre);
        setRfcCompany(i.rfc);

        let dt_ = await apishare.post('generarcode', { url: urls, rfc: i.rfc });
        window.$("#modalcodeqr").modal("show");
    }
    const _share_code_qr_ = async (event) => {
        event.preventDefault()
        let dt_ = await apishare.post('sendmailcodeqr',
            {
                namedesti: nameperson, correo: emailperson,
                name: nombreempresa, asunto: asuntoperson, rfc: rfccompany,
                link: urlqr
            }
        );
        setSharefile(false)
    }
    const viewconfigreports = async (params) => {

        localStorage.setItem("datacompanyall", JSON.stringify(params));
        redirect.push("/app/panel/company");
    }
    const { perfil } = userData;
    return (
        <div>
            <Backdrop className={classes.backdrop} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="page-header border-bottom-0">
                <div className="page-header-content header-elements-md-inline">
                    <div className="page-title d-flex">
                        <a href="#" style={{ color: 'white' }}> <h4><i className="icon-arrow-left52 mr-2"></i> <span className="font-weight-semibold">Inicio </span></h4></a>
                        <a href="#" className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></a>
                    </div>
                    <div className="header-elements d-none mb-3 mb-md-0">
                        <div className="d-flex justify-content-center">
                            <a href="https://e-xpertum.com.mx/webadmin/api/documents/api/help/Manual_E_xpertum_Audit_d_l_Conta_V1.pdf" target="_blank" className="btn btn-link btn-float text-default"><i className="icon-help"></i><span>Instructivo</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content pt-0">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title" style={{ textAlign: 'center' }}>Auditoría de cumplimiento fiscal</h5>
                            </div>
                            {
                                (aceptoterminos === 'false') ? (
                                    <>
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="alert alert-warning alert-styled-right alert-dismissible">
                                                            <button type="button" className="close" data-dismiss="alert"><span>×</span></button>
                                                            <span className="font-weight-semibold">Usuario!</span> Debe de aceptar los términos y condiciones para poder utilizar los servicios. <span className="font-weight-semibold"> Solución:</span> click en inicio y acepte los términos.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (<div className="card-body">
                                    <div className="row">

                                        <div className="col-md-6"><h6>Listado de empresas</h6></div>
                                        <div className="col-md-2">
                                            <Tooltip title={
                                                <div className="container">
                                                    <table className="table">
                                                        <thead><tr>
                                                            <th>Administración de la documentación</th></tr></thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Click para ver el listado de documentos a recabar para la importación.</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            } placement="top" arrow>
                                                <a className="btn btn-light" href="#/app/ui/helpauditorcontable" target="_blank">Documentos requeridos</a>
                                            </Tooltip></div>
                                        <div className="col-md-3">
                                            {
                                                (localStorage.getItem("_numero_empresas_") > infos.length) ?
                                                    (<Tooltip placement="top" arrow title={
                                                        <div className="container">
                                                            <table className="table">
                                                                <thead><tr><th>Registro de empresas</th></tr></thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>El usuario ingresará su constancia de situación fiscal, para crear una nueva empresa.</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    }><button type="button" className="btn btn-light" data-toggle="modal" data-target="#modal_large">Subir Constancia  Situación<i className="icon-play3 ml-2"></i></button></Tooltip>)
                                                    : null
                                            }

                                        </div>
                                        <div className="col-md-1">
                                            <Tooltip title={
                                                <div className="container">
                                                    <table className="table">
                                                        <thead><tr>
                                                            <th>Administración de la documentación</th></tr></thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Paso 1. Subir la constancia de situación fiscal.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Paso 2. El usuario deberá dar clic al icono <i className="icon-drawer3"></i> para registrar la documentación de la empresa.</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            } placement="top" arrow><a target="_blank"><i style={{ color: '#2196F3', cursor: 'pointer', textAlign: 'center' }} className="icon-help"></i></a></Tooltip>
                                        </div>
                                    </div>

                                    {
                                        notification ? (
                                            <div class="alert bg-primary alert-styled-right alert-dismissible">
                                                <span className="font-weight-semibold">Notificación!</span> Usuario: Para poder ver los detalles de la auditoria, debe adquirir el servicio.
                                            </div>
                                        ) : null
                                    }

                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th className="text-left">Razón social / Nombre</th>
                                                <th className="text-center">Validación CFDI's</th>
                                                <th className="text-center">Contabilidad electrónica</th>
                                                <th className="text-center">Contribuciones federales</th>
                                                <th className="text-center">Declaraciones informativas</th>
                                                {/*<th className="text-center">Contribuciones estatales</th>*/}
                                                <th className="text-center">Reportes fiscales</th>
                                                <th className="text-center">Expediente fiscal</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(infos || []).map((row) => (
                                                <tr key={row.nombre}>
                                                    <td onClick={() => viewconfigreports(row)} > {row.nombre}</td>
                                                    <td className="text-center"><Tooltip onClick={() => addViewCfdis(row)} title={row.notificationcfdi} placement="top" arrow>{_validacion_icon_cfdis(row.validacion_cfdi)}</Tooltip></td>
                                                    <td className="text-center"><Tooltip onClick={() => handleNavigationContabilidad(row)} title={row.notificationcontabilidad} placement="top" arrow>{_validacion_icon_cfdis(row.contabilidad_electronica)}</Tooltip></td>
                                                    <td className="text-center"><Tooltip onClick={() => addViewContribucionFederal(row)} title={row.notificationfederales} placement="top" arrow>{_contribuciones_federales_(row.contribucion_federal)}</Tooltip></td>
                                                    <td className="text-center"><Tooltip onClick={() => addViewDeclaracionesInformativas(row)} title={row.notificationinformativa} placement="top" arrow>{_contribuciones_federales_(row.informativa)}</Tooltip></td>
                                                    {/*<td className="text-center"><Tooltip onClick={() => addViewContribucionesEstatales(row)} title={row.notificationestatales} placement="top" arrow>{_validacion_icon_cfdis(row.estatal)}</Tooltip></td>*/}
                                                    <td className="text-center" style={{ cursor: 'pointer' }}><Tooltip onClick={() => viewconfigreports(row)} title={'Ingresar a los reportes fiscales'} placement="top" arrow>{iconmultas(row.multa)}</Tooltip></td>
                                                    <td className="text-center">
                                                        <Tooltip onClick={() => modalDinamyc(row)} title={
                                                            <div className="container">
                                                                <table className="table">
                                                                    <thead><tr>
                                                                        <th>Administración de la documentación</th></tr></thead>
                                                                    <tbody>

                                                                        <tr>
                                                                            <td>Paso 2. El usuario deberá dar clic al icono para registrar la documentación de la empresa.</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        } placement="top" arrow><i style={{ color: '#2196F3', cursor: 'pointer' }} className="icon-drawer3"></i></Tooltip>
                                                    </td>

                                                    <td className="text-center">
                                                        {
                                                            perfil !== "CAPTURISTA" ? (
                                                                <Tooltip onClick={() => handlerDelete(row)} title="Eliminar empresa" placement="top" arrow>
                                                                    <i style={{ cursor: 'pointer' }} className="icon-trash text-danger"></i>
                                                                </Tooltip>
                                                            ) : null
                                                        }
                                                    </td>

                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    {container}
                                    <div id="modal_small" className="modal fade" tabIndex="-1" aria-hidden="true" style={{ "display": "none" }}>
                                        <div className="modal-dialog modal-sm">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">Confirmación de eliminación</h5>
                                                    <button type="button" className="close" data-dismiss="modal">×</button>
                                                </div>

                                                <div className="modal-body">
                                                    <p>Está seguro de eliminar el siguiente registro {nombreempresa.nombre}?</p>
                                                </div>

                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-link" data-dismiss="modal">Cancelar</button>
                                                    <button type="button" className="btn bg-primary" onClick={handleConfirmacionDelete} >Aceptar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="modal_large" className="modal fade" tabIndex="-1">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">Crear Nueva Empresa</h5>
                                                    <button type="button" className="close" data-dismiss="modal">×</button>
                                                </div>

                                                <div className="modal-body">
                                                    <ModalFiles informacion={{ infos, informacionObtenida }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="modalcodeqr" className="modal fade" tabIndex="-1">
                                        <div className="modal-dialog modal-sm">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">Código QR</h5>
                                                    <button type="button" className="close" data-dismiss="modal">×</button>
                                                </div>

                                                <div className="modal-body">
                                                    <div className="card">
                                                        <div className="card-header bg-transparent border-bottom header-elements-inline">
                                                            <h6 className="card-title">Compartir</h6>
                                                            <div className="header-elements">
                                                                <div className="list-icons">
                                                                    <div className="dropdown">
                                                                        <a href="#" className="list-icons-item dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="icon-menu7" /></a>
                                                                        <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end" style={{ position: 'absolute', transform: 'translate3d(-147px, 19px, 0px)', top: '0px', left: '0px', willChange: 'transform' }}>
                                                                            <div className="dropdown-divider" />
                                                                            <a style={{ cursor: 'pointer' }} onClick={() => setSharefile(true)} className="dropdown-item">Correo</a>
                                                                            <div className="dropdown-divider" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {
                                                            (sharep) ? (
                                                                <div className="card-body">
                                                                    <form onSubmit={_share_code_qr_}>
                                                                        <fieldset className="mb-3">
                                                                            <legend className="text-uppercase font-size-sm font-weight-bold border-bottom">Ingrese los datos para compartir la información</legend>
                                                                            <div className="form-group row">
                                                                                <div className="col-lg-12">
                                                                                    <input type="text" className="form-control" required placeholder="Ingrese el nombre completo..." onChange={(text) => setNamePerson(text.target.value)} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group row">
                                                                                <div className="col-lg-12">
                                                                                    <input type="text" className="form-control" required placeholder="Ingrese el asunto..." onChange={(text) => setAsuntoPerson(text.target.value)} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group row">
                                                                                <div className="col-lg-12">
                                                                                    <input type="text" className="form-control" required placeholder="Ingrese el correo..." onChange={(text) => setEmailPerson(text.target.value)} />
                                                                                </div>
                                                                            </div>
                                                                        </fieldset>

                                                                        <div className="text-right">
                                                                            <button className="btn btn-link" onClick={() => setSharefile(false)}>Cancelar</button>
                                                                            <button type="submit" className="btn btn-primary">Enviar <i className="icon-paperplane ml-2" /></button>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            ) : (
                                                                <div className="card-body" style={{ paddingLeft: '2.5rem' }}>
                                                                    <QRCode value={urlqr} logoWidth={260} logoHeight={250} />
                                                                </div>
                                                            )
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>)
                            }

                        </div>
                    </div>
                </div>
            </div>
            {/*********** */}
            <div id="modalcfdi" className="modal fade" tabIndex="-1" aria-hidden="true" style={{ "display": "none" }}>
                <div className="modal-dialog modal-full">
                    <div className="modal-content">
                        <div className="modal-header" style={{ height: 80 }}>
                            <h5 className="modal-title"> Validación de CFDIs vs Listas negras</h5>
                            <button type="button" className="close" data-dismiss="modal" onClick={() => setViewCfdis('')}>×</button>
                        </div>
                        <div className="modal-body">
                            {cfdi_}
                        </div>
                    </div>
                </div>
            </div>
            <div id="modalcontribucionesfederales" className="modal fade" tabIndex="-1" aria-hidden="true" style={{ "display": "none" }}>
                <div className="modal-dialog modal-full">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Contribuciones federales</h5>
                            <button type="button" className="close" data-dismiss="modal" onClick={handleClearObjects}>×</button>
                        </div>

                        <div className="modal-body">
                            {contribucionfederal}
                        </div>
                    </div>
                </div>
            </div>
            <div id="modalinformativas" className="modal fade" tabIndex="-1" aria-hidden="true" style={{ "display": "none" }}>
                <div className="modal-dialog modal-full">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Declaraciones informativas</h5>
                            <button type="button" className="close" data-dismiss="modal" onClick={handleClearObjects}>×</button>
                        </div>

                        <div className="modal-body">
                            {declaracioninformativa}
                        </div>
                    </div>
                </div>
            </div>
            <div id="modalcontribucionesestatales" className="modal fade" tabIndex="-1" aria-hidden="true" style={{ "display": "none" }}>
                <div className="modal-dialog modal-full">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Contribuciones estatales</h5>
                            <button type="button" className="close" data-dismiss="modal" onClick={handleClearObjects}>×</button>
                        </div>

                        <div className="modal-body">
                            {contribucionestatal}
                        </div>
                    </div>
                </div>
            </div>
            <div id="modalmultas" className="modal fade" tabIndex="-1" aria-hidden="true" style={{ "display": "none" }}>
                <div className="modal-dialog modal-full">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Multas</h5>
                            <button type="button" className="close" data-dismiss="modal" onClick={handleClearObjects}>×</button>
                        </div>

                        <div className="modal-body">
                            {multa}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
function _validacion_icon_cfdis(i) {
    if (i === 0)
        return (<i style={{ color: 'gray', cursor: 'pointer' }} className="icon-minus-circle2"></i>);
    else if (i === 1)
        return (<i style={{ color: '#f9c74f', cursor: 'pointer' }} className="icon-warning22"></i>);
    else if (i === 2)
        return (<i style={{ color: "#ef5350", cursor: 'pointer' }} className="icon-cross2"></i>);
    else if (i === 3)
        return (<i style={{ color: '#66bb6a', cursor: 'pointer' }} className="icon-checkmark2"></i>);
    else
        return (<i style={{ color: 'gray' }} className="icon-minus-circle2"></i>);
}
function _contribuciones_federales_(i) {

    if (i === 1)
        return (<i style={{ color: '#f9c74f', cursor: 'pointer' }} className="icon-warning22"></i>);
    else if (i === 0 || i === 2)
        return (<i style={{ color: "#ef5350", cursor: 'pointer' }} className="icon-cross2"></i>);
    else if (i === 3)
        return (<i style={{ color: '#66bb6a', cursor: 'pointer' }} className="icon-checkmark2"></i>);
    else
        return (<i style={{ color: 'gray' }} className="icon-minus-circle2"></i>);
}
function iconmultas(i) {
    return (<i className="icon-file-text mr-3 text-info"></i>)
}
