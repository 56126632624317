import React, { Component } from 'react';
import api from '../../Config/apiconnect';
import Skeleton from '@material-ui/lab/Skeleton';
import ReactDatatable from '@ashvin27/react-datatable';
import '../../Reports/details/style.css';
class TaxCompliceF extends Component {
    constructor(props) {
        super(props);
        let objects = JSON.parse(localStorage.getItem("datacompanyall"));
        this.state = {
            rfc: objects.rfc,
            empresa_id: objects.id,
            result: '',
            internalIdentifier: '',
            checkedAt: '',
            taxpayer: {},
            directorio: '', linkpdf: '',
            loadingfirts: false,
            clave: '',
            id: '',
            list: []
        }
        this.columns = [
            {
                key: "internalIdentifier",
                text: "Folio",
                cell: record => {
                    return (
                        <div className="text-center">
                            {record.internalIdentifier}
                        </div>
                    );
                }
            },
            {
                key: "result",
                text: "Resultado",
                className: "result",
                cell: record => {
                    return (
                        <div className="text-center">
                            {record.result === "positive" ?
                                (<span className="text-success">Positivo</span>)
                                :
                                (<span className="text-danger">Negativo</span>)
                            }
                        </div>
                    );
                }
            },

            {
                key: "createdAt",
                text: "Fecha de resivión",
                className: "createdAt",
                cell: y => {
                    return (
                        <div className="text-center">
                            {(y.createdAt)}
                        </div>
                    );
                }
            },
            {
                key: "",
                text: "Archivos",
                className: "postcode",
                cell: y => {
                    return (
                        <div className="text-center">
                            <button type="button" className="btn btn-link" onClick={() => this.handelViewFilePDF(y)}><i className="icon-file-pdf mr-2 text-success"></i> PDF</button>
                        </div>
                    );
                }
            },
        ];
        this.config = {
            "page_size": 20,
            "length_menu": [50, 70, 90],
            "show_filter": true,
            "show_pagination": true,
            "button": {
                "excel": false,
                "print": false,
                "extra": false
            },
            "language": {
                "length_menu": "Mostrar _MENU_ registros por página",
                "filter": "Buscar en registros ...",
                "info": "Mostrando _START_ a _END_ de _TOTAL_ entradas",
                "pagination": {
                    "first": "Primero",
                    "previous": "Previa",
                    "next": "próximo",
                    "last": "Última"
                }
            }
        };
    }
    componentDidMount() {
        this.getdata();
    }
    getdata = async () => {
        let _data = await api.post('sat/tax-compliance-checks', { empresa_id: this.state.empresa_id });

        if (_data.data.length > 0) {
            console.log(_data.data)
            let _data_ = await _data.data[0];
            this.setState({
                list: JSON.parse(_data_.description),
                id: (JSON.parse(_data_.description)[0]).id,
                directorio: (JSON.parse(_data_.description)[0]).file.filename,
                result: ((JSON.parse(_data_.description)[0]).result) === "positive" ? "Positivo" : "Negativo",
                internalIdentifier: (JSON.parse(_data_.description)[0]).internalIdentifier,
                checkedAt: (JSON.parse(_data_.description)[0]).checkedAt,
                taxpayer: (JSON.parse(_data_.description)[0]).taxpayer
            })
        }
    }
    handleViewPDF = () => {
        this.setState({ loadingfirts: true, linkpdf: 'https://e-xpertum.com.mx/adminfile/api/doc-view-all/' + this.state.rfc + '/' + this.state.id + '/' + this.state.directorio })
        window.$("#modallargepdf").modal("show");
    }
    handelViewFilePDF = (x) => {
        console.log(x)
        this.setState({ loadingfirts: true, linkpdf: 'https://e-xpertum.com.mx/adminfile/api/doc-view-all/' + this.state.rfc + '/' + x.id + '/' + x.file.filename })
        window.$("#modallargepdf").modal("show");
    }
    handleLoading = async () => {
        this.setState({
            loadingfirts: false
        })
    }
    render() {
        return (
            <div className="content pt-0">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card-group-control card-group-control-right">
                            <div className="card">
                                <div className="card-header">
                                    <h6 className="card-title">
                                        <a data-toggle="collapse" className="text-default" href="#collapsible-control-right-group1">Opinión de cumplimiento</a>
                                        <button type="button" style={{ left: '72%' }} className="btn btn-light" onClick={this.handleViewPDF}><i className="icon-file-pdf mr-2 text-success"></i> PDF</button>
                                    </h6>
                                </div>
                                <div id="collapsible-control-right-group1" className="collapse show">
                                    <div className="card-body">
                                        <table className="table">
                                            <tbody>
                                                <tr>
                                                    <td>RFC</td>
                                                    <td>{this.state.taxpayer.rfc}</td>
                                                    <td>Denominación/Razón Social</td>
                                                    <td>{this.state.taxpayer.name}</td>
                                                </tr>
                                                <tr>
                                                    <td>Resultado</td>
                                                    <td className={(this.state.result === "Positivo" ? "text-success" : "text-danger")}>{this.state.result}</td>
                                                    <td>Folio</td>
                                                    <td>{this.state.internalIdentifier}</td>

                                                </tr>
                                                <tr>
                                                    <td>Fecha de revisión</td>
                                                    <td colSpan={3}>{this.state.checkedAt}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header">
                                    <h6 className="card-title">
                                        <a className="text-default" data-toggle="collapse" href="#accordion-control-right-group2">Historial de la opinión de cumplimiento</a>
                                    </h6>
                                </div>
                                <div id="accordion-control-right-group2" className="collapse show" data-parent="#accordion-control-right" style={{}}>
                                    <div className="card-body">
                                        <ReactDatatable
                                            config={this.config}
                                            records={this.state.list}
                                            columns={this.columns}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div id="modallargepdf" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                    <div className="modal-dialog modal-full">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Opinión de cumplimiento fiscal</h5>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>
                            <div className="modal-body">
                                <div className="embed-container">
                                    {
                                        (this.state.loadingfirts) ?
                                            (
                                                <div>
                                                    <Skeleton variant="text" />
                                                    <Skeleton variant="circle" animation="wave" width={40} height={40} />
                                                    <Skeleton variant="rect" animation="wave" width={510} height={118} />
                                                </div>
                                            ) : null
                                    }
                                    <iframe src={this.state.linkpdf} onLoad={this.handleLoading}
                                        width="100%"
                                        frameBorder="0" allowFullScreen
                                    >
                                    </iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TaxCompliceF;