import React, { Component } from 'react';
import { Tooltip } from "@material-ui/core";
/*******
 * LIBRARY UPLOAD FILES
 */
import * as ALLIcons from '@material-ui/icons';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
/*******
 * CONFIG CONEXION
 */
import api from '../../../app/Config/api';
/**************
 * NOTIFICATIONS
 */
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class FormImportFiles extends Component {
    constructor(props) {
        const data = JSON.parse(localStorage.getItem("datavalidate"));
        super(props);
        this.state = {
            filezip: '',
            filexml: '',
            namezip: 'Seleccione Zip',
            namexml: 'Seleccione los XMLS',
            amount: '',
            password: '',
            weight: '',
            weightRange: '',
            showPassword: false,
            note: '',
            zip: '',
            block: false,
            resultnotifications: '',
            show: false,
            alert: '',
            empresa_id: data.id,
            rfc: data.rfc
        }
    }

    handleChangeFileZIP = (e) => {
        this.setState({ block: true, resultnotifications: '' });
        const file = e.target.files[0];

        const formData = new FormData();
        formData.append('file', file)
        formData.append('empresa_id', this.state.empresa_id);
        formData.append('rfc', this.state.rfc);
        api.post('subirdocumentszip', formData).then(res => {
            if (res.data.status === 200)
                this.setState({ block: false, show: true, alert: res.data.menssage, zip: '', namezip: 'Seleccione Zip', resultnotifications: JSON.stringify(res.data.objectResult) });
        }).catch(err => console.log(err));
        this.setState({ namezip: file.name, filezip: file });
    }
    handleChangeFilesXML = (e) => {
        var file = e.target.files;
        if (file.length > 0) {
            var formData = new FormData();
            for (let index = 0; index < file.length; index++) {
                formData.append(`file`, file[index])
            }
            formData.append('rfc', this.state.rfc)
            formData.append('empresa_id', this.state.empresa_id)
            formData.append('numero', file.length)
            api.post('subirdocumentsvalidadorxmls', formData).then(res => {
                if (res.data.status === 200) {
                    this.setState.note = '';
                    this.setState({ block: false, show: true, alert: res.data.menssage, note: '', namexml: 'Seleccione los XMLS', resultnotifications: JSON.stringify(res.data.objectResult) });
                }
            }).catch(err => console.log(err));
        }
    }
    handleChange = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };

    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };
    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    handleCloseMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({ show: false });
    };
    render() {
        return (<>
            <div className="content-wrapper">
                <div className="content pt-0">
                    <div className="row">
                        <div className="col-md-6">
                            <br></br>
                            <div className="form-group row">
                                <label className="col-form-label col-lg-4">Seleccione archivos zip</label>
                                <div className="col-lg-7">
                                    <div className="uniform-uploader">
                                        <input type="file" className="form-control-uniform-custom" value={this.state.zip} onChange={this.handleChangeFileZIP} accept=".zip" />
                                        <span className="filename" style={{ userSelect: "none" }}>{this.state.namezip}</span>
                                        <span className="action btn bg-warning" style={{ userSelect: "none" }}>Archivos zip</span>
                                    </div>
                                </div>
                                <div className="col-lg-1">
                                    <a href="https://e-xpertum.com.mx/adminfile/help" target="_blank"><i className="icon-question3 text-warning"></i></a>
                                </div>
                            </div>
                            {/*<div className="form-group row">
                                            <label className="col-form-label col-lg-4">Seleccione los XML's</label>
                                            <div className="col-lg-7">
                                                <div className="uniform-uploader">
                                                    <input type="file" className="form-control-uniform-custom" value={this.state.note} multiple onChange={this.handleChangeFilesXML} accept=".xml" />
                                                    <span className="filename" style={{ userSelect: "none" }}> {this.state.namexml} </span>
                                                    <span className="action btn bg-warning" style={{ userSelect: "none" }}>XML's</span>
                                                </div>
                                            </div>
                                            <div className="col-lg-1">
                                            </div>
                                        </div>*/}
                        </div>

                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header header-elements-inline">
                                    <h6 className="card-title">Notificaciones</h6>
                                </div>
                                <div className="card-body">
                                    {this.state.resultnotifications}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Backdrop open={this.state.block} style={{ color: "#fff", background: "#B9B6B6", zIndex: 400, opacity: "0.5" }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar open={this.state.show} autoHideDuration={6000} onClose={this.handleCloseMessage} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert severity="success" onClose={this.handleCloseMessage}>{this.state.alert}</Alert>
            </Snackbar>
        </>);
    }
}

export default FormImportFiles;