import React, { Component } from 'react';

import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import Skeleton from '@material-ui/lab/Skeleton';
import api from '../../../app/Config/api';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
function addZero(i) {
    if (i < 10) {
        i = "0" + i;
    }
    return i;
}

function gethoursdate() {
    var d = new Date();

    var h = addZero(d.getHours());
    var m = addZero(d.getMinutes());
    var s = addZero(d.getSeconds());
    return (h + ":" + m + ":" + s + (h < 12 ? " am" : " pm"));
}
class MonitorActivities extends Component {
    constructor(props) {
        super(props);
        let prospecto = JSON.parse(localStorage.getItem("filterprospecto"));
        var objec = JSON.parse(localStorage.getItem('_data_ventas_admin_'));
        var datauser = JSON.parse(localStorage.getItem('id_token'));
        this.estatus = [
            { clave: 'New', descripcion: 'Pendiente a contactar' },
            { clave: 'Open', descripcion: 'Contactado sin decisión' },
            { clave: 'Inprogress', descripcion: 'Contactado sin interes' },
            { clave: 'Contactattempt', descripcion: 'Intento de contacto' },
            { clave: 'Cotizacion', descripcion: 'Cotizado sin respuesta' },
            { clave: 'Cliente', descripcion: 'Cambiar a cliente' }
        ]
        this.state = {
            info: [],
            editorState: EditorState.createEmpty(),
            comments: '',
            prospecto_id: prospecto.pros_id,
            status: '',
            id: prospecto.pros_id,
            hrs: gethoursdate(),
            listadofiles: [],
            nombrecompletoventas: objec.ncompleto + " <" + objec.email + ">",
            venta_id: datauser.id,
            comment_id: 0,
            asunto: ''
        }
        this.handleSendMail = this.handleSendMail.bind();
    }
    handlechangeData = (o) => {
        this.setState({ [o.target.name]: o.target.value })
    }
    componentDidMount() {
        this.getfilescreateautomatic();
        setTimeout(() => {
            this.getarrayfiles();
        }, 600);

    }
    getarrayfiles = async () => {
        let par = {
            id: this.state.comment_id
        }
        let file = await api.post("getfilesmails", par);
        let { data } = await file;
        if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                data[i].ext = getxtension(data[i].nombrefile)
            }
        }
        this.setState({ listadofiles: data });
    }
    getfilescreateautomatic = async () => {
        let deleted = await api.post("llenadoautomatico", this.state);
        let { info } = await deleted.data;
        this.setState({ comment_id: info.id });
    }
    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
            comments: JSON.stringify(convertToRaw(editorState.getCurrentContent()))
        });
    };
    onChangeHandler = event => {
        var objec = JSON.parse(localStorage.getItem('_data_ventas_admin_'));
        let data_contacto_ = JSON.parse(localStorage.getItem('_contact_admin_'));
        let prospecto = JSON.parse(localStorage.getItem("filterprospecto"));
        var parameters = new FormData();
        parameters.append("file", event.target.files[0])
        parameters.append("distribuidor", objec.usuario)
        parameters.append("vendedor", data_contacto_.usuario)
        parameters.append("prospecto", prospecto.correo)
        parameters.append("prospecto_id", this.state.comment_id)
        api.post("/uploadfileventas", parameters).then((i) => i.data).then((h) => {
            console.log(h)
            this.getarrayfiles();
        })
    }
    handleSendMail = async (event) => {
        let parameters = {
            comments: this.state.comments,
            comment_id: this.state.comment_id,
            status: this.state.status,
            asunto: this.state.asunto,
            prospecto_id: this.state.prospecto_id
        }
        event.preventDefault();
        let datainfo = await api.post("sendchangestatusprospecto", parameters)
        let { data } = await datainfo;
        if (data.estatus === 200)
            this.props.close();
        /*api.post("sendchangestatusprospecto", parameters).then((op) => op.data).then((ui) => {
            console.log(ui)
        })*/
    }
    handleDeleteFrom = async (i) => {
        let deleted = await api.post("deletefilesprospect", i);
        this.getarrayfiles();
    }
    handleViewPreviewFile = (k) => {
        window.location.href = 'https://e-xpertum.com.mx/webadmin/api/documents/getfilesproveedor?query=' + k.directorio;
    }
    handleCancelEmail = async () => {
        let deleted = await api.post("deletecomments", { id: this.state.comment_id });
        this.props.close();
    }
    render() {
        const { editorState } = this.state;
        return (
            <div className="card">
                <div className="card-body">
                    <form onSubmit={this.handleSendMail}>
                        <div className="navbar navbar-light navbar-expand-lg py-lg-2 rounded-top shadow-0">
                            <div className="navbar-collapse text-center text-lg-left flex-wrap collapse" id="inbox-toolbar-toggle-write">

                                <div className="mt-3 mt-lg-0 mr-lg-3">
                                    <button type="submit" className="btn bg-blue"><i className="icon-paperplane mr-2"></i> Enviar</button>
                                </div>
                                <div className="mt-3 mt-lg-0 mr-lg-3">
                                    <div className="btn-group">
                                        <button type="button" className="btn btn-light" onClick={this.handleCancelEmail}>
                                            <i className="icon-cross2"></i>
                                            <span className="d-none d-lg-inline-block ml-2">Cancelar</span>
                                        </button>
                                    </div>
                                </div>
                                <div className="navbar-text ml-lg-auto">{this.state.hrs}</div>
                            </div>
                        </div>
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td className="align-top py-0" style={{ "width": "1%" }}>
                                        <div className="py-2 mr-sm-3">Para:</div>
                                    </td>
                                    <td className="align-top py-0">
                                        <div className="d-sm-flex flex-sm-wrap">
                                            <input type="text" className="form-control bg-transparent flex-fill w-auto py-2 px-0 border-0 rounded-0" placeholder="Agregar destinatario" value={this.state.nombrecompletoventas} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-top py-0">
                                        <div className="py-2 mr-sm-3">Asunto:</div>
                                    </td>
                                    <td className="align-top py-0">
                                        <input type="text" className="form-control bg-transparent py-2 px-0 border-0 rounded-0" placeholder="Agregar asunto" name="asunto" onChange={this.handlechangeData} />
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: '25%' }}>
                                        <ul className="list-inline d-sm-flex flex-sm-wrap mb-0">
                                            <li className="list-inline-item">
                                                <a>
                                                    <label htmlFor="file" style={{ cursor: 'pointer' }} >
                                                        <i className="icon-attachment mr-2"></i> Adjuntar archivos
                                                    <input type="file" name="file" id="file" onChange={this.onChangeHandler} style={{ display: "none" }} />
                                                    </label>
                                                </a>
                                            </li>
                                        </ul>
                                    </td>
                                    <td>
                                        <select className="form-control" name="status" value={this.state.status} onChange={this.handlechangeData}>
                                            {
                                                (this.estatus).map((j, k) => (
                                                    <option value={j.clave} key={k}>{j.descripcion}</option>
                                                ))
                                            }
                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div className="form-group row">
                            <div className="col-lg-12">
                                <Editor
                                    placeholder="Describa el texto"
                                    editorState={editorState}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange}
                                    toolbar={{
                                        inline: { inDropdown: true },
                                        list: { inDropdown: true },
                                        textAlign: { inDropdown: true },
                                        link: { inDropdown: true },
                                        history: { inDropdown: true },
                                    }}
                                />
                            </div>
                        </div>
                    </form>
                    {
                        (this.state.listadofiles.length > 0) ? (
                            <div className="card-body border-top">
                                <h6 className="mb-0">{this.state.listadofiles.length} archivo(s) adjuntos</h6>
                                <ul className="list-inline mb-0">
                                    {
                                        (this.state.listadofiles).map((i, k) => (
                                            <li className="list-inline-item" key={k}>
                                                <div className="card bg-light py-2 px-3 mt-2 mb-0">
                                                    <div className="media my-1">
                                                        <div className="mr-3 a lign-self-center">
                                                            {
                                                                ((i.ext) === 'pdf') ? (<i className="icon-file-pdf icon-2x text-danger-400 top-0"></i>) :
                                                                    (
                                                                        ((i.ext) === "jpg" || (i.ext) === "png" || (i.ext) === "jepg") ? (<i className="icon-image2 icon-2x text-danger-400 top-0"></i>)
                                                                            : (
                                                                                ((i.ext) === "cvs" || (i.ext) === "xlsx" || (i.ext) === "xls") ? (<i className="icon-file-spreadsheet icon-2x text-success-400 top-0"></i>) : (
                                                                                    <i className="icon-file-empty2 icon-2x text-danger-400 top-0"></i>)
                                                                            )
                                                                    )
                                                            }
                                                        </div>
                                                        <div className="media-body">
                                                            <div className="font-weight-semibold">{i.nombrefile}</div>

                                                            <ul className="list-inline list-inline-condensed mb-0">
                                                                <li className="list-inline-item"><a onClick={() => this.handleViewPreviewFile(i)} style={{ cursor: 'pointer' }}> Ver</a></li>
                                                                <li className="list-inline-item"><a onClick={() => this.handleDeleteFrom(i)} style={{ cursor: 'pointer' }}>Eliminar</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        ) : null
                    }
                </div>
            </div>

        )
    }
}

export default MonitorActivities;
function getxtension(params) {
    var ext = params.split('.');
    // ahora obtenemos el ultimo valor despues el punto
    // obtenemos el length por si el archivo lleva nombre con mas de 2 puntos
    ext = ext[ext.length - 1];
    return ext;
}