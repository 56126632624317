import React, { Component } from 'react';
import api from '../../../app/Config/api';
import draftToHtml from 'draftjs-to-html';
import SKELETON from '../../Sales/details/skeleton';
import parse from 'html-react-parser';
class Inbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            mail: '',
            listadofiles: [],
            asunto: '',
            comments: '',
            fecha: '',
            id: 0,
            nombre_: '',
            prospecto: '',
            prospecto_id: 0,
            usuario: '',
            skeleton: true,
        }
    }
    componentDidMount() {
        this.getarraydata();
        setInterval(() => {
            this.getarraydata();
        }, 20000);
    }
    getarraydata = async () => {
        let getinformacion = await api.post("getlistmailssendventasall");
        let { data } = await getinformacion;
        this.setState({ list: data });
    }
    getarrayfiles = async () => {
        let par = {
            id: this.state.id
        }
        let file = await api.post("getarrayfilesendmailadmin", par);
        let { data } = await file;
        if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                data[i].ext = getxtension(data[i].nombrefile)
            }
        }
        this.setState({ listadofiles: data });
    }
    viewmailfilter = (op) => {
        if (op.comments !== "") {
            this.setState(op);
            setTimeout(() => {
                this.getarrayfiles();
                this.setState({ skeleton: false })
            }, 400);
        }
    }
    handleViewPreviewFile = (i) => {
        window.location.href = 'https://e-xpertum.com.mx/webadmin/api/documents/getfilesproveedor?query=' + i.directorio;
    }
    render() {
        return (
            <>
                <div className="content-wrapper">
                    <div className="page-header border-bottom-0">
                        <div className="page-header-content header-elements-md-inline">
                            <div className="page-title d-flex">
                                <h4><i className="icon-home mr-2" /> <span className="font-weight-semibold">Inicio</span></h4>
                                <a href="#" className="header-elements-toggle text-default d-md-none"><i className="icon-more" /></a>
                            </div>
                            <h6>Listado de correos</h6>
                            <div className="header-elements d-none">
                            </div>
                        </div>
                    </div>
                    <div className="content pt-0">
                        <div className="row">
                            <div className="col-sm-4">
                                <div className="card">
                                    <div className="card-header bg-transparent border-bottom header-elements-inline">
                                        <span className="card-title font-weight-semibold">Listado de correos</span>
                                        <div className="header-elements">
                                            <div className="list-icons">
                                                <a className="list-icons-item" data-action="collapse" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <ul className="media-list" style={{ overflowY: 'scroll', height: 400 }}>
                                            {
                                                (this.state.list || []).map((i, j) => (
                                                    <li className="media" key={j} onClick={() => this.viewmailfilter(i)} style={{ cursor: 'pointer' }}>
                                                        <div className="mr-3">
                                                            <h5 className="text-center text-success mb-0">{String(i.fecha).substr(8, 2)}</h5>
                                                            <div className="font-size-sm text-muted line-height-1">{getmeses(String(i.fecha).substr(5, 2))}</div>
                                                        </div>
                                                        <div className="media-body">
                                                            <span className="font-weight-semibold">{i.asunto + ', ' + i.prospecto}</span>&nbsp;&nbsp;<span className="text-success">Vendedor</span>  <a style={{ color: '#8dccff' }}>{i.nombre_}</a>
                                                            <ul className="list-inline list-inline-dotted mt-1 mb-0">
                                                                <li className="list-inline-item"><span className="text-muted">{i.distribuidor} (Distribuidor)</span></li>
                                                                <li className="list-inline-item"><span className="text-muted">{String(i.fecha).substr(11, 5)}</span></li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-8">
                                {/* Single line */}
                                <div className="card">
                                    {
                                        this.state.skeleton ? (<SKELETON />) :
                                            (
                                                <div className="card-body">
                                                    <div className="card-body">
                                                        <div className="media flex-column flex-md-row">
                                                            <a href="#" className="d-none d-md-block mr-md-3 mb-3 mb-md-0">
                                                                <span className="btn bg-teal-400 btn-icon btn-lg rounded-round">
                                                                    <span className="letter-icon">{(this.state.prospecto !== null ? this.state.prospecto.substr(0, 1) : "N")}</span>
                                                                </span>
                                                            </a>
                                                            <div className="media-body">
                                                                <h6 className="mb-0">{this.state.asunto}</h6>
                                                                <table className="table">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td width="30">Prospecto:</td><td><div className="letter-icon-title font-weight-semibold">{(this.state.prospecto !== null ? this.state.prospecto : "")} <a style={{ color: "#03a9f4" }}>&lt;{this.state.correo_}&gt;</a></div></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Vendedor:</td><td><div className="letter-icon-title font-weight-semibold">{this.state.nombre_} <a style={{ color: "#03a9f4" }}>&lt;{this.state.email}&gt;</a></div></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Distribuidor:</td><td><div className="letter-icon-title font-weight-semibold">{this.state.distribuidor} </div></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>


                                                            </div>

                                                        </div>
                                                    </div>
                                                    {/* /mail details */}
                                                    {/* Mail container */}
                                                    <div className="card-body border-top">
                                                        <div className="overflow-auto mw-100">
                                                            {/* Email sample (demo) */}
                                                            <table width="100%" border={0} cellPadding={0} cellSpacing={0} align="center">
                                                                <tbody><tr>
                                                                    <td>
                                                                        {/* The Best Prices for You */}
                                                                        <table width="100%" border={0} cellPadding={0} cellSpacing={0} align="center">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td width="100%" height={1} bgcolor="#dddddd" style={{ fontSize: '1px', lineHeight: '1px' }}>&nbsp;</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td align="center" width="100%" valign="top" bgcolor="#fafafa" style={{ backgroundColor: '#fafafa' }}>
                                                                                        <table width={640} border={0} cellPadding={0} cellSpacing={0} align="center">
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <td width="100%" height={50} />
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td align="center">
                                                                                                        {/* Header */}
                                                                                                        <table width={600} border={0} cellPadding={0} cellSpacing={0} align="center">
                                                                                                            <tbody>
                                                                                                                <tr>
                                                                                                                    <td align="center">
                                                                                                                        <table width={600} border={0} cellPadding={0} cellSpacing={0} align="center">
                                                                                                                            <tbody>
                                                                                                                                <tr>
                                                                                                                                    <td align="center" valign="middle" style={{ fontSize: '24px', color: '#444444', lineHeight: '32px', fontWeight: 500 }}>
                                                                                                                                        {this.state.asunto}
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <td width="100%" height={30} />
                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <td width="100%">
                                                                                                                                        <table width={100} border={0} cellPadding={0} cellSpacing={0} align="center">
                                                                                                                                            <tbody>
                                                                                                                                                <tr>
                                                                                                                                                    <td height={1} bgcolor="#f67b7c" style={{ fontSize: '1px', lineHeight: '1px' }}>&nbsp;</td>
                                                                                                                                                </tr>
                                                                                                                                            </tbody>
                                                                                                                                        </table>
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <td width="100%" height={30} />
                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <td valign="middle" width="100%"
                                                                                                                                        style={{ fontSize: '14px', color: '#808080', lineHeight: '22px', fontWeight: 400 }}>
                                                                                                                                        <p style={{ textAlign: 'justify', fontSize: '14px', color: '#808080', lineHeight: '22px', fontWeight: 400 }}>

                                                                                                                                            {
                                                                                                                                                this.state.asunto !== "" ? (
                                                                                                                                                    <>
                                                                                                                                                        {parse(draftToHtml((JSON.parse(this.state.comments))))}
                                                                                                                                                    </>
                                                                                                                                                ) : null
                                                                                                                                            }

                                                                                                                                        </p>
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <td width="100%" height={30} />
                                                                                                                                </tr>
                                                                                                                            </tbody>
                                                                                                                        </table>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                        {/* /header */}
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td height={60} />
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        {/* /the Best Prices for You */}


                                                                    </td>
                                                                </tr>
                                                                </tbody></table>
                                                            {/* /email sample (demo) */}
                                                        </div>
                                                    </div>
                                                    {/* /mail container */}
                                                    {/* Attachments */}
                                                    {
                                                        (this.state.listadofiles.length > 0) ? (
                                                            <div className="card-body border-top">
                                                                <h6 className="mb-0">{this.state.listadofiles.length} archivo(s) adjuntos</h6>
                                                                <ul className="list-inline mb-0">
                                                                    {
                                                                        (this.state.listadofiles).map((i, k) => (
                                                                            <li className="list-inline-item" key={k}>
                                                                                <div className="card bg-light py-2 px-3 mt-2 mb-0">
                                                                                    <div className="media my-1">
                                                                                        <div className="mr-3 a lign-self-center">
                                                                                            {
                                                                                                ((i.ext) === 'pdf') ? (<i className="icon-file-pdf icon-2x text-danger-400 top-0"></i>) :
                                                                                                    (
                                                                                                        ((i.ext) === "jpg" || (i.ext) === "png" || (i.ext) === "jepg") ? (<i className="icon-image2 icon-2x text-danger-400 top-0"></i>)
                                                                                                            : (
                                                                                                                ((i.ext) === "cvs" || (i.ext) === "xlsx" || (i.ext) === "xls") ? (<i className="icon-file-spreadsheet icon-2x text-success-400 top-0"></i>) : (
                                                                                                                    <i className="icon-file-empty2 icon-2x text-danger-400 top-0"></i>)
                                                                                                            )
                                                                                                    )
                                                                                            }
                                                                                        </div>
                                                                                        <div className="media-body">
                                                                                            <div className="font-weight-semibold">{i.nombrefile}</div>

                                                                                            <ul className="list-inline list-inline-condensed mb-0">
                                                                                                <li className="list-inline-item"><a onClick={() => this.handleViewPreviewFile(i)} style={{ cursor: 'pointer' }}> Ver</a></li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        ))
                                                                    }
                                                                </ul>
                                                            </div>
                                                        ) : null
                                                    }
                                                </div>
                                            )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Inbox;
function getmeses(params) {
    switch (params) {
        case '01':
            return "Ene"
            break;
        case '02':
            return "Feb"
            break;
        case '03':
            return "Mar"
            break;
        case '04':
            return "Abr"
            break;
        case '05':
            return "May"
            break;
        case '06':
            return "Jun"
            break;
        case '07':
            return "Jul"
            break;
        case '08':
            return "Ago"
            break;
        case '09':
            return "Sep"
            break;
        case '10':
            return "Oct"
            break;
        case '11':
            return "Nov"
            break;
        case '12':
            return "Dic"
            break;
        default:
            return "Error"
            break;
    }
}
function getxtension(params) {
    var ext = params.split('.');
    ext = ext[ext.length - 1];
    return ext;
}