import React, { Component } from 'react';
import api from '../../Config/apifiles';
import { Tooltip, } from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactJson from 'react-json-view';
import Backdrop from "@material-ui/core/Backdrop";
import ReactDatatable from '@ashvin27/react-datatable';
function getMinMaxTime(points) {
    if (!points) return undefined;
    if (!points.length) return undefined; // not array
    if (points.length < 0) return null;
    var min = points[0].ejercicio;
    var max = points[0].ejercicio;
    for (var index in points) {
        if (points[index].ejercicio > max) max = points[index].ejercicio;
        if (points[index].ejercicio < min) min = points[index].ejercicio;
    }
    return { min: min, max: max };
}
function orderbydate(a, b) {
    return new Date(a.fecha).getTime() - new Date(b.fecha).getTime();
}
class SueldosSalarios extends Component {
    constructor(props) {
        super(props);
        let objects = JSON.parse(localStorage.getItem("datacompanyall"));
        this.columns = [
            {
                key: "fecha",
                text: "Fecha",
                className: "name",
                cell: record => {
                    return (
                        <div className="text-center">
                            {record.fecha.substr(0, 10)}
                        </div>
                    );
                }
            },
            {
                key: "deducciones_concepto",
                text: "Concepto",
                cell: record => {
                    return (
                        <div className="text-center">
                            {record.deducciones_concepto}
                        </div>
                    );
                }
            },
            {
                key: "deducciones_total_impuestos_retenidos",
                text: "Total impuesto retenido",
                className: "postcode",
                cell: y => {
                    return (
                        <div className="text-center">
                            {(y.deducciones_total_impuestos_retenidos < 0 ? (
                                <span className="text-danger">({y.deducciones_total_impuestos_retenidos.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1, ')})</span>
                            ) : <>{y.deducciones_total_impuestos_retenidos.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1, ')}</>
                            )}
                        </div>
                    );
                }
            },
            {
                key: "percepciones_total_exento",
                text: "Exento",
                className: "postcode",
                cell: y => {
                    return (
                        <div className="text-center">
                            {(y.percepciones_total_exento < 0 ? (
                                <span className="text-danger">({y.percepciones_total_exento.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1, ')})</span>
                            ) : <>{y.percepciones_total_exento.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1, ')}</>
                            )}
                        </div>
                    );
                }
            },
            {
                key: "percepciones_total_gravado",
                text: "Gravado",
                className: "action",
                cell: y => {
                    return (
                        <div className="text-left">
                            {(y.percepciones_total_gravado).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                        </div>
                    );
                }
            },
            {
                key: "percepciones_total_sueldos",
                text: "Sueldo",
                className: "action",
                cell: y => {
                    return (
                        <div className="text-left">
                            {y.percepciones_total_sueldos.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                        </div>
                    );
                }
            },
            {
                cell: y => {
                    return (
                        <div className="text-center">
                            <a onClick={() => this.viewpdfcfdi_(y)} style={{ cursor: 'pointer' }}> <Tooltip title="Click para ver cfdi completo" placement="top" arrow><span className="badge badge-flat  text-success"><i className="icon-file-pdf"></i></span></Tooltip></a>
                            &nbsp;&nbsp;
                            <a onClick={() => this.viewxml(y)} style={{ cursor: 'pointer' }}><Tooltip title="Click para ver cfdi completo" placement="top" arrow><span className="badge badge-flat  text-success"><i className="icon-file-xml"></i></span></Tooltip></a>
                        </div>
                    );
                }
            }
        ];
        this.config = {
            "page_size": 50,
            "length_menu": [50, 70, 90],
            "show_filter": true,
            "show_pagination": true,
            "button": {
                "excel": false,
                "print": false,
                "extra": false
            },
            "language": {
                "length_menu": "Mostrar _MENU_ registros por página",
                "filter": "Buscar en registros ...",
                "info": "Mostrando _START_ a _END_ de _TOTAL_ entradas",
                "pagination": {
                    "first": "Primero",
                    "previous": "Previa",
                    "next": "próximo",
                    "last": "Última"
                }
            }
        };
        this.state = {
            empresa_id: objects.id,
            nombrecompany: objects.nombre,
            rfc: objects.rfc,
            dataexercise: ['2016', '2017', '2018', '2019', '2020', '2021'],
            datafactura: [],
            datadeclaraciones: [],
            header: true,
            body: false,
            datacfdi: [],
            allcfdis: [],
            link: '',
            loading: false,
            loadingpdf: false,
            linkpdf: '',
            loadingfirts: false,
            clave: '',
            cargandospanol: true,
            filterdeclaracion: [],
            subtitlemodal: '',
            cfdi: '',
            showloading: false,
            arraymonths: ['', 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
            sumimpuestoretenido: 0,
            sumexento: 0, sumgravado: 0, sumsueldo: 0,
            ejercicio: 0, periodo: ''
        }
    }
    componentDidMount() {
        this.getdataaudite()
    }
    getdataaudite = async () => {
        let _config_data_tmp_ = await api.post('getdataadmindata', { empresa_id: this.state.empresa_id });
        let data_ = await api.post('list-data-cfdis-declaraciones', { empresa_id: this.state.empresa_id });

        let { facturas, declaracion } = await data_.data;
        if (facturas.length > 0) {
            let exercises = getMinMaxTime(facturas);
            let exercise_add = []
            for (let init = exercises.min; init <= exercises.max; init++) {
                exercise_add.push(init)
            }
            this.setState({ cargandospanol: false, datafactura: facturas, datadeclaraciones: declaracion, dataexercise: exercise_add, clave: _config_data_tmp_.data });
        }
        else {
            this.setState({
                cargandospanol: false,
            })
        }
    }
    handleChangeView = async (p) => {
        this.setState({ showloading: true });
        setTimeout(async () => {
            let data_ = await api.post('list-cfdis-nomina-imports',
                { empresa_id: this.state.empresa_id, ejercicio: p.ejercicio, periodo: p.period }
            );
            let _data_iteracion_ = data_.data;
            let _local_sumimpuestoretenido = 0, _local_sumexento = 0, _local_sumgravado = 0, _local_sumsueldo = 0;
            for (let j = 0; j < _data_iteracion_.length; j++) {
                _local_sumimpuestoretenido = _local_sumimpuestoretenido + _data_iteracion_[j].deducciones_total_impuestos_retenidos;
                _local_sumexento = _local_sumexento + _data_iteracion_[j].percepciones_total_exento;
                _local_sumgravado = _local_sumgravado + _data_iteracion_[j].percepciones_total_gravado;
                _local_sumsueldo = _local_sumsueldo + _data_iteracion_[j].percepciones_total_sueldos;
            }
            this.setState({
                ejercicio: p.ejercicio, periodo: p.period,
                header: false,
                body: true,
                datacfdi: _data_iteracion_.sort(orderbydate),
                allcfdis: data_.data,
                showloading: false, sumimpuestoretenido: _local_sumimpuestoretenido.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
                sumexento: _local_sumexento.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'), sumgravado: _local_sumgravado.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'), sumsueldo: _local_sumsueldo.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
            });

        }, 200);

    }
    viewpdfcfdi_ = async (t) => {
        this.setState({
            loadingpdf: true
        })
        let _link_ = "";
        _link_ = 'https://e-xpertum.com.mx/adminfile/api/genera-pdf-cfdi-nomina/' + t.cfdi_id + '/' + (t.uuid + "&" + this.state.rfc);

        this.setState({
            link: _link_,
            uuid: t.uuid
        });

        window.$('#viewpdfcfdi').modal('show');
    }
    handleLoading = async () => {
        this.setState({
            loadingpdf: false
        })
    }
    _viewInit_ = () => {
        this.setState({
            header: true,
            body: false,
        })
    }
    handleViewPDF = () => {
        let linkpdf = 'https://e-xpertum.com.mx/adminfile/api/export-pdf-view-nomina/' + this.state.rfc + '/' + this.state.nombrecompany + '/' + this.state.clave;
        var win = window.open(linkpdf, '_blank');
        if (win) {
            win.focus();
        } else {
            alert('Please allow popups for this website');
        }
    }
    handleLoading = async () => {
        this.setState({
            loadingfirts: false
        })
    }
    getViewPdfFilter = async (p) => {
        let _filter_data_ = (this.state.datadeclaraciones).filter((h) => h.ejercicio === p.ejercicio && h.period === p.period);

        if (_filter_data_.length > 0) {
            ///console.log(_filter_data_)
            // DistinctRecords((retenciones_iva_declarados_).filter((t) => t.period == _new_array_imports_[cf].period && (t.totalsubsidio) > 0), "numero_de_operacion")
            this.setState({
                subtitlemodal: (this.state.arraymonths[parseInt(p.period)] + ' de ' + p.ejercicio),
                filterdeclaracion: DistinctRecords((_filter_data_).sort(function (a, b) {
                    return a.numero_de_operacion - b.numero_de_operacion;
                }), "numero_de_operacion")
            });
        }
        else
            this.setState({
                subtitlemodal: (this.state.arraymonths[parseInt(p.period)] + ' de ' + p.ejercicio),
                filterdeclaracion: []
            });

        window.$("#modalpegasusproject").modal("show");
    }
    handleViewPDFDeclaracion = (t) => {
        let linkpdf = 'https://e-xpertum.com.mx/adminfile/api/displayfilepdf/bd54bba3f9d85ea1426942775518b90b6/ecb868faf29054fedf1ac6cbac5fc63/' + t.directorio;
        var win = window.open(linkpdf, '_blank');
        if (win) {
            win.focus();
        } else {
            alert('Please allow popups for this website');
        }
    }
    viewxml = async (t) => {
        ///console.log(t)
        window.$("#viewxmlcfdi").modal("show");
        let _data_ = await api.post('genera_xml_cfdi', { id: t.cfdi_id, tipo: 'EMITIDO' });
        this.setState({
            uuid: t.uuid,
            cfdi: <ReactJson src={JSON.parse(_data_.data)} enableClipboard={false} theme={"monokai"} displayDataTypes={false} displayObjectSize={false} />
        })

    }
    handleFilterretenciones = async () => {
        this.setState({ showloading: true });
        setTimeout(async () => {
            let data_ = await api.post('list-cfdis-nomina-imports-mayor-cero',
                { empresa_id: this.state.empresa_id, ejercicio: this.state.ejercicio, periodo: this.state.periodo }
            );
            if (data_.data.length > 0) {
                let _data_iteracion_ = data_.data;
                let _local_sumimpuestoretenido = 0, _local_sumexento = 0, _local_sumgravado = 0, _local_sumsueldo = 0;
                for (let j = 0; j < _data_iteracion_.length; j++) {
                    _local_sumimpuestoretenido = _local_sumimpuestoretenido + _data_iteracion_[j].deducciones_total_impuestos_retenidos;
                    _local_sumexento = _local_sumexento + _data_iteracion_[j].percepciones_total_exento;
                    _local_sumgravado = _local_sumgravado + _data_iteracion_[j].percepciones_total_gravado;
                    _local_sumsueldo = _local_sumsueldo + _data_iteracion_[j].percepciones_total_sueldos;
                }
                this.setState({
                    showloading: false,
                    datacfdi: _data_iteracion_,
                    sumimpuestoretenido: _local_sumimpuestoretenido.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
                    sumexento: _local_sumexento.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
                    sumgravado: _local_sumgravado.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
                    sumsueldo: _local_sumsueldo.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                })
            }
            else
                this.setState({
                    showloading: false,
                    datacfdi: [],
                    sumimpuestoretenido: 0.00,
                    sumexento: 0.00,
                    sumgravado: 0.00,
                    sumsueldo: 0.00
                })
        }, 300);
    }
    handlefilterall = async () => {
        this.setState({ showloading: true });
        setTimeout(async () => {
            let _data_iteracion_ = this.state.allcfdis;
            if (_data_iteracion_.length > 0) {
                let _local_sumimpuestoretenido = 0, _local_sumexento = 0, _local_sumgravado = 0, _local_sumsueldo = 0;
                for (let j = 0; j < _data_iteracion_.length; j++) {
                    _local_sumimpuestoretenido = _local_sumimpuestoretenido + _data_iteracion_[j].deducciones_total_impuestos_retenidos;
                    _local_sumexento = _local_sumexento + _data_iteracion_[j].percepciones_total_exento;
                    _local_sumgravado = _local_sumgravado + _data_iteracion_[j].percepciones_total_gravado;
                    _local_sumsueldo = _local_sumsueldo + _data_iteracion_[j].percepciones_total_sueldos;
                }
                this.setState({
                    datacfdi: _data_iteracion_.sort(orderbydate),
                    showloading: false, sumimpuestoretenido: _local_sumimpuestoretenido.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
                    sumexento: _local_sumexento.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'), sumgravado: _local_sumgravado.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'), sumsueldo: _local_sumsueldo.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                });
            }
            else
                this.setState({
                    showloading: false,
                    datacfdi: [],
                    sumimpuestoretenido: 0.00,
                    sumexento: 0.00,
                    sumgravado: 0.00,
                    sumsueldo: 0.00
                });
        }, 300);
    }
    handleexportexcel = async () => {
        window.open('http://e-xpertum.com.mx/adminfile/api/export-work-papers-nomina/' + this.state.empresa_id + '/' + this.state.ejercicio + '/' + this.state.periodo);
    }
    render() {
        return (
            <>
                {
                    (this.state.datafactura.length > 0 || this.state.datafactura.length > 0) ? (
                        <>
                            <Backdrop open={this.state.showloading} style={{ color: "#fff", background: "#B9B6B6", zIndex: 400, opacity: "0.5" }}>
                                <CircularProgress color="inherit" />
                            </Backdrop>
                            {
                                <>
                                    {
                                        (this.state.header === true && this.state.body === false) ?
                                            (
                                                <div className="card">
                                                    <div className="card-header header-elements-sm-inline">
                                                        <h6 className="card-title">Sueldos y salarios + asimilados a salarios</h6>
                                                        <button type="button" className="btn btn-light" onClick={this.handleViewPDF}><i className="icon-file-pdf mr-2 text-success"></i> PDF</button>
                                                    </div>
                                                    <div className="media card-body flex-column flex-lg-row m-0">
                                                        <div className="media-body">
                                                            {
                                                                (this.state.dataexercise).map((g, j) => (
                                                                    <div className="card" key={j}>
                                                                        <div className="card-header header-elements-sm-inline">
                                                                            <h6 className="card-title"> CFDI vs Retenciones por (Sueldos y salarios + asimilados a salarios) del {g}</h6>
                                                                        </div>
                                                                        <div className="media card-body flex-column flex-lg-row m-0">
                                                                            <div className="media-body">
                                                                                {
                                                                                    <DetailsViews key={String(j + 1)} factura={this.state.datafactura}
                                                                                        declaracion={this.state.datadeclaraciones} filter={g}
                                                                                        View={this.handleChangeView}
                                                                                        getViewPdfFilter={this.getViewPdfFilter}

                                                                                    />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            ) :
                                            (
                                                <div className="card">
                                                    <div className="card-header header-elements-sm-inline">
                                                        <h6 className="card-title"> CFDI de Retenciones por (Sueldos y salarios + asimilados a salarios) </h6>
                                                        {
                                                            (this.state.header === false && this.state.body === true) ?
                                                                (
                                                                    <div className="d-flex align-items-center" style={{ cursor: 'pointer' }} onClick={this._viewInit_}>
                                                                        <i className="icon-arrow-left16 mr-3 icon-2x text-primary"></i>
                                                                        <div className="text-primary">Inicio</div>
                                                                    </div>
                                                                ) : null
                                                        }

                                                    </div>
                                                    <div className="media card-body flex-column flex-lg-row m-0">
                                                        <div className="media-body">
                                                            <div className="form-group">
                                                                <div className="border p-3 rounded">
                                                                    <div className="custom-control custom-radio custom-control-inline">
                                                                        <input type="radio" className="custom-control-input" name="cr-i-l" id="cr_l_i_s" onClick={this.handleFilterretenciones} />
                                                                        <label className="custom-control-label" htmlFor="cr_l_i_s">Solo CFDIs con impuesto retenido</label>
                                                                    </div>
                                                                    <div className="custom-control custom-radio custom-control-inline">
                                                                        <input type="radio" className="custom-control-input" name="cr-i-l" id="cr_l_i_u" defaultChecked onClick={this.handlefilterall} />
                                                                        <label className="custom-control-label" htmlFor="cr_l_i_u">Todos los CFDIs</label>
                                                                    </div>
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                    <button type="button" className="btn btn-light" onClick={this.handleexportexcel}><i className="icon-file-excel mr-2 text-success"></i> Excel</button>
                                                                </div>
                                                            </div>
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Total impuesto retenido</th>
                                                                        <th>Exento</th>
                                                                        <th>Gravado</th>
                                                                        <th>Sueldo</th>
                                                                        <th></th>
                                                                    </tr>
                                                                </thead>
                                                                <tfoot>
                                                                    <tr>
                                                                        <td style={{ textAlign: 'right' }}>{this.state.sumimpuestoretenido}</td>
                                                                        <td style={{ textAlign: 'right' }}>{this.state.sumexento}</td>
                                                                        <td style={{ textAlign: 'right' }}>{this.state.sumgravado}</td>
                                                                        <td style={{ textAlign: 'right' }}>{this.state.sumsueldo}</td>
                                                                    </tr>
                                                                </tfoot>
                                                            </table>
                                                            <ReactDatatable
                                                                config={this.config}
                                                                records={this.state.datacfdi}
                                                                columns={this.columns}
                                                            ///extraButtons={this.extraButtons}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                    }
                                </>
                            }
                        </>
                    ) : ((this.state.cargandospanol) ? (
                        <CircularProgress style={{ color: 'white', position: 'absolute', top: '50%', left: '49%' }}></CircularProgress>
                    ) :
                        <div className="alert bg-info alert-styled-left alert-dismissible">
                            <span className="font-weight-semibold">No existe información para mostrar!</span>.
                        </div>
                    )
                }
                <div id="viewpdfcfdi" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                    <div className="modal-dialog modal-full">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{this.state.uuid}</h5>
                                <button type="button" className="close" data-dismiss="modal" onClick={() => this.setState({ link: '' })}>×</button>
                            </div>
                            <div className="modal-body">
                                <div className="embed-container">
                                    {
                                        (this.state.loadingpdf) ?
                                            (
                                                <div>
                                                    <Skeleton variant="text" />
                                                    <Skeleton variant="circle" animation="wave" width={40} height={40} />
                                                    <Skeleton variant="rect" animation="wave" width={510} height={118} />
                                                </div>
                                            ) : null
                                    }
                                    <iframe src={this.state.link} onLoad={this.handleLoading}
                                        width="100%"
                                        frameBorder="0" allowFullScreen
                                    >
                                    </iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="viewxmlcfdi" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                    <div className="modal-dialog modal-full">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{this.state.uuid}</h5>
                                <button type="button" className="close" data-dismiss="modal" onClick={() => this.setState({ link: '' })}>×</button>
                            </div>
                            <div className="modal-body">
                                <div>
                                    {
                                        (this.state.loading) ?
                                            (
                                                <div>
                                                    <Skeleton variant="text" />
                                                    <Skeleton variant="circle" animation="wave" width={40} height={40} />
                                                    <Skeleton variant="rect" animation="wave" width={510} height={118} />
                                                </div>
                                            ) : null
                                    }
                                    {this.state.cfdi}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modallargepdf" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                    <div className="modal-dialog modal-full">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Reporte de sueldos y salarios</h5>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>
                            <div className="modal-body">
                                <div className="embed-container">
                                    {
                                        (this.state.loadingfirts) ?
                                            (
                                                <div>
                                                    <Skeleton variant="text" />
                                                    <Skeleton variant="circle" animation="wave" width={40} height={40} />
                                                    <Skeleton variant="rect" animation="wave" width={510} height={118} />
                                                </div>
                                            ) : null
                                    }
                                    <iframe src={this.state.linkpdf} onLoad={this.handleLoading}
                                        width="100%" frameBorder="0" allowFullScreen
                                    >
                                    </iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modalpegasusproject" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                    <div className="modal-dialog modal-full">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{this.state.subtitlemodal}</h5>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>
                            <div className="modal-body">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>ISR A Cargo</th>
                                            <th>Subsidio</th>
                                            <th>Número de operación</th>
                                            <th>Tipo declaración</th>
                                            <th>Fecha de presentación</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (this.state.filterdeclaracion || []).map((g, k) => (
                                                <tr key={k}>
                                                    <td>{(g.isr_retenciones_por_asimilados_a_salarios + g.isr_retenciones_por_salarios).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                    <td>{g.subsidio.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                    <td>{g.numero_de_operacion}</td>
                                                    <td>{g.tipo_declaracion}</td>
                                                    <td>{g.fecha_de_presentacion}</td>
                                                    <td><button type="button" className="btn btn-light" onClick={() => this.handleViewPDFDeclaracion(g)}><i className="icon-file-pdf text-success"></i></button></td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default SueldosSalarios;

class DetailsViews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataexercise: [],
            total_isr_factura: 0,
            total_isr_declaracion: 0,
            total_diferencia: 0,
            filterdeclaracion: [],

        }
    }
    componentDidMount() {
        if (this.props.factura.length > 0) {
            let total_isr_factura = 0;
            let total_isr_declaracion = 0;
            let total_diferencia = 0;
            let retenciones_iva_cfdis_ = (this.props.factura).filter((f) => f.ejercicio == this.props.filter);
            let retenciones_iva_declarados_ = (this.props.declaracion).filter((f) => f.ejercicio == this.props.filter);
            ///console.log(retenciones_iva_declarados_)
            let _perids_ = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
            let _new_array_imports_ = []
            for (let ip = 0; ip < _perids_.length; ip++) {
                let _resultado_filter_facturas_ = (retenciones_iva_cfdis_).filter((u) => u.period === _perids_[ip]);
                /// console.log(_resultado_filter_facturas_)
                let _resultado_filter_declarados_ = (retenciones_iva_declarados_).filter((u) => u.period === _perids_[ip] && u.isr_retenciones_por_asimilados_a_salarios > 0);
                ///console.log(_resultado_filter_declarados_)
                if (_resultado_filter_facturas_.length > 0 && _resultado_filter_declarados_.length > 0) {
                    _new_array_imports_.push(_resultado_filter_facturas_[0])
                }
                else if (_resultado_filter_facturas_.length === 0 && _resultado_filter_declarados_.length > 0) {
                    _new_array_imports_.push({
                        comprobantes: 0,
                        deducciones_total_impuestos_retenidos: 0,
                        diferencias: 0,
                        ejercicio: this.props.filter,
                        isr_retenciones_por_asimilados_a_salarios: 0,
                        period: _perids_[ip],
                        subsidio: 0,
                        totalsubsidio: 0
                    })
                }
                else if (_resultado_filter_facturas_.length > 0 && _resultado_filter_declarados_.length === 0) {
                    _new_array_imports_.push(_resultado_filter_facturas_[0])
                }
            }
            setTimeout(() => {
                //console.log(retenciones_iva_declarados_)
                for (let cf = 0; cf < _new_array_imports_.length; cf++) {
                    ///console.log(_new_array_imports_[cf].subsidio)
                    let import_retenciones_iva = 0;
                    let filter_retenciones_por_mes = DistinctRecords((retenciones_iva_declarados_).filter((t) => t.period == _new_array_imports_[cf].period && (t.isr_retenciones_por_asimilados_a_salarios + t.isr_retenciones_por_salarios) > 0), "numero_de_operacion");
                    let data_subsidio_ = DistinctRecords((retenciones_iva_declarados_).filter((t) => t.period == _new_array_imports_[cf].period && (t.totalsubsidio) > 0), "numero_de_operacion");
                    if (data_subsidio_.length > 0) {
                        _new_array_imports_[cf].totalsubsidio = data_subsidio_[0].totalsubsidio;
                    } else
                        _new_array_imports_[cf].totalsubsidio = 0;
                    ///console.log(filter_retenciones_por_mes)
                    if (filter_retenciones_por_mes.length > 1) {
                        filter_retenciones_por_mes = (filter_retenciones_por_mes).filter((e) => e.tipo_declaracion.includes("Complementaria"))
                        ///console.log(filter_retenciones_por_mes)
                        import_retenciones_iva = import_retenciones_iva + filter_retenciones_por_mes[0].isr_retenciones_por_asimilados_a_salarios + filter_retenciones_por_mes[0].isr_retenciones_por_salarios;
                        // _new_array_imports_[cf].totalsubsidio = filter_retenciones_por_mes[0].totalsubsidio;
                    }
                    else if (filter_retenciones_por_mes.length == 1) {

                        import_retenciones_iva = import_retenciones_iva + filter_retenciones_por_mes[0].isr_retenciones_por_asimilados_a_salarios + filter_retenciones_por_mes[0].isr_retenciones_por_salarios;
                    }
                    _new_array_imports_[cf].isr_retenciones_por_asimilados_a_salarios = import_retenciones_iva;
                    let importediferencia = (parseFloat(_new_array_imports_[cf].deducciones_total_impuestos_retenidos) - parseFloat(import_retenciones_iva));
                    _new_array_imports_[cf].diferencias = importediferencia.toFixed(2);
                    total_isr_declaracion = total_isr_declaracion + import_retenciones_iva;
                    total_diferencia = total_diferencia + importediferencia;
                    total_isr_factura = total_isr_factura + parseFloat(_new_array_imports_[cf].deducciones_total_impuestos_retenidos);
                }
            }, 1000);

            setTimeout(() => {
                this.setState({
                    dataexercise: _new_array_imports_,
                    total_isr_factura: total_isr_factura,
                    total_isr_declaracion: total_isr_declaracion,
                    total_diferencia: total_diferencia
                })
            }, 1000);
            /// console.log(_new_array_imports_)
        }
    }
    getDetailsView = async (k) => {
        this.props.View(k);
    }
    getViewPdfFilter = (y) => {
        this.props.getViewPdfFilter(y);
    }
    render() {
        return (
            <>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Ejercicio</th>
                            <th>Periodo</th>
                            <th>Comprobantes</th>
                            <th>ISR</th>
                            <th>Subsidio entregado</th>
                            <th>ISR Cargo</th>
                            <th>Subsidio aplicado</th>
                            <th>Diferencia</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (this.state.dataexercise || []).map((ex, hy) => (
                                <tr key={hy}>
                                    <td style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => this.getDetailsView(ex)}><Tooltip title="Clic para ver las facturas " placement="top" arrow><span className="text-primary">{ex.ejercicio}</span></Tooltip> </td>
                                    <td style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => this.getDetailsView(ex)}><Tooltip title="Clic para ver las facturas " placement="top" arrow><span className="text-primary">{ex.period}</span></Tooltip> </td>
                                    <td style={{ textAlign: 'center' }}>{(ex.comprobantes === 0 ? '' : ex.comprobantes)}</td>
                                    <td style={{ textAlign: 'right' }}>{parseFloat(ex.deducciones_total_impuestos_retenidos).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                    <td style={{ textAlign: 'right' }}>{parseFloat(ex.subsidio).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                    <td style={{ textAlign: 'right', cursor: 'pointer' }} onClick={() => this.getViewPdfFilter(ex)}><Tooltip title="Clic para ver las declaraciones " placement="top" arrow><span className="text-primary">{parseFloat(ex.isr_retenciones_por_asimilados_a_salarios).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span></Tooltip></td>
                                    <td style={{ textAlign: 'right' }}>{parseFloat(ex.totalsubsidio).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                    <td style={{ textAlign: 'right' }}>{(ex.diferencias === '0.00' ? '' :
                                        (ex.diferencias < 0 ? (<span className="text-success">{ex.diferencias.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>) :
                                            (<span className="text-danger">{ex.diferencias.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>)
                                        ))
                                    }</td>
                                </tr>
                            ))
                        }
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ textAlign: 'right' }}>{this.state.total_isr_factura.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                            <td></td>
                            <td style={{ textAlign: 'right' }}>{this.state.total_isr_declaracion.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                            <td></td>
                            <td style={{ textAlign: 'right' }}>{this.state.total_diferencia === 0 ? '' : (<span className="badge badge-danger d-block">{this.state.total_diferencia.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>)}</td>
                        </tr>
                    </tfoot>
                </table>

            </>
        );
    }
}
function DistinctRecords(MYJSON, prop) {
    return MYJSON.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    })
}
