
import React, { useState } from 'react';
import DatePicker from 'react-date-picker';
function padLeft(n) {
    return ("00" + n).slice(-2);
}
function formatDate(t) {
    var d = new Date(t),
        dformat = [
            padLeft(d.getMonth() + 1),
            d.getFullYear()
        ].join('-');
    return dformat
}
export default function MyApp(props) {
    const [value, onChange] = useState(new Date());
    const date_ = (t) => {
        onChange(t);
        props.setDate(formatDate(t))
    }
    return (
        <div>
            <DatePicker className="form-control"
                maxDetail={"year"}
                format="yy-MM"
                onChange={date_}
                value={value}
                calendarIcon={null}
                clearIcon={null}
                calendarAriaLabel="Toggle calendar"
            ></DatePicker>
        </div>
    );
}