import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Checkbox from '@material-ui/core/Checkbox';
const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'none',
        },
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),

    },
    tableRightBorder: {
        backgroundColor: "#262d3c"
    },
});

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
    const handleChange = (event) => {
        setChecked(event.target.checked);
        props.filter(event.target.value);
    };
    const [checked, setChecked] = React.useState(true);
    return (
        <React.Fragment>
            <tr className={classes.root}
                hover
                tabIndex={-1}>
                <td>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </td>
                <td>
                    <Checkbox
                        checked={checked}
                        onChange={handleChange}
                        value={row.C}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </td>
                <td style={{ color: 'white' }} component="th" scope="row">{row.F + ' ' + row.G + ' ' + row.H}</td>
                <td style={{ color: 'white' }} align="center">{row.D}</td>
                <td style={{ color: 'white' }} align="center">{row.E}</td>
                <td style={{ color: 'white' }} align="center">{row.I}</td>
            </tr>
            <tr>
                <td style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <span>Información complementaria</span>
                            <table size="small" aria-label="purchases" className="table">
                                <tbody>
                                    <tr>
                                        <td style={{ color: '#43a76f' }}>NSS</td>
                                        <td style={{ color: '#43a76f' }}>Cuota diaria</td>
                                        <td style={{ color: '#43a76f' }} align="left">Puesto</td>
                                        <td colSpan={2} style={{ color: '#43a76f' }} align="left">Actividad</td>
                                    </tr>
                                    <tr>
                                        <td style={{ color: '#c76932' }}>{row.O}</td>
                                        <td style={{ color: '#c76932' }}>{row.S}</td>
                                        <td style={{ color: '#c76932' }}>{row.Z}</td>
                                        <td colSpan={2} style={{ color: '#c76932' }}>{row.BD}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ color: '#43a76f' }} align="left">Domicilio</td>
                                        <td style={{ color: '#43a76f' }} align="center">Correo electrónico</td>
                                        <td style={{ color: '#43a76f' }} align="left">Beneficiario</td>
                                        <td style={{ color: '#43a76f' }} align="left">Genero</td>
                                        <td style={{ color: '#43a76f' }} align="left">Escolaridad</td>
                                    </tr>
                                    <tr>
                                        <td style={{ color: '#c76932' }}>{row.AB + '' + row.AC + '' + row.AD + ', ' + row.AF}</td>
                                        <td style={{ color: '#c76932' }}>{row.AS}</td>
                                        <td style={{ color: '#c76932' }}>{row.AT}</td>
                                        <td style={{ color: '#c76932' }}>{row.AZ}</td>
                                        <td style={{ color: '#c76932' }}>{row.AA}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Box>
                    </Collapse>
                </td>
            </tr>
        </React.Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        D: PropTypes.string.isRequired,
        E: PropTypes.string.isRequired,
        F: PropTypes.string.isRequired,
        C: PropTypes.string.isRequired,
    }).isRequired,
};

export default function CollapsibleTable(props) {
    const classes = useRowStyles();
    let rows = (props.dt);
    const handleCheckBox = (j) => {
        props.filterdt(j)
    }
    return (
        <TableContainer component={Paper} className={classes.tableRightBorder}>
            <table aria-label="collapsible table" className="table">
                <thead>
                    <tr>
                        <th />
                        <th />
                        <th style={{ color: 'white' }}>Nombre completo</th>
                        <th style={{ color: 'white' }} align="center">RFC</th>
                        <th style={{ color: 'white' }} align="center">Curp</th>
                        <th style={{ color: 'white' }} align="center">Estado civil</th>
                    </tr>
                </thead>
                <tbody>
                    {(rows || []).map((row) => (
                        <Row key={row.D} row={row} filter={handleCheckBox} />
                    ))}
                </tbody>
            </table>
        </TableContainer>
    );
}
