import React, { Component } from 'react';
import api from '../../../../app/Config/api';
import { Tooltip, } from "@material-ui/core";
import ReactDatatable from '@ashvin27/react-datatable';
import Skeleton from '@material-ui/lab/Skeleton';
class CuentasControl extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "account_number",
                text: "Cuenta contable",
                className: "account_number",
                align: "left",
                sortable: true,
            },
            {
                key: "description",
                text: "Descripción",
                className: "description",
                align: "left",
                sortable: true
            },
            {
                key: "control_account",
                text: "Cuenta de control",
                className: "control_account",

            },
            {
                key: "grouping_code",
                text: "Código agrupador",
                className: "grouping_code",
                align: "center",
                cell: record => {
                    return (
                        <div className="text-center">
                            <a className="text-danger" style={{ fontSize: 12 }}>{(record.grouping_code)}</a>
                            &nbsp;&nbsp;-&nbsp;&nbsp;
                            <a className="text-success" style={{ fontSize: 12 }}>{(record.grouping_code).substr(0, 3)}</a>
                        </div>
                    );
                }
            },
            {
                key: "number_level",
                text: "Nivel",
                className: "number_level",
                align: "center",
            }
            ,
            {
                key: "nature",
                text: "Naturaleza",
                className: "nature",
                align: "center",
            }
        ];
        this.config = {
            key_column: 'account_number',
            page_size: 1000,
            length_menu: [1000, 2000, 5000],
            button: {
                excel: true,
                print: true,
                extra: false,
            },
            "language": {
                "length_menu": "Mostrar _MENU_ registros por página",
                "filter": "Buscar en registros ...",
                "info": "Mostrando _START_ a _END_ de _TOTAL_ entradas",
                "pagination": {
                    "first": "Primero",
                    "previous": "Previa",
                    "next": "próximo",
                    "last": "Última"
                }
            }
        }

        this.state = {
            recorset: [],
            skeleton: true,
        }
    }
    componentDidMount() {
        this.getdata();
    }
    getdata = async () => {
        let pareme = JSON.parse(localStorage.getItem("parameterscompany"));
        let result_ = await api.post("getcuentas-control-ca-registro", pareme);
        let { data } = await result_;
        this.setState({ recorset: data, skeleton: false });
    }
    render() {
        return (
            <>
                {
                    this.state.skeleton ? (
                        <div className="card">
                            <Skeleton />
                            <Skeleton animation={false} height={400} />
                            <Skeleton animation="wave" />
                        </div>
                    ) : (
                        <ReactDatatable
                            config={this.config}
                            records={(this.state.recorset || [])}
                            columns={this.columns}
                        />
                    )
                }
            </>
        );
    }
}

export default CuentasControl;