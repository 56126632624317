import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import * as Icons from '@material-ui/icons';
import { Typography, Button, Dialog, Grid, Paper } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import api from '../../../app/Config/api';
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6" style={{ textAlign: 'center' }}>{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <Icons.Close />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
class NewStructure extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
            file: '',
            namefile: 'Seleccione la constancia de situación fiscal',
            message: '',
            success: false,
            vertical: 'top',
            horizontal: 'right',
            error: false,
            blockscreen: false
        }
    }
    handleClickOpen = () => {
        this.setState({ open: true });
    };
    handleClose = () => {
        var ejercicio = new Date();

        if (this.state.file !== '') {
            const user = JSON.parse(localStorage.getItem("id_token"));
            this.setState({ blockscreen: true })
            const formData = new FormData();
            formData.append('file', this.state.file)
            formData.append('ejercicio', ejercicio.getFullYear())
            formData.append('cliente_id', user.cliente_id)
            api.post('/constaciasituacionfiscalvalidador', formData).then(res => {
                var containers = res.data;
                if (containers.status === 200) {
                    this.setState({
                        open: false,
                        file: '',
                        namefile: 'Seleccione la Constancia de Situación Fiscal',
                        success: true,
                        vertical: 'top',
                        horizontal: 'right',
                        error: false,
                        message: containers.message,
                        blockscreen: false
                    });
                    //el.current.value = "";
                    var contenido_ = this.props.informacion;
                    contenido_.informacionObtenida(false);
                    //resultData.informacion('Hola mundo....');
                }
                else if (containers.status === 404) {
                    this.setState({
                        open: false,
                        file: '',
                        namefile: 'Seleccione la Constancia de Situación Fiscal',
                        success: false,
                        vertical: 'top',
                        horizontal: 'right',
                        error: true,
                        message: containers.message,
                        blockscreen: false
                    });
                }
            }).catch(err => console.log(err));
        }
        else
            this.setState({ success: false, vertical: 'top', horizontal: 'right', error: true, message: 'Para poder continuar debe de seleccionar el documento!!!' });
        //this.setState({ error: true, message: 'ok' });
        //
    };
    handleCloseModal = () => {
        this.setState({ open: false });
    }
    handleChangeFile = (i) => {

        const file = i.target.files[0];
        this.setState({ file: file, namefile: file.name, })
    }
    handleCloseMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({
            success: false,
            vertical: 'top',
            horizontal: 'right',
            error: false,
            message: ''
        });
    };
    render() {
        const { vertical, horizontal } = this.state;
        return (
            <>
                <Button variant="outlined" color="primary" onClick={this.handleClickOpen} style={{ display: 'none' }}>Open dialog</Button>

                <Snackbar open={this.state.success} autoHideDuration={6000} onClose={this.handleCloseMessage} anchorOrigin={{ vertical, horizontal }}>
                    <Alert onClose={this.handleCloseMessage} severity="success">
                        {this.state.message}
                    </Alert>
                </Snackbar>
                <Snackbar open={this.state.error} autoHideDuration={6000} onClose={this.handleCloseMessage} anchorOrigin={{ vertical, horizontal }}>
                    <Alert onClose={this.handleCloseMessage} severity="error">
                        {this.state.message}
                    </Alert>
                </Snackbar>
                <Dialog onClose={this.handleCloseModal} aria-labelledby="customized-dialog-title" open={this.state.open} fullWidth={true} maxWidth='sm'>
                    <DialogTitle id="customized-dialog-title" onClose={this.handleCloseModal}>Crear nueva estructura</DialogTitle>
                    <DialogContent dividers>
                        <Backdrop open={this.state.blockscreen} style={{ color: "#fff", background: "#B9B6B6", zIndex: 800, opacity: "0.5" }}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        <Grid container>


                            <Grid item xl={12}>
                                <Paper>
                                    <label htmlFor="upload-button">
                                        <Button variant="contained" component="span" color="primary">
                                            <Icons.PictureAsPdfOutlined />
                                            <span> {this.state.namefile} </span>
                                        </Button>
                                    </label>
                                    <input type="file" id="upload-button" style={{ display: "none" }} onChange={this.handleChangeFile} accept=".pdf" />
                                </Paper>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus variant='contained' onClick={this.handleClose} color="primary">Enviar</Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

export default NewStructure;