import React, { Component } from 'react';
import { FormControl, Select, InputLabel, MenuItem } from '@material-ui/core';
/*******
 * CONFIG CONEXION
 */
import apiconnect from '../Config/apifiles';
/*******
 * LIBRARY UPLOAD FILES
 */
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
function years(ejercicioinit) {
    var contenidoejercicio = [];
    var ejercicio = (new Date()).getFullYear();
    for (let index = ejercicioinit; index <= ejercicio; index++) {
        contenidoejercicio.push({ ejercicio: index })
    }
    return contenidoejercicio;
}
class TimeLineContabilidad extends Component {
    constructor(props) {
        super(props);
        var data = JSON.parse(localStorage.getItem("_data_info_empresa_"));
        this.state = {
            ejercicioinit: ((data.ejercicio === undefined || data.ejercicio === null) ? (new Date().getFullYear()) : data.ejercicio),
            mensaje: 'hola',
            anio: ((data.ejercicio === undefined || data.ejercicio === null) ? (new Date().getFullYear()) : data.ejercicio), //(new Date()).getFullYear(),
            ejercicios: years(((data.ejercicio === undefined || data.ejercicio === null) ? (new Date().getFullYear()) : data.ejercicio)),
            datalist: [],
            empresa_id: data.empresa_id,
            openscreen: true,
            notificacion: false,
            openca: false,
            compensacion: [],
            id: data.id
        }
    }
    getdatainforhead = async (e) => {
        this.setState({ openscreen: true, datalist: [] });
        let dat_ = await apiconnect.post('list-report-acuses-read', {
            empresa_id: this.state.id,
            ejercicio: e.target.value
        });
        this.setState({ datalist: dat_.data, openscreen: false });
    }
    componentDidMount() {
        this.getData();
    }
    getData = async () => {
        let dat_ = await apiconnect.post('list-report-acuses-read', {
            empresa_id: this.state.id,
            ejercicio: this.state.anio
        });
        this.setState({ datalist: dat_.data, openscreen: false });

        /*
        var objectinfo = {
            empresa_id: this.state.id,
            ejercicio: this.state.anio
        }
        apiconnect.post('list-report-acuses-read', objectinfo)
            .then(response => response.data)
            .then(data => {
                if (data.length > 0)
                    this.setState({ datalist: data, openscreen: false });
                else {
                    this.setState({ datalist: [], openscreen: false, notificacion: true });
                    setTimeout(() => {
                        this.setState({ notificacion: false });
                    }, 4000);
                }
            });*/
    }
    setOpen = (s) => {
        this.setState({ openca: true, compensacion: s.compensacion });
    }
    setOpenBack = () => {
        this.setState({ openca: false });
    }
    render() {
        return (
            <>
                <Backdrop open={this.state.openscreen} style={{ color: "#fff", background: "#B9B6B6", zIndex: 400, opacity: "0.5" }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div className="content-wrapper">
                    <div className="content pt-0">
                        {
                            (!this.state.openca) ? (
                                <div className="card">
                                    <div className="card-header bg-transparent border-bottom header-elements-sm-inline py-sm-0">
                                        <h6 className="card-title py-sm-3">Seleccione el ejercicio a consultar</h6>
                                        <div className="header-elements">
                                            <form action="#">
                                                <div className="wmin-sm-200">
                                                    <select onChange={this.getdatainforhead} className="custom-select">
                                                        {
                                                            (this.state.ejercicios || []).map((s, x) => (
                                                                <option value={s.ejercicio} key={x}>{s.ejercicio}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                    <div className="card-body">
                                        {
                                            this.state.notificacion ? (<div className="alert alert-warning alert-styled-right alert-dismissible">
                                                <span className="font-weight-semibold">Notificación!</span> No existe información para mostrar.
                                            </div>) : null
                                        }
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Ejercicio</th>
                                                    <th>Periodo</th>
                                                    <th>Número de operación</th>
                                                    <th>Fecha de presentación</th>
                                                    <th>Tipo de declaración</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(this.state.datalist || []).map((s, d) => (
                                                    <Row key={d} row={s} display={this.setOpen} />
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div className="card">
                                        <div className="card-header bg-transparent border-bottom">
                                            <h6 className="card-title" onClick={this.setOpenBack} style={{ cursor: 'pointer' }}>
                                                <i className="icon-arrow-left52 mr-2" />
                                                Volver
                                            </h6>
                                        </div>
                                        <div className="card-body">
                                            <table className="table">
                                                <tbody>
                                                    {
                                                        (this.state.compensacion || []).map((x, c) => (
                                                            <React.Fragment key={c}>
                                                                <tr>
                                                                    <th>Tipo</th>
                                                                    <th><span className="text-primary">{x.tipo}</span></th>
                                                                    <th>Periociodad</th>
                                                                    <th><span className="text-primary">{x.periocidad}</span></th>
                                                                </tr>
                                                                <tr>
                                                                    <th>Periodo</th>
                                                                    <th><span className="text-primary">{x.periodo}</span></th>
                                                                    <th>Ejercicio</th>
                                                                    <th><span className="text-primary">{x.ejercicio}</span></th>
                                                                </tr>
                                                                <tr>
                                                                    <th>Concepto</th>
                                                                    <th><span className="text-primary">{x.concepto}</span></th>
                                                                    <th>Saldo a aplicar</th>
                                                                    <th><span className="text-primary">{x.saldoaplicar.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span></th>
                                                                </tr>
                                                                <tr>
                                                                    <th>Tipo de declaración</th>
                                                                    <th><span className="text-primary">{x.tipodeclaracion}</span></th>
                                                                    <th>Número de operación</th>
                                                                    <th><span className="text-primary">{x.numerooperacion}</span></th>
                                                                </tr>
                                                                <tr>
                                                                    <th>Monto del saldo a favor original</th>
                                                                    <th><span className="text-primary">{x.montooriginal.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span></th>
                                                                    <th>Remanente histórico antes de la aplicación</th>
                                                                    <th><span className="text-primary">{x.remanentehistoricoantesdelaaplicacion.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span></th>
                                                                </tr>
                                                                <tr>
                                                                    <th>Fecha en la que se presentó la declaración</th>
                                                                    <th><span className="text-primary">{x.fechaenlaquesepresento}</span></th>
                                                                    <th>Remanente actualizado antes de la aplicación</th>
                                                                    <th><span className="text-primary">{x.remanenteactualizadoantesdelaaplicacion.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span></th>
                                                                </tr>
                                                                <tr><td style={{ height: 20 }}></td></tr>
                                                            </React.Fragment>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
            </>
        )
    }
}

export default TimeLineContabilidad;
const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'none',
        }
    },
    tableRightBorder: {
        backgroundColor: "#262d3c"
    },
});
function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
    const handleChange = (p) => {
        let links = 'https://e-xpertum.com.mx/adminfile/api/doc-view-contributions/rdoc-frd/' + p + '/downloadfile';
        window.open(links, "_blank");
    };
    const displayDetails = (r) => {
        props.display(r);
    }
    return (
        <React.Fragment>
            <tr className={classes.root} tabIndex={-1}>
                <th>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </th>
                <th style={{ color: 'white' }} component="th" scope="row">{row.ejercicio}</th>
                <th style={{ color: 'white' }} component="th" scope="row">{row.periodo}</th>
                <th style={{ color: 'white' }} align="center">{row.numero_de_operacion}</th>
                <th style={{ color: 'white' }} align="center">{row.fechapresentacion}</th>
                <th style={{ color: 'white' }} align="center">{row.tipodeclaracion}</th>
                <th><button type="button" className="btn btn-link" onClick={() => handleChange(row.document)}><i className="icon-file-pdf mr-2 text-success"></i> PDF</button></th>
            </tr>
            <tr>
                <td style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <span style={{ fontSize: 14 }} className="text-success">Información de las obligaciones</span>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Obligación</th>
                                        <th className="text-center">A cargo</th>
                                        <th className="text-center">Act.</th>
                                        <th className="text-center">Recargos</th>
                                        <th className="text-center">Subsidio</th>
                                        <th className="text-center">A favor</th>
                                        <th className="text-center">Total a pagar</th>
                                        <th className="text-center">Comp. / Acred.</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (row.details || []).map((d, v) => (
                                            <tr key={v}>
                                                <td>{d.obl}</td>
                                                <td className="text-right">{(d.acargo === 0 ? '-' : (d.acargo.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')))}</td>
                                                <td className="text-right">{(d.actualizaciones_ === 0 ? '-' : (d.actualizaciones_.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')))}</td>
                                                <td className="text-right">{(d.recargos === 0 ? '-' : (d.recargos.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')))}</td>
                                                <td className="text-right">{(d.subsidio === 0 ? '-' : (d.subsidio.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')))}</td>
                                                <td className="text-right">{(d.afavor === 0 ? '-' : (d.afavor.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')))}</td>
                                                <td className="text-right">{(d.total_contribuciones === 0 ? '-' : (d.total_contribuciones.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')))}</td>
                                                <td className="text-center">{(d.compensacion.length > 0) ? <Tooltip title={"Clic para ver la Compensación o Acreditamiento"} placement="top" arrow>
                                                    <button type="button" onClick={() => displayDetails(d)} className="btn btn-link">Ver</button>
                                                </Tooltip> : null}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </Box>
                    </Collapse>
                </td>
            </tr>
        </React.Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        tipodeclaracion: PropTypes.string.isRequired,
        periodo: PropTypes.string.isRequired,
        ejercicio: PropTypes.string.isRequired,
        numero_de_operacion: PropTypes.string.isRequired,
        fechapresentacion: PropTypes.string.isRequired,
        document: PropTypes.string.isRequired,
        details: PropTypes.array,
    }).isRequired,
};