import React, { Component } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import * as Icon from '@material-ui/icons';
import 'react-vertical-timeline-component/style.min.css';
//import "./styles.scss"
import { makeStyles } from '@material-ui/core/styles';
/*******
 * CONFIG CONEXION
 */
import api from '../Config/api';
/*******
 * LIBRARY UPLOAD FILES
 */
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import ViewLeft from './util-multa/viewleft';
//import ViewRigth from './util-multa/viewright';

class TimeLineEstatales extends Component {
    constructor(props) {
        super(props);
        var data = JSON.parse(localStorage.getItem("_data_info_empresa_"));
        this.state = {
            datalist: [],
            openscreen: true,
            empresa_id: data.empresa_id, //
            //empresa_id: 132650,
            mensaje: ''
        }

    }
    componentDidMount() {
        this.getData();
    }
    getData() {
        var objectinfo = {
            empresa_id: this.state.empresa_id
        }
        api.post('getlistobligacionmultas', objectinfo)
            .then(response => response.data)
            .then(data => {
                console.log(data)
                if (data.estatus === 204) {
                    this.setState({
                        mensaje: data.info,
                        openscreen: false
                    });
                    setTimeout(() => {
                        this.setState({ mensaje: '' });
                    }, 4000);
                }
                else if (data.estatus === 200) {
                    this.setState({
                        datalist: data.info,
                        openscreen: false
                    });
                }
            });
    }
    render() {

        const { datalist } = this.state;
        return (
            <>
                <Backdrop open={this.state.openscreen} style={{ color: "#fff", background: "#B9B6B6", zIndex: 400, opacity: "0.5" }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                {
                    this.state.mensaje !== '' ? (
                        <div className="alert alert-warning alert-styled-right alert-dismissible">
                            <span className="font-weight-semibold">Notificación!</span> {this.state.mensaje}
                        </div>

                    ) : null
                }
                <div className="content-wrapper">
                    <div className="content pt-0">
                        <div className="timeline timeline-center">
                            <div className="timeline-container">
                                {
                                    datalist.map((i, o) => (
                                        <div key={o}>
                                            {
                                                <ViewLeft titulo={'Fecha de notificación: ' + i.dFechaNotificacion.substr(0, 10)} subtitulo={i.Obligacion} fechalimite={i.dFechaLimite} details={i} />
                                            }
                                        </div>

                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
        /* return (
             <>
                 <div className="container">
                     
                     <VerticalTimeline>
                         {
                             datalist.map((i, o) => (
                                 <VerticalTimelineElement key={o}
                                     className="vertical-timeline-element"
                                     date={'Fecha de notificación: ' + i.dFechaNotificacion}
                                     iconStyle={{ background: 'rgb(255, 92, 147)', color: '#fff' }}
                                     icon={<Icon.Close />}>
                                     <h3 className="vertical-timeline-element-title">Importe - {i.Saldo}</h3>
                                     <h3 className="subtitle-timeline">{i.Obligacion}</h3>
                                     <Tablaview value={i} />
                                 </VerticalTimelineElement>
                             ))
                         }
 
                     </VerticalTimeline>
                 </div>
             </>
         );*/
    }

}

export default TimeLineEstatales;