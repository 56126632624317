import React, { Component } from 'react';
import api from '../../Config/apifiles';
class PanelAdminsUsers extends Component {
    constructor(props) {
        super(props);
        var objec = JSON.parse(localStorage.getItem('id_token'));
        this.state = {
            viewnomina: false,
            viewauditoria: false,
            viewjuridico: false,
            perfil: objec.perfil,
            nombre: objec.nombre + " " + objec.apellido_materno + " " + objec.apellido_paterno,
            usuario: objec.usuario,
            correo: objec.email,
            usuario_id: objec.id,
            counts: 0,
            countsauditor: 0,
            countsjuridico: 0,
        }
    }
    componentDidMount() {
        this.getdata();
    }
    getdata = async () => {
        let _data_ = await api.post('select-count-companys', { usuario_id: this.state.usuario_id });
        if (this.state.perfil === "ADMINISTRADORNOMINA")
            this.setState({ viewnomina: true, counts: (_data_.data).length });
        if (this.state.perfil === "ADMINISTRADORAUDITOR")
            this.setState({ viewauditoria: true, countsauditor: (_data_.data).length });
        if (this.state.perfil === "ADMINISTRADORJURIDICO")
            this.setState({ viewjuridico: true, countsjuridico: (_data_.data).length });
    }
    render() {
        return (
            <div className="content-inner">
                <div className="page-header border-bottom-0">
                    <div className="page-header-content header-elements-lg-inline">
                        <div className="page-title d-flex">
                            <a>  <h4><i className="icon-display4 mr-2" /> <span className="font-weight-semibold">Panel </span> - Administrativo</h4></a>
                            <a href="#" className="header-elements-toggle text-body d-lg-none"><i className="icon-more" /></a>
                        </div>
                        <div className="header-elements d-none mb-3 mb-lg-0">
                            <div className="d-flex justify-content-center">
                                <a href="#" className="btn btn-link btn-float text-body" style={{ display: 'none' }}><i className="icon-bars-alt" /><span>Statistics</span></a>
                                <a href="#" className="btn btn-link btn-float text-body" style={{ display: 'none' }}><i className="icon-calculator" /> <span>Invoices</span></a>
                                <a href="#" className="btn btn-link btn-float text-body" style={{ display: 'none' }}><i className="icon-calendar5" /> <span>Schedule</span></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content pt-0">
                    <div className="mb-3 pt-2">
                        <h6 className="mb-0 font-weight-semibold">
                            Administrador
                        </h6>
                        <span className="text-muted d-block">{this.state.nombre}</span>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-xl-4">
                            <div className="card card-body text-center bg-primary">
                                <div className="svg-center position-relative" id="progress_icon_one">
                                    <svg width={84} height={84}>
                                        <g transform="translate(42,42)">
                                            <path className="d3-progress-background" d="M0,42A42,42 0 1,1 0,-42A42,42 0 1,1 0,42M0,39.5A39.5,39.5 0 1,0 0,-39.5A39.5,39.5 0 1,0 0,39.5Z" style={{ fill: 'rgb(255, 255, 255)', opacity: '0.1' }} />
                                            <path className="d3-progress-foreground" filter="url(#blur)" d="M6.661338147750939e-16,-40.7308237088326A1.25,1.25 0 0,1 1.2883435582822094,-41.98023547904219A42,42 0 1,1 -41.48773651930016,6.539703242894765A1.25,1.25 0 0,1 -40.40964899818112,5.104925841165514L-40.40964899818112,5.104925841165514A1.25,1.25 0 0,1 -39.01822839315133,6.150435192722457A39.5,39.5 0 1,0 1.2116564417177922,-39.48141193862301A1.25,1.25 0 0,1 6.661338147750939e-16,-40.7308237088326Z" style={{ fill: 'rgb(255, 255, 255)', stroke: 'rgb(255, 255, 255)' }} />
                                            <path className="d3-progress-front" d="M6.661338147750939e-16,-40.7308237088326A1.25,1.25 0 0,1 1.2883435582822094,-41.98023547904219A42,42 0 1,1 -41.48773651930016,6.539703242894765A1.25,1.25 0 0,1 -40.40964899818112,5.104925841165514L-40.40964899818112,5.104925841165514A1.25,1.25 0 0,1 -39.01822839315133,6.150435192722457A39.5,39.5 0 1,0 1.2116564417177922,-39.48141193862301A1.25,1.25 0 0,1 6.661338147750939e-16,-40.7308237088326Z" style={{ fill: 'rgb(255, 255, 255)', fillOpacity: 1 }} />
                                        </g>
                                    </svg>
                                    <i className="icon-collaboration counter-icon" style={{ color: 'rgb(255, 255, 255)', top: '26px' }} />
                                </div>
                                <h2 className="pt-1 mt-2 mb-1">{this.state.counts}</h2>
                                Nómina
                                <div className="font-size-sm text-muted">
                                    <a href="#/app/panel/nomina-panel" className="btn btn-light" style={{ cursor: 'pointer' }}>Ingresar <i class="icon-arrow-right7 mr-2"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xl-4">
                            <div className="card card-body text-center bg-teal has-bg-image">
                                <div className="svg-center position-relative" id="progress_icon_three">
                                    <svg width={84} height={84}>
                                        <g transform="translate(42,42)">
                                            <path className="d3-progress-background" d="M0,42A42,42 0 1,1 0,-42A42,42 0 1,1 0,42M0,39.5A39.5,39.5 0 1,0 0,-39.5A39.5,39.5 0 1,0 0,39.5Z" style={{ fill: 'rgb(255, 255, 255)', opacity: '0.1' }} />
                                            <path className="d3-progress-foreground" filter="url(#blur)" d="M6.661338147750939e-16,-40.7308237088326A1.25,1.25 0 0,1 1.2883435582822094,-41.98023547904219A42,42 0 1,1 -41.48773651930016,6.539703242894765A1.25,1.25 0 0,1 -40.40964899818112,5.104925841165514L-40.40964899818112,5.104925841165514A1.25,1.25 0 0,1 -39.01822839315133,6.150435192722457A39.5,39.5 0 1,0 1.2116564417177922,-39.48141193862301A1.25,1.25 0 0,1 6.661338147750939e-16,-40.7308237088326Z" style={{ fill: 'rgb(255, 255, 255)', stroke: 'rgb(255, 255, 255)' }} />
                                            <path className="d3-progress-front" d="M6.661338147750939e-16,-40.7308237088326A1.25,1.25 0 0,1 1.2883435582822094,-41.98023547904219A42,42 0 1,1 -41.48773651930016,6.539703242894765A1.25,1.25 0 0,1 -40.40964899818112,5.104925841165514L-40.40964899818112,5.104925841165514A1.25,1.25 0 0,1 -39.01822839315133,6.150435192722457A39.5,39.5 0 1,0 1.2116564417177922,-39.48141193862301A1.25,1.25 0 0,1 6.661338147750939e-16,-40.7308237088326Z" style={{ fill: 'rgb(255, 255, 255)', fillOpacity: 1 }} />
                                        </g>
                                    </svg>
                                    <i className="icon-cabinet counter-icon" style={{ color: 'rgb(255, 255, 255)', top: '26px' }} />
                                </div>
                                <h2 className="pt-1 mt-2 mb-1">{this.state.countsauditor}</h2>
                                Auditoría de cumplimiento fiscal
                                <div className="font-size-sm opacity-75">
                                    <a className="btn btn-pink btn-labeled btn-labeled-left" style={{ cursor: 'pointer' }}>Ingresar <i class="icon-arrow-right7 mr-2"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xl-4">
                            <div className="card card-body text-center bg-purple has-bg-image">
                                <div className="svg-center position-relative" id="progress_icon_two">
                                    <svg width="84" height="84">
                                        <g transform="translate(42,42)">
                                            <path className="d3-progress-background" d="M0,42A42,42 0 1,1 0,-42A42,42 0 1,1 0,42M0,39.5A39.5,39.5 0 1,0 0,-39.5A39.5,39.5 0 1,0 0,39.5Z" style={{ fill: 'rgb(255, 255, 255)', opacity: '0.1' }} />
                                            <path className="d3-progress-foreground" filter="url(#blur)" d="M6.661338147750939e-16,-40.7308237088326A1.25,1.25 0 0,1 1.2883435582822094,-41.98023547904219A42,42 0 1,1 -41.48773651930016,6.539703242894765A1.25,1.25 0 0,1 -40.40964899818112,5.104925841165514L-40.40964899818112,5.104925841165514A1.25,1.25 0 0,1 -39.01822839315133,6.150435192722457A39.5,39.5 0 1,0 1.2116564417177922,-39.48141193862301A1.25,1.25 0 0,1 6.661338147750939e-16,-40.7308237088326Z" style={{ fill: 'rgb(255, 255, 255)', stroke: 'rgb(255, 255, 255)' }} />
                                            <path className="d3-progress-front" d="M6.661338147750939e-16,-40.7308237088326A1.25,1.25 0 0,1 1.2883435582822094,-41.98023547904219A42,42 0 1,1 -41.48773651930016,6.539703242894765A1.25,1.25 0 0,1 -40.40964899818112,5.104925841165514L-40.40964899818112,5.104925841165514A1.25,1.25 0 0,1 -39.01822839315133,6.150435192722457A39.5,39.5 0 1,0 1.2116564417177922,-39.48141193862301A1.25,1.25 0 0,1 6.661338147750939e-16,-40.7308237088326Z" style={{ fill: 'rgb(255, 255, 255)', fillOpacity: 1 }} />
                                        </g>
                                    </svg>
                                    <i class="icon-balance counter-icon" style={{ color: 'rgb(255, 255, 255)', top: '26px' }}></i>
                                </div>
                                <h2 className="pt-1 mt-2 mb-1">{this.state.countsjuridico}</h2>
                                Jurídico
                                <div className="font-size-sm text-muted">
                                    <a className="btn btn-pink" style={{ cursor: 'pointer' }}>Ingresar <i class="icon-arrow-right7 mr-2"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PanelAdminsUsers;