
import React, { useState, useRef } from 'react'
import axios from 'axios';
import { Alert as MuiAlert } from '@material-ui/lab';
import { Slide, Snackbar, CircularProgress } from '@material-ui/core';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6}
        ref={ref}
        variant="filled"
        {...props} style={{ color: "white", backgroundColor: "#f44336 !important" }} />;
});
const url_ = "https://e-xpertum.com.mx/invoces/api/";
const RegisterEfirmaExterno = (props) => {
    console.log(props.match.params);
    const inputFile = useRef(null);
    const inputFileKey = useRef(null);
    const id_ = props.match.params.id; ///"B74C08931B6D414D040DD832E748A05F";
    const [certificate, setCertificado] = useState(null);
    const [any, setLlave] = useState({ status: 0, url: '' });
    const [path_key, setPath] = useState("");
    const [show_alert, setShow] = useState("");
    const [show_notification, setShowNotification] = useState("");
    const [nombrecer, setNameCer] = useState("");
    const [nombrekey, setNameKey] = useState("");
    const [passwordInput, setPasswordInput] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const [open, setOpen] = useState(false);
    const [show_status, setStatus] = useState("button");
    const [firma, setFirma] = useState({
        path: '', url: '', status: '', name_: '', rfc: '', end_date: ''
    });
    /**********
     * estatus de los archivos...
     */
    const [status_certificate, setStatusCertificate] = useState("button");
    const [status_key, setStatusKey] = useState("button");
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShow("");
        setOpen(false);
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleSubmit = async (event) => {
        event.preventDefault();



        if (!certificate || !nombrekey || !passwordInput) {
            setShow("error");
            setOpen(true);
            setShowNotification("Para continuar debes de ingresar todos los datos");
        } else {

            setStatus("loading");
            let _send_valida_ = await axios.post(url_ + 'save-and-validate-efirma-with-exterior',
                {
                    name: firma.name_,
                    rfc: firma.rfc,
                    password: passwordInput,
                    directorykey: path_key,
                    directorycer: firma.url,
                    id_: id_,
                    fecha_caducidad: firma.end_date
                });
            const { status, result_ } = await _send_valida_.data;
            if (status === 200) {
                ///
                if (result_.data.status === "EXIST") {
                    setOpen(true);
                    setShow("warning");
                    setShowNotification('Ya existe la empresa, favor de registrar otra!.');
                    setStatus("button");
                } else {

                    let cont = 0
                    const result_xxxx = setInterval(() => {
                        if (cont === 3) {
                            clearInterval(result_xxxx);
                            handle_validate_efirma();
                        }
                        cont++;
                    }, 5000);

                }
            }

        }
    };
    const handle_validate_efirma = async () => {
        var params = { id_: id_, rfc: firma.rfc }
        let _ap = await axios.post(url_ + 'validate-e-firma-with-sat-with-rfc', params);
        const { status, message } = await _ap.data;
        ///console.log('--------------------------------------------------', _ap.data);
        if (status === 200) {
            setStatus("");
            setTimeout(() => {
                setShow("success");
                setShowNotification(message);
                setOpen(true);
                setStatus("button");
                setTimeout(() => {
                    setFirma({
                        path: '', url: '', status: '', name_: '', rfc: '', end_date: ''
                    });
                    setStatusCertificate("button");
                    setStatusKey("button");
                    setStatus("button");
                    setCertificado(null);
                    setLlave({ status: 0, url: '' });
                    setPath("");
                    setShow("");
                    setShowNotification("");
                    setNameCer("");
                    setNameKey("");
                    setPasswordInput("");
                    setShowPassword(false);
                    setOpen(false);
                }, 2000);
            }, 1000);
        }
        else {
            setShow("info");
            setShowNotification(message);
            setOpen(true);
            setStatus("button");
        }
    }
    const handleCertificadoChange = (event) => {
        const files = event.target.files;
        setStatusCertificate("loading");
        if (files) {
            const file = files[0];
            const formData = new FormData();
            formData.append('file', file)
            formData.append('id_', id_);
            setTimeout(async () => {
                setCertificado(files[0]);
                setNameCer(files[0].name);
                let _dt_ = await axios.post(url_ + 'subirdocumentsvalidador', formData);
                const { status } = await _dt_.data;
                if (status === 200) {
                    setStatusCertificate("");
                }
                else {
                    setStatusCertificate("button");
                }
                setFirma(_dt_.data);
            }, 100);
        }
    };
    const handleLlaveChange = async (event) => {
        setStatusKey("loading");
        const files = event.target.files;
        if (files) {
            const file = files[0];
            const formData = new FormData();
            formData.append('file', file)
            formData.append('id_', id_)
            formData.append('path', firma.path)
            let _result_ = await axios.post(url_ + 'upload-file-key', formData);
            const { status, url } = await _result_.data;
            if (status === 200) {
                setLlave(_result_.data);
                setStatusKey("");
                setPath(url);
            } else {
                setStatusKey("button");
            }
            setNameKey(files[0].name);
        }
    };
    return (
        <div className="page-content">
            <div className="content-wrapper">
                <div className="content-inner">
                    <div className="page-header page-header-light shadow">
                        <div className="page-header-content d-lg-flex">
                            <div className="d-flex" style={{ height: 50 }}>
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className='row'>
                            <div className='col-lg-4'></div>
                            <div className='col-lg-4'>
                                <div className="row">
                                    <div className='col-lg-12' style={{ paddingTop: 60 }}>
                                        <div className="card" style={{ width: "100%" }}>
                                            <div className="card-header">
                                                <div className="mb-3">
                                                    <h6 className="mb-0">Registro de la firma electrónica</h6>
                                                    <span className="text-muted">Registra tu credencial del Servicio de Administración Tributaria</span>
                                                </div>
                                            </div>
                                            <form onSubmit={handleSubmit}
                                                autoComplete='off'>
                                                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
                                                    TransitionComponent={(props) => {
                                                        return <Slide {...props} direction="right" />;
                                                    }}
                                                    anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                                                    <Alert onClose={handleClose} severity={show_alert} sx={{ width: '100%' }}>
                                                        {show_notification}
                                                    </Alert>
                                                </Snackbar>
                                                <div className="card-body">
                                                    <div className="mb-4">
                                                        <div className="fw-bold border-bottom pb-2 mb-3">Ingresa todos los datos</div>
                                                        <div className="row mb-3">
                                                            <div className="col-lg-12">
                                                                <div className="input-group">
                                                                    <span className="input-group-text"><i className="icon-certificate" /></span>
                                                                    <input id='file-cer' hidden accept=".cer" multiple type="file" ref={inputFile} onChange={handleCertificadoChange} />
                                                                    <input type="text" className="form-control" placeholder="Selecciona el archivo .cer" value={nombrecer} autoComplete='off' />
                                                                    {status_certificate === "button" ? (
                                                                        <button className='btn btn-outline-success me-2' onClick={() => inputFile.current.click()}>
                                                                            <i className="icon-search4" />
                                                                        </button>
                                                                    ) : (
                                                                        status_certificate === "loading" ? (
                                                                            <button className='btn btn-outline-success'>
                                                                                <CircularProgress color='success' style={{ color: '#66bb6a' }} size={16} />
                                                                            </button>
                                                                        ) :
                                                                            <button className='btn btn-outline-success me-2'>
                                                                                <i className='icon-shield-check'></i>
                                                                            </button>
                                                                    )}

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <div className="col-lg-12">
                                                                <div className="input-group">
                                                                    <span className="input-group-text"><i className="icon-key" /></span>
                                                                    <input type="text" className="form-control" placeholder="Selecciona el archivo .key" value={nombrekey} autoComplete='off' />
                                                                    <input hidden accept=".key" multiple type="file" onChange={handleLlaveChange} ref={inputFileKey} />
                                                                    {
                                                                        status_key === "button" ? (
                                                                            <button type='button' className="btn btn-outline-success me-2" onClick={() => inputFileKey.current.click()}>
                                                                                <i className="icon-search4" />
                                                                            </button>
                                                                        ) : (
                                                                            status_key === "loading" ? (
                                                                                <button className='btn btn-outline-success'>
                                                                                    <CircularProgress color='success' style={{ color: '#66bb6a' }} size={16} />
                                                                                </button>
                                                                            ) :
                                                                                <button className='btn btn-outline-success me-2'>
                                                                                    <i className='icon-shield-check'></i>
                                                                                </button>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <div className="col-lg-12">
                                                                <div className="input-group">
                                                                    <span className="input-group-text"><i className="icon-lock2" /></span>
                                                                    <input type={showPassword ? 'text' : 'password'} onChange={(e) => setPasswordInput(e.target.value)}
                                                                        className="form-control" placeholder="Contraseña" autoComplete='off' />
                                                                    <button type='button' className="btn btn-outline-success me-2"
                                                                        onClick={handleClickShowPassword}
                                                                        onMouseDown={handleMouseDownPassword}>
                                                                        {showPassword ? <i className="icon-eye-blocked2" /> : <i className="icon-eye" />}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <div className="col-lg-12">
                                                                <div className="d-flex justify-content-center text-center">
                                                                    {
                                                                        show_status === "button" ? (
                                                                            <button className='btn btn-outline-success btn-lg btn-block'>
                                                                                Enviar
                                                                            </button>
                                                                        ) : (
                                                                            show_status === "loading" ? (
                                                                                <button className='btn btn-outline-success btn-lg btn-block'>
                                                                                    <CircularProgress disableShrink color='success' />
                                                                                </button>
                                                                            ) : <button className='btn btn-outline-success btn-lg btn-block'>
                                                                                <i className='icon-cloud-check' />
                                                                            </button>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default RegisterEfirmaExterno;

/* return (
     <ThemeProvider theme={theme}>
         <Box
             sx={{
                 marginTop: 8,
                 display: 'flex',
                 flexDirection: 'column',
                 alignItems: 'center',
             }}
         >
             <CssBaseline />
             <Typography component="h1" variant="h5">
                 <title>
                     Registro | cumplimiento fiscal
                 </title>
             </Typography>
             <Box
                 component="main"
                 sx={{
                     alignItems: 'center',
                     display: 'flex',
                     flexGrow: 1,
                     minHeight: '100%'
                 }}
             >
 
                 <Container maxWidth="sm">
                     <Card sx={{ p: 5 }}>
                         <form onSubmit={handleSubmit}
                             autoComplete='off'>
                             <Snackbar open={open}
                                 autoHideDuration={6000}
                                 onClose={handleClose}
                                 TransitionComponent={(props) => {
                                     return <Slide {...props} direction="right" />;
                                 }}
                                 anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                                 <Alert onClose={handleClose}
                                     severity={show_alert}
                                     sx={{ width: '100%' }}>
                                     {show_notification}
                                 </Alert>
                             </Snackbar>
                             <Grid container
                                 rowSpacing={4}
                                 columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                 <Grid item
                                     xs={12}>
                                     <Box sx={{ my: 1, pb: 1 }}>
                                         <Typography color="black"
                                             variant="h6"
                                             align='center'
                                             sx={{ pb: 1 }} >
                                             Registro de la firma electrónica
                                         </Typography>
                                         <Typography
                                             color="InfoText"
                                             gutterBottom
                                             variant="body2"
                                         >
                                             Registra tu credencial del Servicio de Administración Tributaria
                                         </Typography>
                                     </Box>
                                 </Grid>
                                 <Grid item xs={12}>
                                     <TextField
                                         InputProps={{
                                             startAdornment: <InputAdornment position="start"><ArticleOutlined /></InputAdornment>,
                                             endAdornment: <div>
                                                 {status_certificate === "button" ? (
                                                     <Button variant="contained" component="label" color='success'>
                                                         <Search />
                                                         <input hidden accept=".cer" multiple type="file" onChange={handleCertificadoChange} />
                                                     </Button>
                                                 ) : (
                                                     status_certificate === "loading" ? (
                                                         <Box sx={{ pr: 2 }}>
                                                             <CircularProgress color='success' size={30} />
                                                         </Box>
                                                     ) :
                                                         <Box sx={{ pr: 2 }}>
                                                             <TaskAltOutlined color='success' />
                                                         </Box>)}
 
                                             </div>
                                         }}
                                         fullWidth placeholder='Selecciona el .cer' variant="outlined" value={nombrecer} color='success' />
                                 </Grid>
                                 <Grid item xs={12}>
                                     <TextField
                                         InputProps={{
                                             startAdornment: <InputAdornment position="start"><KeyOutlined /></InputAdornment>,
                                             endAdornment: <div>
                                                 {
                                                     status_key === "button" ? (
                                                         <Button variant="contained" component="label" color='success'>
                                                             <Search />
                                                             <input hidden accept=".key" multiple type="file" onChange={handleLlaveChange} />
                                                         </Button>
                                                     ) : (
                                                         status_key === "loading" ? (
                                                             <Box sx={{ pr: 2 }}>
                                                                 <CircularProgress color='success' size={30} />
                                                             </Box>
                                                         ) :
                                                             <Box sx={{ pr: 2 }}>
                                                                 <TaskAltOutlined color='success' />
                                                             </Box>)
                                                 }
                                             </div>
                                         }}
                                         fullWidth placeholder='Selecciona el .key' variant="outlined" value={nombrekey} autoComplete={"off"} color='success' />
                                 </Grid>
                                 <Grid item
                                     xs={12}>
                                     <TextField fullWidth
                                         value={passwordInput}
                                         placeholder='Contraseña'
                                         type={showPassword ? 'text' : 'password'}
                                         InputProps={{
                                             startAdornment: <InputAdornment position="start"><LockOutlined /></InputAdornment>,
                                             endAdornment: <InputAdornment position="end">
                                                 <IconButton
                                                     aria-label="toggle password visibility"
                                                     onClick={handleClickShowPassword}
                                                     onMouseDown={handleMouseDownPassword}
                                                     edge="end"
                                                 >
                                                     {showPassword ? <VisibilityOff /> : <Visibility />}
                                                 </IconButton>
                                             </InputAdornment>
                                         }} onChange={(e) => setPasswordInput(e.target.value)}
                                         color='success' />
                                 </Grid>
                                 <Grid item xs={2}></Grid>
                                 <Grid item xs={2}></Grid>
                                 <Grid item
                                     xs={12}>
                                     <Typography variant='caption'>{firma.rfc}</Typography>
                                 </Grid>
                             </Grid>
                             <Box sx={{ py: 2 }}>
                                 {
                                     show_status === "button" ? (
                                         <Button fullWidth size="large" type="submit" variant="contained" color='success'>
                                             Enviar
                                         </Button>
                                     ) : (
                                         show_status === "loading" ? (
                                             <Button fullWidth
                                                 variant="outlined"
                                                 color='success'>
                                                 <CircularProgress disableShrink color='success' />
                                             </Button>
                                         ) : <Button fullWidth
                                             variant="outlined"
                                             color='success'>
                                             <TaskAltOutlined color='success' />
                                         </Button >
                                     )
                                 }
 
                             </Box>
 
                         </form>
                     </Card>
                 </Container>
 
             </Box>
         </Box>
     </ThemeProvider>
 );*/

