import React, { Component } from 'react';
import { red } from '@material-ui/core/colors';
import * as Icons from '@material-ui/icons';
import { Typography, Avatar, IconButton, Dialog, Backdrop, Snackbar, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiAlert from '@material-ui/lab/Alert';
import api from '../../../../app/Config/api';
/*****************
 * 
 * NOTIFICACIONES VIEW SISTEMA 
 * 
 */
import ViewContabilidad from '../../../../app/Notificaciones/contabilidad';
import ViewFederales from '../../../../app/Notificaciones/impuestofederal';
import ViewInformativa from '../../../../app/Notificaciones/screen-informativa';
import ViewEstatal from '../../../../app/Notificaciones/screen-estatales';
import ViewMulta from '../../../../app/Notificaciones/screen-multa';
import ViewValidador from '../../../../app/Notificaciones/screen-validador';
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6" align='center'>{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <Icons.Close />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class MenuAuditorSistema extends Component {
    constructor(props) {
        super(props);
        var result = props.value.info;
        this.state = {
            notificationcfdi: '',
            notificationcontabilidad: '',
            notificationfederales: '',
            notificationestatales: '',
            notificationinformativa: '',
            notificationmulta: '',
            validacion_cfdi_color: '',
            contribucion_federal_color: '',
            contabilidad_electronica_color: '',
            informativa_color: '',
            estatal_color: '',
            multa_color: '',
            nombreempresa: '',
            modalcfdi: false,
            modalcontabilidad: false,
            modalfederales: false,
            modalinformativa: false,
            modalestatal: false,
            modalmulta: false,
            screen: true,
            empresa_id: (result.empresa_id == null ? 0 : result.empresa_id),
            cliente_id: '',
            mensaje: '',
            openmensaje: false,
            vertical: 'top',
            horizontal: 'right'
        }
    }
    componentDidMount() {
        localStorage.setItem("_data_info_empresa_", JSON.stringify(this.props.value.info));
        api.post('listcompanymobil', this.props.value.info).then((response) => {
            var resultend = (response.data).filter((y) => y.empresa_id === this.state.empresa_id)[0];
            if (resultend != undefined) {
                this.setState({
                    notificationcfdi: (resultend.validacion_cfdi == 0 ? 'Aún no se ha configurado por primera vez.' : resultend.validacion_cfdi == 1 ? 'No se ha iniciado la carga o validación.' : resultend.validacion_cfdi == 2 ? '1 o más CFDIs en Listas Negras' : resultend.validacion_cfdi == 3 ? 'Ningún CFDI en Listas Negras' : ''),
                    notificationcontabilidad: (resultend.contabilidad_electronica == 0 ? 'Aún no se ha configurado por primera vez.' : resultend.contabilidad_electronica == 1 ? 'No se ha iniciado la importación.' : resultend.contabilidad_electronica == 2 ? 'Hay omisiones y/o errores en pólizas, balanza o catálogo.' : resultend.contabilidad_electronica == 3 ? 'Está todo importado y sin errores u omisiones' : ''),
                    notificationfederales: (resultend.contribucion_federal == 0 ? 'No configurada.' : resultend.contribucion_federal == 1 ? 'No se ha iniciado la captura de impuestos.' : resultend.contribucion_federal == 2 ? 'No se ha capturado y/o pagado alguna contribución.' : resultend.contribucion_federal == 3 ? 'Se encuentran todas las contribuciones cargadas y pagadas.' : ''),
                    notificationestatales: (resultend.estatal == 0 ? 'No configurada.' : resultend.estatal == 1 ? 'No se ha iniciado la captura de impuestos.' : resultend.estatal == 2 ? 'No se ha capturado y/o pagado alguna contribución.' : resultend.estatal == 3 ? 'Todas las contribuciones se encuentran pagadas.' : ''),
                    notificationinformativa: (resultend.informativa == 0 ? 'No configurada.' : resultend.informativa == 1 ? 'No se han cargado las declaraciones.' : resultend.informativa == 2 ? 'No se ha cumplido con la presentación de declaraciones a la fecha.' : resultend.informativa == 3 ? 'Están presentadas todas las declaraciones informativas' : ''),
                    notificationmulta: (resultend.multa == 0 ? 'No configurada.' : resultend.multa == 1 ? 'Existen multas por pagar.' : resultend.multa == 2 ? 'Falta de pago de alguna multa.' : resultend.multa == 3 ? 'Todas las multas se encuentran pagadas.' : ''),
                    nombreempresa: resultend.razon_social,
                    validacion_cfdi_color: (resultend.validacion_cfdi == 0 ? 'gray' : resultend.validacion_cfdi == 1 ? '#f9c74f' : resultend.validacion_cfdi == 2 ? '#bf0603' : resultend.validacion_cfdi == 3 ? '#90be6d' : ''),
                    contabilidad_electronica_color: (resultend.contabilidad_electronica == 0 ? 'gray' : resultend.contabilidad_electronica == 1 ? '#f9c74f' : resultend.contabilidad_electronica == 2 ? '#bf0603' : resultend.contabilidad_electronica == 3 ? '#90be6d' : ''),
                    contribucion_federal_color: (resultend.contribucion_federal == 0 ? 'gray' : resultend.contribucion_federal == 1 ? '#f9c74f' : resultend.contribucion_federal == 2 ? '#bf0603' : resultend.contribucion_federal == 3 ? '#90be6d' : ''),
                    informativa_color: (resultend.informativa == 0 ? 'gray' : resultend.informativa == 1 ? '#f9c74f' : resultend.informativa == 2 ? '#bf0603' : resultend.informativa == 3 ? '#90be6d' : ''),
                    estatal_color: (resultend.estatal == 0 ? 'gray' : resultend.estatal == 1 ? '#f9c74f' : resultend.estatal == 2 ? '#bf0603' : resultend.estatal == 3 ? '#90be6d' : ''),
                    multa_color: (resultend.multa == 0 ? 'gray' : resultend.multa == 1 ? '#f9c74f' : resultend.multa == 2 ? '#bf0603' : resultend.multa == 3 ? '#90be6d' : ''),
                    screen: false
                });
            }
            else {
                this.setState({ screen: false, openmensaje: true, mensaje: 'No existe información para la auditoria, importar los datos' });
                setTimeout(() => {
                    this.props.value.close();
                }, 6000);
            }
        });
    }
    handleClickOpen = (i) => {
        this.setState(i);
    };
    handleClose = () => {
        this.setState({
            modalcfdi: false,
            modalcontabilidad: false,
            modalfederales: false,
            modalinformativa: false,
            modalestatal: false,
            modalmulta: false,
        })
    }
    handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({ openmensaje: false });
    };
    render() {
        const { horizontal, vertical } = this.state;
        return (
            <>

                <Backdrop open={this.state.screen} style={{ color: "#fff", background: "#B9B6B6", zIndex: 400, opacity: "0.5" }} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Snackbar open={this.state.openmensaje} autoHideDuration={6000} onClose={this.handleCloseAlert} anchorOrigin={{ vertical, horizontal }}>
                    <Alert onClose={this.handleCloseAlert} severity="warning">{this.state.mensaje}</Alert>
                </Snackbar>

                <div className="row" style={{ paddingTop: 10 }}>
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <h6 className="card-title text-center" style={{ color: "white" }}>{this.state.nombreempresa}</h6>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xl-4">
                                        <div className="card border-left-3 border-left-danger rounded-left-0">
                                            <div className="card-header">
                                                <div className="media">
                                                    <div className="mr-3">
                                                        <Avatar aria-label="recipe" style={{ backgroundColor: red[500] }}>69B</Avatar>
                                                    </div>
                                                    <div className="media-body">
                                                        <h6 className="mb-0" style={{ color: 'white', textAlign: 'left' }}>Validación de CFDI's</h6>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="card-body">
                                                <span style={{ color: 'white' }}> {this.state.notificationcfdi}</span>
                                            </div>
                                            <div className="card-footer border-light d-sm-flex justify-content-sm-between align-items-sm-center">
                                                <button type="button" className="btn btn-danger" onClick={() => this.handleClickOpen({ modalcfdi: true })}> Ver más detalle <i className="icon-chevron-right mr-2"></i></button>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-xl-4">
                                        <div className="card border-left-3 border-left-danger rounded-left-0">
                                            <div className="card-header">
                                                <div className="media">
                                                    <div className="mr-3">
                                                        <Avatar aria-label="recipe" style={{ backgroundColor: red[500] }}>C</Avatar>
                                                    </div>
                                                    <div className="media-body">
                                                        <h6 className="mb-0" style={{ color: 'white', textAlign: 'left' }}>Contabilidad electrónica</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <span style={{ color: 'white' }}> {this.state.notificationcontabilidad}</span>
                                            </div>
                                            <div className="card-footer border-light d-sm-flex justify-content-sm-between align-items-sm-center">
                                                <button type="button" className="btn btn-danger" onClick={() => this.handleClickOpen({ modalcontabilidad: true })}> Ver más detalle <i className="icon-chevron-right mr-2"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4">
                                        <div className="card border-left-3 border-left-danger rounded-left-0">
                                            <div className="card-header">
                                                <div className="media">
                                                    <div className="mr-3">
                                                        <Avatar aria-label="recipe" style={{ backgroundColor: red[500] }}>F</Avatar>
                                                    </div>
                                                    <div className="media-body">
                                                        <h6 className="mb-0" style={{ color: 'white', textAlign: 'left' }}>Contribuciones federales</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <span style={{ color: 'white' }}> {this.state.notificationfederales}</span>
                                            </div>
                                            <div className="card-footer border-light d-sm-flex justify-content-sm-between align-items-sm-center">
                                                <button type="button" className="btn btn-danger" onClick={() => this.handleClickOpen({ modalfederales: true })}> Ver más detalle <i className="icon-chevron-right mr-2"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-4">
                                        <div className="card border-left-3 border-left-danger rounded-left-0">
                                            <div className="card-header">
                                                <div className="media">
                                                    <div className="mr-3">
                                                        <Avatar aria-label="recipe" style={{ backgroundColor: red[500] }}>I</Avatar>
                                                    </div>
                                                    <div className="media-body">
                                                        <h6 className="mb-0" style={{ color: 'white', textAlign: 'left' }}>Declaraciones informativas</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <span style={{ color: 'white' }}> {this.state.notificationinformativa}</span>
                                            </div>
                                            <div className="card-footer border-light d-sm-flex justify-content-sm-between align-items-sm-center">
                                                <button type="button" className="btn btn-danger" onClick={() => this.handleClickOpen({ modalinformativa: true })}> Ver más detalle <i className="icon-chevron-right mr-2"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4">
                                        <div className="card border-left-3 border-left-danger rounded-left-0">
                                            <div className="card-header">
                                                <div className="media">
                                                    <div className="mr-3">
                                                        <Avatar aria-label="recipe" style={{ backgroundColor: red[500] }}>E</Avatar>
                                                    </div>
                                                    <div className="media-body">
                                                        <h6 className="mb-0" style={{ color: 'white', textAlign: 'left' }}>Contribuciones estatales</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <span style={{ color: 'white' }}> {this.state.notificationestatales}</span>
                                            </div>
                                            <div className="card-footer border-light d-sm-flex justify-content-sm-between align-items-sm-center">
                                                <button type="button" className="btn btn-danger" onClick={() => this.handleClickOpen({ modalestatal: true })}> Ver más detalle <i className="icon-chevron-right mr-2"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4">
                                        <div className="card border-left-3 border-left-danger rounded-left-0">
                                            <div className="card-header">
                                                <div className="media">
                                                    <div className="mr-3">
                                                        <Avatar aria-label="recipe" style={{ backgroundColor: red[500] }}>M</Avatar>
                                                    </div>
                                                    <div className="media-body">
                                                        <h6 className="mb-0" style={{ color: 'white', textAlign: 'left' }}>Multas</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <span style={{ color: 'white' }}> {this.state.notificationmulta}</span>
                                            </div>
                                            <div className="card-footer border-light d-sm-flex justify-content-sm-between align-items-sm-center">
                                                <button type="button" className="btn btn-danger" onClick={() => this.handleClickOpen({ modalmulta: true })}> Ver más detalle <i className="icon-chevron-right mr-2"></i></button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={this.state.modalcfdi} maxWidth="xl" fullWidth={true}>
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose}><span style={{ color: 'white' }}> Reporte del 69B vs proveedores</span></DialogTitle>
                    <DialogContent dividers>
                        <ViewValidador />
                    </DialogContent>
                </Dialog>
                <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={this.state.modalcontabilidad} maxWidth="xl" fullWidth={true}>
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose}><span style={{ color: 'white' }}> Contabilidad electrónica</span></DialogTitle>
                    <DialogContent dividers>
                        <ViewContabilidad />
                    </DialogContent>
                </Dialog>
                <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={this.state.modalfederales} maxWidth="xl" fullWidth={true}>
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose}><span style={{ color: 'white' }}> Contribuciones federales</span></DialogTitle>
                    <DialogContent dividers>
                        <ViewFederales />
                    </DialogContent>
                </Dialog>
                <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={this.state.modalinformativa} maxWidth="xl" fullWidth={true}>
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose}><span style={{ color: 'white' }}> Declaraciones informativas</span></DialogTitle>
                    <DialogContent dividers>
                        <ViewInformativa />
                    </DialogContent>
                </Dialog>
                <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={this.state.modalestatal} maxWidth="xl" fullWidth={true}>
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose}><span style={{ color: 'white' }}> Contribuciones estatales</span></DialogTitle>
                    <DialogContent dividers>
                        <ViewEstatal />
                    </DialogContent>
                </Dialog>
                <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={this.state.modalmulta} maxWidth="xl" fullWidth={true}>
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose}><span style={{ color: 'white' }}> Multas</span></DialogTitle>
                    <DialogContent dividers>
                        <ViewMulta />
                    </DialogContent>
                </Dialog>
            </>
        );
    }
}

export default MenuAuditorSistema;

