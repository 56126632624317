import React, { Component } from 'react';
import api from '../../../../app/Config/api';
import { Tooltip, } from "@material-ui/core";
import ReactDatatable from '@ashvin27/react-datatable';
import Skeleton from '@material-ui/lab/Skeleton';

class SaldosIniciales extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "account_number",
                text: "Cuenta contable",
                className: "account_number",
                align: "left",
                sortable: true,
            },
            {
                key: "description",
                text: "Descripción",
                className: "description",
                align: "left",
                sortable: true
            },
            {
                key: "opening_debit_balance",
                text: "Saldo inicial deudor",
                className: "opening_debit_balance",
                cell: record => {
                    return (
                        <div className="text-right">
                            {
                                (record.opening_debit_balance > 0 && record.opening_creditor_balance === 0) ?
                                    (
                                        <p style={{ fontSize: 14 }}>{(record.opening_debit_balance).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                                    ) :
                                    (
                                        (record.opening_creditor_balance > 0 && record.opening_debit_balance > 0) ?
                                            (
                                                <>
                                                    {
                                                        (record.opening_creditor_balance === record.opening_debit_balance > 0) ?
                                                            (<p className="text-success" style={{ fontSize: 14 }}>{(record.opening_debit_balance).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>) :
                                                            (<p className="text-danger" style={{ fontSize: 14 }}>{(record.opening_debit_balance).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>)
                                                    }

                                                </>
                                            ) : null
                                    )
                            }
                        </div>
                    );
                }
            },
            {
                key: "opening_creditor_balance",
                text: "Saldo inicial acreedor",
                className: "opening_creditor_balance",
                cell: record => {
                    return (
                        <div className="text-right">
                            {
                                (record.opening_creditor_balance > 0 && record.opening_debit_balance === 0) ?
                                    (
                                        <p style={{ fontSize: 14 }}>{(record.opening_creditor_balance).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                                    ) : (
                                        (record.opening_creditor_balance > 0 && record.opening_debit_balance > 0) ?
                                            (
                                                <>
                                                    {
                                                        (record.opening_creditor_balance === record.opening_debit_balance > 0) ?
                                                            (<p className="text-success" style={{ fontSize: 14 }}>{(record.opening_creditor_balance).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>) :
                                                            (<p className="text-danger" style={{ fontSize: 14 }}>{(record.opening_creditor_balance).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>)
                                                    }

                                                </>
                                            ) : null
                                    )
                            }
                        </div>
                    );
                }
            }, {
                key: "action",
                text: "Acción",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <div className="text-center">
                            {
                                (record.opening_debit_balance > 0 && record.opening_creditor_balance === 0) ?
                                    (
                                        <Tooltip title="Modificar" placement="top" arrow>
                                            <a className="list-icons-item text-blue-300" style={{ cursor: 'pointer' }}>
                                                <i className="icon-arrow-right16"></i>
                                            </a>
                                        </Tooltip>
                                    ) :
                                    ((record.opening_creditor_balance > 0 && record.opening_debit_balance === 0) ?
                                        (
                                            <Tooltip title="Modificar" placement="top" arrow>
                                                <a className="list-icons-item text-blue-300" style={{ cursor: 'pointer' }}>
                                                    <i className="icon-arrow-left16"></i>
                                                </a>
                                            </Tooltip>
                                        ) : null)
                            }


                        </div>
                    );
                }
            }
        ];
        this.config = {
            key_column: 'account_number',
            page_size: 1000,
            length_menu: [1000, 2000, 5000],
            button: {
                excel: true,
                print: true,
                extra: false,
            },
            "language": {
                "length_menu": "Mostrar _MENU_ registros por página",
                "filter": "Buscar en registros ...",
                "info": "Mostrando _START_ a _END_ de _TOTAL_ entradas",
                "pagination": {
                    "first": "Primero",
                    "previous": "Previa",
                    "next": "próximo",
                    "last": "Última"
                }
            }
        }

        this.state = {
            recorset: [],
            skeleton: true,
        }
    }
    componentDidMount() {
        this.getSaldoInicial();
    }
    getSaldoInicial = async () => {
        let pareme = JSON.parse(localStorage.getItem("parameters"));
        let result_ = await api.post("getSaldosIniciales", pareme);
        let { data } = await result_;
        this.setState({ recorset: data, skeleton: false });
    }
    render() {
        return (
            <>
                {
                    this.state.skeleton ? (
                        <div className="card">
                            <Skeleton />
                            <Skeleton animation={false} height={400} />
                            <Skeleton animation="wave" />
                        </div>
                    ) : (
                            <ReactDatatable
                                config={this.config}
                                records={(this.state.recorset || [])}
                                columns={this.columns}
                            />
                        )
                }
            </>
        );
    }
}

export default SaldosIniciales;