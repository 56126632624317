import React, { Component } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import apiFile from '../../Config/apifiles';
class HistoryCfdiDownload extends Component {
    constructor(props) {
        super(props);
        let _data_ = JSON.parse(localStorage.getItem("panelconfig"));
        this.columns = [
            {
                key: "rfc",
                text: "RFC",
                className: "rfc",
                align: "left",
            },
            {
                key: "tipo",
                text: "Tipo",
                className: "tipo",
                align: "center",
                sortable: true,
                cell: record => {
                    return (
                        <div style={{ textAlign: 'center' }}>
                            {
                                <span className="text-succes">{record.tipo}</span>
                            }
                        </div>
                    );
                }
            },
            {
                key: "estatus",
                text: "Estatus",
                className: "estatus",
                align: "center",
                sortable: true,
                cell: record => {
                    return (
                        <div style={{ textAlign: 'center' }}>
                            {
                                (record.estatus === "ERROR") ? (
                                    <span className="badge badge-danger d-block">{record.estatus}</span>
                                ) : (
                                    (record.estatus === "SUCCESS") ?
                                        (
                                            <span className="badge badge-success d-block">{record.estatus}</span>
                                        ) :
                                        (
                                            (record.estatus === "PROCCESS") ?
                                                (
                                                    <>
                                                        <span className="badge badge-success d-block">{record.estatus}&nbsp;&nbsp;&nbsp; <i className="icon-spinner2 spinner"></i></span>

                                                    </>
                                                ) :
                                                <span className="badge badge-info d-block">{record.estatus}</span>
                                        )
                                )
                            }
                        </div>
                    );
                }
            }, {
                key: "fecha_inicio",
                text: "Fecha inicio",
                className: "fecha_inicio",
                align: "center",
                sortable: true,
                cell: record => {
                    return (
                        <div style={{ textAlign: 'center' }}>
                            {
                                <span className="text-succes">{record.fecha_inicio}</span>
                            }
                        </div>
                    );
                }
            }, {
                key: "fecha_fin",
                text: "Fecha fin",
                className: "fecha_fin",
                align: "center",
                sortable: true,
                cell: record => {
                    return (
                        <div style={{ textAlign: 'center' }}>
                            {
                                <span className="text-succes">{record.fecha_fin}</span>
                            }
                        </div>
                    );
                }
            }
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,
            button: {
                excel: false,
                print: false,
                extra: false,
            },
            language: {
                length_menu: "Mostrar _MENU_ registros por página",
                filter: "Buscar en registros ...",
                info: "Mostrando _START_ a _END_ de _TOTAL_ entradas",
                pagination: {
                    first: "Primero",
                    previous: "Previa",
                    next: "próximo",
                    last: "Última"
                }
            },
            no_data_text: "No se encontraron registros"
        }

        this.state = {
            info: [],
            id: _data_.empres_id,
        }
    }
    componentDidMount() {
        let iterate_ = 1000;
        let iterate_x = 0;
        this.lista_app();
        const aps = setInterval(() => {
            if (iterate_ === iterate_x) {
                clearInterval(aps);
            }
            this.lista_app();
            iterate_x++;
        }, 900000);

    }
    lista_app = async () => {
        let app = await apiFile.post('report-history-download', this.state);
        this.setState({
            info: app.data
        });
    }
    render() {
        return (
            <>
                <div className="card">
                    <div className="card-header bg-transparent border-bottom header-elements-inline">
                        <h6 className="card-title">Single icon</h6>
                        <div className="header-elements">
                            <div className="list-icons">
                                <a href="#" className="list-icons-item"><i className="icon-cog3" /></a>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <ReactDatatable
                            config={this.config}
                            records={this.state.info}
                            columns={this.columns}
                        ////extraButtons={this.extraButtons}
                        />
                    </div>
                </div>

            </>
        );
    }
}

export default HistoryCfdiDownload;