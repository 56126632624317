import React, { Component } from 'react';
/*******
 * CONFIG CONEXION
 */
import api from '../Config/api';
/*******
 * LIBRARY UPLOAD FILES
 */
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import ViewLeft from './util-estatal/viewleft';
import ViewRight from './util-estatal/viewright';

class TimeLineEstatales extends Component {
    constructor(props) {
        super(props);
        var data = JSON.parse(localStorage.getItem("_data_info_empresa_"));
        this.state = {
            ejercicios: [],
            datalist: [],
            empresa_id: data.empresa_id,
            openscreen: true,
            mensaje: '',
            bools: false
        }
    }
    componentDidMount() {
        this.getData();
    }
    getData() {
        var objectinfo = {
            empresa_id: this.state.empresa_id
        };
        api.post('getlistobligacionesestatales', objectinfo)
            .then(response => response.data)
            .then(data => {
                console.log(data)
                if (data.estatus === 200) {
                    var object = data.info;

                    // if (object.lenght > 0) {
                    var statusleft = true, statusright = false;
                    for (let obej = 0; obej < object.length; obej++) {
                        if (statusleft === true) {
                            statusleft = false;
                            statusright = true;
                            object[obej].view = 'left';
                        }
                        else if (statusright === true) {
                            statusleft = true;
                            statusright = false;
                            object[obej].view = 'right';
                        }

                    }
                    setTimeout(() => {
                        this.setState({
                            datalist: object,
                            openscreen: false
                        });
                    }, 2000);

                } else if (data.estatus === 204) {
                    this.setState({
                        mensaje: data.info,
                        openscreen: false,
                        bools: true
                    });
                    setTimeout(() => {
                        this.setState({
                            bools: false
                        });
                    }, 9000);
                }
                else if (data.estatus === 404) {
                    this.setState({
                        mensaje: 'No hay información a mostrar',
                        openscreen: false,
                        bools: true
                    });
                    setTimeout(() => {
                        this.setState({
                            bools: false
                        });
                        this.props.closemodal();
                    }, 9000);
                }
            });
    }
    render() {

        const { datalist } = this.state;
        return (
            <>
                <Backdrop open={this.state.openscreen} style={{ color: "#fff", background: "#B9B6B6", zIndex: 400, opacity: "0.5" }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div className="content-wrapper">
                    <div className="content pt-0">
                        {
                            this.state.bools ? (
                                <div class="alert alert-warning alert-styled-right alert-dismissible">
                                    <span className="font-weight-semibold">Información!</span> {this.state.mensaje}
                                </div>
                            ) : null
                        }
                        <div className="timeline timeline-center">
                            <div className="timeline-container">
                                {
                                    datalist.map((i, o) => (
                                        <div key={o}>
                                            {
                                                (i.view === "left") ?
                                                    <ViewLeft tipo={i.tipo} key={o} details={i} titulo={i.estado + ' ' + i.ejercicio} subtitulo={i.mes_causacion} fecha={i.fecha_corta} /> :
                                                    (i.view === "right") ?
                                                        <ViewRight tipo={i.tipo} key={o} details={i} titulo={i.estado + ' ' + i.ejercicio} subtitulo={i.mes_causacion} fecha={i.fecha_corta} /> : null
                                            }
                                        </div>

                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
        /* return (
             <>
                 <div className="container">
                     
                     <VerticalTimeline>
                         {
                             datalist.map((i, o) => (
                                 <VerticalTimelineElement key={o}
                                     className="vertical-timeline-element"
                                     date={<>
                                         Estado: {i.estado + ' ' + i.ejercicio + ' - ' + i.tipo}
                                     </>}
                                     iconStyle={{ background: 'rgb(255, 92, 147)', color: '#fff' }}
                                     icon={<Icon.Close />}>
                                     <h3 className="vertical-timeline-element-title">{i.ejercicio} - {i.mes_causacion}</h3>
                                     <h3 className="subtitle-timeline">Contribuciones no presentadas</h3>
                                     <Tablaview value={i} />
                                 </VerticalTimelineElement>
                             ))
                         }
 
                     </VerticalTimeline>
                 </div>
             </>
         );*/
    }

}

export default TimeLineEstatales;