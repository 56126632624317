import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MultiSelect from "react-multi-select-component";
import { Button, Dialog, DialogTitle as MuiDialogTitle, DialogContent as MuiDialogContent, DialogActions as MuiDialogActions, Typography, Card, CardContent, MenuItem, Grid, TextField } from '@material-ui/core';
import api from '../../Config/api';
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function CustomizedDialogs() {
    const [open, setOpen] = useState(false);
    const [descripcion, setInformacion] = useState();
    const [options, setPersonName] = useState([]);
    var objectData = JSON.parse(localStorage.getItem("id_token"));

    const [formState, setFormState] = React.useState({
        userRoles: []
    });
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        if (descripcion != '') {
            let _resulta_ = {
                descripcion: descripcion,
                sistemas: formState.userRoles,
                usuario: objectData.usuario
            }

            api.post('NewSercicio', _resulta_).then(res => {
                setOpen(false);
            });
        }
    };
    const handleChange = (event) => {
        const value = event.target.value;
        setInformacion(value);
    };

    useEffect(() => {
        api.get('listProduct')
            .then(response => response.data)
            .then(data => {
                let teamsFromApi = data.map(team => {
                    return { value: team.id, label: team.nombre };
                });
                setPersonName(teamsFromApi);
            });
    }, []);

    const handleFieldChange = event => {

        event.persist();
        setFormState(formState => ({
            ...formState,
            [event.target.name]:
                event.target.type === "checkbox"
                    ? event.target.checked
                    : event.target.value
        }));
    };
    return (
        <div>
            <button type="button" className="btn btn-light" onClick={handleClickOpen}>Nuevo <i class="icon-play3 ml-2"></i></button>

            <Dialog onClose={() => setOpen(false)} aria-labelledby="customized-dialog-title" open={open} fullWidth={true} maxWidth="md" >
                <DialogTitle style={{ backgroundColor: "#3a445b", color: "#ffffff" }} onClose={() => setOpen(false)} style={{ textAlign: "center" }}>Nuevo servicio</DialogTitle>
                <DialogContent dividers className="Card">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField id="descripcion" name="descripcion" onChange={handleChange} style={{ color: '#ffffff' }}
                                label="Descripción" type="text" margin="dense" fullWidth required variant="outlined" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField margin="dense"
                                fullWidth required variant="outlined"
                                select
                                name="userRoles"
                                id="userRoles"
                                variant="outlined"
                                label="Sistemas"
                                SelectProps={{
                                    multiple: true,
                                    value: formState.userRoles,
                                    onChange: handleFieldChange
                                }}
                            >
                                {options.map((team, i) => (
                                    <MenuItem key={i} value={team.value}>{team.label}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <button onClick={() => setOpen(false)} className="btn btn-info">Cancelar</button>
                    <button autoFocus onClick={handleClose} className="btn btn-primary">Guardar</button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
