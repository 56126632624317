import React, { Component } from 'react';
import api from '../Config/audit-data';
import Constancia from './reports/constancia';
import TaxComplixe from './reports/opinioncumplimiento';
import RSFor from './reports/saldo-favor';
import Navbar from './navbar';
import Balance from './reports/balance';
import IncomeStatement from './reports/income_statement';
import Recibidos from '../Reports/details/recibido';
import Emitido from '../Reports/details/emitido';
import NavTabsEffectiveIncomeTax from '../Reports/details/tab_rates';
import SueldosSalarios from '../Reports/details/wages-salaries';
import CedulaA from '../../report-iva/screen-pepers-works/cedula-a';
import IMSSE from '../Reports/details/imss';
import ListObligations from './reports/obligations';
//import CompareIVAData from '../../compare';
import Acreditable from '../../report-iva/acreditable';
import IndexTraslado from '../../report-iva/index-trasladado';
import CashFlow from './reports/cash_flow';
import Statement_changes_capital from './reports/statement_changes_capital';
import Reconciliation_between_accounting_tax_results from './reports/reconciliation_between_accounting_tax_results';
import Income_Tax_Lease from '../Reports/details/income_tax_lease';
import fastapi from '../Config/fast_api';

const components = {
    CONSTANCIA: <Constancia />,
    OBLIGACION: <ListObligations />,
    ACREDITABLE: <Acreditable />,
    OPINION: <TaxComplixe />,
    INCOME: <IncomeStatement />,
    BALANCE: <Balance />,
    ANUAL: <NavTabsEffectiveIncomeTax />,
    EMITIDO: <Emitido />,
    RIVA: <Recibidos />,
    SUELDOS: <SueldosSalarios />,
    IMSS: <IMSSE />,
    SF: <RSFor />,
    IVACOBRADOR: <IndexTraslado />,
    FEFECTIVO: <CashFlow />,
    CAMBIOSCAPITAL: <Statement_changes_capital />,
    CONTABLEFISCAL: <Reconciliation_between_accounting_tax_results />,
    LEASE: <Income_Tax_Lease />
};
class DashboardIni extends Component {
    constructor(props) {
        super(props);
        let objects = JSON.parse(localStorage.getItem("datacompanyall"));
        console.log(objects)
        this.state = {
            empresa_id: objects.id,
            rfc: objects.rfc,
            obligations: '',
            codeQR: '',
            balance: '',
            extras: '',
            income: '', cashflow: '',
            link: 'https://e-xpertum.com.mx/adminfile/api/convert-docx/' + objects.id + '/' + objects.rfc + '/xxxxxxxxxx',

        }
    }
    componentDidMount() {
        this.handle_reports_View();
        this.handleReadPdfAll();
        this.setState({ obligations: <ListObligations /> });
    }
    handle_reports_View = async () => {
        await api.post("parser-xlsx-anual", { empresa_id: this.state.empresa_id }).then(d => {
            console.log(d);
        }).catch(x => {
            console.log(x);
        });
    }

    handleAddViewReport = (d) => {
        const selectedComponent = components[d];
        this.setState({
            obligations: selectedComponent,
        });
    }
    handleReadPdfAll = async () => {
        let data = new FormData();
        data.append('rfc', this.state.rfc);
        data.append('id', this.state.empresa_id);

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://exentus.net/api/read-pdf-rates-effective',
            data: data
        };
        fastapi.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
            })
            .catch((error) => {
                console.log(error);
            });
        //await fastapi.post("read-pdf-rates-effective", { rfc: this.state.rfc, id: this.state.empresa_id });
    }
    render() {
        return (
            <div className="content-wrapper">
                {/* Page header */}
                <div className="page-header border-bottom-0">
                    <div className="page-header-content header-elements-md-inline">
                        <div className="page-title d-flex">
                            <a href="#/app/client/sistem" style={{ color: 'white', cursor: 'pointer' }}>  <h4><i className="icon-arrow-left52 mr-2" /> <span className="font-weight-semibold">Listado de empresas</span></h4></a>
                            <a href="#" className="header-elements-toggle text-default d-md-none"><i className="icon-more" /></a>
                        </div>
                        <h4>{this.state.nombrecompany}</h4>
                        <div className="header-elements d-none mb-3 mb-md-0">
                            <div className="d-flex justify-content-center">
                                <a href={this.state.link} target="" className="btn btn-link btn-float text-body"><i className="icon-file-word text-primary"></i><span>Descargar informe</span></a>
                                <a href="#" className="btn btn-link btn-float text-default"><i className="" /> <span></span></a>
                                <a href="#" className="btn btn-link btn-float text-default"><i className="" /> <span></span></a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* /page header */}
                {/* Content area */}
                <div className="content pt-0">
                    {/* Solid tabs */}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <Navbar url={this.handleAddViewReport} />
                                    <div className="content pt-4">
                                        {this.state.obligations}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DashboardIni;
