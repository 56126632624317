
import React, { Component } from 'react';
import api from '../../../app/Config/apifiles'

class ConfigCompany extends Component {
    constructor(props) {
        super(props);
        var objec = JSON.parse(localStorage.getItem('id_token'));
        this.state = {
            user: [],
            company: [],
            copycompany: [],
            user_id: 0,
            namegroup: '',
            userCompany: [],
            userCompanyorgi: [],
            cliente_id: objec.cliente_id
        }
    }
    handleChangeSelectUser = async (x) => {
        let xp = (x.target.value).split('&');
        this.setState({
            namegroup: xp[1] + xp[2] + xp[3],
            user_id: xp[0],
            company: []
        })
        let filteradd = (this.state.userCompanyorgi).filter((s) => s.usuario_id == xp[0]);
        this.setState({
            userCompany: filteradd,
            company: this.state.copycompany
        })
    }
    handleChangeSelectCompany = async (x) => {
        let xp = (x.target.value).split('&');
        let newElement = {
            usuario_id: this.state.user_id,
            namegroup: this.state.namegroup,
            empresa_id: xp[0],
            name: xp[1],
            cliente_id: this.state.cliente_id
        }
        let selectapp = await api.post('insert-update-config-user', newElement);
        let _dr_app_ = (await selectapp.data)[0];
        let filteradd = (_dr_app_).filter((s) => parseInt(s.usuario_id) == parseInt(this.state.user_id));
        this.setState({
            userCompany: filteradd,
            userCompanyorgi: _dr_app_
        });

    }
    componentDidMount() {
        this.getlistadmin();
    }
    getlistadmin = async () => {
        let _data_ = await api.post('list-user-company-config', { cliente_id: this.state.cliente_id });
        let list_all = await _data_.data;
        this.setState({
            user: list_all[0],
            company: list_all[1],
            copycompany: list_all[1],
            userCompany: list_all[2],
            userCompanyorgi: list_all[2],
        });
    }
    handledelete = async (d) => {
        let _data_ = await api.post('insert-delete-config-user', { cliente_id: this.state.cliente_id, id: d.identificador });
        let list_all = await _data_.data;
        this.setState({
            userCompany: list_all,
            userCompanyorgi: list_all,
        });
    }
    render() {
        return (
            <>
                <div className="content-wrapper">
                    <div className="content-inner">
                        <div className="page-header border-bottom-0">
                            <div className="page-header-content header-elements-lg-inline">
                                <div className="page-title d-flex">
                                    <h4><i className="icon-arrow-left52 mr-2" /> <span className="font-weight-semibold">Inicio</span></h4>
                                    <a href="#" className="header-elements-toggle text-body d-lg-none"><i className="icon-more" /></a>
                                </div>
                                <div className="header-elements d-none mb-3 mb-lg-0">
                                    <div className="d-flex justify-content-center">
                                        <a href="#" className="btn btn-link btn-float text-body"></a>
                                        <a href="#" className="btn btn-link btn-float text-body"></a>
                                        <a href="#" className="btn btn-link btn-float text-body"></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content pt-0">
                            <div className="mb-3">
                                <h6 className="mb-0 font-weight-semibold">
                                    Configuración empresas con usuarios
                                </h6>
                            </div>
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="card">
                                        <div className="card-header bg-transparent border-bottom">
                                            <h6 className="card-title">
                                                <i className="icon-user-tie mr-2" />
                                                Listado de usuarios
                                            </h6>
                                        </div>
                                        <div className="card-body">
                                            <div className="border p-3 rounded">
                                                {this.state.user.map((item, index) => (
                                                    <div key={index}>
                                                        <div className="custom-control custom-radio mb-2">
                                                            <input type="radio" value={(item.id + "&" + item.nombre + "&" + item.apellido_paterno + "&" + item.apellido_materno)} className="custom-control-input" name="cr-l" id={"cr_l_s_s" + item.id} onChange={this.handleChangeSelectUser} />
                                                            <label className="custom-control-label" htmlFor={"cr_l_s_s" + item.id}>{item.nombre + " " + item.apellido_paterno + " " + item.apellido_materno}</label>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="card">
                                        <div className="card-header bg-transparent border-bottom">
                                            <h6 className="card-title">
                                                <i className="icon-office ml-2" />
                                                &nbsp; &nbsp; Listado de empresas
                                            </h6>
                                        </div>
                                        <div className="card-body">
                                            <div className="border p-3 rounded">
                                                {this.state.company.map((item, index) => (
                                                    <div key={index}>
                                                        <div className="custom-control custom-checkbox mb-2">
                                                            <input type="checkbox" className="custom-control-input" id={"cc_ls" + item.id} value={(item.id + "&" + item.nombre)} onChange={this.handleChangeSelectCompany} />
                                                            <label className="custom-control-label" htmlFor={"cc_ls" + item.id}>{item.nombre}</label>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="card">
                                        <div className="card-header bg-transparent border-bottom">
                                            <h6 className="card-title">
                                                <i className="icon-collaboration ml-2" />
                                                &nbsp; &nbsp;Grupo de: {this.state.namegroup}
                                            </h6>
                                        </div>
                                        <div className="card-body">
                                            <div className="border p-3 rounded">
                                                <ul className="media-list">
                                                    {this.state.userCompany.map((item, index) => (
                                                        <div key={index}>
                                                            <li className="media">
                                                                <div className="mr-3">
                                                                    <div className="d-flex align-items-center">
                                                                        <i className="icon-home8 mr-3 text-warning" style={{ fontSize: 20 }} />
                                                                    </div>
                                                                </div>
                                                                <div className="media-body">
                                                                    <div className="media-title font-weight-semibold">{item.nombre}</div>
                                                                </div>
                                                                <div className="align-self-center ml-3" style={{ cursor: 'pointer' }}>
                                                                    <div className="list-icons list-icons-extended">
                                                                        <a onClick={() => this.handledelete(item)}><i className="icon-trash-alt text-danger" /></a>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </div>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}


export default ConfigCompany;