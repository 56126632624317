import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'none',
        }
    },
    tableRightBorder: {
        backgroundColor: "#262d3c"
    },
});

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const [link, setLink] = React.useState('');
    const classes = useRowStyles();

    const handleChange = (p) => {
        let links = 'https://e-xpertum.com.mx/adminfile/api/ecb868faf29054fedf1ac6cbac5fc63bd54bba3f9d85ea1426942775518b90b6?query=' + p;
        props.modalpdf(links);
    };
    const deleteAdmin = (i) => {
        props.functiondelete(i)
    }
    const _close_modal_ = () => {
        setLink('');
    }
    return (
        <React.Fragment>
            <tr className={classes.root}
                hover
                tabIndex={-1}>
                <th>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </th>
                <th style={{ color: 'white' }} component="th" scope="row">{row.F + ' ' + row.G + ' ' + row.H}</th>
                <th style={{ color: 'white' }} align="center">{row.D}</th>
                <th style={{ color: 'white' }} align="center">{row.E}</th>
                <th style={{ color: 'white' }} align="center">{row.I}</th>
                <th><button type="button" class="btn btn-light" onClick={() => handleChange((row.path === undefined ? '' : row.path))}><i class="icon-file-pdf mr-2 text-warning"></i> PDF</button></th>
                <th><button type="button" class="btn btn-light btn-icon rounded-pill" onClick={() => deleteAdmin(row.C)}><i class="icon-cross text-warning"></i></button></th>
            </tr>
            <tr>
                <td style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <span style={{ color: 'white', fontSize: 14 }}>Información complementaria</span>
                            <table className="table">
                                <tr style={{ color: '#43a76f' }}>
                                    <td>NSS</td>
                                    <td>Cuota diaria</td>
                                    <td>Puesto</td>
                                    <td colSpan={2}>Actividad</td>
                                </tr>
                                <tr>
                                    <td style={{ color: '#c76932' }}>{row.O}</td>
                                    <td style={{ color: '#c76932' }}>{row.S}</td>
                                    <td style={{ color: '#c76932' }}>{row.Z}</td>
                                    <td colSpan={2} style={{ color: '#c76932' }}>{row.BD}</td>
                                </tr>
                                <tr>
                                    <td style={{ color: '#43a76f' }} align="left">Domicilio</td>
                                    <td style={{ color: '#43a76f' }} align="center">Correo electrónico</td>
                                    <td style={{ color: '#43a76f' }} align="left">Beneficiario</td>
                                    <td style={{ color: '#43a76f' }} align="left">Genero</td>
                                    <td style={{ color: '#43a76f' }} align="left">Escolaridad</td>
                                </tr>
                                <tr>
                                    <td style={{ color: '#c76932' }}>{row.AB + '' + row.AC + '' + row.AD + ', ' + row.AF}</td>
                                    <td style={{ color: '#c76932' }}>{row.AS}</td>
                                    <td style={{ color: '#c76932' }}>{row.AT}</td>
                                    <td style={{ color: '#c76932' }}>{row.AZ}</td>
                                    <td style={{ color: '#c76932' }}>{row.AA}</td>
                                </tr>
                            </table>

                        </Box>
                    </Collapse>
                </td>
            </tr>

        </React.Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        D: PropTypes.string.isRequired,
        E: PropTypes.string.isRequired,
        F: PropTypes.string.isRequired,
        C: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
    }).isRequired,
};

export default function CollapsibleTable(props) {
    const classes = useRowStyles();
    let rows = (props.dt);
    const [clave, setClave] = React.useState('');
    const confirmdetele = (t) => {
        setClave(t);
        window.$("#modalmini").modal("show");
    }
    const _afirm_delete_ = () => {
        props.delete(clave);
        window.$("#modalmini").modal("hide");
    }
    const modal_View = (t) => {
        props.modalpdf(t);
    }
    return (
        <TableContainer component={Paper} className={classes.tableRightBorder}>
            <table aria-label="collapsible table" className="table">
                <thead>
                    <tr>
                        <th></th>
                        <th style={{ color: 'white' }}>Nombre completo</th>
                        <th style={{ color: 'white' }} align="center">RFC</th>
                        <th style={{ color: 'white' }} align="center">Curp</th>
                        <th style={{ color: 'white' }} align="center">Estado civil</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {(rows || []).map((row) => (
                        <Row key={row.D} row={row} functiondelete={confirmdetele} modalpdf={modal_View} />
                    ))}
                </tbody>
            </table>
            <div id="modalmini" className="modal fade" tabIndex={-1} style={{ display: 'none' }} aria-hidden="true">
                <div className="modal-dialog modal-xs">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" style={{ color: 'white' }}>Confirmación de eliminación</h5>
                            <button type="button" className="close" data-dismiss="modal" style={{ color: 'white' }}>×</button>
                        </div>
                        <div className="modal-body" style={{ color: 'white' }}>
                            <p>Está seguro de eliminar el registro?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-link" data-dismiss="modal">Cancelar</button>
                            <button type="button" className="btn btn-primary" onClick={_afirm_delete_}>Aceptar</button>
                        </div>
                    </div>
                </div>
            </div>
        </TableContainer>
    );
}
