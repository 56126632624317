import React from "react";
export default function TableComponent({ data }) {
    var result = (data.length > 0 ? data : data);
    if (result !== undefined) {
        return (
            <>
                <div className="card-body">
                    <div className="col-md-6">
                        <label className="col-form-label">Nombre(s): {result.nombre}</label>
                    </div>
                    <div className="col-md-6">
                        <label className="col-form-label">Apellido(s): {result.apellido_paterno} {result.apellido_materno}</label>
                    </div>
                    <div className="col-md-6">
                        <label className="col-form-label">Teléfono: {result.telefono}</label>
                    </div>
                    <div className="col-md-6">
                        <label className="col-form-label">Correo electrónico: {result.correo}</label>
                    </div>
                </div>
            </>
        );
    }
    else
        return (
            <h1>Cargando.......</h1>
        )
}
