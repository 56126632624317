import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import api from '../../Config/api';
import ReactDatatable from '@ashvin27/react-datatable';
class ListAdminCompany extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "razon_social",
                text: "Nombre, Denominación o razón social",
                className: "razon_social",
                align: "left",
                sortable: true,
            },
            {
                key: "rfc",
                text: "RFC",
                className: "rfc",
                align: "left",
                sortable: true
            },
            {
                key: "fecha_inicio",
                text: "Fecha de inicio",
                className: "fecha_inicio",
                sortable: true
            }, {
                key: "fecha_fin",
                text: "Fecha fin",
                className: "fecha_fin",
                sortable: true
            }
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,

            language: {
                length_menu: "Mostrar _MENU_ registros por página",
                filter: "Buscar en registros ...",
                info: "Mostrando _START_ a _END_ de _TOTAL_ entradas",
                pagination: {
                    first: "Primero",
                    previous: "Previa",
                    next: "próximo",
                    last: "Última"
                }
            }
        }

        this.state = {
            data: [],
            details: ''
        }
    }
    componentDidMount() {
        console.log('00000000')
        var datainfo = JSON.parse(localStorage.getItem("panelProduct"));
        var object = {
            id: datainfo.id
        }
        api.post('listaempresassubempresas', object).then((res) => {
            console.log(res.data)
            this.setState({ data: res.data });
        }).catch(function (error) {
            console.log(error);
        });
    }
    handleclearcache = () => {
        this.setState({ details: '' })
    }
    handleViewModal = (info) => {
        /*this.setState({
            details: <ModalDetalle value={{ data: info, clear: this.handleclearcache }} />
        });*/
    }
    render() {
        return (
            <>
                <div className="content-wrapper">
                    <div className="page-header border-bottom-0">
                        <div className="page-header-content header-elements-md-inline">
                            <div className="page-title d-flex">
                                <Link to="/app/dashboard" style={{ color: 'white' }}><h4><i className="icon-arrow-left52 mr-2"></i> <span className="font-weight-semibold">Panel</span> </h4></Link>
                            </div>
                        </div>
                    </div>
                    <div className="content pt-0">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header bg-transparent border-bottom">
                                        <h6 className="card-title"> <i className="icon-cog3 mr-2"></i> Listado de empresas con administración de archivos</h6>
                                    </div>
                                    <div className="card-body">
                                        <ReactDatatable
                                            config={this.config}
                                            records={this.state.data}
                                            columns={this.columns}
                                        />
                                        {/*<table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Nombre / Razón social</th>
                                                    <th>RFC</th>
                                                    <th>Fecha Inicio</th>
                                                    <th>Fecha Fin</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(this.state.data || []).map((dt, id) => (
                                                    <tr key={id}>
                                                        <td>{dt.razon_social}</td>
                                                        <td>{dt.rfc}</td>
                                                        <td>{dt.fecha_inicio}</td>
                                                        <td>{dt.fecha_fin}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ListAdminCompany;