import React, { Component } from 'react'
import api from '../../Config/audit-data';
class Statement_changes_capital extends Component {
    constructor(props) {
        super(props);

        let objects = JSON.parse(localStorage.getItem("datacompanyall"));
        this.state = {
            empresa_id: objects.id,
            data: []
        }
    }
    componentDidMount() {
        this.get_cash_flow();
    }
    get_cash_flow = async () => {
        let _data = await api.post('get-report-annual-clousure', { empresa_id: this.state.empresa_id });
        let _data_ = await _data.data;
        this.setState({
            data: _data_.jsonData_capital_contable
        });
    }
    render() {
        return (
            <>
                <div className="content pt-0">
                    <div className="card">
                        <div className="card-header bg-transparent border-bottom header-elements-inline py-0">
                            <h6 className="card-title py-3">
                                Estado de cambios en el capital contable
                            </h6>
                            <div className="header-elements">
                                <div>
                                    <button type="button" className="btn btn-light" style={{ margin: '0 10px' }}
                                        onClick={() => {
                                            window.open("https://e-xpertum.com.mx/invoces/api/export-to-excel-data/" + this.state.empresa_id + "/ES");
                                        }}
                                    ><i className="icon-file-excel mr-2 text-success"></i> Excel</button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="content" style={{ width: "100%" }}>
                                <div className='wrapper' style={{ marginLeft: "300px" }}>
                                    <table className='table' style={{ width: "2000px" }}>
                                        <tbody>
                                            {
                                                (this.state.data || []).map((tr, index) => (
                                                    index > 1 ?
                                                        (<tr key={index}>
                                                            <td className='fixed' style={{ left: "0px", height: (String(tr.descripcion).length > 50 ? "100px" : "70px") }}>{tr.descripcion}</td>
                                                            <td className='text-right'>{tr.capital_social != "" ? ((!isNaN(tr.capital_social)) ? parseFloat(tr.capital_social).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : tr.capital_social) : ""}</td>
                                                            <td className='text-right'>{tr.utilidad_neta != "" ? ((!isNaN(tr.utilidad_neta)) ? parseFloat(tr.utilidad_neta).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : tr.utilidad_neta) : ""}</td>
                                                            <td className='text-right'>{tr.utilidades_acumuladas != "" ? ((!isNaN(tr.utilidades_acumuladas)) ? parseFloat(tr.utilidades_acumuladas).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : tr.utilidades_acumuladas) : ""}</td>
                                                            <td className='text-right'>{tr.perdida_neta != "" ? ((!isNaN(tr.perdida_neta)) ? parseFloat(tr.perdida_neta).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : tr.perdida_neta) : ""}</td>
                                                            <td className='text-right'>{tr.perdidas_acumuladas != "" ? ((!isNaN(tr.perdidas_acumuladas)) ? parseFloat(tr.perdidas_acumuladas).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : tr.perdidas_acumuladas) : ""}</td>
                                                            <td className='text-right'>{tr.resultado_por_conversion_de_operaciones_extranjeras != "" ? ((!isNaN(tr.resultado_por_conversion_de_operaciones_extranjeras)) ? parseFloat(tr.resultado_por_conversion_de_operaciones_extranjeras).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : tr.resultado_por_conversion_de_operaciones_extranjeras) : ""}</td>
                                                            <td className='text-right'>{tr.valuacion_de_coberturas_de_flujos_de_efectivo != "" ? ((!isNaN(tr.valuacion_de_coberturas_de_flujos_de_efectivo)) ? parseFloat(tr.valuacion_de_coberturas_de_flujos_de_efectivo).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : tr.valuacion_de_coberturas_de_flujos_de_efectivo) : ""}</td>
                                                            <td className='text-right'>{tr.participacion_en_los_ORI_de_asociadas != "" ? ((!isNaN(tr.participacion_en_los_ORI_de_asociadas)) ? parseFloat(tr.participacion_en_los_ORI_de_asociadas).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : tr.participacion_en_los_ORI_de_asociadas) : ""}</td>
                                                            <td className='text-right'>{tr.total_participacion_de_la_controladora != "" ? ((!isNaN(tr.total_participacion_de_la_controladora)) ? parseFloat(tr.total_participacion_de_la_controladora).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : tr.total_participacion_de_la_controladora) : ""}</td>
                                                            <td className='text-right'>{tr.participacion_de_la_no_controladora != "" ? ((!isNaN(tr.participacion_de_la_no_controladora)) ? parseFloat(tr.participacion_de_la_no_controladora).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : tr.participacion_de_la_no_controladora) : ""}</td>
                                                            <td className='text-right'>{tr.total_de_capital_contable != "" ? ((!isNaN(tr.total_de_capital_contable)) ? parseFloat(tr.total_de_capital_contable).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : tr.total_de_capital_contable) : ""}</td>
                                                        </tr>) : null

                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default Statement_changes_capital;
