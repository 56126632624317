import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions, } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom';

import ModalFiles from './newcompany';
import NewEstructureCompany from '../../../client/Dashboard/validador_/newcreatecompany';
import ValidateRFC from './validate';
import api from '../../../app/Config/api';

/**********
 * style para listado de empresas
 */

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    }, backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function CustomizedTables() {
    const [aceptoterminos] = useState(localStorage.getItem("terminosycondiciones"));
    const classes = useStyles();
    const history = useHistory();
    const [infos, setData] = useState([]);
    const [container, setInfoContaniner] = useState('');
    const _data_ = JSON.parse(localStorage.getItem("id_token"));
    const [userData, setUserAdd] = useState(_data_);
    const [open, setOpen] = React.useState(false);
    const [modaleliminar, setDelete] = React.useState(false);
    const [nombreempresa, setNombreEmpresa] = React.useState({});
    const [validation, setValidation] = React.useState('');
    async function getData() {
        var objec = JSON.parse(localStorage.getItem('id_token'));

        var info = {
            cliente_id: _data_.cliente_id,
            clave: '4000', objec
        }
        const response = await api.post('listcompanysistemalerts', info)
        var data = await response.data;
        setData(data);
        if (data.length === 0) {
            windowtoAddNewCompany();
        }

        setOpen(false);
    }
    useEffect(() => {
        setOpen(true);
        if (aceptoterminos === 'true')
            getData();
        else
            setOpen(false);
    }, []);
    function informacionObtenida(i) {
        getData();
        var objec = JSON.parse(localStorage.getItem('id_token'));
        var obje = {
            query: "SELECT numero_empresa FROM activaciones where cliente_id='" + objec.cliente_id + "'"
        }
        api.post('configtabledinamyc', obje).then((r) => r.data)
            .then((j) => {
                localStorage.removeItem("_numero_empresas_");
                var contador = 0;
                for (let po = 0; po < j.length; po++) {
                    contador = contador + parseInt(j[po].numero_empresa)
                }
                localStorage.setItem("_numero_empresas_", contador);
            }).catch((u) => {
                console.log(u)
            })
    }
    function windowtoAddNewCompany() {
        setInfoContaniner('');
        setInfoContaniner(<NewEstructureCompany informacion={{ infos, informacionObtenida }} />)
    }
    function modalDinamyc(i) {
        setInfoContaniner('');
        var datainfo = {
            status: true,
            datos: i,
            //clear: setInfoContaniner
        }
        localStorage.removeItem("datavalidate");
        localStorage.setItem("datavalidate", JSON.stringify(i));
        history.push("/app/ui/filesimport");
    }
    function modalDinamycReport(i) {
        setInfoContaniner('');
        var datainfo = {
            status: true,
            datos: i,
            //clear: setInfoContaniner
        }
        localStorage.removeItem("datavalidate");
        localStorage.setItem("datavalidate", JSON.stringify(i));
        history.push("/app/ui/reportvalidate");
    }
    function handlerDelete(j) {
        setNombreEmpresa(j)
        window.$("#modal_small").modal("show");
        // setDelete(true);
    }
    function handlerCloseModal() {
        setDelete(false);
    }
    function handleConfirmacionDelete() {
        var object = {
            id: parseInt(nombreempresa.id)
        }
        api.post('deleteconfirmcompany', object).then(r => {

            getData();
            window.$("#modal_small").modal("hide");
            //setDelete(false);
        });
    }
    function viewprovedors(params) {
        localStorage.removeItem("datavalidate");
        localStorage.setItem("datavalidate", JSON.stringify(params));
        history.push("/app/catalogs/proveedores");
    }
    const viewmodalValidation = () => {
        setValidation(<ValidateRFC />);
        window.$("#viewmodalvalidate").modal("show");
    }
    const closemodalvalidate = () => {
        setValidation('');
    }
    const { perfil } = userData;
    return (

        <>
            <Backdrop className={classes.backdrop} open={open} >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="page-header border-bottom-0">
                <div className="page-header-content header-elements-md-inline">
                    <div className="page-title d-flex">
                        <a href="#" style={{ color: 'white' }}> <h4><i className="icon-arrow-left52 mr-2"></i> <span className="font-weight-semibold">Inicio</span></h4></a>
                        <a href="#" className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></a>
                    </div>
                    <div className="header-elements d-none mb-3 mb-md-0">
                        <div className="d-flex justify-content-center">
                            <a href="https://e-xpertum.com.mx/webadmin/api/documents/api/help/MANUAL_E_XPERTUM_VALIDACION69B_V1.pdf" target="_blank" className="btn btn-link btn-float text-default"><i className="icon-help"></i><span>Instructivo</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content pt-0">
                <div className="card">
                    <div className="card-header">
                        <h5 className="card-title" style={{ textAlign: 'center' }}>Validación 69B</h5>
                    </div>
                    {
                        (aceptoterminos === 'false') ? (
                            <>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="alert alert-warning alert-styled-right alert-dismissible">
                                                    <button type="button" className="close" data-dismiss="alert"><span>×</span></button>
                                                    <span className="font-weight-semibold">Usuario!</span> Debe de aceptar los términos y condiciones para poder utilizar los servicios. <span className="font-weight-semibold"> Solución:</span> click en inicio y acepte los términos.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </>
                        ) : (
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="text-left">Razón social / Nombre</th>
                                        <th className="text-center">Propiedades</th>
                                        <th className="text-center">Reporte</th>
                                        <th></th>
                                        <th>
                                            <button type="button" className="btn btn-light" onClick={viewmodalValidation}>Validar RFC <i className="icon-office ml-2"></i></button>
                                        </th>
                                        <th className="text-center">
                                            {
                                                (localStorage.getItem("_numero_empresas_") > infos.length) ?
                                                    (<ModalFiles informacion={{ infos, informacionObtenida }} />)
                                                    : null
                                            }
                                            <Tooltip placement="top" arrow title={
                                                <>
                                                    <ul>
                                                        <li>Paso 1. Al ingresar su constancia fiscal el sistema automáticamente registra su empresa abajo.</li>
                                                        <li>Paso 2 Registrar datos (botón azul) </li>
                                                        <li>Paso 3 Para consultas posteriores puede ir directo al reporte resultados. Oprime REPORTE.</li>
                                                    </ul>
                                                </>
                                            } style={{ float: 'right' }}><a target="_blank"><i className="icon-help text-warning"></i></a></Tooltip>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(infos || []).map((row) => (
                                        <tr key={row.nombre}>
                                            <td> {row.nombre}</td>
                                            <td className="text-center">
                                                <button type="button" className="btn btn-primary" onClick={() => modalDinamyc(row)}><i className="icon-cogs"></i>&nbsp;&nbsp; Configuración </button>
                                            </td>
                                            <td className="text-center">
                                                <button type="button" className="btn btn-primary" onClick={() => modalDinamycReport(row)}><i className="icon-list3"></i>&nbsp;&nbsp; Reporte
                                                    {
                                                        (row.list > 0) ?
                                                            (
                                                                <>
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="badge badge-danger badge-pill">{row.list}</span>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="badge badge-primary badge-pill" style={{ color: '#2196f3' }}>{row.list}</span>
                                                                </>
                                                            )
                                                    }

                                                </button>
                                            </td>
                                            <td>
                                                {
                                                    (row.validacion_ === "SUCCESS") ? (
                                                        <span className="badge badge-success d-block">Finalizado</span>
                                                    ) : (
                                                        (row.validacion_ === "ERROR") ?
                                                            (
                                                                <span className="badge badge-danger d-block">Error en la efirma</span>
                                                            ) :
                                                            (
                                                                (row.validacion_ === "NOTEFIRMA") ?
                                                                    (
                                                                        <span className="badge badge-warning d-block">Sin efirma</span>
                                                                    ) :
                                                                    (
                                                                        (row.validacion_ === "NOTCONFIG") ?
                                                                            (<span className="badge badge-danger d-block">Sin configuración</span>) :
                                                                            (<span className="badge badge-info d-block">Proceso</span>)
                                                                    )
                                                            )
                                                    )
                                                }
                                            </td>
                                            <td>
                                                {
                                                    (row.validacion_ === "SUCCESS") ? (
                                                        <button type="button" className="btn btn-light btn-sm" onClick={() => viewprovedors(row)}>
                                                            <i className="icon-person mr-2"></i> Proveedores
                                                        </button>) : null
                                                }
                                            </td>
                                            <td className="text-center">
                                                {
                                                    perfil !== "CAPTURISTA" ? (
                                                        <Tooltip onClick={() => handlerDelete(row)} title="Eliminar empresa" placement="top" arrow>
                                                            <i style={{ cursor: 'pointer' }} className="icon-trash text-danger"></i>
                                                        </Tooltip>
                                                    ) : null
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    {container}
                    <div id="modal_small" className="modal fade" tabIndex="-1" aria-hidden="true" style={{ "display": "none" }}>
                        <div className="modal-dialog modal-sm">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Confirmación de eliminación</h5>
                                    <button type="button" className="close" data-dismiss="modal">×</button>
                                </div>

                                <div className="modal-body">
                                    <p>Está seguro de eliminar el siguiente registro {nombreempresa.nombre}?</p>
                                </div>

                                <div className="modal-footer">
                                    <button type="button" className="btn btn-link" data-dismiss="modal">Cancelar</button>
                                    <button type="button" className="btn bg-primary" onClick={handleConfirmacionDelete} >Aceptar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="viewmodalvalidate" className="modal fade" tabIndex="-1" aria-hidden="true" style={{ "display": "none" }}>
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Validar el rfc</h5>
                                    <button type="button" className="close" data-dismiss="modal" onClick={closemodalvalidate}>×</button>
                                </div>

                                <div className="modal-body">
                                    {validation}
                                </div>

                                <div className="modal-footer">
                                    <button type="button" className="btn btn-link" onClick={closemodalvalidate} data-dismiss="modal">Cerrar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Dialog open={modaleliminar} onClose={handlerCloseModal} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                        <DialogTitle id="alert-dialog-title">{"Confirmación de eliminación"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description" style={{ color: 'white' }}>
                                Está seguro de eliminar el siguiente registro {nombreempresa.nombre}?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <button onClick={handlerCloseModal} className="btn btn-info">Cancelar</button>
                            <button onClick={handleConfirmacionDelete} className="btn btn-primary" autoFocus>Aceptar</button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </>
    );
}