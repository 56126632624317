import React, { Component } from 'react';
import FilesExtension from './extensions/extencion-files';
import Extens from './extensions/ext';
import ExtensionEnd from './extensions/ext-list';
//import axios from 'axios';
import axios from '../../../app/Config/apifiles';
import { Link } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
/*************************
 * view docs
 */
import PDF from './viewfiles/pdf';
import Progress from './viewfiles/progress';
import IMG from './viewfiles/img';
//import api from '../../../app/Config/nomina';
//import EXCEL from './viewfiles/progress';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
var array_data = [];
function bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}
function dia() {
    let arraydys = []
    for (let index = 1; index < 32; index++) {
        arraydys.push({ dia: index });
    }
    return arraydys;
}
function formatDate(date) {
    date = new Date(date);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    var perido_ = (date.getMonth() + 1) < 10 ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1);
    var dia_ = date.getDate() < 10 ? ('0' + date.getDate()) : date.getDate();
    return dia_ + "-" + perido_ + "-" + date.getFullYear() + "  " + strTime;
}
class Admin extends Component {
    constructor(props) {
        super(props);
        var objectData = JSON.parse(localStorage.getItem("id_token"));

        this.state = {
            data: [],
            breadcrumb: [],
            nombre_directory: '',
            nombre_directorysecond: '',
            directoryfind: [],
            directoryfindsecond: [],
            directoryraiz: true,
            directoryraizsecond: false,
            directoryimport: '',
            viewloading: false,
            open: false,
            openerror: false,
            nombrecarpeta: '',
            statusview: false,
            pro_name: '',
            pro_size: 0,
            pro_fecha: '',
            pro_fecha_actua: '',
            ext: '',
            vertical: 'top',
            horizontal: 'right',
            mensaje: '',
            rename_directory: '',
            level: 0,
            validateext: '',
            dataview: {},
            arraylist: [],
            email: '',
            emailError: false,
            comments: '',
            sizeutilized: 0,
            sizeaquid: 24000000,
            usuario_id: objectData.id,
            cliente_id: objectData.cliente_id,
            usuario: objectData.usuario,
            loading: true,
            aceptoterminos: true,
            alertCompartir: false,
            arraydyas: dia(),
            wekeends: [{ spanish: 'Lunes', inglish: 'Monday' }, { spanish: 'Martes', inglish: 'Tuesday' }, { spanish: 'Miércoles', inglish: 'Wednesday' }, { spanish: 'Jueves', inglish: 'Thursday' },
            { spanish: 'Viernes', inglish: 'Friday' }, { spanish: 'Sábado', inglish: 'Saturday' }],
            questionyes: '', questionwekend: '', questionmonth: '',
            questionnot: '',
            firtsquestion: '',
            secondquestion: '',
            questionday: '',
            arrayfolderload: [],
            typeoder: 'CUADRO',
            typedelete: '',
            identificador_: 0,
            respuestanotificacion: ''
        }
        this.handleValidateExtFile = this.handleValidateExtFile.bind(this);
    }
    componentDidMount() {
        this.getfolderdata();
        this.getdataall();
    }
    getfolderdata = async () => {
        let folderdata = await axios.post('/listcarpetas', { cliente_id: this.state.cliente_id });
        this.setState({ arrayfolderload: folderdata.data.folder, typeoder: (folderdata.data.list).tipo });

    }
    getdataall = async () => {
        var _rsestatus_ = localStorage.getItem("terminosycondiciones");
        if (_rsestatus_ === 'true') {

            let res = await axios.post('/tree', { rfc_user: this.state.cliente_id, usuario_id: this.state.usuario_id, usuario: this.state.usuario });

            let arraytreeviewfiles = res.data.children;
            console.log(arraytreeviewfiles)
            let docsdatatable = this.state.arrayfolderload;
            for (let k = 0; k < arraytreeviewfiles.length; k++) {
                let filterdocs = (docsdatatable.filter((j) => j.carpeta === arraytreeviewfiles[k].name && j.directorio === arraytreeviewfiles[k].path));
                if (filterdocs.length > 0) {
                    arraytreeviewfiles[k].notificacion = "ok";
                    arraytreeviewfiles[k].respuesta = filterdocs[0].notificacion;
                    arraytreeviewfiles[k].id = filterdocs[0].id;
                    //console.log(filterdocs)
                }
            }
            localStorage.removeItem("initdirecoty");
            var resultutilizado = parseInt((bytesToSize((res.data).size)).replace('Bytes', '').replace('KB', '').replace('MB', '').replace('GB', '').replace('TB', '').trim().trimRight().trimEnd());
            this.setState({ aceptoterminos: false, sizeutilized: resultutilizado, nombre_directory: res.data.name, data: arraytreeviewfiles, directoryimport: res.data.path, loading: false })
            localStorage.setItem("initdirecoty", res.data.path);
            array_data = [];
        }
        else
            this.setState({ loading: false })
    }
    handleChangeDirectory = (directory) => {
        var count = array_data.length;
        this.setState({ loading: true, });
        axios.post("/navegationUrl", { path: directory.path + "/" }).then((t) => t.data)
            .then((k) => {
                // console.log(k)
                array_data.push({
                    name: k.name,
                    path: k.path,
                    mtime: k.mtime,
                    size: k.size,
                    type: k.type,
                    children: k.children,
                    contador: (count === 0 ? 1 : count + 1)
                });
                let arraytreeviewfiles = k.children;//validacionimportacion.dir.children;
                let docsdatatable = this.state.arrayfolderload;
                for (let k = 0; k < arraytreeviewfiles.length; k++) {
                    let filterdocs = (docsdatatable.filter((j) => j.carpeta === arraytreeviewfiles[k].name && j.directorio === arraytreeviewfiles[k].path));
                    if (filterdocs.length > 0) {
                        arraytreeviewfiles[k].notificacion = "ok";
                        arraytreeviewfiles[k].respuesta = filterdocs[0].notificacion;
                        arraytreeviewfiles[k].id = filterdocs[0].id;
                    }
                }
                this.setState({
                    loading: false,
                    directoryimport: k.path,
                    directoryfind: arraytreeviewfiles,
                    breadcrumb: array_data,
                    directoryraiz: false,
                    directoryraizsecond: true,
                    level: (count === 0 ? 1 : count + 1)
                });
            }).catch((i) => {
                console.log(i)
            });
    }
    handleChangeHome = () => {
        array_data = [];
        this.setState({
            directoryfind: [],
            directoryraiz: true,
            directoryraizsecond: false,
            directoryimport: localStorage.getItem("initdirecoty"),
        });
        this.componentDidMount();
    }

    handleChangeFilterData = (m) => {
        this.setState({ loading: true, });
        axios.post("/navegationUrl", { path: m.path }).then((t) => t.data)
            .then((k) => {

                var _iteracion_resulta_ = [];
                for (let index = 0; index < array_data.length; index++) {
                    if (array_data[index].contador <= m.contador) {
                        _iteracion_resulta_.push(array_data[index])
                    }
                }
                setTimeout(() => {
                    array_data = _iteracion_resulta_;
                    this.setState({
                        loading: false,
                        directoryfind: k.children,
                        breadcrumb: array_data,
                        directoryraiz: false,
                        directoryraizsecond: true
                    });
                }, 200);
            }
            );
    }
    handleChangeFile = (e) => {
        const file = e.target.files;

        this.setState({ viewloading: true });
        const formData = new FormData();
        for (let x = 0; x < file.length; x++) {
            formData.append(`file[${x}]`, file[x]);
        }
        ///formData.append('file', file)
        formData.append('directory', this.state.directoryimport)
        formData.append('level', this.state.level)
        formData.append('cliente_id', this.state.cliente_id)
        axios.post('/upload-admin-files-array', formData).then((e) => {
            var validacionimportacion = e.data;
            if (validacionimportacion.status === 200) {
                if (array_data.length === 0)
                    this.setState({ open: true, viewloading: false, mensaje: validacionimportacion.messaje, data: validacionimportacion.dir.children, });
                else {
                    let arraytreeviewfiles = validacionimportacion.dir.children; //k.children;//validacionimportacion.dir.children;
                    let docsdatatable = this.state.arrayfolderload;
                    for (let k = 0; k < arraytreeviewfiles.length; k++) {
                        let filterdocs = (docsdatatable.filter((j) => j.carpeta === arraytreeviewfiles[k].name && j.directorio === arraytreeviewfiles[k].path));
                        if (filterdocs.length > 0) {
                            arraytreeviewfiles[k].notificacion = "ok";
                            arraytreeviewfiles[k].respuesta = filterdocs[0].notificacion;
                            arraytreeviewfiles[k].id = filterdocs[0].id;
                        }
                    }
                    this.setState({
                        open: true, viewloading: false,
                        mensaje: validacionimportacion.messaje, directoryfind: arraytreeviewfiles//validacionimportacion.dir.children,
                    });
                }

                window.$('#modalsmallrename').modal('hide');
            }
            else if (validacionimportacion.status === 204)
                this.setState({ openerror: true, mensaje: validacionimportacion.messaje, viewloading: false });

        }).catch((ex) => {
            console.log(ex)
        });

    }
    handleClose = () => {
        window.$('#modal_form_vertical').modal('hide');
    }
    handleProperties = (i) => {
        var nombrefile = i.name.split('.').shift();
        this.setState({
            pro_name: nombrefile, //i.name,
            pro_size: bytesToSize(i.size),
            pro_fecha: i.mtime,
            pro_fecha_actua: i.mtime,
            ext: (i.extension === undefined ? '' : i.extension),
            rename_directory: i.path,
            identificador_: (i.id === undefined ? 0 : i.id)
        })
    }
    handleConfirmRenameFilesFolder = () => {
        var object_ = {
            directory: this.state.directoryimport,
            dir: this.state.rename_directory,
            name: this.state.pro_name + this.state.ext,
            id: this.state.identificador_
        }

        axios.post('/renamedirectoryadmin', object_).then((e) => {
            this.getfolderdata();
            var validacionimportacion = e.data;
            if (validacionimportacion.status === 200) {
                if (array_data.length === 0) {
                    setTimeout(() => {
                        let arraytreeviewfiles = validacionimportacion.dir.children;
                        let docsdatatable = this.state.arrayfolderload;
                        for (let k = 0; k < arraytreeviewfiles.length; k++) {
                            let filterdocs = (docsdatatable.filter((j) => j.carpeta === arraytreeviewfiles[k].name && j.directorio === arraytreeviewfiles[k].path));
                            if (filterdocs.length > 0) {
                                arraytreeviewfiles[k].notificacion = "ok";
                                arraytreeviewfiles[k].respuesta = filterdocs[0].notificacion;
                                arraytreeviewfiles[k].id = filterdocs[0].id;
                            }
                        }
                        this.setState({
                            open: true, mensaje: validacionimportacion.messaje,
                            pro_name: '',
                            ext: '',
                            data: arraytreeviewfiles//validacionimportacion.dir.children,
                        });
                    }, 200);

                }
                else {
                    let arraytreeviewfiles = validacionimportacion.dir.children;
                    let docsdatatable = this.state.arrayfolderload;
                    for (let k = 0; k < arraytreeviewfiles.length; k++) {
                        let filterdocs = (docsdatatable.filter((j) => j.carpeta === arraytreeviewfiles[k].name && j.directorio === arraytreeviewfiles[k].path));
                        if (filterdocs.length > 0) {
                            arraytreeviewfiles[k].notificacion = "ok";
                            arraytreeviewfiles[k].respuesta = filterdocs[0].notificacion;
                            arraytreeviewfiles[k].id = filterdocs[0].id;
                        }
                    }
                    this.setState({
                        open: true, mensaje: validacionimportacion.messaje,
                        pro_name: '',
                        ext: '',
                        directoryfind: arraytreeviewfiles//validacionimportacion.dir.children,
                    });
                }

                window.$('#modalsmallrename').modal('hide');
            }
            else if (validacionimportacion.status === 204)
                this.setState({ openerror: true, mensaje: validacionimportacion.messaje });

        }).catch((ex) => {
            console.log(ex)
        })
    }
    handleChangeNameFolder = (e) => {
        this.setState({ pro_name: e.target.value })
    }
    handleSubmitCreateFolder = () => {
        if (this.state.pro_name === '') {
            this.setState({ openerror: true, mensaje: 'Debe de capturar el nombre de la carpeta!.' });
        }
        else {
            if (this.state.firtsquestion === 'SI' && (this.state.secondquestion === '' || this.state.questionday === ''))
                this.setState({ openerror: true, mensaje: 'Debe de seleccionar el dia o fecha de notificación.' });
            else {
                var object_ = {
                    directory: this.state.directoryimport + '/',
                    namefolder: this.state.pro_name,
                    cliente_id: this.state.cliente_id,
                    notificacion: this.state.firtsquestion,
                    tiponotificacion: this.state.secondquestion,
                    configuracionnotificacion: this.state.questionday,
                    dir: this.state.directoryimport
                }
                axios.post('/createdirectoryadmin', object_).then((e) => {
                    this.getfolderdata();
                    var validacionimportacion = e.data;
                    if (validacionimportacion.status === 200) {
                        if (array_data.length === 0) {
                            setTimeout(() => {
                                let arraytreeviewfiles = validacionimportacion.dir.children;
                                let docsdatatable = this.state.arrayfolderload;

                                for (let k = 0; k < arraytreeviewfiles.length; k++) {
                                    let filterdocs = (docsdatatable.filter((j) => j.carpeta === arraytreeviewfiles[k].name && j.directorio === arraytreeviewfiles[k].path));
                                    if (filterdocs.length > 0) {
                                        arraytreeviewfiles[k].notificacion = "ok";
                                        arraytreeviewfiles[k].respuesta = filterdocs[0].notificacion;
                                        arraytreeviewfiles[k].id = filterdocs[0].id;
                                    }
                                }
                                this.setState({
                                    open: true, pro_name: '',
                                    mensaje: validacionimportacion.messaje,
                                    data: arraytreeviewfiles,
                                    questionyes: '',
                                    questionwekend: '', questionmonth: '',
                                    questionnot: '',
                                    firtsquestion: '',
                                    secondquestion: '',
                                    questionday: '',
                                });
                            }, 900);

                        }
                        else
                            this.setState({
                                open: true, pro_name: '', mensaje: validacionimportacion.messaje, directoryfind: validacionimportacion.dir.children, questionyes: '', questionwekend: '', questionmonth: '',
                                questionnot: '',
                                firtsquestion: '',
                                secondquestion: '',
                                questionday: '',
                            });

                        window.$('#modal_form_vertical').modal('hide');
                    }
                    else if (validacionimportacion.status === 204)
                        this.setState({
                            openerror: true, mensaje: validacionimportacion.messaje, questionyes: '', questionwekend: '', questionmonth: '',
                            questionnot: '',
                            firtsquestion: '',
                            secondquestion: '',
                            questionday: '',
                        });

                }).catch((ex) => {
                    console.log(ex)
                })
            }
        }
    }
    handleCloseAlert = () => {
        this.setState({ open: false, openerror: false })
    }
    handleChangeDeleteFileFolder = (j) => {
        this.setState({
            nombre_directorysecond: j.path, identificador_: (j.id === undefined ? 0 : j.id),
            respuestanotificacion: j.respuesta, typedelete: j.type
        });
    }
    handleConfirmDelete = () => {
        let link = this.state.typedelete === "file" ? "/delete-directory-admin-files" : "/deletedirectoryadmin";
        var object_ = {
            directory: this.state.directoryimport,
            dir: this.state.nombre_directorysecond,
            id: this.state.identificador_
        }
        axios.post(link, object_).then((e) => {
            var validacionimportacion = e.data;
            if (validacionimportacion.status === 200) {
                if (array_data.length === 0) {
                    let arraytreeviewfiles = validacionimportacion.dir.children;
                    let docsdatatable = this.state.arrayfolderload;
                    for (let k = 0; k < arraytreeviewfiles.length; k++) {
                        let filterdocs = (docsdatatable.filter((j) => j.carpeta === arraytreeviewfiles[k].name && j.directorio === arraytreeviewfiles[k].path));
                        if (filterdocs.length > 0) {
                            arraytreeviewfiles[k].notificacion = "ok";
                            arraytreeviewfiles[k].respuesta = filterdocs[0].notificacion;
                            arraytreeviewfiles[k].id = filterdocs[0].id;
                        }
                    }
                    this.setState({
                        open: true, mensaje: validacionimportacion.messaje,
                        data: arraytreeviewfiles// validacionimportacion.dir.children,
                    });
                }
                else {
                    let arraytreeviewfiles = validacionimportacion.dir.children;
                    let docsdatatable = this.state.arrayfolderload;
                    for (let k = 0; k < arraytreeviewfiles.length; k++) {
                        let filterdocs = (docsdatatable.filter((j) => j.carpeta === arraytreeviewfiles[k].name && j.directorio === arraytreeviewfiles[k].path));
                        if (filterdocs.length > 0) {
                            arraytreeviewfiles[k].notificacion = "ok";
                            arraytreeviewfiles[k].respuesta = filterdocs[0].notificacion;
                            arraytreeviewfiles[k].id = filterdocs[0].id;
                        }
                    }
                    this.setState({
                        open: true, mensaje: validacionimportacion.messaje,
                        directoryfind: arraytreeviewfiles//validacionimportacion.dir.children,
                    });
                }

                window.$('#modalsmalldeletefile').modal('hide');
            }
            else if (validacionimportacion.status === 204)
                this.setState({ openerror: true, mensaje: validacionimportacion.messaje });

        }).catch((ex) => {
            console.log(ex)
        })
    }

    handleValidateExtFile = async (i) => {
        if (i.extension === ".pdf" || i.extension === '.png' || i.extension === '.jpg') {
            this.setState({ validateext: i.extension, dataview: i });
            window.$("#modal_scrollable").modal("show");
        }
        else {
            window.$("#modal_scrollable").modal("hide");
            if (i.type === "directory") {
                window.location.href = 'https://e-xpertum.com.mx/adminfile/api/downloadzip?query=' + JSON.stringify({ path: i.path, name: i.name });
            }
            else
                window.location.href = 'https://e-xpertum.com.mx/adminfile/api/download?query=' + JSON.stringify(i);
        }
    }

    handleValidateEmail = (i) => {
        if (i.target.name === "email") {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            const result = pattern.test(i.target.value);
            if (result === true) {
                this.setState({
                    emailError: false,
                    email: i.target.value
                })
            } else {
                this.setState({
                    emailError: true
                })
            }
        }
        else if (i.target.name === "descripcion") {
            this.setState({
                comments: i.target.value
            })
        }
    }
    handleShareSubmit(e) {
        e.preventDefault();
        if (this.refs.correoelectronico.value === '') {
            alert('Debe de agregar un correo valido');
        } else {
            this.setState(state => {
                const list = state.arraylist.push({
                    email: state.email,
                    usuario_id: this.state.usuario_id,
                    cliente_id: this.state.cliente_id
                });

                return {
                    list,
                    email: '',
                };
            });
        }
        this.refs.correoelectronico.value = '';
    }

    onRemoveItem = (i) => {
        var result = this.state.arraylist.filter(item => item.email !== i.email);
        this.setState({ arraylist: result });
    };

    handleSavesharefile = () => {
        var parameters = {
            directory: this.state.rename_directory,
            email: this.state.arraylist,
            comments: this.state.comments,
            k: this.state.pro_name
        }
        if (this.state.arraylist.length > 0) {
            //console.log(parameters)
            axios.post('sharefiles', parameters).then((f) => {
                if (f.data === "ok") {
                    this.setState({
                        comments: '',
                        arraylist: []
                    })
                    window.$("#modalshare").modal("hide");
                }
            }).catch((g) => {
                console.log(g)
            });
        }
        else {
            this.setState({ alertCompartir: true });
            setTimeout(() => {
                this.setState({ alertCompartir: false });
            }, 5000);
        }
        /*var parameters = {
            directory: this.state.rename_directory,
            email: this.state.arraylist,
            comments: this.state.comments
        }
        axios.post('/sharefiles', parameters).then((f) => {
           
            if (f.data === "ok") {
                $("#modalshare").modal("hide");
            }
        }).catch((g) => {
            console.log(g)
        });*/
    }
    handlechangequestions = (i) => {
        if (i.target.value === "SI")
            this.setState({ questionyes: 'checked', questionnot: '', firtsquestion: i.target.value });
        else if (i.target.value === "NO")
            this.setState({ questionyes: '', questionnot: 'checked', questionwekend: '', questionmonth: '', firtsquestion: i.target.value });
    }
    handleQuestionWekedMonth = (i) => {
        if (i.target.value === "SEMANAL")
            this.setState({ questionwekend: 'checked', questionmonth: '', secondquestion: i.target.value });
        else if (i.target.value === "DIA")
            this.setState({ questionwekend: '', questionmonth: 'checked', secondquestion: i.target.value });
    }
    handleselectdaynotification = (t) => {
        this.setState({ questionday: t.target.value });
    }
    handleupdatetypeviewlist = async (t) => {
        console.log(new Date())
        this.setState({ typeoder: t })
        let data = await axios.post("updatetypeview", { tipo: t, cliente_id: this.state.cliente_id });
        setTimeout(() => {
            console.log(data, this.state.typeoder, t)
        }, 300);

    }
    render() {
        const { vertical, horizontal } = this.state;
        return (
            <>
                <Backdrop open={this.state.loading} style={{ color: "#fff", background: "#B9B6B6", zIndex: 400, opacity: "0.5" }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div className="content-wrapper" style={{ overflowX: 'hidden' }}>
                    <div className="page-header border-bottom-0">
                        <div className="page-header-content header-elements-md-inline">
                            <div className="page-title d-flex">
                                <a href="#" style={{ color: "#fff" }}> <h4><i className="icon-arrow-left52 mr-2"></i> <span className="font-weight-semibold">Inicio</span></h4></a>
                                <Link to="#" className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></Link>
                            </div>
                            <h5 className="title" style={{ paddingLeft: '30%' }}>Administración de archivos</h5>

                            <div className="header-elements d-none mb-3 mb-md-0">
                                <div className="d-flex justify-content-center">
                                    <span to="#" className="btn btn-link btn-float text-default">

                                        <div className="d-flex align-items-center mb-1">Espacio utilizado<span className="text-muted ml-auto">&nbsp;&nbsp;&nbsp; {(this.state.sizeutilized / this.state.sizeaquid * 100).toFixed(2) + '%'} </span></div>
                                        <div className="progress" style={{ height: "0.475rem" }}>
                                            <Progress value={{ utilizado: ((this.state.sizeutilized / this.state.sizeaquid * 100)), porcentaje: 100, total: this.state.sizeaquid }} />
                                        </div>

                                    </span>
                                    <a href="https://e-xpertum.com.mx/webadmin/api/documents/api/help/MANUAL_E-XPERTUM_ADMINISTRADOR.pdf" rel='noreferrer' target="_blank" className="btn btn-link btn-float text-default"><i className="icon-help"></i><span>Instructivo</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.aceptoterminos ? (
                            <>
                                <div className="content pt-0">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h5 className="card-title" style={{ textAlign: 'center' }}>Administrador de Archivos</h5>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="alert alert-warning alert-styled-right alert-dismissible">
                                                                <button type="button" className="close" data-dismiss="alert"><span>×</span></button>
                                                                <span className="font-weight-semibold">Usuario!</span> Debe de aceptar los términos y condiciones para poder utilizar los servicios. <span className="font-weight-semibold"> Solución:</span> click en inicio y acepte los términos.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) :
                            (
                                <div className="content pt-0">
                                    <div className="row">
                                        <div className="col-md-12">
                                            {
                                                this.state.typeoder === "CUADRO" ? (
                                                    <div className="card">
                                                        <div className="mb-3 border-top-2 border-top-primary">
                                                            {this.state.viewloading ? (
                                                                <div className="card card-body border-top-danger text-center">
                                                                    <div>
                                                                        <div className="pace-demo bg-dark ml-2">
                                                                            <div className="theme_xbox_sm theme_xbox_with_text">
                                                                                <div className="pace_progress" data-progress-text="99%" data-progress="100"></div>
                                                                                <div className="pace_activity"></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) :
                                                                (
                                                                    this.state.directoryraiz ? (
                                                                        <div className="page-header page-header-light">
                                                                            <div className="card-header bg-transparent border-bottom header-elements-sm-inline">
                                                                                <h6 className="card-title" onClick={this.handleChangeHome}><i className="icon-home2 mr-2" style={{ fontSize: 21 }}></i> {this.state.nombre_directory}</h6>
                                                                                <div className="header-elements">
                                                                                    <ul className="list-inline list-inline-dotted mb-0">
                                                                                        <li className="list-inline-item" onClick={() => this.handleupdatetypeviewlist("CUADRO")}><i className="icon-grid6 mr-2" style={{ userSelect: 'none', cursor: 'pointer', fontSize: 21 }}></i></li>
                                                                                        <li className="list-inline-item" onClick={() => this.handleupdatetypeviewlist("LISTADO")}><i className="icon-list mr-2" style={{ userSelect: 'none', cursor: 'pointer', fontSize: 21 }}></i></li>
                                                                                        <li className="list-inline-item">
                                                                                            <label className="breadcrumb-elements-item" htmlFor="_upload_file_">
                                                                                                <div className="uniform-uploader" style={{ cursor: 'pointer', top: 5 }}>
                                                                                                    <i className="icon-folder-upload mr-2" style={{ userSelect: 'none', cursor: 'pointer', fontSize: 21 }}>
                                                                                                    </i>
                                                                                                    Subir archivos
                                                                                                </div>
                                                                                            </label>
                                                                                            <input type="file" id="_upload_file_" className="form-control-uniform-custom" style={{ display: 'none' }} multiple name="docs" onChange={this.handleChangeFile} />
                                                                                        </li>
                                                                                        <li className="list-inline-item" data-toggle="modal" data-target="#modal_form_vertical" style={{ cursor: 'pointer' }}>
                                                                                            <i className="icon-folder-plus2 mr-2" style={{ fontSize: 21 }}></i>Nueva carpeta</li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                            <div className="card-body">
                                                                                <div className="row">
                                                                                    {
                                                                                        (this.state.data || []).map((op, u) => (
                                                                                            <div className="col-sm-2 col-lg-2" key={u}>
                                                                                                <div className="card">
                                                                                                    {op.type === "directory" ? (
                                                                                                        <>
                                                                                                            <div className={(op.notificacion !== undefined && op.respuesta === "SI") ? "card" : ""}>
                                                                                                                <div className="card-header bg-transparent border-bottom header-elements-inline">
                                                                                                                    <h6 className="card-title" style={{ visibility: 'hidden' }}>Icon dropdown</h6>
                                                                                                                    <div className="header-elements">
                                                                                                                        <div className="list-icons">
                                                                                                                            <div className="dropdown">
                                                                                                                                <a className="list-icons-item dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                                                                                                                    <i className="icon-menu7"></i></a>
                                                                                                                                <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end"
                                                                                                                                    style={{ "position": "absolute", willChange: "transform", "top": " 0px", "left": "0px", "transform": "translate3d(-147px, 19px, 0px)" }}>
                                                                                                                                    <a className="dropdown-item" data-toggle="modal" data-target="#modalsmalldeletefile" onClick={() => this.handleChangeDeleteFileFolder(op)} ><i className="icon-cancel-square"></i> Eliminar</a>
                                                                                                                                    <a className="dropdown-item" data-toggle="modal" data-target="#modalsmallrename" onClick={() => this.handleProperties(op)}><i className="icon-pencil"></i> Renombrar</a>
                                                                                                                                    <div className="dropdown-divider"></div>
                                                                                                                                    <a className="dropdown-item" onClick={() => this.handleValidateExtFile(op)}><i className="icon-file-download"></i> Descarga normal</a>
                                                                                                                                    <div className="dropdown-divider"></div>
                                                                                                                                    <a className="dropdown-item" data-toggle="modal" data-target="#modalshare" onClick={() => this.handleProperties(op)}><i className="icon-share2"></i> Compartir</a>
                                                                                                                                    <a className="dropdown-item" data-toggle="modal" data-target="#modal_small" onClick={() => this.handleProperties(op)}><i className="icon-notification2"></i> Información</a>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                                <div className="card-body text-center" onClick={() => this.handleChangeDirectory(op)} style={{ cursor: 'pointer' }}>
                                                                                                                    <FilesExtension ext={op} />
                                                                                                                    <div className="text">
                                                                                                                        <span className="card-title">{op.name}</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                {
                                                                                                                    (op.notificacion !== undefined && op.respuesta === "SI") ? (
                                                                                                                        <div className="card-footer bg-light d-flex justify-content-between">
                                                                                                                            <i className="icon-checkmark-circle text-success mr-2" style={{ textAlign: 'center' }}></i>
                                                                                                                        </div>
                                                                                                                    ) : null
                                                                                                                }

                                                                                                            </div>

                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            <div className="card">
                                                                                                                <div className="card-header bg-transparent border-bottom header-elements-inline">
                                                                                                                    <h6 className="card-title" style={{ visibility: 'hidden' }}>Icon dropdown</h6>
                                                                                                                    <div className="header-elements">
                                                                                                                        <div className="list-icons">
                                                                                                                            <div className="dropdown">
                                                                                                                                <a className="list-icons-item dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                                                                                                                    <i className="icon-menu7"></i></a>
                                                                                                                                <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end"
                                                                                                                                    style={{ "position": "absolute", willChange: "transform", "top": " 0px", "left": "0px", "transform": "translate3d(-147px, 19px, 0px)" }}>
                                                                                                                                    <a className="dropdown-item" data-toggle="modal" data-target="#modalsmalldeletefile" onClick={() => this.handleChangeDeleteFileFolder(op)} ><i className="icon-cancel-square"></i> Eliminar</a>
                                                                                                                                    <a className="dropdown-item" data-toggle="modal" data-target="#modalsmallrename" onClick={() => this.handleProperties(op)}><i className="icon-pencil"></i> Renombrar</a>
                                                                                                                                    <div className="dropdown-divider"></div>
                                                                                                                                    <a className="dropdown-item" onClick={() => this.handleValidateExtFile(op)}><i className="icon-file-download"></i> Descarga normal</a>

                                                                                                                                    <div className="dropdown-divider"></div>
                                                                                                                                    <a className="dropdown-item" data-toggle="modal" data-target="#modalshare" onClick={() => this.handleProperties(op)}><i className="icon-share2"></i> Compartir</a>
                                                                                                                                    <a className="dropdown-item" data-toggle="modal" data-target="#modal_small" onClick={() => this.handleProperties(op)}><i className="icon-notification2"></i> Información</a>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                                <div className="card-body text-center" style={{ cursor: 'pointer' }} onClick={() => this.handleValidateExtFile(op)}>
                                                                                                                    <FilesExtension ext={op} />
                                                                                                                    <div className="text">
                                                                                                                        <span className="card-title">{op.name}</span>
                                                                                                                        {/*(op.extension === ".pdf" || op.extension === ".jpg" || op.extension === ".png") ?
                                                                                                                            (
                                                                                                                                <>
                                                                                                                                    <span data-toggle="modal" data-target="#modal_scrollable" onClick={() => this.handleValidateExtFile(op)}>{op.name}</span>
                                                                                                                                </>
                                                                                                                            ) :
                                                                                                                            <span onClick={() => this.handleValidateExtFile(op)}>{op.name}</span>
                                                                                                                        */}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                        </>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ) :
                                                                        (
                                                                            <>
                                                                                {this.state.directoryraizsecond ? (
                                                                                    <div className="page-header page-header-light">
                                                                                        <div className="card-header bg-transparent border-bottom header-elements-sm-inline">
                                                                                            {/* <h6 className="card-title" onClick={this.handleChangeHome}>
                                                                                        <i className="icon-home2 mr-2" style={{ fontSize: 21 }}></i>
                                                                                        {this.state.nombre_directory}</h6>*/}
                                                                                            <div className="breadcrumb">
                                                                                                <h6 style={{ cursor: 'pointer' }} className="breadcrumb-item" onClick={this.handleChangeHome}><i className="icon-home2 mr-2" style={{ fontSize: 21 }}></i> {this.state.nombre_directory}</h6>
                                                                                                {
                                                                                                    (this.state.breadcrumb || []).map((y, ip) => (
                                                                                                        <a style={{ cursor: 'pointer' }} className="breadcrumb-item" key={ip} onClick={() => this.handleChangeFilterData(y)}> {y.name}</a>
                                                                                                    ))
                                                                                                }
                                                                                            </div>
                                                                                            <div className="header-elements">
                                                                                                <ul className="list-inline list-inline-dotted mb-0">
                                                                                                    <li className="list-inline-item" onClick={() => this.handleupdatetypeviewlist("CUADRO")}><i className="icon-grid6 mr-2" style={{ userSelect: 'none', cursor: 'pointer', fontSize: 21 }}></i></li>
                                                                                                    <li className="list-inline-item" onClick={() => this.handleupdatetypeviewlist("LISTADO")}><i className="icon-list mr-2" style={{ userSelect: 'none', cursor: 'pointer', fontSize: 21 }}></i></li>
                                                                                                    <li className="list-inline-item">
                                                                                                        <label className="breadcrumb-elements-item" htmlFor="_upload_file_">
                                                                                                            <div className="uniform-uploader" style={{ cursor: 'pointer', top: 5 }}>
                                                                                                                <i className="icon-folder-upload mr-2" style={{ userSelect: 'none', cursor: 'pointer', fontSize: 21 }}>
                                                                                                                </i>
                                                                                                                Subir archivos
                                                                                                            </div>
                                                                                                        </label>
                                                                                                        <input type="file" id="_upload_file_" className="form-control-uniform-custom" style={{ display: 'none' }} multiple name="docs" onChange={this.handleChangeFile} />
                                                                                                    </li>
                                                                                                    <li className="list-inline-item" data-toggle="modal" data-target="#modal_form_vertical" style={{ cursor: 'pointer' }}>
                                                                                                        <i className="icon-folder-plus2 mr-2" style={{ fontSize: 21 }}></i>Nueva carpeta</li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="card-body">
                                                                                            <div className="row">
                                                                                                {
                                                                                                    (this.state.directoryfind || []).map((op, u) => (
                                                                                                        <div className="col-sm-2 col-lg-2" key={u}>
                                                                                                            <div className="card">
                                                                                                                {op.type === "directory" ? (
                                                                                                                    <>
                                                                                                                        <div className={(op.notificacion !== undefined && op.respuesta === "SI") ? "card" : ""}>
                                                                                                                            <div className="card-header bg-transparent border-bottom header-elements-inline">
                                                                                                                                <h6 className="card-title" style={{ visibility: 'hidden' }}>Icon dropdown</h6>
                                                                                                                                <div className="header-elements">
                                                                                                                                    <div className="list-icons">
                                                                                                                                        <div className="dropdown">
                                                                                                                                            <a className="list-icons-item dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                                                                                                                                <i className="icon-menu7"></i></a>
                                                                                                                                            <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end"
                                                                                                                                                style={{ "position": "absolute", willChange: "transform", "top": " 0px", "left": "0px", "transform": "translate3d(-147px, 19px, 0px)" }}>
                                                                                                                                                <a className="dropdown-item" data-toggle="modal" data-target="#modalsmalldeletefile" onClick={() => this.handleChangeDeleteFileFolder(op)} ><i className="icon-cancel-square"></i> Eliminar</a>
                                                                                                                                                <a className="dropdown-item" data-toggle="modal" data-target="#modalsmallrename" onClick={() => this.handleProperties(op)}><i className="icon-pencil"></i> Renombrar</a>
                                                                                                                                                <div className="dropdown-divider"></div>
                                                                                                                                                <a className="dropdown-item" onClick={() => this.handleValidateExtFile(op)}><i className="icon-file-download"></i> Descarga normal</a>

                                                                                                                                                <div className="dropdown-divider"></div>
                                                                                                                                                <a className="dropdown-item" data-toggle="modal" data-target="#modalshare" onClick={() => this.handleProperties(op)}><i className="icon-share2"></i> Compartir</a>
                                                                                                                                                <a className="dropdown-item" data-toggle="modal" data-target="#modal_small" onClick={() => this.handleProperties(op)}><i className="icon-notification2"></i> Información</a>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className="card-body text-center" onClick={() => this.handleChangeDirectory(op)} style={{ cursor: 'pointer' }}>
                                                                                                                                <FilesExtension ext={op} />
                                                                                                                                <div className="text">
                                                                                                                                    <span className="card-title">{op.name}</span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            {
                                                                                                                                (op.notificacion !== undefined && op.respuesta === "SI") ? (
                                                                                                                                    <div className="card-footer bg-light d-flex justify-content-between">
                                                                                                                                        <i className="icon-checkmark-circle text-success mr-2" style={{ textAlign: 'center' }}></i>
                                                                                                                                    </div>
                                                                                                                                ) : null
                                                                                                                            }

                                                                                                                        </div>

                                                                                                                    </>
                                                                                                                ) : (
                                                                                                                    <>
                                                                                                                        <div className="">
                                                                                                                            <div className="card-header bg-transparent border-bottom header-elements-inline">
                                                                                                                                <h6 className="card-title" style={{ visibility: 'hidden' }}>Icon dropdown</h6>
                                                                                                                                <div className="header-elements">
                                                                                                                                    <div className="list-icons">
                                                                                                                                        <div className="dropdown">
                                                                                                                                            <a className="list-icons-item dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                                                                                                                                <i className="icon-menu7"></i></a>
                                                                                                                                            <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end"
                                                                                                                                                style={{ "position": "absolute", willChange: "transform", "top": " 0px", "left": "0px", "transform": "translate3d(-147px, 19px, 0px)" }}>
                                                                                                                                                <a className="dropdown-item" data-toggle="modal" data-target="#modalsmalldeletefile" onClick={() => this.handleChangeDeleteFileFolder(op)} ><i className="icon-cancel-square"></i> Eliminar</a>
                                                                                                                                                <a className="dropdown-item" data-toggle="modal" data-target="#modalsmallrename" onClick={() => this.handleProperties(op)}><i className="icon-pencil"></i> Renombrar</a>
                                                                                                                                                <div className="dropdown-divider"></div>
                                                                                                                                                <a className="dropdown-item" onClick={() => this.handleValidateExtFile(op)}><i className="icon-file-download"></i> Descarga normal</a>

                                                                                                                                                <div className="dropdown-divider"></div>
                                                                                                                                                <a className="dropdown-item" data-toggle="modal" data-target="#modalshare" onClick={() => this.handleProperties(op)}><i className="icon-share2"></i> Compartir</a>
                                                                                                                                                <a className="dropdown-item" data-toggle="modal" data-target="#modal_small" onClick={() => this.handleProperties(op)}><i className="icon-notification2"></i> Información</a>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className="card-body text-center" style={{ cursor: 'pointer' }} data-toggle="modal" data-target="#modal_scrollable" onClick={() => this.handleValidateExtFile(op)}>
                                                                                                                                <FilesExtension ext={op} />
                                                                                                                                <div className="text">
                                                                                                                                    <span>{op.name}</span>
                                                                                                                                </div>
                                                                                                                                {/*(op.extension === ".pdf" || op.extension === ".jpg" || op.extension === ".png") ?
                                                                                                                                    (
                                                                                                                                        <>
                                                                                                                                            <div className="text">
                                                                                                                                                <span className="card-title" data-toggle="modal" data-target="#modal_scrollable" onClick={() => this.handleValidateExtFile(op)}>{op.name}</span>
                                                                                                                                            </div>

                                                                                                                                        </>
                                                                                                                                    ) : (
                                                                                                                                        <div className="text">
                                                                                                                                            <span onClick={() => this.handleValidateExtFile(op)}>{op.name}</span>
                                                                                                                                        </div>
                                                                                                                                    )

                                                                                                                                */}
                                                                                                                            </div>


                                                                                                                        </div>

                                                                                                                    </>
                                                                                                                )}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    ))
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : (
                                                                                    <>
                                                                                        <p>Error al cargar la información</p>
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        )
                                                                )
                                                            }
                                                        </div>
                                                    </div>

                                                ) : (
                                                    <>
                                                        {
                                                            this.state.directoryraiz ? (
                                                                <div className="card">
                                                                    <div className="card-header bg-transparent border-bottom header-elements-sm-inline">
                                                                        <h6 className="card-title" onClick={this.handleChangeHome}><i className="icon-home2 mr-2" style={{ fontSize: 21 }}></i> {this.state.nombre_directory}</h6>
                                                                        <div className="header-elements">
                                                                            <ul className="list-inline list-inline-dotted mb-0">
                                                                                <li className="list-inline-item" onClick={() => this.handleupdatetypeviewlist("CUADRO")}><i className="icon-grid6 mr-2" style={{ userSelect: 'none', cursor: 'pointer', fontSize: 21 }}></i></li>
                                                                                <li className="list-inline-item" onClick={() => this.handleupdatetypeviewlist("LISTADO")}><i className="icon-list mr-2" style={{ userSelect: 'none', cursor: 'pointer', fontSize: 21 }}></i></li>
                                                                                <li className="list-inline-item">
                                                                                    <label className="breadcrumb-elements-item" htmlFor="_upload_file_">
                                                                                        <div className="uniform-uploader" style={{ cursor: 'pointer', top: 5 }}>
                                                                                            <i className="icon-folder-upload mr-2" style={{ userSelect: 'none', cursor: 'pointer', fontSize: 21 }}>
                                                                                            </i>
                                                                                            Subir archivos
                                                                                        </div>
                                                                                    </label>
                                                                                    <input type="file" id="_upload_file_" className="form-control-uniform-custom" style={{ display: 'none' }} multiple name="docs" onChange={this.handleChangeFile} />
                                                                                </li>
                                                                                <li className="list-inline-item" data-toggle="modal" data-target="#modal_form_vertical" style={{ cursor: 'pointer' }}>
                                                                                    <i className="icon-folder-plus2 mr-2" style={{ fontSize: 21 }}></i>Nueva carpeta</li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <div className="row">
                                                                            <div className="dataTables_wrapper no-footer" style={{ width: '100%' }}>
                                                                                <div className="datatable-scroll-lg">
                                                                                    <table className="table tasks-list table-lg dataTable no-footer">
                                                                                        <thead>
                                                                                            <tr role="row">
                                                                                                <th></th>
                                                                                                <th className="sorting">Nombre</th>
                                                                                                <th className="sorting">Fecha de modificación</th>
                                                                                                <th className="sorting">Tamaño</th>
                                                                                                <th className="sorting">Clase</th>
                                                                                                <th className="sorting">Estatus</th>
                                                                                                <th className="text-center text-muted sorting_disabled"></th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                (this.state.data || []).map((op, u) => (
                                                                                                    <>
                                                                                                        {op.type === "directory" ? (
                                                                                                            <tr role="row" className="odd" key={u}>
                                                                                                                <td className="sorting_1" onClick={() => this.handleChangeDirectory(op)} style={{ cursor: 'pointer' }}>
                                                                                                                    <ExtensionEnd ext={op} />
                                                                                                                </td>
                                                                                                                <td onClick={() => this.handleChangeDirectory(op)} style={{ cursor: 'pointer' }}>
                                                                                                                    <h6 className="mb-0" style={{ paddingTop: '3%' }}>{op.name}</h6>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <span className="text-muted">{formatDate(op.mtime)}</span>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <span className="text-muted">{bytesToSize(op.size)}</span>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <span className="text-muted">Carpeta</span>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    {
                                                                                                                        (op.notificacion !== undefined && op.respuesta === "SI") ? (
                                                                                                                            <div className="card-footer bg-light d-flex justify-content-between">
                                                                                                                                <i className="icon-checkmark-circle text-success mr-2" style={{ textAlign: 'center' }}></i>
                                                                                                                            </div>
                                                                                                                        ) : null
                                                                                                                    }
                                                                                                                </td>
                                                                                                                <td className="text-center">
                                                                                                                    <div className="list-icons">
                                                                                                                        <div className="dropdown">
                                                                                                                            <a href="#" className="list-icons-item dropdown-toggle caret-0" data-toggle="dropdown"><i className="icon-menu9" /></a>
                                                                                                                            <div className="dropdown-menu dropdown-menu-right">
                                                                                                                                <a className="dropdown-item" data-toggle="modal" data-target="#modalsmalldeletefile" onClick={() => this.handleChangeDeleteFileFolder(op)} ><i className="icon-cancel-square"></i> Eliminar</a>
                                                                                                                                <a className="dropdown-item" data-toggle="modal" data-target="#modalsmallrename" onClick={() => this.handleProperties(op)}><i className="icon-pencil"></i> Renombrar</a>
                                                                                                                                <div className="dropdown-divider"></div>
                                                                                                                                <a className="dropdown-item" onClick={() => this.handleValidateExtFile(op)}><i className="icon-file-download"></i> Descarga normal</a>
                                                                                                                                <div className="dropdown-divider"></div>
                                                                                                                                <a className="dropdown-item" data-toggle="modal" data-target="#modalshare" onClick={() => this.handleProperties(op)}><i className="icon-share2"></i> Compartir</a>
                                                                                                                                <a className="dropdown-item" data-toggle="modal" data-target="#modal_small" onClick={() => this.handleProperties(op)}><i className="icon-notification2"></i> Información</a>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        ) : (
                                                                                                            <tr role="row" className="odd" key={u}>
                                                                                                                <td className="sorting_1">
                                                                                                                    <ExtensionEnd ext={op} />
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    {(op.extension === ".pdf" || op.extension === ".jpg" || op.extension === ".png") ?
                                                                                                                        (
                                                                                                                            <>
                                                                                                                                <h6 className="mb-0" style={{ paddingTop: '3%', cursor: 'pointer' }} data-toggle="modal" data-target="#modal_scrollable" onClick={() => this.handleValidateExtFile(op)}>{op.name}</h6>
                                                                                                                            </>
                                                                                                                        ) :
                                                                                                                        <h6 className="mb-0" style={{ paddingTop: '3%', cursor: 'pointer' }} onClick={() => this.handleValidateExtFile(op)}>{op.name}</h6>
                                                                                                                    }
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <span className="text-muted">{formatDate(op.mtime)}</span>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <span className="text-muted">{bytesToSize(op.size)}</span>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <span className="text-muted">Archivo</span>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    {
                                                                                                                        (op.notificacion !== undefined && op.respuesta === "SI") ? (
                                                                                                                            <div className="card-footer bg-light d-flex justify-content-between">
                                                                                                                                <i className="icon-checkmark-circle text-success mr-2" style={{ textAlign: 'center' }}></i>
                                                                                                                            </div>
                                                                                                                        ) : null
                                                                                                                    }
                                                                                                                </td>
                                                                                                                <td className="text-center">
                                                                                                                    <div className="list-icons">
                                                                                                                        <div className="dropdown">
                                                                                                                            <a href="#" className="list-icons-item dropdown-toggle caret-0" data-toggle="dropdown"><i className="icon-menu9" /></a>
                                                                                                                            <div className="dropdown-menu dropdown-menu-right">
                                                                                                                                <a className="dropdown-item" data-toggle="modal" data-target="#modalsmalldeletefile" onClick={() => this.handleChangeDeleteFileFolder(op)} ><i className="icon-cancel-square"></i> Eliminar</a>
                                                                                                                                <a className="dropdown-item" data-toggle="modal" data-target="#modalsmallrename" onClick={() => this.handleProperties(op)}><i className="icon-pencil"></i> Renombrar</a>
                                                                                                                                <div className="dropdown-divider"></div>
                                                                                                                                <a className="dropdown-item" onClick={() => this.handleValidateExtFile(op)}><i className="icon-file-download"></i> Descarga normal</a>

                                                                                                                                <div className="dropdown-divider"></div>
                                                                                                                                <a className="dropdown-item" data-toggle="modal" data-target="#modalshare" onClick={() => this.handleProperties(op)}><i className="icon-share2"></i> Compartir</a>
                                                                                                                                <a className="dropdown-item" data-toggle="modal" data-target="#modal_small" onClick={() => this.handleProperties(op)}><i className="icon-notification2"></i> Información</a>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        )
                                                                                                        }
                                                                                                    </>
                                                                                                ))
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                                :
                                                                (
                                                                    <>
                                                                        {this.state.directoryraizsecond ? (
                                                                            <div className="page-header page-header-light">
                                                                                <div className="card-header bg-transparent border-bottom header-elements-sm-inline">
                                                                                    <div className="breadcrumb">
                                                                                        <h6 style={{ cursor: 'pointer' }} className="breadcrumb-item" onClick={this.handleChangeHome}><i className="icon-home2 mr-2" style={{ fontSize: 21 }}></i> {this.state.nombre_directory}</h6>
                                                                                        {
                                                                                            (this.state.breadcrumb || []).map((y, ip) => (
                                                                                                <a style={{ cursor: 'pointer' }} className="breadcrumb-item" key={ip} onClick={() => this.handleChangeFilterData(y)}> {y.name}</a>
                                                                                            ))
                                                                                        }
                                                                                    </div>
                                                                                    <div className="header-elements">
                                                                                        <ul className="list-inline list-inline-dotted mb-0">
                                                                                            <li className="list-inline-item" onClick={() => this.handleupdatetypeviewlist("CUADRO")}><i className="icon-grid6 mr-2" style={{ userSelect: 'none', cursor: 'pointer', fontSize: 21 }}></i></li>
                                                                                            <li className="list-inline-item" onClick={() => this.handleupdatetypeviewlist("LISTADO")}><i className="icon-list mr-2" style={{ userSelect: 'none', cursor: 'pointer', fontSize: 21 }}></i></li>
                                                                                            <li className="list-inline-item">
                                                                                                <label className="breadcrumb-elements-item" htmlFor="_upload_file_">
                                                                                                    <div className="uniform-uploader" style={{ cursor: 'pointer', top: 5 }}>
                                                                                                        <i className="icon-folder-upload mr-2" style={{ userSelect: 'none', cursor: 'pointer', fontSize: 21 }}>
                                                                                                        </i>
                                                                                                        Subir archivos
                                                                                                    </div>
                                                                                                </label>
                                                                                                <input type="file" id="_upload_file_" className="form-control-uniform-custom" style={{ display: 'none' }} multiple name="docs" onChange={this.handleChangeFile} />
                                                                                            </li>
                                                                                            <li className="list-inline-item" data-toggle="modal" data-target="#modal_form_vertical" style={{ cursor: 'pointer' }}>
                                                                                                <i className="icon-folder-plus2 mr-2" style={{ fontSize: 21 }}></i>Nueva carpeta</li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="card-body">
                                                                                    <div className="row">
                                                                                        <div className="dataTables_wrapper no-footer" style={{ width: '100%' }}>
                                                                                            <div className="datatable-scroll-lg">
                                                                                                <table className="table tasks-list table-lg dataTable no-footer">
                                                                                                    <thead>
                                                                                                        <tr role="row">
                                                                                                            <th></th>
                                                                                                            <th className="sorting">Nombre</th>
                                                                                                            <th className="sorting">Fecha de modificación</th>
                                                                                                            <th className="sorting">Tamaño</th>
                                                                                                            <th className="sorting">Clase</th>
                                                                                                            <th className="sorting">Estatus</th>
                                                                                                            <th className="text-center text-muted sorting_disabled"></th>
                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    <tbody>
                                                                                                        {
                                                                                                            (this.state.directoryfind || []).map((op, u) => (
                                                                                                                <>
                                                                                                                    {op.type === "directory" ? (
                                                                                                                        <tr role="row" className="odd" key={u}>
                                                                                                                            <td className="sorting_1" onClick={() => this.handleChangeDirectory(op)} style={{ cursor: 'pointer' }}>
                                                                                                                                <ExtensionEnd ext={op} />
                                                                                                                            </td>
                                                                                                                            <td onClick={() => this.handleChangeDirectory(op)} style={{ cursor: 'pointer' }}>
                                                                                                                                <h6 className="mb-0" style={{ paddingTop: '3%' }}>{op.name}</h6>
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                <span className="text-muted">{formatDate(op.mtime)}</span>
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                <span className="text-muted">{bytesToSize(op.size)}</span>
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                <span className="text-muted">Carpeta</span>
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                {
                                                                                                                                    (op.notificacion !== undefined && op.respuesta === "SI") ? (
                                                                                                                                        <div className="card-footer bg-light d-flex justify-content-between">
                                                                                                                                            <i className="icon-checkmark-circle text-success mr-2" style={{ textAlign: 'center' }}></i>
                                                                                                                                        </div>
                                                                                                                                    ) : null
                                                                                                                                }
                                                                                                                            </td>
                                                                                                                            <td className="text-center">
                                                                                                                                <div className="list-icons">
                                                                                                                                    <div className="dropdown">
                                                                                                                                        <a href="#" className="list-icons-item dropdown-toggle caret-0" data-toggle="dropdown"><i className="icon-menu9" /></a>
                                                                                                                                        <div className="dropdown-menu dropdown-menu-right">
                                                                                                                                            <a className="dropdown-item" data-toggle="modal" data-target="#modalsmalldeletefile" onClick={() => this.handleChangeDeleteFileFolder(op)} ><i className="icon-cancel-square"></i> Eliminar</a>
                                                                                                                                            <a className="dropdown-item" data-toggle="modal" data-target="#modalsmallrename" onClick={() => this.handleProperties(op)}><i className="icon-pencil"></i> Renombrar</a>
                                                                                                                                            <div className="dropdown-divider"></div>
                                                                                                                                            <a className="dropdown-item" onClick={() => this.handleValidateExtFile(op)}><i className="icon-file-download"></i> Descarga normal</a>
                                                                                                                                            <div className="dropdown-divider"></div>
                                                                                                                                            <a className="dropdown-item" data-toggle="modal" data-target="#modalshare" onClick={() => this.handleProperties(op)}><i className="icon-share2"></i> Compartir</a>
                                                                                                                                            <a className="dropdown-item" data-toggle="modal" data-target="#modal_small" onClick={() => this.handleProperties(op)}><i className="icon-notification2"></i> Información</a>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                    ) : (
                                                                                                                        <tr role="row" className="odd" key={u}>
                                                                                                                            <td className="sorting_1">
                                                                                                                                <ExtensionEnd ext={op} />
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                {(op.extension === ".pdf" || op.extension === ".jpg" || op.extension === ".png") ?
                                                                                                                                    (
                                                                                                                                        <>
                                                                                                                                            <h6 className="mb-0" style={{ paddingTop: '3%', cursor: 'pointer' }} data-toggle="modal" data-target="#modal_scrollable" onClick={() => this.handleValidateExtFile(op)}>{op.name}</h6>
                                                                                                                                        </>
                                                                                                                                    ) :
                                                                                                                                    <h6 className="mb-0" style={{ paddingTop: '3%', cursor: 'pointer' }} onClick={() => this.handleValidateExtFile(op)}>{op.name}</h6>
                                                                                                                                }
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                <span className="text-muted">{formatDate(op.mtime)}</span>
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                <span className="text-muted">{bytesToSize(op.size)}</span>
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                <span className="text-muted">Archivo</span>
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                {
                                                                                                                                    (op.notificacion !== undefined && op.respuesta === "SI") ? (
                                                                                                                                        <div className="card-footer bg-light d-flex justify-content-between">
                                                                                                                                            <i className="icon-checkmark-circle text-success mr-2" style={{ textAlign: 'center' }}></i>
                                                                                                                                        </div>
                                                                                                                                    ) : null
                                                                                                                                }
                                                                                                                            </td>
                                                                                                                            <td className="text-center">
                                                                                                                                <div className="list-icons">
                                                                                                                                    <div className="dropdown">
                                                                                                                                        <a href="#" className="list-icons-item dropdown-toggle caret-0" data-toggle="dropdown"><i className="icon-menu9" /></a>
                                                                                                                                        <div className="dropdown-menu dropdown-menu-right">
                                                                                                                                            <a className="dropdown-item" data-toggle="modal" data-target="#modalsmalldeletefile" onClick={() => this.handleChangeDeleteFileFolder(op)} ><i className="icon-cancel-square"></i> Eliminar</a>
                                                                                                                                            <a className="dropdown-item" data-toggle="modal" data-target="#modalsmallrename" onClick={() => this.handleProperties(op)}><i className="icon-pencil"></i> Renombrar</a>
                                                                                                                                            <div className="dropdown-divider"></div>
                                                                                                                                            <a className="dropdown-item" onClick={() => this.handleValidateExtFile(op)}><i className="icon-file-download"></i> Descarga normal</a>

                                                                                                                                            <div className="dropdown-divider"></div>
                                                                                                                                            <a className="dropdown-item" data-toggle="modal" data-target="#modalshare" onClick={() => this.handleProperties(op)}><i className="icon-share2"></i> Compartir</a>
                                                                                                                                            <a className="dropdown-item" data-toggle="modal" data-target="#modal_small" onClick={() => this.handleProperties(op)}><i className="icon-notification2"></i> Información</a>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                    )
                                                                                                                    }
                                                                                                                </>
                                                                                                            ))
                                                                                                        }
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ) : null
                                                                        }
                                                                    </>
                                                                )
                                                        }
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                    }
                </div>
                <Snackbar open={this.state.open} autoHideDuration={6000} onClose={this.handleCloseAlert} anchorOrigin={{ vertical, horizontal }}>
                    <Alert onClose={this.handleCloseAlert} severity="success">
                        {this.state.mensaje}
                    </Alert>
                </Snackbar>
                <Snackbar open={this.state.openerror} autoHideDuration={6000} onClose={this.handleCloseAlert} anchorOrigin={{ vertical, horizontal }}>
                    <Alert onClose={this.handleCloseAlert} severity="error">
                        {this.state.mensaje}
                    </Alert>
                </Snackbar>



                <div id="modal_form_vertical" className="modal fade" tabIndex="-1" aria-hidden="true" style={{ display: 'none' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Nombre de la carpeta</h5>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>
                            <div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <input type="text" placeholder="Nombre carpeta / E-xpertum" value={this.state.pro_name} onChange={this.handleChangeNameFolder} className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group mb-3 mb-md-2">
                                        <label className="d-block font-weight-semibold">¿Desea recibir notificación cuando no suban la información?</label>
                                        <div className="form-check form-check-inline">
                                            <label className="form-check-label">
                                                <div className="uniform-choice border-warning text-warning">
                                                    <span className={this.state.questionyes}>
                                                        <input type="radio" className="form-check-input-styled-warning" name="radio-inline-left" value="SI" onChange={this.handlechangequestions} />
                                                    </span>
                                                </div>
                                                Si
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <label className="form-check-label">
                                                <div className="uniform-choice border-warning text-warning">
                                                    <span className={this.state.questionnot}>
                                                        <input type="radio" className="form-check-input-styled-warning" name="radio-inline-left" value="NO" onChange={this.handlechangequestions} />
                                                    </span>
                                                </div>
                                                No
                                            </label>
                                        </div>
                                    </div>
                                    {this.state.questionyes !== '' ? (
                                        <div className="form-group mb-3 mb-md-2">
                                            <label className="d-block font-weight-semibold">Configure el día o le fecha en la que dese recibir las notificaciones</label>
                                            <div className="form-check form-check-inline">
                                                <label className="form-check-label">
                                                    <div className="uniform-choice  border-success text-success">
                                                        <span className={this.state.questionwekend}>
                                                            <input type="radio" className="form-check-input-styled-success" name="radiooptions" value="SEMANAL" onChange={this.handleQuestionWekedMonth} />
                                                        </span>
                                                    </div>
                                                    Día de la semana
                                                </label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <label className="form-check-label">
                                                    <div className="uniform-choice  border-success text-success">
                                                        <span className={this.state.questionmonth}>
                                                            <input type="radio" className="form-check-input-styled-success" name="radiooptions" value="DIA" onChange={this.handleQuestionWekedMonth} />
                                                        </span>
                                                    </div>
                                                    Día del mes
                                                </label>
                                            </div>
                                        </div>) : null}
                                    {
                                        (this.state.questionwekend !== '' && this.state.questionwekend !== '') ? (
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <select className="form-control" onChange={this.handleselectdaynotification}>
                                                            <option>Seleccione el día de la semana</option>
                                                            {
                                                                (this.state.wekeends).map((j, o) => (
                                                                    <option value={j.inglish} key={0}>{j.spanish} </option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>) : null}

                                    {(this.state.questionmonth !== '' && this.state.questionmonth !== '') ? (
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <select className="form-control" onChange={this.handleselectdaynotification}>
                                                        <option>Seleccione el día</option>
                                                        {
                                                            (this.state.arraydyas).map((j, o) => (
                                                                <option value={j.dia} key={0}>{j.dia} </option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>) : null}
                                </div>
                                <div className="modal-footer">
                                    <a className="btn btn-link" data-dismiss="modal" onClick={this.handleClose}>Cancelar</a>
                                    <a className="btn bg-primary" onClick={this.handleSubmitCreateFolder}>Crear carpeta</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="modal_small" className="modal fade" tabIndex="-1" aria-hidden="true" style={{ "display": "none" }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title"></h5>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>

                            <div className="modal-body">
                                <ul className="media-list">
                                    <li className="media">
                                        <div className="mr-3">
                                            <a className="icon-2x text-primary-300 border-primary-300 border-3 rounded-round p-3 mb-3 mt-1">
                                                <Extens ext={this.state.ext} />
                                            </a>
                                        </div>
                                        <div className="media-body">
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <th>Nombre:</th>
                                                            <td className="text-left">{this.state.pro_name}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Tamaño:</th>
                                                            <td className="text-left">{this.state.pro_size}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Última modificación:</th>
                                                            <td className="text-left">{this.state.pro_fecha_actua}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Fecha subida:</th>
                                                            <td className="text-left">{this.state.pro_fecha}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="modalshare" className="modal fade" tabIndex="-1" aria-hidden="true" style={{ "display": "none" }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title"></h5>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>

                            <div className="modal-body">
                                <ul className="media-list">
                                    <li className="media">
                                        <div className="mr-3">
                                            <a className="icon-2x text-primary-300 border-primary-300 border-3 rounded-round p-3 mb-3 mt-1">
                                                <Extens ext={this.state.ext} />
                                            </a>
                                        </div>
                                        <div className="media-body">
                                            <div className="table-responsive">
                                                {this.state.alertCompartir ? (<div className="alert alert-warning alert-styled-right alert-dismissible">
                                                    <span className="font-weight-semibold">Usuario!</span> Debe de ingresar al menos un correo para compartir la información.
                                                </div>) : null}
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <div className="input-group">
                                                                    <span className="input-group-prepend">
                                                                        <span className="input-group-text">
                                                                            <i className="icon-mailbox"></i>
                                                                        </span>
                                                                    </span>
                                                                    <input autoComplete="off" type="text" className="form-control" name="email" placeholder="Ingresa un correo eletrónico" ref="correoelectronico" onChange={this.handleValidateEmail} />
                                                                    <span className="input-group-append" style={{ cursor: 'pointer' }} onClick={this.handleShareSubmit.bind(this)}>
                                                                        <span className="input-group-text"><i className="icon-stack-plus"></i></span>
                                                                    </span>
                                                                </div>
                                                                {this.state.emailError ? <span style={{ color: "red" }}>Por favor ingrese una dirección de correo electrónico válida</span> : ''}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <ul>
                                                                    {this.state.arraylist.map((item, j) => (
                                                                        <li key={j}>{item.email}&nbsp;&nbsp;&nbsp;
                                                                            <i className="icon-cross2 warning" style={{ fontSize: 12 }} onClick={() => this.onRemoveItem(item)}></i>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <textarea rows="2" cols="2" value={this.state.comments} className="form-control" name="descripcion" placeholder="Agregar comentario a los destinatarios." onChange={this.handleValidateEmail}></textarea>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ float: 'right' }}>
                                                                <button className="btn btn-primary" onClick={this.handleSavesharefile}>Compartir</button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="modalsmallrename" className="modal fade" tabIndex="-1" style={{ "display": "none" }} aria-hidden="true">
                    <div className="modal-dialog modal-sm">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Renombrar archivo</h5>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>

                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <input type="text" placeholder="Nombre carpeta / Julissa" value={this.state.pro_name} onChange={this.handleChangeNameFolder} className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-link" data-dismiss="modal">Cancelar</button>
                                <button type="button" className="btn bg-primary" onClick={this.handleConfirmRenameFilesFolder}>Renombrar</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="modalsmalldeletefile" className="modal fade" tabIndex="-1" style={{ "display": "none" }} aria-hidden="true">
                    <div className="modal-dialog modal-sm">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Confirmación de eliminar</h5>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>

                            <div className="modal-body">
                                <p style={{ fontSize: 12 }}>¿Estás seguro de eliminar el archivo?</p>
                                <p style={{ fontSize: 12 }}>Aviso: El archivo o carpeta se eliminará definitivamente del directorio</p>
                                {
                                    (this.state.identificador_ > 0 && this.state.respuestanotificacion === "SI") ? (
                                        <p style={{ fontSize: 12 }}>Nota: El directorio que desea eliminar, contine la configuración de notificaciones. ¿Está de acuerdo eliminar? </p>
                                    ) : null
                                }
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-link" data-dismiss="modal">No</button>
                                <button type="button" className="btn bg-primary" onClick={this.handleConfirmDelete}>Si</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="modal_scrollable" className="modal fade" tabIndex="-1">
                    <div className="modal-dialog modal-full">
                        <div className="modal-content">
                            <div className="modal-header pb-3">
                                <h5 className="modal-title"></h5>
                                <button type="button" className="close" data-dismiss="modal" onClick={() => this.setState({ validateext: '' })}>×</button>
                            </div>

                            <div className="modal-body py-0" >

                                {
                                    (this.state.validateext === ".png" || this.state.validateext === ".jpg") ?
                                        <IMG view={this.state.dataview} /> :
                                        (this.state.validateext === ".pdf" ? <PDF view={this.state.dataview} /> : null)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Admin;

