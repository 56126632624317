import React, { Component } from 'react';
///import axios from 'axios';
import { Tooltip, } from "@material-ui/core";
import ReactDatatable from '@ashvin27/react-datatable';
import Skeleton from '@material-ui/lab/Skeleton';
import { PacmanLoader } from "react-spinners";
import api from '../../app/Config/apifiles';
export default class DetailsIvaTrasladado extends Component {
    constructor(props) {
        super(props)
        this.columns = [
            {
                key: "fecha",
                text: "Fecha",
                className: "fecha",
                align: "left",
                sortable: true,
                width: 120,
                cell: record => {
                    return (
                        <div className="text-left">
                            {
                                <p style={{ fontSize: 12 }}>{String(record.fecha).slice(0, 10)}</p>
                            }
                        </div>
                    );
                }
            }, {
                key: "tipo_de_comprobante",
                text: "Tipo",
                className: "tipo_de_comprobante",
                align: "center",
                sortable: true,
                cell: record => {
                    return (
                        <div className="text-left">
                            {
                                <p style={{ fontSize: 12 }}>{parseTypeInvoice(record.tipo_de_comprobante)}</p>
                            }
                        </div>
                    );
                }
            }, {
                key: "metodo_pago",
                text: "Método pago",
                className: "metodo_pago",
                align: "center",
                sortable: true,
                cell: record => {
                    return (
                        <div className="text-left">
                            {
                                <p style={{ fontSize: 12 }}>{record.metodo_pagod}</p>
                            }
                        </div>
                    );
                }
            }, {
                key: "forma_pago",
                text: "Forma pago",
                className: "forma_pago",
                align: "center",
                sortable: true,
                cell: record => {
                    return (
                        <div className="text-left">
                            {
                                <p style={{ fontSize: 12 }}>{record.forma_pagod}</p>
                            }
                        </div>
                    );
                }
            }, {
                key: "sum_descuento_concepto",
                text: "Descuento",
                className: "sum_descuento_concepto",
                align: "center",
                sortable: true,
                cell: record => {
                    return (
                        <div className="text-right">
                            {
                                <p style={{ fontSize: 12 }}>{parseFloat(record.sum_descuento_concepto).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                            }
                        </div>
                    );
                }
            }, {
                key: "total_impuesto_trasladado",
                text: "IVA Trasladado",
                className: "total_impuesto_trasladado",
                align: "center",
                sortable: true,
                cell: record => {
                    return (
                        <div className="text-right">
                            {
                                <p style={{ fontSize: 12 }}>{parseFloat(record.total_impuesto_trasladado).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                            }
                        </div>
                    );
                }
            }, {
                key: "receptor",
                text: "RFC",
                className: "receptor",
                align: "center",
                sortable: true,
                cell: record => {
                    return (
                        <div className="text-left">
                            {parseRFCName(record.receptor, "rfc")}
                        </div>
                    );
                }
            }, {
                key: "total",
                text: "Nombre",
                className: "receptor",
                align: "center",
                sortable: true,
                cell: record => {
                    return (
                        <div className="text-left">
                            {parseRFCName(record.receptor, "name")}
                        </div>
                    );
                }
            }, {
                key: "uuid",
                text: "UUID",
                className: "uuid",
                align: "center",
                sortable: true,
                cell: record => {
                    return (
                        <div className="text-left">
                            {
                                <p style={{ fontSize: 12 }}>{record.uuid}</p>
                            }
                        </div>
                    );
                }
            },]
        this.extraButtons = [
            {
                className: "btn btn-primary",
                title: "Descargar reporte",
                children: [
                    <span>
                        <i className="icon-file-excel" aria-hidden="true" ></i>
                    </span>
                ],
                onClick: (event) => {
                    ///window.open("https://e-xpertum.com.mx/invoces/api/download-invoce-xml-faltante/" + this.state.empresa_id)
                },
            },
        ]
        this.config = {
            key_column: 'uuid',
            page_size: 50,
            length_menu: [100, 200, 500],
            button: {
                excel: false,
                print: false,
                extra: true,
            },
            "language": {
                "length_menu": "Mostrar _MENU_ registros por página",
                "filter": "Buscar en registros ...",
                "info": "Mostrando _START_ a _END_ de _TOTAL_ entradas",
                "pagination": {
                    "first": "Primero",
                    "previous": "Previa",
                    "next": "próximo",
                    "last": "Última"
                }
            }
        }
        this.state = {
            recorset: [],
            skeleton: true,
            loading: true,
            type: '',
            method_payment: '',
            iva_cobrado_ingreso: 0,
            iva_pendiente_ingreso: 0,
            iva_cobrado_egreso: 0,
            iva_pendiente_egreso: 0,
            empresa_id: 0,
            exercise: props.params.year,
            month: props.params.key
        }
    }
    componentDidMount() {
        this.getDataToCfdi();
    }
    getDataToCfdi = async () => {
        let _params_ = {
            empresa_id: 50395,
            year: this.state.exercise,
            month: this.state.month,
            number_page: 1,
            total_rows: 100
        }
        await api.post('get-all-xml-reports', _params_)
            .then((result) => {
                let data_ = result.data;
                this.setState({
                    recorset: data_.data, loading: false, skeleton: false, type: '',
                    iva_cobrado_ingreso: data_.ingreso_pue,
                    iva_pendiente_ingreso: data_.ingreso_ppd,
                    iva_cobrado_egreso: data_.egreso_pue,
                    iva_pendiente_egreso: data_.egreso_ppd,
                });
            }).catch((e) => {
                console.log(e)
            });
    }
    get_filter_data_ = async (i) => {
        let _params_ = {
            empresa_id: 50395,
            year: this.state.exercise,
            month: this.state.month,
            type: i
        }
        await api.post('get-all-xml-reports-filter-type', _params_).then((result) => {
            let data_ = result.data;
            this.setState({ recorset: data_, loading: false, skeleton: false, type: i });
        }).catch((e) => {
            console.log(e)
        });
    }
    get_filter_payment_method_type_ = async (i) => {
        let _params_ = {
            empresa_id: 50395,
            year: this.state.exercise,
            month: this.state.month,
            type: this.state.type,
            method: i
        }
        await api.post('get-all-xml-reports-filter-method-payment', _params_).then((result) => {
            let data_ = result.data;
            this.setState({ recorset: data_, loading: false, skeleton: false, method_payment: i });
        }).catch((e) => {
            console.log(e)
        });
    }
    render() {
        return (
            <>
                {
                    this.state.skeleton ? (
                        <div className="card">
                            <Skeleton />
                            <div style={{
                                margin: 40,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <PacmanLoader color={"#ff7043"} loading={this.state.loading} size={25} margin={2} />
                            </div>
                            <Skeleton variant="circle" animation="wave" width={40} height={40} />
                            <Skeleton variant="rect" animation="wave" width={"100%"} height={118} />
                            <Skeleton animation="wave" />
                        </div>
                    ) : (
                        <>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className="form-group">
                                        <div className="border p-3 rounded">
                                            <table className='table'>
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Método de pago</th>
                                                        <th>IVA cobrado</th>
                                                        <th>IVA pendiente de cobro</th>
                                                        <th></th>
                                                        <th>Método de pago</th>
                                                        <th>IVA cobrado</th>
                                                        <th>IVA pendiente de cobro</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td rowSpan={2} className="text-success">Ingreso</td>
                                                        <td>Pago en parcialidades o diferido</td>
                                                        <td className='text-right text-warning'>{parseFormatCurrency(this.state.iva_cobrado_ingreso)}</td>
                                                        <td></td>
                                                        <td rowSpan={2} className="text-success">Egreso</td>
                                                        <td>Pago en parcialidades o diferido</td>
                                                        <td className='text-right text-warning'>{parseFormatCurrency(this.state.iva_cobrado_egreso)}</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Pago en una sola exhibición</td>
                                                        <td></td>
                                                        <td className='text-right text-warning'>{parseFormatCurrency(this.state.iva_pendiente_ingreso)}</td>
                                                        <td>Pago en una sola exhibición</td>
                                                        <td></td>
                                                        <td className='text-right text-warning'>{parseFormatCurrency(this.state.iva_pendiente_egreso)}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <div className="border p-3 rounded">
                                            <p className="font-size-base text-muted d-block">Filtrar facturas por tipo de comprobante</p>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" className="custom-control-input" name="cr-i-l" id="chIncome" onChange={() => this.get_filter_data_('I')} />
                                                <label className="custom-control-label" htmlFor="chIncome">Ingreso</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" className="custom-control-input" name="cr-i-l" id="chExpenses" onChange={() => this.get_filter_data_('E')} />
                                                <label className="custom-control-label" htmlFor="chExpenses">Egreso</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" className="custom-control-input" name="cr-i-l" id="crall" defaultChecked onChange={this.getDataToCfdi} />
                                                <label className="custom-control-label" htmlFor="crall">Todos</label>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <div className="border p-3 rounded">
                                            <p className="font-size-base text-muted d-block">Filtrar facturas por método de pago</p>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" className="custom-control-input" name="payment_method_type" id="chPUE" onChange={() => this.get_filter_payment_method_type_('PUE')} />
                                                <label className="custom-control-label" htmlFor="chPUE">PUE</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" className="custom-control-input" name="payment_method_type" id="chPPD" onChange={() => this.get_filter_payment_method_type_('PPD')} />
                                                <label className="custom-control-label" htmlFor="chPPD">PPD</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" className="custom-control-input" name="payment_method_type" id="chALL" defaultChecked onChange={this.getDataToCfdi} />
                                                <label className="custom-control-label" htmlFor="chALL">Ambos</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <ReactDatatable
                                    config={this.config}
                                    records={(this.state.recorset || [])}
                                    columns={this.columns}
                                    extraButtons={this.extraButtons}
                                />
                            </div>
                        </>
                    )
                }
            </>
        )
    }
}
function parseRFCName(data, type) {
    let _ = JSON.parse(data);
    if (type === "rfc") {
        return (
            <p style={{ fontSize: 12 }}>{_.rfc}</p>
        )
    } else {
        return (
            <p style={{ fontSize: 12 }}>{_.nombre}</p>
        )
    }
};
function parseTypeInvoice(key) {
    switch (key) {
        case "I":
            return "Ingreso"
            break;
        case "E":
            return "Egreso"
            break;
        case "P":
            return "Pago"
            break;
        default:
            break;
    }
}
function parseFormatCurrency(imports) {
    if (imports === 0) {
        return ("")
    } else return parseFloat(imports).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}