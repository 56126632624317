import React, { Component } from 'react';
import '../details/style.css';
class ReportMetadata extends Component {
    constructor(props) {
        super(props);
        let pareme = JSON.parse(localStorage.getItem("parameterscompany"));
        this.state = {
            data: [],
            empresa_id: pareme.empresa_id,
            id: pareme.id,
            rfc: pareme.rfc,
            details: [],
            subdetailsdata: [],
            title: '',
            clave: '',
            url: 'http://localhost:3004/report-metadata-x?empresa_id=' + pareme.id + "&rfc=" + pareme.rfc,
            skeletonheader: false,
            skeletonbody: false,
            namecompany: pareme.nombre,
            titleinit: 'Importación de archivos XML de otras contabilidades'
        }
    }
    render() {
        return (
            <div className="content-inner">
                <div className="page-header border-bottom-0">
                    <div className="page-header-content header-elements-md-inline">
                        <div className="page-title d-flex">
                            <h4><i className="icon-arrow-left52 mr-2" /><a href='#/app/client/sistem' style={{ color: 'white' }}><span className="font-weight-semibold">Listado de empresas</span></a> </h4>
                            <a href="#/app/client/sistem" className="header-elements-toggle text-body d-lg-none"><i className="icon-more" /></a>
                        </div>
                        <div className="text-center">
                            <h6 className="mb-0 font-weight-semibold">{this.state.namecompany}</h6>
                            <p className="mb-3 text-muted">{this.state.titleinit}</p>
                        </div>

                        <div className="header-elements d-none mb-3 mb-lg-0">
                            <div className="d-flex justify-content-center">
                                <a href="#" className="btn btn-link btn-float text-body"></a>
                                <a href="#" className="btn btn-link btn-float text-body"></a>
                                <a href="#" className="btn btn-link btn-float text-body"></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content pt-0">
                    <div className="card">
                        <div className="card-body">
                            <div className='containeriframe'>
                                <iframe className='responsiveiframeall scroll scroll1'
                                    src={this.state.url}
                                    frameBorder={0} allowFullScreen
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ReportMetadata;