import React, { Component } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import * as Icon from '@material-ui/icons';
import 'react-vertical-timeline-component/style.min.css';
//import "./styles.scss"
import { makeStyles } from '@material-ui/core/styles';
/*******
 * CONFIG CONEXION
 */
import api from '../Config/api';
/*******
 * LIBRARY UPLOAD FILES
 */
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import ViewLeft from './util-informativa/viewleft';
import ViewRight from './util-informativa/viewright';

class TimeLineInformativa extends Component {
    constructor(props) {
        super(props);
        var data = JSON.parse(localStorage.getItem("_data_info_empresa_"));
        this.state = {
            ejercicios: [],
            datalist: [],
            empresa_id: data.empresa_id,
            openscreen: true,
            mensaje: '',
            notificacion: false,
        }
    }

    componentDidMount() {
        this.getData();
    }
    getData() {
        var objectinfo = {
            empresa_id: this.state.empresa_id
        }
        api.post('getlistobligacionesinformativas', objectinfo)
            .then(response => response.data)
            .then(data => {
                if (data.estatus === 200) {
                    if (data.info.length > 0)
                        this.setState({
                            datalist: ((data.info).filter((i) => i.NoOperacion === null)),
                            openscreen: false
                        });
                    else {
                        this.setState({
                            mensaje: data.info,
                            openscreen: false,
                            notificacion: true,
                            mensaje: 'No existe información para mostrar!!!.'
                        });
                        setTimeout(() => {
                            this.setState({ mensaje: '' });
                        }, 2000);
                    }
                }
                else {
                    this.setState({
                        mensaje: data.info,
                        openscreen: false,
                        notificacion: true,
                        mensaje: 'No existe información para mostrar!!!.'
                    });
                    setTimeout(() => {
                        this.setState({ mensaje: '' });
                    }, 2000);
                }
            });
    }
    render() {
        const { datalist } = this.state;
        return (
            <>
                <Backdrop open={this.state.openscreen} style={{ color: "#fff", background: "#B9B6B6", zIndex: 400, opacity: "0.5" }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                {
                    this.state.notificacion ? (
                        <div className="alert alert-warning alert-styled-right alert-dismissible">
                            <span className="font-weight-semibold">Notificación!</span> No existe información para mostrar.
                        </div>
                    ) : null
                }
                <div className="content-wrapper">
                    <div className="content pt-0">
                        <div className="timeline timeline-center">
                            <div className="timeline-container">
                                {
                                    datalist.map((i, o) => (
                                        <div key={o}>
                                            {
                                                (i.nombre_mes === "Enero" || i.nombre_mes === "Marzo" || i.nombre_mes === "Mayo" || i.nombre_mes === "Julio" || i.nombre_mes === "Septiembre" || i.nombre_mes === "Noviembre") ?
                                                    <ViewLeft fecha={i.ejercicio_c + ' / ' + i.nombre_mes} key={o} details={i} titulo={i.ejercicio_c + ' - ' + i.nombre_mes} /> :
                                                    (i.nombre_mes === "Febrero" || i.nombre_mes === "Abril" || i.nombre_mes === "Junio" || i.nombre_mes === "Agosto" || i.nombre_mes === "Octubre" || i.nombre_mes === "Diciembre") ?
                                                        <ViewRight fecha={i.ejercicio_c + ' / ' + i.nombre_mes} key={o} details={i} titulo={i.ejercicio_c + ' - ' + i.nombre_mes} /> : null
                                            }
                                        </div>

                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )

    }

}

export default TimeLineInformativa;