import React, { Component, Fragment } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import { ClockLoader } from "react-spinners";
import { Tooltip } from '@material-ui/core';
import api from '../../../../app/Config/api';
class InvocePolices extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            /*{
                key: "uuid_cfdi",
                text: "UUID",
                className: "uuid_cfdi",
                align: "left",
                sortable: true,
                cell: (record, index) => {
                    return (
                        <Fragment>
                            <label className='text-danger'>{(record.uuid_cfdi)}</label>
                        </Fragment>
                    );
                }
            }, {
                key: "rfc",
                text: "RFC",
                className: "address",
                align: "left",
                sortable: true,
                cell: (record, index) => {
                    return (
                        <Fragment>
                            <label className='text-danger'>{(record.rfc)}</label>
                        </Fragment>
                    );
                }
            },*/

            {
                key: "numero_unico_identificacion_poliza",
                text: "# Póliza",
                className: "name",
                align: "left",
                sortable: true,
                cell: (record, index) => {
                    return (
                        <Fragment>
                            <label className='text-danger'>{(record.numero_unico_identificacion_poliza)}</label>
                        </Fragment>
                    );
                }
            },

            {
                key: "fecha",
                text: "Fecha",
                className: "text-center",
                align: "center",
                sortable: true,
                cell: (record, index) => {
                    return (
                        <Fragment>
                            <label>{(record.fecha)}</label>
                        </Fragment>
                    );
                }
            },
            {
                key: "monto_total",
                text: "Factura",
                className: "text-right",
                sortable: true,
                align: "center",
                cell: (record, index) => {
                    return (
                        <Fragment>
                            <label>{(record.monto_total).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</label>
                        </Fragment>
                    );
                }
            },
            {
                key: "debe",
                text: "Debe",
                className: "text-right",
                sortable: true,
                align: "center",
                cell: (record, index) => {
                    return (
                        <Fragment>
                            <label>{(record.debe).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</label>
                        </Fragment>
                    );
                }
            },
            {
                key: "haber",
                text: "Haber",
                className: "text-right",
                sortable: true,
                align: "center",
                cell: (record, index) => {
                    return (
                        <Fragment>
                            <label>{(record.haber).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</label>
                        </Fragment>
                    );
                }
            }

        ];
        this.config = {
            filename: "PolicesVSFacturas",
            page_size: 10,
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,
            button: {
                excel: true,
                print: true,
            },
            language: {
                length_menu: "Mostrar _MENU_ registros por página",
                filter: "Buscar en registros ...",
                info: "Mostrando _START_ a _END_ de _TOTAL_ entradas",
                pagination: {
                    first: "Primero",
                    previous: "Previa",
                    next: "próximo",
                    last: "Última"
                }
            },
            no_data_text: "No se encontraron registros"
        }

        this.state = {
            records: [],
            loading: true,
            rfc: this.props.data.rfc,
            uuid: this.props.data.uuid
        }
    }
    componentDidMount() {
        ///console.log(this.props)
        this.get_data_();
    }
    get_data_ = async () => {
        let data_ = await api.post("filter-uuid-polices-factura", {
            uuid: this.props.data.uuid,
            empresa_id: this.props.data.empresa_id,
        });
        setTimeout(() => {
            this.setState({
                records: data_.data,
                loading: false
            })
        }, 2000);

    }

    render() {
        return (
            <>
                {
                    this.state.loading ?
                        (
                            <div style={{
                                margin: 40,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <ClockLoader color={"#ff7043"} loading={this.state.loading} size={50} />
                            </div>
                        ) :
                        (
                            <div className="card">
                                <div className="card-header bg-transparent border-bottom header-elements-inline">
                                    <h6 className="card-title text-danger">{this.state.rfc + " - " + this.state.uuid}</h6>
                                    <div className="header-elements">
                                        <span className='text-primary' style={{ cursor: 'pointer' }} onClick={() => this.props.hiddeview()}><i className="icon-arrow-left7 mr-2" />Regresar</span>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <ReactDatatable
                                        config={this.config}
                                        records={this.state.records}
                                        columns={this.columns}
                                        extraButtons={this.extraButtons}
                                    />
                                </div>
                            </div>

                        )
                }
            </>
        );
    }
}

export default InvocePolices;