import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Tabs, Tab, Typography, Box } from '@material-ui/core';
import DeclaracionAnual from './declaracionanual';
import IsrAppBarChart from './report_effective_income_tax_rates';
import EvolutionIncome from './evolution-income';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `nav-tab-${index}`,
        'aria-controls': `nav-tabpanel-${index}`,
    };
}

function LinkTab(props) {
    return (
        <Tab
            component="a"
            onClick={(event) => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}



export default function NavTabsEffectiveIncomeTax() {

    const [value, setValue] = React.useState(0);
    const [declaracion_anual, setDeclaracionAnual] = useState('');
    const [rates_effective, setRatesEffective] = useState('');
    const [incomeEvolutions, setEvolutions] = useState('');
    useEffect(() => {
        setDeclaracionAnual(<DeclaracionAnual />);
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue === 0) {
            setDeclaracionAnual(<DeclaracionAnual />);
            setRatesEffective('');
            setEvolutions('');
        }
        if (newValue === 1) {
            setRatesEffective(<IsrAppBarChart />);
            setDeclaracionAnual('');
            setEvolutions('');
        }
        if (newValue === 2) {
            setRatesEffective('');
            setDeclaracionAnual('');
            setEvolutions(<EvolutionIncome />);
        }
    };

    return (
        <div>
            <AppBar position="static">
                <Tabs
                    variant="fullWidth"
                    value={value}
                    onChange={handleChange}
                    aria-label="nav tabs example"
                >
                    <LinkTab label="Declaraciones anuales" href="/anuals" {...a11yProps(0)} />
                    <LinkTab label="Tasa efectivas de ISR" href="/rates" {...a11yProps(1)} />
                    <LinkTab label="Evolución de ingresos " href="/evolucion" {...a11yProps(2)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                {declaracion_anual}
            </TabPanel>
            <TabPanel value={value} index={1}>
                {rates_effective}
            </TabPanel>
            <TabPanel value={value} index={2}>
                {incomeEvolutions}
            </TabPanel>
        </div>
    );
}
