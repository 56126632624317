import React, { Component } from 'react';

import Skeleton from '@material-ui/lab/Skeleton';
import api from '../../Config/apifiles';
import DateDynamic from './date_';
///import axios from 'axios';
import axios from 'axios';
class ProveedorGob extends Component {
    constructor(props) {
        super(props);
        let data_app = JSON.parse(localStorage.getItem("datavalidate"));
        let data_ = JSON.parse(localStorage.getItem("_data_filter_rfc"));
        console.log(data_);
        //console.log(data_);
        //GET_VALIDATE_PERIOD_CLOSE
        //_get_apertura_cierre_anterior_
        this.state = {
            data: [],
            nombre: data_.nombre,
            skeleton: true,
            rfc: data_.rfc,
            enviar: 'SI',
            correo: '',
            empresa_id: data_app.id,
            message: 1,
            isLoading: false
        }
        this.savehandleemail = this.savehandleemail.bind(this);
    }
    componentDidMount() {
        let _get_date_now_ = new Date();
        let ft_ = {
            ejercicio: _get_date_now_.getFullYear(),
            periodo: String(_get_date_now_.getMonth() + 1).padStart(2, '0'),
            rfc: this.state.rfc,
            empresa_id: this.state.empresa_id
        }
        this.getlist(ft_);
    }
    getlist = async (objs) => {
        let data = await api.post("getlist-vendors-filter", objs);
        //let data_mail = await axios.post("http://localhost:3020/api/app-select-email-cumplimiento", this.state);
        let data_mail = await api.post("app-select-email-cumplimiento", this.state);
        this.setState({ data: data.data, skeleton: false, correo: (data_mail.data).email })
    }
    hendleSelectData = (t) => {
        let parameters = t.split('-');
        let ft_ = {
            ejercicio: parameters[1],
            periodo: parameters[0],
            rfc: this.state.rfc
        }
        this.getlist(ft_);
    }
    handlesetsendmail = (f) => {
        this.setState({ enviar: f.target.value });
    }
    handlesetemail = (f) => {
        this.setState({ correo: f.target.value });
    }
    savehandleemail = async (t) => {
        this.setState({ isLoading: true });
        t.preventDefault();
        //let data_ = await axios.post('http://localhost:3020/api/generatelink', this.state);
        let data_ = await api.post('generatelink', this.state);
        this.setState({ message: 2 });
        setTimeout(() => {
            this.setState({ message: 1, isLoading: false });
        }, 4000);
    }

    render() {
        return (
            <>
                <div className="content-wrapper">
                    <div className="page-header border-bottom-0">
                        <div className="page-header-content header-elements-md-inline">
                            <div className="page-title d-flex">
                                <a href="#/app/catalogs/vendorscompany" style={{ color: 'white' }}><h4><i className="icon-arrow-left52 mr-2" /> <span className="font-weight-semibold">Listado de proveedores</span></h4></a>
                                <a href="#" className="header-elements-toggle text-default d-md-none"><i className="icon-more" /></a>
                            </div>
                            <h6>{this.state.nombre}</h6>
                            <div className="header-elements d-none mb-3 mb-md-0">
                                <div className="d-flex justify-content-center" style={{ display: 'none' }}>
                                    <a href="#" className="btn btn-link btn-float text-default"></a>
                                    <a href="#" className="btn btn-link btn-float text-default"></a>
                                    <a href="#" className="btn btn-link btn-float text-default"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        (this.state.skeleton) ?
                            (
                                <div className="page-content">
                                    <div className="content-wrapper">
                                        <div className="content pt-0">
                                            <br></br>
                                            <br></br>
                                            <div className="card">
                                                <Skeleton animation="wave" variant="text" height={40} />
                                                <Skeleton animation="wave" variant="text" height={40} />
                                                <Skeleton animation="wave" variant="text" height={40} />
                                                <Skeleton animation="wave" variant="text" height={40} />
                                                <Skeleton animation="wave" variant="text" height={40} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) :
                            (
                                <div className="content pt-0">

                                    <div id="accordion-styled">
                                        <div className="card">
                                            <div className="card-header bg-danger">
                                                <h6 className="card-title">
                                                    <a data-toggle="collapse" className="text-body collapsed" href="#accordion-styled-group1" aria-expanded="true">Detalle del cumplimiento</a>
                                                </h6>
                                            </div>
                                            <div id="accordion-styled-group1" className="collapse show" data-parent="#accordion-styled" style={{}}>
                                                <div className="card-body">
                                                    <div className="card">
                                                        <div className="card-header header-elements-inline">
                                                            <h6 className="card-title">Detalle del cumplimiento contable - fiscal</h6>
                                                            <div className="header-elements">
                                                                <div className="list-icons">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-lg-4">
                                                                    <div className="form-group">
                                                                        <label>Seleccione la fecha: </label>
                                                                        <DateDynamic setDate={this.hendleSelectData} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3">
                                                                </div>
                                                                <div className="col-lg-4">
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-1"></div>
                                                                <div className="col-lg-10">
                                                                    <ul className="media-list">
                                                                        {
                                                                            (this.state.data || []).map((t, r) => (
                                                                                <li className="media" key={r}>
                                                                                    <div className="mr-3">
                                                                                        {
                                                                                            (
                                                                                                (t.status) === "NOT") ?
                                                                                                (
                                                                                                    <a href="#/app/catalogs/details-vendors" className="btn btn-outline-danger rounded-pill border-2 btn-icon">
                                                                                                        <i className="icon-blocked"></i>
                                                                                                    </a>
                                                                                                ) : (
                                                                                                    (t.status) === "NEGATIVE" ?
                                                                                                        (
                                                                                                            <a href="#/app/catalogs/details-vendors" className="btn btn-outline-danger rounded-pill border-2 btn-icon">
                                                                                                                <i className="icon-cross2" />
                                                                                                            </a>
                                                                                                        ) : (
                                                                                                            <a href="#/app/catalogs/details-vendors" className="btn btn-outline-success rounded-pill border-2 btn-icon">
                                                                                                                <i className="icon-checkmark3" />
                                                                                                            </a>
                                                                                                        )
                                                                                                )
                                                                                        }
                                                                                    </div>
                                                                                    <div className="media-body">
                                                                                        {t.descripcion}
                                                                                        <div className="text-muted font-size-sm"></div>
                                                                                    </div>
                                                                                    <div className="media-body">
                                                                                        {descriptions_opinion(t)}
                                                                                        <div className="text-muted font-size-sm"></div>
                                                                                    </div>
                                                                                </li>
                                                                            ))
                                                                        }
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header bg-teal">
                                                <h6 className="card-title">
                                                    <a className="text-body collapsed" data-toggle="collapse" href="#accordion-styled-group2" aria-expanded="false">Configuración extra</a>
                                                </h6>
                                            </div>
                                            <div id="accordion-styled-group2" className="collapse" data-parent="#accordion-styled" style={{}}>
                                                <div className="card-body">
                                                    {
                                                        this.state.message === 2 ? (
                                                            <>
                                                                {
                                                                    (this.state.enviar === "SI") ? (
                                                                        <div className="alert alert-success alert-styled-left alert-arrow-left alert-dismissible">
                                                                            <span className="font-weight-semibold">Información!</span> Se ha enviado correctamente el correo!.
                                                                        </div>
                                                                    ) :
                                                                        (
                                                                            <div className="alert alert-success alert-styled-left alert-arrow-left alert-dismissible">
                                                                                <span className="font-weight-semibold">Información!</span> Se ha configurado correctamente!.
                                                                            </div>
                                                                        )
                                                                }
                                                            </>
                                                        ) : null
                                                    }
                                                    <div className="row">
                                                        <div className="col-sm-1">
                                                        </div>
                                                        <div className="col-sm-8">
                                                            <form onSubmit={this.savehandleemail}>
                                                                <fieldset>
                                                                    <div className="form-group">
                                                                        <label>Correo electrónico:</label>
                                                                        <input type="text" className="form-control" placeholder="Correo electrónico" value={this.state.correo} required onChange={this.handlesetemail} />
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label className="d-block">Enviar solicitud al proveedor:</label>
                                                                        <label className="custom-control custom-radio custom-control-inline">
                                                                            <input type="radio" className="custom-control-input" value="SI" name="gender2" defaultChecked onChange={this.handlesetsendmail} />
                                                                            <span className="custom-control-label">Si</span>
                                                                        </label>
                                                                        <label className="custom-control custom-radio custom-control-inline">
                                                                            <input type="radio" className="custom-control-input" value="NO" name="gender2" onChange={this.handlesetsendmail} />
                                                                            <span className="custom-control-label">No</span>
                                                                        </label>
                                                                    </div>
                                                                </fieldset>
                                                                <div className="text-right">
                                                                    {
                                                                        this.state.isLoading ? (
                                                                            <button type="button" className="btn btn-light">Guardar <i className="icon-spinner10 spinner"></i></button>
                                                                        ) : <button type="submit" className="btn btn-primary">Guardar <i className="icon-paperplane ml-2" /></button>
                                                                    }
                                                                </div>
                                                            </form>
                                                        </div>
                                                        <div className="col-sm-2">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card" style={{ display: 'none' }}>
                                            <div className="card-header bg-primary">
                                                <h6 className="card-title">
                                                    <a className="text-body" data-toggle="collapse" href="#accordion-styled-group3" aria-expanded="false">Historial de constancia de cumplimiento</a>
                                                </h6>
                                            </div>
                                            <div id="accordion-styled-group3" className="collapse" data-parent="#accordion-styled" style={{}}>
                                                <div className="card-body">
                                                    3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                    }
                </div>

            </>
        );
    }
}

export default ProveedorGob;
function descriptions_opinion(p) {
    const displaypdfCumplimiento = async () => {
        window.$("#modalfullconstancia").modal("show");
    }
    const displaypdfCumplimientoNegative = async () => {
        window.$("#modalfullconstanciaNegative").modal("show");
    }
    if (p.clave === "OC") {

        let _p_ = {
            directorio: p.dir
        }
        const link = 'https://e-xpertum.com.mx/adminfile/api/displaypdf?query=' + JSON.stringify(_p_)
        if (p.status === "NEGATIVE")
            return <>
                <table>
                    <tbody>
                        <tr>
                            <td><a href="#/app/catalogs/details-vendors">La opinión de cumplimiento es: </a></td>
                            <td style={{ width: '5%' }}></td>
                            <td><span className="badge badge-danger d-block">Negativo</span></td>
                            <td style={{ width: '10%' }}></td>
                            <td onClick={displaypdfCumplimientoNegative} style={{ cursor: 'pointer' }}><i className="icon-file-pdf text-success mr-3"></i></td>
                        </tr>
                    </tbody>
                </table>
                <div id="modalfullconstanciaNegative" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                    <div className="modal-dialog modal-full">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Constancia de cumplimiento</h5>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>
                            <div className="modal-body">
                                <div className="embed-container">
                                    <iframe src={link} width="100%"
                                        frameBorder="0" allowFullScreen
                                    >
                                    </iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        else if (p.status === "POSITIVE")
            return <>
                <table>
                    <tbody>
                        <tr>
                            <td><a href="#/app/catalogs/details-vendors">La opinión de cumplimiento es: </a></td>
                            <td style={{ width: '5%' }}></td>
                            <td> <span className="badge badge-success d-block">Positivo</span></td>
                            <td style={{ width: '10%' }}></td>
                            <td onClick={displaypdfCumplimiento} style={{ cursor: 'pointer' }}><i className="icon-file-pdf text-success mr-3"></i></td>
                        </tr>
                    </tbody>
                </table>
                <div id="modalfullconstancia" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                    <div className="modal-dialog modal-full">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Constancia de cumplimiento</h5>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>
                            <div className="modal-body">
                                <div className="embed-container">
                                    <iframe src={link} width="100%"
                                        frameBorder="0" allowFullScreen
                                    >
                                    </iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

    }
    if (p.clave === "CE") {
        if (p.status === "NEGATIVE")
            return <>
                <table className="table">
                    <tbody>
                        <tr>
                            <td><a href="#/app/catalogs/details-vendors">No se ha generado la contabilidad electrónica </a></td>
                        </tr>
                    </tbody>
                </table>
            </>
    }
    if (p.clave === "DF") {
        if (p.status === "NEGATIVE")
            return <>
                <table className="table">
                    <tbody>
                        <tr>
                            <td><a href="#/app/catalogs/details-vendors">No se han presentado las declaraciones federales </a></td>
                        </tr>
                    </tbody>
                </table>
            </>
    }
    if (p.clave === "69B") {
        if (p.status === "NEGATIVE")
            return <>
                <table className="table">
                    <tbody>
                        <tr>
                            <td><a href="#/app/catalogs/details-vendors">Se presume que realizó operaciones con proveedores inexistentes amparadas en comprobantes fiscales</a></td>
                        </tr>
                    </tbody>
                </table>
            </>
    }
}