import React, { Component } from 'react'
import MaskedInput from 'react-text-mask'
class contacto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            nombre: '',
            ape_paterno: '',
            ape_materno: '',
            telefono: '',
            correo: '',
            proviene: 'Contacto',
        };
    }
    handleChangeForm = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });
        setTimeout(() => {
            this.props.data(this.state)
        }, 200);
    }
    render() {
        return (
            <>
                <div className="card">
                    <div className="card-body">
                        <div autoComplete="off">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group row">
                                        <div className="col-lg-12">
                                            <input type="text" className="form-control" autoComplete="off" placeholder="Nombre(s)" name="nombre" onChange={this.handleChangeForm} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <input type="text" placeholder="Apellido paterno" autoComplete="off" className="form-control" name="ape_paterno" id="ape_paterno" onChange={this.handleChangeForm} />
                                        </div>
                                        <div className="col-md-6">
                                            <input type="text" placeholder="Apellido materno" autoComplete="off" className="form-control" name="ape_materno" id="ape_materno" onChange={this.handleChangeForm} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <MaskedInput className="form-control" placeholder="Teléfono" autoComplete="off" name="telefono" onChange={this.handleChangeForm}
                                                mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <input type="text" placeholder="Correo electrónico" autoComplete="off" className="form-control" name="correo" id="correo" onChange={this.handleChangeForm} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default contacto;