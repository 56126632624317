import React, { Component } from 'react';
import './style.css';
class TeminosCondiciones extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <>
                <div className="content-wrapper dataview">
                    <div className="page-header border-bottom-0">

                    </div>
                    <div className="content pt-0">
                        <div className="card">
                            <div className="card-body">
                                <div className="container-fluid bg-2 text-center">
                                    <h4 style={{ "text-align": " center" }}><strong>Términos y Condiciones.</strong> </h4>
                                    <h5 style={{ "text-align": " center" }}><strong>E-XPERTUM</strong></h5>
                                    <h6 style={{ textAlign: 'left' }}>Última actualización 16 de enero de 2021.</h6>
                                </div>
                                <div className="container-fluid bg-2 text-center">
                                    <p><strong>1. Acuerdo de Términos.</strong></p>
                                    <p style={{ "padding-left": "3%" }} className="mb-3 text-muted d-block">1.1. Estos Términos y Condiciones constituyen un acuerdo legalmente
                                        vinculante hecho entre usted, ya sea persona física o persona moral (“Usted” y/o “Usuario”) y MERCANETICA ESTRATEGIAS, S.A. DE C.V.
                                        (“E-XPERTUM”), en relación con el uso y acceso a los servicios prestados por E-XPERTUM dentro del sitio
                                        (https://e-xpertum.com/), así como en cualquier aplicación relacionada (el “Sitio”).

                                        El Sitio ofrece los siguientes servicios:</p>
                                    <ol style={{ "padding-left": "6%" }} className="mb-3 text-muted d-block">
                                        <li>Validación de CFDI's (Alertas conforme a las publicaciones de listas negras de acuerdo con el artículo
                                            69-B del Código  Fiscal de la Federación)</li>
                                        <li>Servicio de Nómina (Maquila y dispersión de nómina)</li>
                                        <li>Servicio Jurídico (Fiscal, Laboral, Corporativo)</li>
                                        <li>Auditoría (Cumplimiento: Obligaciones, Tareas, Expediente Fiscal)</li>
                                        <li>Contabilidad (Contabilidad electrónica)</li>
                                    </ol>
                                    <p style={{ "padding-left": "3%" }} className="mb-3 text-muted d-block">Lo anterior en su conjunto (los “Servicios”). Usted acepta que al acceder al Sitio y/o a los Servicios, ha leído, entendido y acepta
                                        regirse por todos estos Términos y Condiciones.
                                        Dichos Servicios tienen los alcances que se describen de manera descriptiva en el Sitio.
                                    </p>
                                    <p style={{ "padding-left": "3%" }} className="mb-3 text-muted d-block">
                                        Al usar y acceder a cualquiera de los servicios, está de acuerdo y queda sujeto a estos Términos y
                                        Condiciones.
                                    </p>
                                    <p style={{ "padding-left": "3%" }} className="mb-3 text-muted d-block">1.2. E-XPERTUM bajo ninguna circunstancia brinda asesoría sobre la contabilidad del
                                        usuario, por lo que la veracidad e integridad de su información contable, fiscal y financiera es responsabilidad exclusiva del Usuario. El
                                        Sitio es una herramienta mediante la cual se prestan los Servicios de conformidad con la legislación aplicable. E-XPERTUM
                                        no es responsable en ningún momento de la veracidad e integridad de la información, ni de su presentación a las
                                        autoridades competentes, por lo que es exclusiva responsabilidad del Usuario el cumplimiento de las disposiciones
                                        fiscales y/o de cualquier otra disposición aplicable.
                                    </p>
                                    <p style={{ "padding-left": "3%" }} className="mb-3 text-muted d-block">
                                        1.3. Estos Términos y Condiciones podrán ser modificados en cualquier momento. La versión actualizada de
                                        estos Términos y Condiciones se indicará con una fecha “Revisada” actualizada y la versión actualizada entrará en vigor tan
                                        pronto sea accesible en el Sitio. Usted es responsable de revisar estos Términos y Condiciones para mantenerse
                                        informado de las actualizaciones. El uso o acceso al Sitio implica que Usted ha aceptado expresamente cualquier cambio o
                                        actualización de los Términos y Condiciones.
                                    </p>
                                    <p style={{ "padding-left": "3%" }} className="mb-3 text-muted d-block">
                                        1.4. Podemos actualizar o modificar el Sitio de tiempo en tiempo para reflejar cambios o ajustes en nuestros
                                        Servicios, las necesidades de nuestros usuarios y/o nuestras prioridades comerciales, entre otras razones.
                                    </p>
                                    <p style={{ "padding-left": "3%" }} className="mb-3 text-muted d-block">
                                        1.5. Nuestro Sitio está dirigido a residentes en los Estados Unidos Mexicanos. La información proporcionada
                                        en el Sitio no está destinada a ser distribuida o utilizada por ninguna persona o entidad en ninguna jurisdicción o país
                                        donde dicha distribución o uso sea contrario a la ley o regulación o que nos someta a cualquier requisito de registro,
                                        trámite o autorización dentro de dicha jurisdicción o país.
                                    </p>
                                    <p style={{ "padding-left": "3%" }} className="mb-3 text-muted d-block">
                                        1.6. El Sitio está destinado a usuarios que tengan al menos 18 años, por lo que está prohibido registrarse
                                        en el Sitio o utilizar los Servicios de este a personas menores. Las cuentas creadas por menores de 18 años serán
                                        canceladas y no se reembolsará, devolverá o compensará con otras cuentas, cantidad alguna.
                                    </p>
                                </div>
                                <div className="container-fluid bg-2 text-center">
                                    <p><strong>2. Uso Aceptable.</strong></p>
                                    <p className="mb-3 text-muted d-block">
                                        2.1. Está prohibido acceder y/o utilizar el Sitio para cualquier propósito distinto de la prestación de los
                                        Servicios que ponemos a su disposición a través del mismo. Está prohibido utilizar el Sitio para fines
                                        comerciales, distintos de aquellos respaldados y aprobados por E-XPERTUM. E-XPERTUM se reserva su derecho de ejercer
                                        cualquier tipo de acción en contra de quien, sin autorización, haga uso distinto a la prestación de sus servicios, lo haga con
                                        fines comerciales o cualquier uso distinto de aquellos respaldados y aprobados.
                                    </p>
                                    <p style={{ "padding-left": "3% " }} className="mb-3 text-muted d-block">
                                        2.2. Como Usuario de este Sitio, acepta abstenerse en todo momento de:</p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        2.2.1. Recuperar sistemáticamente datos u otro contenido del Sitio a una base de datos de compilación o
                                        directorio sin nuestro permiso previo, expreso y por escrito. </p>

                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">2.2.2. Hacer cualquier uso no autorizado del Sitio, incluida la recopilación de
                                        nombres de usuario y/o direcciones de correo electrónico de otros usuarios para enviar correos electrónicos no solicitados, crear cuentas de
                                        usuario con finalidades distintas al uso correcto de los Servicios o hacer uso indebido de la información extraída del
                                        Sitio.
                                    </p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        2.2.3. Eludir, deshabilitar o interferir de otra manera con las funciones relacionadas con la seguridad del
                                        Sitio, incluidas las funciones que impiden o restringen el uso o la copia de cualquier contenido o imponen
                                        limitaciones en el  uso.
                                    </p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        2.2.4. Engañar y/o defraudar a E-XPERTUM y/o a otros usuarios, especialmente en cualquier intento de obtener
                                        información confidencial, como contraseñas de usuario, entre otros datos.
                                    </p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        2.2.5. Hacer uso indebido de nuestros servicios de soporte o enviar informes falsos de cualquier tipo,
                                        incluyendo informes de abuso o mala conducta.
                                    </p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        2.2.6. Participar en cualquier uso automatizado del sistema, como el uso de scripts para enviar comentarios
                                        o mensajes, o el uso de minería de datos, robots o herramientas similares de recopilación y extracción de datos.
                                    </p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        2.2.7. Interferir, afectar negativamente, interrumpir o crear una carga indebida en el Sitio o a las redes y
                                        Servicios conectados o prestados a través del Sitio.
                                    </p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        2.2.8. Hacerse pasar por otro usuario o persona, o utilizar el nombre de usuario y/o contraseña de otro
                                        usuario.
                                    </p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        2.2.9. Vender o transferir su perfil por cualquier título y/o medio.
                                    </p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        2.2.10. Usar cualquier información obtenida del Sitio para acosar, abusar o dañar a otra persona, así como
                                        para llevar a cabo cualquier actividad ilícita.
                                    </p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        2.2.11. Usar total o parcialmente el Sitio o su contenido como parte de cualquier esfuerzo o iniciativa para
                                        competir con nosotros o para crear un esfuerzo o empresa comercial que directa o indirectamente genere ingresos.
                                    </p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        2.2.12. Descifrar, descompilar, desensamblar y/o aplicar ingeniería inversa a cualquier software que
                                        comprenda o de alguna manera forme parte del Sitio.
                                    </p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        2.2.13. Intentar acceder a cualquier parte del Sitio a la que tenga restringido el acceso.
                                    </p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        2.2.14. Acosar, molestar, intimidar o amenazar a cualquier de nuestros empleados, agentes u otros usuarios.
                                    </p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        2.2.15. Copiar o adaptar el Software del Sitio, incluidos, entre otros, PHP, HTML, u otro Código empleado en
                                        el mismo.
                                    </p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        2.2.16. Cargar o transmitir (o intentar cargar o transmitir) virus, caballos de troya u otro material o
                                        elemento que interfiera, obstaculice o afecte negativamente el uso y disfrute adecuado, seguro e ininterrumpido del
                                        Sitio.
                                    </p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        2.2.17. Despreciar, dañar y/o perjudicar negativamente, en nuestra opinión, a nosotros y/o al Sitio.
                                    </p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        2.2.18. Usar el Sitio de manera incompatible con las leyes y/o regulaciones aplicables.
                                    </p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        2.2.19. Insinuar y/o representar falsamente una relación con E-XPERTUM u otra empresa con la que no tenga
                                        relación.
                                    </p>
                                </div>

                                <div className="container-fluid bg-2 text-center">
                                    <p><strong>3. Información que nos proporciona.</strong></p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        3.1. Usted declara y garantiza que: a) Toda la información de registro que proporcione es y será verdadera,
                                        precisa, actual y completa, y estará relacionada con usted y no con cualquier tercero; b) mantendrá la precisión de dicha
                                        información y la actualizará de inmediato según sea necesario; c) mantendrá la confidencialidad y resguardo de su contraseña
                                        en todo momento, y será responsable de todo uso de su contraseña y su cuenta; d) tiene capacidad legal y acepta
                                        cumplir con estos Términos y Condiciones; y e) no es menor de edad conforme a la legislación Mexicana.
                                    </p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        3.2. Si sabe o sospecha que alguien que no sea usted conoce su información de usuario y/o contraseña, debe
                                        notificarnos de inmediato al sistemas@e-xpertum.com.
                                    </p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        3.3. Usted reconoce y acepta que en caso de proporcionar cualquier información falsa, inexacta, no actual o
                                        incompleta, E-XPERTUM podrá suspender o cancelar su cuenta de inmediato y sin previo aviso. Podemos eliminar o cambiar
                                        algún nombre de usuario que seleccione si determinamos que dicho nombre de usuario es inapropiado.
                                    </p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        3.4. Usted acepta que es responsable de toda la documentación e información que comparta, publique o
                                        proporcione a E-XPERTUM a través del Sitio, por lo que en caso de que cualquier Servicio se preste de manera incorrecta en
                                        virtud de información errónea compartida o proporcionada directa o indirectamente por usted será su exclusiva
                                        responsabilidad.
                                    </p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        3.5. Al publicar o enviar contenido al Sitio, el Usuario otorga automáticamente a E-XPERTUM el derecho a
                                        usar, reproducir, presentar, exhibir, adaptar, modificar, distribuir o promover el contenido de cualquier forma y
                                        para cualquier fin. Asimismo, el usuario acepta y garantiza que tiene los derechos de propiedad necesarios
                                        para dicho contenido o control sobre dicho contenido y que la publicación y uso del contenido de parte de
                                        E-XPERTUM no infringe ni viola los derechos de ningún tercero, y no constituye un delito o infracción a disposición o
                                        acuerdo alguno.
                                        El usuario acepta y confirma que es exclusivamente responsable por todo el contenido que publique o envíe a
                                        través del Sitio. El usuario: i) otorga a E-XPERTUM una autorización de uso, gratuita y no exclusiva, sobre los datos
                                        compartidos; y ii) se obliga a sacar en paz y salvo a E-XPERTUM, y, de forma enunciativa y no limitativa, a sus filiales, funcionarios,
                                        empleados y representantes, de cualquier procedimiento, acción, demanda o reclamación por cualquier violación de
                                        propiedad intelectual derivada del uso de datos compartidos que el propio Usuario proporcione a través del Sitio.
                                    </p>
                                </div>
                                <div className="container-fluid bg-2 text-center">
                                    <p><strong>4. Cuenta del Usuario.</strong></p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        4.1. Cada usuario deberá registrarse para acceder al Sitio, para ello es necesario que cumpla con los
                                        requisitos establecidos en estos Términos y Condiciones y proporcione la información que para ello requiera el Sitio, o
                                        la que sea requerida por E-XPERTUM.
                                    </p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        4.2. E-XPERTUM en cualquier momento puede solicitar cualquier tipo de documento o dato adicional a efecto de
                                        corroborar la Cuenta y la identidad del usuario, así como suspender temporal o definitivamente las cuentas cuyos datos
                                        no haya sido posible de confirmarla. En consecuencia, usted autoriza a E-XPERTUM para obtener, verificar y registrar la
                                        información de la persona que cree la cuenta, con el fin de corroborar la identidad de la persona. De manera enunciativa
                                        más no limitativa, E-XPERTUM, podrá solicitar el nombre, domicilio, fecha de nacimiento, número de folio de
                                        identificación, entre otros, con la finalidad de identificar al Usuario.
                                    </p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        4.3. Usted y/o el Usuario son los únicos responsables y se comprometen a mantener de forma segura y
                                        confidencial las contraseñas de acceso a la cuenta abierta en el Sitio. Asimismo el usuario tomará todas las medidas
                                        razonables para resguardar y proteger las contraseñas para acceder a su cuenta.
                                    </p>
                                </div>
                                <div className="container-fluid bg-2 text-center">
                                    <p><strong>5. Cancelación de Cuenta.</strong></p>

                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        5.1. Además de las hipótesis mencionadas en los demás puntos de estos Términos y condiciones, la falta de
                                        pago oportuno de cualquier cantidad a cargo del Usuario vinculada a su cuenta en el Sitio,
                                        podrá provocar que la cuenta sea eliminada sin previo aviso junto con toda la información del Usuario almacenada
                                        en los servidores de E-XPERTUM, así como suspendidos los Servicios sin responsabilidad alguna para esta última. Lo
                                        anterior en caso de que la mora se extienda por más de tres meses de calendario. El Usuario podrá cancelar su cuenta
                                        mediante aviso por escrito a: <strong>Mercanética estrategias sa de cv</strong>, en cuyo caso E-XPERTUM evaluará y en su
                                        caso autorizará la devolución únicamente de pagos correspondientes a Servicios no devengados a la fecha en
                                        que surta efectos la cancelación, según lo indique E-XPERTUM. Si un usuario decide por cualquier causa dejar de usar E-XPERTUM, será su responsabilidad la
                                        obtención del respaldo de su información desde el mismo sistema; lo anterior, podrá realizarlo a través del correo
                                        electrónico <strong>entas@e-xpertum.com</strong>v y deberá contar con saldo pre pagado para poder acceder a
                                        esta opción. En dicho caso, E-XPERTUM interpretará que el usuario ya no requiere más los Servicios y por lo tanto otorga su autorización a
                                        E-XPERTUM para borrar sus datos y cualquier archivo generado por el usuario que se encuentre hospedado en nuestros
                                        servidores.
                                    </p>
                                </div>

                                <div className="container-fluid bg-2 text-center">
                                    <p><strong>6. Contratación de los Servicios</strong></p>

                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        6.1. Es obligatorio, para el Usuario, completar toda la información solicitada por E-XPERTUM. Al momento de
                                        dar de alta su cuenta como Usuario; usted deberá proporcionar datos válidos, exactos, precisos, actuales y verdaderos
                                        para poder utilizar los Servicios que brinda el Sitio. El Usuario garantiza, en todo momento la veracidad y
                                        autenticidad de los datos e información ingresada en el Sitio. E-XPERTUM no se responsabiliza en ninguna circunstancia, por la
                                        certeza de los datos ingresados por usted o por los usuarios del Sitio, ni de la información que por virtud de dichos
                                        datos se genere, siendo absoluta responsabilidad de usted y/o del Usuario de la cuenta los efectos legales o de
                                        cualquier otro tipo que la misma genere frente a cualquier autoridad competente.
                                    </p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        6.2. La contratación de los Servicios deberá de realizarse por medio del Sitio. E-XPERTUM brinda a los
                                        usuarios un plan de suscripción mensual para el uso de los Servicios. El uso de los Servicios está condicionado al prepago
                                        correspondiente al mes contratado. La tarifa que debe pagarse por los Servicios se expresa en pesos
                                        mexicanos, moneda de curso legal en los Estados Unidos Mexicanos.
                                    </p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        6.3. Los pagos se harán accediendo al Sitio. Es necesario efectuar el pago previo al mes de uso de los
                                        Servicios para poder utilizar los mismos. La vigencia de los Servicios será definida por el prepago realizado, esto quiere
                                        decir que los Servicios permanecerán activos siempre y cuando el usuario se encuentre al corriente en los pagos de las
                                        tarifas correspondientes a la suscripción de los Servicios.
                                    </p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        6.4. El día en que se realiza el pago de la suscripción será la misma fecha de corte.
                                    </p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        6.5. E-XPERTUM se reserva el derecho de actualizar las tarifas por Servicios en cualquier tiempo, a su sola
                                        discreción.
                                        El pago será cargado al momento de solicitar los Servicios. Al aceptar estos Términos y Condiciones, el
                                        usuario autoriza a E-XPERTUM a realizar el cargo automático a su tarjeta de crédito, débito, PayPal, u otra forma de pago.
                                        Todos los titulares de tarjeta de crédito o débito están sujetos a comprobaciones y autorizaciones por parte del
                                        emisor de la tarjeta. Al proporcionar voluntariamente el usuario información sobre su tarjeta de crédito o débito para la
                                        contratación y recurrencia de los Servicios, el usuario consiente su uso para el cargo correspondiente, y
                                        confirma que la tarjeta es suya y que cuenta con fondos suficientes o crédito disponible para cubrir los cargos.
                                    </p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        6.6. Sólo procederán las cancelaciones en caso de que se hubiere contratado un Servicio no deseado, y
                                        siempre y cuando:
                                        1) no sea un Servicio recurrente, y 2) no se haya empezado a prestar el Servicio. En caso de cancelaciones
                                        de Servicios que sean recurrentes, no aplicará ningún reembolso. En caso de cancelación de un pago o solicitud de
                                        reembolso, E-XPERTUM se reserva el derecho a solicitar la información correspondiente de la operación al usuario para
                                        confirmar su deseo. La aplicación, interpretación y administración de las políticas de cancelación y de reembolso
                                        corresponde única y exclusivamente a E-XPERTUM, incluyendo el plazo que considere razonable para realizar el reembolso de que se
                                        trate.
                                    </p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        6.7. E-XPERTUM se reserva el derecho a poder cambiar las tarifas de los Servicios en cualquier momento. Para
                                        ello, se notificará a los usuarios de cualquier cambio por medio del Sitio. En caso de que los usuarios no estén de
                                        acuerdo con la nueva tarifa deberán manifestarlo de manera expresa y por escrito dentro del plazo que 15 días naturales
                                        a partir de la publicación del cambio. En caso de no manifestar su negativa dentro de dicho plazo o el hecho de que el
                                        usuario continúe utilizando el Sitio y sus Servicios, se entenderá que está de acuerdo con la nueva tarifa.
                                    </p>
                                </div>
                                <div className="container-fluid bg-2 text-center">
                                    <p><strong>7. Propiedad Intelectual.</strong></p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        7.1. E-XPERTUM cuenta con la titularidad y propiedad exclusiva de los derechos otorgados por las leyes
                                        vigentes y/o tratados internacionales inherentes a la propiedad intelectual, relativa a todas las marcas, avisos
                                        comerciales, software y programas de computación en general, y demás figuras susceptibles de protección legal conforme a
                                        la Ley de la Propiedad Industrial y la Ley Federal del Derecho de Autor, con relación al Sitio, mediante los cuales
                                        promueva, comercialice y presten sus Servicios, incluyendo pero sin limitar: (i) la propiedad industrial derivada de
                                        títulos de registros de marcas y avisos comerciales otorgados por el Instituto Mexicano de la Propiedad Industrial;
                                        (ii) los derechos de autor respecto del programa(s) de computación, desarrollos de software, sistema(s) y/o sus
                                        derivados, otorgados por el Instituto Nacional de Derechos de Autor; y (iii) todos los desarrollos, know how, signos
                                        distintivos  y/o bienes o derechos que le correspondan en el ámbito de la propiedad intelectual (la “Propiedad
                                        Intelectual”).
                                    </p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        7.2. E-XPERTUM no concede ninguna licencia o autorización de uso de ninguna clase sobre la Propiedad
                                        Intelectual, salvo acuerdo expreso por escrito, por lo que el Usuario reconoce que: (i) no tiene derecho alguno sobre la
                                        Propiedad Intelectual, por lo que se obliga a respetar en todo momento los derechos que detenta E-XPERTUM sobre ésta;
                                        (ii) no podrá modificar, alterar, suprimir, copiar, reproducir, explotar o aprovechar por cualquier medio,ya sea
                                        total o parcialmente, incluyendo pero sin limitar, el contenido informativo generado por E-XPERTUM, la Propiedad
                                        Intelectual y/o cualquier indicación contenida en el Sitio.
                                    </p>
                                </div>

                                <div className="container-fluid bg-2 text-center">
                                    <p><strong>8. Atención al Usuario.</strong></p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        8.1. E-XPERTUM brinda por medio de un área de Soporte Técnico a todos los usuarios del Sitio atención para
                                        aclaración de dudas, comentarios o errores sobre la utilización del Sitio, a través del correo electrónico
                                        ventas@e-xpertum, en un horario de atención de lunes a viernes de 24/7 365 días del año, o por medio del chat del Sitio.
                                    </p>
                                </div>
                                <div className="container-fluid bg-2 text-center">
                                    <p><strong>9. Suspensión de Servicios.</strong></p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        9.1. E-XPERTUM podrá, sin previo aviso, suspender, desconectar, denegar o restringir acceso al
                                        Sitio: i) durante un fallo técnico del mismo, o durante la modificación o mantenimiento del Sitio; ii) si el Usuario
                                        usa el Sitio de E-XPERTUM de manera que viole los Términos y Condiciones, iii) si el Usuario hace algo o deja de
                                        hacer algo que en la opinión de E-XPERTUM pudiera tener como consecuencia el poner en peligro el funcionamiento o la
                                        integridad del Sitio, y iv) en caso de falta de pago oportuno por los Servicios prestados a través del Sitio.
                                    </p>
                                </div>
                                <div className="container-fluid bg-2 text-center">
                                    <p><strong>10. Privacidad.</strong></p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        10.1. La recopilación y el uso de datos personales que se origine en relación con los Servicios
                                        prestados en el Sitio se regirá conforme a lo establecido en el Aviso de Privacidad de E-XPERTUM, el cual se encuentra
                                        disponible en el Sitio, en cumplimiento a lo establecido por la Ley Federal de Protección de Datos Personales en Posesión
                                        de Particulares, su reglamento y demás disposiciones aplicables en la materia.
                                    </p>
                                </div>
                                <div className="container-fluid bg-2 text-center">
                                    <p><strong>11. Consentimiento para recepción de mensajes.</strong></p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        11.1. Al momento en que usted proporciona su número de teléfono y correo electrónico a través
                                        del Sitio, usted autoriza expresamente a E-XPERTUM para poder enviar mensajes de texto, correos electrónicos en relación
                                        con los Servicios prestados a través del mismo. En caso de que el Usuario desee optar por dejar de recibir
                                        mensajes o modificar la forma de contacto, deberá comunicarse con E-XPERTUM de acuerdo con los Términos y Condiciones a fin de
                                        solicitarlo.

                                    </p>
                                </div>
                                <div className="container-fluid bg-2 text-center">

                                    <p><strong> 12. Del funcionamiento del Sitio. </strong></p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        12.1. E-XPERTUM podrá llevar a cabo de manera discrecional mantenimiento, ajustes y/o actualizaciones ya sea
                                        de emergencia o de rutina en el Sitio, sin previo aviso al usuario, por lo que durante los periodos de
                                        mantenimiento, ajustes y/o actualizaciones el Sitio podría no estar disponible o accesible, sin por ello derive
                                        responsabilidad alguna  por parte de X-PERTUM.</p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        12.2. E-XPERTUM no se responsabiliza por cualquier daño o pérdida que sufran los usuarios de manera directa
                                        o indirecta por el uso del Sitio, ya sea por fallas en el mismo, en el servidor, en Internet en general, o por cualquier
                                        otra causa.
                                        E-XPERTUM tampoco será responsable por cualquier virus que pudiera infectar el equipo del Usuario como
                                        consecuencia del acceso o uso del Sitio o en virtud de cualquier transferencia de información o contenidos en el mismo. El
                                        Usuario no podrá exigirle responsabilidad alguna a E-XPERTUM, ni podrá exigirle el pago de ninguna cantidad por
                                        concepto de indemnización derivada de daños o perjuicios a causa de dificultades técnica o fallas de Internet.
                                    </p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        12.3. E-XPERTUM no garantiza el acceso y uso continuo del Sitio debido a que éste eventualmente puede no
                                        estar disponible debido a dificultades técnicas o a cualquier causa ajena a E-XPERTUM, sin que por ello se le
                                        pueda imputar responsabilidad a E-XPERTUM. Asimismo, considerando que el Sitio requiere de tecnología y de la
                                        participación y servicios de terceros, E-XPERTUM no garantiza ni se responsabiliza por los cambios en cualquiera de estos
                                        servicios y participaciones de terceros que puedan afectar de manera adversa al Sitio y/o a la prestación de los
                                        Servicios.
                                    </p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        12.4. E-XPERTUM no será responsable por cualquier retraso y/o incumplimiento derivado de la ocurrencia de
                                        algún caso fortuito o de fuerza mayor, como lo son desastres naturales, guerras, actos terroristas, pandemias,
                                        disturbios generalizados, incendios, terremotos, inundaciones, cortes de energía generales, huelgas, condiciones
                                        climáticas adversas, actos de piratas informáticos, y actos de proveedores de servicios de Internet entre otros.
                                    </p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        12.5. El Sitio puede contener publicidad y/o enlaces a otros sitios web de terceros. En dichos casos,
                                        E-XPERTUM no será responsable de ninguna manera del contenido, productos, servicios, anuncios u otra información que pueda ser
                                        proporcionado por o a través de tales sitios o publicidad, ni de sus políticas de privacidad o su
                                        cumplimiento con las disposiciones legales aplicables.</p>
                                </div>

                                <div className="container-fluid bg-2 text-center">
                                    <p><strong>13. Aplicación móvil.</strong></p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        13.1. Si accede a los Servicios a través de una aplicación móvil, le otorgamos un derecho revocable, no
                                        exclusivo, intransferible y limitado, considero que estos términos no trascienden a ese punto
                                        Propongo, acceder y utilizar la aplicación móvil en dispositivos electrónicos
                                        inalámbricos de su propiedad o controlados por usted, y para acceder y utilizar la aplicación móvil en dichos dispositivos
                                        estrictamente de  acuerdo con los Términos y Condiciones de E-XPERTUM.
                                    </p>
                                </div>
                                <div className="container-fluid bg-2 text-center">
                                    <p><strong>14. Limitaciones de Responsabilidad.</strong></p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        14.1. El uso y acceso al Sitio, y la contratación de los Servicios a través del mismo es bajo su propio
                                        riesgo, por lo que en ningún momento deberá entenderse que E-XPERTUM presta cualquier tipo de asesoría, recomendación u
                                        opinión de carácter legal, financiera, contable, laboral o cualquier otro tipo de asesoría profesional. Cualquier
                                        información proporcionada por E-XPERTUM a través de su Sitio tendrá el carácter única y exclusivamente informativo y no
                                        deberá interpretarse como una prestación de los Servicios.</p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        14.2. En caso de que se produzca algún daño, afectación, menoscabo, pérdida o perjuicio debido al retraso en
                                        la entrega, error, inexactitud o falsedad, ya sea directa o indirectamente, en la información (o modificaciones a la
                                        información), que haya sido proporcionada directa o indirectamente por el Usuario, E-XPERTUM no será responsable de los
                                        mismos, incluyendo, de manera enunciativa mas no limitativa, cualquier reclamo, demanda, notificación, aviso,
                                        citatorio, instrucción, orden, acción, litigio o procedimiento, derivado de, a raíz de, o que de cualquier otra forma
                                        se relacione, directa o indirectamente, con dicho daño, afectación, menoscabo, pérdida o perjuicio.
                                    </p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        14.3. El Sitio y los Servicios se ofrecen y proporcionan tal como están y según estén disponibles. Usted
                                        acepta que el uso del Sitio y sus Servicios será bajo su propio riesgo y responsabilidad. Todas las garantías, términos,
                                        condiciones, compromisos, expresos o implícitos en relación con el Sitio y los Servicios, así como respecto del uso de
                                        los mismos quedan excluidas en la máxima medida permitida por la legislación aplicable.</p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        14.4. No ofrecemos garantías ni representaciones sobre la precisión o integridad del contenido del Sitio y
                                        no somos responsables de ningún: i) error u omisión en el contenido del Sitio; ii) cualquier acceso no autorizado o
                                        uso de  nuestros servidores y/o cualquier información personal y/o financiera almacenada en nuestro servidor_; iii)
                                        cualquier interrupción o cese de la transmisión hacia o desde el Sitio o vinculado a los Servicios; y/o iv) cualquier
                                        error, virus, troyano o similares que puedan ser transmitidos hacia o desde el Sitio o a través de un tercero.
                                        E-XPERTUM no será responsable de ningún retraso o incumplimiento de sus obligaciones en virtud de estos Términos y
                                        Condiciones si dicho retraso o incumplimiento es causado por un evento fuera de su control razonable.</p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        14.5. En ningún caso la responsabilidad total de E-XPERTUM por cualquier reclamación que se derive o
                                        relacione con estos Términos y Condiciones, el Sitio y/o los Servicios, incluyendo aquellos relacionados con daños directos o
                                        indirectos permitidos, o con el uso o incapacidad de usar el Sitio, recibir o aprovechar los Servicios excederá de los
                                        montos que el Usuario haya pagado a E-XPERTUM por el uso de Sitio o los Servicios durante un periodo de 6 (seis) meses
                                        inmediatamente anteriores a la ocurrencia de las causales que den lugar a la reclamación correspondiente.
                                        Las exclusiones y limitaciones de daños y perjuicios aquí establecidas forman parte del motivo determinante de
                                        la voluntad  de E-XPERTUM para la celebración de cualquier acuerdo con el Usuario.</p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        14.6. No seremos responsables ante usted por ninguna pérdida o daños, ya sea por contrato, agravio (incluida
                                        la negligencia), incumplimiento del deber legal o de otro modo, incluso si es previsible, que surja de o en
                                        relación con: i) el uso o la imposibilidad de usar nuestro Sitio y cualquiera de los Servicios; y/o ii) el uso de
                                        cualquier contenido mostrado en nuestro Sitio. En particular no seremos responsables de: i) pérdida de ganancias, ventas,
                                        negocios ingresos; ii) interrupción de cualquier negocio o actividad; iii) pérdida de ahorros anticipados; iv) pérdida de
                                        oportunidad comercial, buena voluntad o reputación; v) cualquier pérdida o daño directo o indirecto. Tenga en cuenta que
                                        solo proporcionamos nuestro Sitio para uso privado. Usted acepta expresamente y sin limitación alguna no utilizar
                                        nuestro Sitio para fines comerciales.</p>
                                </div>

                                <div className="container-fluid bg-2 text-center">
                                    <p><strong>15. Indemnización.</strong></p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        15.1. Usted acepta y se obliga a indemnizar, defender y a sacar en paz y a salvo a E-XPERTUM (incluyendo,
                                        pero no limitado a, sus respectivos accionistas, directores, gerentes, funcionarios, representantes agentes y
                                        empleados) (las “Partes Indemnizadas”), de cualquier reclamación, controversia, demanda, juicio, procedimiento,
                                        responsabilidad, sanción, multa daño, pérdida, gasto o costo (conjuntamente, las “Reclamaciones” y/o “Reclamación”), que
                                        pudiera hacerse en su contra con motivo o en relación con: i) el acceso o uso del Sitio o los Servicios por parte de
                                        cualquiera de sus usuarios; ii) el uso de la cuenta del Usuario (incluyendo cualquier acto doloso, fraudulento o ilícito);
                                        iii) el retraso en la entrega, error, inexactitud o falsedad, ya sea directa o indirectamente, en la información (o
                                        modificaciones a la información) que haya sido proporcionada por el Usuario, ya sea de manera directa o indirecta; iv) la
                                        violación o supuesta violación a cualquier derecho de propiedad intelectual de E-XPERTUM o de terceros; v) el
                                        incumplimiento o violación de cualesquiera leyes, normas o regulaciones aplicables a las Partes Indemnizadas o al Usuario, de
                                        manera directa o indirecta; o vi) cualquier incumplimiento a estos Términos y Condiciones o a cualquier política de
                                        E-XPERTUM, por parte del Usuario, ya sea de manera directa o indirecta.</p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        15.2. En razón de lo anterior, el Usuario acuerda indemnizar y eximir de responsabilidad a las Partes
                                        Indemnizadas por
                                        cualquier Reclamación, obligándose a anticipar o reembolsar a E-XPERTUM cualesquier cantidad que requiera
                                        por concepto de honorarios de abogados, costos legales y contables. En caso de que el Usuario ya sea manera directa o
                                        indirecta tenga conocimiento de alguna Reclamación en contra de alguna Parte Indemnizada deberá notificar de manera
                                        inmediata a  E-XPERTUM mediante los medios de contacto disponibles en el Sitio, debiendo el Usuario tomar bajo su propia
                                        cuenta y  responsabilidad todas las medidas necesarias para mitigar cualquier Reclamación en contra de las Partes
                                        Indemnizadas. Lo anterior, en el entendido que dicha notificación no eximirá al Usuario de sus demás obligaciones conforme a
                                        lo  establecido en estos Términos y Condiciones.</p>
                                </div>
                                <div className="container-fluid bg-2 text-center">
                                    <p><strong>16. Relación entre las Partes.</strong></p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        16.1. 3.1. Entre Usted y E-XPERTUM no existe relación laboral alguna si no una relación comercial de partes
                                        totalmente independiente entre sí. Por lo que en ningún momento se considerará que existe algún tipo de asociación,
                                        agencia,  representación y/o comisión mercantil entre E-XPERTUM y el Usuario.</p>
                                </div>
                                <div className="container-fluid bg-2 text-center">
                                    <p><strong>17. Información Confidencial</strong></p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        17.1. Toda la información proporcionada por el Usuario será tratada como información confidencial y no podrá
                                        ser revelada sin el consentimiento del Usuario, excepto a aquellos funcionarios, empleados, representantes o
                                        asesores de E-XPERTUM que tengan necesidad de conocer la información para el cumplimiento de las obligaciones
                                        relacionadas con la operación del Sitio y la prestación de los Servicios, conforme a los presentes Términos y Condiciones. No
                                        obstante,  E-XPERTUM podrá revelar la información que le proporcione el Usuario para dar cumplimiento a requerimientos
                                        gubernamentales, administrativos o judiciales a los cuales esté sujeto, previo aviso al Usuario y, siempre y
                                        cuando la revelación de la información sea obligatoria para E-XPERTUM de conformidad con las leyes aplicables o con
                                        alguna orden de autoridad. Esta obligación de confidencialidad concluirá a los 6 (seis) meses siguientes a la fecha de
                                        terminación de  estos Términos y Condiciones y no cesará con motivo de la expiración, suspensión, terminación o rescisión de
                                        los presentes Términos y Condiciones.</p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        17.2. E-XPERTUM no está obligada, pero tiene derecho a monitorear el acceso y uso del Sitio por parte del
                                        Usuario, así como para investigar conductas que considere que podrían afectar al Sitio y/o a los Servicios, incluyendo
                                        las posibles violaciones a estos Términos y Condiciones o a las disposiciones aplicables.</p>
                                </div>

                                <div className="container-fluid bg-2 text-center">
                                    <p><strong>18. Modificaciones a estos Términos y Condiciones y al Sitio.</strong></p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        18.1. E-XPERTUM podrá modificar los Términos y Condiciones en cualquier momento, sin necesidad de
                                        notificarlo previamente al usuario. Sin perjuicio de lo anterior, E-XPERTUM, podrá publicar las actualizaciones o
                                        modificaciones a los Términos y Condiciones del Sitio, en el entendido de que la sola utilización del mismo implica la
                                        aceptación expresa por parte del usuario de cualquier actualización o modificación de los Términos y Condiciones Generales
                                    </p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        18.2. Nos reservamos el derecho de cambiar, modificar o eliminar contenido del Sitio en cualquier momento o
                                        por cualquier motivo a nuestra entera discreción sin previo aviso. También nos reservamos el derecho de
                                        modificar o descontinuar todo o parte de los Servicios sin previo aviso en cualquier momento.
                                    </p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        18.3. Puede haber información en el Sitio que contenga errores tipográficos, inexactitudes u omisiones que
                                        puedan estar relacionadas con los Servicios, incluidas descripciones, precios, disponibilidad u otra información. Nos
                                        reservamos el derecho, sin estar obligados a ello, de corregir cualquier error, inexactitud u omisión de cambiar o
                                        actualizar la información en cualquier momento, sin previo aviso.
                                    </p>
                                </div>
                                <div className="container-fluid bg-2 text-center">
                                    <p><strong>19. Duración y rescisión</strong></p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        19.1. Estos Términos y Condiciones permanecerán en plena vigencia y efecto mientras usted utilice el Sitio o
                                        los Servicios o sea Usuario del Sitio, según corresponda. Puede terminar su uso o participación en cualquier
                                        momento, por cualquier motivo, siguiendo las instrucciones para cancelar cuentas de Usuario en la configuración de su
                                        cuenta, si está disponible, o comunicándose con nosotros.</p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        19.2. Sin limitar ninguna otra disposición de estos Términos y Condiciones, a nuestro exclusivo criterio y
                                        sin previo aviso ni responsabilidad, nos reservamos el derecho a denegar total o parcialmente el acceso y el uso del
                                        Sitio y/o cualquiera de los Servicios a cualquier persona, por cualquier motivo, entre otros, el incumplimiento de
                                        algún acuerdo u obligación contenida en estos Términos y Condiciones, la ley o reglamento aplicables.
                                    </p>
                                    <p><strong>20. Encabezados.</strong></p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        20.1. El título de cada uno de los encabezados señalados en los presentes Términos y Condiciones tiene
                                        exclusivamente fines descriptivos, debiendo atenerse al contenido de los mismos para su interpretación y cumplimiento.
                                    </p>
                                </div>
                                <div className="container-fluid bg-2 text-center">
                                    <p><strong> 21. Acuerdo Total</strong></p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        21.1. Los presentes Términos y Condiciones constituyen el total entendimiento y acuerdo entre el Usuario y
                                        E-XPERTUM, y prevalecerán sobre cualquier otro acuerdo verbal y/o escrito anterior a la fecha de aceptación de estos
                                        Términos y Condiciones. En caso de que alguna o algunas de las disposiciones del presente documento fueran
                                        consideradas, por cualquier razón inválidas, ilegales o inejecutables, estas no afectarán la validez de las otras
                                        disposiciones contenidas en los presentes.</p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        21.2. El usuario no podrá ceder y/o transferir total o parcialmente por cualquier título los derechos y/o
                                        las obligaciones derivadas de estos Términos y Condiciones, ni de los Servicios sin el consentimiento previo,
                                        expreso y por escrito de E-XPERTUM. En cambio, E-XPERTUM podrá ceder o transferir libremente, de manera total o parcial
                                        estos Términos  y Condiciones bastando para ello una notificación por escrito al Usuario.
                                    </p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        21.3. Las disposiciones contenidas en los presentes Términos y Condiciones resultarán en beneficio de y
                                        serán vinculantes para el Usuario y para E-XPERTUM, así como para sus respectivos herederos, sucesores y
                                        cesionarios permitidos.</p>
                                </div>

                                <div className="container-fluid bg-2 text-center">
                                    <p><strong>22. Domicilios para notificaciones</strong></p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        22.1. Se establece como domicilio de E-XPERTUM, el ubicado en calle Calz. Niños Héroes de Chapultepec ·116,
                                        Piso 2, Col Jalatlaco, C.P. 68080, Oaxaca de Juárez, Oax, México..
                                    </p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        22.2. Datos de contacto: correo electrónico:ventas@e-xpertum.com.
                                    </p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        22.3. El domicilio del Usuario será el que detalle al momento de su registro en el Sitio. Cualquier
                                        notificación u otras comunicaciones dirigidas al Usuario por parte de E-XPERTUM en virtud de este documento, se entregarán: i)
                                        por correo electrónico, o ii) mediante publicación en el Sitio.
                                    </p>
                                </div>
                                <div className="container-fluid bg-2 text-center">
                                    <p><strong>23. Jurisdicción Aplicable.</strong></p>
                                    <p style={{ "padding-left": "5%" }} className="mb-3 text-muted d-block">
                                        23.1. Los presentes Términos y Condiciones se regirán e interpretarán conforme a las leyes de del Estado de
                                        Quintana  Roo. Todas las desavenencias que deriven de los presentes Términos y Condiciones, incluyendo su
                                        incumplimiento, resolución o nulidad, serán resueltas definitivamente de acuerdo con las Reglas de Arbitraje del Centro de
                                        Arbitraje de México (CAM), por un solo árbitro nombrado conforme a dichas Reglas. La sede del arbitraje será la Ciudad de
                                        México y el idioma será español.
                                    </p>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>
            </>
        );
    }
}

export default TeminosCondiciones;