
import React, { Component } from 'react';
class TreeList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subdetails: [],
            level: this.props.level
        }
    }
    componentDidMount() {
        let resultado_end = []
        let object = this.props.data;
        for (let g = 0; g < object.length; g++) {
            if (object[g].children !== undefined)
                resultado_end.push({

                    ejercicio2015: (object[g]["2015"] == undefined ? 0 : object[g]["2015"]),
                    ejercicio2016: (object[g]["2016"] == undefined ? 0 : object[g]["2016"]),
                    ejercicio2017: (object[g]["2017"] == undefined ? 0 : object[g]["2017"]),
                    ejercicio2018: (object[g]["2018"] == undefined ? 0 : object[g]["2018"]),
                    ejercicio2019: (object[g]["2019"] == undefined ? 0 : object[g]["2019"]),
                    ejercicio2020: (object[g]["2020"] == undefined ? 0 : object[g]["2020"]),
                    ejercicio2021: (object[g]["2021"] == undefined ? 0 : object[g]["2021"]),
                    ejercicio2022: (object[g]["2022"] == undefined ? 0 : object[g]["2022"]),
                    category: object[g].category,
                    children: object[g].children
                })
            else
                resultado_end.push({

                    ejercicio2015: (object[g]["2015"] == undefined ? 0 : object[g]["2015"]),
                    ejercicio2016: (object[g]["2016"] == undefined ? 0 : object[g]["2016"]),
                    ejercicio2017: (object[g]["2017"] == undefined ? 0 : object[g]["2017"]),
                    ejercicio2018: (object[g]["2018"] == undefined ? 0 : object[g]["2018"]),
                    ejercicio2019: (object[g]["2019"] == undefined ? 0 : object[g]["2019"]),
                    ejercicio2020: (object[g]["2020"] == undefined ? 0 : object[g]["2020"]),
                    ejercicio2021: (object[g]["2021"] == undefined ? 0 : object[g]["2021"]),
                    ejercicio2022: (object[g]["2022"] == undefined ? 0 : object[g]["2022"]),
                    category: object[g].category,
                })

        }
        this.setState({ subdetails: resultado_end })
    }
    render() {
        return (
            <>
                {
                    (this.state.subdetails).map((h, j) => (
                        <>
                            <tr key={j}>
                                <td style={{ paddingLeft: this.state.level }}>{h.category}</td>

                                <td className="text-right">{(h.ejercicio2015) === 0 ? '-' : (h.ejercicio2015 === undefined ? '-' : (h.ejercicio2015).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                <td className="text-right">{(h.ejercicio2016) === 0 ? '-' : (h.ejercicio2016 === undefined ? '-' : (h.ejercicio2016).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                <td className="text-right">{(h.ejercicio2017) === 0 ? '-' : (h.ejercicio2017 === undefined ? '-' : (h.ejercicio2017).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                <td className="text-right">{(h.ejercicio2018) === 0 ? '-' : (h.ejercicio2018 === undefined ? '-' : (h.ejercicio2018).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                <td className="text-right">{(h.ejercicio2019) === 0 ? '-' : (h.ejercicio2019 === undefined ? '-' : (h.ejercicio2019).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                <td className="text-right">{(h.ejercicio2020) === 0 ? '-' : (h.ejercicio2020 === undefined ? '-' : (h.ejercicio2020).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                <td className="text-right">{(h.ejercicio2022) === 0 ? '-' : (h.ejercicio2022 === undefined ? '-' : (h.ejercicio2022).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                            </tr>
                            {
                                (h.children !== undefined) ? (
                                    <TreeList data={h.children} level={(this.state.level) + 10} key={(j + 100000).toString()} />
                                ) : null
                            }
                        </>
                    ))
                }
            </>
        );
    }
}

export default TreeList;