import React, { Component } from 'react';
import api from '../../../../app/Config/apifiles';
import { Tooltip, } from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
var meses = new Array("", "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre");
function sortByProperty(property) {
    return function (a, b) {
        if (a[property] > b[property]) return 1;
        else if (a[property] < b[property]) return -1;
        return 0;
    }
}
class BlzVSContribucion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recorset: [],
            skeleton: true,
        }
    }
    componentDidMount() {
        this.getdata();
    }
    getdata = async () => {
        let pareme = JSON.parse(localStorage.getItem("datacompanysystem"));
        let result_ = await api.post("validate-blz-contributions", pareme);
        let { data } = await result_;
        let _resultado_admin_ = data.blz;
        let _result_data_ = [];
        for (let g = 1; g < meses.length; g++) {
            let _filter_data_ = (_resultado_admin_.filter((i) => (i.periodo) === meses[g]));
            if (_filter_data_.length > 0) {
                _result_data_.push({
                    mescausacion: meses[g],
                    status: 0
                });
                _result_data_.push({ details: _filter_data_.sort(sortByProperty("numero_de_operacion")) })
            }
        }
        this.setState({ recorset: _result_data_, skeleton: false });

        /*let pareme = JSON.parse(localStorage.getItem("datacompanysystem"));
        let result_ = await api.post("blzcontribuciones", pareme);
        let _resultado_admin_ = await result_.data;
        let _result_data_ = [];
        for (let g = 1; g < meses.length; g++) {

            let _filter_data_ = (_resultado_admin_.filter((i) => parseInt(i.periodo) === g));
            if (_filter_data_.length > 0) {
                _result_data_.push({
                    mescausacion: meses[g],
                    status: 0
                });
                _result_data_.push({ details: _filter_data_ })
            }
        }
        console.log(_result_data_)
        this.setState({ recorset: _result_data_, skeleton: false });*/
    }
    render() {
        return (
            <>
                {
                    this.state.skeleton ? (
                        <div className="card">
                            <Skeleton />
                            <Skeleton animation={false} height={400} />
                            <Skeleton animation="wave" />
                        </div>
                    ) : (
                        <>
                            <table className="table table-dark bg-secondary">
                                <thead>
                                    <tr>
                                        <th>Mes de causción</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (this.state.recorset || []).map((d, t) => (
                                            <tr key={t}>
                                                {
                                                    (d.status != undefined) ? (
                                                        <td className="table-warning">{d.mescausacion}</td>) :
                                                        (
                                                            <td>
                                                                <table className="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Obligación</th>
                                                                            <th>Tipo de declaración</th>
                                                                            <th>Detalle acuse</th>
                                                                            <th>Estatus</th>
                                                                            <th>Importe balanzas</th>
                                                                            <th>Validación</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            (d.details).map((k, j) => (
                                                                                <tr>
                                                                                    <td>{k.obl}</td>
                                                                                    <td className="text-center">{(k.tipodeclaracion)}</td>
                                                                                    <td className="text-right">{(k.total_contribuciones).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                                                    <td className="text-center">{k.tipo}</td>
                                                                                    <td className="text-right">{((k.importebalanza)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                                                    <td className="text-center">
                                                                                        {(k.validacion === "DANGER") ? (<i className="icon-cross2 text-danger"></i>) : (<i className="icon-checkmark2 text-success"></i>)}
                                                                                    </td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        )
                                                }
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </>
                    )
                }
            </>
        );
    }
}

export default BlzVSContribucion;