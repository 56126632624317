import React, { Component } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
function calculoporcentaje(x) {
    if (x.ptupagado === 0) {
        return 0;
    }
    else if (x.ptupagado < 0) {
        let ft = 0;
        if (x.ptupagado < 0 && x.ingresos > 0)
            ft = ((((x.ptupagado * -1) / x.ingresos) * 100) * -1).toFixed(1).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        else if (x.ingresos === 0)
            ft = 0;

        return (0);
    }
    else if (x.ptupagado > 0) {
        let ft = (((x.ptupagado) / x.ingresos) * 100).toFixed(1).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        return (ft);
    }
}
class GrapTasaEfectiva extends Component {
    constructor(props) {
        super(props);
        let _ = props.d;
        for (let z = 0; z < _.length; z++) {
            _[z].tasaefectiva = calculoporcentaje(_[z]);
            /*if (_[z].ptupagado > 0)
                _[z].tasaefectiva = ((_[z].ptupagado / _[z].ingresos) * 100)
            else {
                console.log(first)
                _[z].tasaefectiva = 0;
            }*/
        }
        this.state = {
            data: _,
        }
    }

    render() {
        return (
            <ResponsiveContainer width={"100%"} height={400}>
                <LineChart style={{ color: "black" }}
                    width={650}
                    height={350}
                    data={this.state.data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="4 1 2" vertical={false} />
                    <XAxis dataKey="ejercicio" name="Ejercicio" />
                    <YAxis />
                    <Tooltip formatter={(value) => new Intl.NumberFormat('en').format(value)} contentStyle={{ color: 'gray', fontSize: 13 }} />
                    <Legend />
                    <Line type="monotone" dataKey="tasaefectiva" name='Tasa efectiva' stroke="#82ca9d" activeDot={{ r: 8 }} />
                </LineChart>
            </ResponsiveContainer>
        );
    }
}

export default GrapTasaEfectiva;