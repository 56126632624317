import React, { Component } from 'react';
import parse from 'html-react-parser';
function diaSemana() {
    let date = new Date();
    let options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };
    return date.toLocaleDateString('es-MX', options);
}
function sumall(params) {
    let datasum = 0;
    for (let k = 0; k < params.length; k++) {
        datasum = datasum + params[k].importe;
    }

    return datasum;
}
class PreviewSendmail extends Component {
    constructor(props) {
        super(props);
        let objectinfo = JSON.parse(localStorage.getItem("_data_contact_"));
        this.state = {
            body: [], //props.data,
            result: objectinfo,
            descripcion: '',
            subtotal: 0,
            tipoCotiza: '',
            subtotalmensual: 0,
            bankdescription: '',
            //iva: (sumall(props.data) * 0.16),
            //subtotal: sumall(props.data),
            //  total: (sumall(props.data) * 0.16) + sumall(props.data)
        }
    }
    componentDidMount() {
        let sumimport = 0, sumimportmensual = 0;
        let tableend = []
        let allconfig = (this.props.data)
        let allpreciosservicios = allconfig.listconfig;
        let preciovalidador = (allpreciosservicios).filter((y) => y.id === allconfig.configvalidador)[0];
        if (preciovalidador != undefined) {
            let descriptionvalidador = (allconfig.servicios).filter((j) => j.parent_id === preciovalidador.clave)[0];
            tableend.push({
                servicio: descriptionvalidador.descripcion,
                importemensual: (preciovalidador.importe) / 12,
                importeanual: preciovalidador.importe,
                extra: '',
                textmail: descriptionvalidador.textdescription
            });
            sumimport = sumimport + preciovalidador.importe;
            sumimportmensual = sumimportmensual + (preciovalidador.importe) / 12;
        }

        let precioauditor = (allpreciosservicios).filter((y) => y.id === allconfig.configauditor)[0];
        if (precioauditor != undefined) {
            let descriptionauditor = (allconfig.servicios).filter((j) => j.parent_id === precioauditor.clave)[0];
            tableend.push({
                servicio: descriptionauditor.descripcion,
                importemensual: (precioauditor.importe) / 12,
                importeanual: precioauditor.importe,
                extra: '',
                textmail: descriptionauditor.textdescription
            });
            sumimport = sumimport + precioauditor.importe;
            sumimportmensual = sumimportmensual + (precioauditor.importe) / 12;
        }

        if (parseInt(allconfig.confignomina) > 0) {
            let preciosNomina = (allconfig.listconfig).filter((g) => g.clave === "5000");
            let filterNomina = preciosNomina.filter((h) => (parseInt(h.rango) <= parseInt(allconfig.numerotrabajadores) && parseInt(h.rangomax) >= parseInt(allconfig.numerotrabajadores)))[0]
            let descriptionNomina = (allconfig.servicios).filter((j) => j.parent_id === "5000")[0];

            let _dato_extra_ = "";
            let costo_mensual_ = 0, costo_anual_ = 0;
            if (allconfig.tipopersona === "PF") {
                let filterNominatop = (preciosNomina.filter((h) => h.tipopersona === "PF"))[0];
                console.log(filterNominatop)
                costo_mensual_ = filterNominatop.importe;
                costo_anual_ = filterNominatop.importe * 12;
                _dato_extra_ = " De 1 a 10 trabajadores  ";
            }
            else if (allconfig.tipopersona === "PM") {
                costo_mensual_ = allconfig.numerotrabajadores * filterNomina.importe;
                costo_anual_ = (allconfig.numerotrabajadores * filterNomina.importe) * 12;
                _dato_extra_ = "# trabajadores (" + (allconfig.numerotrabajadores) + ") - Costo mensual por trabajador (" + filterNomina.importe + ")  = ";
            }
            //console.log(allconfig)
            tableend.push({
                servicio: descriptionNomina.descripcion,
                importemensual: costo_mensual_,
                importeanual: costo_anual_,
                extra: _dato_extra_,
                textmail: descriptionNomina.textdescription
            });
            sumimportmensual = sumimportmensual + costo_mensual_;
            sumimport = sumimport + costo_anual_; //(allconfig.tipopersona === "PM" ? ((costo_mensual_ * parseInt(allconfig.numerotrabajadores)) * 12) : costo_mensual_ * 12);
        }

        if (parseInt(allconfig.configadministrador) > 0) {
            let preciosAdmin = (allconfig.listconfig).filter((g) => g.clave === "6000")[0];
            let descriptionAdmin = (allconfig.servicios).filter((j) => j.parent_id === "6000")[0];
            tableend.push({
                servicio: descriptionAdmin.descripcion + " 50 GB de capacidad ",
                importemensual: (preciosAdmin.importe) / 12,
                extra: '',
                importeanual: (preciosAdmin.importe),
                textmail: ""
            });
            sumimportmensual = sumimportmensual + (preciosAdmin.importe) / 12;
            sumimport = sumimport + preciosAdmin.importe;
        }
        ///console.log(sumimport)
        this.setState({ bankdescription: allconfig.descriptionbank, body: tableend, subtotal: sumimport, subtotalmensual: sumimportmensual, tipoCotiza: allconfig.iva, descripcion: allconfig.descripcion });
        setTimeout(() => {
            this.props._dataget(this.state)
        }, 900);
    }
    render() {
        return (
            <>
                <div className="card" style={{ height: 500, overflowY: 'scroll' }}>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="mb-4">
                                    <img src="https://e-xpertum.com.mx/adminfile/logos/logo_expertum.png" className="mb-3 mt-2" alt="" style={{ width: '120px' }} />

                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="mb-4">
                                    <div className="text-sm-right">
                                        <h4 className="text-orange-300 mb-2 mt-md-2">Cotización #0001</h4>
                                        <ul className="list list-unstyled mb-0">
                                            <li>Fecha: <span className="font-weight-semibold">{diaSemana()}</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-0">
                            <dl className="mb-0">
                                <dt>{this.state.result.rfc} / C. {this.state.result.nombre_completo}</dt>
                                <dd>Presente</dd>
                                <dd>Es grato dirigirnos a usted, para hacer de su conocimiento que en atención a la solicitud de cotización, luego de haber examinado la información proporcionada por  {this.state.result.rfc} / C. {this.state.result.nombre_completo} y conocer todas las condiciones solicitadas, e-xpertum ofrece:</dd>
                            </dl>
                        </div>
                        <br></br>
                        <div className="mb-0">
                            <dl className="mb-0">

                                {
                                    (this.state.body).map((s, g) => (
                                        <>
                                            {parse(s.textmail)}
                                        </>
                                    ))
                                }
                            </dl>
                        </div>
                        <span className="text-muted">De conformidad con las especificaciones establecidas en los términos y condiciones, la propuesta económica de e-xpertum es la siguiente:</span>
                        <div className="table-responsive">
                            <table className="table table-lg">
                                <thead>
                                    <tr>
                                        <th>Servicios</th>
                                        <th className="text-center">Costo mensual</th>
                                        <th className="text-center">Costo anual</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (this.state.body).map((g, k) => (
                                            <tr key={k}>
                                                <td>
                                                    <span className="text-muted">{g.servicio}</span>
                                                </td>
                                                <td className="text-right">{g.extra}{(g.importemensual).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                <td className="text-right">{(g.importeanual).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td className="text-right">Subtotal:</td>
                                        <td className="text-right">{(this.state.subtotalmensual).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                        <td className="text-right">{(this.state.subtotal).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                    </tr>
                                    {
                                        this.state.tipoCotiza === "FRONTERA" ?
                                            (
                                                <tr>
                                                    <td className="text-right">IVA (0.08%)</td>
                                                    <td className="text-right">{(this.state.subtotalmensual * 0.08).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                    <td className="text-right">{(this.state.subtotal * 0.08).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                </tr>
                                            ) :
                                            (
                                                <tr>
                                                    <td className="text-right">IVA (0.16%)</td>
                                                    <td className="text-right">{(this.state.subtotalmensual * 0.16).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                    <td className="text-right">{(this.state.subtotal * 0.16).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                </tr>
                                            )
                                    }
                                    {
                                        this.state.tipoCotiza === "FRONTERA" ?
                                            (
                                                <tr>
                                                    <td className="text-right">Total:</td>
                                                    <td className="text-right">{((this.state.subtotalmensual * 0.08) + this.state.subtotalmensual).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                    <td className="text-right">{((this.state.subtotal * 0.08) + this.state.subtotal).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                </tr>
                                            ) :
                                            (
                                                <tr>
                                                    <td className="text-right">Total:</td>
                                                    <td className="text-right">{((this.state.subtotalmensual * 0.16) + this.state.subtotalmensual).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                    <td className="text-right">{((this.state.subtotal * 0.16) + this.state.subtotal).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                </tr>
                                            )
                                    }

                                </tfoot>
                            </table>
                        </div>
                        <div className="mb-0">
                            <blockquote>
                                <strong>Documentación requerida para la contratación</strong>
                                <ol style={{ "list-style-type": "lower-alpha", "font-size": "12px" }}>
                                    <li style={{ "text-align": "justify" }}><em><span style={{ "font-weight": "400" }}>Constancia de situación fiscal.</span></em></li>
                                    <li style={{ "text-align": "justify" }}><em><span style={{ "font-weight": "400" }}>Carta de aceptación de servicios.</span></em></li>
                                </ol>
                            </blockquote>
                            <blockquote>
                                <strong>Consideraciones finales</strong>
                                <ol style={{ "list-style-type": "lower-alpha", "font-size": "12px" }}>
                                    <li style={{ "text-align": "justify" }}>{parse(this.state.bankdescription)}</li>
                                </ol>
                            </blockquote>
                            <blockquote>
                                <strong>Nota</strong>
                                <ol style={{ "list-style-type": "lower-alpha", "font-size": "12px" }}>
                                    <li style={{ "text-align": "justify" }}><em><span style={{ "font-weight": "400" }}>Facturación del servicio para personas morales y fisicas.</span></em></li>
                                </ol>
                            </blockquote>
                        </div>
                        <br></br>
                        <div className="mb-0">
                            <dl className="mb-0 text-center">
                                <dt>ATENTAMENTE</dt>
                                <dd>Direccion comercial E-XPERTUM</dd>

                            </dl>
                        </div>
                        <div className="card-footer border-light">
                            <span className="text-muted">Este documento tiene validez de 15 días, y se expide a petición del interesado sin tener ninguna validez, legal, únicamente para fines informativos del solicitante. </span>
                        </div>
                    </div>

                </div>
            </>
        );
    }
}

export default PreviewSendmail;