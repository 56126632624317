import React, { Component } from 'react';
import api from '../../app/Config/apifiles';
import { Link } from "react-router-dom";
class FormRecuve extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            errorisValid: false,
            passwordConfirm: '',
            error: '',
            parameter_1: '',
            parameter_2: '',
            isValid: false
        }
        this.handleSenmail = this.handleSenmail.bind();
    }
    componentDidMount() {
        let _p = (this.props.match.params.url).split('&');
        this.setState({ parameter_1: _p[0], parameter_2: _p[1] });
    }
    handleSetData = async (v) => {
        this.setState({ password: v.target.value })
    }
    handleSetDataConfirm = async (v) => {
        if (v.target.value.length > 0) {
            if (this.state.password !== v.target.value) {
                this.setState({ error: 'La contraseña no coincide con la ingresada', passwordConfirm: v.target.value })
            }
            else {
                this.setState({ error: '', passwordConfirm: v.target.value })
            }
        }
        else
            this.setState({ error: '', passwordConfirm: '' })
    }
    handleSenmail = async (e) => {
        if (this.state.error.trim() == '') {
            this.setState({ isValid: true });
            let _ = await api.post("recuve-password-set-update", this.state);
            setTimeout(() => {
                this.setState({ isValid: false });
            }, 6000);
            setTimeout(() => {
                this.props.history.push('/login');
            }, 9000);
        }
        else {
            this.setState({ errorisValid: true });
            setTimeout(() => {
                this.setState({ errorisValid: false });
            }, 6000);
        }
        e.preventDefault();
    }
    render() {
        return (
            <>
                <div className="navbar navbar-expand-md navbar-light navbar-static" style={{ backgroundColor: "#262d3c" }}>
                    <div className="navbar-brand">
                        <Link to='/' className="d-inline-block">
                            <img src="logos/logo_expertumb.png" alt="" />
                        </Link>
                    </div>

                    <div className="d-md-none">
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-mobile">
                            <i className="icon-tree5"></i>
                        </button>
                    </div>
                    <div className="collapse navbar-collapse" id="navbar-mobile">

                    </div>
                </div>
                <div className="page-content">
                    <div className="content-wrapper">
                        <div className="content-inner">

                            <div className="content pt-1">
                                {
                                    this.state.isValid ? (
                                        <div className="alert alert-success alert-styled-left alert-arrow-left alert-dismissible">
                                            <span className="font-weight-semibold">Genail!</span> Se ha cambiado correctamente la contraseña!.
                                        </div>
                                    ) : null
                                }
                                {
                                    this.state.errorisValid ? (
                                        <div className="alert alert-danger alert-styled-left alert-dismissible">
                                            <span className="font-weight-semibold">Ups!</span> No se puede guardar la contraseña si no coinciden.
                                        </div>

                                    ) : null
                                }
                                <div className="row">
                                    <div className="col-lg-4"></div>
                                    <div className="col-lg-4">
                                        <div className="card">
                                            <div className="card-body">
                                                <form onSubmit={this.handleSenmail}>
                                                    <fieldset>
                                                        <legend className="font-weight-semibold">Ingrese los datos requeridos</legend>
                                                        <div className="form-group">
                                                            <label>Nueva contraseña:</label>
                                                            <input type="password" onChange={this.handleSetData} className="form-control" placeholder="Nueva contraseña" />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Confirme su contraseña:</label>
                                                            <input type="password" onChange={this.handleSetDataConfirm} className="form-control" placeholder="Confirme su contraseña" />
                                                        </div>
                                                        <div className="text-danger">{this.state.error}</div>
                                                    </fieldset>
                                                    <div className="text-right">
                                                        <button type="submit" className="btn btn-primary">Enviar <i className="icon-paperplane ml-2" /></button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        );
    }
}

export default FormRecuve;