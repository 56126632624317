import React, { Component, Fragment } from 'react'
import $ from "jquery";
import api from '../../../app/Config/api';
import ReactDatatable from '@ashvin27/react-datatable';
import MaskedInput from 'react-text-mask';
import ConfigAdmin from '../../Config/config.json';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, } from '@material-ui/core';
import { Tooltip, } from "@material-ui/core";
import { withRouter } from 'react-router-dom';
class VendorsListAmind extends Component {
    constructor(props) {
        super(props);
        var objec = JSON.parse(localStorage.getItem('id_token'));

        this.columns = [
            {
                key: "nombre_completo",
                text: "Nombre completo",
                className: "name",
                align: "left",
                sortable: true,
            },
            {
                key: "usuario",
                text: "Usuario",
                className: "address",
                align: "left",
                sortable: true
            },
            {
                key: "correo",
                text: "Correo",
                className: "postcode",
                sortable: true
            },
            {
                key: "telefono",
                text: "Teléfono",
                className: "postcode",
                sortable: true
            },
            {
                key: "perfil",
                text: "Perfil",
                className: "action",
                width: 150,
                align: "left",
                sortable: false,
                cell: t => {
                    return (
                        <Fragment>
                            {
                                (t.perfil === 'CLIENTE' || t.perfil === 'GOBIERNO') ? (
                                    <>
                                        <span className="badge badge-mark bg-danger border-danger align-self-center mr-2"></span> Admin - Cliente { (t.estatus === "INACTIVO" ? t.estatus : '')}
                                    </>
                                ) : (
                                        (t.perfil === 'VENTAS' || t.perfil === 'VENDEDOR') ? (
                                            <>
                                                <span className="badge badge-mark bg-info border-info align-self-center mr-2"></span><a style={{ fontSize: 10 }}>{t.perfil + ' ' + (t.estatus === "INACTIVO" ? ('- ' + t.estatus) : '')}</a>
                                            </>
                                        ) :
                                            (
                                                t.perfil === 'ADMINISTRADORNOMINA' ? (
                                                    <>
                                                        <span className="badge badge-mark bg-info border-info align-self-center mr-2"></span>Nómina { (t.estatus === "INACTIVO" ? t.estatus : '')}
                                                    </>
                                                ) :
                                                    (
                                                        t.perfil === 'ADMINISTRADORJURIDICO' ? (
                                                            <>
                                                                <span className="badge badge-mark bg-info border-info align-self-center mr-2"></span>Jurídico { (t.estatus === "INACTIVO" ? t.estatus : '')}
                                                            </>
                                                        ) : (
                                                                t.perfil === 'ADMINISTRADORAUDITOR' ? (
                                                                    <>
                                                                        <span className="badge badge-mark bg-info border-info align-self-center mr-2"></span>Auditor { (t.estatus === "INACTIVO" ? t.estatus : '')}
                                                                    </>
                                                                ) : (
                                                                        t.perfil === 'ADMIN' ? (
                                                                            <>
                                                                                <span className="badge badge-mark bg-info border-info align-self-center mr-2"></span>Administrador { (t.estatus === "INACTIVO" ? t.estatus : '')}
                                                                            </>
                                                                        ) : (
                                                                                <>
                                                                                    <span className="badge badge-mark bg-info border-info align-self-center mr-2"></span>Capturista { (t.estatus === "INACTIVO" ? t.estatus : '')}
                                                                                </>
                                                                            )
                                                                    )
                                                            )
                                                    )
                                            )
                                    )
                            }
                        </Fragment>
                    );
                }
            },
            {
                key: "action",
                text: "Acción",
                className: "action",
                width: 120,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <div className="text-center">
                            {objec.perfil === "ADMIN" ?
                                (
                                    <>
                                        <Tooltip title="Ver listado de clientes" placement="top" arrow>
                                            <a className="list-icons-item text-blue-300" style={{ cursor: 'pointer' }} onClick={() => this.handleNavigationListCostumer(record)}><i className="icon-magazine"></i></a>
                                        </Tooltip>
                            &nbsp;
                                    </>
                                ) : null
                            }
                            <Tooltip title="Modificar usuario / vendedor" placement="top" arrow>
                                <a className="list-icons-item text-blue-300" style={{ cursor: 'pointer' }} onClick={() => this.handlemodaledit(record)}><i className="icon-pencil"></i></a>
                            </Tooltip>
                            &nbsp;
                            <Tooltip title="Eliminar usuarios / vendedores" placement="top" arrow>
                                <a className="list-icons-item text-orange-300" style={{ cursor: 'pointer' }} onClick={() => this.handlemodaldelete(record)}><i className="icon-user-cancel"></i></a>
                            </Tooltip>
                            &nbsp;

                            <Tooltip title="Ver vendedores" placement="top" arrow>
                                <a className="list-icons-item text-orange-300" style={{ cursor: 'pointer' }} onClick={() => this.handleNavigationListContact(record)}><i className="icon-users"></i></a>
                            </Tooltip>
                        </div>
                    );
                }
            }
        ];
        this.config = ConfigAdmin.arrow;

        this.extraButtons = [
            {
                className: "btn btn-light",
                title: "Export TEst",
                children: [
                    <span>
                        Nuevo<i className="icon-play3 ml-2" aria-hidden="true"></i>
                    </span>
                ],
                onClick: (event) => {
                    window.$("#modal_default").modal("show");
                }
            },
        ]

        this.state = {
            id: objec.id,
            array_users: [],
            usuario: '',
            correo: '',
            nombre: '',
            apellido_paterno: '',
            apellido_materno: '',
            cliente_id: objec.cliente_id,
            datos_fiscales_id: objec.razon_id,
            nombreestado: '',
            telefono: '',
            perfil: '',
            persona_id: 0,
            usuario_id: 0,
            inter_id: 0,
            viewbutton: false,
            perfiluser: objec.perfil,
            errorusuario: false,
            errorcorreo: false,
            errornombre: false,
            errorapellido_paterno: false,
            errorapellido_materno: false,
            errortelefono: false,
            errorperfil: false,
            EstadoData: [],
            viewestados: false
        };
    }
    componentDidMount() {
        var options = {
            perfil: this.state.perfiluser,
            ventas_id: this.state.id
        }
        api.post("getlistusersadmin", options).then((res) => res.data).then((op) => {


            if (op.length > 0) {

                this.setState({
                    array_users: (op).filter((i) => i.perfil === "VENTAS")
                });
            }
        });

        this.getcatalogoEstados();
    }
    handleDataNumberUser() {
        var objec = JSON.parse(localStorage.getItem('id_token'));
        var obje = {
            query: "SELECT numero_usuario FROM activaciones where cliente_id='" + objec.cliente_id + "'"
        }
        api.post('configtabledinamyc', obje).then((r) => r.data)
            .then((j) => {
                //var numero_usuarios_ =j[0];
                if (this.state.array_users.length >= j[0].numero_usuario)
                    this.setState({ viewbutton: true })

                else
                    this.setState({ viewbutton: false })
            }).catch((u) => {
                console.log(u)
            })
    }

    handlemodaledit = (j) => {
        this.setState(j);
        this.setState({ nombreestado: j.estados_id })
        window.$('#edit_modal').modal('show');
    }
    handlemodaldelete = (j) => {
        this.setState(j);
        window.$('#remove_modal').modal('show');
    }
    handlenewuser = (i) => {
        this.setState({
            [i.target.name]: i.target.value
        });
    }
    handleSendData = () => {
        if (this.state.nombre !== '')
            if (this.state.apellido_paterno !== '')
                if (this.state.usuario !== '')
                    if (this.state.correo !== '')
                        if (this.state.perfil !== '')
                            if (this.state.telefono !== '')

                                api.post("newuseradd", this.state).then((u) => u.data).then((i) => {

                                    if (i === "good") {
                                        this.setState({
                                            usuario: '',
                                            correo: '',
                                            nombre: '',
                                            apellido_paterno: '',
                                            apellido_materno: '',
                                            telefono: '',
                                            perfil: '',
                                            persona_id: 0,
                                            usuario_id: 0,
                                            inter_id: 0,
                                        });
                                        this.componentDidMount();
                                        window.$("#modal_default").modal("hide");
                                    }
                                });
                            else {
                                this.setState({ errortelefono: true });
                                setTimeout(() => {
                                    this.setState({
                                        errorusuario: false,
                                        errorcorreo: false,
                                        errornombre: false,
                                        errorapellido_paterno: false,
                                        errorapellido_materno: false,
                                        errortelefono: false,
                                        errorperfil: false,
                                    })
                                }, 3000);
                            } else {
                            this.setState({ errorperfil: true, errortelefono: true })
                            setTimeout(() => {
                                this.setState({
                                    errorusuario: false,
                                    errorcorreo: false,
                                    errornombre: false,
                                    errorapellido_paterno: false,
                                    errorapellido_materno: false,
                                    errortelefono: false,
                                    errorperfil: false,
                                })
                            }, 3000);
                        } else {
                        this.setState({ errorcorreo: true, errorperfil: true, errortelefono: true })
                        setTimeout(() => {
                            this.setState({
                                errorusuario: false,
                                errorcorreo: false,
                                errornombre: false,
                                errorapellido_paterno: false,
                                errorapellido_materno: false,
                                errortelefono: false,
                                errorperfil: false,
                            })
                        }, 3000);
                    } else {
                    this.setState({ errorusuario: true, errorcorreo: true, errorperfil: true, errortelefono: true })
                    setTimeout(() => {
                        this.setState({
                            errorusuario: false,
                            errorcorreo: false,
                            errornombre: false,
                            errorapellido_paterno: false,
                            errorapellido_materno: false,
                            errortelefono: false,
                            errorperfil: false,
                        })
                    }, 3000);
                } else {
                this.setState({ errorapellido_paterno: true, errorusuario: true, errorcorreo: true, errorperfil: true, errortelefono: true })
                setTimeout(() => {
                    this.setState({
                        errorusuario: false,
                        errorcorreo: false,
                        errornombre: false,
                        errorapellido_paterno: false,
                        errorapellido_materno: false,
                        errortelefono: false,
                        errorperfil: false,
                    })
                }, 3000);
            } else {
            this.setState({ errornombre: true, errorapellido_paterno: true, errorusuario: true, errorcorreo: true, errorperfil: true, errortelefono: true })
            setTimeout(() => {
                this.setState({
                    errorusuario: false,
                    errorcorreo: false,
                    errornombre: false,
                    errorapellido_paterno: false,
                    errorapellido_materno: false,
                    errortelefono: false,
                    errorperfil: false,
                })
            }, 3000);
        }
    }
    handledeletefrom = () => {
        /* var options = {
             query: " DELETE FROM intermedia_usuarios_personas_sistema where id=" + this.state.inter_id + "; " +
                 " DELETE FROM Usuario where id=" + this.state.usuario_id + "; DELETE FROM personas where id=" + this.state.persona_id + "; DELETE FROM vendedor WHERE vendedor_id=" + this.state.usuario_id
         }*/
        let options = {
            query: " UPDATE Usuario SET estatus='INACTIVO' where id= " + this.state.usuario_id + " UPDATE personas SET estatus='INACTIVO' WHERE id=" + this.state.persona_id
        }
        api.post("configtabledinamyc", options).then((res) => res.data).then((op) => {
            this.componentDidMount();
        });
    }
    handleUpdateAll = () => {
        var options = {
            query: " UPDATE Usuario SET usuario='" + this.state.usuario + "',email='" + this.state.correo + "',perfil='" + this.state.perfil + "' where id=" + this.state.usuario_id + ";" +
                " UPDATE personas SET nombre='" + this.state.nombre + "',apellido_paterno='" + this.state.apellido_paterno + "',apellido_materno='" + this.state.apellido_materno + "',telefono='" + this.state.telefono + "',correo='" + this.state.correo + "',estados_id='" + this.state.nombreestado + "' where id=" + this.state.persona_id
        }
        api.post("configtabledinamyc", options).then((res) => res.data).then((op) => {
            this.componentDidMount();
            window.$("#edit_modal").modal("hide");
        });
    }

    getcatalogoEstados = async () => {
        let _estados_ = await api.get('catestados?pais=MEX');
        let { data } = await _estados_;
        this.setState({
            EstadoData: data,
        });
    }
    ChangeteState = (e, values) => {
        if (values != null) {
            this.setState({ nombreestado: values.nombre });
        }
    }
    handleNavigationListContact = (i) => {
        localStorage.setItem("_contact_admin_vendors", JSON.stringify(i));
        this.props.history.push('/app/filter/details-sales');
    }
    handleNavigationListCostumer = (j) => {
        localStorage.setItem("_contact_admin_vendors", JSON.stringify(j));
        this.props.history.push('/app/filter/listcostumer');
    }
    render() {
        return (
            <>
                <div className="page-header border-bottom-0">
                    <div className="page-header-content header-elements-md-inline">
                        <div className="page-title d-flex">
                            <a href="#" className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></a>
                        </div>
                        <h5 className="card-title">Administración distribuidores / vendedores</h5>
                        <div className="header-elements d-none mb-3 mb-md-0">
                            <div className="d-flex justify-content-center">
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content pt-0">
                    <div className="card">
                        <div className="row">
                            <div className="card-body">
                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.array_users}
                                    columns={this.columns}
                                    extraButtons={this.extraButtons}
                                />
                            </div>
                        </div>
                    </div>
                    <div id="edit_modal" className="modal fade" role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header bg-transparent">
                                    <h5 className="modal-title">Modificar : {this.state.nombre + ' ' + this.state.apellido_paterno + ' ' + this.state.apellido_materno}</h5>
                                    <button type="button" className="close" data-dismiss="modal">×</button>
                                </div>

                                <div className="modal-body">
                                    <div className="card">
                                        <div className="card-body">
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>Nombre(s):</label>
                                                            <input type="text" className="form-control" placeholder="Nombre(s)" value={this.state.nombre} name="nombre" onChange={this.handlenewuser} autoComplete="off" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Apellido paterno:</label>
                                                            <input type="text" className="form-control" placeholder="Apellido paterno" value={this.state.apellido_paterno} name="apellido_paterno" onChange={this.handlenewuser} autoComplete="off" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Apellido materno:</label>
                                                            <input type="text" className="form-control" placeholder="Apellido materno" value={this.state.apellido_materno} name="apellido_materno" onChange={this.handlenewuser} autoComplete="off" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Usuario:</label>
                                                            <input type="text" className="form-control" placeholder="Usuario" value={this.state.usuario} name="usuario" onChange={this.handlenewuser} autoComplete="off" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Correo:</label>
                                                            <input type="text" className="form-control" placeholder="Correo" value={this.state.correo} name="correo" onChange={this.handlenewuser} autoComplete="off" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        {
                                                            (this.state.perfiluser === "ADMIN") ? (
                                                                <div className="form-group">
                                                                    <label>Perfil:</label>
                                                                    <select className="form-control" name="perfil" value={this.state.perfil} onChange={this.handlenewuser}>
                                                                        <option value="">Seleccione el perfil</option>
                                                                        <option value="ADMIN">Administrador</option>
                                                                        <option value="VENTAS">VENTAS</option>
                                                                        <option value="VENDEDOR">VENDEDOR</option>
                                                                        <option value="ADMINISTRADORNOMINA">Administrador de Nómina</option>
                                                                        <option value="ADMINISTRADORJURIDICO">Administrador de Jurídico</option>
                                                                        <option value="ADMINISTRADORAUDITOR">Administrador de Auditor</option>
                                                                    </select>
                                                                </div>
                                                            ) : (
                                                                    <div className="form-group">
                                                                        <label>Perfil:</label>
                                                                        <select className="form-control" name="perfil" value={this.state.perfil} onChange={this.handlenewuser}>
                                                                            <option value="">Seleccione el perfil</option>
                                                                            <option value="VENDEDOR">VENDEDOR</option>
                                                                        </select>
                                                                    </div>
                                                                )
                                                        }

                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Teléfono:</label>
                                                            <MaskedInput className="form-control" placeholder="Teléfono" value={this.state.telefono}
                                                                mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} name="telefono" onChange={this.handlenewuser} autoComplete="off"
                                                            />

                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    (this.state.perfil === "VENTAS" || this.state.perfil === "VENDEDOR") ? (
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <label>Estados</label>
                                                                    <Autocomplete
                                                                        id="estado"
                                                                        options={this.state.EstadoData}
                                                                        autoHighlight
                                                                        getOptionLabel={(option) => option.nombre}
                                                                        renderOption={(option) => (
                                                                            <div>
                                                                                <span>{option.claves} - {option.nombre}</span>
                                                                            </div>
                                                                        )}
                                                                        renderInput={(params) => (
                                                                            <TextField {...params} label="Selecciona el estado" value={this.state.nombreestado} variant="outlined" inputProps={{ ...params.inputProps, autoComplete: 'new-password', }} margin="dense" fullWidth required variant="outlined" />

                                                                        )}
                                                                        onChange={this.ChangeteState} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : null
                                                }
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer bg-transparent">
                                    <div className="d-flex justify-content-end align-items-center">
                                        <button type="button" className="btn btn-light" data-dismiss="modal">Cancelar</button>
                                        <button type="submit" className="btn bg-blue ml-3" onClick={this.handleUpdateAll}>Enviar cambios<i className="icon-paperplane ml-2"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="remove_modal" className="modal fade" role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Confirmación eliminar</h5>
                                    <button type="button" className="close" data-dismiss="modal">×</button>
                                </div>
                                <div className="modal-body">Está seguró de eliminar el distribuidor / ventas?</div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.handledeletefrom}>Si, Eliminar</button>
                                    <button type="button" className="btn btn-light" data-dismiss="modal">No, Gracias</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="modal_default" className="modal fade" role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Nuevo distribuidor / ventas</h5>
                                    <button type="button" className="close" data-dismiss="modal">×</button>
                                </div>

                                <div className="modal-body">
                                    <div className="card">
                                        <div className="card-body">
                                            <form ref="forms">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>Nombre(s):</label>
                                                            <input type="text" className="form-control" placeholder="Nombre(s)" value={this.state.nombre} name="nombre" onChange={this.handlenewuser} autoComplete="off" />
                                                            {this.state.errornombre ? (
                                                                <div className="alert bg-danger alert-dismissible">
                                                                    <span className="font-weight-semibold">Capturar el registro.</span>
                                                                </div>
                                                            ) : null
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Apellido paterno:</label>
                                                            <input type="text" className="form-control" placeholder="Apellido paterno" value={this.state.apellido_paterno} name="apellido_paterno" onChange={this.handlenewuser} autoComplete="off" />
                                                            {this.state.errorapellido_paterno ? (
                                                                <div className="alert bg-danger alert-dismissible">
                                                                    <span className="font-weight-semibold">Capturar el registro.</span>
                                                                </div>
                                                            ) : null
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Apellido materno:</label>
                                                            <input type="text" className="form-control" placeholder="Apellido materno" value={this.state.apellido_materno} name="apellido_materno" onChange={this.handlenewuser} autoComplete="off" />
                                                            {this.state.errorapellido_paterno ? (
                                                                <div className="alert bg-danger alert-dismissible">
                                                                    <span className="font-weight-semibold">Capturar el registro.</span>
                                                                </div>
                                                            ) : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Usuario:</label>
                                                            <input type="text" className="form-control" placeholder="Usuario" value={this.state.usuario} name="usuario" onChange={this.handlenewuser} autoComplete="off" />
                                                            {this.state.errorusuario ? (
                                                                <div className="alert bg-danger alert-dismissible">
                                                                    <span className="font-weight-semibold">Capturar el registro.</span>
                                                                </div>
                                                            ) : null
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Correo:</label>
                                                            <input type="text" className="form-control" placeholder="Correo" value={this.state.correo} name="correo" onChange={this.handlenewuser} autoComplete="off" />

                                                            {this.state.errorcorreo ? (
                                                                <div className="alert bg-danger alert-dismissible">
                                                                    <span className="font-weight-semibold">Capturar el registro.</span>
                                                                </div>
                                                            ) : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        {
                                                            (this.state.perfiluser === "ADMIN") ? (
                                                                <div className="form-group">
                                                                    <label>Perfil:</label>
                                                                    <select className="form-control" name="perfil" value={this.state.perfil} onChange={this.handlenewuser}>
                                                                        <option value="">Seleccione el perfil</option>
                                                                        <option value="ADMIN">Administrador</option>
                                                                        <option value="VENTAS">VENTAS</option>
                                                                        <option value="VENDEDOR">VENDEDOR</option>
                                                                        <option value="ADMINISTRADORNOMINA">Administrador de Nómina</option>
                                                                        <option value="ADMINISTRADORJURIDICO">Administrador de Jurídico</option>
                                                                        <option value="ADMINISTRADORAUDITOR">Administrador de Auditor</option>
                                                                    </select>
                                                                    {this.state.errorperfil ? (
                                                                        <div className="alert bg-danger alert-dismissible">
                                                                            <span className="font-weight-semibold">Capturar el registro.</span>
                                                                        </div>
                                                                    ) : null
                                                                    }
                                                                </div>
                                                            ) : (
                                                                    <div className="form-group">
                                                                        <label>Perfil:</label>
                                                                        <select className="form-control" name="perfil" value={this.state.perfil} onChange={this.handlenewuser}>
                                                                            <option value="">Seleccione el perfil</option>
                                                                            <option value="VENDEDOR">VENDEDOR</option>
                                                                        </select>
                                                                        {this.state.errorperfil ? (
                                                                            <div className="alert bg-danger alert-dismissible">
                                                                                <span className="font-weight-semibold">Capturar el registro.</span>
                                                                            </div>
                                                                        ) : null
                                                                        }
                                                                    </div>
                                                                )
                                                        }

                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Teléfono:</label>
                                                            <MaskedInput className="form-control" placeholder="Teléfono" value={this.state.telefono}
                                                                mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} name="telefono" onChange={this.handlenewuser} autoComplete="off"
                                                            />
                                                            {this.state.errortelefono ? (
                                                                <div className="alert bg-danger alert-dismissible">
                                                                    <span className="font-weight-semibold">Capturar el registro.</span>
                                                                </div>
                                                            ) : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    (this.state.perfil === "VENTAS" || this.state.perfil === "VENDEDOR") ? (
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <label>Estados</label>
                                                                    <Autocomplete
                                                                        id="estado"
                                                                        options={this.state.EstadoData}
                                                                        autoHighlight
                                                                        getOptionLabel={(option) => option.nombre}
                                                                        renderOption={(option) => (
                                                                            <div>
                                                                                <span>{option.claves} - {option.nombre}</span>
                                                                            </div>
                                                                        )}
                                                                        renderInput={(params) => (
                                                                            <TextField {...params} label="Selecciona el estado" variant="outlined" inputProps={{ ...params.inputProps, autoComplete: 'new-password', }} margin="dense" fullWidth required variant="outlined" />

                                                                        )}
                                                                        onChange={this.ChangeteState} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : null
                                                }

                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <div className="d-flex justify-content-end align-items-center">
                                        <button type="button" className="btn btn-light" data-dismiss="modal">Cancelar</button>
                                        <button type="submit" className="btn bg-blue ml-3" onClick={this.handleSendData}>Enviar <i className="icon-paperplane ml-2"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(VendorsListAmind);
