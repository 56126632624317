import React, { Component } from 'react';
import api from '../../Config/apifiles';
import { Tooltip, } from "@material-ui/core";
import TasaEfec from './grapTasaEfectiva';
class DeclaracionAnual extends Component {
    constructor(props) {
        super(props);
        let objects = JSON.parse(localStorage.getItem("datacompanyall"));
        this.state = {
            empresa_id: objects.id,
            data: [],
            dataf: [],
            title: 'Declaración anual de contribuyentes',
            details: [],
            viewdetails: false,
            exercise: (new Date()).getFullYear()
        }
    }
    componentDidMount() {
        this.getdataaudite();
    }
    getdataaudite = async () => {
        let data_ = await api.post('list-anules-contribuyentes', { empresa_id: this.state.empresa_id });
        let datas_ = await api.post('get-list-acuses-anual-company', { empresa_id: this.state.empresa_id });
        let resultadoaudite_ = await data_.data;
        this.setState({
            data: (resultadoaudite_).sort(function (a, b) {
                return a.ejercicio - b.ejercicio;
            }),
            dataf: datas_.data
        });
    }
    handleviewmodalAdd = async (i) => {
        let data_ = await api.post('details-filter-exercise', i);
        let resultadoaudite_ = await data_.data;
        this.setState({ details: resultadoaudite_, viewdetails: true, exercise: i.ejercicio });
    }
    _viewInit_ = () => {
        this.setState({ viewdetails: false });
    }
    viewpdf = (t) => {
        let linkpdf = 'https://e-xpertum.com.mx/adminfile/api/displayfilepdf/bd54bba3f9d85ea1426942775518b90b6/ecb868faf29054fedf1ac6cbac5fc63/' + t.directorio;
        var win = window.open(linkpdf, '_blank');
        if (win) {
            win.focus();
        } else {
            alert('Please allow popups for this website');
        }
    }
    render() {
        return (
            <>
                <div className="card-group-control card-group-control-left" id="accordion-control">
                    <div className="card">
                        <div className="card-header">
                            <h6 className="card-title">
                                <a data-toggle="collapse" className="text-body collapsed" href="#accordion-control-group1" aria-expanded="false">Comportamiento de la tasa efectiva</a>
                            </h6>
                        </div>
                        <div id="accordion-control-group1" className="collapse show" data-parent="#accordion-control" style={{}}>
                            <div className="card-body">

                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="card border-top-success rounded-top-0">
                                            <div className="card-header">
                                            </div>
                                            <div className="card-body">
                                                <table className='table'>
                                                    <thead>
                                                        <tr>
                                                            <th>Ejercicio</th>
                                                            <th className='text-center'>Tasa efectiva de ISR</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            (this.state.dataf || []).map((h, x) => (
                                                                <tr key={x}>
                                                                    <td>
                                                                        <a style={{ cursor: 'pointer', color: "#8dccff", textDecoration: "none", backgroundColor: 'transparent' }}>{h.ejercicio}</a>
                                                                    </td>
                                                                    <td style={{ textAlign: 'right' }}>{
                                                                        calculoporcentaje(h)
                                                                    }</td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="card border-bottom-success rounded-bottom-0">
                                            <div className="card-header">
                                                <h6 className="card-title">Tasa efectiva de ISR</h6>
                                            </div>
                                            <div className="card-body">
                                                {
                                                    this.state.dataf.length > 0 ? (
                                                        <TasaEfec d={this.state.dataf} />
                                                    ) : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <h6 className="card-title">
                                <a className="collapsed text-body" data-toggle="collapse" href="#accordion-control-group2"><h6 className="card-title">{this.state.title}</h6></a>
                            </h6>
                        </div>
                        <div id="accordion-control-group2" className="collapse" data-parent="#accordion-control">
                            <div className="card-body">
                                {
                                    (!this.state.viewdetails) ? (
                                        <>
                                            {
                                                (this.state.data.length > 0) ? (
                                                    <div className="content pt-0">
                                                        <div className="card">

                                                            <div className="card-body">
                                                                <table className="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th style={{ textAlign: 'center' }}>Ejercicio</th>
                                                                            <th style={{ textAlign: 'center' }}>Tipo de declaración</th>
                                                                            <th style={{ textAlign: 'center' }}>Fecha de presentación</th>
                                                                            <th style={{ textAlign: 'center' }}>Número de operación</th>
                                                                            <th style={{ textAlign: 'center' }}>Ingresos acumulables</th>
                                                                            <th style={{ textAlign: 'center' }}>Deducciones autorizadas</th>
                                                                            <th style={{ textAlign: 'center' }}>Utilidad (Pérdida) fiscal</th>
                                                                            <th style={{ textAlign: 'center' }}>Tasa efectiva</th>
                                                                            <th style={{ textAlign: 'center' }}></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            (this.state.data || []).map((h, j) => (
                                                                                <tr key={j}>

                                                                                    <td>{h.ejercicio}</td>

                                                                                    <td>{h.tipo_declaracion}</td>
                                                                                    <td>{h.fecha_de_presentacion.substr(0, 10)}</td>
                                                                                    <td>{h.numero_de_operacion}</td>
                                                                                    <td style={{ textAlign: 'right' }}>{(h.ingresos === 0 ? '' : h.ingresos.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                                                                    <td style={{ textAlign: 'right' }}>{(h.deducciones_autorizadas === 0 ? '' : h.deducciones_autorizadas.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                                                                    <td style={{ textAlign: 'right' }}>{(h.ptupagado === 0 ? '' : h.ptupagado.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                                                                    <td style={{ textAlign: 'right' }}>{((h.ptupagado === 0 || h.ptupagado < 0) ? '' : ((h.ptupagado / h.ingresos) * 100).toFixed(1).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + " %")}</td>
                                                                                    <td><button type="button" className="btn btn-light" onClick={() => this.viewpdf(h)}><i className="icon-file-pdf text-success"></i></button></td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : <div className="alert bg-info alert-styled-left alert-dismissible">
                                                    <span className="font-weight-semibold">No existe información para mostrar!</span>.
                                                </div>
                                            }
                                        </>
                                    ) :
                                        (
                                            <>
                                                <div className="content pt-0">
                                                    <div className="card">
                                                        <div className="card-header header-elements-inline">
                                                            <h6 className="card-title">Ingresos de declaración anual de {this.state.exercise}</h6>
                                                            <div className="d-flex align-items-center" style={{ cursor: 'pointer' }} onClick={this._viewInit_}>
                                                                <i className="icon-arrow-left16 mr-3 icon-2x text-primary"></i>
                                                                <div className="text-primary">Inicio</div>
                                                            </div>
                                                        </div>
                                                        <div className="card-body">
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Mes</th>
                                                                        <th>Importe</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        (this.state.details || []).map((hg, y) => (
                                                                            <tr key={y}>
                                                                                <td style={{ textAlign: 'left' }}>{hg.periodo}</td>
                                                                                <td style={{ textAlign: 'left' }}>{hg.importe.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default DeclaracionAnual;
function calculoporcentaje(x) {
    if (x.ptupagado === 0) {
        return '0 %';
    }
    else if (x.ptupagado < 0) {
        let ft = 0;
        if (x.ptupagado < 0 && x.ingresos > 0)
            ft = ((((x.ptupagado * -1) / x.ingresos) * 100) * -1).toFixed(1).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        else if (x.ingresos === 0)
            ft = 0;
        return (0) + ' %';
    }
    else if (x.ptupagado > 0) {
        let ft = (((x.ptupagado) / x.ingresos) * 100).toFixed(1).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        return (ft) + ' %';
    }
}