import React, { Component, Fragment } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';

import { Snackbar, Backdrop, CircularProgress } from '@material-ui/core';

import MuiAlert from '@material-ui/lab/Alert';
import api from '../../Config/api'

import { withRouter } from 'react-router-dom';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
class ListClients extends Component {
    constructor(props) {
        super(props);
        var objec = JSON.parse(localStorage.getItem('id_token'));
        var _data_vendors_ = JSON.parse(localStorage.getItem('_contact_admin_vendors'));
        console.log(_data_vendors_)
        this.columns = [
            {
                key: "razon_social",
                text: "Nombre, Denominación o razón social",
                className: "razon_social",
                align: "left",
                sortable: true,
            },
            {
                key: "rfc",
                text: "RFC",
                className: "rfc",
                align: "left",
                sortable: true
            },
            {
                key: "nombre",
                text: "Nombre(s)",
                className: "nombre",
                sortable: true
            }, {
                key: "apellido",
                text: "Apellido(s)",
                className: "apellido",
                sortable: true
            }, {
                key: "telefono",
                text: "Teléfono",
                className: "telefono",
                sortable: true
            }, {
                key: "correo",
                text: "Correo",
                className: "correo",
                sortable: true
            }
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,
            button: {
                excel: true,
                print: true,
                extra: false,
            },
            language: {
                length_menu: "Mostrar _MENU_ registros por página",
                filter: "Buscar en registros ...",
                info: "Mostrando _START_ a _END_ de _TOTAL_ entradas",
                pagination: {
                    first: "Primero",
                    previous: "Previa",
                    next: "próximo",
                    last: "Última"
                }
            },
            no_data_text: "No se encontraron registros"
        }
        this.extraButtons = [
            {
                className: "btn btn-light",
                title: "Export TEst",
                children: [
                    <span>
                        Nuevo Cliente<i className="icon-play3 ml-2" aria-hidden="true"></i>
                    </span>
                ],
                onClick: (event) => {
                    window.$("#modal_full").modal("show");
                }
            },
        ]
        this.state = {
            info: [],
            loading: true,
            nombrevenderdor: _data_vendors_.nombre_completo,
            usuario_id: _data_vendors_.usuario_id,//(objec.id),
            perfil: 'VENTAS',

        }
    }
    componentDidMount() {
        api.post('listRazonPersonasFilter', { user_id: this.state.usuario_id, perfil: this.state.perfil }).then(response => response.data)
            .then(data => {
                this.setState({
                    info: data,
                    loading: false
                });
            });
    }

    render() {
        return (
            <>
                <Backdrop style={{ color: "#fff", background: "#B9B6B6", zIndex: 400, opacity: "0.5" }} open={this.state.loading} >
                    <CircularProgress color="inherit" />
                </Backdrop>

                <div className="page-header border-bottom-0">
                    <div className="page-header-content header-elements-md-inline">
                        <div className="page-title d-flex">
                            <a href="#/app/filter/Sales" style={{ color: 'white' }}><h4><i className="icon-arrow-left52 mr-2"></i> <span className="font-weight-semibold">Listado de distribuidores</span></h4></a>
                            <a className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></a>
                        </div>
                        <h5 className="">Clientes de {this.state.nombrevenderdor}</h5>
                        <div className="header-elements d-none mb-3 mb-md-0">
                            <div className="d-flex justify-content-center">

                            </div>
                        </div>
                    </div>

                </div>
                <div className="content pt-0">
                    <div className="card">
                        <div className="card-body">
                            <ReactDatatable
                                config={this.config}
                                records={this.state.info}
                                columns={this.columns}
                                extraButtons={this.extraButtons}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(ListClients);