import React, { Component } from 'react';
import api from '../../../app/Config/api';

class Suscriptions extends Component {
    constructor(props) {
        super(props);
        var object = JSON.parse(localStorage.getItem('id_token'));
        this.state = {
            id: object.razon_id,
            info: [],
            suscripcion: []
        }
    }
    componentDidMount() {
        this.getlist_();
    }
    getlist_ = async () => {
        let d = await api.post('listSuscripcionEmpresa', this.state);
        var contenidochingon = d.data[0];
        let _d = await api.get('listSuscripcion');
        let result = _d.data;
        let teamsFromApi = result.map(team => {
            return { id: team.id, nombre: team.nombre, status: (contenidochingon.nombre === team.nombre ? true : false) };
        });
        this.setState({ info: d.data, suscripcion: teamsFromApi })
    }
    render() {
        return (
            <div className="row">
                <div className="col-lg-12">
                    <div className="card border-left-3 border-left-success rounded-left-0">
                        <div className="card-body">
                            <div className="row">
                                {
                                    (this.state.info || []).map((d, f) => (

                                        <div className="col-lg-6">
                                            <div className="card border-left-3 border-left-success rounded-left-0" key={f}>
                                                <div className="card-body">
                                                    <div className="d-sm-flex align-item-sm-center flex-sm-nowrap">
                                                        <div>

                                                            <ul className="list list-unstyled mb-0">
                                                                <li>Servicio</li>
                                                                <li>Fecha de Inicio</li>
                                                                <li>Número de usuarios</li>
                                                            </ul>
                                                        </div>
                                                        <div className="text-sm-right mb-0 mt-3 mt-sm-0 ml-auto">
                                                            <ul className="list list-unstyled mb-0">
                                                                <li><span className="text-muted font-size-sm font-weight-normal ml-auto">{d.descripcion}</span></li>
                                                                <li><span className="text-muted font-size-sm font-weight-normal ml-auto">{d.fecha_inicio}</span></li>
                                                                <li><span className="text-muted font-size-sm font-weight-normal ml-auto">{d.numero_usuario}</span></li>
                                                            </ul>
                                                        </div>
                                                        <div className="text-sm-right mb-0 mt-3 mt-sm-0 ml-auto">
                                                            <h6 className="font-weight-semibold" style={{ color: 'transparent' }}>w</h6>
                                                            <ul className="list list-unstyled mb-0">
                                                                <li>Fecha fin: </li>
                                                                <li>Número de empresas:</li>
                                                            </ul>
                                                        </div>
                                                        <div className="text-sm-right mb-0 mt-3 mt-sm-0 ml-auto">
                                                            <h6 className="font-weight-semibold" style={{ color: 'transparent' }}>w</h6>
                                                            <ul className="list list-unstyled mb-0">
                                                                <li><span className="text-muted font-size-sm font-weight-normal ml-auto">{d.fecha_fin}</span></li>
                                                                <li><span className="text-muted font-size-sm font-weight-normal ml-auto">{d.numero_empresa}</span></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Suscriptions;

/*import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardActions, CardHeader, CardContent, Button, Typography, Divider, Grid, FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import api from '../../../app/Config/api';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(3),
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    formControl: {
        margin: theme.spacing(3),
    },
}));
export default function OutlinedCard() {
    const classes = useStyles();
    const [info, setInforma] = useState([]);
    const [suscripcion, setSuscripcion] = useState([]);
    useEffect(() => {
        var object = JSON.parse(localStorage.getItem('id_token'));
        var obje = {
            id: object.razon_id
        }
        api.post('listSuscripcionEmpresa', obje).then((res) => {
            console.log(res.data)
            setInforma(res.data);
            var contenidochingon = res.data[0];
            api.get('listSuscripcion').then((res) => {
                let result = res.data;
                let teamsFromApi = result.map(team => {
                    return { id: team.id, nombre: team.nombre, status: (contenidochingon.nombre === team.nombre ? true : false) };
                });
                setSuscripcion(teamsFromApi);
            });
        });
    }, []);

    ///const { descripcion, fecha_fin, fecha_inicio, nombre, numero_empresa, numero_usuario } = info;

    return (
        <>
            <div className="row">
                <div className="col-lg-8">
                    <div className="card border-left-3 border-left-success rounded-left-0">
                        <div className="card-body">

                            <table className='table'>

                                {
                                    (info || []).map((d, f) => {
                                        <tbody key={d}>
                                            <tr>
                                                <td>Servicio</td><td>{d.descripcion}</td>
                                            </tr>
                                            <tr>
                                                <td>Fecha de Inicio</td><td>{d.fecha_inicio}</td>
                                            </tr>
                                        </tbody>

                                     <div className="card border-left-3 border-left-success rounded-left-0" key={f}>
                                <div className="card-body">
                                    <div className="d-sm-flex align-item-sm-center flex-sm-nowrap">
                                        <div>
                                            {
                                                console.log(d)
                                            }
                                            <ul className="list list-unstyled mb-0">
                                                <li>Servicio</li>
                                                <li>Fecha de Inicio</li>
                                                <li>Número de usuarios</li>
                                            </ul>
                                        </div>
                                        <div className="text-sm-right mb-0 mt-3 mt-sm-0 ml-auto">
                                            <ul className="list list-unstyled mb-0">
                                                <li><span className="text-muted font-size-sm font-weight-normal ml-auto">{d.descripcion}</span></li>
                                                <li><span className="text-muted font-size-sm font-weight-normal ml-auto">{d.fecha_inicio}</span></li>
                                                <li><span className="text-muted font-size-sm font-weight-normal ml-auto">{d.numero_usuario}</span></li>
                                            </ul>
                                        </div>
                                        <div className="text-sm-right mb-0 mt-3 mt-sm-0 ml-auto">
                                            <h6 className="font-weight-semibold" style={{ color: 'transparent' }}>w</h6>
                                            <ul className="list list-unstyled mb-0">
                                                <li>Fecha fin: </li>
                                                <li>Número de empresas:</li>
                                            </ul>
                                        </div>
                                        <div className="text-sm-right mb-0 mt-3 mt-sm-0 ml-auto">
                                            <h6 className="font-weight-semibold" style={{ color: 'transparent' }}>w</h6>
                                            <ul className="list list-unstyled mb-0">
                                                <li><span className="text-muted font-size-sm font-weight-normal ml-auto">{d.fecha_fin}</span></li>
                                                <li><span className="text-muted font-size-sm font-weight-normal ml-auto">{d.numero_empresa}</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                    })
                                }
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-header bg-transparent border-bottom header-elements-inline">
                            <span className="card-title font-weight-semibold">Plan seleccionado</span>
                            <div className="header-elements">
                                <div className="list-icons">
                                    <a className="list-icons-item" data-action="collapse"></a>
                                </div>
                            </div>
                        </div>

                        <div className="card-body p-2">
                            <div className="row">
                                <div className="col-md-12">

                                    {
                                        (suscripcion || []).map((y, i) => (
                                            y.status ? (
                                                <div className="form-check" key={i}>
                                                    <label className="form-check-label">
                                                        <div className="uniform-checker border-warning text-warning">
                                                            <span className="checked">
                                                                <input type="checkbox" className="form-check-input-styled-warning" defaultChecked={true} data-fouc="" />
                                                            </span>
                                                        </div>
                                                        {y.nombre}
                                                    </label>
                                                </div>) : (<div className="form-check" key={i}>
                                                    <label className="form-check-label">
                                                        <div className="uniform-checker border-warning text-warning">
                                                            <span className="">
                                                                <input type="checkbox" className="form-check-input-styled-warning" data-fouc="" />
                                                            </span>
                                                        </div>
                                                        {y.nombre}
                                                    </label>
                                                </div>)

                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </>
    );
}*/
/**
 *
 * <Grid container spacing={3}>
                <Grid item xs={8}>
                    <Card>
                        <CardHeader title={'Detalle de la suscripción'} align="center" /><Divider />
                        <CardContent>
                            <Grid item container spacing={3} xs={12}>
                                <Grid item xs={6}>
                                    <Typography className={classes.pos} color="textSecondary"><strong>Fecha Inicio:</strong> </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography className={classes.pos} color="textSecondary"><strong>Fecha Fin:</strong></Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography className={classes.pos} color="textSecondary"><strong>Número de usuarios:</strong></Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography className={classes.pos} color="textSecondary"><strong>Número de empresas:</strong> </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography className={classes.pos} color="textSecondary"><strong>Suscripción:</strong> </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography className={classes.pos} color="textSecondary"><strong>Servicio:</strong> </Typography>
                                </Grid>
                            </Grid>

                        </CardContent>
                        <CardActions>
                            <Button size="small">Learn More</Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card>
                        <CardHeader title={'Plan seleccionado'} align="center" /><Divider />
                        <CardContent>
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormGroup>
                                    {
                                        (suscripcion || []).map((y, i) => (
                                            <FormControlLabel key={i} control={<Checkbox checked={y.status} name={y.nombre} />} label={y.nombre} />
                                        ))
                                    }
                                </FormGroup>
                            </FormControl>
                        </CardContent>
                        <CardActions>
                            <Button size="small">Cambiar de plan</Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
 *
 *
 */