import React from "react";
import api from "../app/Config/api";
var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();
function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("id_token"),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut };

// ###########################################################

function loginUser(dispatch, login, password, history, setIsLoading, setError, setNotifycate, setNotifycateStatus) {

  setError(false);
  setIsLoading(true);
  setNotifycate('');
  setNotifycateStatus({
    statusmesagge: false,
    vertical: 'top',
    horizontal: 'right',
  });
  if (!login == '' && !password == '') {

    setTimeout(() => {
      const parameter = new FormData();
      parameter.append("user", login);
      parameter.append("password", password);
      api.post('login', parameter).then((response) => {
        var resultResponse = response.data;
        console.log(resultResponse)
        if (resultResponse.status === 200) {
          localStorage.setItem('id_token', JSON.stringify(resultResponse));
          setError(null);
          setIsLoading(false);
          dispatch({ type: 'LOGIN_SUCCESS' });
          if (resultResponse.perfil === "GOBIERNO") { history.push('/app/ui/validadorgobierno') }
          else if (resultResponse.perfil === "FINANCIERA") { history.push('/app/ui/financial') }
          else { history.push('/app/dashboard') }
        }
        else if (resultResponse.status === 404) {
          var objts = {
            vertical: 'top',
            horizontal: 'right',
          }
          setNotifycateStatus({ statusmesagge: true, ...objts });
          setNotifycate(resultResponse.mesagge);
          setIsLoading(false);
        }
      }).catch((x) => {
        console.log(x)
      });


    }, 500);
  } else {
    dispatch({ type: "LOGIN_FAILURE" });
    setError(true);
    setIsLoading(false);
  }
}

function signOut(dispatch, history) {
  localStorage.removeItem("id_token");
  localStorage.removeItem("_data_info_empresa_");
  localStorage.removeItem("parameterscompany");
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/login");
}
