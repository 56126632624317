import React, { Component } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
class DayWeeksEnd extends Component {
    constructor(props) {
        super(props);
        ///console.log(props)
        this.state = {
            namedays: [{ day: 'Lunes', status: false, num: 1 },
            { day: 'Martes', status: false, num: 2 },
            { day: 'Miércoles', status: false, num: 3 },
            { day: 'Jueves', status: false, num: 4 },
            { day: 'Viernes', status: false, num: 5 },]
        }
    }
    componentDidMount() {
        console.log((this.props.data.config))
        console.log(JSON.parse(this.props.data.config))
        if ((this.props.data.config) != null) {
            let week = JSON.parse(this.props.data.config)
            if (week.length > 2) {
                this.setState({ namedays: week })
            }
        } //else
        /// this.setState({ namedays: week })
    }
    _set_data_ = async (r) => {
        let xxxx = this.state.namedays;
        for (let x = 0; x < xxxx.length; x++) {
            if (xxxx[x].day == r.target.value && r.target.checked == true)
                xxxx[x].status = true;
            else if (xxxx[x].day == r.target.value && r.target.checked == false)
                xxxx[x].status = false;
        }
        this.setState({ namedays: xxxx });
        this.props.setdays(xxxx)
    }
    render() {
        return (
            <FormControl component="fieldset">
                <FormGroup>
                    {(this.state.namedays).map((i, o) =>
                    (
                        <>
                            <FormControlLabel key={o} control={<Checkbox checked={i.status} name={i.day} value={i.day} onChange={this._set_data_} />}
                                label={<span style={{ color: 'white' }}>{i.day}</span>}
                            />
                        </>
                    ))}
                </FormGroup>
            </FormControl>
        );
    }
}

export default DayWeeksEnd;
