import React, { Component, Fragment } from 'react';
import { Typography, Card, CardContent, Grid, Avatar } from '@material-ui/core';
import api from '../../Config/api';
class DetailsServicios extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sistemas: [],
            descripcion: ''
        }

    }
    componentDidMount() {
        console.log(this.props.inf.descripcion)
        this.setState({
            descripcion: this.props.inf.descripcion
        });

        api.post('listProductidModulo', this.props.inf)
            .then(response => response.data)
            .then(data => {
                console.log(data)
                this.setState({
                    sistemas: data
                })
            });
    }
    render() {
        return (<>
            <Card>
                <CardContent>
                    <form>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography color="textPrimary">Nombre: {this.state.descripcion}</Typography>
                            </Grid>

                            {
                                (this.state.sistemas || []).map((y, i) => (
                                    <Grid key={i} container >
                                        <Grid item xs={4}>
                                            <Avatar alt={y.nombre} src={'http://localhost:3005/' + y.nombre_corto} />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography color="textPrimary"> {y.nombre} </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                        </Grid>
                                    </Grid>
                                ))
                            }


                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </>);
    }
}

export default DetailsServicios;