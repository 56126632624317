import React, { Component } from 'react';
import { Tooltip } from "@material-ui/core";
import axios from '../../app/Config/apifiles';
class UploadFiles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            key: this.props.match.params.id,
            selectedFileCer: null,
            selectedFileKey: null,
            selectedFileConstancia: null,
            labeltextcer: 'Cargar archivo .cer',
            labeltextkey: 'Cargar archivo .key',
            labeltextconstancia: 'Seleccione el archivo',
            dircer: '',
            dirkey: '',
            secret: '',
            list: [],
            mensaje: false,
            textmensaje: '',
            textmensajeerror: ''
        }
        this.onsubmitform = this.onsubmitform.bind(this);
    }
    componentDidMount() {
        this.getApplist();
    }
    getApplist = async () => {
        var form = new FormData();
        form.append('key', this.state.key)
        let upload = await axios.post('app-select-list-cumplimiento', form)
        console.log(upload.data)
        let list_ = [];
        let _app_list_ = upload.data;
        for (let f = 0; f < _app_list_.length; f++) {
            list_.push(JSON.parse(_app_list_[f].description));
        }
        this.setState({ list: list_ });
    }
    onFileChangecer = async event => {
        this.setState({ selectedFileCer: event.target.files[0], labeltextcer: event.target.files[0].name });
        var form = new FormData();
        form.append('file', event.target.files[0])
        form.append('key', this.state.key)
        let upload = await axios.post('upload-file-efirma', form)
        console.log(upload)
    };
    onFileChangekey = async event => {
        this.setState({ selectedFileKey: event.target.files[0], labeltextkey: event.target.files[0].name });
        var form = new FormData();
        form.append('file', event.target.files[0])
        form.append('key', this.state.key)
        let upload = await axios.post('upload-file-efirma', form);

    };
    onSetSecret = event => {
        this.setState({ secret: event.target.value });
    };
    onsubmitform = async () => {

    }
    onFileChangeConstancia = async (event) => {
        this.setState({ selectedFileConstancia: event.target.files[0], labeltextconstancia: event.target.files[0].name });
        var form = new FormData();
        form.append('file', event.target.files[0])
        form.append('id', this.state.key)
        let dt = await axios.post('maping-constancia-cumplimiento', form);
        this.getApplist();
        let xpp = dt.data;
        if (xpp.status == 300) {
            this.setState({
                mensaje: true,
                textmensaje: '',
                textmensajeerror: ' El archivo ingresado, no corresponde a la empresa, ingresar el correspondiente.'
            })
            setTimeout(() => {
                this.setState({ mensaje: false, textmensaje: '', textmensajeerror: '' })
            }, 40000);
        }
        else {
            this.setState({
                mensaje: true,
                textmensaje: 'Se ha validado correctamente el documento ingresado.',
                textmensajeerror: ''
            });
            setTimeout(() => {
                this.setState({ mensaje: false, textmensaje: '', textmensajeerror: '' })
            }, 40000);
        }
        console.log(dt.data)
    };
    onViewPdf = (k) => {
        window.open('view-pdf-cumplimiento/' + k.optio01 + '/' + k.option02 + '/' + k.dir, '_blank');
    }
    render() {
        return (
            <>
                <div className="navbar navbar-expand-md navbar-light navbar-static" style={{ backgroundColor: "#262d3c" }}>
                    <div className="navbar-brand">
                        <a href='/' className="d-inline-block">
                            <img src="logos/logo_expertumb.png" alt="" />
                        </a>
                    </div>

                    <div className="d-md-none">
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-mobile">
                            <i className="icon-tree5"></i>
                        </button>
                    </div>

                    <div className="collapse navbar-collapse" id="navbar-mobile">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item dropdown">
                                <a href="#" className="navbar-nav-link">
                                    <i className="icon-display4"></i>
                                    <span className="d-md-none ml-2">Go to website</span>
                                </a>
                            </li>

                            <li className="nav-item dropdown">
                                <a href="#" className="navbar-nav-link">
                                    <i className="icon-user-tie"></i>
                                    <span className="d-md-none ml-2">Contact admin</span>
                                </a>
                            </li>

                            <li className="nav-item dropdown">
                                <a href="#" className="navbar-nav-link">
                                    <i className="icon-cog3"></i>
                                    <span className="d-md-none ml-2">Options</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="page-content login-cover">
                    <div className="content-wrapper">
                        <div className="content-inner">
                            <div className="content d-flex justify-content-center align-items-center">
                                <div className="login-form wmin-sm-600" style={{ width: '80%' }}>
                                    <div className="card mb-0">
                                        <ul className="nav nav-tabs nav-tabs-bottom nav-justified rounded-top mb-0">
                                            <li className="nav-item"><a href="#login-tab1" className="nav-link border-y-0 border-left-0 active" data-toggle="tab"><h6 className="my-1">E-firma</h6></a></li>
                                            <li className="nav-item"><a href="#login-tab2" className="nav-link border-y-0 border-right-0" data-toggle="tab"><h6 className="my-1">Constancia de cumplimiento</h6></a></li>
                                        </ul>
                                        <div className="tab-content card-body">
                                            <div className="tab-pane fade show active" id="login-tab1">
                                                <div className="row">
                                                    <div className="col-sm-2">
                                                    </div>
                                                    <div className="col-sm-8">
                                                        <form autoComplete="off" onSubmit={this.onsubmitform} className="login-form wmin-sm-600">
                                                            <div className="text-center mb-3">
                                                                <h5 className="mb-0">Ingrese sus datos de la e-firma</h5>
                                                                <span className="d-block text-muted">Todos sus datos permanecen cifrados.</span>
                                                            </div>
                                                            <div className="form-group form-group-feedback form-group-feedback-left">
                                                                <div className="custom-file">
                                                                    <input type="file" className="custom-file-input" id="btncer" accept=".cer" onChange={this.onFileChangecer} />
                                                                    <label className="custom-file-label" htmlFor="btncer">{this.state.labeltextcer}</label>
                                                                </div>
                                                            </div>
                                                            <div className="form-group form-group-feedback form-group-feedback-left">
                                                                <div className="custom-file">
                                                                    <input type="file" className="custom-file-input" id="btnkey" accept=".key" onChange={this.onFileChangekey} />
                                                                    <label className="custom-file-label" htmlFor="btnkey">{this.state.labeltextkey}</label>
                                                                </div>
                                                            </div>
                                                            <div className="form-group form-group-feedback form-group-feedback-left">
                                                                <input type="password" className="form-control" placeholder="Password" required autoComplete="off" onChange={this.onSetSecret} />
                                                                <div className="form-control-feedback">
                                                                    <i className="icon-lock2 text-muted" />
                                                                </div>
                                                            </div>
                                                            <div className="form-group d-flex align-items-center">
                                                                <label className="custom-control custom-checkbox">
                                                                    <input type="checkbox" name="remember" className="custom-control-input" required />
                                                                    <span className="custom-control-label">He leído y acepto la política de privacidad.</span>
                                                                </label>
                                                            </div>
                                                            <div className="form-group">
                                                                <button type="submit" className="btn btn-primary btn-block">Enviar</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div className="col-sm-2">
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="tab-pane fade" id="login-tab2">
                                                {
                                                    (this.state.mensaje && this.state.textmensaje !== "") ?
                                                        (
                                                            <div className="alert alert-success alert-styled-left alert-arrow-left alert-dismissible">

                                                                <span className="font-weight-semibold">Información!</span> {this.state.textmensaje}
                                                            </div>
                                                        ) : null
                                                }
                                                {
                                                    (this.state.mensaje && this.state.textmensajeerror !== "") ?
                                                        (
                                                            <div className="alert alert-danger alert-styled-left alert-dismissible">

                                                                <span className="font-weight-semibold">Error!</span> {this.state.textmensajeerror}
                                                            </div>
                                                        ) : null
                                                }


                                                <form>
                                                    <div className="form-group form-group-feedback form-group-feedback-left">
                                                        <div className="custom-file">
                                                            <input type="file" className="custom-file-input" id="btnkey" accept=".pdf" onChange={this.onFileChangeConstancia} />
                                                            <label className="custom-file-label" htmlFor="btnkey">{this.state.labeltextconstancia}</label>
                                                        </div>
                                                    </div>
                                                </form>
                                                {
                                                    this.state.list.length > 0 ?
                                                        (<table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th>RFC</th>
                                                                    <th>Folio</th>
                                                                    <th>Estatus</th>
                                                                    <th>Fecha Revisión</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    (this.state.list || []).map((d, f) => (
                                                                        <tr>
                                                                            <td>{d.internalIdentifier}</td>
                                                                            <td>{d.taxpayer.rfc}</td>
                                                                            <td>{d.result === "negative" ?
                                                                                (<span className="text-danger">Negativa</span>) :
                                                                                <span className="text-success">Positiva</span>
                                                                            }</td>
                                                                            <td>{d.createdAt}</td>
                                                                            <td><button type="button" className="btn btn-light btn-icon" onClick={() => this.onViewPdf(d.file)}>PDF <i className="icon-file-pdf text-success"></i></button></td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>) : null
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="navbar navbar-expand-lg navbar-light" style={{ position: 'fixed', bottom: 0, width: '100%' }}>
                            <div className="text-center d-lg-none w-100">
                                <button type="button" className="navbar-toggler dropdown-toggle" data-toggle="collapse" data-target="#navbar-footer">
                                    <i className="icon-unfold mr-2"></i>
                                    Pie de página
                                </button>
                            </div>

                            <div className="navbar-collapse collapse" id="navbar-footer">
                                <span className="navbar-text">
                                    <span style={{ fontSize: '15px' }}> &copy;</span> {this.state.ejercicio}  Mercanetica estrategias S.A de C.V.
                                </span>

                                <ul className="navbar-nav ml-lg-auto">
                                    <li className="nav-item"><a href="https://e-xpertum.com.mx/webadmin/read-android" className="navbar-nav-link"><Tooltip arrow placement="top" title="Click en el icono para descargar el APP para Android"><span style={{ color: 'green' }}><i style={{ fontSize: '20px' }} className="icon-android"></i></span></Tooltip> </a></li>
                                    <li className="nav-item"><a href="https://e-xpertum.com.mx/webadmin/read-ios" className="navbar-nav-link"><Tooltip arrow placement="top" title="Click en el icono para descargar el APP para IOS i-Phn"><span className="text-info-400"><i style={{ fontSize: '20px' }} className="icon-apple2"></i></span></Tooltip></a></li>
                                    <li className="nav-item"><a href="https://e-xpertum.com.mx/adminfile/preguntas-frecuentes" className="navbar-nav-link" target="_blank"><span className="text-warning-400"><i className="icon-lifebuoy mr-2"></i> Preguntas frecuentes</span></a></li>
                                    <li className="nav-item"><a href="https://e-xpertum.com.mx/adminfile/terminosycondiciones" className="navbar-nav-link" target="_blank"><span className="text-warning-400"><i className="icon-file-text2 mr-2"></i> Términos y condiciones</span></a></li>
                                    <li className="nav-item"><a href="https://e-xpertum.com.mx/adminfile/avisoprivacidad" className="navbar-nav-link" target="_blank"><span className="text-warning-400"><i className="icon-clipboard3 mr-2"></i> Aviso de privacidad</span></a></li>
                                    <li className="nav-item"><a href="https://e-xpertum.com/" className="navbar-nav-link" target="_blank"><span className="text-warning-400"><i className="icon-cart2 mr-2"></i> Comprar</span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default UploadFiles;