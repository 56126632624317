import React, { useState, useEffect, useRef, useCallback } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import {
    Paper, DialogContent, Backdrop, CircularProgress, FormControl, InputLabel, Select,
    MenuItem, TableContainer, Tooltip
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import * as ALLIcons from '@material-ui/icons';
/***************
 * conexion db
 */
import api from '../../../app/Config/api';
import apiaudit from '../../../app/Config/audit-data';
/**************
 * NOTIFICATIONS
 */
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

/****************
 * view modal federales
*/
import ModalXML from '../documents/listfilesxmls';
import ModalFiles from '../documents/listdocuments';
import ModalEfirma from '../auditor_/detalles/screen-import-efirma';

/********************
 * 
 */
import Skeleton from '@material-ui/lab/Skeleton';
import { PacmanLoader, ClimbingBoxLoader } from "react-spinners";


import './style.css';
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#C70C0C",
        borderColor: "#C70C0C",
        color: '#fff',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    }, arrow: {
        color: "#C70C0C"
    },
}))(Tooltip);
const LightTooltipGood = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#666",
        borderColor: "#666",
        color: '#fff',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    }, arrow: {
        color: "#666"
    },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
        color: "white !important"
    }, paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperbutton: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        WebkitTapHighlightColor: 'transparent',
    },
    "MuiTableCell-root": {
        padding: "5px"
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
var ejercicio_array = []
function getcreateanios(ejercicio) {
    ejercicio_array = [];
    var anio = new Date();
    for (let index = (ejercicio === null ? 2017 : ejercicio); index <= anio.getFullYear(); index++) {
        ejercicio_array.push({ value: index })
    }
}
export default function FullScreenDialog(contenido) {
    let ejercicioconsult = (new Date()).getFullYear();
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [statuspac, SetPac] = useState(true);
    const [data, setData] = useState(contenido.value.datos);
    const { id, ejercicio, rfc, nombre, cliente_id, empresa_id } = data;
    const [documentType, setDocumentType] = useState('');
    const [block, setOpenScreen] = useState(false);
    const [value, setAge] = useState(ejercicioconsult);
    const [type, setTypeFiles] = useState('');
    const [resultinfodocuments, setDataDocuments] = useState('');
    const [fileXML, setDataXML] = useState('');
    const [estatusperiodo, setChangePeriodo] = useState(false);
    const [nombreperiodo, setChangePeriodoName] = useState("01");
    const [estatuspolicescfdis, setChangeEstatusPolices] = useState(0);
    const [messageall, setOpenMessage] = useState({
        success: false,
        vertical: 'top',
        horizontal: 'center',
        error: false,
        message: ''
    });
    const { vertical, horizontal, success, error, message } = messageall;
    const [datafile, setFile] = useState({ file: '', namefile: 'Seleccione el archivo', open: false });
    /***********************
     * INFORMACIÓN PARA MOSTRAR LOS DATOS EN MODAL
     */
    const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    const sorter = (a, b) => {
        if (a.ejercicio !== b.ejercicio) {
            return a.ejercicio - b.ejercicio;
        } else {
            return months.indexOf(a.periodo) - months.indexOf(b.periodo);
        };
    };
    const [containerpdf, setContainerPDF] = useState('');
    async function getData(id, ejercicio) {
        var info = {
            id: id,
            ejercicio: ejercicio
        }

        const response = await api.post('listDetailsDocumentsSystema', info)
        const resultdata = await response.data;
        console.log(resultdata);
        setDataDocuments(resultdata)

    }
    async function validate_cfdis_polices(empresa_id) {
        const response = await apiaudit.post('list-validate-polices-invoce', { empresa_id: id })
        let resultdata = await response.data;
        console.log(resultdata)
        if (resultdata.status === 201) {
            setOpenMessage({
                success: false,
                vertical: 'top',
                horizontal: 'right',
                error: true,
                message: resultdata.message
            });
        }
    }
    useEffect(() => {
        ///setOpenScreen(false);
        SetPac(true);
        var container_end = contenido.value.datos;
        setAge((container_end.ejercicio === null ? ejercicioconsult : container_end.ejercicio));
        setData(contenido.value.datos);
        getData(container_end.id, container_end.ejercicio);
        setTimeout(() => {
            getcreateanios(container_end.ejercicio);
            setOpen(true);
        }, 200);
        setTimeout(() => {
            ///setOpenScreen(false);
            validate_cfdis_polices(empresa_id);
            SetPac(false);
        }, 1000);

    }, [contenido.value.datos]);

    const handleClickOpen = () => {
        setOpen(true);
    };
    function cerrarModal(event) {
        event.preventDefault();
        setOpen(false);
        event.stopPropagation();
        contenido.value.clear('');
    }

    const handleChangeselect = (event) => {
        setAge(event.target.value);
        getData(id, event.target.value);
    };
    const handleChangeselectPeriod = (event) => {
        setChangePeriodoName(event.target.value);
    };

    /************
     * COMPONENTES PARA LA CARGA DE ARCHIVOS
     */
    const el = useRef();
    const handleChangeselectTypeDocument = (event) => {
        el.current.value = "";
        setTypeFiles(event.target.value)
        var extension = event.target.value;
        var urldinamyc = ((extension === "001" || extension === "002") ? ".zip,.xml" :
            (extension === "003" || extension === "004" || extension === "005") ? ".zip,.xml" :
                (extension === "006") ? ".pdf" : (extension === "007") ? ".pdf" : (extension === "000") ? ".pdf" :
                    (extension === "008" || type === "009") ? ".pdf" : (extension === "010") ? ".pdf" :
                        (extension === "011") ? ".pdf" : (extension === "012") ? ".pdf" : '');
        if (extension === "010")
            setChangePeriodo(true)
        else
            setChangePeriodo(false)

        setFile({ file: '', namefile: 'Seleccione el archivo', open: false });
        setDocumentType(urldinamyc);
    }
    const handleChangeFile = (e) => {
        if (type !== '') {
            setOpenScreen(true);

            const file = e.target.files[0];
            setFile({ open: false });
            const formData = new FormData();
            let files_ = e.target.files;
            if (files_.length > 1) {
                setFile({ namefile: (files_.length + " archivos seleccionados"), open: false });
                for (let g = 0; g < files_.length; g++) {
                    formData.append('file', files_[g])
                }
            } else {
                setFile({ namefile: file.name });
                formData.append('file', file)
            }

            formData.append('empresa_id', id)
            formData.append('rfc', rfc)
            formData.append('tipodocument', type)
            formData.append('ejercicio', value)
            formData.append('cliente_id', cliente_id)
            formData.append('ejercicioinicio', ejercicio)
            formData.append('periodo', nombreperiodo)
            formData.append('identificador', empresa_id)
            var urldinamyc = ((type === "001" || type === "002") ? "/cfdiscontabilidad" :
                (type === "003" || type === "004" || type === "005") ? "/contablidad-array" :
                    (type === "006") ? "/federales" : (type === "009") ? "/informativas" :
                        (type === "000") ? "/constancia" : (type === "008") ? "/imssinfonavit" :
                            (type === "010") ? "/estatales" : (type === "011") ? "/acuses-aceptacion-contabilidad" :
                                (type === "012") ? "/ejerciciosanteriores" : '');

            api.post(urldinamyc, formData).then(res => {
                console.log(res.data)
                var containers = res.data;

                if (containers.status === 200) {
                    console.log(containers.data)
                    setOpenMessage({
                        success: true,
                        vertical: 'top',
                        horizontal: 'right',
                        error: false,
                        message: "Se ingresaron correctamente los archivos"
                    });

                    el.current.value = "";
                    setFile({ file: '', namefile: 'Seleccione el archivo', open: false });
                    getData(id, value);
                    let data_info_ = containers.data;
                    let _list_blz_ = [];
                    let _list_accounts__ = [];
                    let _list_polices_ = [];
                    if ((type === "003" || type === "004" || type === "005")) {
                        for (let x = 0; x < data_info_.length; x++) {
                            if (data_info_[x].type === "POL") {
                                _list_polices_.push({
                                    ejercicio: data_info_[x].ejercicio,
                                    periodo: data_info_[x].mes,
                                    empresa_id: id,
                                    id: empresa_id,
                                });
                            }
                            if (data_info_[x].type === "BLZ") {
                                _list_blz_.push({
                                    ejercicio: data_info_[x].ejercicio,
                                    periodo: data_info_[x].mes,
                                    empresa_id: id,
                                    id: empresa_id,
                                });
                            }
                            if (data_info_[x].type === "CC") {
                                _list_accounts__.push({
                                    ejercicio: data_info_[x].ejercicio,
                                    periodo: data_info_[x].mes,
                                    empresa_id: empresa_id,
                                    id: id,
                                })
                            }
                        }
                        setTimeout(() => {
                            if (_list_accounts__.length > 0) {
                                validate_accounts_polices_all(_list_accounts__);
                            }
                        }, 500);
                        setTimeout(async () => {
                            if (_list_blz_.length) {
                                genera_blz_of_xml_import(_list_blz_, (_list_polices_.sort(sorter)));
                            }
                            else {
                                if (_list_polices_.length > 0) {
                                    _generate_balance_to_xml_polices((_list_polices_.sort(sorter)));
                                }
                            }
                        }, 500);
                    }

                    setOpenScreen(false);
                }
                else if (containers.status === 404) {
                    setOpenScreen(false);
                    setOpenMessage({
                        success: false,
                        vertical: 'top',
                        horizontal: 'right',
                        error: true,
                        message: containers.message
                    });
                    el.current.value = "";
                    setFile({ file: '', namefile: 'Seleccione el archivo', open: false });
                }
            }).catch(err => {
                console.log(err)
                setOpenScreen(false);
                setOpenMessage({
                    success: false,
                    vertical: 'top',
                    horizontal: 'right',
                    error: true,
                    message: err
                })
            });
        }
        else {
            setOpenMessage({
                success: false,
                vertical: 'top',
                horizontal: 'right',
                error: true,
                message: 'Debe de seleccionar el módulo al que pertenece el documento!.'
            })
        }
    }
    const validate_accounts_polices_all = async (j) => {
        let data_ = await apiaudit.post("validate-accounts-polices-all", j[0]);
        let _dresult_ = await data_.data;
        if (_dresult_.status == 201) {
            setOpenMessage({
                success: false,
                vertical: 'top',
                horizontal: 'right',
                error: true,
                message: _dresult_.message
            });
        }
    };
    const genera_blz_of_xml_import = async (j, _list_polices_) => {
        let data_ = await apiaudit.post("genera-blz-of-xml-import", j[0]);
        let _dresult_ = await data_.data;
        if (_dresult_.error == 201) {
            setOpenMessage({
                success: false,
                vertical: 'top',
                horizontal: 'right',
                error: true,
                message: _dresult_.message
            });
        }
        if (_list_polices_.length > 0) {
            _generate_balance_to_xml_polices(_list_polices_);
        }
    }
    const _generate_balance_to_xml_polices = async (j) => {
        if (j.length) {
            let _contador_xx = 0;
            for (let hg = 0; hg < j.length; hg++) {
                let data_ = await apiaudit.post("generate-balance-to-xml-polices", j[hg]);
                let _dresult_ = await data_.data;

                if (_dresult_.error == 201) {
                    setOpenMessage({
                        success: false,
                        vertical: 'top',
                        horizontal: 'right',
                        error: true,
                        message: _dresult_.message
                    });
                }
                _contador_xx++;
            }
            const _xx_dr_ = setInterval(async () => {
                if (j.length === _contador_xx) {
                    clearInterval(_xx_dr_);
                    for (let sw = 2017; sw <= (new Date().getFullYear()); sw++) {
                        let data_ = await apiaudit.post("list-details-view-blz-contribucion", { ejercicio: sw, empresa_id: id });
                        let _dresult_ = await data_.data;
                        console.log('ok ya se ingreso la información de comprativo', _dresult_)
                    }

                }
            }, 400);

        }
    };

    const handleCloseMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenMessage({
            success: false,
            vertical: 'top',
            horizontal: 'right',
            error: false,
            message: ''
        });
    };
    /****************************************
     * VER INFORMACIÓN DE CADA TIPO
     */
    const ViewFilesImpuestosFederales = (e) => {
        var contenidoObject = JSON.parse(e.split('&')[0]);
        contenidoObject.type = e.split('&')[1];
        setContainerPDF(<ModalFiles value={contenidoObject} />);
    }
    const ViewFilesXMLS = (i) => {
        i.rfc = data.rfc;
        setDataXML(<ModalXML value={i} />);
    }
    return (
        <div>
            <Button style={{ display: "none" }} id="btnmodal" variant="outlined" color="primary" onClick={handleClickOpen}>Open full-screen dialog</Button>
            <Dialog fullScreen open={open} onClose={cerrarModal} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>{nombre}</Typography>

                        <IconButton edge="start" color="inherit" onClick={cerrarModal} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    {/*<Backdrop className={classes.backdrop} open={block}>
                        <CircularProgress color="inherit" />
                    </Backdrop>*/}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <Snackbar open={success} autoHideDuration={6000} onClose={handleCloseMessage} anchorOrigin={{ vertical, horizontal }}>
                                        <Alert onClose={handleCloseMessage} severity="success">
                                            {message}
                                        </Alert>
                                    </Snackbar>
                                    <Snackbar open={error} autoHideDuration={6000} onClose={handleCloseMessage} anchorOrigin={{ vertical, horizontal }}>
                                        <Alert onClose={handleCloseMessage} severity="error">
                                            {message}
                                        </Alert>
                                    </Snackbar>
                                    <div className="row">
                                        <div className="col-lg-1">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel id="demo-simple-select-label">Ejercicio</InputLabel>
                                                <Select style={{ width: 100 }} labelId="demo-simple-select-label" id="demo-simple-select" value={value} onChange={handleChangeselect} >
                                                    {ejercicio_array.map((e, key) => {
                                                        return <MenuItem key={key} value={e.value}>{e.value}</MenuItem>;
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel id="demo-simple-select-label">Documentos</InputLabel>
                                                <Select style={{ width: 300 }} labelId="demo-simple-select-label"
                                                    id="demo-simple-select" value={type}
                                                    onChange={handleChangeselectTypeDocument}>
                                                    <MenuItem value="000">Constancia</MenuItem>
                                                    <MenuItem value="001">CFDI's Emitidos - Recibidos</MenuItem>
                                                    <MenuItem value="003">Contabilidad electrónica&nbsp; <i style={{ fontSize: '14px' }}>"Pólizas, balanza, catálogo de cuentas"</i> </MenuItem>
                                                    <MenuItem value="006">Impuestos Federales</MenuItem>
                                                    <MenuItem value="008">Imss/Infonavit</MenuItem>
                                                    <MenuItem value="009">Informativas</MenuItem>
                                                    <MenuItem value="010">Impuestos Estatales</MenuItem>
                                                    <MenuItem value="011">Acuses de la contabilidad&nbsp; <i style={{ fontSize: '14px' }}>"Acuse del catálogo, Acuse de balanza"</i> </MenuItem>
                                                    <MenuItem value="012">Impuestos de ejercicios anteriores</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="col-lg-2">
                                            {
                                                estatusperiodo ? (
                                                    <FormControl className={classes.formControl}>
                                                        <InputLabel id="demo-simple-select-label">Periodo</InputLabel>
                                                        <Select style={{ width: 150 }} labelId="demo-simple-select-label" id="demo-simple-select" value={nombreperiodo} onChange={handleChangeselectPeriod} >

                                                            <MenuItem value="01">Enero</MenuItem>
                                                            <MenuItem value="02">Febrero</MenuItem>
                                                            <MenuItem value="03">Marzo</MenuItem>
                                                            <MenuItem value="04">Abril</MenuItem>
                                                            <MenuItem value="05">Mayo</MenuItem>
                                                            <MenuItem value="06">Junio</MenuItem>
                                                            <MenuItem value="07">Julio</MenuItem>
                                                            <MenuItem value="08">Agosto</MenuItem>
                                                            <MenuItem value="09">Septiembre</MenuItem>
                                                            <MenuItem value="10">Octubre</MenuItem>
                                                            <MenuItem value="11">Noviembre</MenuItem>
                                                            <MenuItem value="12">Diciembre</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                ) : null}
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="uniform-uploader">
                                                <input type="file" ref={el} className="form-control-uniform" multiple data-fouc="" onChange={handleChangeFile} accept={documentType} />
                                                <span className="filename" style={{ userSelect: "none" }}>{datafile.namefile} </span>
                                                <span className="action btn bg-warning-400" style={{ userSelect: "none" }}>Seleccione archivo</span>
                                            </div>
                                        </div>
                                        <div className="col-lg-2 text-right">
                                            <Tooltip title="Hola">
                                                <ModalEfirma value={data} />
                                            </Tooltip>
                                            &nbsp;&nbsp;
                                            <Tooltip title={
                                                <div className="card bg-warning-400">
                                                    <div className="card-header">
                                                        <p style={{ fontSize: 12 }}>Importación de archivos</p>
                                                    </div>
                                                    <div className="card-body">
                                                        <ul>
                                                            <li>Paso 3.1 Seleccionas el ejercicio que se vaya a importar.</li>
                                                            <li>Paso 3.2 Selecciona el tipo de documentos a importa ("Impuestos federales","Contabilidad",etc..)</li>
                                                            <li>Paso 3.3 Selecciona el archivo a importar de acuerdo, al tipo de documento seleccionado.</li>
                                                        </ul>
                                                        <p style={{ fontSize: 11 }}>Opcional la importación de la e-firma</p>
                                                        <p style={{ fontSize: 11 }}>Si importa la efirma, en automático se descarga los cfdis apartir del ejercicio de inicio</p>
                                                    </div>

                                                </div>
                                            } arrow placement="bottom"><i className="icon-help mr-3 text-blue-400"></i></Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            {containerpdf}
                                            {fileXML}
                                            {(statuspac) ?
                                                (
                                                    <div>
                                                        <Skeleton variant="text" />
                                                        <Skeleton variant="text" />
                                                        <div style={{
                                                            margin: 40,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                        }}>
                                                            <PacmanLoader color={"#ff7043"} loading={statuspac} size={25} margin={2} />
                                                        </div>
                                                        <Skeleton variant="rect" animation="wave" width={"100%"} height={15} />
                                                        <Skeleton variant="rect" animation="wave" width={"100%"} height={15} />
                                                        <Skeleton variant="rect" animation="wave" width={"100%"} height={15} />
                                                    </div>
                                                ) : (
                                                    <>
                                                        {(block) ? (
                                                            <div>
                                                                <Skeleton variant="text" />
                                                                <Skeleton variant="text" />
                                                                <div style={{
                                                                    margin: 40,
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                }}>
                                                                    <ClimbingBoxLoader color={"#ff7043"} loading={block} size={25} margin={2} />
                                                                </div>
                                                                <Skeleton variant="rect" animation="wave" width={"100%"} height={20} />
                                                                <Skeleton variant="rect" animation="wave" width={"100%"} height={20} />
                                                                <Skeleton variant="rect" animation="wave" width={"100%"} height={20} />
                                                            </div>
                                                        ) : (<div component={Paper}>
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th align="center">Mes</th>
                                                                        <th align="center">CFDI's Emitidos</th>
                                                                        <th align="center">CFDI's Recibidos</th>
                                                                        <th align="center">Catálogo de cuentas</th>
                                                                        <th align="center">Acuse Catálogo</th>
                                                                        <th align="center">Pólizas</th>
                                                                        <th align="center">Balanza</th>
                                                                        <th align="center">Acuse Balanza</th>
                                                                        <th align="center">Impuestos Federales</th>
                                                                        <th align="center">IMSS/INFONAVIT</th>
                                                                        <th align="center">Informativas</th>
                                                                        <th align="center">Impuestos Estatales</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {(resultinfodocuments || []).map((row) => (
                                                                        <tr key={row.mes_descripcion}>
                                                                            <td component="th" scope="row"> {row.mes_descripcion}</td>
                                                                            <td align="center">{(row.cfdis_emitidos > 0 ? <LightTooltipGood title="Click para ver el documento importado" placement="top" arrow><img src="https://e-xpertum.com.mx/images/icons/d_xml_v.png" alt="emitidos"></img></LightTooltipGood> : <LightTooltip title="No se ha realizado la importación" placement="top" arrow><ALLIcons.Close style={{ color: "red" }} /></LightTooltip>)}</td>
                                                                            <td align="center">{(row.cfdis_recibidos > 0 ? <LightTooltipGood title="Click para ver el documento importado" placement="top" arrow><img src="https://e-xpertum.com.mx/images/icons/d_xml_v.png" alt="recibidos"></img></LightTooltipGood> : <LightTooltip title="No se ha realizado la importación" placement="top" arrow><ALLIcons.Close style={{ color: "red" }} /></LightTooltip>)}</td>
                                                                            <td align="center">{(row.cuentas_contables > 0 ? <LightTooltipGood title="Click para ver el documento importado" placement="top" arrow><img style={{ cursor: "pointer" }} src="https://e-xpertum.com.mx/images/icons/d_xml_v.png" onClick={() => ViewFilesXMLS(row)} alt="catalogos"></img></LightTooltipGood> : <LightTooltip title="No se ha realizado la importación" placement="top" arrow><ALLIcons.RemoveCircle style={{ color: "gray" }} /></LightTooltip>)}</td>
                                                                            <td align="center">{(row.acuse_catalogo > 0 ? <LightTooltipGood title="Click para ver el documento importado" placement="top" arrow><img style={{ cursor: "pointer" }} src="https://e-xpertum.com.mx/images/icons/d_pdf_v.png" onClick={() => ViewFilesImpuestosFederales(JSON.stringify(row) + '&011')} alt="pdf catalogos"></img></LightTooltipGood> : <LightTooltip title="No se ha realizado la importación" placement="top" arrow><ALLIcons.RemoveCircle style={{ color: "gray" }} /></LightTooltip>)}</td>
                                                                            <td align="center">{(row.poliza > 0 ? <LightTooltipGood title="Click para ver el documento importado" placement="top" arrow><img style={{ cursor: "pointer" }} src="https://e-xpertum.com.mx/images/icons/d_xml_v.png" onClick={() => ViewFilesXMLS(row)} alt="xml polizas"></img></LightTooltipGood> : <LightTooltip title="No se ha realizado la importación" placement="top" arrow><ALLIcons.Close style={{ color: "red" }} /></LightTooltip>)}</td>
                                                                            <td align="center">{(row.balanza_comprobacion > 0 ? <LightTooltipGood title="Click para ver el documento importado" placement="top" arrow><img style={{ cursor: "pointer" }} src="https://e-xpertum.com.mx/images/icons/d_xml_v.png" onClick={() => ViewFilesXMLS(row)} alt="xml balanza"></img></LightTooltipGood> : <LightTooltip title="No se ha realizado la importación" placement="top" arrow><ALLIcons.Close style={{ color: "red" }} /></LightTooltip>)}</td>
                                                                            <td align="center">{(row.acuse_balanza > 0 ? <LightTooltipGood title="Click para ver el documento importado" placement="top" arrow><img style={{ cursor: "pointer" }} src="https://e-xpertum.com.mx/images/icons/d_pdf_v.png" onClick={() => ViewFilesImpuestosFederales(JSON.stringify(row) + '&011')} alt="pdf balanza"></img></LightTooltipGood> : <LightTooltip title="No se ha realizado la importación" placement="top" arrow><ALLIcons.Close style={{ color: "red" }} /></LightTooltip>)}</td>
                                                                            <td align="center">{(row.impuestos_federales > 0 ? <LightTooltipGood title="Click para ver el documento importado" placement="top" arrow><img style={{ cursor: "pointer" }} src="https://e-xpertum.com.mx/images/icons/d_pdf_v.png" onClick={() => ViewFilesImpuestosFederales(JSON.stringify(row) + '&006')} alt="federales"></img></LightTooltipGood> : <LightTooltip title="No se ha realizado la importación" placement="top" arrow><ALLIcons.Close style={{ color: "red" }} /></LightTooltip>)}</td>
                                                                            <td align="center">{(row.infonavit > 0 ? <LightTooltipGood title="Click para ver el documento importado" placement="top" arrow><img style={{ cursor: "pointer" }} src="https://e-xpertum.com.mx/images/icons/d_pdf_v.png" onClick={() => ViewFilesImpuestosFederales(JSON.stringify(row) + '&008')} alt="imssinfonavit"></img></LightTooltipGood> : <LightTooltip title="No se ha realizado la importación" placement="top" arrow><ALLIcons.Close style={{ color: "red" }} /></LightTooltip>)}</td>
                                                                            <td align="center">{(row.informativas > 0 ? <LightTooltipGood title="Click para ver el documento importado" placement="top" arrow><img style={{ cursor: "pointer" }} src="https://e-xpertum.com.mx/images/icons/d_pdf_v.png" onClick={() => ViewFilesImpuestosFederales(JSON.stringify(row) + '&009')} alt="informativas"></img></LightTooltipGood> : <LightTooltip title="No se ha realizado la importación" placement="top" arrow><ALLIcons.Close style={{ color: "red" }} /></LightTooltip>)}</td>
                                                                            <td align="center">{(row.impuestos_estatales > 0 ? <LightTooltipGood title="Click para ver el documento importado" placement="top" arrow><img style={{ cursor: "pointer" }} src="https://e-xpertum.com.mx/images/icons/d_pdf_v.png" onClick={() => ViewFilesImpuestosFederales(JSON.stringify(row) + '&012')} alt="estatales"></img></LightTooltipGood> : <LightTooltip title="No se ha realizado la importación" placement="top" arrow><ALLIcons.Close style={{ color: "red" }} /></LightTooltip>)}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>)}
                                                    </>

                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}