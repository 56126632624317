import React, { Component } from 'react'
import DatosFiscales from './screen-edit/datos_fiscales-edit';
import Contacto from './screen-edit/contacto-edit';
import Suscripcion from './screen-edit/suscripcion-edit';
class viewedit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewdatos: '',
            viewcontacto: '',
            viewsuscripcion: ''
        };
    }
    componentDidMount() {

        this.setState(this.props)
        setTimeout(() => {
            this.setState({
                viewdatos: <DatosFiscales datofiscal={this.state.datosfiscales} />,
                viewcontacto: <Contacto contacto={this.state.contacto} />,
                viewsuscripcion: <Suscripcion suscripcion={this.state.suscripcion} />,
            })
        }, 200);

    }
    render() {
        return (
            <>
                <div className="card">
                    <div className="card-body">
                        <ul className="nav nav-tabs nav-tabs-top">
                            <li className="nav-item"><a href="#top-tab1" className="nav-link active" data-toggle="tab">Datos fiscales</a></li>
                            <li className="nav-item"><a href="#top-tab2" className="nav-link" data-toggle="tab">Contacto</a></li>
                            <li className="nav-item"><a href="#top-tab3" className="nav-link" data-toggle="tab">Suscripción</a></li>
                        </ul>

                        <div className="tab-content">
                            <div className="tab-pane fade active show" id="top-tab1">
                                {this.state.viewdatos}
                            </div>

                            <div className="tab-pane fade" id="top-tab2">
                                {this.state.viewcontacto}
                            </div>

                            <div className="tab-pane fade" id="top-tab3">
                                {this.state.viewsuscripcion}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default viewedit;