import React from 'react';
import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';


const arrayMonths = ['', 'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);


    return (
        <React.Fragment>
            {
                (row.descripcion !== null) ? (
                    <tr>
                        <td style={{ padding: "5px" }}>
                            <button className='btn btn-default' onClick={() => setOpen(!open)}>
                                {open ? <i className="icon-arrow-up15"></i> : <i className="icon-arrow-down15"></i>}
                            </button>
                        </td>
                        <td>{row.descripcion}</td>
                    </tr>
                ) : null
            }
            {
                (Array.isArray(row.detaiil) && row.detaiil.length > 0) ?
                    <tr>
                        <td colSpan={2} style={{ padding: "5px" }}>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <br></br>
                                <span className='btn btn-link'>
                                    Detalle de las obligaciones atrasadas
                                </span>
                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <th>Obligación</th>
                                            <th>Anio</th>
                                            <th>Mes de causación</th>
                                        </tr>
                                    </thead>
                                    <tbody> {(row.detaiil || []).map((historyRow) => (
                                        <tr key={historyRow.obligacion}>
                                            <td component="th" scope="row">
                                                {historyRow.obligacion}
                                            </td>
                                            <td>{historyRow.exercise}</td>
                                            <td align="right">{arrayMonths[parseInt(historyRow.month)]}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </Collapse>
                        </td>
                    </tr> :
                    null
            }

        </React.Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        detaiil: PropTypes.arrayOf(
            PropTypes.shape({
                exercise: PropTypes.number.isRequired,
                month: PropTypes.string.isRequired,
                obligacion: PropTypes.string.isRequired,
            }),
        ).isRequired,
        descripcion: PropTypes.string
    }).isRequired,
};

let rows;

export default function CollapsibleTable(props) {
    rows = props.data;
    return (
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead className="table-danger" >
                <tr>
                    <th style={{ paddingTop: "12px", paddingBottom: "12px" }}></th>
                    <th style={{ paddingTop: "12px", paddingBottom: "12px" }}>Obligaciones no presentadas</th>
                </tr>
            </thead>
            <tbody>
                {rows.map((row) => (
                    <Row key={row.descripcion} row={row} />
                ))}
            </tbody>
        </table>

    );
}
  /*<TableContainer component={Paper}>
<Table aria-label="collapsible table">
<TableHead>
<TableRow>
<TableCell />
<TableCell>Obligaciones no presentadas</TableCell>
<TableCell align="right">Calories</TableCell>
</TableRow>
</TableHead>
<TableBody>
{rows.map((row) => (
<Row key={row.descripcion} row={row} />
))}
</TableBody>
</Table>
</TableContainer>*/