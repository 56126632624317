import React, { Component } from 'react';
import api from '../../Config/apiconnect';
import Details from './tree/tree';
import ExportFinancial from '../../../v2-buro/details/subdetails/report-financial';
class IncomeStatement extends Component {
    constructor(props) {
        super(props);
        let objects = JSON.parse(localStorage.getItem("datacompanyall"));
        this.state = {
            empresa_id: objects.id,
            data: []
        }
    }
    componentDidMount() {
        this.get_IncomeStatement();
    }
    get_IncomeStatement = async () => {
        let _data = await api.post('sat/income-statement-edo-resultados', { empresa_id: this.state.empresa_id });

        if (_data.data.length > 0) {
            let _data_ = (_data.data).reduce((prev, current) => (prev.id > current.id) ? prev : current);
            let object = JSON.parse(_data_.description);
            this.setState({
                data: object //resultado_end
            });

        }
    }
    handledownloadfiles = () => {
        window.open("https://e-xpertum.com.mx/invoces/api/statement-of-income/" + this.state.empresa_id);
    }

    render() {
        return (
            <>
                <div className="card">
                    <div className="card-header bg-transparent border-bottom header-elements-inline py-0">
                        <h6 className="card-title py-3" style={{ textAlign: 'center' }}>
                            Basado en las declaraciones anuales
                        </h6>
                        <div className="header-elements">
                            <button type="button" className="btn btn-light" onClick={this.handledownloadfiles} ><i className="icon-file-excel mr-2 text-success"></i> Excel</button>
                        </div>
                    </div>
                    <div className="card-body">
                        {(this.state.data.length > 0) ? (
                            <ExportFinancial data={this.state.data} type={"er"} />
                        ) : null}

                    </div>
                </div>
            </>
        );
    }
}

export default IncomeStatement;