import React, { Component } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormControl, Select, InputLabel, MenuItem } from '@material-ui/core';
import Parser from 'html-react-parser';

import api from '../Config/api';

import ViewLeft from './util-contabilidad/viewleft';
import ViewRight from './util-contabilidad/viewright';
class Contabilidad extends Component {
    constructor(props) {
        super(props);
        var data = JSON.parse(localStorage.getItem("_data_info_empresa_"));
        this.state = {
            mensaje: '',
            datalist: [],
            empresa_id: data.empresa_id,
            anio: (new Date()).getFullYear(),
            openscreen: true,
            notificacion: false
        }
        //this.getdatainforhead = this.getdatainforhead.bind();
    }
    getdatainforhead = (e) => {
        this.setState({ openscreen: true, anio: e.target.value });
        setTimeout(() => {
            this.getData()
        }, 200);

    }
    componentDidMount() {
        this.getData();
    }

    async getData() {
        var object = {
            empresa_id: this.state.empresa_id,
            ejercicio: this.state.anio
        }
        const data_info = await api.post('getreportlistcontabilidad', object)
        const { data } = await data_info;
        if (data.length > 0)
            this.setState({ datalist: data, openscreen: false });
        else {
            this.setState({ datalist: data, openscreen: false, notificacion: true });
            setTimeout(() => {
                this.setState({ notificacion: false });
            }, 4000);
        }

    }



    render() {
        var contenidoejercicio = [];
        var ejercicio = (new Date()).getFullYear();
        for (let index = 2019; index <= ejercicio; index++) {
            contenidoejercicio.push({ ejercicio: index })
        }
        return (
            <>
                <Backdrop open={this.state.openscreen} style={{ color: "#fff", background: "#B9B6B6", zIndex: 400, opacity: "0.5" }}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <div className="content-wrapper">
                    <div className="content pt-0">

                        <table className="table">
                            <tbody>
                                <tr>
                                    <td>
                                        <FormControl className="selecthead" style={{ width: 300 }}>
                                            <InputLabel id="demo-simple-select-label">Seleccione el ejercicio</InputLabel>
                                            <Select labelId="demo-simple-select-label" id="demo-simple-select"
                                                value={this.state.anio} onChange={this.getdatainforhead}
                                            >
                                                {(contenidoejercicio || []).map((e, key) => {
                                                    return <MenuItem key={key} value={e.ejercicio}>{e.ejercicio}</MenuItem>;
                                                })}
                                            </Select>
                                        </FormControl>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {
                            this.state.notificacion ? (<div className="alert alert-warning alert-styled-right alert-dismissible">
                                <span className="font-weight-semibold">Notificación!</span> No existe información para mostrar.
                            </div>) : null
                        }

                        <div className="timeline timeline-center">
                            <div className="timeline-container">
                                {
                                    (this.state.datalist || []).map((i, k) => (
                                        <div key={k}>
                                            {
                                                (i.periodo === "Enero" || i.periodo === "Marzo" || i.periodo === "Mayo" || i.periodo === "Julio" || i.periodo === "Septiembre" || i.periodo === "Noviembre") ?
                                                    <ViewLeft fecha={i.ejercicio + '/' + i.periodo} status={i.icon.toLowerCase()} title={(i.ejercicio + ' - ' + i.periodo)} balanza={Parser(i.descripcion_balanza)} informacion={i.informacion} polizas={Parser(i.descripcion_poliza)} /> :
                                                    (i.periodo === "Febrero" || i.periodo === "Abril" || i.periodo === "Junio" || i.periodo === "Agosto" || i.periodo === "Octubre" || i.periodo === "Diciembre") ?
                                                        <ViewRight fecha={i.ejercicio + ' / ' + i.periodo} status={i.icon.toLowerCase()} title={(i.ejercicio + ' - ' + i.periodo)} balanza={Parser(i.descripcion_balanza)} informacion={i.informacion} polizas={Parser(i.descripcion_poliza)} /> : null}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Contabilidad;
