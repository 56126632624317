import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Button, Dialog, IconButton, Paper, Grid, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import * as Icons from '@material-ui/icons';
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";

import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import api from '../../../app/Config/api';
/****
 * url view document
 */
import DocumentTpdf from "./viewfile";
import "../style.css";
import "./style.css";
//import samplePDF from "../LSOConstancia.pdf";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);



export default function CustomizedDialogs(props) {
  //console.log(props.value)
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  //const [fullWidth, setFullWidth] = useState(true);
  //const [maxWidth, setMaxWidth] = useState("xl");
  const [fullWidth] = useState(true);
  const [maxWidth] = useState("xl");
  const [namefile, setNameFile] = useState('');
  /*********
   * CARGA LOS DATOS DE LOS PDFS
   */
  const [infos, setData] = useState([]);
  /************
   * CARGA EL CONTENIDO DE LOS PDFS
   */
  const [container, setContainer] = useState('');
  /***************
   * CARGAR DATOS DEL PROPS
   */
  const [title, setTitle] = useState('');
  const [informaciondata, setDataFind] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  /*****************
   * CARGA DE DATOS
   */
  async function getData(i) {
    const response = await api.post('listDetailsDocumentViewForType', i)
    const data = await response.data;
    console.log(data)
    setData(data);
  }
  useEffect(() => {
    var resultobjects = props.value;
    setTitle('Documentos de ' + resultobjects.ejercicio + ' de ' + resultobjects.mes_descripcion);
    setDataFind(props.value);
    setTimeout(() => {
      setOpen(true);
    }, 300);
    getData(props.value);
  }, [props.value]);

  const getinformacion = (e) => {

    setNameFile(e.nombre_pdf)
    //console.log(JSON.stringify(e))
    setContainer('https://e-xpertum.com.mx/adminfile/api/displaypdf?query=' + JSON.stringify(e))
    window.$("#modallargepdf").modal("show");
    ///setContainer(<DocumentTpdf url={e.directorio}></DocumentTpdf>)
  }

  return (
    <>
      <Button variant="outlined" color="primary" onClick={handleClickOpen} style={{ display: "none" }}>Open dialog</Button>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth={fullWidth} maxWidth={maxWidth}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose} style={{ textAlign: "center" }}><span style={{ color: 'white' }}>{title}</span></DialogTitle>
        <DialogContent dividers>
          <div className="content-wrapper" style={{ color: 'white' }}>
            <div className="content pt-0">
              <div className="row">

                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header header-elements-inline">
                      <h5 className="card-title">Documentos</h5>
                    </div>

                    <div className="card-body">

                      <table className="table">
                        <thead>
                          <tr>
                            <th>Nombre</th>
                            <th className="text-center">Tipo documento</th>
                            <th className="text-center">Tipo presentación</th>
                            <th className="text-center">Acción</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            (infos || []).map((i, j) => (
                              <tr key={j}>
                                <td>{i.nombre_pdf}</td>
                                <td><span className="text-muted">{(i.tipo === "001" ? "FORMULARIO" : i.tipo === "002" ? "PRESENTACIÓN" : i.tipo === "003" ? "PAGO" : i.tipo === "004" ? "ACUSE ANUAL" : i.tipo === "005" ? "PAGO ANUAL" : i.tipo === "006" ? "ACUSE ANUAL" : i.tipo === "007" ? "FORMULARIO ANUAL" : "")}</span></td>
                                <td><span className="text-muted">{i.t}</span></td>
                                <td className="text-center"><a className="list-icons-item" style={{ cursor: 'pointer' }} data-popup="tooltip" title="" data-toggle="modal" data-trigger="hover" data-target="#call" data-original-title="Call" onClick={() => getinformacion(i)}><i className="icon-file-pdf text-warning-300 top-0"></i></a></td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                      {/*      <ul className="media-list"><li className="media font-weight-semibold py-1">Listado de archivos</li>
                          (infos || []).map((i, j) => (
                            <li className="media" key={j}>
                              <div className="mr-3">
                                <span className="btn bg-brown-400 rounded-circle btn-icon btn-sm">
                                  <span className="letter-icon">{i.nombre_pdf.substring(0, 1)}</span>
                                </span>
                              </div>

                              <div className="media-body">
                                <div className="media-title font-weight-semibold">{i.nombre_pdf} </div>
                                <span className="text-muted">{(i.tipo === "001" ? "FORMULARIO" : i.tipo === "002" ? "PRESENTACIÓN" : i.tipo === "003" ? "PAGO" : i.tipo === "004" ? "ACUSE ANUAL" : i.tipo === "005" ? "PAGO ANUAL" : i.tipo === "006" ? "ACUSE ANUAL" : i.tipo === "007" ? "FORMULARIO ANUAL" : "")}</span>
                              </div>

                              <div className="align-self-center ml-3">
                                <div className="list-icons list-icons-extended">
                                  <a className="list-icons-item" style={{ cursor: 'pointer' }} data-popup="tooltip" title="" data-toggle="modal" data-trigger="hover" data-target="#call" data-original-title="Call" onClick={() => getinformacion(i)}><i className="icon-file-pdf text-warning-300 top-0"></i></a>
                                </div>
                              </div>
                            </li>
                          ))</ul>
                       */}

                    </div>
                  </div>

                </div>

                <div id="modallargepdf" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                  <div className="modal-dialog modal-full">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">{namefile}</h5>
                        <button type="button" className="close" data-dismiss="modal">×</button>
                      </div>
                      <div className="modal-body">
                        <div className="embed-container">
                          <iframe src={container}
                            width="100%"
                            frameborder="0" allowfullscreen
                          >
                          </iframe>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                {/*<div className="col-md-5">
                  <div className="card">
                    <div className="card-header header-elements-inline">
                      <h5 className="card-title">Preview archivos</h5>

                    </div>

                    <div className="card-body">
                      {container}
                    </div>
                  </div>

                </div>*/}
              </div>

            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )

}
