import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

import { IconButton, Typography, Button, Dialog, Grid, Tooltip, DialogContentText } from '@material-ui/core';


import * as Icons from '@material-ui/icons';
//import DateFnsUtils from '@date-io/date-fns';
//import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";

import api from '../../../../app/Config/api';
import sencod from '../../../../app/Config/apisecond';
import './style.css';
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6" style={{ textAlign: 'center' }}>{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <Icons.Close />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


class ImportEfirma extends Component {
    constructor(props) {
        super(props);
        var object = props.value;
        this.state = {
            filercer: '',
            filekey: '',
            empresa_id: object.id,
            servicio_id: 1,
            namecer: 'Certificado (.cer)',
            namekey: 'Clave privada (.key)',
            amount: '',
            password: '',
            weight: '',
            weightRange: '',
            showPassword: false,
            vigencia: new Date(),
            directorycer: '',
            directorykey: '',
            block: false,
            alert: '',
            show: false,
            rfc: object.rfc,
            data: [],
            delete: false,
            delete_id: 0,
            open: false
        }
        this.handleSave = this.handleSave.bind();
    }
    handleClickOpen = () => {
        this.setState({ open: true });
    }
    handleClose = () => {
        this.setState({ open: false });
    };
    handleChangeFile = (e) => {
        const file = e.target.files[0];
        this.setState({ namecer: file.name, filercer: file });
        const formData = new FormData();
        formData.append('file', file)
        formData.append('rfc', this.state.rfc)
        api.post('subirdocumentsauditor', formData).then(res => {

            this.setState({ directorycer: res.data.path });
        }).catch(err => console.log(err));
        sencod.post('subirarchivo', formData).then(res => {

        }).catch(err => console.log(err));
    }
    handleChangeFilekey = (e) => {
        const file = e.target.files[0];
        this.setState({ namekey: file.name, filekey: file });
        const formData = new FormData();
        formData.append('file', file)
        formData.append('rfc', this.state.rfc)
        api.post('subirdocumentsauditor', formData).then(res => {
            this.setState({ directorykey: res.data.path });
        }).catch(err => console.log(err));
        sencod.post('subirarchivo', formData).then(res => {

        }).catch(err => console.log(err));
    }
    handleDateChange(d) {
        this.setState({ vigencia: d.target.value });
    }
    handlechangepassword = (i) => {

        //console.log(u)
        this.setState({ password: i.target.value });
    }
    componentDidMount() {
        var conte = {
            empresa_id: this.state.empresa_id
        }
        api.post('listconfiguracionefirma', conte).then(res => {
            this.setState({ data: res.data });
        }).catch(err => console.log(err));
    }
    handleSave = (event) => {
        event.preventDefault();
        this.setState({ block: true });
        api.post('InsertFirmaElectronica', this.state).then(res => {
            if (res.data.status === 200) {
                this.setState({ show: true, alert: res.data.description, block: false, filercer: '', filekey: '', namecer: 'Certificado (.cer)', namekey: 'Clave privada (.key)', password: '', vigencia: new Date(), directorycer: '', directorykey: '' });
                this.componentDidMount();
            }
        }).catch(err => console.log(err));
    }
    handleDeleteRegistro = (i) => {

        this.setState({ delete: true, delete_id: i });
    }
    handleConfirmDeleteRegistro = () => {
        var params = { id: this.state.delete_id }

        api.post('deleteefirmaok', params).then(res => {
            this.componentDidMount();
            this.setState({ delete: false });
        }).catch(err => console.log(err));
    }
    handleCloseDeleteRegistro = () => {
        this.setState({ delete: false });
    }
    render() {
        return (
            <>
                <button type="button" className="btn btn-light" data-toggle="modal" data-target="#modal_default" onClick={this.handleClickOpen}>Efirma <i className="icon-play3 ml-2"></i></button>

                <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={this.state.open} fullWidth={true} maxWidth="xl">
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose}><span style={{ color: 'white' }}>Importar e.firma</span></DialogTitle>
                    <DialogContent dividers>

                        <div className="row" style={{ color: "white" }}>
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="row">
                                            <div className="col-md-11">
                                                <div className="header-elements-inline">
                                                    <h5 className="card-title">Registrar e.firma</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-11">
                                                <form onSubmit={this.handleSave}>
                                                    <div className="form-group">
                                                        <div className="uniform-uploader">
                                                            <input type="file" className="form-input-styled" data-fouc="" onChange={this.handleChangeFile} accept=".cer" />
                                                            <span className="filename" style={{ userSelect: "none" }}>{this.state.namecer} </span>
                                                            <span className="action btn bg-warning-400" style={{ userSelect: "none" }}>Selecciona .cer</span>
                                                        </div>
                                                        <span className="form-text text-muted">Acepta formato: .cer</span>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="uniform-uploader">
                                                            <input type="file" className="form-input-styled" data-fouc="" onChange={this.handleChangeFilekey} accept=".key" />
                                                            <span className="filename" style={{ userSelect: "none" }}>{this.state.namekey} </span>
                                                            <span className="action btn bg-warning-400" style={{ userSelect: "none" }}>Selecciona .key</span>
                                                        </div>
                                                        <span className="form-text text-muted">Acepta formato: .key</span>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Contraseña de clave privada:</label>
                                                        <input type='password' id="password" className="form-control" value={this.state.password} autoComplete="off" name="password" onChange={this.handlechangepassword} />
                                                    </div>

                                                    <div className="form-group">
                                                        <label>Fecha de caducidad:</label>
                                                        <input type="date" className="form-control" value={this.state.vigencia} onChange={(vigencia) => this.handleDateChange(vigencia)} />

                                                    </div>
                                                    <div className="text-right">
                                                        <button type="submit" className="btn btn-primary">Enviar <i className="icon-paperplane ml-2"></i></button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-8">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card">
                                            <div className="card-header header-elements-inline">
                                                <h5 className="card-title">Listado de la e.firma</h5>
                                            </div>
                                            <div className="card-body">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>Certificado (.cer)</th>
                                                            <th>Clave privada (.key)</th>
                                                            <th>Contraseña de clave privada</th>
                                                            <th>Fecha de caducidad</th>
                                                            <th>Acción</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody> {
                                                        (this.state.data || []).map(({ id, directorycer, directorykey, fecha_vencimiento, password }, cont) => (
                                                            <tr key={cont}>
                                                                <td style={{ textAlign: 'center' }}>{validacion(directorycer)}</td>
                                                                <td style={{ textAlign: 'center' }}>{validacion(directorykey)}</td>
                                                                <td className="hidetext">{password}</td>
                                                                <td>{fecha_vencimiento}</td>
                                                                <td style={{ textAlign: 'center' }}>
                                                                    <Tooltip title="Eliminar" placement="top">
                                                                        <Icons.Clear style={{ color: "#ef233c", cursor: 'pointer' }} onClick={() => { this.handleDeleteRegistro(id) }} />
                                                                    </Tooltip>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }</tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Dialog open={this.state.delete} onClose={this.handleCloseDeleteRegistro} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                            <DialogTitle id="alert-dialog-title"><span style={{ color: 'white' }}>{"Confirmación de eliminar"}</span> </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    <span style={{ color: 'white' }}>¿Está de acuerdo en eliminar el registro?</span>

                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleCloseDeleteRegistro} color="primary">Cancelar</Button>
                                <Button onClick={this.handleConfirmDeleteRegistro} color="primary" autoFocus>Aceptar</Button>
                            </DialogActions>
                        </Dialog>
                    </DialogContent>
                </Dialog>

            </>
        );
    }
}

export default ImportEfirma;


function validacion(i) {
    if (i !== '')
        return (<Icons.Check style={{ color: '#7ebc89' }} />)
}


/*
export default function CustomizedDialogs(props) {
    const [open, setOpen] = React.useState(false);
    const [nombrecer, setNombreCer] = React.useState('Archivo .cer');
    const [nombrekey, setNombreKey] = React.useState('Archivo .key');
    const [selectedDate, handleDateChange] = useState(new Date());
    const [data, setData] = useState(props.value);
    const [info, setInfo] = useState({
                        vigencia: new Date(),
        directorycer: '',
        directorykey: '',
        password:''
    })
    const handleClickOpen = () => {
                        setOpen(true);
    };
    const handleClose = () => {
                        setOpen(false);
    };
    const
    const handleSave = () => {

                        api.post('InsertFirmaElectronica', this.state).then(res => {
                            if (res.data.status === 200) {

                            }
                        }).catch(err => console.log(err));
    }
    return (
        <div>

                    </div>
    );
}
*/