import React, { Component } from 'react';
import api from 'axios';
import Iframe from 'react-iframe'
import ShadowScrollbars from '../../documents/Scroll';
class ViewIMG extends Component {
    constructor(props) {
        super(props);
        this.state = {
            container: '',
            iFrameHeight: '0px'
        }
    }
    componentDidMount() {
        api('https://e-xpertum.com.mx/adminfile/api/viewimg?query=' + JSON.stringify(this.props.view), {
            method: 'GET',
            responseType: 'blob'
        }).then((res) => {
            let blob = new Blob([res.data], { type: 'image/jpg' });
            let url = window.URL.createObjectURL(blob);
            this.setState({ container: url });
        }).catch((ex) => {
            console.log(ex)
        })

    }
    render() {
        return (
            <>
                <ShadowScrollbars style={{ width: "100%", height: 550 }}>
                    <Iframe url={this.state.container}
                        width="100%"
                        frameBorder={0}
                        scrolling={false}
                        height="550" />
                </ShadowScrollbars>

            </>
        );
    }

}

export default ViewIMG;