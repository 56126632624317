import React, { Component } from 'react'

export default class LoginNew extends Component {
    render() {
        return (
            <div>
                <div className="navbar navbar-expand-lg navbar-light navbar-static">
                    <div className="navbar-brand ml-2 ml-lg-0">
                        <a href="index.html" className="d-inline-block">
                            <img src="logos/logo_expertumb.png" alt="" />
                        </a>
                    </div>
                    <div className="d-flex justify-content-end align-items-center ml-auto">
                        <ul className="navbar-nav flex-row">
                            <li className="nav-item">
                                <a href="#" className="navbar-nav-link">
                                    <i className="icon-lifebuoy" />
                                    <span className="d-none d-lg-inline-block ml-2">Support</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="navbar-nav-link">
                                    <i className="icon-user-plus" />
                                    <span className="d-none d-lg-inline-block ml-2">Register</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="navbar-nav-link">
                                    <i className="icon-user-lock" />
                                    <span className="d-none d-lg-inline-block ml-2">Login</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="page-content">
                    {/* Main content */}
                    <div className="content-wrapper">
                        {/* Inner content */}
                        <div className="content-inner">
                            {/* Content area */}
                            <div className="content d-flex justify-content-center align-items-center">
                                {/* Login form */}
                                <form className="login-form" action="index.html">
                                    <div className="card mb-0">
                                        <div className="card-body">
                                            <div className="text-center mb-3">
                                                <i className="icon-reading icon-2x text-secondary border-secondary-100 border-3 rounded-pill p-3 mb-3 mt-1" />
                                                <h5 className="mb-0">Login to your account</h5>
                                                <span className="d-block text-muted">Enter your credentials below</span>
                                            </div>
                                            <div className="form-group form-group-feedback form-group-feedback-left">
                                                <input type="text" className="form-control" placeholder="Username" />
                                                <div className="form-control-feedback">
                                                    <i className="icon-user text-muted" />
                                                </div>
                                            </div>
                                            <div className="form-group form-group-feedback form-group-feedback-left">
                                                <input type="password" className="form-control" placeholder="Password" />
                                                <div className="form-control-feedback">
                                                    <i className="icon-lock2 text-muted" />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <button type="submit" className="btn btn-primary btn-block">Sign in</button>
                                            </div>
                                            <div className="text-center">
                                                <a href="login_password_recover.html">Forgot password?</a>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                {/* /login form */}
                            </div>
                            {/* /content area */}
                            {/* Footer */}
                            <div className="navbar navbar-expand-lg navbar-light">
                                <div className="text-center d-lg-none w-100">
                                    <button type="button" className="navbar-toggler dropdown-toggle" data-toggle="collapse" data-target="#navbar-footer">
                                        <i className="icon-unfold mr-2"></i>
                                        Pie de página
                                    </button>
                                </div>
                                <div className="navbar-collapse collapse" id="navbar-footer">
                                    <span className="navbar-text"> <span style={{ fontSize: '15px' }}> &copy;</span> {(new Date()).getFullYear()} Mercanetica estrategias S.A. de C.V.</span>
                                    <ul className="navbar-nav ml-lg-auto">

                                        <li className="nav-item"><a href="https://e-xpertum.com.mx/adminfile/preguntas-frecuentes" className="navbar-nav-link" target="_blank"><span className="text-warning-400"><i className="icon-lifebuoy mr-2"></i> Preguntas frecuentes</span></a></li>
                                        <li className="nav-item"><a href="https://e-xpertum.com.mx/adminfile/terminosycondiciones" className="navbar-nav-link" target="_blank"><span className="text-warning-400"><i className="icon-file-text2 mr-2"></i> Términos y condiciones</span></a></li>
                                        <li className="nav-item"><a href="https://e-xpertum.com.mx/adminfile/avisoprivacidad" className="navbar-nav-link" target="_blank"><span className="text-warning-400"><i className="icon-clipboard3 mr-2"></i> Aviso de privacidad</span></a></li>

                                    </ul>
                                </div>
                            </div>
                            {/* /footer */}
                        </div>
                        {/* /inner content */}

                    </div>
                    {/* /main content */}
                </div>
            </div>

        )
    }
}
