import React, { Component } from 'react';
class ViewLeft extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <>
                <div className="timeline-row timeline-row-left">
                    <div className="timeline-icon">
                        <div className={"bg-danger-400"}>
                            <i className="icon-cross3  icon-2x"></i>
                        </div>
                    </div>
                    <div className="timeline-time" style={{ color: 'white' }}>{this.props.titulo}
                        <div className="text-muted"></div>
                    </div>

                    <div className="card border-left-3 border-left-danger rounded-left-0">
                        <div className="card-body">
                            <div className="d-sm-flex align-item-sm-center flex-sm-nowrap">
                                <div>
                                    <h6 className="font-weight-semibold" style={{ color: 'white' }}>{this.props.subtitulo}</h6>
                                    <ul className="list list-unstyled mb-0">
                                        <li>{this.props.details.Infraccion}</li>
                                    </ul>
                                </div>

                                <div className="text-sm-right mb-0 mt-3 mt-sm-0 ml-auto">
                                    <h6 className="font-weight-semibold" > {new Intl.NumberFormat().format(this.props.details.fImporte)} </h6>
                                    <ul className="list list-unstyled mb-0">
                                        <li>{this.props.details.Unidad}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="card-footer d-sm-flex justify-content-sm-between align-items-sm-center border-light">
                            <span style={{ color: 'white' }}>
                                <span className="badge badge-mark border-danger mr-2"></span>
                                Fecha de límite de pago: <span className="font-weight-semibold">{this.props.fechalimite.substr(0, 10)}</span>
                            </span>

                            <ul className="list-inline list-inline-condensed mb-0 mt-2 mt-sm-0">
                                <li className="list-inline-item">
                                    <a className="text-default"><i className="icon-eye8"></i></a>
                                </li>
                                <li className="list-inline-item dropdown">
                                    <a className="text-default" data-toggle="dropdown"><i className="icon-menu7"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </>
        );
    }
}

export default ViewLeft;