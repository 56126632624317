import React, { Component } from 'react'
import ReactDatatable from '@ashvin27/react-datatable';
import Skeleton from '@material-ui/lab/Skeleton';
import api from '../../app/Config/api';
import { Tooltip, } from "@material-ui/core";
import { withRouter } from 'react-router-dom';
export default class VendorsAll extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "nombre",
                text: "Nombre, Denominación o razón social",
                className: "nombre",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <div className="text-letf">
                            {(record.nombre !== "") ?
                                record.nombre.substr(0, 60) : ""}
                        </div>
                    );
                }
            },
            {
                key: "rfc",
                text: "RFC",
                className: "rfc",
                align: "left",
                sortable: true
            }, {
                key: "importe",
                text: "Importes",
                className: "importe",
                sortable: true,
                cell: record => {
                    return (
                        <div className="text-right">
                            <p style={{ fontSize: 14 }}>{(record.importe) === 0 ? '-' : (record.importe).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                        </div>
                    );
                }
            }, {
                key: "status",
                text: "Estatus",
                className: "estatus",
                sortable: true,
                cell: record => {
                    return (
                        <div className="text-center" style={{ textAlign: 'center' }}>
                            {
                                (record.status === "NOCONFIG") ?
                                    (
                                        <div className="mr-3" style={{ textAlign: 'center' }}>
                                            <Tooltip arrow title="No ha ingresado información" placement="top">
                                                <span className="badge badge-warning">No configurado</span>
                                            </Tooltip>
                                        </div>
                                    ) : (
                                        <div className="mr-3" style={{ textAlign: 'center' }}>
                                            <Tooltip arrow title="El proveedor ya ha sido auditado" placement="top">
                                                <span className="badge badge-success">Auditado</span>
                                            </Tooltip>
                                        </div>
                                    )
                            }
                        </div>
                    );
                }
            }
        ];
        this.config = {
            filename: "report_cat_proveedores",
            key_column: 'rfc',
            page_size: 10,
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,
            button: {
                excel: true,
                print: true,
                //extra: true,
            },
            language: {
                length_menu: "Mostrar _MENU_ registros por página",
                filter: "Buscar en registros ...",
                info: "Mostrando _START_ a _END_ de _TOTAL_ entradas",
                pagination: {
                    first: "Primero",
                    previous: "Previa",
                    next: "próximo",
                    last: "Última"
                }
            },
            no_data_text: "No se encontraron registros"
        }
        this.state = {
            data: [],
            nombre: '',
            skeleton: true
        }
    }
    componentDidMount() {
        this.getlist();
    }
    getlist = async () => {
        let data_ = JSON.parse(localStorage.getItem("datavalidate"));
        let data = await api.post("catproveedores", { empresa_id: data_.id });
        this.setState({ data: data.data, nombre: data_.nombre, skeleton: false })
    }
    handleSelectDetails = async (p) => {
        localStorage.setItem("_data_filter_rfc", JSON.stringify(p));
        ///this.props.history.push('/app/catalogs/details-vendors');
    }
    render() {
        return (
            <>
                <div className="content-wrapper">

                    {
                        (this.state.skeleton) ?
                            (
                                <div className="page-content">
                                    <div className="content-wrapper">
                                        <div className="content pt-0">
                                            <br></br>
                                            <br></br>
                                            <div className="card">
                                                <Skeleton animation="wave" variant="text" height={40} />
                                                <Skeleton animation="wave" variant="text" height={40} />
                                                <Skeleton animation="wave" variant="text" height={40} />
                                                <Skeleton animation="wave" variant="text" height={40} />
                                                <Skeleton animation="wave" variant="text" height={40} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) :
                            (
                                <div className="content pt-0">
                                    <div className="card">

                                        <div className="card-body">
                                            <ReactDatatable
                                                config={this.config}
                                                records={this.state.data}
                                                columns={this.columns}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                    }
                </div>
            </>
        );
    }
}
