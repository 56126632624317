import React, { Component } from 'react';
import api from '../../../app/Config/apifiles';
import Tabls from './table';
import TablsList from './TableView';
import Skeleton from '@material-ui/lab/Skeleton';
import Iframe from 'react-iframe';
class statusNomina extends Component {
    constructor(props) {
        super(props);
        var datacompany = JSON.parse(localStorage.getItem("setdatanomina"));
        console.log(datacompany)
        this.state = {
            rfc: datacompany.rfc,
            empresa_id: datacompany.id,
            nombrecompany: datacompany.nombre,
            cliente_id: datacompany.cliente_id,
            cat_trabajador: [],
            excel: [],
            data_filter: [],
            loading: true,
            link: ''
        };
    }
    componentDidMount() {
        this.listcat();
    }
    listcat = async () => {
        //let _admin_list_ = await axios.post('http://localhost:3020/api/getcat-nomina-trabajadores', { empresa_id: this.state.empresa_id });
        let _admin_list_ = await api.post('getcat-nomina-trabajadores',
            { empresa_id: this.state.empresa_id });
        if (_admin_list_.data[0].length > 0) {
            this.setState({ cat_trabajador: JSON.parse((((_admin_list_.data)[0])[0]).data), loading: false });
            //console.log('opop', _admin_list_.data[0])
        }
        //  
    }
    /*******
  * subir archivo excel read 
  */
    handleUploadFileTrabajadores = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('file', file)
        formData.append('rfc', this.state.rfc)
        formData.append('empresa_id', this.state.empresa_id)
        //let uploadfile = await axios.post('http://localhost:3020/api/upload-file-nomina-trabajadores', formData);
        let uploadfile = await api.post('upload-file-nomina-trabajadores', formData);
        let { status, data, messaje } = await uploadfile.data;
        if (status === 200) {
            if (data.length > 0) {
                this.setState({ excel: data });
                window.$("#modaldatatrabajadores").modal("show");
            }
        }
    }
    handleAllFilterData = async (ok) => {
        let data_ = this.state.excel;//(this.state.excel).filter((i) => i.C === ok)
        let _filter_data_ = this.state.data_filter;
        for (let index = 0; index < data_.length; index++) {
            if (data_[index].C === ok && data_[index].estatus === undefined) {
                data_[index].estatus = "Negativo";
                _filter_data_.push(data_[index]);
            }
            else if (data_[index].C === ok && data_[index].estatus === "Negativo") {
                data_[index].estatus = "Positivo";
                _filter_data_.push(data_[index]);
            }
        }
        this.setState({ excel: data_, data_filter: _filter_data_ });
    }
    handleCreateContratos = async () => {
        //let result_generate_ = await axios.post('http://localhost:3020/api/generate-contratos-select-all', { rfc: this.state.rfc, filter: this.state.data_filter, empresa_id: this.state.empresa_id });
        let result_generate_ = await api.post('generate-contratos-select-all',
            {
                rfc: this.state.rfc,
                filter: this.state.data_filter,
                empresa_id: this.state.empresa_id,
                cliente_id: this.state.cliente_id
            });
        window.$("#modaldatatrabajadores").modal("hide");
        this.listcat();
    }
    handle_confima_delete = async (p) => {
        this.setState({ loading: true })
        let result_generate_ = await api.post('delete-trabajador-db',
            //let result_generate_ = await axios.post('http://localhost:3020/api/delete-trabajador-db',
            {
                clave: p,
                empresa_id: this.state.empresa_id
            });
        this.setState({ cat_trabajador: result_generate_.data, loading: false });
    }
    handleViewPDF = (t) => {
        this.setState({ link: t })
        window.$("#modalfullpdf").modal("show");
    }
    render() {
        return (
            <>
                <div className="content-wrapper">
                    <div className="content-inner">
                        <div className="page-header">
                            <div className="page-header-content header-elements-md-inline">
                                <div className="page-title d-flex">
                                    <a href="#/app/ui/sydeyte93ijd248" style={{ color: 'white' }}><h4><i className="icon-arrow-left52 mr-2" /> <span className="font-weight-semibold">Panel de archivos</span></h4></a>
                                    <a href="#" className="header-elements-toggle text-body d-lg-none"><i className="icon-more" /></a>
                                </div>
                                <h6>{this.state.nombrecompany}</h6>
                                <div className="header-elements d-none mb-3 mb-md-0">
                                    <div className="d-flex justify-content-center">
                                        <label className="breadcrumb-elements-item" style={{ visibility: 'hidden' }}>
                                            <a href="#" className="btn btn-link btn-float text-body"><i className="icon-bars-alt" /><span>Exportar</span></a>
                                        </label>
                                        <label className="breadcrumb-elements-item" style={{ marginLeft: '0.25rem' }} htmlFor="_upload_file_contrato">
                                            <div className="uniform-uploader" style={{ cursor: 'pointer' }}>
                                                <a className="btn btn-link btn-float text-body"><i className="icon-calculator" /> <span>Importar</span></a>
                                            </div>
                                        </label>
                                        <label className="breadcrumb-elements-item" style={{ marginLeft: '0.25rem' }} >
                                            <a href="#/app/panel/new" className="btn btn-link btn-float text-body"><i className="icon-calendar5" /> <span>Nuevo</span></a>
                                        </label>

                                        <input type="file" id="_upload_file_contrato" className="form-control-uniform-custom" style={{ display: 'none' }} accept=".xlsx" name="docs" onChange={this.handleUploadFileTrabajadores} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content pt-0">
                            <div className="card">
                                <div className="card-header">
                                    <h6 className="card-title">Listado de trabajadores</h6>
                                </div>
                                <div className="card-body">
                                    {this.state.loading ? (
                                        <div className="card">
                                            <Skeleton animation="wave" variant="text" height={40} />
                                            <Skeleton animation="wave" variant="circle" width={80} height={80} />
                                            <Skeleton animation="wave" variant="text" height={40} />
                                            <div style={{ height: 2 }}></div>
                                            <Skeleton animation="wave" variant="text" height={40} />
                                            <div style={{ height: 2 }}></div>
                                            <Skeleton animation="wave" variant="text" height={40} />
                                            <div style={{ height: 2 }}></div>
                                            <Skeleton animation="wave" variant="text" height={40} />
                                            <div style={{ height: 2 }}></div>
                                            <Skeleton animation="wave" variant="text" height={40} />
                                            <div style={{ height: 2 }}></div>
                                            <Skeleton animation="wave" variant="text" height={40} />
                                        </div>
                                    ) :
                                        (
                                            <TablsList dt={this.state.cat_trabajador} delete={this.handle_confima_delete} modalpdf={this.handleViewPDF}></TablsList>
                                        )
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="modaldatatrabajadores" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                        <div className="modal-dialog modal-full">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="btn btn-primary" onClick={this.handleCreateContratos}><i className="icon-file-pdf mr-3 icon-2x"></i> Generar los contratos</button>
                                    <button type="button" className="close" data-dismiss="modal">×</button>
                                </div>
                                <div className="modal-body">
                                    <Tabls dt={this.state.excel} filterdt={this.handleAllFilterData}></Tabls>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="modalfullpdf" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                        <div className="modal-dialog modal-full">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Visor del contrato</h5>
                                    <button type="button" className="close" data-dismiss="modal" style={{ color: 'white' }} >×</button>
                                </div>
                                <div className="modal-body">
                                    <div className="embed-container">
                                        <Iframe url={this.state.link}
                                            position="absolute"
                                            width="100%"
                                            id="myId"
                                            className="myClassname"
                                            height="100%"
                                            styles={{ height: "25px" }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default statusNomina;