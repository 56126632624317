import React, { Component } from 'react';
import api from '../../Config/apiconnect';
import Skeleton from '@material-ui/lab/Skeleton';
import '../../Reports/details/style.css';
class Constancia extends Component {
    constructor(props) {
        super(props);
        let objects = JSON.parse(localStorage.getItem("datacompanyall"));
        ///console.log(objects)
        this.state = {
            rfc: objects.rfc,
            empresa_id: objects.id,
            constancia: {},
            company: {},
            domicilio: {},
            person: {},
            strees: [],
            economicActivities: [],
            taxRegimes: [],
            obligations: [],
            directorio: '', linkpdf: '',
            loadingfirts: false,
            clave: ''
        }
    }
    componentDidMount() {
        this.getinfodata();
    }
    getinfodata = async () => {

        let _data = await api.post('sat/tax-status', { empresa_id: this.state.empresa_id });

        if (_data.data.length > 0) {
            let _data_ = await _data.data[0];
            this.setState({
                directorio: _data_.directorio,
                constancia: JSON.parse(_data_.description)[0],
                company: JSON.parse(_data_.description)[0].company,
                person: JSON.parse(_data_.description)[0].person,
                domicilio: JSON.parse(_data_.description)[0].address,
                strees: (JSON.parse(_data_.description)[0].address).streetReferences,
                economicActivities: (JSON.parse(_data_.description)[0].economicActivities),
                taxRegimes: (JSON.parse(_data_.description)[0].taxRegimes),
                obligations: (JSON.parse(_data_.description)[0].obligations),
            })
        }
    }
    handleViewPDF = () => {
        this.setState({ loadingfirts: true, linkpdf: 'https://e-xpertum.com.mx/adminfile/api/ecb868faf29054fedf1ac6cbac5fc63bd54bba3f9d85ea1426942775518b90b6?query=' + this.state.directorio })
        window.$("#modallargepdf").modal("show");
    }
    handleLoading = async () => {
        this.setState({
            loadingfirts: false
        })
    }
    render() {
        return (
            <>
                {
                    (this.state.rfc).length == 12 ? (
                        <div className="content pt-0">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card-group-control card-group-control-right">
                                        <div className="card">
                                            <div className="card-header">
                                                <h6 className="card-title">
                                                    <a data-toggle="collapse" className="text-default" href="#collapsible-control-right-group1">Datos de Identificación</a>
                                                    <button type="button" style={{ left: '72%' }} className="btn btn-light" onClick={this.handleViewPDF}><i className="icon-file-pdf mr-2 text-success"></i> PDF</button>
                                                </h6>
                                            </div>

                                            <div id="collapsible-control-right-group1" className="collapse show">
                                                <div className="card-body">
                                                    <table className="table">
                                                        <tbody>
                                                            <tr>
                                                                <td>RFC</td>
                                                                <td>{this.state.constancia.rfc}</td>
                                                                <td>CIF</td>
                                                                <td>{this.state.constancia.cif}</td>
                                                                <td>Estatus en el padrón</td>
                                                                <td>{this.state.constancia.status}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Fecha inicio de operaciones</td>
                                                                <td>{this.state.constancia.startedOperationsAt}</td>
                                                                <td>Fecha de último cambio de estado</td>
                                                                <td>{this.state.constancia.statusUpdatedAt}</td>
                                                                <td>Denominación/Razón Social</td>
                                                                <td>{this.state.company.legalName}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Régimen Capital</td>
                                                                <td>{this.state.company.entityType}</td>
                                                                <td>Nombre Comercial	</td>
                                                                <td colSpan={3}>{(this.state.company.tradeName === null ? '' : this.state.company.tradeName)}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header">
                                                <h6 className="card-title">
                                                    <a className="text-default" data-toggle="collapse" href="#collapsible-control-right-group2" aria-expanded="true">Datos de ubicación</a>
                                                </h6>
                                            </div>
                                            <div id="collapsible-control-right-group2" className="collapse show">
                                                <div className="card-body">
                                                    <table className="table">
                                                        <tbody>
                                                            <tr>
                                                                <td>Código postal</td>
                                                                <td>{this.state.domicilio.postalCode}</td>
                                                                <td>Tipo de Vialidad</td>
                                                                <td>{this.state.domicilio.streetType}</td>
                                                                <td>Nombre de calle</td>
                                                                <td>{this.state.domicilio.streetName}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Entre calles</td>
                                                                <td>
                                                                    {
                                                                        (this.state.strees).map((j, i) => (
                                                                            <div key={i}>{j}</div>
                                                                        ))
                                                                    }
                                                                </td>
                                                                <td>Número Exterior</td>
                                                                <td>{this.state.domicilio.streetNumber}</td>
                                                                <td>Número Interior</td>
                                                                <td>{this.state.domicilio.buildingNumber}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Colonia</td>
                                                                <td>{this.state.domicilio.neighborhood}</td>
                                                                <td>Localidad</td>
                                                                <td>{this.state.domicilio.locality}</td>
                                                                <td>Municipio</td>
                                                                <td>{this.state.domicilio.municipality}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Entidad Federativa</td>
                                                                <td>{this.state.domicilio.state}</td>
                                                                <td>Correo Electrónico</td>
                                                                <td>{this.state.constancia.email}</td>
                                                                <td>Teléfono</td>
                                                                <td>{this.state.constancia.phone}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header">
                                                <h6 className="card-title">
                                                    <a className="text-default" data-toggle="collapse" href="#collapsible-control-right-group3" aria-expanded="true">Actividades económicas</a>
                                                </h6>
                                            </div>
                                            <div id="collapsible-control-right-group3" className="collapse show" style={{}}>
                                                <div className="card-body">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>Orden</th>
                                                                <th>Actividad económica</th>
                                                                <th>Porcentaje</th>
                                                                <th>Fecha de inicio</th>
                                                                <th>Fecha de fin</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                (this.state.economicActivities).map((o, p) => (
                                                                    <tr key={p}>
                                                                        <td>{o.order}</td>
                                                                        <td>{o.name}</td>
                                                                        <td>{o.percentage}</td>
                                                                        <td>{o.startDate}</td>
                                                                        <td>{o.endDate}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header">
                                                <h6 className="card-title">
                                                    <a className="text-default" data-toggle="collapse" href="#collapsible-control-right-group4" aria-expanded="true">Regímenes</a>
                                                </h6>
                                            </div>
                                            <div id="collapsible-control-right-group4" className="collapse show" style={{}}>
                                                <div className="card-body">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>Régimen</th>
                                                                <th>Fecha de inicio</th>
                                                                <th>Fecha de fin</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                (this.state.taxRegimes).map((o, ok) => (
                                                                    <tr key={ok}>
                                                                        <td>{o.name}</td>
                                                                        <td>{o.startDate}</td>
                                                                        <td>{o.endDate}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header">
                                                <h6 className="card-title">
                                                    <a className="text-default" data-toggle="collapse" href="#collapsible-control-right-group5" aria-expanded="true">Obligaciones</a>
                                                </h6>
                                            </div>
                                            <div id="collapsible-control-right-group5" className="collapse show" style={{}}>
                                                <div className="card-body">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>Descripción de la obligación</th>
                                                                <th>Descripción del vencimiento</th>
                                                                <th>Fecha de inicio</th>
                                                                <th>Fecha de fin</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                (this.state.obligations).map((o, ok) => (
                                                                    <tr key={ok}>
                                                                        <td>{o.description}</td>
                                                                        <td>{o.dueDate}</td>
                                                                        <td>{o.startDate}</td>
                                                                        <td>{o.endDate}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    ) : null
                }
                {
                    (this.state.rfc).length == 13 ? (
                        <div className="content pt-0">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card-group-control card-group-control-right">
                                        <div className="card">
                                            <div className="card-header">
                                                <h6 className="card-title">
                                                    <a data-toggle="collapse" className="text-default" href="#collapsible-control-right-group1">Datos de Identificación</a>
                                                    <button type="button" style={{ left: '72%' }} className="btn btn-light" onClick={this.handleViewPDF}><i className="icon-file-pdf mr-2 text-success"></i> PDF</button>
                                                </h6>
                                            </div>

                                            <div id="collapsible-control-right-group1" className="collapse show">
                                                <div className="card-body">
                                                    <table className="table">
                                                        <tbody>
                                                            <tr>
                                                                <td>RFC</td>
                                                                <td>{this.state.constancia.rfc}</td>
                                                                <td>CIF</td>
                                                                <td>{this.state.constancia.cif}</td>
                                                                <td>Estatus en el padrón</td>
                                                                <td>{this.state.constancia.status}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Fecha inicio de operaciones</td>
                                                                <td>{this.state.constancia.startedOperationsAt}</td>
                                                                <td>Fecha de último cambio de estado</td>
                                                                <td>{this.state.constancia.statusUpdatedAt}</td>
                                                                <td>Denominación/Razón Social</td>
                                                                <td>{this.state.person.fullName}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Nombre(s)</td>
                                                                <td>{this.state.person.firstName}</td>
                                                                <td>Primer Apellido</td>
                                                                <td>{this.state.person.middleName}</td>
                                                                <td>Segundo Apellido</td>
                                                                <td>{this.state.person.lastName}</td>
                                                            </tr>
                                                            <tr>

                                                                <td>Nombre Comercial	</td>
                                                                <td colSpan={5}>{this.state.person.fullName}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header">
                                                <h6 className="card-title">
                                                    <a className="text-default" data-toggle="collapse" href="#collapsible-control-right-group2" aria-expanded="true">Datos de ubicación</a>
                                                </h6>
                                            </div>
                                            <div id="collapsible-control-right-group2" className="collapse show">
                                                <div className="card-body">
                                                    <table className="table">
                                                        <tbody>
                                                            <tr>
                                                                <td>Código postal</td>
                                                                <td>{this.state.domicilio.postalCode}</td>
                                                                <td>Tipo de Vialidad</td>
                                                                <td>{this.state.domicilio.streetType}</td>
                                                                <td>Nombre de calle</td>
                                                                <td>{this.state.domicilio.streetName}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Entre calles</td>
                                                                <td>
                                                                    {
                                                                        (this.state.strees).map((j, i) => (
                                                                            <div key={i}>{j}</div>
                                                                        ))
                                                                    }
                                                                </td>
                                                                <td>Número Exterior</td>
                                                                <td>{this.state.domicilio.streetNumber}</td>
                                                                <td>Número Interior</td>
                                                                <td>{this.state.domicilio.buildingNumber}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Colonia</td>
                                                                <td>{this.state.domicilio.neighborhood}</td>
                                                                <td>Localidad</td>
                                                                <td>{this.state.domicilio.locality}</td>
                                                                <td>Municipio</td>
                                                                <td>{this.state.domicilio.municipality}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Entidad Federativa</td>
                                                                <td>{this.state.domicilio.state}</td>
                                                                <td>Correo Electrónico</td>
                                                                <td>{this.state.constancia.email}</td>
                                                                <td>Teléfono</td>
                                                                <td>{this.state.constancia.phone}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header">
                                                <h6 className="card-title">
                                                    <a className="text-default" data-toggle="collapse" href="#collapsible-control-right-group3" aria-expanded="true">Actividades económicas</a>
                                                </h6>
                                            </div>
                                            <div id="collapsible-control-right-group3" className="collapse show" style={{}}>
                                                <div className="card-body">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>Orden</th>
                                                                <th>Actividad económica</th>
                                                                <th>Porcentaje</th>
                                                                <th>Fecha de inicio</th>
                                                                <th>Fecha de fin</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                (this.state.economicActivities).map((o, p) => (
                                                                    <tr key={p}>
                                                                        <td>{o.order}</td>
                                                                        <td>{o.name}</td>
                                                                        <td>{o.percentage}</td>
                                                                        <td>{o.startDate}</td>
                                                                        <td>{o.endDate}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header">
                                                <h6 className="card-title">
                                                    <a className="text-default" data-toggle="collapse" href="#collapsible-control-right-group4" aria-expanded="true">Regímenes</a>
                                                </h6>
                                            </div>
                                            <div id="collapsible-control-right-group4" className="collapse show" style={{}}>
                                                <div className="card-body">

                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>Régimen</th>
                                                                <th>Fecha de inicio</th>
                                                                <th>Fecha de fin</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                (this.state.taxRegimes).map((o, ok) => (
                                                                    <tr key={ok}>
                                                                        <td>{o.name}</td>
                                                                        <td>{o.startDate}</td>
                                                                        <td>{o.endDate}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header">
                                                <h6 className="card-title">
                                                    <a className="text-default" data-toggle="collapse" href="#collapsible-control-right-group5" aria-expanded="true">Obligaciones</a>
                                                </h6>
                                            </div>
                                            <div id="collapsible-control-right-group5" className="collapse show" style={{}}>
                                                <div className="card-body">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>Descripción de la obligación</th>
                                                                <th>Descripción del vencimiento</th>
                                                                <th>Fecha de inicio</th>
                                                                <th>Fecha de fin</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                (this.state.obligations).map((o, ok) => (
                                                                    <tr key={ok}>
                                                                        <td>{o.description}</td>
                                                                        <td>{o.dueDate}</td>
                                                                        <td>{o.startDate}</td>
                                                                        <td>{o.endDate}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    ) : null
                }

                <div id="modallargepdf" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                    <div className="modal-dialog modal-full">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Constancia de situación fiscal</h5>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>
                            <div className="modal-body">
                                <div className="embed-container">
                                    {
                                        (this.state.loadingfirts) ?
                                            (
                                                <div>
                                                    <Skeleton variant="text" />
                                                    <Skeleton variant="circle" animation="wave" width={40} height={40} />
                                                    <Skeleton variant="rect" animation="wave" width={510} height={118} />
                                                </div>
                                            ) : null
                                    }
                                    <iframe src={this.state.linkpdf} onLoad={this.handleLoading} title="dpf to view"
                                        width="100%"
                                        frameBorder="0" allowFullScreen
                                    >
                                    </iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Constancia;