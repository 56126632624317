import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import api from '../app/Config/audit-data'
class RegisterBuro extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: '',
            name_user: '',
            password: '',
            email: '',
            nombre_completo: '',
            servicio: '',
            proviene_: '',
            estatus: 'ACCEPT',
            errorMail: '',
            successmessage: '',
            passwordmessage: '',
            errormessage: ''
        }
        this.handleSaveDataNew = this.handleSaveDataNew.bind(this);
    }
    handleSaveDataNew = async (e) => {
        e.preventDefault();
        if (this.state.estatus === "ACCEPT") {
            let _save_form_ = await api.post('register-new-user-tmp', this.state);
            let { data, key } = await _save_form_.data;
            if (data == "EXISTS") {
                this.setState({
                    errormessage: 'El siguiente correo ya existe, registrar con otro o recupere su contraseña!!'
                });
                setTimeout(() => {
                    this.setState({
                        errormessage: '',
                        successmessage: ''
                    });
                }, 4000);
            }
            else {
                this.setState({
                    successmessage: 'Se ha enviado un email a su cuenta de correo para acceder!!'
                });
                setTimeout(() => {
                    this.setState({
                        errormessage: '',
                        successmessage: ''
                    });
                    setTimeout(() => {
                        //let d_ = key + "&" + this.state.email;
                        //this.props.history.push("/welcome-new-user/" + d_ + "/compliance")
                    }, 500);
                }, 4000);
            }
            // console.log(_save_form_)
        } else {
            this.setState({
                errormessage: 'Debe de aceptar los términos y condiciones para poder continuar!',
                successmessage: ''
            });
            setTimeout(() => {
                this.setState({
                    errormessage: '',
                    successmessage: ''
                });
            }, 4000);
            console.log('No aceptado!!!!!');
        }
        //

    }
    handleSetParamsForms = async (j) => {
        if ([j.target.name] == "email") {
            this.setState({
                [j.target.name]: j.target.value
            });
            setTimeout(() => {
                this.emailValidation();
            }, 300);
        }
        else {
            this.setState({
                [j.target.name]: j.target.value
            });
        }
        /**/
    }
    handleSetParamsFormsChange = async (j) => {
        if (j.target.value === this.state.password) {
            this.setState({
                [j.target.name]: j.target.value,
                passwordmessage: ''
            });
        }
        else {
            this.setState({
                [j.target.name]: j.target.value,
                passwordmessage: 'La contraseña no coincide'
            });
        }
    }
    handleSetParamsFormsCheck = async (j) => {
        this.setState({
            estatus: j.target.checked ? "ACCEPT" : "NOTACCEPT"
        });
    }
    emailValidation() {
        // eslint-disable-next-line no-useless-escape
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!this.state.email || regex.test(this.state.email) === false) {
            console.log(regex.test(this.state.email))
            this.setState({
                errorMail: "El correo electrónico no es valido"
            });
            return false;
        }
        else
            this.setState({ errorMail: '' });

        return true;
    }
    componentDidMount() {
        this.setState({
            proviene_: this.props.match.params.id
        });
    }
    render() {
        return (
            <div>
                <div className="navbar navbar-expand-lg navbar-light navbar-static">
                    <div className="navbar-brand ml-2 ml-lg-0">
                        <a href="index.html" className="d-inline-block">
                            <img src="logos/logo_expertumb.png" alt="" />
                        </a>
                    </div>
                    <div className="d-flex justify-content-end align-items-center ml-auto">
                        <ul className="navbar-nav flex-row">
                            <li className="nav-item">
                                <a href="/" className="navbar-nav-link">
                                    <i className="icon-lifebuoy" />
                                    <span className="d-none d-lg-inline-block ml-2">Ayuda</span>
                                </a>
                            </li>

                            <li className="nav-item">
                                <a href="/login" className="navbar-nav-link">
                                    <i className="icon-user-lock" />
                                    <span className="d-none d-lg-inline-block ml-2">Login</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="page-content">
                    <div className="content-wrapper">
                        <div className="content-inner">
                            <div className='d-lg-flex align-items-lg-start'>
                                <div className='tab-content flex-1 '>
                                </div>
                                <div className='sidebar sidebar-light bg-transparent sidebar-component sidebar-component-left wmin-600 border-0 shadow-none order-1 order-lg-6 sidebar-expand-lg'>
                                    <div className="content d-flex justify-content-center align-items-center">
                                        <form onSubmit={this.handleSaveDataNew} className="flex-fill" autoComplete={"off"}>
                                            <div className="card mb-0">
                                                <div className="card-body">
                                                    {
                                                        (this.state.errormessage != '') ? (
                                                            <div className="alert alert-danger alert-styled-right alert-dismissible" style={{ position: 'absolute' }}>
                                                                <span className="font-weight-semibold">Notificación!</span> {this.state.errormessage}.
                                                            </div>) : null
                                                    }
                                                    {
                                                        (this.state.successmessage != '') ? (
                                                            <div className="alert alert-success alert-styled-right alert-arrow-right alert-dismissible">
                                                                <span className="font-weight-semibold">Genial!</span> {this.state.successmessage}.
                                                            </div>
                                                        ) : null
                                                    }
                                                    <div className="text-center mb-3">
                                                        <img src='logos/avatarlogo.jpg' style={{ width: 120 }} alt="" className='text-success border-success-100 border-3 rounded-pill  mt-1'></img>
                                                        <h5 className="mb-0">Crear cuenta</h5>
                                                        <span className="d-block text-muted">Todos los campos son obligatorios</span>
                                                    </div>
                                                    <div className="form-group form-group-feedback form-group-feedback-right">
                                                        <input type="text" className="form-control" name='nombre_completo' onChange={this.handleSetParamsForms} placeholder="Nombre completo" required />
                                                        <div className="form-control-feedback">
                                                            <i className="icon-user-plus text-muted" />
                                                        </div>
                                                    </div>
                                                    <div className="form-group form-group-feedback form-group-feedback-right">
                                                        <input type="text" className="form-control" name='email' onChange={this.handleSetParamsForms} placeholder="Correo electrónico" required />
                                                        <span className='text-danger'>{this.state.errorMail}</span>
                                                        <div className="form-control-feedback">
                                                            <i className="icon-mention text-muted" />
                                                        </div>
                                                    </div>
                                                    <div className="form-group form-group-feedback form-group-feedback-right">
                                                        <input type="password" className="form-control" name='password' onChange={this.handleSetParamsForms} placeholder="Contraseña" required />
                                                        <div className="form-control-feedback">
                                                            <i className="icon-user-lock text-muted" />
                                                        </div>
                                                    </div>

                                                    <div className="form-group form-group-feedback form-group-feedback-right">
                                                        <input type="password" className="form-control" name='password_confirm' onChange={this.handleSetParamsFormsChange} placeholder="Confirmar contraseña" required />
                                                        <span className='text-danger'>{this.state.passwordmessage}</span>
                                                        <div className="form-control-feedback">
                                                            <i className="icon-user-lock text-muted" />
                                                        </div>
                                                    </div>
                                                    <div className="form-group form-group-feedback form-group-feedback-right">
                                                        <select className='custom-select' name='servicio' onChange={this.handleSetParamsForms}>
                                                            <option>Seleccione el servicio</option>
                                                            <option value={"14"}>Auditoría de cumplimiento fiscal</option>
                                                            <option value={"50"}>Reporte especial de cumplimiento fiscal.</option>
                                                            <option value={"60"}>Compliance fiscal.</option>
                                                        </select>
                                                        <div className="form-control-feedback">
                                                            <i className="icon-pencil7 text-muted" />
                                                        </div>
                                                    </div>

                                                    <div className="form-group mb-0">
                                                        <label className="custom-control custom-control-success custom-checkbox mb-2">
                                                            <input type="checkbox" className="custom-control-input" defaultChecked onChange={this.handleSetParamsFormsCheck} />
                                                            <span className="custom-control-label">Aceptar
                                                                <a href="https://e-xpertum.com.mx/adminfile/terminosycondiciones" rel='noreferrer' target="_blank">&nbsp;términos y condiciones</a></span>
                                                        </label>
                                                    </div>
                                                    <div className="form-group">

                                                        <span>¿Ya tienes cuenta? <a href='/login' className='text-warning'>Iniciar sesión</a></span>
                                                        <div className="card-footer bg-transparent text-right">
                                                            <button type="submit" className="btn btn-warning btn-labeled btn-labeled-right"><b><i className="mi-send" /></b> Registrarme</button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            {/*<div className='row'>
                                <div className='col-lg-6 col-xl-3'>

                                </div>
                                <div className='col-lg-6 col-xl-3'>
                                    <div className="content d-flex justify-content-center align-items-center">
                                        <form className="flex-fill" autoComplete={false}>
                                            <div className="row">
                                                <div className="col-lg-10 offset-lg-2">
                                                   
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>*/}

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(RegisterBuro);