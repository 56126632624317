import React, { Component } from "react";
import { Table, TableRow, TableHead, TableBody, TableCell, Tooltip } from "@material-ui/core";
import {
    DialogContentText, Paper, DialogContent,
    DialogActions, Dialog, Button, Snackbar, Backdrop, CircularProgress, DialogTitle
} from '@material-ui/core';

import * as Icons from "@material-ui/icons";
import Avatar from '@material-ui/core/Avatar';
import ModalNew from './CRUD/new';
import api from '../Config/api';
import Details from './CRUD/details';
import Edit from './CRUD/edit';


class SistemasFinales extends Component {
    _isMounted = false
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            sistema: [],
            nombre: '',
            editar: false,
            detail: false,
            delete: false,
            loading: false,
        }
        this.actualizalistado = this.actualizalistado.bind(this);
        this.closeModalDialog = this.closeModalDialog.bind(this);
        this.handleConfirmacionDelete = this.handleConfirmacionDelete.bind(this);
        this.setReturnDate = this.setReturnDate.bind(this);

    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({ loading: true });
        this.onLoadUser();
    }
    onLoadUser = async () => {
        try {
            const response = await api.get('listProduct');
            if (this._isMounted) {
                this.setState({ sistema: response.data, loading: false, nombre: '', editar: false, detail: false, delete: false, loading: false });
            }
        } catch (err) {

        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    actualizalistado() {
        this.componentDidMount();
    }
    /***********************************
     * OPERACIONES CRUD
     */
    funcionEditar(id) {
        this.setState({
            id: id.id,
            nombre: id.nombre
        });
        setTimeout(() => {
            this.setState({
                editar: true,
                detail: false,
                delete: false
            });
        }, 500);

    }
    funcionEliminar(id) {
        this.setState({
            id: id.id, nombre: id.nombre, editar: false, detail: false, delete: true
        });
    }
    funcionDetails(id) {
        this.setState({ id: id.id, nombre: id.nombre, editar: false, detail: true, delete: false });
    }
    closeModalDialog() {
        if (this.state.editar)
            this.componentDidMount();

        this.setState({ nombre: '', editar: false, detail: false, delete: false });
    }
    handleConfirmacionDelete() {
        var producto = {
            id: this.state.id
        }
        api.post('DeleteProduct', producto)
            .then((result) => {
                this.componentDidMount();
            },
                (error) => {
                    this.setState({ error });
                }
            );
    }
    setReturnDate(r) {
        console.log(r)
        // this.componentDidMount();
    }
    render() {
        return (
            <>
                <Backdrop style={{ color: "#fff", background: "#B9B6B6", zIndex: 400, opacity: "0.5" }} open={this.state.loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div className="page-header border-bottom-0">
                    <div className="page-header-content header-elements-md-inline">
                        <div className="page-title d-flex">
                            <a href="#" style={{ color: 'white' }}>  <h4><i className="icon-arrow-left52 mr-2"></i> <span className="font-weight-semibold">E-xpertum</span> </h4></a>
                            <a href="#" className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></a>
                        </div>

                    </div>
                </div>
                <div className="content pt-0">
                    <div className="card">
                        <div className="card-header header-elements-inline">
                            <h5 className="card-title">Catálogo de sistemas</h5>
                        </div>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Nombre sistema</th>
                                    <th>Descripción</th>
                                    <th>Versión</th>
                                    <th>Proveedor</th>
                                    <th className="text-center"><ModalNew contenido={this.actualizalistado} /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {(this.state.sistema || []).map(({ id, nombre, descripcion, version, marca, nombre_corto }) => (
                                    <tr key={id}>
                                        <td><Avatar alt={nombre} src={'http://localhost:3005/' + nombre_corto} style={{ width: "60", height: "60" }} /></td>
                                        <td className="pl-3 fw-normal">{nombre}</td>
                                        <td>{descripcion}</td>
                                        <td>{version}</td>
                                        <td>{marca}</td>
                                        <td>
                                            <Tooltip title="Modificar" placement="top"><Icons.Edit style={{ color: "orange", cursor: "pointer" }} onClick={() => this.funcionEditar({ id, nombre })} /></Tooltip> &nbsp;&nbsp;&nbsp;&nbsp;
                                    <Tooltip title="Detalle" placement="top"><Icons.List style={{ color: "green", cursor: "pointer" }} onClick={() => this.funcionDetails({ id, nombre })} /></Tooltip> &nbsp;&nbsp;&nbsp;&nbsp;
                                    <Tooltip title="Eliminar" placement="top"><Icons.DeleteOutline style={{ color: "red", cursor: "pointer" }} onClick={() => this.funcionEliminar({ id, nombre })} /></Tooltip>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <Dialog fullWidth={true} maxWidth={"lg"} open={this.state.editar} onClose={this.handleCloseDetail} aria-labelledby="max-width-dialog-title" >
                            <DialogTitle id="max-width-dialog-title" style={{ textAlign: "center" }}>Modificar {this.state.nombre}</DialogTitle>
                            <DialogContent>
                                <Edit identificador={{ id: this.state.id, url: this.setReturnDate }} />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.closeModalDialog} color="primary"> Cerrar</Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog fullWidth={true} maxWidth={"lg"} open={this.state.detail} onClose={this.handleCloseDetail} aria-labelledby="max-width-dialog-title" >
                            <DialogTitle id="max-width-dialog-title" style={{ textAlign: "center" }}>Detalle {this.state.nombre}</DialogTitle>
                            <DialogContent>
                                <Details identificador={this.state.id} />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.closeModalDialog} color="primary"> Cerrar</Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog open={this.state.delete} onClose={this.handleConfirmacionCancelar} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                            <DialogTitle id="alert-dialog-title">{"Confirmación de eliminación"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Está seguro de eliminar el siguiente registro {this.state.nombre}?
                    </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.closeModalDialog} color="primary">Cancelar</Button>
                                <Button onClick={this.handleConfirmacionDelete} color="primary" autoFocus>Aceptar</Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </div>

            </>);
    }
}


export default SistemasFinales;