
import React, { Component } from 'react'
import api from '../app/Config/audit-data';
import POLZADMIN from './details/screen-view-polices';
import { PacmanLoader } from "react-spinners";
import Skeleton from '@material-ui/lab/Skeleton';
import ErrorsBLZ from './details/screen-error-balance';
import ReportAll from './details/screen-list-reports';
import CBLZALL from './details/conciliacion-balance-contribucion';
const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
const sorter = (a, b) => {
    if (a.ejercicio !== b.ejercicio) {
        return a.ejercicio - b.ejercicio;
    } else {
        return months.indexOf(a.periodo) - months.indexOf(b.periodo);
    };
};
class ReportErros extends Component {
    constructor(props) {
        super(props);
        let pareme = JSON.parse(localStorage.getItem("parameterscompany"));
        this.state = {
            data: [],
            empresa_id: pareme.empresa_id,
            id: pareme.id,
            details: [],
            subdetailsdata: [],
            title: '',
            clave: '',
            polices_cfdis_: '',
            reports: '',
            titlereport: '',
            titleerros: '',
            status: true,
            skeleton: true,
            reportconciliacion: '',
            titlesubtitle: '',
            skeletonheader: false,
            skeletonbody: false,
            namecompany: pareme.nombre,
            titleinit: 'Importación de archivos XML de otras contabilidades',
            stateaccounts: 0,
            statereports: 0,
            urlaccounts: ''
        }
    }
    componentDidMount() {
        this.getdataall();
    }
    getdataall = async () => {
        let data = await api.post("list-errors-contabilidad", { empresa_id: this.state.id });
        this.setState({
            data: (data.data)[0],
            stateaccounts: (data.data)[1][0].cc,
            statereports: (data.data)[2][0].p + (data.data)[2][0].b,
        });
    }
    get_details_ = async (d) => {
        this.setState({ clave: d.clave, skeletonheader: true });
        if (d.clave === "CCC" || d.clave === "CC") {
            let data = await api.post("list-errors-details", { id: d.id });
            let filter_ = await data.data;
            if (filter_.length > 0) {
                this.setState({
                    details: JSON.parse(filter_[0].descripcion),
                    title: d.descripcion,
                    clave: d.clave
                })
                window.$("#modal_full").modal("show");
            }
        }
        if (d.clave === "BLZC") {
            let data = await api.post("list-errors-contribucions", { empresa_id: d.empresa_id });
            let filter_ = await data.data;
            if (filter_.length > 0) {
                this.setState({
                    details: filter_,
                    clave: d.clave,
                    skeletonheader: false,
                    reportconciliacion: <CBLZALL data_={filter_} />
                });
                window.$("#modalviewerrorsconciliacion").modal("show");
            }
        }
        else if (d.clave === "CFDIPOL") {
            this.setState({
                polices_cfdis_: <POLZADMIN />,
                title: d.descripcion,
                clave: d.clave
            });
            window.$("#modal_full").modal("show");
        }
        else if (d.clave === "BLZ") {
            this.setState({
                details: [],
                title: d.descripcion,
                clave: d.clave
            });
            window.$("#modalerrorblz").modal("show");
            let data = await api.post("list-errors-details", { id: d.id });
            let filter_ = await data.data;
            if (filter_.length > 0) {
                if (JSON.parse(filter_[0].descripcion).length > 0) {
                    this.setState({
                        subdetailsdata: JSON.parse(filter_[0].descripcion),
                        details: JSON.parse(filter_[0].descripcion),
                        reports: <ErrorsBLZ dt={JSON.parse(filter_[0].descripcion)} title={this.handleChangeTitleReportOthersErrors} />,
                        titleerros: d.descripcion,
                        clave: d.clave
                    });
                }
            }
        }
        else if (d.clave === "CCA") {
            let data = await api.post("list-errors-details-sf-si", { id: this.state.id, clave: d.clave });
            let filter_ = await data.data;
            this.setState({
                details: filter_[0],
                title: d.descripcion,
                clave: d.clave
            });
            window.$("#modal_full").modal("show");
        }
        else if (d.clave === "BLZSI" || d.clave === "BLZSF" || d.clave === "BLZSF") {
            let data = await api.post("list-errors-details-sf-si", { id: this.state.id, clave: d.clave });
            let filter_ = await data.data;
            this.setState({
                details: filter_,
                title: d.descripcion,
                clave: d.clave
            });
            window.$("#modal_full").modal("show");
        }
        else if (d.clave === "BLZDH") {
            this.setState({
                details: [],
                title: d.descripcion,
                clave: d.clave
            });
            let data = await api.post("list-errors-details-sf-si", { id: this.state.id, clave: d.clave });
            let filter_ = await data.data;
            if (filter_.length > 0) {
                if (filter_.length > 0) {
                    this.setState({
                        details: filter_, ///JSON.parse(filter_[0].descripcion),
                        title: d.descripcion,
                        clave: d.clave
                    });
                    window.$("#modal_full").modal("show");
                }
            }
        }
    }
    handleModalReport = async () => {
        this.setState({
            polices_cfdis_: '',
            title: '',
            clave: '',
            skeleton: false,
            reports: <ReportAll title={this.handleChangeTitleReport} />
        });
        window.$("#modal_full").modal("hide");
        window.$("#modalviewerrorsfull").modal("show");
    }
    handleChangeTitleReport = async (title) => {
        this.setState({
            titlereport: title
        });
    }
    handleChangeTitleReportOthers = async (title) => {
        this.setState({
            title: title
        });
    }
    handleChangeTitleReportOthersErrors = async (title) => {
        this.setState({
            titleerros: title
        });
    }
    handleclearallResult = async () => {
        this.setState({
            polices_cfdis_: '',
            title: '',
            clave: '',
            reportconciliacion: '',
            reports: '',
            titlesubtitle: '',
            skeletonbody: false,
            urlaccounts: ''
        })
    }
    handleModalReporttChangeStatus = async () => {
        this.setState({ reports: '', titlereport: '', skeleton: true, });
        setTimeout(() => {
            this.setState({
                reports: <ReportAll title={this.handleChangeTitleReport} status={this.state.status} />
            });
        }, 800);
        setTimeout(() => {
            this.setState({
                skeleton: false,
            });
        }, 3000);
    }
    handleModalReporttChangeStatusError = async () => {
        this.setState({
            titleerros: '',
            reports: '',
            details: []
        });
        setTimeout(() => {
            this.setState({
                titleerros: 'Listado de errores',
                reports: <ErrorsBLZ dt={this.state.subdetailsdata} title={this.handleChangeTitleReportOthersErrors} />,
                details: this.state.subdetailsdata
            });
        }, 1000);
    }
    handleDetailsViewAll = async (x) => {
        let data = await api.post("list-details-view-blz-contribucion", { empresa_id: this.state.id, ejercicio: x.ejercicio });
        let filter_ = await data.data;
        if (filter_.length > 0) {
            this.setState({
                titlesubtitle: x.descripcion + ' de ' + x.ejercicio,
                reportconciliacion: <CBLZALL data_={filter_} />,
                skeletonbody: true
            });
        }
    }
    handleXtitleViewApp = async () => {
        this.setState({
            skeletonheader: false,
            titlesubtitle: '',
            reportconciliacion: '',
            skeletonbody: false,
        });
    }
    handleViewAcccountsCompany = async () => {
        this.setState({
            //skeletonheader: false,
            // titlesubtitle: '',
            //reportconciliacion: '',
            urlaccounts: 'https://e-xpertum.com.mx/invoces/report-acconts-company?empresa_id=' + this.state.empresa_id,
            //urlaccounts: 'http://localhost:3004/report-acconts-company?empresa_id=' + this.state.empresa_id,
        });

        window.$("#accountmodal").modal("show");
    }

    render() {
        return (
            <div className="content-inner">
                <div className="page-header border-bottom-0">
                    <div className="page-header-content header-elements-md-inline">
                        <div className="page-title d-flex">
                            <h4><i className="icon-arrow-left52 mr-2" /><a href='#/app/client/sistem' style={{ color: 'white' }}><span className="font-weight-semibold">Listado de empresas</span></a> </h4>
                            <a href="#/app/client/sistem" className="header-elements-toggle text-body d-lg-none"><i className="icon-more" /></a>
                        </div>
                        <div className="text-center">
                            <h6 className="mb-0 font-weight-semibold">{this.state.namecompany}</h6>
                            <p className="mb-3 text-muted">{this.state.titleinit}</p>
                        </div>

                        <div className="header-elements d-none mb-3 mb-lg-0">
                            <div className="d-flex justify-content-center">
                                <a href="#" className="btn btn-link btn-float text-body"></a>
                                <a href="#" className="btn btn-link btn-float text-body"></a>
                                <a href="#" className="btn btn-link btn-float text-body"></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content pt-0">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <div className="card-header bg-transparent border-bottom header-elements-inline">
                                            <h6 className="font-weight-semibold mt-2">
                                                <i className="icon-folder6 mr-2" /> Errores en la contablidad electrónica
                                            </h6>
                                            <div className="header-elements">
                                                <div className="list-icons">
                                                    {
                                                        (this.state.stateaccounts > 0) ? (
                                                            <button type="button" onClick={this.handleViewAcccountsCompany} className="btn btn-link" data-toggle="modal" data-target="#accountmodal"><i className="icon-clipboard6 mr-2 text-success" /> Catálogo de cuentas</button>
                                                        ) : (
                                                            <button type="button" className="btn btn-link" ><i className="icon-clipboard6 mr-2 text-danger" /> Catálogo de cuentas: No importado</button>
                                                        )
                                                    }

                                                </div>
                                                <div className="list-icons">
                                                    {
                                                        (this.state.statereports > 0) ? (
                                                            <button type="button" onClick={this.handleModalReport} className="btn btn-link" data-toggle="modal" data-target="#modalviewerrorsfull"><i className="icon-list2 mr-2 text-success" /> Reportes de la contabilidad electrónica</button>
                                                        ) :
                                                            (
                                                                <button type="button" className="btn btn-link"><i className="icon-list2 mr-2 text-danger" /> Contabilidad electrónica: No importada </button>
                                                            )
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                        {
                                            (this.state.data || []).map((index, d) => (
                                                <a className="dropdown-item" key={d} onClick={() => this.get_details_(index)}>
                                                    <i className="icon-file-text2" /> {index.descripcion} <span className="badge badge-danger ml-auto">Error</span>
                                                </a>
                                            ))
                                        }
                                    </div>
                                    <div id="modal_full" className="modal fade" tabIndex={-1} style={{ display: 'none' }} aria-hidden="true">
                                        <div className="modal-dialog modal-full">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">{this.state.title}</h5>
                                                    <button type="button" className="close" data-dismiss="modal">×</button>
                                                </div>
                                                <div className="modal-body">
                                                    {
                                                        (this.state.clave != '' && this.state.clave == "CCC" || this.state.clave == "CC") ? (
                                                            <>
                                                                {_validacion_cuentas_control_(this.state.details)}
                                                            </>
                                                        ) : (
                                                            (this.state.clave != '' && this.state.clave === "CFDIPOL") ?
                                                                (
                                                                    <>
                                                                        {this.state.polices_cfdis_}
                                                                    </>
                                                                ) : (
                                                                    (this.state.clave != '' && this.state.clave === "BLZSD") ?
                                                                        (
                                                                            <>{
                                                                                (this.state.details.length > 0) ? (
                                                                                    <ErrorsBLZ dt={this.state.details} title={this.handleChangeTitleReportOthers} />
                                                                                ) : null
                                                                            }
                                                                            </>
                                                                        ) : (
                                                                            (this.state.clave != '' && this.state.clave === "BLZSI") ? (
                                                                                <>
                                                                                    {details_data_si((this.state.details).sort(sorter))}
                                                                                </>
                                                                            ) : (
                                                                                (this.state.clave != '' && this.state.clave === "BLZSF") ? (
                                                                                    <>
                                                                                        {details_data_sf((this.state.details).sort(sorter))}
                                                                                    </>
                                                                                ) : (
                                                                                    (this.state.clave != '' && this.state.clave === "BLZDH") ? (
                                                                                        <>
                                                                                            {details_data_debehaber((this.state.details).sort(sorter))}
                                                                                        </>
                                                                                    ) : (
                                                                                        (this.state.clave != '' && this.state.clave === "CCA") ? (
                                                                                            <>
                                                                                                {viewreportacconts_((this.state.details))}
                                                                                            </>
                                                                                        ) : null
                                                                                    )
                                                                                )
                                                                            )
                                                                        )
                                                                )
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="modalerrorblz" className="modal fade" tabIndex={-1} style={{ display: 'none' }} aria-hidden="true">
                                        <div className="modal-dialog modal-full">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <div className="breadcrumb-line header-elements-lg-inline border-bottom">
                                                        <div className="d-flex">
                                                            <div className="breadcrumb">
                                                                {
                                                                    (this.state.titleerros != '') ? (
                                                                        <>
                                                                            <span className="breadcrumb-item  btn btn-link" style={{ cursor: 'pointer', fontSize: 14 }} onClick={this.handleModalReporttChangeStatusError}>Errores en la balanza</span>
                                                                            <span className="breadcrumb-item" style={{ fontSize: 14 }}>{this.state.titleerros}</span>
                                                                        </>
                                                                    ) : (
                                                                        <span className="breadcrumb-item" style={{ cursor: 'pointer', fontSize: 14 }}>Errores en la balanza</span>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="button" className="close" data-dismiss="modal">×</button>
                                                </div>
                                                <div className="modal-body">
                                                    {
                                                        (this.state.clave != '' && this.state.clave === "BLZ") ?
                                                            (
                                                                <>{
                                                                    (this.state.details.length > 0) ? (
                                                                        <>
                                                                            {this.state.reports}
                                                                        </>
                                                                    ) : (
                                                                        <div className="card">
                                                                            <Skeleton />
                                                                            <div style={{
                                                                                margin: 40,
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                justifyContent: 'center',
                                                                            }}>
                                                                                <PacmanLoader color={"#ff7043"} loading={this.state.loading} size={25} margin={2} />
                                                                            </div>
                                                                            <Skeleton variant="circle" animation="wave" width={40} height={40} />
                                                                            <Skeleton variant="rect" animation="wave" width={"100%"} height={118} />
                                                                            <Skeleton animation="wave" />
                                                                        </div>
                                                                    )
                                                                }
                                                                </>
                                                            ) : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="modalviewerrorsfull" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                                        <div className="modal-dialog modal-full">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <div className="breadcrumb-line header-elements-lg-inline border-bottom">
                                                        <div className="d-flex">
                                                            <div className="breadcrumb">
                                                                {
                                                                    (this.state.titlereport != '') ? (
                                                                        <>
                                                                            <span className="breadcrumb-item btn btn-link" style={{ cursor: 'pointer', fontSize: 14 }} onClick={this.handleModalReporttChangeStatus}> Reportes de la contabilidad electrónica</span>
                                                                            <span className="breadcrumb-item" style={{ fontSize: 14 }}>{this.state.titlereport}</span>
                                                                        </>

                                                                    ) : (
                                                                        <span className="breadcrumb-item" style={{ cursor: 'pointer', fontSize: 14 }}>Reportes de la contabilidad electrónica</span>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="button" className="close" data-dismiss="modal" onClick={this.handleclearallResult}>×</button>
                                                </div>
                                                <div className="modal-body">
                                                    {this.state.skeleton ? (
                                                        <div className="card">
                                                            <Skeleton />
                                                            <div style={{
                                                                margin: 40,
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                            }}>
                                                                <PacmanLoader color={"#ff7043"} loading={this.state.loading} size={25} margin={2} />
                                                            </div>
                                                            <Skeleton variant="circle" animation="wave" width={40} height={40} />
                                                            <Skeleton variant="rect" animation="wave" width={"100%"} height={118} />
                                                            <Skeleton animation="wave" />
                                                        </div>
                                                    ) : (
                                                        <>
                                                            {this.state.reports}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="modalviewerrorsconciliacion" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                                        <div className="modal-dialog modal-full">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <div className="breadcrumb-line header-elements-lg-inline border-bottom">
                                                        <div className="d-flex">
                                                            <div className="breadcrumb">
                                                                {
                                                                    (this.state.titlesubtitle != '') ? (
                                                                        <>
                                                                            <span className="breadcrumb-item btn btn-link" style={{ cursor: 'pointer', fontSize: 14 }} onClick={this.handleXtitleViewApp}>Declaraciones: Diferencias entre impuestos declarados y contabilizados</span>
                                                                            <span className="breadcrumb-item" style={{ fontSize: 14 }}>{this.state.titlesubtitle}</span>
                                                                        </>
                                                                    ) : (<span className="breadcrumb-item" style={{ cursor: 'pointer', fontSize: 14 }}>Declaraciones: Diferencias entre impuestos declarados y contabilizados </span>)
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="button" className="close" data-dismiss="modal" onClick={this.handleclearallResult}>×</button>
                                                </div>
                                                <div className="modal-body">
                                                    {
                                                        (this.state.skeletonheader === true) ? (
                                                            <div className="card">
                                                                <Skeleton />
                                                                <Skeleton animation={false} height={400} />
                                                                <Skeleton animation="wave" />
                                                            </div>
                                                        ) : (
                                                            <>
                                                                {
                                                                    (!this.state.skeletonbody) ? (
                                                                        <table className="table">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Ejercicio</th>
                                                                                    <th></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    (this.state.details || []).map((d, t) => (
                                                                                        <tr key={t}>
                                                                                            <td>{d.ejercicio}</td>
                                                                                            <td>
                                                                                                <button type="button" className="btn btn-light" onClick={() => this.handleDetailsViewAll(d)}>
                                                                                                    <i className="icon-menu2 mr-2 text-danger" /> Ver detalle</button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    ) : (
                                                                        <>
                                                                            {
                                                                                this.state.reportconciliacion
                                                                            }
                                                                        </>
                                                                    )
                                                                }

                                                            </>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="accountmodal" className="modal fade" tabIndex={-1} style={{ display: 'none' }} aria-hidden="true">
                                        <div className="modal-dialog modal-full">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">Catálogo de cuentas</h5>
                                                    <button type="button" className="close" data-dismiss="modal" onClick={this.handleclearallResult}>×</button>
                                                </div>
                                                <div className="modal-body">
                                                    <div className='containeriframe'>
                                                        <iframe className='responsiveiframeall scroll scroll1'
                                                            src={this.state.urlaccounts}
                                                            frameBorder={0} allowFullScreen
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function _validacion_cuentas_control_(details) {
    return (
        <table className='table'>
            <thead>
                <tr>
                    <th># cuenta</th>
                    <th># Póliza</th>
                    <th>Fecha</th>
                    <th>Concepto</th>
                    <th>Ejercicio</th>
                    <th>Periodo</th>
                </tr>
            </thead>
            <tbody>
                {
                    (details || []).map((ins, index) => (
                        <tr key={index}>
                            <td>{ins.NumCta}</td>
                            <td>{ins.num_unico_pol}</td>
                            <td>{ins.fecha}</td>
                            <td>{ins.Concepto}</td>
                            <td>{ins.ejercicio}</td>
                            <td>{name_month(ins.periodo)}</td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    )
}
function details_data_si(data) {
    return (
        <table className='table'>
            <thead>
                <tr>
                    <th>Ejercicio</th>
                    <th>Periodo</th>
                    <th>Saldo Inicial Deudor</th>
                    <th>Saldo Inicial Acreedor</th>
                </tr>
            </thead>
            <tbody>
                {
                    (data || []).map((s, index) => (
                        <tr key={index}>
                            <td>{s.ejercicio}</td>
                            <td>{name_month(s.periodo)}</td>
                            <td className='text-danger text-center'>{parseFloat(s.sid).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                            <td className='text-danger text-center'>{parseFloat(s.sia).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    )
}
function details_data_sf(data) {
    return (
        <table className='table'>
            <thead>
                <tr>
                    <th>Ejercicio</th>
                    <th>Periodo</th>
                    <th>Saldo Final Deudor</th>
                    <th>Saldo Final Acreedor</th>
                </tr>
            </thead>
            <tbody>
                {
                    (data || []).map((s, index) => (
                        <tr key={index}>
                            <td>{s.ejercicio}</td>
                            <td>{name_month(s.periodo)}</td>
                            <td className='text-danger text-center'>{parseFloat(s.sfd).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                            <td className='text-danger text-center'>{parseFloat(s.sfa).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    )
}
function details_data_debehaber(data) {
    return (
        <table className='table'>
            <thead>
                <tr>
                    <th>Ejercicio</th>
                    <th>Periodo</th>
                    <th>Saldo Debe</th>
                    <th>Saldo Haber</th>
                </tr>
            </thead>
            <tbody>
                {
                    (data || []).map((s, index) => (
                        <tr key={index}>
                            <td>{s.ejercicio}</td>
                            <td>{name_month(s.periodo)}</td>
                            <td className='text-danger text-center'>{parseFloat(s.debe).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                            <td className='text-danger text-center'>{parseFloat(s.haber).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    )
}

function name_month(key) {
    switch (key) {
        case "01":
            return "Enero"
            break;
        case "02":
            return "Febrero"
            break;
        case "03":
            return "Marzo"
            break;
        case "04":
            return "Abril"
            break;
        case "05":
            return "Mayo"
            break;
        case "06":
            return "Junio"
            break;
        case "07":
            return "Julio"
            break;
        case "08":
            return "Agosto"
            break;
        case "09":
            return "Septiembre"
            break;
        case "10":
            return "Octubre"
            break;
        case "11":
            return "Noviembre"
            break;
        case "12":
            return "Diciembre"
            break;
        default:
            break;
    }
}
function viewreportacconts_(data) {
    return (
        <table className='table'>
            <thead>
                <tr>
                    <th>Cuenta contable</th>
                    <th>Descripción</th>
                    <th>Cuenta de control</th>
                    <th>Código agrupador</th>
                    <th>Código agrupador correcto</th>
                </tr>
            </thead>
            <tbody>
                {
                    (data || []).map((s, index) => (
                        <tr key={index}>
                            <td>{s.account_number}</td>
                            <td>{s.description}</td>
                            <td>{s.control_account}</td>
                            <td className='text-danger text-center'>{(s.grouping_code)}</td>
                            <td className='text-success text-center'>{String(s.grouping_code).slice(0, 3)}</td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    )
}
export default ReportErros;