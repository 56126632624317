import React, { Component } from 'react'
import api from '../../app/Config/audit-data';
import { Tooltip } from '@material-ui/core';
import { PacmanLoader } from "react-spinners";
import Skeleton from '@material-ui/lab/Skeleton';
import './style.css';
import ReactDatatable from '@ashvin27/react-datatable';
export default class ListReports extends Component {
    constructor(props) {
        super(props);
        let pareme = JSON.parse(localStorage.getItem("parameterscompany"));
        this.columns = [
            {
                key: "periodo",
                text: "Pólizas",
                className: "periodo",
                align: "center",
                //sortable: true,
                /// width: 120,
                cell: df => {
                    return (
                        <div className="text-left">
                            {
                                df.poliza != 0 ?
                                    (
                                        <Tooltip title={"Click para ver las pólizas de " + name_month(df.periodo) + " de " + df.ejercicio} arrow placeholder="top">
                                            <button onClick={() => this.handleViewReport(df)} type="button" className="btn btn-light btn-labeled btn-labeled-right"><b><i className="icon-menu2 text-success"></i></b>Pólizas de {name_month(df.periodo) + " de " + df.ejercicio}</button>
                                        </Tooltip>
                                    ) : null
                            }
                        </div>
                    );
                }
            }, {
                key: "balanza_comprobacion",
                text: "Balanza XML importada",
                className: "balanza_comprobacion",
                align: "center",
                ///sortable: true,
                cell: df => {
                    return (
                        <div className="text-left">
                            {
                                df.balanza_comprobacion != 0 ?
                                    (
                                        <Tooltip title={"Click para ver la balanza importada de " + name_month(df.periodo) + " de " + df.ejercicio} arrow placeholder="top">
                                            <button type="button" className="btn btn-light btn-labeled btn-labeled-right" onClick={() => this.handleViewReportblzxml(df)}><b><i className="icon-menu2 text-success"></i></b>Balanza XML de {name_month(df.periodo) + " de " + df.ejercicio}</button>
                                        </Tooltip>
                                    ) : null
                            }
                        </div>
                    );
                }
            }, {
                key: "ejercicio",
                text: "Balanza generada de pólizas",
                className: "ejercicio",
                align: "center",
                ///sortable: true,
                cell: df => {
                    return (
                        <div className="text-left">
                            {
                                df.poliza != 0 ?
                                    (
                                        <Tooltip title={"Click para ver la balanza generada de " + name_month(df.periodo) + " de " + df.ejercicio} arrow placeholder="top">
                                            <button type="button" className="btn btn-light btn-labeled btn-labeled-right" onClick={() => this.handleViewReportblzgenerada(df)}><b><i className="icon-menu2 text-success"></i></b>Balanza generada de {name_month(df.periodo) + " de " + df.ejercicio}</button>
                                        </Tooltip>
                                    ) : null
                            }
                        </div>
                    );
                }
            }]

        this.config = {
            key_column: 'uuid',
            page_size: 20,
            length_menu: [20, 50, 100],
            button: {
                excel: false,
                print: false,
                extra: false,
            },
            "language": {
                "length_menu": "Mostrar _MENU_ registros por página",
                "filter": "Buscar en registros ...",
                "info": "Mostrando _START_ a _END_ de _TOTAL_ entradas",
                "pagination": {
                    "first": "Primero",
                    "previous": "Previa",
                    "next": "próximo",
                    "last": "Última"
                }
            }
        }
        this.state = {
            data: [],
            empresa_id: pareme.empresa_id,
            id: pareme.id,
            start: props.status,
            reportpol: false,
            reportblz: false,
            reportblzgn: false,
            skeleton: true,
            url: '',
            urlinit: 'https://e-xpertum.com.mx/invoces/'
            ///urlinit: 'http://localhost:3004/',
        }
    }
    componentDidMount() {
        this.getDataList();
    }
    getDataList = async (fr) => {
        let _data_ = await api.post("list-reports-view", { empresa_id: this.state.id });
        let _result_data_ = await _data_.data;
        this.setState({
            data: _result_data_,
            start: true,
            skeleton: false
        });
    }
    handleViewReport = async (sx) => {
        this.props.title("Póliza del mes de " + name_month(sx.periodo) + " de " + sx.ejercicio);
        this.setState({
            start: false,
            reportpol: true,
            url: this.state.urlinit + "polices?empresa_id=" + this.state.id + "&ejercicio=" + sx.ejercicio + "&periodo=" + sx.periodo
        });
    }
    handleViewReportblzxml = async (sx) => {
        this.props.title("Balanza de comprobación (xml) del mes de " + name_month(sx.periodo) + " de " + sx.ejercicio);
        this.setState({
            start: false,
            reportpol: false,
            reportblz: true,
            reportblzgn: false,
            url: this.state.urlinit + "blz-xml?empresa_id=" + this.state.id + "&ejercicio=" + sx.ejercicio + "&periodo=" + sx.periodo + "&id=" + this.state.empresa_id
        });
    }
    handleViewReportblzgenerada = async (sx) => {
        this.props.title("Balanza de comprobación del mes de " + name_month(sx.periodo) + " de " + sx.ejercicio);
        this.setState({
            start: false,
            reportpol: false,
            reportblz: false,
            reportblzgn: true,
            url: this.state.urlinit + "blz-generada?empresa_id=" + this.state.id + "&ejercicio=" + sx.ejercicio + "&periodo=" + sx.periodo + "&id=" + this.state.empresa_id
        });
    }
    handleclearView = async () => {
        this.props.title("Listado de reportes");
        this.setState({
            start: true,
            reportpol: false,
            reportblz: false,
            reportblzgn: false,
            url: ''
        });
    }
    render() {
        return (
            <>{this.state.skeleton ? (
                <div className="card">
                    <Skeleton />
                    <div style={{
                        margin: 40,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <PacmanLoader color={"#ff7043"} loading={this.state.loading} size={25} margin={2} />
                    </div>
                    <Skeleton variant="circle" animation="wave" width={40} height={40} />
                    <Skeleton variant="rect" animation="wave" width={"100%"} height={118} />
                    <Skeleton animation="wave" />
                </div>) : (
                <>
                    {
                        this.state.start ? (
                            <ReactDatatable
                                config={this.config}
                                records={(this.state.data || [])}
                                columns={this.columns}
                            />
                        ) : null
                    }
                    {
                        (this.state.reportpol) ? (
                            <div className='containeriframe'>
                                <iframe className='responsiveiframeall scroll scroll1'
                                    src={this.state.url} title="K"
                                    frameBorder={0} allowFullScreen
                                />
                            </div>
                        ) : null
                    }
                    {
                        (this.state.reportblz) ? (
                            <div className='containeriframe'>
                                <iframe className='responsiveiframeall scroll scroll1'
                                    src={this.state.url} title="K"
                                    frameBorder={0} allowFullScreen
                                />
                            </div>
                        ) : null
                    }
                    {
                        (this.state.reportblzgn) ? (
                            <div className='containeriframe'>
                                <iframe className='responsiveiframeall scroll scroll1'
                                    src={this.state.url} title="K"
                                    frameBorder={0} allowFullScreen
                                />
                            </div>
                        ) : null
                    }
                </>
            )}
            </>
        )
    }
}
function name_month(key) {
    switch (key) {
        case "01":
            return "Enero"
            break;
        case "02":
            return "Febrero"
            break;
        case "03":
            return "Marzo"
            break;
        case "04":
            return "Abril"
            break;
        case "05":
            return "Mayo"
            break;
        case "06":
            return "Junio"
            break;
        case "07":
            return "Julio"
            break;
        case "08":
            return "Agosto"
            break;
        case "09":
            return "Septiembre"
            break;
        case "10":
            return "Octubre"
            break;
        case "11":
            return "Noviembre"
            break;
        case "12":
            return "Diciembre"
            break;
        default:
            break;
    }
}