import React, { useState, useEffect } from "react";
export default function TableComponent(props) {
    const [details, setDetails] = useState([]);
    const [numUser, setNumUser] = useState(0);
    const [numCompany, setNumComp] = useState(0);
    useEffect(() => {
        let data = props.data;
        setDetails(data.details);
        setNumUser(data.numero_usuario);
        setNumComp(data.numero_empresa);
    }, details)

    ///var result = (data.length > 0 ? data : data);
    if (props.data !== undefined) {
        return (
            <>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-6">
                            <label className="col-form-label"> Número de usuarios:</label>&nbsp;&nbsp;&nbsp;
                            <span className="badge badge-light badge-striped badge-striped-left border-left-primary"> {numUser}</span>
                        </div>
                        <div className="col-md-6">
                            <label className="col-form-label">Numero de empresas:</label>&nbsp;&nbsp;&nbsp;
                            <span className="badge badge-light badge-striped badge-striped-left border-left-primary"> {numCompany}</span>
                        </div>
                        <hr />
                        <div className="col-md-12">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Servicio</th>
                                        <th>Fecha Inicio</th>
                                        <th>Fecha Fin</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (details || []).map((k, j) => (
                                            <tr key={j}>
                                                <td>
                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                        <input type="checkbox" className="custom-control-input" id={k.parent_id} defaultChecked />
                                                        <label className="custom-control-label" htmlFor={k.parent_id}>Activo</label>
                                                    </div>
                                                </td>
                                                <td>{k.label}</td>
                                                <td>{k.fecha_inicio}</td>
                                                <td>{k.fecha_fin}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </>
        );
    }
    else
        return (
            <h1>Cargando.......</h1>
        )
}
