import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom'
import ReactDatatable from '@ashvin27/react-datatable';
import api from '../../Config/api';

import ModalDetalle from './detalleempresas';
import { Tooltip, } from "@material-ui/core";
class ListadClientes extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "razon_social",
                text: "Nombre, Denominación o razón social",
                className: "razon_social",
                align: "left",
                sortable: true,
            },
            {
                key: "rfc",
                text: "RFC",
                className: "rfc",
                align: "left",
                sortable: true
            },
            {
                key: "fecha_inicio",
                text: "Fecha de inicio",
                className: "fecha_inicio",
                sortable: true
            }, {
                key: "fecha_fin",
                text: "Fecha fin",
                className: "fecha_fin",
                sortable: true
            },
            {
                key: "action",
                text: "Acción",
                className: "action",
                width: 150,
                align: "center",
                sortable: false,
                cell: record => {
                    return (
                        <div style={{ textAlign: 'center', cursor: 'pointer' }}>
                            <Tooltip title="Ver Docs" placement="top" arrow onClick={() => this.handleViewModal(record)}>
                                <i className="icon-file-text2 mr-2 text-success"></i>
                            </Tooltip>
                            {
                                record.status === "SUCCESS" ? (
                                    <>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <Tooltip title="Configurar la empresas para descargar los acuses" placement="top" arrow onClick={() => this.handleModalConfig(record)}>
                                            <i className="icon-cogs mr-2 text-danger"></i>
                                        </Tooltip>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <Tooltip title="Tablero de control de CFDIs a contabilizar " placement="top" arrow onClick={() => this.hadleviewtablero(record)}>
                                            <i className="icon-file-xml2 mr-2 text-success"></i>
                                        </Tooltip>
                                    </>
                                ) : null
                            }

                        </div>
                    );
                }
            }
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,

            language: {
                length_menu: "Mostrar _MENU_ registros por página",
                filter: "Buscar en registros ...",
                info: "Mostrando _START_ a _END_ de _TOTAL_ entradas",
                pagination: {
                    first: "Primero",
                    previous: "Previa",
                    next: "próximo",
                    last: "Última"
                }
            }
        }
        this.state = {
            data: [],
            details: '',
            empresa_id: 0,
            fecha_inicio: '',
            fecha_fin: '',
            emitido: '',
            recibido: '',
            declaraciones: '',
            mensaje: '', rfc: '', id: 0, mensajesucess: ''
        }

    }
    componentDidMount() {
        this.list_data_config_();
    }
    list_data_config_ = async () => {
        var datainfo = JSON.parse(localStorage.getItem("panelProduct"));
        var object = {
            id: datainfo.id
        }
        let dataapp = await api.post('list-efirma-cat', object);
        let _result_data_ = await dataapp.data;
        this.setState({ data: _result_data_ });
    }
    handleclearcache = () => {
        this.setState({ details: '' })
    }
    handleViewModal = (info) => {
        this.setState({
            details: <ModalDetalle value={{ data: info, clear: this.handleclearcache }} />
        });
    }
    handleModalConfig = async (u) => {
        localStorage.setItem("panelconfig", JSON.stringify(u))
        this.props.history.push('/app/panel/history-cfdis');
        ///console.log(u)
        ///this.setState({ id: u.empres_id, empresa_id: u.empresa_id, rfc: u.rfc });
        //window.$("#appconfigdescargadata").modal("show");
    }

    hadleviewtablero = async (l) => {
        //console.log(l)
        localStorage.setItem("panelconfig", JSON.stringify(l))
        ////this.props.history.push('/app/panel/validate-cfdis');
    }
    render() {
        return (
            <>
                <div className="content-wrapper">
                    <div className="page-header border-bottom-0">
                        <div className="page-header-content header-elements-md-inline">
                            <div className="page-title d-flex">

                                <a href="#/app/dashboard" style={{ color: 'white' }}><h4><i className="icon-arrow-left52 mr-2"></i> <span className="font-weight-semibold">Panel</span></h4></a>
                            </div>
                        </div>
                    </div>
                    <div className="content pt-0">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header bg-transparent border-bottom">
                                        <h6 className="card-title"> <i className="icon-cog3 mr-2"></i> Listado de empresas con el servicio de auditoría</h6>
                                    </div>
                                    <div className="card-body">
                                        <ReactDatatable
                                            config={this.config}
                                            records={this.state.data}
                                            columns={this.columns}
                                        />
                                        {/*<table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Nombre / Razón social</th>
                                                    <th>RFC</th>
                                                    <th>Fecha Inicio</th>
                                                    <th>Fecha Fin</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(this.state.data || []).map((dt, id) => (
                                                    <tr key={id}>
                                                        <td>{dt.razon_social}</td>
                                                        <td>{dt.rfc}</td>
                                                        <td>{dt.fecha_inicio}</td>
                                                        <td>{dt.fecha_fin}</td>
                                                        <td>
                                                            <a className="navbar-nav-link" onClick={() => this.handleViewModal(dt)}><i className="icon-file-text2 mr-2"></i>Ver Docs</a>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.details}
                <div id="appconfigdescargadata" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                </div>
            </>
        );
    }
}

export default withRouter(ListadClientes);