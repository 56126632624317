import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { Card, CardActions, CardContent, Avatar, Typography, Divider, Button, LinearProgress } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import api from '../../app/Config/api';
const useStyles = makeStyles(theme => ({
    root: {},
    details: {
        display: 'flex'
    },
    avatar: {
        marginLeft: 'auto',
        height: 110,
        width: 100,
        flexShrink: 0,
        flexGrow: 0
    },
    progress: {
        marginTop: 2// theme.spacing(2)
    },
    uploadButton: {
        marginRight: 2//theme.spacing(2)
    }
}));

const AccountProfile = props => {
    const { className, ...rest } = props;

    const classes = useStyles();
    const user = JSON.parse(localStorage.getItem("id_token"));
    const [name, setNameFile] = useState('Subir foto');
    const [data, setData] = useState(user);
    const handleChangeFiles = (e) => {
        const file = e.target.files[0];
        setNameFile(file.name);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('id', data.id);
        api.post('ProfileUser', formData).then(res => {
            if (res.data.status === 200) {
                localStorage.removeItem("id_token")
                localStorage.setItem('id_token', JSON.stringify(res.data.result));

                setData({
                    ...data,
                    img: res.data.url
                });
            }
            /**/
        }).catch(err => console.log(err));
    }
    const { img } = data;
    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}>
            <CardContent>
                <div className={classes.details}>
                    <div>
                        <Typography gutterBottom variant="h6">
                            <span style={{ color: 'white' }}> {user.nombre} {user.apellido_paterno}</span>
                        </Typography>
                        <Typography className={classes.locationText} color="textSecondary" variant="body1" >
                            <span style={{ color: 'white' }}>{user.usuario}</span>
                        </Typography>
                        <Typography className={classes.dateText} color="textSecondary" variant="body1">
                            <span style={{ color: 'white' }}>{moment().format('hh:mm A')} ({'GTM-7'})</span>
                        </Typography>
                    </div>
                    <Avatar className={classes.avatar} src={img} />
                </div>

            </CardContent>
            <Divider />
            <CardActions>
                <label htmlFor={2}>
                    <Button variant="contained" component="span" color="primary" fullWidth>
                        <Icons.VerifiedUser />
                        <span> {name} </span>
                    </Button>
                </label>
                <input type="file" id={2} style={{ display: "none" }} onChange={handleChangeFiles} accept="image/*" />
            </CardActions>
        </Card>
    );
};

AccountProfile.propTypes = {
    className: PropTypes.string
};

export default AccountProfile;