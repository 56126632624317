import React from 'react';
import { render } from "react-dom";
import PropTypes from 'prop-types';
import { Button, Divider, Card, CardContent, TextField, Grid, } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import IMGSYSTEM from './file';
import api from '../../../app/Config/api';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
class ResponsiveDialog extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            acccion: 'edit',
            id: '',
            nombre: '',
            descripcion: '',
            version: '',
            marca: '',
            mercado: '',
            nombreimg: '',
            ulrimg: '',
            alert: false,
            alerterror: false,
            vertical: 'top',
            horizontal: 'right',
            mesagge: ''
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleCerrar = this.handleCerrar.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.obtenerNombreUlrImg = this.obtenerNombreUlrImg.bind(this);
        this.handleCloseMesagge = this.handleCloseMesagge.bind(this);
        this.formRef = null;
        //this.onSubmit = this.onSubmit.bind(this);
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleCerrar = () => {
        this.setState({ open: false });
    }
    handleChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        })
    }
    handleSubmit(event) {
        api.post("NewProducto", this.state).then((response) => {
            var result = response.data;
            if (result.status === 200) {
                this.setState({ alert: true, mesagge: result.message });
                this.props.contenido();
                this.name.value = "";
                setTimeout(() => {
                    this.setState({
                        open: false, id: '',
                        nombre: '',
                        descripcion: '',
                        version: '',
                        marca: '',
                        mercado: '',
                        nombreimg: '',
                        ulrimg: '',
                    });
                }, 7000);
            }
            else
                this.setState({ alerterror: true, mesagge: result.message });
        });
    }
    obtenerNombreUlrImg(e) {
        this.setState({
            nombreimg: e.file,
            ulrimg: e.path
        });
    }
    handleCloseMesagge = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({ alert: false, alerterror: false });
    };
    onSubmit = async (values) => {
        console.log(values)
    }

    render() {
        const { fullScreen } = this.props;
        const { horizontal, vertical } = this.state;
        return (
            <div>

                <button type="button" class="btn btn-light btn-ladda btn-ladda-spinner ladda-button" data-style="slide-left" onClick={this.handleClickOpen}>
                    <span class="ladda-label">Nuevo</span>
                    <span class="ladda-spinner"></span><div class="ladda-progress" style={{ "width": " 0px;" }}></div></button>
                <Dialog fullWidth={fullScreen} maxWidth={"lg"} open={this.state.open} onClose={this.handleClose} aria-labelledby="max-width-dialog-title" >
                    <DialogTitle id="max-width-dialog-title" style={{ textAlign: "center" }}>Nuevo cliente</DialogTitle>
                    <Divider />
                    <DialogContent>
                        <Card>
                            <CardContent>
                                <Snackbar open={this.state.alert} autoHideDuration={6000} onClose={this.handleCloseMesagge} anchorOrigin={{ vertical, horizontal }}>
                                    <Alert onClose={this.handleCloseMesagge} severity="success">
                                        {this.state.mesagge}
                                    </Alert>
                                </Snackbar>
                                <Snackbar open={this.state.alerterror} autoHideDuration={6000} onClose={this.handleCloseMesagge} anchorOrigin={{ vertical, horizontal }}>
                                    <Alert onClose={this.handleCloseMesagge} severity="error">
                                        {this.state.mesagge}
                                    </Alert>
                                </Snackbar>

                                <form ref={(ref) => this.formRef = ref}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <TextField id="nombre" name="nombre" onChange={this.handleChange} value={this.state.nombre}
                                                label="Nombre del producto" type="text" margin="dense" fullWidth required variant="outlined"
                                                inputRef={el => (this.name = el)} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField id="descripcion" label="Descripción" value={this.state.descripcion} name="descripcion" onChange={this.handleChange}
                                                type="text" margin="dense" fullWidth required variant="outlined"
                                                inputRef={el => (this.name = el)} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField id="marca" label="Proveedor" value={this.state.marca} name="marca" onChange={this.handleChange} type="text" margin="dense" fullWidth required variant="outlined"
                                                inputRef={el => (this.name = el)} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField id="mercado" label="Aplicación" value={this.state.mercado} name="mercado" onChange={this.handleChange} type="text" margin="dense" fullWidth required variant="outlined"
                                                inputRef={el => (this.name = el)} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField id="version" label="Versión" value={this.state.version} name="version" onChange={this.handleChange} type="text" margin="dense" fullWidth required variant="outlined"
                                                inputRef={el => (this.name = el)} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <IMGSYSTEM name={this.obtenerNombreUlrImg} />
                                        </Grid>
                                        <Grid item xs={10}>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Button variant="outlined" color="primary" onClick={this.handleSubmit}>Guardar</Button>
                                        </Grid>
                                    </Grid>
                                </form>

                            </CardContent>
                        </Card>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary"> Cerrar</Button>
                    </DialogActions>
                </Dialog>

            </div>
        );
    }
}

ResponsiveDialog.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
};

export default withMobileDialog()(ResponsiveDialog);

