import React, { Component } from 'react';
import api from '../../Config/apifiles';
class DowloadCFDI extends Component {
    constructor(props) {
        let config = JSON.parse(localStorage.getItem("panelconfig"));
        super(props);
        this.state = {
            empresa_id: config.empres_id,
            emitido: [],
            filter: "ANIO",
            filterre: 'ANIO',
            recibido: [],
            tmprecibido: [],
            tmpemitido: [],
            rfc: config.rfc,
            mensaje: ''
        }
    }
    componentDidMount() {
        //this.getlistEmitido();
    }
    getlistEmitido = async () => {
        let data = await api.post('get-view-list-emitidos', this.state);
        let datar = await api.post('get-view-list-recibidos', this.state);
        this.setState({ emitido: data.data, tmpemitido: data.data, recibido: datar.data, tmprecibido: datar.data, });
    }
    getfilterListEmitido = async (y) => {
        this.setState({ filter: y.target.value });
        let _pr = {
            empresa_id: this.state.empresa_id,
            filter: y.target.value
        }
        let data = await api.post('get-view-list-emitidos', _pr);
        this.setState({ emitido: data.data, tmpemitido: data.data });
    }
    getfilterListCanceladosEmitidos = async (y) => {
        this.setState({ filter: "ANIO" });
        let data = await api.post('get-view-list-emitidos-cancelados', { empresa_id: this.state.empresa_id });
        this.setState({ emitido: data.data });
    }
    getfilterListCanceladosRecibidos = async (y) => {
        this.setState({ filter: y.target.value });
        let data = await api.post('get-view-list-recibidos-cancelados', { empresa_id: this.state.empresa_id });
        this.setState({ recibido: data.data });
    }
    getfilterListRecibido = async (y) => {
        this.setState({ filterre: y.target.value });
        let _pr = {
            empresa_id: this.state.empresa_id,
            filter: y.target.value
        }
        let data = await api.post('get-view-list-recibidos', _pr);
        this.setState({ recibido: data.data, tmprecibido: data.data });
    }
    filterListRecibidos = async (k) => {
        const target = k.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        if (value) {
            let filters = (this.state.tmprecibido).filter((d) => d.faltantes > 0);
            this.setState({ recibido: filters });
        }
        else
            this.setState({ recibido: this.state.tmprecibido });
    }
    filterListEmitidos = async (k) => {
        const target = k.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        if (value) {
            let filters = (this.state.tmpemitido).filter((d) => d.faltantes > 0);
            this.setState({ emitido: filters });
        }
        else
            this.setState({ emitido: this.state.tmpemitido });
    }
    handleDownloadConfigRecibido = async () => {
        let parameters = {
            empresa_id: this.state.empresa_id,
            rfc: this.state.rfc
        }
        if (this.state.filterre === "ANIO") {
            let data = await api.post('config-recibidos-download-anio', parameters);
            this.setState({ mensaje: (data.data.message) });
            setTimeout(() => {
                this.setState({ mensaje: '' });
            }, 4000);
        }
        if (this.state.filterre === "DIA") {
            let data = await api.post('config-recibidos-download-day', parameters);
            this.setState({ mensaje: (data.data.message) });
            setTimeout(() => {
                this.setState({ mensaje: '' });
            }, 4000);
        }
    }
    handleDownloadConfigEmitido = async () => {
        let parameters = {
            empresa_id: this.state.empresa_id,
            rfc: this.state.rfc
        }
        if (this.state.filter === "ANIO") {
            let data = await api.post('config-emitido-download-anio', parameters);
            this.setState({ mensaje: (data.data.message) });
            setTimeout(() => {
                this.setState({ mensaje: '' });
            }, 4000);
        }
        if (this.state.filter === "DIA") {
            let data = await api.post('config-emitido-download-day', parameters);
            this.setState({ mensaje: (data.data.message) });
            setTimeout(() => {
                this.setState({ mensaje: '' });
            }, 4000);
        }
    }
    render() {
        return (
            <div className="card">
                <div className="card-header bg-transparent border-bottom">
                    <h6 className="card-title">
                        <i className="icon-cog3 mr-2" />
                        Comparativo de los cfdis vs metadata
                    </h6>
                </div>
                <div className="card-body">
                    <div className="row">
                        {
                            (this.state.mensaje !== '') ? (
                                <div className="alert alert-success alert-styled-left alert-arrow-left alert-dismissible">
                                    <span className="font-weight-semibold">Información!</span> {this.state.mensaje}
                                </div>
                            ) : null
                        }

                        <div className="col-sm-6">
                            <div className="row">
                                <div className="col-lg-9">
                                    <div className="form-group">
                                        <p className="font-weight-semibold">Filtrar Emitidos</p>
                                        <div className="border p-3 rounded">
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" className="custom-control-input" name="cr-i-l" id="cr_l_i_s" value="ANIO" defaultChecked onChange={this.getfilterListEmitido} />
                                                <label className="custom-control-label" htmlFor="cr_l_i_s">Año</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" className="custom-control-input" name="cr-i-l" id="cr_l_i_u" value="MES" onChange={this.getfilterListEmitido} />
                                                <label className="custom-control-label" htmlFor="cr_l_i_u">Mes</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" className="custom-control-input" name="cr-i-l" id="cr_l_i_d" value="DIA" onChange={this.getfilterListEmitido} />
                                                <label className="custom-control-label" htmlFor="cr_l_i_d">Día</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" className="custom-control-input" name="cr-i-l" id="cancelados_emitidos" value="CANCELADO" onChange={this.getfilterListCanceladosEmitidos} />
                                                <label className="custom-control-label" htmlFor="cancelados_emitidos">Cancelados</label>
                                            </div>
                                            <div className="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" className="custom-control-input" id="ccliS" onChange={this.filterListEmitidos} />
                                                <label className="custom-control-label" htmlFor="ccliS">Faltantes Vigentes</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <br /><br />
                                    <button type="button" className="btn btn-light" onClick={this.handleDownloadConfigEmitido}><i className="icon-make-group mr-2" /> Configurar</button>
                                </div>
                            </div>
                            {
                                this.state.filter === "ANIO" ? (
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Ejercicio</th>
                                                <th>Total cfdis</th>
                                                <th>Total Faltantes</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (this.state.emitido || []).map((x, s) => (
                                                    <tr key={s}>
                                                        <td className="text-center">{x.anio}</td>
                                                        <td className="text-center">{x.conts}</td>
                                                        <td className="text-center">{(x.faltantes <= 0 ? (<i className="text-success icon-file-check"></i>) : x.faltantes)} </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                ) : (this.state.filter === "MES" ? (
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Ejercicio</th>
                                                <th>Mes</th>
                                                <th>Total cfdis</th>
                                                <th>Total Faltantes</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (this.state.emitido || []).map((x, s) => (
                                                    <tr key={s}>
                                                        <td className="text-center">{x.anio}</td>
                                                        <td className="text-center">{x.namemonth}</td>
                                                        <td className="text-center">{x.conts}</td>
                                                        <td className="text-center">{(x.faltantes <= 0 ? (<i className="text-success icon-file-check"></i>) : x.faltantes)} </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                ) : (this.state.filter === "DIA" ?
                                    (<table className="table">
                                        <thead>
                                            <tr>
                                                <th>Ejercicio</th>
                                                <th>Fecha</th>
                                                <th>Total cfdis</th>
                                                <th>Total Faltantes</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (this.state.emitido || []).map((x, s) => (
                                                    <tr key={s}>
                                                        <td className="text-center">{x.anio}</td>
                                                        <td className="text-center">{x._fecha}</td>
                                                        <td className="text-center">{x.conts}</td>
                                                        <td className="text-center">{(x.faltantes <= 0 ? (<i className="text-success icon-file-check"></i>) : x.faltantes)} </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>) : null))
                            }
                        </div>
                        <div className="col-sm-6">
                            <div className="row">
                                <div className="col-lg-9">
                                    <div className="form-group">
                                        <p className="font-weight-semibold">Filtrar Recibidos</p>
                                        <div className="border p-3 rounded">
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" className="custom-control-input" name="cr-r-l" id="cr_r_i_s" value="ANIO" defaultChecked onChange={this.getfilterListRecibido} />
                                                <label className="custom-control-label" htmlFor="cr_r_i_s">Año</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" className="custom-control-input" name="cr-r-l" id="cr_r_i_u" value="MES" onChange={this.getfilterListRecibido} />
                                                <label className="custom-control-label" htmlFor="cr_r_i_u">Mes</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" className="custom-control-input" name="cr-r-l" id="cr_r_i_d" value="DIA" onChange={this.getfilterListRecibido} />
                                                <label className="custom-control-label" htmlFor="cr_r_i_d">Día</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" className="custom-control-input" name="cr-r-l" id="cancelados_recibidos" value="ANIO" onChange={this.getfilterListCanceladosRecibidos} />
                                                <label className="custom-control-label" htmlFor="cancelados_recibidos">Cancelados</label>
                                            </div>
                                            <div className="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" className="custom-control-input" id="cc_li_c" onChange={this.filterListRecibidos} />
                                                <label className="custom-control-label" htmlFor="cc_li_c">Solo faltantes</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <br /><br />
                                    <button type="button" className="btn btn-light" onClick={this.handleDownloadConfigRecibido}><i className="icon-make-group mr-2" /> Configurar</button>
                                </div>
                            </div>
                            {
                                this.state.filterre === "ANIO" ? (
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Ejercicio</th>
                                                <th>Total cfdis</th>
                                                <th>Total Faltantes</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (this.state.recibido || []).map((x, s) => (
                                                    <tr key={s}>
                                                        <td className="text-center">{x.anio}</td>
                                                        <td className="text-center">{x.conts}</td>
                                                        <td className="text-center">{(x.faltantes <= 0 ? (<i className="text-success icon-file-check"></i>) : x.faltantes)} </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                ) : (this.state.filterre === "MES" ? (
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Ejercicio</th>
                                                <th>Mes</th>
                                                <th>Total cfdis</th>
                                                <th>Total Faltantes</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (this.state.recibido || []).map((x, s) => (
                                                    <tr key={s}>
                                                        <td className="text-center">{x.anio}</td>
                                                        <td className="text-center">{x.namemonth}</td>
                                                        <td className="text-center">{x.conts}</td>
                                                        <td className="text-center">{(x.faltantes <= 0 ? (<i className="text-success icon-file-check"></i>) : x.faltantes)} </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                ) : (this.state.filterre === "DIA" ?
                                    (<table className="table">
                                        <thead>
                                            <tr>
                                                <th>Ejercicio</th>
                                                <th>Fecha</th>
                                                <th>Total cfdis</th>
                                                <th>Total Faltantes</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (this.state.recibido || []).map((x, s) => (
                                                    <tr key={s}>
                                                        <td className="text-center">{x.anio}</td>
                                                        <td className="text-center">{String(x._fecha).substr(0, 10)}</td>
                                                        <td className="text-center">{x.conts}</td>
                                                        <td className="text-center">{(x.faltantes <= 0 ? (<i className="text-success icon-file-check"></i>) : x.faltantes)} </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>) : null))
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DowloadCFDI;