import React from 'react';
import { render } from "react-dom";
import PropTypes from 'prop-types';
import { Button, Divider, Card, CardContent, TextField, Grid, } from '@material-ui/core';

import withMobileDialog from '@material-ui/core/withMobileDialog';
import Avatar from '@material-ui/core/Avatar';

import IMGSYSTEM from './file';
import api from '../../../app/Config/api';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
class ResponsiveDialog extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            acccion: 'edit',
            id: '',
            nombre: '',
            descripcion: '',
            version: '',
            marca: '',
            mercado: '',
            nombre_corto: '',
            imagen: '',
            alert: false,
            alerterror: false,
            vertical: 'top',
            horizontal: 'right',
            mesagge: ''
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleCerrar = this.handleCerrar.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.obtenerNombreUlrImg = this.obtenerNombreUlrImg.bind(this);
        this.handleCloseMesagge = this.handleCloseMesagge.bind(this);
        this.formRef = null;
    }
    componentDidMount() {
        api.post('listProductid', this.props.identificador)
            .then(response => response.data)
            .then(data => {
                console.log(data)
                this.setState(data);
            });
    }
    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleCerrar = () => {
        this.setState({ open: false });
    }
    handleChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        })
    }
    handleSubmit(event) {
        api.post("UpdateProduct", this.state).then((response) => {
            var result = response.data;
            if (result.status === 200) {
                this.setState({ alert: true, mesagge: result.message });
                // this.props.identificador.url();
                this.name.value = "";
                setTimeout(() => {
                    this.setState({
                        open: false, id: '',
                        nombre: '',
                        descripcion: '',
                        version: '',
                        marca: '',
                        mercado: '',
                        nombre_corto: '',
                        imagen: '',
                    });
                }, 7000);
            }
            else
                this.setState({ alerterror: true, mesagge: result.message });
        });
    }
    obtenerNombreUlrImg(e) {
        this.setState({
            nombre_corto: e.file,
            imagen: e.path
        });
    }
    handleCloseMesagge = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({ alert: false, alerterror: false });
    };
    onSubmit = async (values) => {
        console.log(values)
    }

    render() {
        const { fullScreen } = this.props;
        const { horizontal, vertical } = this.state;
        return (
            <div>
                <Card>
                    <CardContent>
                        <Snackbar open={this.state.alert} autoHideDuration={6000} onClose={this.handleCloseMesagge} anchorOrigin={{ vertical, horizontal }}>
                            <Alert onClose={this.handleCloseMesagge} severity="success">
                                {this.state.mesagge}
                            </Alert>
                        </Snackbar>
                        <Snackbar open={this.state.alerterror} autoHideDuration={6000} onClose={this.handleCloseMesagge} anchorOrigin={{ vertical, horizontal }}>
                            <Alert onClose={this.handleCloseMesagge} severity="error">
                                {this.state.mesagge}
                            </Alert>
                        </Snackbar>

                        <form ref={(ref) => this.formRef = ref}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField id="nombre" name="nombre" onChange={this.handleChange} value={this.state.nombre}
                                        label="Nombre del producto" type="text" margin="dense" fullWidth required variant="outlined"
                                        inputRef={el => (this.name = el)} />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField id="descripcion" label="Descripción" value={this.state.descripcion} name="descripcion" onChange={this.handleChange}
                                        type="text" margin="dense" fullWidth required variant="outlined"
                                        inputRef={el => (this.name = el)} />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField id="marca" label="Proveedor" value={this.state.marca} name="marca" onChange={this.handleChange} type="text" margin="dense" fullWidth required variant="outlined"
                                        inputRef={el => (this.name = el)} />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField id="mercado" label="Aplicación" value={this.state.mercado} name="mercado" onChange={this.handleChange} type="text" margin="dense" fullWidth required variant="outlined"
                                        inputRef={el => (this.name = el)} />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField id="version" label="Versión" value={this.state.version} name="version" onChange={this.handleChange} type="text" margin="dense" fullWidth required variant="outlined"
                                        inputRef={el => (this.name = el)} />
                                </Grid>
                                <Grid item xs={6}>
                                    <IMGSYSTEM name={this.obtenerNombreUlrImg} />
                                </Grid>
                                <Grid item xs={12}> <Avatar alt={this.state.nombre} src={'http://localhost:3005/' + this.state.nombre_corto} style={{ width: "60", height: "60" }} /></Grid>
                                <Grid item xs={10}>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button variant="outlined" color="primary" onClick={this.handleSubmit}>Guardar</Button>
                                </Grid>
                            </Grid>
                        </form>

                    </CardContent>
                </Card>
            </div>
        );
    }
}

ResponsiveDialog.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
};

export default withMobileDialog()(ResponsiveDialog);

