import React from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { IconButton, Button, OutlinedInput, InputLabel, InputAdornment, FormControl, Grid, } from '@material-ui/core'
import api from '../../../app/Config/api';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert'
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default class CustomRulesFormExample extends React.Component {
    constructor(props) {
        super(props);
        if (!ValidatorForm.hasValidationRule('isPasswordMatch')) {
            ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
                const { formData } = this.state;
                if (value !== formData.newpassword) {
                    return false;
                }
                return true;
            });
        }

        this.state = {
            formData: {
                password: '',
                newpassword: '',
                repeatPassword: '',
            },
            input: {},
            submitted: false,
            visibility: false,
            message: ''
        };
    }

    componentWillUnmount() {
        if (ValidatorForm.hasValidationRule('isPasswordMatch')) {
            ValidatorForm.removeValidationRule('isPasswordMatch');
        }
    }

    handleChange = (event) => {
        const { formData } = this.state;
        formData[event.target.name] = event.target.value;
        if (event.target.name === 'newpassword') {
            this.form.isFormValid(false);
        }
        this.setState({ formData });
    }

    handleSubmit = () => {

        const user = JSON.parse(localStorage.getItem("id_token"));
        var object = {
            user: user,
            params: this.state.formData
        }
        this.setState({ message: 'i.data.message', visibility: true, });
        this.setState({ submitted: true }, () => {
            api.post('UpdateUserPassword', object).then((i) => {
                if (i.data.status === 200) {
                    const { formData } = this.state;
                    formData.password = '';
                    formData.newpassword = '';
                    formData.repeatPassword = '';
                    this.setState({ message: i.data.message, visibility: true, submitted: false });
                }
            });
            //setTimeout(() => this.setState({ submitted: false }), 5000);
        });
    }
    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ visibility: false, });
    };
    render() {
        const { formData, submitted } = this.state;
        return (<>
            <div className="card" data-select2-id="48">
                <div className="card-body" data-select2-id="47">
                    <ValidatorForm ref={r => (this.form = r)} onSubmit={this.handleSubmit}>
                        <Snackbar open={this.state.visibility} autoHideDuration={6000} onClose={this.handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                            <Alert onClose={this.handleClose} severity="success">
                                {this.state.message}
                            </Alert>
                        </Snackbar>
                        <div className="form-group row">
                            <div className="col-lg-3">
                                <TextValidator label="Contraseña actual" autoComplete="off" className="form-control" type="password" validators={['required']} onChange={this.handleChange} name="password" value={formData.password}
                                    errorMessages={['Este campo es requerido']} variant="outlined" fullWidth margin="dense"
                                />

                            </div>
                            <div className="col-lg-3">
                                <TextValidator label="Nueva contraseña" className="form-control" onChange={this.handleChange} name="newpassword" type="password" validators={['required']}
                                    errorMessages={['Este campo es requerido']} value={formData.newpassword} variant="outlined" fullWidth margin="dense" />


                            </div>
                            <div className="col-lg-3">
                                <TextValidator label="Confirmación de la contraseña" className="form-control" onChange={this.handleChange} name="repeatPassword" type="password"
                                    validators={['isPasswordMatch', 'required']} errorMessages={['La contraseña no coincide', 'Este campo es requerido']}
                                    value={formData.repeatPassword} variant="outlined" fullWidth margin="dense" />

                            </div>
                            <div className="text-right">
                                <button type="submit" className="btn btn-primary" disabled={submitted}>{
                                    (submitted && 'Su información ha sido enviada!')
                                    || (!submitted && 'Guardar')
                                }
                                    <i className="icon-paperplane ml-2"></i></button>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-lg-12">
                                <strong>Nota:</strong> Todos los datos se envian cifrados al sistema, los administradores no tienen acceso a los datos planos. La segurídad del cliente es nuestro trabajo. Aplicativo 100% seguro.
                            </div>
                        </div>
                    </ValidatorForm>
                </div>
            </div>
        </>
        );
    }
}

/****
 *
 *  <Grid container spacing={3} xl={12}>
                <Grid item lg={4} md={4} xl={4} xs={4}>

                </Grid>
                <Grid item lg={4} md={4} xl={4} xs={4}>

                </Grid>
                <Grid item lg={4} md={4} xl={4} xs={4}>

                </Grid>
                <Grid item lg={4} md={4} xl={4} xs={4}><Button color="primary" variant="contained" type="submit" disabled={submitted} >
                    {
                        (submitted && 'Su información ha sido enviada!')
                        || (!submitted && 'Guardar')
                    }
                </Button></Grid>
            </Grid>
 *
 */
