import React from "react";
import Panels from "../../app/Panel/index";
export default function Dashboard(props) {

  return (
    <>
      <Panels />
    </>
  );
}

