import React, { Component } from 'react'
import api from '../app/Config/audit-data';
import TaxComplace from './details/tax-complace';
import BlackListAll from './details/black-list-data';
import VendorsAll from './details/vendors-to-all';
import FormImportFiles from './details/efirm-import';
import ConfigVendors from './details/configure-vendors';
import ReportFinancial from './details/report-financial';
import { Tooltip } from "@material-ui/core";
import { url3004 } from '../app/Config/urls';
export default class BuroV2 extends Component {
    constructor(props) {
        super(props)
        let data_ = JSON.parse(localStorage.getItem("datavalidate"));
        var token_id = JSON.parse(localStorage.getItem('id_token'));
        ///console.log(token_id, '................................................................')
        this.state = {
            perfil: token_id.perfil,
            dt_opinion: [],
            dt_blacklist: [],
            number_opinion: 0,
            number_blacklist: 0,
            viewopinion: false,
            viewblacklist: false,
            viewvendors: false,
            viewimportfiles: false,
            viewConfigVendors: false,
            viewReportsFinancials: false,
            subtitle: '',
            list_invoce: [],
            nombreempresa: data_.nombre,
            empresa_id: data_.id,
            link: url3004 + "report-pdf-buro-page/" + data_.id
        }
    }
    componentDidMount() {
        this.handleViewErrors();
    }
    handleViewErrors = async () => {
        let data_ = await api.post("report-vendors-all", { empresa_id: this.state.empresa_id });
        let _result_ = await data_.data;
        this.setState({
            number_opinion: _result_.opinion_negative,
            number_blacklist: _result_.blacklist_,
            dt_opinion: _result_.details_tax,
            dt_blacklist: _result_.company_black_list,
            list_invoce: _result_.invocelist,
        });
    }
    handleViewErrorsOpinion = async (f) => {
        this.setState({
            subtitle: f,
            viewopinion: true,
            viewblacklist: false,
            viewvendors: false,
            viewimportfiles: false,
            viewConfigVendors: false,
        });
    }
    handleViewErrorsBlacklist = async (f) => {
        this.setState({
            subtitle: f,
            viewopinion: false,
            viewblacklist: true,
            viewvendors: false,
            viewimportfiles: false,
            viewConfigVendors: false,
        });
    }
    handleViewListApp = async (f) => {
        this.setState({
            subtitle: f,
            viewopinion: false,
            viewblacklist: false,
            viewvendors: true,
            viewimportfiles: false,
            viewConfigVendors: false,
        });
    }
    handleViewListImportFiles = async (f) => {
        this.setState({
            subtitle: f,
            viewopinion: false,
            viewblacklist: false,
            viewvendors: false,
            viewimportfiles: true,
            viewConfigVendors: false,
            viewReportsFinancials: false,
        });
    }
    handleViewListConfigVendors = async (f) => {
        this.setState({
            subtitle: f,
            viewopinion: false,
            viewblacklist: false,
            viewvendors: false,
            viewimportfiles: false,
            viewConfigVendors: true,
            viewReportsFinancials: false,
        });
    }
    handleViewReportAll = async () => {
        this.setState({
            link: url3004 + "report-pdf-buro-page/" + this.state.empresa_id
        });

        window.$("#modalfullreport").modal("show");
    }
    handleClearAll = async (d) => {
        this.setState({
            subtitle: '',
            viewopinion: false,
            viewblacklist: false,
            viewvendors: false,
            viewimportfiles: false,
            viewConfigVendors: false,
            link: ''
        });
    }
    handleViewListReportsFinancial = async (r) => {
        this.setState({
            subtitle: r,
            viewopinion: false,
            viewblacklist: false,
            viewvendors: false,
            viewimportfiles: false,
            viewConfigVendors: false,
            link: '',
            viewReportsFinancials: true,
        });
    }
    render() {
        return (
            <div className="content-wrapper">
                {/* Inner content */}
                <div className="content-inner">
                    <div className="page-header border-bottom-0">
                        <div className="page-header-content header-elements-md-inline">
                            <div className="page-title d-flex">
                                <h4><i className="icon-home4 mr-2" /> <span className="font-weight-semibold">Inicio</span></h4>
                            </div>
                            <div className="card-header">
                                <h6 className="card-title text-center" style={{ color: "white" }}>{this.state.nombreempresa}</h6>
                            </div>
                            <div className="header-elements d-none mb-3 mb-lg-0">
                                <div className="d-flex justify-content-center">
                                    <button onClick={() => this.handleViewListImportFiles("Configuración de la e-firma")} className="btn btn-link btn-float text-body"><i className="icon-file-upload" /><span>Importación</span></button>
                                    <button onClick={() => this.handleViewListConfigVendors("Configuración de proveedores / clientes")} className="btn btn-link btn-float text-body"><i className="icon-cog7" /> <span>Configuración</span></button>
                                    <button onClick={this.handleViewReportAll} className="btn btn-link btn-float text-body"><i className="icon-file-pdf" /> <span>Reporte</span></button>
                                </div>
                            </div>

                        </div>
                    </div>
                    {
                        (this.state.perfil === "FINANCIERA") ? (
                            <div className="content pt-0">
                                <div className="card">
                                    <div className="card-body">
                                        {
                                            (this.state.viewConfigVendors === true || this.state.viewblacklist === true || this.state.viewimportfiles ===
                                                true || this.state.viewopinion === true || this.state.viewvendors === true || this.state.viewReportsFinancials === true) ? (
                                                <div className="mb-3">
                                                    <div className="page-header page-header-light">
                                                        <div className="page-header-content">
                                                            <div className="title">
                                                                <table style={{ width: "100%" }}>
                                                                    <tr>
                                                                        <td style={{ width: "33.3%" }}>
                                                                            <h5>
                                                                                <button className='btn tbtn-link' onClick={() => this.setState({
                                                                                    subtitle: '',
                                                                                    viewopinion: false,
                                                                                    viewblacklist: false,
                                                                                    viewvendors: false,
                                                                                    viewimportfiles: false,
                                                                                    viewConfigVendors: false,
                                                                                    viewReportsFinancials: false
                                                                                })}>
                                                                                    <i className="icon-arrow-left52 mr-2" />
                                                                                    <span className="font-weight-semibold">Panel</span>
                                                                                </button>

                                                                            </h5></td>
                                                                        <td style={{ width: "33.3%" }}><h5>
                                                                            <small className="d-block  text-center">{this.state.subtitle}</small>
                                                                        </h5></td>
                                                                        <td style={{ width: "33.3%" }}></td>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null
                                        }
                                    </div>
                                </div>
                                {(this.state.viewConfigVendors === false && this.state.viewblacklist === false
                                    && this.state.viewimportfiles === false && this.state.viewopinion === false
                                    && this.state.viewvendors === false && this.state.viewReportsFinancials === false) ? (
                                    <>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <i className="icon-reading icon-2x text-teal border-teal-100 border-3 rounded-pill p-3 mb-3 mt-1" />
                                                        <h5 className="card-title">Listado de clientes, proveedores / reportes financieros</h5>
                                                        <p className="mb-3 text-muted">Reportes de posición financiera y estado de resultados de los últimos 5 años por cliente / proveedor.</p>
                                                        <button style={{ cursor: "pointer" }} onClick={() => this.handleViewListReportsFinancial("Listado reportes financieros")} className="btn btn-success">Ver</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        {
                                                            (this.state.number_opinion) > 0 ? (
                                                                <i className={"icon-seven-segment-" + this.state.number_opinion + " icon-2x text-danger border-danger-100 border-3 rounded-pill p-3 mb-3 mt-1"} />
                                                            ) :
                                                                <i className={"icon-stack-check icon-2x text-success border-success-100 border-3 rounded-pill p-3 mb-3 mt-1"} />
                                                        }
                                                        <h5 className="card-title">Opinión de cumplimiento "SAT"</h5>
                                                        <p className="mb-3 text-muted">Proveedores / clientes con opinión negativa.</p>
                                                        <br />
                                                        {
                                                            (this.state.number_opinion) > 0 ? (
                                                                <button type="button" onClick={() => this.handleViewErrorsOpinion("Listado de opinión")} className="btn btn-danger">
                                                                    <Tooltip title="Clic para ver a los proveedores con opninión negativa / positiva." placement="top" arrow>
                                                                        <span>Ver detalle</span>
                                                                    </Tooltip>
                                                                </button>
                                                            ) :
                                                                <button type="button" className="btn btn-warning"><Tooltip title="Aún no existe información para mostrar" placement="top" arrow><span>Ver detalle</span></Tooltip> </button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        {
                                                            (this.state.number_blacklist > 0) ? (
                                                                <i className={"icon-seven-segment-" + this.state.number_blacklist + " icon-2x text-danger border-danger-100 border-3 rounded-pill p-3 mb-3 mt-1"} />
                                                            ) :
                                                                <i className={"icon-users4 icon-2x text-success border-success-100 border-3 rounded-pill p-3 mb-3 mt-1"} />
                                                        }
                                                        <h5 className="card-title">Publicados en el "69-B"</h5>
                                                        {
                                                            (this.state.number_blacklist > 0) ? (
                                                                <>
                                                                    <p className="mb-1 text-muted" style={{ textAlign: 'justify' }}>Se presume que realizaron operaciones con proveedores inexistentes amparadas en comprobantes fiscales.</p>
                                                                    <button type="button" onClick={() => this.handleViewErrorsBlacklist("Publicados en el '69-B'")} className="btn btn-danger">
                                                                        <Tooltip title="Clic para ver a los proveedores con 69B." placement="top" arrow><span>Ver detalle</span></Tooltip>
                                                                    </button>
                                                                </>

                                                            ) : (
                                                                <>
                                                                    <p className="mb-1 text-muted">No existen proveedores que hayan realizado operaciones con contribuyentes del 69-B.</p>
                                                                    <button type="button" className="btn btn-warning">Ver detalle</button>
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*<div className='row'>
                                            <div className="col-lg-6">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <i className="icon-reading icon-2x text-teal border-teal-100 border-3 rounded-pill p-3 mb-3 mt-1" />
                                                        <h5 className="card-title">Listado de clientes / proveedores</h5>
                                                        <p className="mb-3 text-muted">Catálogo de proveedores / clientes. Prodrá descargar el reporte.</p>
                                                        <button style={{ cursor: "pointer" }} onClick={() => this.handleViewListApp("Listado de clientes / proveedores")} className="btn btn-success">Ver</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>*/}
                                    </>
                                ) : null}
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        {(this.state.viewopinion) ? (<><TaxComplace data={this.state.dt_opinion} /></>) : null}
                                    </div>
                                    <div className='col-lg-12'>
                                        {this.state.viewblacklist ? (<><BlackListAll data={this.state.dt_blacklist} listblack={this.state.list_invoce} /></>) : null}
                                    </div>
                                    <div className='col-lg-12'>
                                        {this.state.viewvendors ? (<><VendorsAll /></>) : null}
                                    </div>
                                    <div className='col-lg-12'>
                                        {this.state.viewimportfiles ? (<><FormImportFiles /></>) : null}
                                    </div>
                                    <div className='col-lg-12'>
                                        {this.state.viewConfigVendors ? (<><ConfigVendors /></>) : null}
                                    </div>
                                    <div className='col-lg-12'>
                                        {this.state.viewReportsFinancials ? (<><ReportFinancial /></>) : null}
                                    </div>
                                </div>
                                <div style={{ height: 40 }}></div>
                            </div>
                        ) : (
                            <div className="content pt-0">
                                <div className="card">
                                    <div className="card-body">
                                        {
                                            (this.state.viewConfigVendors === true || this.state.viewblacklist === true || this.state.viewimportfiles ===
                                                true || this.state.viewopinion === true || this.state.viewvendors === true) ? (
                                                <div className="mb-3">
                                                    <div className="page-header page-header-light">
                                                        <div className="page-header-content">
                                                            <div className="title">
                                                                <table style={{ width: "100%" }}>
                                                                    <tr>
                                                                        <td style={{ width: "33.3%" }}>
                                                                            <h5>
                                                                                <button className='btn tbtn-link' onClick={() => this.setState({
                                                                                    subtitle: '',
                                                                                    viewopinion: false,
                                                                                    viewblacklist: false,
                                                                                    viewvendors: false,
                                                                                    viewimportfiles: false,
                                                                                    viewConfigVendors: false,
                                                                                })}>
                                                                                    <i className="icon-arrow-left52 mr-2" />
                                                                                    <span className="font-weight-semibold">Panel</span>
                                                                                </button>

                                                                            </h5></td>
                                                                        <td style={{ width: "33.3%" }}><h5>
                                                                            <small className="d-block  text-center">{this.state.subtitle}</small>
                                                                        </h5></td>
                                                                        <td style={{ width: "33.3%" }}></td>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null
                                        }
                                    </div>
                                </div>
                                {
                                    (this.state.viewConfigVendors === false && this.state.viewblacklist === false && this.state.viewimportfiles === false && this.state.viewopinion === false && this.state.viewvendors === false) ? (
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        {
                                                            (this.state.number_opinion) > 0 ? (
                                                                <i className={"icon-seven-segment-" + this.state.number_opinion + " icon-2x text-danger border-danger-100 border-3 rounded-pill p-3 mb-3 mt-1"} />
                                                            ) :
                                                                <i className={"icon-stack-check icon-2x text-success border-success-100 border-3 rounded-pill p-3 mb-3 mt-1"} />
                                                        }
                                                        <h5 className="card-title">Opinión de cumplimiento "SAT"</h5>
                                                        <p className="mb-3 text-muted">Proveedores con opinión negativa.</p>
                                                        <br />
                                                        {
                                                            (this.state.number_opinion) > 0 ? (
                                                                <button type="button" onClick={() => this.handleViewErrorsOpinion("Listado de opinión")} className="btn btn-danger">Ver detalle</button>
                                                            ) :
                                                                <button type="button" onClick={() => this.handleViewErrorsOpinion("Listado de opinión")} className="btn btn-success">Ver detalle</button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        {
                                                            (this.state.number_blacklist > 0) ? (
                                                                <i className={"icon-seven-segment-" + this.state.number_blacklist + " icon-2x text-danger border-danger-100 border-3 rounded-pill p-3 mb-3 mt-1"} />
                                                            ) :
                                                                <i className={"icon-users4 icon-2x text-success border-success-100 border-3 rounded-pill p-3 mb-3 mt-1"} />
                                                        }
                                                        <h5 className="card-title">Publicados en el "69-B"</h5>
                                                        {
                                                            (this.state.number_blacklist > 0) ? (
                                                                <>
                                                                    <p className="mb-1 text-muted" style={{ textAlign: 'justify' }}>Se presume que realizaron operaciones con proveedores inexistentes amparadas en comprobantes fiscales.</p>
                                                                    <button type="button" onClick={() => this.handleViewErrorsBlacklist("Publicados en el '69-B'")} className="btn btn-danger">Ver detalle</button>

                                                                </>

                                                            ) : (
                                                                <>
                                                                    <p className="mb-1 text-muted">No existen proveedores que hayan realizado operaciones con contribuyentes del 69-B.</p>

                                                                    <button type="button" className="btn btn-warning">Ver detalle</button>
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <i className="icon-reading icon-2x text-teal border-teal-100 border-3 rounded-pill p-3 mb-3 mt-1" />
                                                        <h5 className="card-title">Listado de proveedores</h5>
                                                        <p className="mb-3 text-muted">Catálogo de proveedores / clientes, obtenido de los cfdis.</p>
                                                        <button style={{ cursor: "pointer" }} onClick={() => this.handleViewListApp("Listado de proveedores")} className="btn btn-success">Ver</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null
                                }
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        {this.state.viewopinion ? (<><TaxComplace data={this.state.dt_opinion} /></>) : null}
                                    </div>
                                    <div className='col-lg-12'>
                                        {this.state.viewblacklist ? (<><BlackListAll data={this.state.dt_blacklist} listblack={this.state.list_invoce} /></>) : null}
                                    </div>
                                    <div className='col-lg-12'>
                                        {this.state.viewvendors ? (<><VendorsAll /></>) : null}
                                    </div>
                                    <div className='col-lg-12'>
                                        {this.state.viewimportfiles ? (<><FormImportFiles /></>) : null}
                                    </div>
                                    <div className='col-lg-12'>
                                        {this.state.viewConfigVendors ? (<><ConfigVendors /></>) : null}
                                    </div>
                                </div>
                            </div >
                        )
                    }

                    <div id="modalfullreport" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                        <div className="modal-dialog modal-full">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Constancia de cumplimiento</h5>
                                    <button type="button" className="close" data-dismiss="modal" onClick={this.handleClearAll}>×</button>
                                </div>
                                <div className="modal-body">
                                    <div className="embed-container">
                                        <iframe src={this.state.link}
                                            title="Constancia de cumplimientos" width="100%"
                                            frameBorder="0" allowFullScreen />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

