import React, { Component } from 'react';
import api from '../../../app/Config/api';
import { Tooltip, } from "@material-ui/core";
class Config69B extends Component {
    constructor(props) {
        super(props);
        let data_costumer = JSON.parse(localStorage.getItem("data_costumer"));
        this.state = {
            recordset: [],
            cliente_id: data_costumer.cliente_id,
            razon_social: data_costumer.razon_social
        }
    }
    componentDidMount() {
        this.getinfo();
    }
    getinfo = async () => {
        let _resultado_consulta_ = await api.post("getfilterdata69b", { cliente_id: this.state.cliente_id });
        let { data } = await _resultado_consulta_;

        this.setState({ recordset: data });
    }
    render() {
        return (
            <>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Nombre / razon social</th>
                            <th style={{ textAlign: 'center' }}>Configuración</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (this.state.recordset || []).map((i, j) => (
                                <tr key={j}>
                                    <td>
                                        {i.nombre}
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                        {(i.status === "0" ? (<Tooltip title="No se ha configurado si la empresa va importar o descargar la metadata." placement="top" ><i className="icon-warning22 mr-3 icon-2x text-danger"></i></Tooltip>) : (<i className="icon-checkmark4 mr-3 icon-2x text-success"></i>))}
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

            </>
        );
    }
}

export default Config69B;