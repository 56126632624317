import React, { Component } from 'react';
import api from '../../../Config/apifiles';
import { Tooltip, } from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
import ReactJson from 'react-json-view';
import ReactDatatable from '@ashvin27/react-datatable';
import { PacmanLoader } from "react-spinners";
function orderbydate(a, b) {
    return new Date(a.fecha).getTime() - new Date(b.fecha).getTime();
}
function validate_type(t) {
    switch (t) {
        case "003":
            return "Localizado en definitivo";
            break;
        case "002":
            return "Localizado en presuntos";
            break;
        case "001":
            return "Localizado la lista 'No localizados'";
            break;
        case "004":
            return "Localizado en desvirtuado'";
            break;
        default:
            break;
    }
}
///Emitido
class Cast_Work_Paper extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "fecha",
                text: "Fecha",
                className: "name",
                cell: record => {
                    return (
                        <div>
                            {record.alert.length > 0 ? (
                                <>
                                    <Tooltip title={validate_type(record.alert[0].tipo)} placement="top" arrow>
                                        <div className={"text-center text-danger"} style={{ cursor: 'pointer' }}>
                                            {record.fecha.substr(0, 10)}
                                        </div>
                                    </Tooltip>
                                </>
                            ) : <div className={"text-center"}>
                                {record.fecha.substr(0, 10)}
                            </div>
                            }
                        </div>
                    );
                }
            },
            {
                key: "tipo_de_comprobante",
                text: "Clase CFDI",
                cell: record => {
                    return (
                        <div>
                            {record.alert.length > 0 ? (
                                <>
                                    <Tooltip title={validate_type(record.alert[0].tipo)} placement="top" arrow>
                                        <div className={"text-center text-danger"} style={{ cursor: 'pointer' }}>
                                            {tipoComprobante(record.tipo_de_comprobante)}
                                        </div>
                                    </Tooltip>
                                </>
                            ) : <div className={"text-center"}>
                                {tipoComprobante(record.tipo_de_comprobante)}
                            </div>
                            }
                        </div>
                    );
                }
            },
            {
                key: "import",
                text: "Subtotal",
                className: "postcode",
                cell: y => {
                    return (
                        <div>
                            {y.alert.length > 0 ? (
                                <>
                                    <Tooltip title={validate_type(y.alert[0].tipo)} placement="top" arrow>
                                        <div className={"text-center text-danger"} style={{ cursor: 'pointer' }}>
                                            {(y.import < 0 ? (
                                                <span className="text-danger">({y.import.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1, ')})</span>
                                            ) : <>{y.import.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1, ')}</>
                                            )}
                                        </div>
                                    </Tooltip>
                                </>
                            ) : <div className={"text-center"}>
                                {(y.import < 0 ? (
                                    <span className="text-danger">({y.import.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1, ')})</span>
                                ) : <>{y.import.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1, ')}</>
                                )}
                            </div>
                            }
                        </div>
                    );
                }
            },
            {
                key: "import",
                text: "Descuento",
                className: "postcode",
                cell: y => {
                    return (
                        <div>
                            {y.alert.length > 0 ? (
                                <>
                                    <Tooltip title={validate_type(y.alert[0].tipo)} placement="top" arrow>
                                        <div className={"text-center text-danger"} style={{ cursor: 'pointer' }}>
                                            {(y.import_descuento < 0 ? (
                                                <span className="text-danger">({y.import_descuento.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1, ')})</span>
                                            ) : <>{y.import_descuento.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1, ')}</>
                                            )}
                                        </div>
                                    </Tooltip>
                                </>
                            ) : <div className={"text-center"}>
                                {(y.import_descuento < 0 ? (
                                    <span className="text-danger">({y.import_descuento.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1, ')})</span>
                                ) : <>{y.import_descuento.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1, ')}</>
                                )}
                            </div>
                            }
                        </div>
                    );
                }
            },
            {
                key: "neto_",
                text: "Neto",
                className: "postcode",
                cell: y => {
                    return (
                        <div>
                            {y.alert.length > 0 ? (
                                <>
                                    <Tooltip title={validate_type(y.alert[0].tipo)} placement="top" arrow>
                                        <div className={"text-center text-danger"} style={{ cursor: 'pointer' }}>
                                            {(y.neto_ < 0 ? (
                                                <span className="text-danger">({y.neto_.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1, ')})</span>
                                            ) : <>{y.neto_.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1, ')}</>
                                            )}
                                        </div>
                                    </Tooltip>
                                </>
                            ) : <div className={"text-center"}>
                                {(y.neto_ < 0 ? (
                                    <span className="text-danger">({y.neto_.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1, ')})</span>
                                ) : <>{y.neto_.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1, ')}</>
                                )}
                            </div>
                            }
                        </div>
                    );
                }
            },
            {
                key: "receptor",
                text: "RFC     -     Nombre",
                className: "action",
                cell: y => {
                    return (
                        <div>
                            {y.alert.length > 0 ? (
                                <>
                                    <Tooltip title={validate_type(y.alert[0].tipo)} placement="top" arrow>
                                        <div className={"text-center text-danger"} style={{ cursor: 'pointer' }}>
                                            {dataNameEmisor(y.receptor)}
                                        </div>
                                    </Tooltip>
                                </>
                            ) : <div className={"text-center"}>
                                {dataNameEmisor(y.receptor)}
                            </div>
                            }
                        </div>
                    );
                }
            },

            {
                cell: y => {
                    return (
                        <div>
                            {y.alert.length > 0 ? (
                                <>
                                    <Tooltip title={validate_type(y.alert[0].tipo)} placement="top" arrow>
                                        <div className="text-center">
                                            <a style={{ cursor: 'pointer' }} onClick={() => this.viewpdfcfdi_(y)}><Tooltip title="Click para ver cfdi completo" placement="top" arrow><span className="badge badge-flat  text-danger"><i className="icon-file-pdf"></i></span></Tooltip></a>
                                            &nbsp;
                                            <a style={{ cursor: 'pointer' }} onClick={() => this.handleLoadXML(y)}><Tooltip title="Click para ver el xml completo" placement="top" arrow><span className="badge badge-flat  text-danger"><i className="icon-file-xml"></i></span></Tooltip></a>
                                        </div>
                                    </Tooltip>
                                </>
                            ) : <div className="text-center">
                                <a style={{ cursor: 'pointer' }} onClick={() => this.viewpdfcfdi_(y)}><Tooltip title="Click para ver cfdi completo" placement="top" arrow><span className="badge badge-flat  text-success"><i className="icon-file-pdf"></i></span></Tooltip></a>
                                &nbsp;
                                <a style={{ cursor: 'pointer' }} onClick={() => this.handleLoadXML(y)}><Tooltip title="Click para ver el xml completo" placement="top" arrow><span className="badge badge-flat  text-success"><i className="icon-file-xml"></i></span></Tooltip></a>
                            </div>
                            }
                        </div>

                    );
                }
            }
        ];
        this.config = {
            "page_size": 50,
            "length_menu": [50, 70, 90],
            "show_filter": true,
            "show_pagination": true,
            "button": {
                "excel": false,
                "print": false,
                "extra": false
            },
            "language": {
                "length_menu": "Mostrar _MENU_ registros por página",
                "filter": "Buscar en registros ...",
                "info": "Mostrando _START_ a _END_ de _TOTAL_ entradas",
                "pagination": {
                    "first": "Primero",
                    "previous": "Previa",
                    "next": "próximo",
                    "last": "Última"
                }
            }
        };


        let objects = JSON.parse(localStorage.getItem("datacompanyall"));
        this.state = {
            empresa_id: objects.id,
            rfc: objects.rfc,
            exercise: parseInt(this.props.data.ejercicio),
            period: this.props.data.periodo,
            data: [],
            data_all: [],
            link: '',
            uuid: '',
            loading: false,
            loadingfirts: true,
            subtotal: 0,
            descuento: 0,
            descuentoegreso: 0,
            cfdi: '',
            tipofilter: '',
            subtotalegreso: 0,
            url: "https://e-xpertum.com.mx/adminfile/api/get-export-cfdi-excel/EMITIDO/" + objects.id + "@" + parseInt(this.props.data.ejercicio) + "@" + this.props.data.periodo + "@ALL"
        }
    }
    componentDidMount() {
        this.list_array_filter_exercise_period_();
    }
    list_array_filter_exercise_period_ = async () => {
        let _data_ = await api.post('cfdis-emitidos-ejercicio-periodo', { empresa_id: this.state.empresa_id, ejercicio: this.state.exercise, periodo: this.state.period });
        let _b69list_ = await api.post("app-relations-69b-exercise-period-costumer",
            { empresa_id: this.state.empresa_id, ejercicio: this.state.exercise, periodo: this.state.period })
        let filter_b69list_ = _b69list_.data;

        let _result_data_ = await (_data_.data).filter((tf) => (tf.tipo_de_comprobante === 'I' || tf.tipo_de_comprobante === 'egreso' || tf.tipo_de_comprobante === 'E' || tf.tipo_de_comprobante === 'ingreso'));
        let sumsubtotal = 0, sumdescuento = 0, subtotalegresos = 0, sumneto = 0, descuentoegreso = 0;
        for (let p = 0; p < _result_data_.length; p++) {
            _result_data_[p].neto_ = 0;
            if (_result_data_[p].tipo_de_comprobante === 'E' || _result_data_[p].tipo_de_comprobante === 'egreso') {
                _result_data_[p].import = (_result_data_[p].sub_total * -1);
                _result_data_[p].sub_total = (_result_data_[p].sub_total);
                _result_data_[p].descuento = (_result_data_[p].descuento * -1);
                _result_data_[p].import_descuento = (_result_data_[p].descuento);
                _result_data_[p].neto_ = (_result_data_[p].sub_total * -1);
                subtotalegresos = subtotalegresos + _result_data_[p].sub_total;
                descuentoegreso = descuentoegreso + _result_data_[p].descuento;
            }
            else {
                _result_data_[p].import = _result_data_[p].sub_total;
                _result_data_[p].import_descuento = (_result_data_[p].descuento);
                _result_data_[p].neto_ = (_result_data_[p].sub_total - (_result_data_[p].descuento));
                sumsubtotal = sumsubtotal + (_result_data_[p].sub_total);
                sumneto = sumneto + (_result_data_[p].sub_total - (_result_data_[p].descuento));
                sumdescuento = sumdescuento + _result_data_[p].descuento;
            }

            _result_data_[p].alert = ((filter_b69list_).filter((fds) => String(fds.uuid).toUpperCase() == String(_result_data_[p].uuid).toUpperCase()))



        }
        this.setState({
            data_all: _result_data_,
            data: _result_data_.sort(orderbydate), loadingfirts: false,
            descuento: sumdescuento,
            subtotal: sumsubtotal,
            subtotalegreso: subtotalegresos,
            sumneto: sumneto,
            descuentoegreso: descuentoegreso
        })
    }
    viewpdfcfdi_ = async (t) => {
        window.$('#viewpdfcfdi').modal('show');
        this.setState({
            loading: true
        })
        let _link_ = "";
        if (t.tipo_de_comprobante === "N")
            _link_ = 'https://e-xpertum.com.mx/adminfile/api/genera-pdf-cfdi-nomina/' + t.listado_cfdi_emitido_id + '/nomina';
        else
            _link_ = 'https://e-xpertum.com.mx/adminfile/api/genera_pdf_cfdi/' + t.listado_cfdi_emitido_id + '/EMITIDO&' + this.state.rfc


        this.setState({
            link: _link_,
            uuid: t.uuid
        });
    }
    handleLoading = async () => {
        this.setState({
            loading: false
        })
    }
    handleLoadXML = async (t) => {
        window.$("#viewxmlcfdi").modal("show")
        let _data_ = await api.post('genera_xml_cfdi', { id: t.listado_cfdi_emitido_id, tipo: 'EMITIDO' });
        this.setState({
            uuid: t.uuid,
            cfdi: <ReactJson src={JSON.parse(_data_.data)} enableClipboard={false} theme={"monokai"} displayDataTypes={false} displayObjectSize={false} />
        })

    }
    handleFilterretenciones = async (i) => {
        let _result_data_ = [];
        if (i === "EGRESOS")
            _result_data_ = (this.state.data_all).filter((t) => t.tipo_de_comprobante === 'E' || t.tipo_de_comprobante === 'egreso')
        if (i === "INGRESOS")
            _result_data_ = (this.state.data_all).filter((t) => t.tipo_de_comprobante === 'I' || t.tipo_de_comprobante === 'ingreso')

        let sumsubtotal = 0, sumdescuento = 0, subtotalegreso = 0;
        for (let p = 0; p < _result_data_.length; p++) {
            sumsubtotal = sumsubtotal + _result_data_[p].sub_total;
            sumdescuento = sumdescuento + _result_data_[p].descuento;
        }
        this.setState({
            data: _result_data_,
            /// descuento: sumdescuento,
            // subtotal: (i === "INGRESOS" ? sumsubtotal : 0.00),
            // tipofilter: i,
            //subtotalegreso: (i === "EGRESOS" ? sumsubtotal : 0.00),
            url: "https://e-xpertum.com.mx/adminfile/api/get-export-cfdi-excel/EMITIDO/" + this.state.empresa_id + "@" + (this.state.exercise) + "@" + this.state.period + "@" + i
        });
    }
    viewalldata = async () => {
        let _result_data_ = [];
        _result_data_ = (this.state.data_all);//.filter((t) => t.tipo_de_comprobante === 'I' || t.tipo_de_comprobante === 'ingreso')
        let sumsubtotal = 0, sumdescuento = 0, subtotalegreso = 0, sumneto = 0;
        for (let p = 0; p < _result_data_.length; p++) {
            ///if(_result_data_)
            ///console.log(_result_data_[p])
            if (_result_data_[p].tipo_de_comprobante === "I") {
                sumsubtotal = sumsubtotal + _result_data_[p].sub_total;
                sumneto = sumneto + _result_data_[p].sub_total - _result_data_[p].descuento;
            }
            else
                subtotalegreso = subtotalegreso + _result_data_[p].sub_total;

            sumdescuento = sumdescuento + _result_data_[p].descuento;
        }
        this.setState({
            data: _result_data_,
            descuento: sumdescuento,
            subtotal: sumsubtotal,
            tipofilter: '',
            subtotalegreso: subtotalegreso,
            sumneto: sumneto,
            url: "https://e-xpertum.com.mx/adminfile/api/get-export-cfdi-excel/EMITIDO/" + this.state.empresa_id + "@" + (this.state.exercise) + "@" + this.state.period + "@ALL"
        })
    }
    handleexportexcel = async () => {
        window.open('https://e-xpertum.com.mx/adminfile/api/test-read-cfdi/' + this.state.exercise + '-' + this.state.period + '/' + (this.state.tipofilter === '' ? 'TODOS' : this.state.tipofilter) + '/' + this.state.empresa_id);
    }
    render() {
        return (
            <>
                {
                    (this.state.loadingfirts) ?
                        (
                            <div>
                                <Skeleton variant="text" />
                                <div style={{
                                    margin: 40,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                    <PacmanLoader color={"#ff7043"} loading={this.state.loadingfirst} size={25} margin={2} />
                                </div>
                                <Skeleton variant="circle" animation="wave" width={40} height={40} />
                                <Skeleton variant="rect" animation="wave" width={"100%"} height={118} />
                            </div>
                        ) : (
                            <>
                                <table className="table" style={{ fontSize: 14 }}>
                                    <thead>
                                        <tr>
                                            <th>Tipo</th>
                                            <th style={{ textAlign: 'right' }}>Subtotal</th>
                                            <th style={{ textAlign: 'right' }}>Descuentos</th>
                                            <th style={{ textAlign: 'right' }}>Neto</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Ingresos: </td>
                                            <td style={{ textAlign: 'right' }}>{change_type(this.state.subtotal)}</td>
                                            <td style={{ textAlign: 'right' }}>{change_type(this.state.descuento)}</td>
                                            <td style={{ textAlign: 'right' }}>{change_type(this.state.sumneto)}</td>
                                        </tr>
                                        <tr>
                                            <td>Egresos:</td>
                                            <td style={{ textAlign: 'right' }}>{change_type(this.state.subtotalegreso)}</td>
                                            <td style={{ textAlign: 'right' }}>{change_type(this.state.descuentoegreso)}</td>
                                            <td style={{ textAlign: 'right' }}>{change_type(this.state.subtotalegreso)} </td>
                                        </tr>
                                        <tr>
                                            <td>Ingresos - Egresos:</td>
                                            <td></td>
                                            <td></td>
                                            <td style={{ borderBottomStyle: 'double', borderBottomWidth: 4, textAlign: 'right' }}>{change_type(this.state.sumneto - this.state.subtotalegreso)} </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="form-group">
                                    <div className="border p-3 rounded">
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input type="radio" className="custom-control-input" name="cr-i-l" id="cr_l_i_s" onClick={() => this.handleFilterretenciones('INGRESOS')} />
                                            <label className="custom-control-label" htmlFor="cr_l_i_s">Solo CFDIs Ingreso</label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input type="radio" className="custom-control-input" name="cr-i-l" id="cr_l_i_e" onClick={() => this.handleFilterretenciones('EGRESOS')} />
                                            <label className="custom-control-label" htmlFor="cr_l_i_e">Solo CFDIs Egreso</label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input type="radio" className="custom-control-input" name="cr-i-l" id="cr_l_i_u" defaultChecked onClick={this.viewalldata} />
                                            <label className="custom-control-label" htmlFor="cr_l_i_u">Todos los CFDIs</label>
                                        </div>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <a type="button" className="btn btn-light" href={this.state.url}><i className="icon-file-excel mr-2 text-success"></i> Excel</a>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <a type="button" className="btn btn-light" href={this.props.functionHandle} onClick={this.props.functionHandle}><i className="icon-file-xml mr-2 text-success"></i>Metadata</a>
                                    </div>
                                </div>

                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.data}
                                    columns={this.columns}
                                />

                            </>
                        )
                }

                <div id="viewpdfcfdi" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                    <div className="modal-dialog modal-full">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{this.state.uuid}</h5>
                                <button type="button" className="close" data-dismiss="modal" onClick={() => this.setState({ link: '' })}>×</button>
                            </div>
                            <div className="modal-body">
                                <div className="embed-container">
                                    {
                                        (this.state.loading) ?
                                            (
                                                <div>
                                                    <Skeleton variant="text" />
                                                    <Skeleton variant="circle" animation="wave" width={40} height={40} />
                                                    <Skeleton variant="rect" animation="wave" width={510} height={118} />
                                                </div>
                                            ) : null
                                    }
                                    <iframe src={this.state.link} onLoad={this.handleLoading}
                                        width="100%"
                                        frameBorder="0" allowFullScreen
                                    >
                                    </iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="viewxmlcfdi" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                    <div className="modal-dialog modal-full">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{this.state.uuid}</h5>
                                <button type="button" className="close" data-dismiss="modal" onClick={() => this.setState({ link: '' })}>×</button>
                            </div>
                            <div className="modal-body">
                                <div>
                                    {
                                        (this.state.loading) ?
                                            (
                                                <div>
                                                    <Skeleton variant="text" />
                                                    <Skeleton variant="circle" animation="wave" width={40} height={40} />
                                                    <Skeleton variant="rect" animation="wave" width={510} height={118} />
                                                </div>
                                            ) : null
                                    }
                                    {this.state.cfdi}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Cast_Work_Paper;
function tipoComprobante(key) {
    switch (key) {
        case 'I':
            return 'I - Ingreso'
            break;
        case 'E':
            return 'E - Egreso'
            break;
        case 'T':
            return 'T - Traslado'
            break;
        case 'P':
            return 'P - Recepción de Pagos'
            break;
        case 'N':
            return 'N - Nómina'
            break;
        case 'R':
            return 'Retenciones e Información de Pagos'
            break;
        default:
            return key
            break;
    }
}
function dataNameEmisor(op) {
    if (op != null && op != '') {
        let _infor_ = JSON.parse(op);
        return (
            <p style={{ fontSize: 10 }}>{(_infor_["@Rfc"] === undefined ? (_infor_["@rfc"] == undefined ? _infor_.rfc : _infor_["@rfc"]) : _infor_["@Rfc"])} - {(_infor_["@Nombre"] === undefined ? (_infor_["@nombre"] == undefined ? ((_infor_.name) != undefined ? (_infor_.name).substr(0, 40) : '') : _infor_["@nombre"]) : _infor_["@Nombre"])}</p>
        )
    } else
        return (<p></p>)
}
function change_type(valor) {
    if (valor < 0) { return <span className="text-danger">{valor.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span> }
    else { return <span>{valor.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span> }
}