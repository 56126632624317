import axios from 'axios';

const fastapi = axios.create({
    //baseURL: 'http://localhost:8000/api'
    //baseURL: 'http://74.208.137.47:3004/api'
    baseURL: 'https://exentus.net/api'
});

export default fastapi;

