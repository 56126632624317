import React, { Component } from 'react'
import ReactDatatable from '@ashvin27/react-datatable';
import Skeleton from '@material-ui/lab/Skeleton';
import api from '../../app/Config/apifiles';
import invoce from '../../app/Config/audit-data';
import { Tooltip, } from "@material-ui/core";
import ReportFinancialAPP from './subdetails/report-financial';
export default class ReportFinancial extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "nombre",
                text: "Nombre, Denominación o razón social",
                className: "nombre",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <div className="text-letf">
                            {(record.nombre !== "") ?
                                record.nombre.substr(0, 60) : ""}
                        </div>
                    );
                }
            },
            {
                key: "rfc",
                text: "RFC",
                className: "rfc",
                align: "left",
                sortable: true
            }, {
                key: "constancia",
                text: "Constancia",
                className: "constancia",
                sortable: true,
                cell: record => {
                    return (
                        <div className="text-center" style={{ textAlign: 'center' }}>
                            {
                                (record.statuconstancia === "NOT") ?
                                    (
                                        <div className="mr-3" style={{ textAlign: 'center' }}>
                                            <button type='button' className='btn btn-outline-danger btn-sm rounded-pill'>
                                                <Tooltip arrow title="No ha ingresado información" placement="top">
                                                    <i className="icon-file-pdf"></i>
                                                </Tooltip>
                                            </button>
                                        </div>
                                    ) : (
                                        <div className="mr-3" style={{ textAlign: 'center' }}>
                                            <button type='button' className='btn btn-outline-success btn-sm rounded-pill' onClick={() => this.handleViewPdfToProveedor(record)}>
                                                <Tooltip arrow title="Clic para ver la constancia de situación fiscal" placement="top">
                                                    <i className="icon-file-pdf"></i>
                                                </Tooltip>
                                            </button>
                                        </div>
                                    )
                            }
                        </div>
                    );
                }
            }, {
                key: "statusbalance",
                text: "Edo. posición financiera",
                className: "estatus",
                sortable: true,
                cell: record => {
                    return (
                        <div className="text-center" style={{ textAlign: 'center' }}>
                            {
                                (record.statusbalance === "NOT") ?
                                    (
                                        <div className="mr-3" style={{ textAlign: 'center' }}>
                                            <button type='button' className='btn btn-outline-danger btn-sm rounded-pill'>
                                                <Tooltip arrow title="No ha ingresado información" placement="top">
                                                    <i className="icon-file-pdf"></i>
                                                </Tooltip>
                                            </button>
                                        </div>
                                    ) : (
                                        <div className="mr-3" style={{ textAlign: 'center' }}>
                                            <button type='button' className='btn btn-outline-success btn-sm rounded-pill' onClick={() => this.handleSelectDetails(record)}>
                                                <Tooltip arrow title="Clic para ver el reporte" placement="top">
                                                    <i className="icon-file-pdf"></i>
                                                </Tooltip>
                                            </button>
                                        </div>
                                    )
                            }
                        </div>
                    );
                }
            }, {
                key: "statusedoresult",
                text: "Edo. Resultados",
                className: "statusedoresult",
                sortable: true,
                cell: record => {
                    return (
                        <div className="text-center" style={{ textAlign: 'center' }}>
                            {
                                (record.statusedoresult === "NOT") ?
                                    (
                                        <div className="mr-3" style={{ textAlign: 'center' }}>
                                            <button type='button' className='btn btn-outline-danger btn-sm rounded-pill'>
                                                <Tooltip arrow title="No ha ingresado información" placement="top">
                                                    <i className="icon-file-pdf"></i>
                                                </Tooltip>
                                            </button>
                                        </div>
                                    ) : (
                                        <div className="mr-3" style={{ textAlign: 'center' }}>
                                            <button type='button' className='btn btn-outline-success btn-sm rounded-pill' onClick={() => this.handleSelectDetailsEdoResultados(record)}>
                                                <Tooltip arrow title="Clic para ver el reporte" placement="top">
                                                    <i className="icon-file-pdf"></i>
                                                </Tooltip>
                                            </button>
                                        </div>
                                    )
                            }
                        </div>
                    );
                }
            }
        ];
        this.config = {
            filename: "report_cat_proveedores",
            key_column: 'rfc',
            page_size: 100,
            length_menu: [100, 200, 500],
            show_filter: true,
            show_pagination: true,
            button: {
                excel: true,
                print: true,
                //extra: true,
            },
            language: {
                length_menu: "Mostrar _MENU_ registros por página",
                filter: "Buscar en registros ...",
                info: "Mostrando _START_ a _END_ de _TOTAL_ entradas",
                pagination: {
                    first: "Primero",
                    previous: "Previa",
                    next: "próximo",
                    last: "Última"
                }
            },
            no_data_text: "No se encontraron registros"
        }
        this.state = {
            data: [],
            nombre: '',
            skeleton: true,
            loadingfirts: false,
            loadingpdfview: false,
            loadingsecond: false,
            titlereport: '',
            namepdf: '',
            typereport: '',
            datareport: [],
            linkpdf: '',
            linktmp: '',
            link: 'https://e-xpertum.com.mx/adminfile/api/ecb868faf29054fedf1ac6cbac5fc63bd54bba3f9d85ea1426942775518b90b6?query='
        }
    }
    componentDidMount() {
        this.getlist();
    }
    getlist = async () => {
        let data_ = JSON.parse(localStorage.getItem("datavalidate"));
        let data = await api.post("report-balance-income-state", { empresa_id: data_.id });
        let dat_ = (data.data).sort(function (a, b) {
            if (a.id < b.id) return -1;
            if (b.id < a.id) return 1;
            return 0;
        });
        this.setState({ data: dat_, nombre: data_.nombre, skeleton: false })
    }
    handleViewPdfToProveedor = async (x) => {

        this.setState({
            loadingfirts: true,
            link: 'https://e-xpertum.com.mx/adminfile/api/ecb868faf29054fedf1ac6cbac5fc63bd54bba3f9d85ea1426942775518b90b6?query=',
            namepdf: ''
        });
        setTimeout(() => {
            this.setState({
                link: this.state.link + dataapp(x.constancia[0]),
                namepdf: namefile(x.constancia[0]),
                loadingfirts: false
            });

        }, 1000);
        window.$("#modalviewpdfconstancia").modal("show");

    }
    handleSelectDetails = async (p) => {
        let dat_ = await invoce.post('resultad_data-company-send', { empresa_id: p._id });
        this.setState({
            typereport: 'b',
            titlereport: 'Estado de posición financiera de ' + p.nombre + " - " + p.rfc,
            loadingsecond: true,
            datareport: p.edoresultado,
            linktmp: 'https://e-xpertum.com.mx/invoces/api/report-pdf-estado-posicion-financiera/' + dat_.data
        });
        window.$("#modalviewreportsFinancial").modal("show");
    }
    handleSelectDetailsEdoResultados = async (p) => {
        let dat_ = await invoce.post('resultad_data-company-send', { empresa_id: p._id });
        this.setState({
            typereport: 'er',
            titlereport: 'Estado de resultados de ' + p.nombre + " - " + p.rfc,
            loadingsecond: true,
            datareport: p.balance,
            linktmp: 'https://e-xpertum.com.mx/invoces/api/report-pdf-estado-resultados-financiera/' + dat_.data
        });
        window.$("#modalviewreportsFinancial").modal("show");
    }
    handleViewModal = () => {
        this.setState({
            loadingpdfview: true,
            linkpdf: this.state.linktmp
        });
        window.$("#modalviewreportsFinancial").modal("hide");
        window.$("#modalviewpdffinancial").modal("show");
        setTimeout(() => {
            this.setState({
                loadingpdfview: false,
            })
        }, 3000);
    }
    render() {
        return (
            <>
                <div className="content-wrapper">
                    {
                        (this.state.skeleton) ?
                            (
                                <div className="page-content">
                                    <div className="content-wrapper">
                                        <div className="content pt-0">
                                            <br></br>
                                            <br></br>
                                            <div className="card">
                                                <Skeleton animation="wave" variant="text" height={70} />
                                                <Skeleton animation="wave" variant="text" height={70} />
                                                <Skeleton animation="wave" variant="text" height={70} />
                                                <Skeleton animation="wave" variant="text" height={70} />
                                                <Skeleton animation="wave" variant="text" height={70} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) :
                            (
                                <div className="content pt-0">
                                    <div className="card">
                                        <div className="card-body">
                                            <ReactDatatable
                                                config={this.config}
                                                records={this.state.data}
                                                columns={this.columns}
                                            />
                                            {/* Para ver el pdf de la constancia*/}
                                            <div id="modalviewpdfconstancia" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                                                <div className="modal-dialog modal-full">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title">{this.state.namepdf}</h5>
                                                            <button type="button" className="close" data-dismiss="modal">×</button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <div className="embed-container">
                                                                {
                                                                    (this.state.loadingfirts) ?
                                                                        (
                                                                            <div>
                                                                                <Skeleton variant="text" />
                                                                                <Skeleton variant="circle" animation="wave" width={40} height={40} />
                                                                                <Skeleton variant="rect" animation="wave" width={510} height={118} />
                                                                            </div>
                                                                        ) : (
                                                                            <iframe src={this.state.link} title="Pdf" frameBorder={0}
                                                                                allowFullScreen width={"100%"} />
                                                                        )
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Para ver los reportes financieros*/}
                                            <div id="modalviewreportsFinancial" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                                                <div className="modal-dialog modal-full">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title">{this.state.titlereport}</h5>
                                                            <button type="button" className="close" data-dismiss="modal">×</button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <div className="card">
                                                                <div className="card-header bg-transparent border-bottom header-elements-inline py-0">
                                                                    <h6 className="card-title py-3" style={{ color: 'transparent' }}>z</h6>
                                                                    <div className="header-elements">
                                                                        <button type="button" className="btn btn-outline-success" onClick={this.handleViewModal}>
                                                                            <i className="icon-file-pdf" />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className="card-body">
                                                                    <ReportFinancialAPP data={this.state.datareport} type={this.state.typereport} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="modalviewpdffinancial" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                                                <div className="modal-dialog modal-full">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title">{this.state.titlereport}</h5>
                                                            <button type="button" className="close" data-dismiss="modal">×</button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <div className="embed-container">
                                                                {
                                                                    (this.state.loadingpdfview) ?
                                                                        (
                                                                            <div>
                                                                                <Skeleton variant="text" />
                                                                                <Skeleton variant="circle" animation="wave" width={40} height={40} />
                                                                                <Skeleton variant="rect" animation="wave" width={510} height={118} />
                                                                            </div>
                                                                        ) : (
                                                                            <iframe src={this.state.linkpdf} title="Pdf" frameBorder={0}
                                                                                allowFullScreen width={"100%"} />
                                                                        )
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                    }
                </div>
            </>
        );
    }
}
function dataapp(params) {
    let _data_ = params.file;
    return _data_.dir + _data_.filename;
}
function namefile(params) {
    let _data_ = params.file;
    return _data_.filename;
}