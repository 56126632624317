import React, { Component } from 'react';
import PropTypes from 'prop-types';
import apiFile from '../../Config/apifiles';
class CompareMetadataCFDI extends Component {
    constructor(props) {
        super(props);
        let config = JSON.parse(localStorage.getItem("panelconfig"));
        this.state = {
            empresa_id: config.empres_id,
            emitido: [],
            recibido: []
        }
    }
    componentDidMount() {
        this.getDataConfig();
    }
    getDataConfig = async () => {
        let _data_ = await apiFile.post("report-cfdi-admin-tablero-list", this.state);
        //"SELECT isnull(max((Num_pol)),0) Num_pol  FROM catalogos.polizas WHERE empresa_id=@empresa_id AND ejercicio=@ejercicio AND periodo=@periodo AND TipoPol=@TipoPol
        this.setState(_data_.data);
    }
    render() {
        return (
            <div className="content-wrapper">
                <div className="content pt-0">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header bg-transparent border-bottom">
                                    <h6 className="card-title"> <i className="icon-cog3 mr-2"></i> Comparación de cfdi's vs metadata</h6>
                                </div>
                                <div className="card-body">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Emitido</th>
                                                <th>Recibido</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th style={{ textAlign: 'center' }}>Metadata</th>
                                                                <th style={{ textAlign: 'center' }}>CFDI</th>
                                                                <th style={{ textAlign: 'center' }}>Año</th>
                                                                <th style={{ textAlign: 'center' }}>Dif.</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                (this.state.emitido || []).map((d, f) => (
                                                                    <tr key={f}>
                                                                        <td style={{ textAlign: 'center' }}>{d.totalmetadata}</td>
                                                                        <td style={{ textAlign: 'center' }}>{d.totalcfdi}</td>
                                                                        <td style={{ textAlign: 'center' }}>{d.anio}</td>
                                                                        <td style={{ textAlign: 'center' }}>{d.totalmetadata - d.totalcfdi}</td>
                                                                        <td style={{ textAlign: 'center' }}>
                                                                            {(d.totalmetadata === d.totalcfdi) ?
                                                                                (<i class="icon-checkmark2 text-success"></i>)
                                                                                :
                                                                                (<i class="icon-cross2 text-danger"></i>)
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th style={{ textAlign: 'center' }}>Metadata</th>
                                                                <th style={{ textAlign: 'center' }}>CFDI</th>
                                                                <th style={{ textAlign: 'center' }}>Año</th>
                                                                <th style={{ textAlign: 'center' }}>Dif.</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                (this.state.recibido || []).map((d, f) => (
                                                                    <tr key={f}>
                                                                        <td style={{ textAlign: 'center' }}>{d.totalmetadata}</td>
                                                                        <td style={{ textAlign: 'center' }}>{d.totalcfdi}</td>
                                                                        <td style={{ textAlign: 'center' }}>{d.anio}</td>
                                                                        <td style={{ textAlign: 'center' }}>{d.totalmetadata - d.totalcfdi}</td>
                                                                        <td style={{ textAlign: 'center' }}>
                                                                            {(d.totalmetadata === d.totalcfdi) ?
                                                                                (<i class="icon-checkmark2 text-success"></i>)
                                                                                :
                                                                                (<i class="icon-cross2 text-danger"></i>)
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CompareMetadataCFDI;