import React, { Component } from 'react';
import api from '../../Config/api';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import Skeleton from '@material-ui/lab/Skeleton';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
class MonitorActivities extends Component {
    constructor(props) {
        super(props);

        this.state = {
            info: [],
            editorState: EditorState.createEmpty(),
            mensaje: ''
        }
    }
    componentDidMount() {

    }
    onEditorStateChange = (editorState) => {
        console.log(JSON.stringify(convertToRaw(editorState.getCurrentContent())))
        //console.log(editorState.getCurrentContent().getPlainText())
        this.setState({
            editorState,
            mensaje: JSON.stringify(convertToRaw(editorState.getCurrentContent()))
        });
    };
    render() {
        const { editorState } = this.state;
        return (
            <div className="card">
                <div className="card-body">
                    <Editor
                        placeholder="Describa el texto"
                        editorState={editorState}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={this.onEditorStateChange}

                    />
                    <div style={{ height: 200 }}></div>
                </div>
            </div>

        )
    }
}

export default MonitorActivities;