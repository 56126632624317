import React, { Component } from 'react';
import api from '../../app/Config/api';
import parse from 'html-react-parser';
import { withRouter } from 'react-router-dom';
import draftToHtml from 'draftjs-to-html';
import SKELETON from './details/skeleton';
class MonitorView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recordsets: [],
            recordread: [],
            asunto: '',
            comments: '',
            fecha: '',
            id: 0,
            nombre_: '',
            prospecto: '',
            prospecto_id: 0,
            usuario: '',
            listadofiles: [],
            skeleton: true,
        }
    }
    componentDidMount() {
        this.getquerydata();
        setInterval(() => {
            this.getquerydata();
            console.log('consulta')
        }, 20000);
    }
    getquerydata = async () => {
        var objec = JSON.parse(localStorage.getItem('id_token'));
        var options = {
            venta_id: objec.id
        }
        let getinformacion = await api.post("getlistmailssendventas", options);
        let { data } = await getinformacion;
        this.setState({
            recordsets: (data).filter((k) => k.estatus === "NOLEIDO"),
            recordread: (data).filter((k) => k.estatus === "LEIDO")
        });
    }
    getarrayfiles = async () => {
        let par = {
            id: this.state.id
        }
        let file = await api.post("getarrayfilesendmail", par);
        let { data } = await file;
        if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                data[i].ext = getxtension(data[i].nombrefile)
            }
        }
        this.setState({ listadofiles: data });
    }
    handleViewDetails = (id) => {
        if (id.comments !== "") {
            this.setState(id);
            setTimeout(() => {
                this.getquerydata();
                this.getarrayfiles();
                this.setState({ skeleton: false })
            }, 400);
        }
    }
    handleViewPreviewFile = (i) => {
        window.location.href = 'https://e-xpertum.com.mx/webadmin/api/documents/getfilesproveedor?query=' + i.directorio;
    }
    render() {
        return (
            <>
                <div className="content-wrapper">
                    {/* Page header */}
                    <div className="page-header border-bottom-0">
                        <div className="page-header-content header-elements-md-inline">
                            <div className="page-title d-flex">
                                <h4><i className="icon-arrow-left52 mr-2" /> <span className="font-weight-semibold">Inicio</span></h4>
                                <a href="#" className="header-elements-toggle text-default d-md-none"><i className="icon-more" /></a>
                            </div>
                            <h5 className="text-title">Listado de correos</h5>
                            <div className="header-elements d-none">
                                <form action="#">
                                    <div className="form-group form-group-feedback form-group-feedback-right" style={{ visibility: 'hidden' }}>
                                        <input type="search" className="form-control wmin-200" placeholder="Search messages" />
                                        <div className="form-control-feedback">
                                            <i className="icon-search4 font-size-base text-muted" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    {/* /page header */}
                    {/* Content area */}
                    <div className="content pt-0">
                        {/* Inner container */}
                        <div className="d-md-flex align-items-md-start">
                            {/* Left sidebar component */}
                            <div className="sidebar sidebar-light bg-transparent sidebar-component sidebar-component-left border-0 shadow-0 sidebar-expand-md">
                                {/* Sidebar content */}
                                <div className="sidebar-content">

                                    {/* Sub navigation */}
                                    <div className="card">
                                        <div className="card-header bg-transparent border-bottom header-elements-inline">
                                            <span className="card-title font-weight-semibold">No leidos</span>
                                            <div className="header-elements">
                                                <div className="list-icons">
                                                    <a className="list-icons-item" data-action="collapse" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body p-0">
                                            <ul className="nav nav-sidebar mb-2" data-nav-type="accordion">
                                                {
                                                    (this.state.recordsets || []).map((a, b) => (
                                                        <li className="nav-item" key={b}>
                                                            <a className="nav-link" style={{ cursor: 'pointer' }} onClick={() => this.handleViewDetails(a)}><i className="icon-stars" /> {a.asunto}</a>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    {/* /sub navigation */}
                                    {/* Start chat */}
                                    {
                                        (this.state.recordread.length > 0) ? (
                                            <div className="card">
                                                <div className="card-header bg-transparent border-bottom header-elements-inline">
                                                    <span className="card-title font-weight-semibold">Leidos</span>
                                                    <div className="header-elements">
                                                        <span className="badge bg-success badge-pill">{this.state.recordread.length}</span>
                                                    </div>
                                                </div>
                                                <ul className="media-list media-list-linked my-2">
                                                    {
                                                        (this.state.recordread || []).map((a, b) => (
                                                            <li className="nav-item" key={b}>
                                                                <a className="nav-link" style={{ cursor: 'pointer' }} onClick={() => this.handleViewDetails(a)}><i className="icon-stars" /> {a.asunto}</a>
                                                            </li>
                                                        ))
                                                    }

                                                </ul>
                                            </div>
                                        ) : null
                                    }

                                    {/* /start chat */}

                                </div>
                                {/* /sidebar content */}
                            </div>
                            {/* /left sidebar component */}
                            {/* Right content */}
                            <div className="flex-fill">
                                {/* Single mail */}
                                {this.state.skeleton ? (
                                    <div className="card">
                                        <SKELETON />
                                    </div>

                                ) :
                                    (
                                        <div className="card">
                                            {/* Mail details */}
                                            <div className="card-body">
                                                <div className="media flex-column flex-md-row">
                                                    <a href="#" className="d-none d-md-block mr-md-3 mb-3 mb-md-0">
                                                        <span className="btn bg-teal-400 btn-icon btn-lg rounded-round">
                                                            <span className="letter-icon">{(this.state.prospecto !== null ? this.state.prospecto.substr(0, 1) : "N")}</span>
                                                        </span>
                                                    </a>
                                                    <div className="media-body">
                                                        <h6 className="mb-0">{this.state.asunto}</h6>
                                                        <table className="table">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="30">Prospecto:</td><td><div className="letter-icon-title font-weight-semibold">{(this.state.prospecto !== null ? this.state.prospecto : "")} <a style={{ color: "#03a9f4" }}>&lt;{this.state.correo_}&gt;</a></div></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Vendedor:</td><td><div className="letter-icon-title font-weight-semibold">{this.state.nombre_} <a style={{ color: "#03a9f4" }}>&lt;{this.state.email}&gt;</a></div></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>


                                                    </div>

                                                </div>
                                            </div>
                                            {/* /mail details */}
                                            {/* Mail container */}
                                            <div className="card-body border-top">
                                                <div className="overflow-auto mw-100">
                                                    {/* Email sample (demo) */}
                                                    <table width="100%" border={0} cellPadding={0} cellSpacing={0} align="center">
                                                        <tbody><tr>
                                                            <td>
                                                                {/* The Best Prices for You */}
                                                                <table width="100%" border={0} cellPadding={0} cellSpacing={0} align="center">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td width="100%" height={1} bgcolor="#dddddd" style={{ fontSize: '1px', lineHeight: '1px' }}>&nbsp;</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td align="center" width="100%" valign="top" bgcolor="#fafafa" style={{ backgroundColor: '#fafafa' }}>
                                                                                <table width={640} border={0} cellPadding={0} cellSpacing={0} align="center">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td width="100%" height={50} />
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td align="center">
                                                                                                {/* Header */}
                                                                                                <table width={600} border={0} cellPadding={0} cellSpacing={0} align="center">
                                                                                                    <tbody>
                                                                                                        <tr>
                                                                                                            <td align="center">
                                                                                                                <table width={600} border={0} cellPadding={0} cellSpacing={0} align="center">
                                                                                                                    <tbody>
                                                                                                                        <tr>
                                                                                                                            <td align="center" valign="middle" style={{ fontSize: '24px', color: '#444444', lineHeight: '32px', fontWeight: 500 }}>
                                                                                                                                {this.state.asunto}
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                        <tr>
                                                                                                                            <td width="100%" height={30} />
                                                                                                                        </tr>
                                                                                                                        <tr>
                                                                                                                            <td width="100%">
                                                                                                                                <table width={100} border={0} cellPadding={0} cellSpacing={0} align="center">
                                                                                                                                    <tbody>
                                                                                                                                        <tr>
                                                                                                                                            <td height={1} bgcolor="#f67b7c" style={{ fontSize: '1px', lineHeight: '1px' }}>&nbsp;</td>
                                                                                                                                        </tr>
                                                                                                                                    </tbody>
                                                                                                                                </table>
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                        <tr>
                                                                                                                            <td width="100%" height={30} />
                                                                                                                        </tr>
                                                                                                                        <tr>
                                                                                                                            <td valign="middle" width="100%"
                                                                                                                                style={{ fontSize: '14px', color: '#808080', lineHeight: '22px', fontWeight: 400 }}>
                                                                                                                                <p style={{ textAlign: 'justify', fontSize: '14px', color: '#808080', lineHeight: '22px', fontWeight: 400 }}>

                                                                                                                                    {
                                                                                                                                        this.state.asunto !== "" ? (
                                                                                                                                            <>
                                                                                                                                                {parse(draftToHtml((JSON.parse(this.state.comments))))}
                                                                                                                                            </>
                                                                                                                                        ) : null
                                                                                                                                    }

                                                                                                                                </p>
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                        <tr>
                                                                                                                            <td width="100%" height={30} />
                                                                                                                        </tr>
                                                                                                                    </tbody>
                                                                                                                </table>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                </table>
                                                                                                {/* /header */}
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td height={60} />
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                {/* /the Best Prices for You */}


                                                            </td>
                                                        </tr>
                                                        </tbody></table>
                                                    {/* /email sample (demo) */}
                                                </div>
                                            </div>
                                            {/* /mail container */}
                                            {/* Attachments */}
                                            {
                                                (this.state.listadofiles.length > 0) ? (
                                                    <div className="card-body border-top">
                                                        <h6 className="mb-0">{this.state.listadofiles.length} archivo(s) adjuntos</h6>
                                                        <ul className="list-inline mb-0">
                                                            {
                                                                (this.state.listadofiles).map((i, k) => (
                                                                    <li className="list-inline-item" key={k}>
                                                                        <div className="card bg-light py-2 px-3 mt-2 mb-0">
                                                                            <div className="media my-1">
                                                                                <div className="mr-3 a lign-self-center">
                                                                                    {
                                                                                        ((i.ext) === 'pdf') ? (<i className="icon-file-pdf icon-2x text-danger-400 top-0"></i>) :
                                                                                            (
                                                                                                ((i.ext) === "jpg" || (i.ext) === "png" || (i.ext) === "jepg") ? (<i className="icon-image2 icon-2x text-danger-400 top-0"></i>)
                                                                                                    : (
                                                                                                        ((i.ext) === "cvs" || (i.ext) === "xlsx" || (i.ext) === "xls") ? (<i className="icon-file-spreadsheet icon-2x text-success-400 top-0"></i>) : (
                                                                                                            <i className="icon-file-empty2 icon-2x text-danger-400 top-0"></i>)
                                                                                                    )
                                                                                            )
                                                                                    }
                                                                                </div>
                                                                                <div className="media-body">
                                                                                    <div className="font-weight-semibold">{i.nombrefile}</div>

                                                                                    <ul className="list-inline list-inline-condensed mb-0">
                                                                                        <li className="list-inline-item"><a onClick={() => this.handleViewPreviewFile(i)} style={{ cursor: 'pointer' }}> Ver</a></li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </div>
                                                ) : null
                                            }
                                            {/* /attachments */}
                                        </div>
                                    )
                                }


                                {/* /single mail */}
                            </div>
                            {/* /right content */}
                        </div>
                        {/* /inner container */}
                    </div>
                    {/* /content area */}

                </div>
            </>
        );
    }
}

export default withRouter(MonitorView);
function getxtension(params) {
    var ext = params.split('.');
    // ahora obtenemos el ultimo valor despues el punto
    // obtenemos el length por si el archivo lleva nombre con mas de 2 puntos
    ext = ext[ext.length - 1];
    return ext;
}