import React, { Component } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

class MonthsGrap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.d,
        }
    }
    render() {
        return (
            <ResponsiveContainer width={"100%"} height={400}>
                <LineChart
                    width={650}
                    height={350}
                    data={this.state.data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <XAxis dataKey="name" name="name" />
                    <YAxis />
                    <Tooltip formatter={(value) => new Intl.NumberFormat('en').format(value)} contentStyle={{ color: 'gray', fontSize: 13, backgroundColor: "white" }} />
                    <Legend />
                    <Line type="monotone" dataKey="total" name='Importe pagado' stroke="#82ca9d" activeDot={{ r: 8 }} />
                </LineChart>
            </ResponsiveContainer >
        );
    }
}

export default MonthsGrap;