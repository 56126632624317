import React, { useState } from 'react';
import DatePicker from 'react-date-picker';
import './estilocalendar.css';
function padLeft(n) {
    return ("00" + n).slice(-2);
}
function formatDate(t) {
    var d = new Date(t);
    var dformat = (d.getUTCDate()) + '-' + ((d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) + '-' + d.getFullYear();
    return dformat
}
export default function MyApp(props) {
    const [startDate, setStartDate] = useState(new Date());

    const date_ = (t) => {
        setStartDate(t);
        props.setDate(formatDate(t))
    }
    return (
        <DatePicker
            dateFormat="yyyy-MM-dd"
            className="form-control"
            selected={startDate}
            value={startDate}
            calendarIcon={null}
            clearIcon={null}
            onChange={(date) => date_(date)}
            inline
        />
    );
};