import React, { Component } from 'react';
import api from '../../../../app/Config/api';
import Skeleton from '@material-ui/lab/Skeleton';

class Conciliacion extends Component {
    constructor(props) {
        super(props);


        this.state = {
            recorset: [],
            skeleton: true,
            status: 200
        }
    }
    componentDidMount() {
        this.getdata();
    }
    getdata = async () => {
        let pareme = JSON.parse(localStorage.getItem("parameters"));
        let result_ = await api.post("ceconciliacion", pareme);
        let { data } = await result_;

        if (data.estatus === 200)
            this.setState({ status: 200, recorset: data.info, skeleton: false });
        else if (data.estatus === 204)
            this.setState({ status: 204, info: data.info, skeleton: false });

    }
    render() {
        return (
            <>
                {
                    this.state.skeleton ? (
                        <div className="card">
                            <Skeleton />
                            <Skeleton animation={false} height={400} />
                            <Skeleton animation="wave" />
                        </div>
                    ) : (<>
                        {
                            this.state.status === 204 ? (
                                <div class="alert alert-warning alert-styled-right alert-dismissible">
                                    <span className="font-weight-semibold">Información!</span> {this.state.info}
                                </div>
                            ) :
                                (
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th rowSpan="2">Obligación</th>
                                                <th rowSpan="2">Número de cuenta</th>
                                                <th colSpan="2" className="text-center">Importes de Balanza</th>
                                                <th colSpan="2" className="text-center">Importes Capturados</th>
                                                <th rowSpan="2" className="text-center">Estatus</th>
                                            </tr>
                                            <tr>
                                                <th className="bg-grey">Por pagar</th>
                                                <th className="bg-grey">A favor</th>
                                                <th className="bg-grey">Por pagar</th>
                                                <th className="bg-grey">A favor</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (this.state.recorset || []).map((o, p) => (
                                                    <tr>
                                                        <td>{o.obligacion}</td>
                                                        <td>{o.cuenta_contable}</td>
                                                        <td className="text-right bg-grey">{(o.haber > 0) ? (o.haber.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')) : ''}</td>
                                                        <td className="text-right bg-grey">{(o.debe > 0) ? (o.debe.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')) : ''}</td>
                                                        <td className="text-right bg-grey">{(o.importe_capturado > 0) ? (o.importe_capturado.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')) : ''}</td>
                                                        <td className="text-right bg-grey">{(o.saldo_afavor > 0) ? (o.saldo_afavor.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')) : ''}</td>
                                                        <td className="text-center">{(o.estatus === "OK" ? (<i className="icon-checkmark4 text-success"></i>) : (<i className="icon-cross2 text-danger"></i>))}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                )
                        }

                    </>
                        )
                }
            </>
        );
    }
}

export default Conciliacion;