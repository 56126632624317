import React, { Component } from 'react';
import api from '../../../../app/Config/api';
import Parse from 'html-react-parser';
import Skeleton from '@material-ui/lab/Skeleton';

class BLZCFDI extends Component {
    constructor(props) {
        super(props);


        this.state = {
            recorset: [],
            skeleton: true,
        }
    }
    componentDidMount() {
        this.getdata();
    }
    getdata = async () => {
        let pareme = JSON.parse(localStorage.getItem("parameters"));
        let result_ = await api.post("getdetailsdebehaber", pareme);
        let { data } = await result_;
        let _array_data_ = (data.info).filter((i) => i.parent_id !== "0");
        console.log(_array_data_)
        //for (let t = 0; t < _array_data_.length; t++) {
        let data_ = (_array_data_[1].descripcion).split('</table></td><td>');

        _array_data_[1].left = data_[0] + '</table>';
        _array_data_[1].right = data_[1];
        _array_data_[1].descripcion = undefined;


        //}
        this.setState({ recorset: _array_data_, skeleton: false })

    }
    render() {
        return (
            <>
                {
                    this.state.skeleton ? (
                        <div className="card">
                            <Skeleton />
                            <Skeleton animation={false} height={400} />
                            <Skeleton animation="wave" />
                        </div>
                    ) : (
                            <>
                                {
                                    (this.state.recorset || []).map((o, p) => (
                                        <div className="card">
                                            {
                                                o.descripcion !== undefined ? (
                                                    Parse(o.descripcion)
                                                ) : (
                                                        <div className="row">
                                                            <div className="col-lg-6">{Parse(o.left)}</div>
                                                            <div className="col-lg-6">{Parse(o.right)}</div>
                                                        </div>
                                                    )
                                            }
                                        </div>
                                    ))
                                }
                            </>
                        )
                }
            </>
        );
    }
}

export default BLZCFDI;