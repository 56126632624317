import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, } from "@material-ui/core";
import api from '../../../app/Config/apifiles';
import Skeleton from '@material-ui/lab/Skeleton';
function sortByProperty(property) {
    return function (a, b) {
        if (a[property] > b[property]) return 1;
        else if (a[property] < b[property]) return -1;
        return 0;
    }
}
class SFsaldofavor extends Component {
    constructor(props) {
        super(props);
        let objects = JSON.parse(localStorage.getItem("datacompanyall"));
        this.state = {
            id: objects.id,
            data: [],
            skeleton: true,
            details: []
        }
    }
    componentDidMount() {
        this.getData_();
    }
    getData_ = async () => {
        let result_ = await api.post("validate-saldo-favor-contributions", this.state);
        let { data } = await result_;
        if (data.length > 0) {
            let _info_ = data.sort(sortByProperty("numero_de_operacion"));
            this.setState({ data: _info_, skeleton: false });
        }
    }
    handleChange = (p) => {
        let links = 'https://e-xpertum.com.mx/adminfile/api/doc-view-contributions/rdoc-frd/' + p + '/downloadfile';
        window.open(links, "_blank");
    }
    handleModalViewDetailsAplication = async (a) => {
        let result_ = await api.post("validate-saldo-favor-contributions-filter", { id: this.state.id, no: a.numero_de_operacion });
        let { data } = await result_;
        this.setState({ details: data.sort(sortByProperty("numero_de_operacion")) });
        window.$("#modalfullmodal").modal("show");
    }
    handleDeleteSF = async (a) => {
        let result_ = await api.post("validate-saldo-favor-contributions-delete", { id: this.state.id, numero_operacion: a.numero_de_operacion });
        this.getData_();
    }
    handledownloadfiles = () => {
        window.open("https://e-xpertum.com.mx/invoces/api/positive-balance/" + this.state.id);
    }
    render() {
        return (
            <>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header bg-transparent border-bottom header-elements-inline py-0">
                                <h6 className="card-title py-3" style={{ textAlign: 'center' }}>
                                    Listado de impuestos a favor
                                </h6>
                                <div className="header-elements">
                                    <button type="button" className="btn btn-light" onClick={this.handledownloadfiles} ><i className="icon-file-excel mr-2 text-success"></i> Excel</button>
                                </div>
                            </div>

                            <div className="card-body">
                                {this.state.skeleton ? (
                                    <div className="card">
                                        <Skeleton />
                                        <Skeleton animation={false} height={400} />
                                        <Skeleton animation="wave" />
                                    </div>
                                ) : (
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th className="text-center">Ejercicio</th>
                                                <th className="text-center">Periodo</th>
                                                <th>Fecha de presentación</th>
                                                <th>Número de operación</th>
                                                <th className="text-center">Tipo</th>
                                                <th className="text-center">Saldo original</th>
                                                <th className="text-center">Aplicación</th>
                                                <th className="text-center">Archivo</th>
                                                <th className="text-center">Acción</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (this.state.data || []).map((s, x) => (
                                                    <tr key={x}>
                                                        <td className="text-center">{s.ejercicio}</td>
                                                        <td className="text-center">{s.periodo}</td>
                                                        <td>{s.fechapresentacion}</td>
                                                        <td>{s.numero_de_operacion}</td>
                                                        <td>{s.tipodeclaracion.substr(0, 15)}</td>
                                                        <td className="text-right">{(s.sf === 0 ? '-' : (s.sf.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')))}</td>
                                                        <td className="text-center">
                                                            <Tooltip title={"Clic para ver la Compensación o Acreditamiento"} placement="top" arrow>
                                                                <button type="button" className="btn btn-link" onClick={() => this.handleModalViewDetailsAplication(s)}><i className="icon-cash mr-2 text-warning"></i> </button>
                                                            </Tooltip></td>
                                                        <td className="text-center">
                                                            <Tooltip title={"Clic para ver el archivo de la declaración"} placement="top" arrow>
                                                                <button type="button" className="btn btn-link" onClick={() => this.handleChange(s.document)}><i className="icon-file-pdf mr-2 text-success"></i> PDF</button>
                                                            </Tooltip></td>
                                                        <td className="text-center">
                                                            <Tooltip title={"Clic para eliminar el saldo a favor"} placement="top" arrow>
                                                                <button type="button" className="btn btn-link" onClick={() => this.handleDeleteSF(s)}><i className="icon-trash mr-2 text-danger"></i></button>
                                                            </Tooltip></td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modalfullmodal" className="modal fade" tabIndex={-1} style={{ display: 'none' }} aria-hidden="true">
                    <div className="modal-dialog modal-full">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Aplicación de los saldos</h5>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>
                            <div className="modal-body">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Obligación</th>
                                            <th>Ejercicio</th>
                                            <th>Periodo</th>
                                            <th>Fecha de aplicación</th>
                                            <th>Saldo original</th>
                                            <th>Remanente actualizado</th>
                                            <th>Saldo aplicado</th>
                                            <th>Importe compensado</th>
                                            <th>Archivo</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(this.state.details || []).map((x, z) => (
                                            <tr key={z}>
                                                <td>{x.obl}</td>
                                                <td>{x.ejercicio}</td>
                                                <td>{x.periodo}</td>
                                                <td>{x.fechapresentacion}</td>
                                                <td className="text-right">{x.montooriginalc.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                <td className="text-right">{x.remanenteactualizadoantesdelaaplicacionc.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                <td className="text-right">{x.saldoaplicarc.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                <td className="text-right">{x.total_contribuciones.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                <td className="text-center">
                                                    <Tooltip title={"Clic para ver el archivo de la declaración"} placement="top" arrow>
                                                        <button type="button" className="btn btn-link" onClick={() => this.handleChange(x.document)}><i className="icon-file-pdf mr-2 text-success"></i></button>
                                                    </Tooltip></td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default SFsaldofavor;