import React, { Component } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import api from '../../../app/Config/api'
import MuiAlert from '@material-ui/lab/Alert';
import { Snackbar, TextField } from '@material-ui/core';
import './style.css'
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class suscripcion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            proviene: 'Suscripcion',
            fecha_fin: '',
            fecha_inicio: '',
            numero_usuario: 1,
            numero_empresa: 1,
            suscripcion_id: 1,
            servicios: [],
            servicio_id: '',
            usuario_tipo: '',
            sistemas: [],
            sistemasSelect: [],
            suscripcion: [], error: false,
            vertical: 'top',
            horizontal: 'right',
        };
    }
    componentDidMount() {
        this._isMounted = true;
        api.get('get-date-getdate')
            .then(response => response.data)
            .then(data => {
                console.log(data)
                this.setState({ fecha_inicio: data.nowt, fecha_fin: data.dateend });
            });
        api.get('listModulo')
            .then(response => response.data)
            .then(data => {
                let teamsFromApi = data.map(team => {
                    return { value: team.id, label: team.descripcion };
                });
                this.setState({
                    servicios: teamsFromApi//[{ value: "", label: "(Selecione el sistema)"}].concat(teamsFromApi)
                });
            });
        api.get('listSuscripcion')
            .then(response => response.data)
            .then(data => {
                let teamsFromApi = data.map(team => {
                    return { value: team.id, label: team.nombre };
                });
                this.setState({
                    suscripcion: teamsFromApi // [{ id: "", nombre: "(Selecione la suscripción)"}].concat(teamsFromApi)
                });
            });
    }
    handle_update_data() {
        setTimeout(() => {
            this.props.data(this.state);
        }, 200);

    }
    handleChangeForm = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });

        this.handle_update_data();
    }

    getvaluedata = (e, i) => {
        if (i !== null) {
            this.setState({
                servicio_id: i.value
            });
            this.handle_update_data();
        }
    }

    getValueSistema = (e, i) => {
        this.setState({
            sistemasSelect: i
        });
        this.handle_update_data();
    }
    getValueSuscripcion = (e, i) => {
        this.setState({
            suscripcion_id: e.target.value
        });
        this.handle_update_data();
    }
    handleSelecttypeCliente = (e, i) => {
        this.setState({
            usuario_tipo: e.target.value
        });
        this.handle_update_data();
    }
    handleChangeUsuarios = (event) => {
        const value = event.target.value;
        this.setState({ numero_usuario: value });
        this.handle_update_data();
    }
    handleChangeEmpresas = event => {
        const value = event.target.value;
        this.setState({ numero_empresa: value });
    }
    validacionform(state) {
        var message = '';
        if (state.numero_usuario === '') {
            this.setState({ message: 'Debe de seleccionar el numero de usuarios' });
            message = 'message';
        }
        if (state.numero_empresa === '') {
            this.setState({ message: 'Debe de seleccionar el numero de empresas' });
            message = 'message';
        }
        if (state.fecha_fin === '') {
            this.setState({ message: 'Debe de seleccionar la fecha de fin' });
            message = 'message';
        }
        if (state.fecha_inicio === '') {
            this.setState({ message: 'Debe de seleccionar la fecha de inicio' });
            message = 'message';
        }
        return (message);
    }
    cerrarMensaje = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ error: false });
    };
    render() {
        const { vertical, horizontal } = this.state;
        return (
            <>
                <Snackbar open={this.state.error} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} key={horizontal}>
                    <Alert onClose={this.cerrarMensaje} severity="error">
                        {this.state.message}
                    </Alert>
                </Snackbar>
                <div className="card">
                    <div className="card-body">
                        <div autoComplete="off">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <div className="col-lg-12">
                                            <input type="text" readOnly className="form-control" value={this.state.fecha_inicio} placeholder="Fecha inicio" name="fecha_inicio" onChange={this.handleChangeForm} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <div className="col-lg-12">
                                            <input type="text" readOnly className="form-control" value={this.state.fecha_fin} placeholder="Fecha fin" name="fecha_fin" onChange={this.handleChangeForm} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-lg-7">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <TextField
                                                fullWidth
                                                label="Número de usuarios"
                                                margin="dense"
                                                name="numerousuarios"
                                                onChange={this.handleChangeUsuarios}
                                                required
                                                select
                                                SelectProps={{ native: true }}
                                                value={this.state.numero_usuario}
                                                variant="outlined">
                                                <option className="select-selected" key={1} value={1}>1</option>
                                                <option className="select-selected" key={2} value={2}>2</option>
                                                <option className="select-selected" key={5} value={5}>5</option>
                                                <option className="select-selected" key={10} value={10}>10</option>
                                                <option className="select-selected" key={15} value={15}>15</option>
                                                <option className="select-selected" key={20} value={20}>20</option>
                                                <option className="select-selected" key={25} value={25}>25</option>
                                                <option className="select-selected" key={30} value={30}>30</option>
                                                <option className="select-selected" key={35} value={35}>35</option>
                                                <option className="select-selected" key={40} value={40}>40</option>
                                            </TextField>
                                        </div>
                                        <div className="col-md-6">
                                            <TextField
                                                fullWidth
                                                label="Número de empresas"
                                                margin="dense"
                                                name="numeroempresas"
                                                onChange={this.handleChangeEmpresas}
                                                required
                                                select
                                                SelectProps={{ native: true }}
                                                value={this.state.numero_empresa}
                                                variant="outlined">
                                                <option className="select-selected" key={1} value={1}>1</option>
                                                <option className="select-selected" key={2} value={2}>2</option>
                                                <option className="select-selected" key={5} value={5}>5</option>
                                                <option className="select-selected" key={10} value={10}>10</option>
                                                <option className="select-selected" key={15} value={15}>15</option>
                                                <option className="select-selected" key={20} value={20}>20</option>
                                                <option className="select-selected" key={25} value={25}>25</option>
                                                <option className="select-selected" key={30} value={30}>30</option>
                                                <option className="select-selected" key={35} value={35}>35</option>
                                                <option className="select-selected" key={40} value={40}>40</option>
                                            </TextField>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5" style={{ paddingTop: 10 }}>
                                    <select className="custom-select" style={{ backgroundColor: '#3a445b' }} onChange={this.handleSelecttypeCliente}>
                                        <option value="">Seleccione el tipo de cliente</option>
                                        <option value="CLIENTE">Persona Moral / Fisica</option>
                                        <option value="GOBIERNO">Gobierno</option>
                                        <option value="FINANCIERA">Financieras</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Autocomplete
                                                id="servicio"
                                                options={this.state.servicios}
                                                autoHighlight
                                                getOptionLabel={(option) => option.label}
                                                renderOption={(option) => (
                                                    <React.Fragment>
                                                        <span>{option.label}</span>
                                                    </React.Fragment>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField  {...params} label="Selecciona el servicio"
                                                        variant="outlined" autoComplete="off" inputProps={{ ...params.inputProps, autoComplete: 'new-password', }}
                                                        margin="dense" fullWidth required />
                                                )}
                                                onChange={this.getvaluedata} />
                                        </div>
                                        <div className="col-md-6" style={{ paddingTop: 10 }}>
                                            <select className="custom-select" style={{ backgroundColor: '#3a445b' }} onChange={this.getValueSuscripcion}>
                                                {
                                                    (this.state.suscripcion || []).map((op, i) => (
                                                        <option style={{ padding: '8px' }} value={op.value} key={i}> {op.label} </option>
                                                    ))
                                                }
                                            </select>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default suscripcion;