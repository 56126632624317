import React, { Component } from 'react';
import api from '../../Config/api'

import DetalleDatos from './detailDatos';
import DetalleContacto from './detailContact';
import DetalleSuscripcion from './detailSuscrip';

class DetailsClients extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.detailData.id,
            cliente_id: props.detailData.cliente_id,
            expanded: false,
            razon: [],
            contacto: [],
            suscripcion: [],
            viewrazon: '',
            viewcontacto: '',
            viewsuscripcion: ''
        }
        //console.log(props.detailData)
    }
    componentDidMount() {
        var objexts = {
            id: this.state.id,
            cliente_id: this.state.cliente_id
        }
        api.get('listModuloClientsDetailsGroupFilter?id=' + JSON.stringify(objexts))
            .then(response => response.data)
            .then(data => {
                this.setState({
                    //razon: data.dataRazon,
                    contacto: data.dataPersona,
                    suscripcion: data.dataSuscripcion,
                    loading: false,
                    viewrazon: <DetalleDatos data={data.dataRazon} />
                });
            });
    }
    handleChange = (panel) => (event, isExpanded) => {
        this.setState({ expanded: (isExpanded ? panel : false) });
    };
    render() {
        return (
            <>
                <div id="accordion-default">
                    <div className="card">
                        <div className="card-header">
                            <h6 className="card-title">
                                <a data-toggle="collapse" className="text-default" href="#accordion-item-default1" aria-expanded="true">Datos Fiscales</a>
                            </h6>
                        </div>

                        <div id="accordion-item-default1" className="collapse" data-parent="#accordion-default" >
                            <div className="card-body">
                                {this.state.viewrazon}
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-header">
                            <h6 className="card-title">
                                <a className="text-default collapsed" data-toggle="collapse" href="#accordion-item-default2" aria-expanded="false">Dato del contacto</a>
                            </h6>
                        </div>

                        <div id="accordion-item-default2" className="collapse" data-parent="#accordion-default" >
                            <div className="card-body">
                                <DetalleContacto data={(this.state.contacto)} />
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-header">
                            <h6 className="card-title">
                                <a className="text-default collapsed" data-toggle="collapse" href="#accordion-item-default3" aria-expanded="false">Suscripción</a>
                            </h6>
                        </div>

                        <div id="accordion-item-default3" className="collapse" data-parent="#accordion-default" >
                            <div className="card-body">
                                <DetalleSuscripcion data={(this.state.suscripcion)} />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
        // const classes = useStyles();
        /*return (
            <div>
                <Container fixed>
                    <Grid container spacing={3}>
                        <ExpansionPanel expanded={this.state.expanded === 'panel1'} onChange={this.handleChange('panel1')}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header" >
                                <Box clone pt={2} pr={1} pb={1} pl={2}>
                                    <Paper elevation={0}>
                                        <Grid container spacing={2} alignItems="center" wrap="nowrap">
                                            <Grid item>
                                                <Box bgcolor="primary.main" clone>
                                                    <Avatar>
                                                        <Icons.Business />
                                                    </Avatar>
                                                </Box>
                                            </Grid>
                                            <Grid item>
                                                <Typography>Datos Fiscales</Typography>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Box>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container spacing={3}>
                                    <Divider></Divider>
                                    
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel expanded={this.state.expanded === 'panel2'} onChange={this.handleChange('panel2')}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header" >
                                <Box clone pt={2} pr={1} pb={1} pl={2}>
                                    <Paper elevation={0}>
                                        <Grid container spacing={2} alignItems="center" wrap="nowrap">
                                            <Grid item>
                                                <Box bgcolor="primary.main" clone>
                                                    <Avatar>
                                                        <Icons.ContactMailTwoTone />
                                                    </Avatar>
                                                </Box>
                                            </Grid>
                                            <Grid item>
                                                <Typography>Dato del contacto</Typography>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Box>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container spacing={3}>
                                    <DetalleContacto data={(this.state.contacto)} />
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel expanded={this.state.expanded === 'panel3'} onChange={this.handleChange('panel3')}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content" id="panel3bh-header" >
                                <Box clone pt={2} pr={1} pb={1} pl={2}>
                                    <Paper elevation={0}>
                                        <Grid container spacing={2} alignItems="center" wrap="nowrap">
                                            <Grid item>
                                                <Box bgcolor="primary.main" clone>
                                                    <Avatar>
                                                        <Icons.Subscriptions />
                                                    </Avatar>
                                                </Box>
                                            </Grid>
                                            <Grid item>
                                                <Typography>Suscripción</Typography>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Box>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container spacing={3}>
                                    <DetalleSuscripcion data={(this.state.suscripcion)} />
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>
                </Container>
            </div>
        );*/
    }
}

export default DetailsClients;
