import React, { Component } from 'react';
import API from '../../../app/Config/api';
//import DetailsValidador from '../../Modulos/details/listcompany';
//import AuditoriContable from '../../../client/Dashboard/listcompany'; // '../../client/Dashboard/listcompany';
//import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import IMG from '../../../images/efos.png';
function LayoutInformaciOn() {
    return (
        <>
            <div className="page-content">
                <div className="content-wrapper">
                    <div className="content pt-0">
                        <br></br>
                        <br></br>
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="card">
                                    <div className="card-body text-center">
                                        <i className="icon-book icon-2x text-success-400 border-success-400 border-3 rounded-round p-3 mb-3 mt-1"></i>
                                        <h5 className="card-title">Validador</h5>
                                        <p className="mb-3">
                                            Audita a los proveedores y clientes. Disminuye el riesgo de hacer negocios con empresas fraudulentas.
                                    </p>
                                        <a href="https://e-xpertum.com/" target="_blank" className="btn bg-success-400">Aquirir</a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="card">
                                    <div className="card-body text-center">
                                        <i className="icon-lifebuoy icon-2x text-warning-400 border-warning-400 border-3 rounded-round p-3 mb-3 mt-1"></i>
                                        <h5 className="card-title">Auditoría de la contabilidad</h5>
                                        <p className="mb-3">Verifica que la contabilidad electrónica no contenga errores antes de ser enviada a la autoridad.</p>
                                        <a href="https://e-xpertum.com/" target="_blank" className="btn bg-success-400">Aquirir</a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="card">
                                    <div className="card-body text-center">
                                        <i className="icon-reading icon-2x text-blue border-blue border-3 rounded-round p-3 mb-3 mt-1"></i>
                                        <h5 className="card-title">Nómina</h5>
                                        <p className="mb-3">Administra la documentación de los trabajadores, así como los comprobantes de pago.</p>
                                        <a href="https://e-xpertum.com/" target="_blank" className="btn bg-success-400">Aquirir</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
class IndexAuditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isservice: false
        }
    }
    componentDidMount() {
        var objecturl = (window.location.href).split('#');
        const _data_ = JSON.parse(localStorage.getItem("id_token"));
        API.post('validacionactivacion', _data_).then((o) => {
            var resultd = (o.data).filter((p) => p.link === objecturl[1])
            if (resultd.length > 0) { this.setState({ isservice: true }) }
        })
    }

    render() {
        return (
            <>
                {(this.state.isservice ? <LayoutInformaciOn /> : <LayoutInformaciOn />)}
            </>
        );
    }
}

export default IndexAuditor;