import React, { Component } from 'react';
import api from '../../Config/api';
import Download from '../../Config/config.json';
import { Tooltip } from '@material-ui/core';
//import CloseIcon from '@material-ui/icons/Close';
import * as Icons from '@material-ui/icons';
import { Slide, Typography, IconButton, Toolbar, AppBar, Card, CardContent, Divider, Grid, Container, DialogContent, Dialog, Button, Backdrop } from '@material-ui/core';
import { SnackbarProvider, useSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import './style.css';
import { data } from 'jquery';
const useStyles = theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
});

function AddButton(dataop) {
    const { enqueueSnackbar } = useSnackbar();
    const handleClickVariant = (variant) => () => {
        api.post("filterdetailsdocuments", dataop.value.data).then((y) => y.data).then((rt) => {
            console.log(rt)
            var _options_data_ = (rt.datos).filter((y) => y.status === 'good');
            var contenidolen = _options_data_.length, iteratelen = 0;
            for (let index = 0; index < _options_data_.length; index++) {
                enqueueSnackbar(_options_data_[index].mensaje, { variant });
                iteratelen++;
            }
            var objec = setInterval(() => {
                if (contenidolen === iteratelen) {
                    console.log(contenidolen, '===', iteratelen)
                    clearInterval(objec);
                    setTimeout(() => {
                        dataop.value.func(dataop.value.data);
                    }, 3000);
                }
            }, 200)
        })
    };

    return (<Tooltip title="Descargar" placement="top" arrow onClick={handleClickVariant("success")}><i style={{ cursor: 'pointer', color: '#29b6f6' }} className="icon-cloud-download2 mr-3 icon-2x"></i></Tooltip>);
}


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

class DetalleEmpresas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
            data: [],
            informacionmostrar: [],
            block: false
        }
    }
    handleClose = () => {
        this.setState({ open: false });
        this.props.value.clear();
    }
    componentDidMount() {
        this.setState({ block: true })
        var objects = this.props.value.data;
        var info = {
            cliente_id: objects.cliente_id
        }
        api.post('listvalidacionarchivosclienteidall', info).then((res) => res.data)
            .then((re) => {
                console.log(re)
                this.setState({ data: re.info, block: false });
            })
            .catch();
        //this.getDataInformacionArray(objects.empresa_id);
    }
    getDataInformacionArray(id) {
        var info = { empresa_id: id }
        api.post('listmenuclientobjectdownload', info).then((res) => {
            //this.setState({ informacionmostrar: res.data.info });
        }).catch();
    }
    handlenotifications = (y) => {

        var object = {
            rfc: y.rfc,
            nombre: y.nombre
        }
        api.post('creardoczip', object).then((res) => {
            console.log(res.data.url)
            window.location.href = Download.directoriodownload.download + '/getfilesproveedor?query=' + res.data.url;
        }).catch();
    }
    render() {
        console.log(this.props)
        //const classes = useStyles();
        return (
            <>
                <Dialog fullScreen open={this.state.open} onClose={this.handleClose} TransitionComponent={Transition}>
                    <AppBar className="appBar">
                        <Toolbar>
                            <Typography variant="h6" className="title">
                                <span style={{ color: 'white' }}>Administrador de archivos</span>
                            </Typography>
                            <IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                                <Icons.Close />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>

                        <Container maxWidth="xl" fixed>
                            <Backdrop open={this.state.block} style={{ color: "#fff", background: "#65665e", zIndex: 400, opacity: "0.3" }}>
                                <CircularProgress color="inherit" />
                            </Backdrop>
                            <Grid container spacing={3} style={{ paddingTop: 100 }}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th style={{ textAlign: 'center' }}>RFC</th>
                                            <th style={{ textAlign: 'center' }}>Estatus</th>
                                            <th style={{ textAlign: 'center' }}>Acción</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (this.state.data || []).map((y) => (
                                                <tr key={y.id}>
                                                    <td>{y.nombre}</td>
                                                    <td style={{ textAlign: 'center' }}>{y.rfc}</td>
                                                    <td style={{ textAlign: 'center' }}>{validacionicon(y.status)}</td>
                                                    <td style={{ textAlign: 'center' }}>
                                                        {(y.tipo == "auditor" ? (y.status == 0 ? <Button variant='contained' disabled color='primary'>Descargar</Button>
                                                            : (<SnackbarProvider><AddButton value={{ data: y, func: this.handlenotifications }}></AddButton></SnackbarProvider>)) : '')} </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>

                            </Grid>
                        </Container>
                    </DialogContent>
                </Dialog>
            </>
        );
    }
}

export default DetalleEmpresas;

function validacionicon(i) {
    if (i == 1)
        return (<Icons.Check style={{ color: "rgb(126, 188, 137)" }} />)
    else
        return (<Icons.Close style={{ color: "rgb(239, 35, 60)" }} />)
}