import React, { Component } from 'react';
import { ClockLoader } from "react-spinners";
import {
    FormControl, InputAdornment, OutlinedInput, Dialog, DialogTitle, DialogContentText,
    DialogContent, DialogActions
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';

/*******
 * LIBRARY UPLOAD FILES
 */
import * as ALLIcons from '@material-ui/icons';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
/*******
 * CONFIG CONEXION
 */
import api from '../../app/Config/api';
import apiUpload from '../../app/Config/apifiles';
/**************
 * NOTIFICATIONS
 */
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Skeleton from '@material-ui/lab/Skeleton';
//import './style/table.css';
//import Skeleton from './loading';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class FormImportFilesEfirma extends Component {
    constructor(props) {
        super(props);
        const data = JSON.parse(localStorage.getItem("datavalidate"));
        const _data_ = JSON.parse(localStorage.getItem("id_token"));
        this.state = {
            filercer: '',
            filekey: '',
            empresa_id: data.id,
            servicio_id: 1,
            namecer: 'Certificado (.cer)',
            namekey: 'Clave privada (.key)',
            amount: '',
            password: '',
            weight: '',
            weightRange: '',
            showPassword: false,
            vigencia: new Date(),
            directorycer: '',
            directorykey: '',
            block: false,
            alert: '',
            show: false,
            rfc: data.rfc,
            data: [],
            delete: false,
            delete_id: 0,
            rfc: data.rfc,
            perfil: _data_.perfil,
            pfxnombre: '',
            pfxrfc: '',
            pfxcaducidad: '',
            pfxstatus: '',
            viewlist: true,

            viewadd: false,
            loginx: ""
        }
    }
    handleChangeFileCer = (e) => {
        const file = e.target.files[0];
        this.setState({ namecer: file.name, filercer: file });
        const formData = new FormData();
        formData.append('file', file)
        formData.append('rfc', this.state.rfc)
        api.post('subirdocumentsvalidador', formData).then(res => {
            this.setState({ directorycer: res.data.path });
        }).catch(err => console.log(err));


    }
    handleChangeFileKey = (e) => {
        const file = e.target.files[0];
        this.setState({ namekey: file.name, filekey: file });
        const formData = new FormData();
        formData.append('file', file)
        formData.append('rfc', this.state.rfc)
        api.post('subirdocumentsvalidador', formData).then(res => {
            this.setState({ directorykey: res.data.path });
        }).catch(err => console.log(err));

    }
    handleChange = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };

    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };
    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    submitSave = () => {
        if (this.state.filercer !== '') {
            if (this.state.filekey !== '') {
                if (this.state.password !== '') {
                    if (this.state.vigencia !== '') {
                        this.setState({ block: true });
                        api.post('InsertFirmaElectronica', this.state).then(res => {
                            if (res.data.status === 200) {
                                this.setState({ show: true, alert: 'Se han guardado correctamente los datos', viewadd: true, block: false, password: '' });
                                setTimeout(async () => {
                                    let xd_d = await apiUpload.post("config-download-upload-all", { id: this.state.empresa_id, rfc: this.state.rfc });
                                    console.log(xd_d.data)
                                }, 200);
                                /******************************
                                 *  generación pfx and validación login  * 
                                 */
                                /*var options = {
                                    rfc: this.state.rfc,
                                    password: this.state.password
                                };
                                sencod.post("convertpfxuploadfiles", options).then((result) => result.data)
                                    .then((res) => {
                                        if (res === "good") {
                                            
                                            sencod.post("validatelogin", options).then((result) => result.data)
                                                .then((res) => {
                                                    if (res.status === 200)
                                                        this.setState({ show: true, alert: res.menssage, block: false, password: '' });
                                                    else if (res.status === 204)
                                                        this.setState({ show: true, alert: res.menssage, block: false, password: '' });
                
                                                }).catch((error) => {
                                                    console.log(error)
                                                });
                                          
                                        }
                                        else if (res === "notgood")
                                            this.setState({ show: true, alert: 'Ocurrio un error al generar el archivo de configuración, ingresar los correctos.', block: false, password: '' });
                
                                    }).catch((error) => {
                                        console.log(error)
                                    });*/

                                let interar = setInterval(() => {
                                    this.componentDidMount();

                                    if (this.state.pfxnombre !== "")
                                        clearInterval(interar);
                                }, 4000);

                            }
                        }).catch(err => console.log(err));
                    } else {
                        this.setState({ show: true, alert: 'Debe de ingresar la fecha de caducidad!!' });
                    }
                } else {
                    this.setState({ show: true, alert: 'Debe de ingresar la contraseña del contribuyente ' });
                }
            } else {
                this.setState({ show: true, alert: 'Debe de ingresar el archivo .key ' });
            }
        }
        else {
            this.setState({ show: true, alert: 'Debe de ingresar el archivo .cer ' });

        }
    }
    handleDateChangeVigencia = (date) => {
        this.setState({ vigencia: new Date() });
    };
    handleCloseMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ show: false });
    };
    componentDidMount() {
        var conte = {
            empresa_id: this.state.empresa_id
        }
        api.post('listconfiguracionefirma', conte).then(res => {

            if (res.data.length > 0) {
                if (((res.data)[0]).vista !== null) {
                    let _view_ = JSON.parse(((res.data)[0]).vista);

                    this.setState({
                        loginx: (res.data)[0].login,
                        pfxnombre: _view_.name, pfxrfc: _view_.rfc,
                        pfxcaducidad: _view_.dateendshort,
                        pfxstatus: _view_.status,
                        viewadd: false
                    });
                }
            }

            this.setState({ data: res.data, viewlist: false });
        }).catch(err => console.log(err));
    }

    handleDeleteRegistro = (i) => {
        this.setState({ delete: true, delete_id: i });
    }
    handleConfirmDeleteRegistro = () => {
        var params = { id: this.state.delete_id }
        api.post('deleteefirmaok', params).then(res => {
            this.componentDidMount();
            this.setState({ delete: false, viewadd: false, viewlist: false });
        }).catch(err => console.log(err));
    }
    handleCloseDeleteRegistro = () => {
        this.setState({ delete: false });
    }
    render() {
        return (
            <>
                <Backdrop open={this.state.block} style={{ color: "#fff", background: "#B9B6B6", zIndex: 400, opacity: "0.5" }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Snackbar open={this.state.show} autoHideDuration={6000} onClose={this.handleCloseMessage} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                    <Alert severity="success" onClose={this.handleCloseMessage}>{this.state.alert}</Alert>
                </Snackbar>
                <div className="content pt-0">
                    <div className="card">
                        <div className="card-header bg-transparent border-bottom">
                            <h6 className="card-title">
                                <i className="icon-certificate mr-2 text-success"></i>
                                Registra tu credencial del Servicio de Administración Tributaria (SAT)
                            </h6>
                        </div>
                        <div className="card-body">
                            <div className='row'>
                                <div className='col-lg-2'></div>
                                <div className='col-lg-8'>
                                    <fieldset className="mb-3">
                                        <div className="form-group row">
                                            {/*<label className="col-form-label col-lg-3">Certificado .(cer)</label>*/}
                                            <div className="col-lg-12">
                                                <div className="uniform-uploader">
                                                    <input type="file" className="form-control-uniform-custom" onChange={this.handleChangeFileCer} accept=".cer" />
                                                    <span className="filename" style={{ userSelect: "none" }}>{this.state.namecer}</span>
                                                    <span className="action btn bg-warning" style={{ userSelect: "none" }}>Buscar</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            {/*<label className="col-form-label col-lg-3">Clave privada (.key)</label>*/}
                                            <div className="col-lg-12">
                                                <div className="uniform-uploader">
                                                    <input type="file" className="form-control-uniform-custom" onChange={this.handleChangeFileKey} accept=".key" />
                                                    <span className="filename" style={{ userSelect: "none" }}> {this.state.namekey}</span>
                                                    <span className="action btn bg-warning" style={{ userSelect: "none" }}>Buscar</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            {/* <label className="col-form-label col-lg-3">Contraseña de clave privada</label>*/}
                                            <div className="col-lg-12">
                                                <FormControl variant="outlined" margin="dense" fullWidth>
                                                    <OutlinedInput autoComplete="off" placeholder='Contraseña de la clave privada'
                                                        id="outlined-adornment-password"
                                                        type={this.state.showPassword ? 'text' : 'password'}
                                                        value={this.state.password}
                                                        onChange={this.handleChange('password')}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={this.handleClickShowPassword}
                                                                    onMouseDown={this.handleMouseDownPassword}
                                                                    edge="end">
                                                                    {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                        labelWidth={200}
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                        {/*<div className="form-group row">
                                        <label className="col-form-label col-md-3">Fecha de caducidad</label>
                                        <div className="col-md-3">
                                            <input className="form-control" type="date" name="date" onChange={date => this.handleDateChangeVigencia(date)} />
                                        </div>
                                    </div>*/}
                                        <div className="text-right">
                                            <button type="submit" className="btn btn-primary" onClick={this.submitSave}>Guardar<i className="icon-paperplane ml-2"></i></button>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="card">
                        <div className="card-header header-elements-inline">
                            <h5 className="card-title">Datos del contribuyente</h5>
                        </div>
                        <div className="row">
                            {
                                (this.state.viewadd) ?
                                    (
                                        <div className='col-sm-12'>

                                            <div>
                                                <Skeleton variant="text" />
                                                <div style={{
                                                    margin: 40,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}>
                                                    <ClockLoader color={"#ff7043"} loading={this.state.viewadd} size={50} margin={2} />
                                                </div>
                                                <Skeleton variant="text" animation="wave" />
                                                <Skeleton variant="rect" animation="wave" width={"100%"} height={118} />
                                            </div>
                                        </div>
                                    ) : (
                                        (this.state.pfxnombre !== '') ?
                                            (<div className="col-md-12">
                                                {
                                                    this.state.loginx === "GOOD" ? (
                                                        <table className="table">
                                                            <tbody>
                                                                <tr>
                                                                    <td>Razon social:</td>
                                                                    <td>{this.state.pfxnombre}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>RFC</td>
                                                                    <td>{this.state.pfxrfc}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Fecha caducidad</td>
                                                                    <td>{this.state.pfxcaducidad}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Estatus</td>
                                                                    <td>{this.state.pfxstatus === "" ? (
                                                                        <span className="badge badge-success d-block">Vigente</span>
                                                                    ) : (<span className="badge badge-danger d-block">La efirma ha caducado</span>)}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    ) : (
                                                        <>
                                                            {
                                                                this.state.loginx === "NOTLOGIN" ? (
                                                                    <table className="table">
                                                                        <tr>
                                                                            <td>
                                                                                <span className="badge badge-danger d-block">{this.state.pfxnombre}</span>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                ) : (
                                                                    <table className="table">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Razon social:</td>
                                                                                <td>{this.state.pfxnombre}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>RFC</td>
                                                                                <td>{this.state.pfxrfc}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Fecha caducidad</td>
                                                                                <td>{this.state.pfxcaducidad}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Estatus</td>
                                                                                <td>{this.state.pfxstatus === "" ? (
                                                                                    <span className="badge badge-success d-block">Vigente</span>
                                                                                ) : (<span className="badge badge-danger d-block">La efirma ha caducado</span>)}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                )
                                                            }
                                                        </>
                                                    )

                                                }

                                            </div>) : null
                                    )
                            }

                        </div>
                        <div style={{ height: 10 }}></div>
                    </div>
                </div>
                <Dialog open={this.state.delete} onClose={this.handleCloseDeleteRegistro} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title"><span style={{ color: 'white' }}>{"Confirmación de eliminar"}</span></DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <span style={{ color: 'white' }}>
                                ¿Está de acuerdo en eliminar el registro?
                            </span>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button type="button" className="btn btn-link" onClick={this.handleCloseDeleteRegistro}>Cancelar</button>
                        <button type="button" className="btn bg-primary" onClick={this.handleConfirmDeleteRegistro}>Aceptar</button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}
export default FormImportFilesEfirma;
function validacion(i) {
    if (i !== '')
        return (<ALLIcons.Check style={{ color: '#7ebc89' }} />)
}