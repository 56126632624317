import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import api from '../../../app/Config/api';

/**********
 * style para listado de empresas
 */
import '../styleinit.css';

export default function CustomizedTables(props) {
    const ref = React.useRef();
    const [aceptoterminos, setAceptoTerminos] = useState(localStorage.getItem("terminosycondiciones"));
    const [estatusmensaje, setEstatusMensaje] = useState(false);
    const [textmensaje, setAddTextMensaje] = useState('');
    const [infos, setData] = useState([]);
    const [container, setInfoContaniner] = useState(false);
    var objec = JSON.parse(localStorage.getItem('id_token'));
    const [userData, setUserAdd] = useState(objec);
    const [cliente_id] = useState(objec.cliente_id);
    const [textdinamyc, setChangeName] = useState('documento .pdf');
    const [id, setInfoID] = useState({});
    let history = useHistory();
    //console.log(aceptoterminos)
    async function getData() {
        var info = {
            cliente_id: cliente_id
        }
        const response = await api.post('listcompanysistem', info)
        const data = await response.data;
        setData(data);
        setInfoContaniner(false);
        window.$('#modal_default').modal('hide');
        window.$("#modaldefaultdetele").modal("hide");
    }

    async function updatelist() {
        var objec = JSON.parse(localStorage.getItem('id_token'));
        var obje = {
            query: "SELECT numero_empresa FROM activaciones where cliente_id='" + objec.cliente_id + "'"
        }
        const resultd = await api.post('configtabledinamyc', obje)//.then((r) => r.data)
        const { data } = await resultd;
        localStorage.removeItem("_numero_empresas_");
        var contador = 0;
        for (let po = 0; po < data.length; po++) {
            contador = contador + parseInt(data[po].numero_empresa)
        }
        localStorage.setItem("_numero_empresas_", contador);
    }
    useEffect(() => {
        //setAceptoTerminos();
        setInfoContaniner(true);
        if (aceptoterminos === 'true')
            getData();
        else
            setInfoContaniner(false);
    }, []);

    function modalDinamyc(i) {
        localStorage.removeItem("setdatanomina");
        localStorage.setItem("setdatanomina", JSON.stringify(i));
        history.push('/app/ui/adminnomina');
    }
    const handleChangeFile = (e) => {
        setInfoContaniner(true);
        const file = e.target.files[0];
        var year = (new Date()).getFullYear();
        if (file.type === "application/pdf") {
            setChangeName(file.name)
            const formData = new FormData();
            formData.append('file', file)
            formData.append('ejercicio', year)
            formData.append('cliente_id', cliente_id)
            api.post('/constancia', formData).then(res => res.data).then((j) => {
                ref.current.value = "";
                if (j.status === 404) {
                    setEstatusMensaje(true);
                    setAddTextMensaje(j.message);
                    setInfoContaniner(false);
                    window.$('#modal_default').modal('hide');
                    window.$("#modaldefaultdetele").modal("hide");
                    getData();
                    setChangeName('documento .pdf')
                    setTimeout(() => {
                        setEstatusMensaje(false);
                    }, 8000);
                }
                else {
                    getData();
                    setChangeName('documento .pdf')
                }

            });
        }
        else {
            setEstatusMensaje(true);
            setAddTextMensaje('El archivo importado no corresponde al documento a importar.');
            setInfoContaniner(false);
            window.$('#modal_default').modal('hide');
            window.$("#modaldefaultdetele").modal("hide");
            ref.current.value = "";
            setChangeName('documento .pdf')
            setTimeout(() => {
                setEstatusMensaje(false);
            }, 8000);
        }
    }
    function handlerDelete(id) {
        setInfoID(id);
        window.$("#modaldefaultdetele").modal("show");
    }
    function handleConfirmacionDelete() {
        var object = {
            id: parseInt(id.id)
        }
        api.post('deleteconfirmcompany', object).then(r => {
            getData();
            updatelist();
        });
    }
    const { perfil } = userData;
    return (
        <>
            <div className="content-wrapper">
                <div className="page-header border-bottom-0">
                    <div className="page-header-content header-elements-md-inline">
                        <div className="page-title d-flex">
                            <a href="#" style={{ color: "#fff" }}> <h4><i className="icon-arrow-left52 mr-2"></i> <span className="font-weight-semibold">Inicio</span></h4></a>
                            <a href="#" className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></a>
                        </div>
                        <div class="header-elements d-none mb-3 mb-md-0">
                            <div class="d-flex justify-content-center">
                                <a href="https://e-xpertum.com.mx/webadmin/api/documents/api/help/MANUAL_E-XPERTUM_MAQUILADENOMINA.pdf" target="_blank" class="btn btn-link btn-float text-default"><i class="icon-help"></i><span>Instructivo</span></a>
                            </div>
                        </div>
                    </div>
                </div>
                <Backdrop open={container} style={{ color: "#fff", background: "#B9B6B6", zIndex: 1400, opacity: "0.5" }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div className="content pt-0">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title" style={{ textAlign: 'center' }}>Expediente de cumplimiento laboral</h5>
                        </div>
                        {
                            estatusmensaje ? (<div class="alert alert-warning alert-styled-right alert-dismissible">
                                <span class="font-weight-semibold">Usuario!</span> {textmensaje}
                            </div>) : null
                        }
                        {
                            (aceptoterminos === "false") ? (
                                <>
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="alert alert-warning alert-styled-right alert-dismissible">
                                                        <span class="font-weight-semibold">Usuario!</span> Debe de aceptar los términos y condiciones para poder utilizar los servicios. <span className="font-weight-semibold"> Solución:</span> click en inicio y acepte los términos.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </>
                            ) : (
                                <>
                                    <div className="card-body">
                                        Listado de empresas
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Razón social / Nombre</th>
                                                    <th>RFC</th>
                                                    <th className="text-center">Documentación</th>
                                                    <th className="text-center">
                                                        {
                                                            (localStorage.getItem("_numero_empresas_") > infos.length) ?
                                                                (<button type="button" className="btn btn-light" data-toggle="modal" data-target="#modal_default">Subir Constancia de Situación <i className="icon-play3 ml-2"></i></button>)
                                                                : null
                                                        }

                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(infos || []).map((row) => (
                                                    <tr key={row.nombre}>
                                                        <td> {row.nombre}</td>
                                                        <td> {row.rfc}</td>
                                                        <td className="text-center">
                                                            <button type="button" className="btn bg-info" onClick={() => modalDinamyc(row)}><i className="icon-menu7 mr-2"></i> Documentación</button>
                                                        </td>
                                                        <td className="text-center">
                                                            {
                                                                perfil !== "CAPTURISTA" ? (
                                                                    <Tooltip onClick={() => handlerDelete(row)} title="Eliminar empresa" placement="top" arrow>
                                                                        <i style={{ color: '#29b6f6', cursor: 'pointer' }} className="icon-close2"></i>
                                                                    </Tooltip>
                                                                ) : null
                                                            }


                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <div id="modal_default" className="modal fade" tabIndex="-1" aria-hidden="true" style={{ "display": "none" }}>
                                            <div className="modal-dialog">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title">Importar constancia</h5>
                                                        <button type="button" className="close" data-dismiss="modal">×</button>
                                                    </div>

                                                    <div className="modal-body">
                                                        <div className="card-body">
                                                            <form>
                                                                <div className="form-group row">
                                                                    <label className="col-form-label col-lg-4">Constancia de situación fiscal</label>
                                                                    <div className="col-lg-8">
                                                                        <div className="uniform-uploader">
                                                                            <input type="file" className="form-control-uniform-custom" accept=".pdf" onChange={handleChangeFile} ref={ref} />
                                                                            <span className="filename" style={{ userSelect: 'none' }}>{textdinamyc}</span>
                                                                            <span className="action btn bg-warning" style={{ userSelect: 'none' }}>Seleccione el archivo</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div id="modaldefaultdetele" className="modal fade" tabIndex="-1" style={{ "display": "none" }} aria-hidden="true">
                                            <div className="modal-dialog">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title">Confirmar de eliminación</h5>
                                                        <button type="button" className="close" data-dismiss="modal">×</button>
                                                    </div>

                                                    <div className="modal-body">
                                                        <h6 className="font-weight-semibold">Está seguro de eliminar el siguiente registro {id.nombre}?</h6>
                                                    </div>

                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-link" data-dismiss="modal">Cancelar</button>
                                                        <button type="button" className="btn bg-primary" onClick={handleConfirmacionDelete}>Aceptar</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>)}
                    </div>
                </div>
            </div>
        </>
    )

}