import React, { Component } from 'react';
import api from '../../../../app/Config/api';
class PolicesProvee extends Component {
    constructor(props) {
        super(props);
        this.state = {
            datanolocalizado: [],
            datapresunto: [],
            datadesvirtuado: [],
            datadefinitivo: [],
        }
    }
    componentDidMount() {
        this.getData();
    }
    getData = async () => {
        let info = await api.post("listpolproveedores", this.props.data);
        let data = await info.data;
        this.setState({
            datanolocalizado: (data).filter((j) => j.clave === "001"),
            datapresunto: (data).filter((j) => j.clave === "002"),
            datadesvirtuado: (data).filter((j) => j.clave === "003"),
            datadefinitivo: (data).filter((j) => j.clave === "004")
        });
    }
    render() {
        return (
            <>
                {
                    this.state.datanolocalizado.length > 0 ? (
                        <div class="card">
                            <div class="card-header">
                                <h6 class="card-title">No localizados</h6>
                            </div>

                            <div class="card-body">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th># Póliza</th>
                                            <th>Fecha</th>
                                            <th>Concepto</th>
                                            <th># Cuenta</th>
                                            <th>Suma Debe</th>
                                            <th>Suma Haber </th>
                                            <th>Proveedor</th>
                                            <th>Comprobante</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (this.state.datanolocalizado).map((j, k) => (
                                                <tr key={k} className="text-warning">
                                                    <td>{j.numero_pol}</td>
                                                    <td>{j.fecha.substr(0, 10)}</td>
                                                    <td>{j.concepto}</td>
                                                    <td>{j.numero_cuenta}</td>
                                                    <td>{(j.debe) === 0 ? '-' : (j.debe).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                    <td>{(j.haber) === 0 ? '-' : (j.haber).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                    <td>{j.rfc}</td>
                                                    <td>{(j.importe_factura) === 0 ? '-' : (j.importe_factura).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    ) : null

                }

                {
                    this.state.datadesvirtuado.length > 0 ? (
                        <div class="card">
                            <div class="card-header">
                                <h6 class="card-title">Desvirtuados</h6>
                            </div>

                            <div class="card-body">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th># Póliza</th>
                                            <th>Fecha</th>
                                            <th>Concepto</th>
                                            <th># Cuenta</th>
                                            <th>Debe</th>
                                            <th>Haber </th>
                                            <th>Proveedor</th>
                                            <th>Comprobante</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (this.state.datadesvirtuado).map((j, k) => (
                                                <tr key={k} className="text-warning">
                                                    <td>{j.numero_pol}</td>
                                                    <td>{j.fecha.substr(0, 10)}</td>
                                                    <td>{j.concepto}</td>
                                                    <td>{j.numero_cuenta}</td>
                                                    <td>{(j.debe) === 0 ? '-' : (j.debe).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                    <td>{(j.haber) === 0 ? '-' : (j.haber).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                    <td>{j.rfc}</td>
                                                    <td>{(j.importe_factura) === 0 ? '-' : (j.importe_factura).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    ) : null
                }
                {
                    this.state.datapresunto.length > 0 ? (
                        <div class="card">
                            <div class="card-header">
                                <h6 class="card-title">Presuntos</h6>
                            </div>

                            <div class="card-body">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th># Póliza</th>
                                            <th>Fecha</th>
                                            <th>Concepto</th>
                                            <th># Cuenta</th>
                                            <th>Suma Debe</th>
                                            <th>Suma Haber </th>
                                            <th>Proveedor</th>
                                            <th>Comprobante</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (this.state.datapresunto).map((j, k) => (
                                                <tr key={k} className="text-warning">
                                                    <td>{j.numero_pol}</td>
                                                    <td>{j.fecha.substr(0, 10)}</td>
                                                    <td>{j.concepto}</td>
                                                    <td>{j.numero_cuenta}</td>
                                                    <td>{(j.debe) === 0 ? '-' : (j.debe).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                    <td>{(j.haber) === 0 ? '-' : (j.haber).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                    <td>{j.rfc}</td>
                                                    <td>{(j.importe_factura) === 0 ? '-' : (j.importe_factura).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    ) : null
                }
                {
                    this.state.datadefinitivo.length > 0 ? (
                        <div class="card">
                            <div class="card-header">
                                <h6 class="card-title">Definitivos</h6>
                            </div>

                            <div class="card-body">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th># Póliza</th>
                                            <th>Fecha</th>
                                            <th>Concepto</th>
                                            <th># Cuenta</th>
                                            <th>Suma Debe</th>
                                            <th>Suma Haber </th>
                                            <th>Proveedor</th>
                                            <th>Comprobante</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (this.state.datadesvirtuado).map((j, k) => (
                                                <tr key={k} className="text-danger">
                                                    <td>{j.numero_pol}</td>
                                                    <td>{j.fecha.substr(0, 10)}</td>
                                                    <td>{j.concepto}</td>
                                                    <td>{j.numero_cuenta}</td>
                                                    <td>{(j.debe) === 0 ? '-' : (j.debe).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                    <td>{(j.haber) === 0 ? '-' : (j.haber).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                    <td>{j.rfc}</td>
                                                    <td>{(j.importe_factura) === 0 ? '-' : (j.importe_factura).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    ) : null
                }

            </>
        );
    }
}

export default PolicesProvee;