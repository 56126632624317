import React, { Component } from 'react';
import api from '../../Config/apifiles'
import { Tooltip, } from "@material-ui/core";
import RenetcionesIVA from './papel-trabajo/receipt-work-paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Skeleton from '@material-ui/lab/Skeleton';
import './style.css';
class Recibidos extends Component {
    constructor(props) {
        super(props);
        let objects = JSON.parse(localStorage.getItem("datacompanyall"));
        this.state = {
            empresa_id: objects.id,
            nombrecompany: objects.nombre,
            rfc: objects.rfc,
            data: [],
            viewheader: true,
            viewbody: false,
            viewdetails: false,
            viewpeperwork: '',
            title: 'Retenciones de IVA anuales (CFDI vs Enteradas)',
            details: [],
            total_retencion_iva_head_factura: 0,
            total_retencion_iva_head_declaracion: 0,
            total_diferencia_retencion_iva_head: 0,
            total_retencion_iva_body_factura: 0,
            total_retencion_iva_body_declaracion: 0,
            total_diferencia_retencion_iva_body: 0,
            loading: true,
            linkpdf: '',
            loadingfirts: false,
            clave: '',
            filterdeclaracion: [], declaraciones: [],
            periodos_filter_: [],
            viewmetadata: false,
            urlmetadata: '',
            ejercicio: 0,
            periodo: '',
            nameperiod: ''
        }
    }
    componentDidMount() {
        this.getdataaudite();
    }
    getdataaudite = async () => {
        let _data_ejercicios_ = await api.post("app-relations-69b", { empresa_id: this.state.empresa_id });
        let data_ejercicios_all = (_data_ejercicios_.data).ejercicio;
        //// console.log(data_ejercicios_all)
        let _config_data_tmp_ = await api.post('getdataadmindata', { empresa_id: this.state.empresa_id });
        let data_ = await api.post('report-validacion-sat-recibido', { empresa_id: this.state.empresa_id });
        let total_retencion_iva_head_factura = 0;
        let total_retencion_iva_head_declaracion = 0;
        let total_diferencia_retencion_iva_head = 0;
        let { facturado, declarado } = await data_.data;
        if (facturado.length > 0) {
            for (let t = 0; t < facturado.length; t++) {
                facturado[t].alert = (data_ejercicios_all).filter((s) => s.ejercicio == facturado[t].ejercicio);
                total_retencion_iva_head_factura = total_retencion_iva_head_factura + facturado[t].retenciones_iva;
                total_retencion_iva_head_declaracion = total_retencion_iva_head_declaracion + facturado[t].ingresos_declarados;
                facturado[t].diferencias = (facturado[t].retenciones_iva - facturado[t].ingresos_declarados);
                total_diferencia_retencion_iva_head = total_diferencia_retencion_iva_head + facturado[t].diferencias;
            }
        }
        this.setState({
            viewmetadata: false,
            data: facturado, total_retencion_iva_head_factura: total_retencion_iva_head_factura,
            total_retencion_iva_head_declaracion: total_retencion_iva_head_declaracion,
            total_diferencia_retencion_iva_head: total_diferencia_retencion_iva_head,
            loading: false,
            clave: _config_data_tmp_.data,
            periodos_filter_: (_data_ejercicios_.data).periodo
        });
    }
    viewdetailsbody = async (i) => {
        this.setState({ loading: true })
        let data_ = await api.post('report-validacion-sat-recibido-details', { empresa_id: this.state.empresa_id, ejercicio: i.ejercicio });
        let { facturado, declarado } = await data_.data;
        let total_retencion_iva_body_factura = 0;
        let total_retencion_iva_body_declaracion = 0;
        let total_diferencia_retencion_iva_body = 0;
        if (facturado.length > 0) {
            for (let t = 0; t < facturado.length; t++) {

                facturado[t].aplicacion = (this.state.periodos_filter_).filter((df) => df.ejercicio == facturado[t].ejercicio && df.periodo == facturado[t].periodo)

                total_retencion_iva_body_factura = total_retencion_iva_body_factura + facturado[t].retenciones_iva;
                total_retencion_iva_body_declaracion = total_retencion_iva_body_declaracion + facturado[t].ingresos_declarados;
                facturado[t].diferencias = (facturado[t].retenciones_iva - facturado[t].ingresos_declarados);
                total_diferencia_retencion_iva_body = total_diferencia_retencion_iva_body + facturado[t].diferencias;
            }
        }
        this.setState({
            viewmetadata: false,
            title: 'Retenciones de IVA Mensuales (CFDI vs Enteradas)',
            details: facturado,
            declaraciones: declarado,
            viewheader: false,
            viewbody: true,
            total_retencion_iva_body_factura: total_retencion_iva_body_factura,
            total_retencion_iva_body_declaracion: total_retencion_iva_body_declaracion,
            total_diferencia_retencion_iva_body: total_diferencia_retencion_iva_body,
            loading: false
        });
    }
    _viewInit_ = async () => {
        this.setState({
            viewmetadata: false,
            title: 'Retenciones de IVA anuales (CFDI vs Enteradas)',
            viewheader: true,
            viewbody: false,
        });
    }
    _view_data_filter_ = async (o) => {
        this.setState({
            viewmetadata: false,
            ejercicio: o.ejercicio,
            periodo: o.periodo,
            nameperiod: ' CFDIs emitidos de ' + o.namemonth + ' de ' + o.ejercicio,
            title: 'CFDIs con retención de IVA de ' + o.namemonth + ' de ' + o.ejercicio,
            viewheader: false,
            viewbody: false,
            viewdetails: true,
            viewpeperwork: <RenetcionesIVA data={o} functionHandle={this.handleViewMetadata} />
        });
    }
    view_details_init_ = async (g) => {
        this.setState({
            viewmetadata: false,
            title: 'Retenciones de IVA Mensuales (CFDI vs Enteradas)',
            viewheader: false,
            viewbody: true,
            viewdetails: false,
            viewpeperwork: ''
        });
    }
    handleViewPDF = () => {
        let linkpdf = 'https://e-xpertum.com.mx/adminfile/api/export-pdf-validacion-sat-recibido/' + this.state.rfc + '/' + this.state.nombrecompany + '/' + this.state.clave
        var win = window.open(linkpdf, '_blank');
        if (win) {
            win.focus();
        } else {
            alert('Please allow popups for this website');
        }
        //this.setState({ loadingfirts: true, })
        //window.$("#modallargepdf").modal("show");
    }
    handleLoading = async () => {
        this.setState({
            viewmetadata: false,
            loadingfirts: false
        })
    }
    handleFilterArrayData = async (t) => {
        window.$("#modallistdeclaracion").modal("show");

        let info_data = (this.state.declaraciones.filter((h) => h.ejercicio === t.ejercicio && h.period === t.periodo)).sort(function (a, b) {
            return a.numero_de_operacion - b.numero_de_operacion;
        });
        //console.log(info_data)
        this.setState({ viewmetadata: false, subtitlemodal: (t.namemonth + ' de ' + t.ejercicio), filterdeclaracion: info_data })

    }
    handleViewPDFDeclaracion = (t) => {
        let linkpdf = 'https://e-xpertum.com.mx/adminfile/api/displayfilepdf/bd54bba3f9d85ea1426942775518b90b6/ecb868faf29054fedf1ac6cbac5fc63/' + t.directorio;
        var win = window.open(linkpdf, '_blank');
        if (win) {
            win.focus();
        } else {
            alert('Please allow popups for this website');
        }
    }
    handleViewMetadata = async () => {
        this.setState({
            title: this.state.nameperiod,
            viewmetadata: true,
            viewheader: false,
            viewbody: false,
            viewdetails: false,
            urlmetadata: 'https://e-xpertum.com.mx/invoces/report-metadata-x?rfc=' + this.state.rfc + '&empresa_id=' + this.state.empresa_id + '&ejercicio=' + this.state.ejercicio + '&periodo=' + this.state.periodo + '&tipo=RECIBIDO',
        });
    }
    handledownloadfiles = () => {
        window.open("https://e-xpertum.com.mx/invoces/api/export-excel-sat-recibido/" + this.state.empresa_id);
    }
    render() {
        return (
            <>
                <div className="content pt-0">
                    <div className="card">
                        <div className="card-header header-elements-inline">
                            <h6 className="card-title">{this.state.title}</h6>
                            {(this.state.viewbody && this.state.viewpeperwork === '') ? (
                                <div className="d-flex align-items-center" style={{ cursor: 'pointer' }} onClick={this._viewInit_}>
                                    <i className="icon-arrow-left16 mr-3 icon-2x  text-primary"></i>
                                    <div className="text-primary">Inicio</div>
                                </div>
                            ) : (this.state.viewbody === false && this.state.viewpeperwork !== '') ? (
                                <div className="d-flex align-items-center" style={{ cursor: 'pointer' }} onClick={this.view_details_init_}>
                                    <i className="icon-arrow-left16 mr-3 icon-2x text-primary"></i>
                                    <div className="text-primary">Detalle</div>
                                </div>
                            ) : (
                                <div>
                                    <button type="button" className="btn btn-light" onClick={this.handledownloadfiles} style={{ margin: '0 10px' }}><i className="icon-file-excel mr-2 text-success"></i> Excel</button>
                                    <button type="button" className="btn btn-light" onClick={this.handleViewPDF}><i className="icon-file-pdf mr-2 text-success"></i> PDF</button>
                                </div>

                            )}

                        </div>
                        <div className="card-body">
                            {
                                this.state.loading ? (
                                    <CircularProgress style={{ color: 'white', position: 'absolute', top: '50%', left: '49%' }}></CircularProgress>
                                ) : null
                            }
                            {
                                (this.state.viewheader === true && this.state.viewbody === false && this.state.viewdetails === false) ?
                                    (
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th style={{ textAlign: 'center' }}>Ejercicio</th>
                                                    <th style={{ textAlign: 'center' }}>Retenciones de IVA</th>
                                                    <th style={{ textAlign: 'center' }}>Retenciones de IVA declarados</th>
                                                    <th style={{ textAlign: 'center' }}>Diferencias</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (this.state.data || []).map((h, l) => (
                                                        <tr key={l}>
                                                            <td style={{ textAlign: 'right' }}>{h.alert.length > 0 ? (<Tooltip title="Existen uno o más proveedores tomados en cuenta para la retención de iva." placement="top" arrow><i className="icon-alert mr-3 text-danger"></i></Tooltip>) : null}</td>
                                                            <td style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => this.viewdetailsbody(h)}>
                                                                <Tooltip title="Clic para ver comparativo mensual" placement="top" arrow>
                                                                    <button onClick={() => this.viewdetailsbody(h)} type="button" className="btn btn-outline-primary btn-lg" style={{ width: '100%' }}><i className="icon-unlink mr-2"></i>{h.ejercicio}</button>
                                                                </Tooltip>
                                                            </td>
                                                            <td style={{ textAlign: 'right' }}>{h.retenciones_iva.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                            <td style={{ textAlign: 'right' }}>{h.ingresos_declarados.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                            <td style={{ textAlign: 'right' }}>
                                                                {
                                                                    (h.diferencias.toFixed(0) > 0) ?
                                                                        (
                                                                            <span className="text-danger" style={{ fontSize: 12, textAlign: 'right' }}>{h.diferencias.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>
                                                                        ) : <span className="text-success" style={{ fontSize: 12, textAlign: 'right' }}>{h.diferencias.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td></td>
                                                    <td style={{ textAlign: 'right' }}>Totales: </td>
                                                    <td style={{ textAlign: 'right' }}>{this.state.total_retencion_iva_head_factura.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                    <td style={{ textAlign: 'right' }}>{this.state.total_retencion_iva_head_declaracion.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                    <td>
                                                        {this.state.total_diferencia_retencion_iva_head === 0 ? '' : (
                                                            <>
                                                                {
                                                                    (this.state.total_diferencia_retencion_iva_head < 0) ? (
                                                                        <a className="btn bg-transparent text-danger-100 border-success ml-1" style={{ textAlign: 'right', width: '100%' }}>
                                                                            <span className='text-success'>{(this.state.total_diferencia_retencion_iva_head * -1).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>
                                                                        </a>
                                                                    ) : (
                                                                        <a className="btn bg-transparent text-danger-100 border-warning ml-1" style={{ textAlign: 'right', width: '100%' }}>
                                                                            <span className='text-danger'>{this.state.total_diferencia_retencion_iva_head.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>
                                                                        </a>
                                                                    )
                                                                }
                                                            </>
                                                        )
                                                        }
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    ) : null
                            }
                            {
                                (this.state.viewheader === false && this.state.viewbody === true && this.state.viewdetails === false) ?
                                    (
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th></th>

                                                    <th style={{ textAlign: 'center' }}>Mes</th>
                                                    <th style={{ textAlign: 'center' }}>Retenciones de IVA</th>
                                                    <th style={{ textAlign: 'center' }}>Retenciones de IVA declarados</th>
                                                    <th style={{ textAlign: 'center' }}>Diferencias</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (this.state.details || []).map((h, l) => (
                                                        <tr key={l}>
                                                            <td style={{ textAlign: 'right' }}>{h.aplicacion.length > 0 ? (<Tooltip title="Existen uno o más proveedores tomados en cuenta para la retención de iva en este mes." placement="top" arrow><i className="icon-alert mr-3 text-danger"></i></Tooltip>) : null}</td>
                                                            <td style={{ textAlign: 'center' }}>
                                                                <Tooltip title="Clic para ver las facturas de retención" placement="top" arrow>
                                                                    <button type="button" className="btn btn-outline-primary btn-lg" style={{ width: '100%' }} onClick={() => this._view_data_filter_(h)}><i className="icon-file-xml mr-2" style={{ float: 'left' }}></i> {h.namemonth + " "}  {h.ejercicio}</button>
                                                                </Tooltip>
                                                            </td>
                                                            <td style={{ textAlign: 'right' }}>{h.retenciones_iva.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                            <td style={{ textAlign: 'right' }}>
                                                                <Tooltip title="Clic para ver las declaraciones de retenciones de IVA" placement="top" arrow>
                                                                    <button type="button" className="btn btn-outline-primary btn-lg" style={{ width: '100%' }} onClick={() => this.handleFilterArrayData(h)}><i className="icon-stack mr-2"></i>{h.ingresos_declarados.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</button>
                                                                </Tooltip>
                                                            </td>
                                                            {/*<td style={{ textAlign: 'right', cursor: 'pointer' }} onClick={() => this.handleFilterArrayData(h)}><Tooltip title="Clic para ver las facturas de retención" placement="top" arrow><span className="text-primary">{h.ingresos_declarados.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span></Tooltip></td>*/}
                                                            <td style={{ textAlign: 'right' }}> {
                                                                (h.diferencias.toFixed(0) > 0) ?
                                                                    (
                                                                        <span className="text-danger" style={{ fontSize: 12, textAlign: 'right' }}>{h.diferencias.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>
                                                                    ) : (h.diferencias === 0 ? '' : (<span className="text-success" style={{ fontSize: 12, textAlign: 'right' }}>{h.diferencias.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>))
                                                            }</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td></td>
                                                    <td style={{ textAlign: 'right' }}>Totales: </td>
                                                    <td style={{ textAlign: 'right' }}>{this.state.total_retencion_iva_body_factura.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                    <td style={{ textAlign: 'right' }}>{this.state.total_retencion_iva_body_declaracion.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                    <td>
                                                        {this.state.total_diferencia_retencion_iva_body === 0 ? '' : (
                                                            <>
                                                                {
                                                                    (this.state.total_diferencia_retencion_iva_body < 0) ? (
                                                                        <a className="btn bg-transparent text-danger-100 border-success ml-1" style={{ textAlign: 'right', width: '100%' }}>
                                                                            <span className='text-success'>{(this.state.total_diferencia_retencion_iva_body * -1).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>
                                                                        </a>
                                                                    ) : (
                                                                        <a className="btn bg-transparent text-danger-100 border-warning ml-1" style={{ textAlign: 'right', width: '100%' }}>
                                                                            <span className='text-danger'>{this.state.total_diferencia_retencion_iva_body.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>
                                                                        </a>
                                                                    )
                                                                }
                                                            </>
                                                        )}
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    ) : null
                            }

                            {
                                (this.state.viewheader === false && this.state.viewbody === false && this.state.viewdetails === true) ?
                                    (
                                        <>
                                            {this.state.viewpeperwork}
                                        </>
                                    ) : null
                            }
                            {
                                (this.state.viewmetadata) ? (
                                    <>
                                        <div className="embed-container">
                                            <iframe src={this.state.urlmetadata} onLoad={this.handleLoading}
                                                width="100%" title='Report de metadata'
                                                frameBorder="0" allowFullScreen
                                            />
                                        </div>
                                    </>
                                ) : null
                            }
                        </div>
                    </div>
                </div>
                <div id="modallargepdf" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                    <div className="modal-dialog modal-full">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Reporte de Retención de IVA</h5>
                                <button type="button" className="close" data-dismiss="modal" onClick={() => this.setState({ linkpdf: '' })}>×</button>
                            </div>
                            <div className="modal-body">
                                <div className="embed-container">
                                    {
                                        (this.state.loadingfirts) ?
                                            (
                                                <div>
                                                    <Skeleton variant="text" />
                                                    <Skeleton variant="circle" animation="wave" width={40} height={40} />
                                                    <Skeleton variant="rect" animation="wave" width={510} height={118} />
                                                </div>
                                            ) : null
                                    }
                                    <iframe src={this.state.linkpdf} onLoad={this.handleLoading}
                                        width="100%"
                                        frameBorder="0" allowFullScreen
                                    >
                                    </iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modallistdeclaracion" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                    <div className="modal-dialog modal-full">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Listado de ingresos declarados de {this.state.subtitlemodal} </h5>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>
                            <div className="modal-body">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Retenciones de IVA</th>
                                            <th>Número de operación</th>
                                            <th>Tipo declaración</th>
                                            <th>Fecha de presentación</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (this.state.filterdeclaracion || []).map((y, p) => (
                                                <tr key={p}>
                                                    <td>{y.retencion_iva.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                    <td>{y.numero_de_operacion}</td>
                                                    <td>{y.tipo_declaracion}</td>
                                                    <td>{y.fecha_de_presentacion}</td>
                                                    <td><button type="button" className="btn btn-light" onClick={() => this.handleViewPDFDeclaracion(y)}><i className="icon-file-pdf text-success"></i></button></td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Recibidos;