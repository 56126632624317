import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionDetails, AccordionSummary, Typography, Card } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BalanceAfter from './balanceafter';
import Balance from './balancebefore';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.success.light,
    },
}));

export default function ControlledAccordions() {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className={classes.root}>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography className={classes.heading}>
                        <span style={{ color: 'white' }}>Estado de posición financiera de 2022</span>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Card style={{ width: "100%" }}>
                        <BalanceAfter />
                    </Card>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header" >
                    <Typography className={classes.heading}>
                        <span style={{ color: 'white' }}>Estado de posición financiera hasta 2021</span>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Card style={{ width: "100%" }}>
                        <Balance />
                    </Card>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}