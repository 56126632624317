import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
// components
import Layout from "./Layout/Layout";
// pages
import Error from "../pages/error";
import Login from "../pages/login";
import Recover from "../pages/recover/recover_password";
import FormRecover from '../pages/recover/form_recover_pass';
import UploadFilesAdmin from '../client/extras/index';
/***********
 * REGISTER NEW USER
 */
import RegisterNewUser from "../v2-register/register-new-user";
import RegisterEfirmaExterno from "../credencials";
/****************
 * Register user buro
 */
import RegisterBuro from "../v2-register";
import UploadEfirma from "../v2-register/upload_e-firma";
import LoginAuth from '../v2-register/login';
// context
import { useUserState } from "../context/UserContext";
export default function App() {
  // global
  var { isAuthenticated } = useUserState();
  return (
    <HashRouter>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/app/dashboard" />} />
        <Route exact path="/app" render={() => <Redirect to="/app/dashboard" />} />
        <PrivateRoute path="/app" component={Layout} />
        <PublicRoute path="/login" component={Login} />
        <Route path="/recupere" component={Recover} />
        <Route path="/recupere-pass/:url" component={FormRecover} />
        <Route path="/validation-efirma/:id" component={UploadFilesAdmin} />
        <Route path="/register-auth/:id/compliance" component={RegisterNewUser} />
        <Route path="/login-auth" component={LoginAuth} />
        <Route path="/buro-register/:id/compliance" component={RegisterBuro} />
        <Route path="/welcome-new-user/:data/compliance" component={UploadEfirma} />
        <Route path="/register-efirma/:data/compliance" component={RegisterEfirmaExterno} />
        <Route component={Error} />
      </Switch>
    </HashRouter>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route  {...rest} render={props =>
        isAuthenticated ? (React.createElement(component, props)) : (
          <Redirect
            to={{
              pathname: "/login", state: { from: props.location, },
            }} />
        )} />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route {...rest} render={props => isAuthenticated ? (
        <Redirect to={{ pathname: "/", }} />
      ) : (
        React.createElement(component, props)
      )} />
    );
  }
}
