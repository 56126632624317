import React, { Component } from 'react'
///import MultiSelect from "react-multi-select-component";
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import api from '../../../app/Config/api';
import connect from '../../../app/Config/apifiles';
///import ka from 'date-fns/esm/locale/ka/index';
class suscripcionedit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            cliente_id: '', //(JSON.parse(localStorage.getItem('id_token'))).cliente_id,
            proviene: 'Suscripcion',
            fecha_fin: '',
            fecha_inicio: '',
            numero_usuario: 0,
            numero_empresa: 0,
            suscripcion_id: '',
            servicios: [],
            servicio_id: [],
            sistemas: [],
            sistemasSelect: [],
            suscripcion: [], error: false,
            vertical: 'top',
            horizontal: 'right',
            open: false,
            message: '',
            dato_fiscal_id: '',
            acceso: ''
        };
    }
    componentDidMount() {
        console.log('Acá ingresamos ')
        this.setState(this.props.suscripcion);
        var obj = this.props.suscripcion;
        this.getcatmodulo();
    }

    getcatmodulo = async () => {
        let _data_ = await api.get('listModulo');
        let array_dat = await _data_.data;
        var obj = (this.props.suscripcion).details;
        for (let l = 0; l < array_dat.length; l++) {
            let result_ = (obj.filter((j) => j.value === array_dat[l].id));
            if (result_.length > 0) {
                array_dat[l].aquirido = true;
                array_dat[l].fecha_fin = result_[0].fecha_fin;
                array_dat[l].fecha_inicio = result_[0].fecha_inicio;
                array_dat[l].servicio_id = result_[0].id
            }
            else {
                array_dat[l].aquirido = false;
                array_dat[l].fecha_fin = '';
                array_dat[l].fecha_inicio = '';
                array_dat[l].servicio_id = 0;
            }
        }
        this.setState({
            servicios: array_dat
        });
    }


    handleChangeForm = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });
    }
    handleSelect = (i) => {
        this.setState({ servicio_id: i })
    }
    handleUpdateAll = () => {
        api.post("/update-activacion-service-all", this.state).then((j) => j.data).then((h) => {
            if (h.status === 200) {
                this.setState({ open: true, message: 'Se ha actualizado correctamente la suscripción' });
                setTimeout(() => {
                    this.setState({ open: false })
                }, 3000);
            }
        }).finally((U) => {
            console.log(U)
        });
    }

    handleSelectService = async (i) => {
        let _dat_servicios_add_ = (this.state.servicios);//.filter((h) => h.id === i.id);
        let options = {
            modulo_id: i.id,
            numero_empresa: this.state.numero_empresa,
            numero_usuario: this.state.numero_usuario,
            cliente_id: this.state.cliente_id,
            dato_fiscal_id: this.state.dato_fiscal_id,
        }
        let result_servicio = await api.post('save-new-service', options);
        let { data } = await result_servicio.data;
        for (let h = 0; h < _dat_servicios_add_.length; h++) {
            if (_dat_servicios_add_[h].id === i.id) {
                _dat_servicios_add_[h].aquirido = true;
                _dat_servicios_add_[h].fecha_fin = data.fecha_fin;
                _dat_servicios_add_[h].fecha_inicio = data.fecha_inicio;
                _dat_servicios_add_[h].servicio_id = data.id;
            }
        }
        this.setState({
            servicios: _dat_servicios_add_
        });
    }
    handleDeSelectService = async (k) => {
        let _dat_servicios_add_ = (this.state.servicios);
        let result_servicio = await api.post('detele-servicio-add', { id: k.servicio_id });
        for (let h = 0; h < _dat_servicios_add_.length; h++) {
            if (_dat_servicios_add_[h].id === k.id) {
                _dat_servicios_add_[h].aquirido = false;
                _dat_servicios_add_[h].fecha_fin = '';
                _dat_servicios_add_[h].fecha_inicio = '';
                _dat_servicios_add_[h].servicio_id = 0;
            }
        }
        this.setState({
            servicios: _dat_servicios_add_
        });
    }
    handleUpdateDateEnd = async (k) => {
        let _dat_servicios_add_ = (this.state.servicios);
        let options = {
            id: k.g.servicio_id,
            fecha_fin: k.text.target.value
        }
        let result = await api.post('update-activacion-service', options);
        let { data } = await result.data;
        for (let h = 0; h < _dat_servicios_add_.length; h++) {
            if (_dat_servicios_add_[h].id === k.g.id) {
                _dat_servicios_add_[h].aquirido = true;
                _dat_servicios_add_[h].fecha_fin = data.fecha_fin;
                _dat_servicios_add_[h].fecha_inicio = data.fecha_inicio;
                _dat_servicios_add_[h].servicio_id = data.id;
            }
        }
        this.setState({
            servicios: _dat_servicios_add_
        });
    }
    handleChangeFiles = async () => {
        let result = await connect.post('change-status-client', { cliente_id: this.state.cliente_id });
        console.log(result)
        this.setState({ acceso: 'SUSCRIPCION' });
    }
    render() {
        return (
            <>
                <div className="card">
                    <div className="card-body">
                        <Collapse in={this.state.open}>
                            <Alert> {this.state.message} </Alert>
                        </Collapse>
                        <div autoComplete="off">
                            <div className="form-group row">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label>Número de usuarios</label>
                                            <input type="number" value={this.state.numero_usuario} name="numero_usuario" className="form-control" placeholder="Número de usuarios" onChange={this.handleChangeForm} />
                                        </div>
                                        <div className="col-md-4">
                                            <label>Número de empresas</label>
                                            <input type="number" value={this.state.numero_empresa} name="numero_empresa" className="form-control" placeholder="Número de empresas" onChange={this.handleChangeForm} />
                                        </div>
                                        <div className="col-md-4">
                                            <div class="text-center">
                                                {
                                                    (this.state.acceso === '' || this.state.acceso === 'DEMO') ?
                                                        (
                                                            <>
                                                                <h6 className="m-0 font-weight-semibold">Cambiar el estatus a Cliente</h6>
                                                                <button type="button" className="btn btn-light" onClick={this.handleChangeFiles}><i className="icon-shuffle mr-2"></i> Cambiar</button>
                                                            </>
                                                        ) :
                                                        (
                                                            <>
                                                                <h6 className="m-0 font-weight-semibold">Cliente</h6>
                                                                <button type="button" className="btn btn-outline-success btn-icon"><i className="icon-checkmark3 text-success"></i></button>
                                                            </>
                                                        )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-lg-12">
                                    <table className="table">
                                        <tbody>
                                            {
                                                (this.state.servicios || []).map((g, h) => (
                                                    <tr key={h}>
                                                        {
                                                            g.aquirido ? (
                                                                <td>
                                                                    <label className="custom-control custom-control-success custom-checkbox mb-2" onClick={() => this.handleDeSelectService(g)}>
                                                                        <input type="checkbox" className="custom-control-input" defaultChecked />
                                                                        <span className="custom-control-label"></span>
                                                                    </label>
                                                                </td>
                                                            ) : <td>
                                                                <label className="custom-control custom-control-success custom-checkbox mb-2" onChange={() => this.handleSelectService(g)}>
                                                                    <input type="checkbox" className="custom-control-input" />
                                                                    <span className="custom-control-label"></span>
                                                                </label>
                                                            </td>
                                                        }
                                                        <td>{g.descripcion}</td>
                                                        <td>{g.fecha_inicio}</td>
                                                        {
                                                            g.aquirido ? (<td><input type="date" value={g.fecha_fin} className="form-control" onChange={(text) => this.handleUpdateDateEnd({ text, g })} /></td>) : <td></td>
                                                        }
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="text-right">
                                <button type="submit" className="btn btn-primary" onClick={this.handleUpdateAll}>Actualizar<i className="icon-paperplane ml-2"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default suscripcionedit;