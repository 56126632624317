import React, { Component } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import api from '../../../Config/api';
class CotizacionAdmin extends Component {
    constructor(props) {
        super(props);
        this.array_config = [];
        this.state = {
            clave: '',
            listconfig: [],
            servicios: [], viewAuditoria: false, viewValidador: false,
            viewNomina: false,
            tableprecio: [],
            tipopersona: '',
            descripcion: '',
            importetotal: 0, configauditor: 0, configvalidador: 0, confignomina: 0, configadministrador: 0,
            importetotalmensual: 0, mensajeerror: false, mensaje: '',
            zonafronte: '', zonacentro: '', iva: '', status: false, numerotrabajadores: 0,
            descriptionbank: '',
            procentaje: 0, tipocotizacion: '', cotizacionmensual: '', cotizacionanual: '', catbank: []
        }
        this.handleSendSubmitSave = this.handleSendSubmitSave.bind();
    }
    componentDidMount() {
        this.listModulosAd();
        this.listarrayservices();
    }
    listarrayservices = async () => {
        let servicios = await api.post("getlistservicioconfig");
        let listServicio = await servicios.data;
        let catbank = await api.post("listcatbank");
        let banks = await catbank.data;
        this.setState({ listconfig: listServicio, catbank: banks });
    }
    listModulosAd = async () => {
        let servicios = await api.get("listModulo");
        let listServicio = await servicios.data;
        for (let j = 0; j < listServicio.length; j++) {
            listServicio[j].isChecked = false;
        }
        this.setState({ servicios: (listServicio).filter((y) => y.textdescription !== null) });
    }
    handleChange = (event) => {
        if (this.state.tipopersona !== '') {
            let _option = (this.state.listconfig).filter((j) => j.clave === event.target.name && j.tipopersona === this.state.tipopersona);
            if (_option.length > 0 && event.target.checked === true) {
                let lisservicios = this.state.servicios;
                let cuerpo_mensaje = '';
                lisservicios.forEach(servicio => {
                    if (servicio.parent_id === event.target.name) {
                        servicio.isChecked = event.target.checked
                        cuerpo_mensaje = servicio.textdescription
                    }
                });
                this.setState({ servicios: lisservicios, });
                this.array_config = this.state.tableprecio;
                this.array_config.push({
                    text: event.target.value,
                    clave: event.target.name,
                    id: _option[0].servicio_id,
                    importe: _option[0].importe,
                    importemensual: (event.target.name === "5000" ? _option[0].importe : (_option[0].importe / 12)),
                    textmail: cuerpo_mensaje,
                    descripcion: (this.state.tipopersona === "PM" ? "Persona Moral" : (this.state.tipopersona === "PF" ? "Persona Fisica" : ""))
                });
                if (event.target.name === "1000")
                    this.setState({ viewAuditoria: event.target.checked, filterservice: _option, });
                else if (event.target.name === "4000")
                    this.setState({ viewValidador: event.target.checked, tableprecio: this.array_config, importetotal: ((_option[0].importe) + (this.state.importetotal)), filterserviceValidador: _option, });
                else if (event.target.name === "5000") {
                    if (this.state.tipopersona === "PM")
                        this.setState({ viewNomina: event.target.checked, confignomina: _option[0].servicio_id, filterservicenomina: _option, });
                    else
                        this.setState({ numerotrabajadores: 0, confignomina: _option[0].servicio_id, filterservicenomina: _option, });
                }
                else if (event.target.name === "6000")
                    this.setState({ viewNomina: event.target.checked, configadministrador: _option[0].servicio_id, filterservicenomina: _option, });


            }
            else {
                if (event.target.checked === false) {
                    if (event.target.name === "1000")
                        this.setState({ viewAuditoria: event.target.checked, configauditor: 0, })
                    else if (event.target.name === "4000")
                        this.setState({ viewValidador: event.target.checked, configvalidador: 0 })
                    else if (event.target.name === "5000")
                        this.setState({ viewNomina: event.target.checked, confignomina: 0 });
                    else if (event.target.name === "6000")
                        this.setState({ viewNomina: event.target.checked, configadministrador: 0 });

                    let sumatoriaall = 0;
                    let listatableprecios = (this.state.tableprecio).filter((i) => i.clave !== event.target.name)
                    if (listatableprecios.length > 0) {
                        for (let t = 0; t < listatableprecios.length; t++) {
                            sumatoriaall = sumatoriaall + listatableprecios[t].importe;
                        }
                    }
                    let lisservicios = this.state.servicios;
                    lisservicios.forEach(servicio => {
                        if (servicio.parent_id === event.target.name)
                            servicio.isChecked = event.target.checked
                    });
                    this.setState({ servicios: lisservicios, tableprecio: listatableprecios, importetotal: sumatoriaall });
                }
            }
        }
        else {
            this.setState({ mensaje: 'Debe de seleccionar el tipo de persona', mensajeerror: true });
            setTimeout(() => {
                this.setState({ mensaje: '', mensajeerror: false });
            }, 4000);
        }
    };
    handleChangeFilterPerson = (i) => {
        console.log('==========>>>>>>>>>>', i);
        console.log('==========>>>>>>>>>>', i);
        this.setState({
            tipopersona: i.target.value
        })
        setTimeout(() => {
            this.datacompartfilter();
        }, 200);

    }
    datacompartfilter = () => {
        let array_list_modif_ = this.state.servicios;
        for (let l = 0; l < array_list_modif_.length; l++) {
            array_list_modif_[l].isChecked = false;
        }
        console.log('==========>>>>>>>>>>');
        setTimeout(() => {
            this.setState({
                servicios: array_list_modif_,
                filterservicenomina: [], filterservice: [], filterserviceValidador: [], viewAuditoria: false,
                viewValidador: false,
                viewNomina: false, tableprecio: [], importetotal: 0, importetotalmensual: 0, zonafronte: '', zonacentro: '', iva: '',
                procentaje: 0, tipocotizacion: '', cotizacionmensual: '', cotizacionanual: ''
            })
        }, 200);
    }
    handleChangeFactura = (event) => {
        this.setState({ configauditor: event.target.value })
        /*let paremt = (event.target.value).split('&');
        let _option = (this.state.listconfig).filter((j) => j.id === paremt[0]);
        let sumimport = 0, sumimportmensual = 0;
        for (let k = 0; k < this.array_config.length; k++) {
            if (this.array_config[k].id === paremt[1]) {
                this.array_config[k].importe = _option[0].importe;
                this.array_config[k].importemensual = (_option[0].importe) / 12;
                this.array_config[k].descripcion = _option[0].descripcion;
            }
            //sumimport = sumimport + _option[0].importe
            sumimport = sumimport + this.array_config[k].importe;
            sumimportmensual = sumimport + this.array_config[k].importemensual;
        }
        this.setState({ tableprecio: this.array_config, importetotal: sumimport, importetotalmensual: sumimportmensual });*/
    }
    handleChangeFacturaNomina = (event) => {
        let paremt = (event.target.value).split('&');
        let _option = (this.state.listconfig).filter((j) => j.id === paremt[0]);
        let sumimport = 0, sumimportmensual = 0;
        for (let k = 0; k < this.array_config.length; k++) {
            if (this.array_config[k].id === paremt[1]) {
                this.array_config[k].importe = _option[0].importe;
                this.array_config[k].importemensual = (_option[0].importe);
                this.array_config[k].descripcion = _option[0].descripcion;
            }
            //sumimport = sumimport + _option[0].importe
            sumimport = sumimport + this.array_config[k].importe;
            sumimportmensual = sumimport + this.array_config[k].importemensual;
        }
        this.setState({ tableprecio: this.array_config, importetotal: sumimport, importetotalmensual: sumimportmensual });
    }
    handleChangeFacturaValidador = (event) => {
        this.setState({ configvalidador: event.target.value })
        /*let paremt = (event.target.value).split('&');
        let _option = (this.state.listconfig).filter((j) => j.id === paremt[0]);
        let sumimport = 0, sumimportmensual = 0;
        for (let k = 0; k < this.array_config.length; k++) {
            if (this.array_config[k].id === paremt[1]) {
                this.array_config[k].importe = _option[0].importe;
                this.array_config[k].importemensual = (_option[0].importe) / 12;
                this.array_config[k].descripcion = _option[0].descripcion;
            }
            sumimport = sumimport + this.array_config[k].importe;//_option[0].importe
            sumimportmensual = sumimport + this.array_config[k].importemensual;
        }
        this.setState({ tableprecio: this.array_config, importetotal: sumimport, importetotalmensual: sumimportmensual });*/
    }
    handleSendSubmitSave = async (e) => {
        e.preventDefault();
        if (this.state.tableprecio.length > 0) {
            let parameters = {
                contacto: localStorage.getItem("_data_contact_"),
                data: JSON.stringify(this.state.tableprecio),
                descripcion: this.state.descripcion
            }
            let insertsendcotizacion = await api.post("saveandsendcotizacionprospect", parameters);
            this.props.close(insertsendcotizacion.data);
        }
        else {
            this.setState({ mensaje: 'Debe de seleccionar al menos un servicio!!', mensajeerror: true });
            setTimeout(() => {
                this.setState({ mensaje: '', mensajeerror: false });
            }, 4000);
        }
    }
    handlepreviewcotizacion = (e) => {
        e.preventDefault();
        if (this.state.tableprecio.length > 0) {
            if (this.state.iva === '')
                this.setState({ mensaje: 'Debe de seleccionar el tipo de iva Zona (Fronteriza o Centro) !!', mensajeerror: true });
            else {
                if (this.state.descriptionbank === '')
                    this.setState({ mensaje: 'Debe de seleccionar el banco!!', mensajeerror: true });
                else
                    this.props.view(this.state)
            }
        }
        else {
            this.setState({ mensaje: 'Debe de seleccionar al menos un servicio!!', mensajeerror: true });
            setTimeout(() => {
                this.setState({ mensaje: '', mensajeerror: false });
            }, 4000);
        }
    }
    handlechangezoneiva = (e) => {
        let array_list_modif_ = this.state.servicios;
        for (let l = 0; l < array_list_modif_.length; l++) {
            array_list_modif_[l].isChecked = false;
        }

        if (e.target.value === "FRONTERA")
            this.setState({
                iva: 'FRONTERA', zonacentro: '', zonafronte: 'checked', procentaje: 0.08, servicios: array_list_modif_,
                filterservicenomina: [], filterservice: [], filterserviceValidador: [], viewAuditoria: false,
                viewValidador: false,
                viewNomina: false, tableprecio: [], importetotal: 0, importetotalmensual: 0,
            });
        else if (e.target.value === "CENTRO")
            this.setState({
                iva: 'CENTRO', zonacentro: 'checked', zonafronte: '', procentaje: 0.16, servicios: array_list_modif_,
                filterservicenomina: [], filterservice: [], filterserviceValidador: [], viewAuditoria: false,
                viewValidador: false,
                viewNomina: false, tableprecio: [], importetotal: 0, importetotalmensual: 0,
            });
    }
    handlechangecotizaciontipo = (e) => {
        if (e.target.value === "MENSUAL")
            this.setState({ tipocotizacion: 'MENSUAL', cotizacionanual: '', cotizacionmensual: 'checked' });
        else if (e.target.value === "ANUAL")
            this.setState({ tipocotizacion: 'ANUAL', cotizacionanual: 'checked', cotizacionmensual: '' });
    }
    handlesetnumbertrabajadores = (e) => {
        this.setState({ [e.target.name]: e.target.value, });
    }
    handleGenerarreport = (e) => {
        this.setState({ descriptionbank: e.target.value });
    }
    render() {
        return (
            <>
                <div>
                    <br></br>
                    <div className="content pt-0">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={this.handleSendSubmitSave}>
                                    {
                                        this.state.mensajeerror ? (
                                            <div className="alert alert-warning alert-styled-right alert-dismissible">
                                                <span className="font-weight-semibold">Información!</span> {this.state.mensaje}
                                            </div>
                                        ) : null
                                    }
                                    <div className="row">
                                        <div className="col-md-6">
                                            <fieldset>
                                                <legend className="font-weight-semibold"><i className="icon-reading mr-2" /> Listado de servicios</legend>
                                                <div className="form-group">
                                                    <select className="form-control" name="tipopersona" onChange={this.handleChangeFilterPerson}>
                                                        <option value="">Seleccione el tipo de persona</option>
                                                        <option value="PM">Persona Moral</option>
                                                        <option value="PF">Persona Fisica</option>
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <div className="form-check form-check-inline">
                                                        <label className="form-check-label">
                                                            <div className="uniform-choice border-info text-info">
                                                                <span className={this.state.zonafronte}>
                                                                    <input type="radio" className="form-input-styled" name="gender" checked="" value="FRONTERA" onChange={this.handlechangezoneiva} data-fouc="" />
                                                                </span>
                                                            </div>Zona fronteriza
                                                        </label>
                                                    </div>

                                                    <div className="form-check form-check-inline">
                                                        <label className="form-check-label">
                                                            <div className="uniform-choice border-info text-info">
                                                                <span className={this.state.zonacentro}>
                                                                    <input type="radio" className="form-input-styled" name="gender" value="CENTRO" onChange={this.handlechangezoneiva} data-fouc="" />
                                                                </span>
                                                            </div>Zona  centro
                                                        </label>
                                                    </div>
                                                </div>
                                                {/*<div className="form-group">
                                                    <label className="d-block">Tipo de cotización</label>
                                                    <div className="form-check form-check-inline">
                                                        <label className="form-check-label">
                                                            <div className="uniform-choice border-info text-info">
                                                                <span className={this.state.cotizacionmensual}>
                                                                    <input type="radio" className="form-input-styled" name="gender" checked="" value="MENSUAL" onChange={this.handlechangecotizaciontipo} data-fouc="" />
                                                                </span>
                                                            </div>Mensual
											            </label>
                                                    </div>

                                                    <div className="form-check form-check-inline">
                                                        <label className="form-check-label">
                                                            <div className="uniform-choice border-info text-info">
                                                                <span className={this.state.cotizacionanual}>
                                                                    <input type="radio" className="form-input-styled" name="gender" value="ANUAL" onChange={this.handlechangecotizaciontipo} data-fouc="" />
                                                                </span>
                                                            </div>Anual
											            </label>
                                                    </div>
                                                </div>*/}
                                                <div className="form-group">
                                                    <FormControl component="fieldset">
                                                        <FormGroup>
                                                            {(this.state.servicios).map((i, o) =>
                                                            (
                                                                <FormControlLabel key={o} control={<Checkbox checked={i.isChecked} name={i.parent_id} onChange={this.handleChange} value={i.descripcion} />}
                                                                    label={<span style={{ color: 'white' }}>{i.descripcion}</span>}
                                                                />
                                                            ))}

                                                        </FormGroup>
                                                    </FormControl>
                                                </div>

                                            </fieldset>
                                        </div>
                                        <div className="col-md-6">
                                            <fieldset data-select2-id={46}>
                                                <legend className="font-weight-semibold"><i className="icon-truck mr-2" />Información complementaria</legend>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <select className="form-control" onChange={this.handleGenerarreport}>
                                                                <option value="">Seleccione el banco</option>
                                                                {
                                                                    (this.state.catbank || []).map((u, e) => (
                                                                        <option value={u.descripcion}>{u.banco}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.state.viewAuditoria ? (
                                                    <div className="row">
                                                        <div className="col-md-11">
                                                            <div className="form-group">
                                                                <label>Tipo de empresa:</label>
                                                                <select className="form-control" onChange={this.handleChangeFactura}>
                                                                    <option value="">Seleccione el tipo de empresa</option>
                                                                    {
                                                                        (this.state.filterservice || []).map((u, e) => (
                                                                            <option value={u.id}>{u.descripcion}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null}
                                                {this.state.viewValidador ? (
                                                    <div className="row">
                                                        <div className="col-md-11">
                                                            <div className="form-group">
                                                                <label>Tipo de empresa:</label>
                                                                <select className="form-control" onChange={this.handleChangeFacturaValidador}>
                                                                    <option value="">Seleccione el tipo de empresa</option>
                                                                    {
                                                                        (this.state.filterserviceValidador || []).map((u, e) => (

                                                                            <option value={u.id}>{u.descripcion}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null}
                                                {this.state.viewNomina ? (
                                                    <>
                                                        {/*<div className="row">
                                                            <div className="col-md-11">
                                                                <div className="form-group">
                                                                    <label>Número de trabajadores:</label>
                                                                    <select className="form-control" onChange={this.handleChangeFacturaNomina}>
                                                                        <option value="">Seleccione el número de trabajadores</option>
                                                                        {
                                                                            (this.state.filterservicenomina || []).map((u, e) => (
                                                                                <option value={u.id + '&' + u.servicio_id}>{u.descripcion}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>*/}
                                                        <div className="row">
                                                            <div className="col-md-11">
                                                                <div className="form-group">
                                                                    <label>Número de trabajadores:</label>
                                                                    <input className="form-control" name="numerotrabajadores" placeholder="Ingresa el número de trabajadores" value={this.state.numerotrabajadores} onChange={this.handlesetnumbertrabajadores} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : null}

                                                <div className="form-group">
                                                    <label>Nota (opcional):</label>
                                                    <textarea rows={4} cols={5} className="form-control" value={this.state.descripcion} name="descripcion" onChange={this.handlesetnumbertrabajadores} placeholder="Ingrese una nota en caso de ser requerida" />
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>
                                    {/*<div className="row">
                                        <div className="col-md-12">
                                            {
                                                this.state.cotizacionanual !== ''
                                                    ? (
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th>Servicio</th>
                                                                    <th>Importe</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    (this.state.tableprecio || []).map((d, f) => (
                                                                        <tr key={f}>
                                                                            <td>{d.text}</td>
                                                                            <td className="text-right">{d.importe.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                            <tfoot>
                                                                <tr>
                                                                    <td className="text-right">Subtotal:</td>
                                                                    <td className="text-right">{(this.state.importetotal).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-right">IVA:</td>
                                                                    <td className="text-right">{(this.state.importetotal * this.state.procentaje).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + '  (' + (this.state.procentaje).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + ' % )'}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-right">Total:</td>
                                                                    <td className="text-right" style={{ borderTop: 'double' }}>{((this.state.importetotal * this.state.procentaje) + this.state.importetotal).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    ) : null
                                            }
                                            {
                                                this.state.cotizacionmensual !== '' ?
                                                    (
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th>Servicio</th>
                                                                    <th>Importe</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    (this.state.tableprecio || []).map((d, f) => (
                                                                        <tr key={f}>
                                                                            <td>{d.text}</td>
                                                                            <td className="text-right">{d.importemensual.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                            <tfoot>
                                                                <tr>
                                                                    <td className="text-right">Subtotal:</td>
                                                                    <td className="text-right">{((this.state.importetotalmensual)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-right">IVA:</td>
                                                                    <td className="text-right">{((this.state.importetotalmensual) * this.state.procentaje).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + '  (' + (this.state.procentaje).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + ' % )'}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-right">Total:</td>
                                                                    <td className="text-right" style={{ borderTop: 'double' }}>{(((this.state.importetotalmensual / 12) * this.state.procentaje) + (this.state.importetotalmensual)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    ) : null
                                            }
                                        </div>
                                    </div>*/}
                                    <div className="text-right">
                                        <button type="submit" className="btn btn-primary" onClick={this.handlepreviewcotizacion}>Generar cotización <i className="icon-file-spreadsheet2 ml-2" /></button>&nbsp;&nbsp;&nbsp;&nbsp;
                                        {/*<button type="submit" className="btn btn-primary">Enviar cotización <i className="icon-paperplane ml-2" /></button>*/}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default CotizacionAdmin;
