import React, { Component, Fragment } from "react";
import ReactDatatable from '@ashvin27/react-datatable';
class list extends Component {
    constructor(props) {
        super(props)
        this.columns = [
            {
                key: "nombre",
                text: "Nombre, Denominación o razón social",
                className: "name",
                align: "left",
                sortable: true,
            },
            {
                key: "rfc",
                text: "RFC",
                className: "address",
                align: "left",
                sortable: true
            },
            {
                key: "total",
                text: "Total",
                className: "text-right",
                sortable: true,
                align: "center",
                cell: (record, index) => {
                    return (
                        <Fragment>
                            <label>{(record.total).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</label>
                        </Fragment>
                    );
                }
            }
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,
            button: {
                excel: true,
                print: true,
                //extra: true,
            },
            language: {
                length_menu: "Mostrar _MENU_ registros por página",
                filter: "Buscar en registros ...",
                info: "Mostrando _START_ a _END_ de _TOTAL_ entradas",
                pagination: {
                    first: "Primero",
                    previous: "Previa",
                    next: "próximo",
                    last: "Última"
                }
            },
            no_data_text: "No se encontraron registros"
        }

        this.state = {
            records: []
        }
        this.extraButtons = [
            {
                className: "btn btn-primary buttons-pdf",
                title: "Export TEst",
                children: [
                    <span>
                        <i className="icon-printer4" aria-hidden="true"></i>
                    </span>
                ],
                onClick: (event) => {
                    console.log(event);
                },
            },
            {
                className: "btn btn-primary buttons-pdf",
                title: "Export TEst",
                children: [
                    <span>
                        <i className="icon-printer4" aria-hidden="true"></i>
                    </span>
                ],
                onClick: (event) => {
                    console.log(event);
                },
                onDoubleClick: (event) => {
                    console.log("doubleClick")
                }
            },
        ]
    }
    componentDidMount() {
        var conteni_ = this.props.data;
        for (let index = 0; index < conteni_.length; index++) {
            conteni_[index].id = index;
        }
        this.setState({
            records: conteni_
        });
    }
    render() {
        return (
            <>
                <ReactDatatable
                    config={this.config}
                    records={this.state.records}
                    columns={this.columns}
                    extraButtons={this.extraButtons}
                />

            </>
        );
    }
}

export default list;