import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';

import api from '../../Config/api';
import Skeleton from './view/loading';

import { Tooltip, } from "@material-ui/core";
import './subdetails/style.css';
import Axiosapi from 'axios';
/***********
 * tareas
 */
import Notas from './subdetails/notas';
import Correo from './subdetails/correos';
import Llamadas from './subdetails/llamadas';
import Presentacionx from './subdetails/presentacion';
import Cotizacion from './subdetails/cotizacion'
import PreviewCotizacion from './subdetails/previewmail';

import ListNote from './view/notas';
import ListActivi from './view/activity';
import ListLlamada from './view/llamada';
import ListCorreo from './view/correo';
import ListPresentacion from './view/presentacion';
import ListCotizacion from './view/cotizacion';
import './style.css';
function GetSortOrder(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return 1;
        } else if (a[prop] > b[prop]) {
            return -1;
        }
        return 0;
    }
}

class MonitorActivities extends Component {
    constructor(props) {
        super(props);
        let objectinfo = JSON.parse(localStorage.getItem("_data_contact_"));
        let user = JSON.parse(localStorage.getItem("id_token"));
        this.state = {
            info: [],
            nombre_corto: user.nombre,
            nombre_: user.nombre + " " + user.apellido_paterno + " " + user.apellido_materno,
            email: user.email,
            contact: objectinfo,
            prospecto_id: objectinfo.pros_id,
            viewmensaje: false,
            mensaje: '',
            notas: '',
            llamadas: '',
            correos: '',
            presentacion: '',
            cotizacion: '',
            previewcotizacion: '',
            listregistro: [],
            viewNota: '',
            viewActividad: '',
            viewCorreo: '',
            viewLlamada: '',
            viewcotizacion: '',
            viewPresentacion: '', datanota: [],
            datacorreo: [],
            datallamada: [],
            datapresentacion: [],
            datacotizacion: [],
            id: 0,
            tipo: '',
            skeleton: true,
            templatecotizacion: ''
        }
    }
    componentDidMount() {
        this.getlist();
    }

    getlist = async () => {
        let array_universal = [];
        let _object_ = await api.post("listActivity", this.state);
        let { correos, llamadas, notas, presentacion, cotizacion } = await _object_.data;
        let notas_ = notas;
        let correos_ = correos;
        let llamadas_ = llamadas;
        let presentacion_ = presentacion;
        for (let k = 0; k < notas_.length; k++) {
            notas_[k].tipo = "nota";
            array_universal.push(notas_[k])
        }
        for (let k = 0; k < correos_.length; k++) {
            correos_[k].tipo = "correo";
            array_universal.push(correos_[k])
        }
        for (let k = 0; k < llamadas_.length; k++) {
            llamadas_[k].tipo = "llamada";
            array_universal.push(llamadas_[k])
        }
        for (let k = 0; k < presentacion_.length; k++) {
            presentacion_[k].tipo = "presentacion";
            array_universal.push(presentacion_[k])
        }
        ///console.log(array_universal)
        for (let k = 0; k < cotizacion.length; k++) {
            cotizacion[k].tipo = "cotizacion";
            //console.log(cotizacion[k])
            array_universal.push(cotizacion[k])
        }
        ///console.log(array_universal.sort(GetSortOrder("fecha")))
        setTimeout(() => {
            this.setState({
                listregistro: array_universal.sort(GetSortOrder("fecha")),
                datanota: notas, skeleton: false,
                datacorreo: correos,
                viewmensaje: false,
                datallamada: llamadas,
                datapresentacion: presentacion,
                datacotizacion: cotizacion,
                viewActividad: <ListActivi data={array_universal.sort(GetSortOrder("fecha"))} delete={this.handleDeleteActividad} />
            });
        }, 900);
    }
    handleCloseModal = (j) => {
        this.setState({
            skeleton: true,
            viewNota: '',
            viewActividad: '',
            viewCorreo: '',
            viewLlamada: '',
            viewPresentacion: '', cotizacion: '', previewcotizacion: ''
        })
        window.$("#_crea_correo_").modal("hide");
        window.$("#_crea_nota_").modal("hide");
        window.$("#_crea_llamada_").modal("hide");
        window.$("#_crea_presentacion_").modal("hide");
        window.$("#_crea_cotizacion_").modal("hide");
        if (j.estatus === 200) {
            this.getlist();
            this.setState({
                mensaje: j.mensaje, viewmensaje: true, notas: '', llamadas: '',
                correos: '', presentacion: '', cotizacion: '', previewcotizacion: ''
            });
            setTimeout(() => {
                this.setState({ viewmensaje: false });
            }, 4000);
        }
    }
    handleViewPage = (t) => {

        if (t === "nota") {
            this.setState({ notas: <Notas close={this.handleCloseModal} />, llamadas: '', correos: '', presentacion: '', cotizacion: '' });
            window.$("#_crea_correo_").modal("hide");
            window.$("#_crea_nota_").modal("show");
            window.$("#_crea_llamada_").modal("hide");
            window.$("#_crea_cotizacion_").modal("hide");
        }
        else if (t === "correo") {
            this.setState({ notas: '', llamadas: '', correos: <Correo close={this.handleCloseModal} />, presentacion: '', cotizacion: '' });
            window.$("#_crea_correo_").modal("show");
            window.$("#_crea_nota_").modal("hide");
            window.$("#_crea_llamada_").modal("hide");
            window.$("#_crea_cotizacion_").modal("hide");
        }
        else if (t === "llamada") {
            this.setState({ notas: '', llamadas: <Llamadas close={this.handleCloseModal} />, correos: '', presentacion: '', cotizacion: '' })
            window.$("#_crea_correo_").modal("hide");
            window.$("#_crea_nota_").modal("hide");
            window.$("#_crea_llamada_").modal("show");
            window.$("#_crea_cotizacion_").modal("hide");
        }
        else if (t === "presentacion") {
            this.setState({ notas: '', llamadas: '', correos: '', presentacion: <Presentacionx close={this.handleCloseModal} />, cotizacion: '' })
            window.$("#_crea_correo_").modal("hide");
            window.$("#_crea_nota_").modal("hide");
            window.$("#_crea_llamada_").modal("hide");
            window.$("#_crea_presentacion_").modal("show");
            window.$("#_crea_cotizacion_").modal("hide");
        } else if (t === "cotizacion") {
            this.setState({ notas: '', llamadas: '', correos: '', presentacion: '', cotizacion: <Cotizacion view={this.handleViewPreviewCotizacion} close={this.handleCloseModal} /> })
            window.$("#_crea_correo_").modal("hide");
            window.$("#_crea_nota_").modal("hide");
            window.$("#_crea_llamada_").modal("hide");
            window.$("#_crea_presentacion_").modal("hide");
            window.$("#_crea_cotizacion_").modal("show");
        }

    }
    handleViewPagesList = (n) => {
        this.setState({ skeleton: true });
        if (n === "actividad") {
            this.setState({
                viewActividad: <ListActivi data={this.state.listregistro} delete={this.handleDeleteActividad} />,
                viewNota: '',
                viewCorreo: '',
                viewLlamada: '',
                viewPresentacion: '',
            });
            setTimeout(() => {
                this.setState({ skeleton: false });
            }, 500);
        }
        else if (n === "nota") {
            this.setState({
                viewNota: <ListNote data={this.state.datanota} delete={this.handleDeleteActividad} />,
                viewActividad: '',
                viewCorreo: '',
                viewLlamada: '',
                viewPresentacion: '',
            });
            setTimeout(() => {
                this.setState({ skeleton: false });
            }, 500);
        }
        else if (n === "correo") {
            this.setState({
                viewCorreo: <ListCorreo data={this.state.datacorreo} delete={this.handleDeleteActividad} />,
                viewNota: '',
                viewActividad: '',
                viewLlamada: '',
                viewPresentacion: '',
            }); setTimeout(() => {
                this.setState({ skeleton: false });
            }, 500);
        }
        else if (n === "llamada") {
            this.setState({
                viewLlamada: <ListLlamada data={this.state.datallamada} delete={this.handleDeleteActividad} />,
                viewNota: '',
                viewActividad: '',
                viewCorreo: '',
                viewPresentacion: '',
            }); setTimeout(() => {
                this.setState({ skeleton: false });
            }, 500);
        }
        else if (n === "presentacion") {
            this.setState({
                viewPresentacion: <ListPresentacion data={this.state.datapresentacion} delete={this.handleDeleteActividad} />,
                viewNota: '',
                viewActividad: '',
                viewCorreo: '',
                viewLlamada: '',
            }); setTimeout(() => {
                this.setState({ skeleton: false });
            }, 500);
        }
        else if (n === "cotizacion") {
            this.setState({
                viewcotizacion: <ListCotizacion data={this.state.datacotizacion} delete={this.handleDeleteActividad} />,
                viewNota: '',
                viewActividad: '',
                viewCorreo: '',
                viewLlamada: '',
                viewPresentacion: ''
            }); setTimeout(() => {
                this.setState({ skeleton: false });
            }, 500);
        }
    }
    handleDeleteActividad = (i) => {
        window.$("#modaleliminar").modal("show");
        this.setState({ id: i.id, tipo: i.tipo });
    }
    handleAceptarDeleteActividad = async () => {

        let _object_ = await api.post("deletedataprospect", this.state);

        this.setState({
            skeleton: true,
            viewNota: '',
            viewActividad: '',
            viewCorreo: '',
            viewLlamada: '',
            viewPresentacion: '', mensaje: 'Se eliminó correctamente el registro!.', viewmensaje: true
        })
        window.$("#modaleliminar").modal("hide");

        this.getlist();
        setTimeout(() => {
            if (this.state.tipo === "nota")
                this.setState({ viewmensaje: false, viewNota: <ListNote data={this.state.datanota} delete={this.handleDeleteActividad} />, });
            else if (this.state.tipo === "correo")
                this.setState({ viewmensaje: false, viewCorreo: <ListCorreo data={this.state.datacorreo} delete={this.handleDeleteActividad} />, });
            else if (this.state.tipo === "llamada")
                this.setState({ viewmensaje: false, viewLlamada: <ListLlamada data={this.state.datallamada} delete={this.handleDeleteActividad} />, });
            else if (this.state.tipo === "presentacion")
                this.setState({ viewmensaje: false, viewPresentacion: <ListPresentacion data={this.state.datapresentacion} delete={this.handleDeleteActividad} />, });
        }, 4000);
    }
    handleCloseModalM = () => {
        this.getlist();
        this.setState({
            cotizacion: '', previewcotizacion: '', notas: '',
            llamadas: '',
            correos: '',
            presentacion: '',
        });
        window.$("#_crea_correo_").modal("hide");
        window.$("#_crea_nota_").modal("hide");
        window.$("#_crea_llamada_").modal("hide");
        window.$("#_crea_presentacion_").modal("hide");
        window.$("#_crea_cotizacion_").modal("hide");

    }
    handleViewPreviewCotizacion = (ob) => {
        window.$("#_crea_cotizacion_").modal("hide");
        window.$("#modalpreviewsendcotizacion").modal("show");
        this.setState({ previewcotizacion: <PreviewCotizacion data={ob} _dataget={this.getdatacontizacion} /> })
    }
    handleViewPreviewCotizacionCancel = () => {
        window.$("#_crea_cotizacion_").modal("show");
        window.$("#modalpreviewsendcotizacion").modal("hide");
        this.setState({ previewcotizacion: '' })
    }
    getdatacontizacion = (obj) => {
        this.setState({ templatecotizacion: JSON.stringify(obj) });
    }
    sendcotizacioncostumer = async () => {
        let conte_ = await api.post("saveandsendcotizacionprospect", { data: this.state.templatecotizacion });
        window.$("#modalpreviewsendcotizacion").modal("hide");
        this.setState({ previewcotizacion: '', skeleton: true, cotizacion: '' });
        this.getlist();

    }
    render() {
        return (
            <>
                <div className="page-content">
                    <div className="content-wrapper">
                        <div className="page-header border-bottom-0">
                            <div className="page-header-content header-elements-md-inline">
                                <div className="page-title d-flex">
                                    <a href="#/app/filter/vendors" style={{ color: 'white' }}> <h4><i className="icon-arrow-left52 mr-2"></i> <span className="font-weight-semibold">Listado de contactos</span></h4></a>
                                    <a href="#" className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></a>
                                </div>
                                {
                                    this.state.viewmensaje ? (
                                        <div className="alert alert-success alert-styled-left alert-arrow-left alert-dismissible">
                                            <span className="font-weight-semibold">Usuario!</span> {this.state.mensaje}
                                        </div>) : null}
                                <div className="header-elements d-none mb-3 mb-md-0">
                                    <div className="d-flex justify-content-center">

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content pt-0">
                            <div className="d-md-flex align-items-md-start">
                                <div className="sidebar sidebar-light bg-transparent sidebar-component sidebar-component-left border-0 shadow-0 sidebar-expand-md">
                                    <div className="sidebar-content">
                                        <div id="accordion-group">
                                            <div className="card mb-0 rounded-bottom-0">
                                                <div className="card-header">
                                                    <h6 className="card-title">
                                                        <a data-toggle="collapse" className="text-default" href="#accordion-item-group1">Contacto</a>
                                                    </h6>
                                                </div>

                                                <div id="accordion-item-group1" className="collapse show" data-parent="#accordion-group">
                                                    <div className="card-body">
                                                        <div className="card">
                                                            <div className="card-body text-center">
                                                                <div className="card-img-actions d-inline-block mb-3">
                                                                    <Avatar className="img-fluid rounded-circle" style={{ width: 100, height: 100 }}>{this.state.contact.nombre.substr(0, 1) + '' + this.state.contact.apellido_paterno.substr(0, 1)}</Avatar>

                                                                    <div className="card-img-actions-overlay card-img rounded-circle">
                                                                        <a style={{ cursor: 'pointer' }} className="btn btn-outline bg-white text-white border-white border-2 btn-icon rounded-round">
                                                                            <i className="icon-camera"></i>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <h6 className="font-weight-semibold mb-0">{this.state.contact.nombre_completo}</h6>
                                                                <span className="d-block text-muted">{this.state.contact.cargo}</span>
                                                            </div>
                                                            <div className="card-footer border-light d-flex justify-content-around text-center p-0">
                                                                <Tooltip title="Crear nota" placement="top" arrow><a className="list-icons-item flex-fill p-3" style={{ cursor: 'pointer' }} onClick={() => this.handleViewPage('nota')}><i className="icon-pencil7"></i></a></Tooltip>
                                                                <Tooltip title="Crear correo" placement="top" arrow><a className="list-icons-item flex-fill p-3" style={{ cursor: 'pointer' }} onClick={() => this.handleViewPage('correo')}><i className="icon-envelop4"></i></a></Tooltip>
                                                                <Tooltip title="Registrar llamada" placement="top" arrow><a className="list-icons-item flex-fill p-3" style={{ cursor: 'pointer' }} onClick={() => this.handleViewPage('llamada')}><i className="icon-phone2"></i></a></Tooltip>
                                                                <Tooltip title="Agendar presentación" placement="top" arrow><a className="list-icons-item flex-fill p-3" style={{ cursor: 'pointer' }} onClick={() => this.handleViewPage('presentacion')}><i className="icon-calendar"></i></a></Tooltip>
                                                                <Tooltip title="Generar cotización" placement="top" arrow><a className="list-icons-item flex-fill p-3" style={{ cursor: 'pointer' }} onClick={() => this.handleViewPage('cotizacion')}><i className="icon-price-tag"></i></a></Tooltip>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card mb-0 rounded-0 border-y-0">
                                                <div className="card-header">
                                                    <h6 className="card-title">
                                                        <a className="collapsed text-default" data-toggle="collapse" href="#accordion-item-group2">Información del contacto</a>
                                                    </h6>
                                                </div>

                                                <div id="accordion-item-group2" className="collapse" data-parent="#accordion-group">
                                                    <div className="card-body">
                                                        <ul className="list list-unstyled mb-0">
                                                            <li>Nombre</li>
                                                            <li><span className="text-muted font-size-sm">{this.state.contact.nombre}</span></li>
                                                            <li>Apellido</li>
                                                            <li><span className="text-muted font-size-sm">{this.state.contact.apellido_paterno + ' ' + this.state.contact.apellido_materno}</span></li>
                                                            <li>Empresa</li>
                                                            <li><span className="text-muted font-size-sm">{this.state.contact.rfc}</span></li>
                                                            <li>Correo</li>
                                                            <li><span className="text-muted font-size-sm">{this.state.contact.correo}</span></li>
                                                            <li>Número de teléfono</li>
                                                            <li><span className="text-muted font-size-sm">{this.state.contact.telefono}</span></li>
                                                            <li>Propietario del contacto</li>
                                                            <li><span className="text-muted font-size-sm">{this.state.nombre_}</span></li>
                                                            <li>Estatus del contacto</li>
                                                            <li><span className="text-muted font-size-sm">{this.state.contact.descripcion}</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-fill">
                                    <div className="card">
                                        <div className="card-body">
                                            <ul className="nav nav-tabs nav-tabs-bottom border-bottom-0 nav-justified">
                                                <li className="nav-item" onClick={() => this.handleViewPagesList("actividad")}><a href="#bottom-justified-divided-tab1" className="nav-link active" data-toggle="tab">Actividad</a></li>
                                                <li className="nav-item" onClick={() => this.handleViewPagesList("nota")}><a href="#bottom-justified-divided-tab2" className="nav-link" data-toggle="tab">Notas</a></li>
                                                <li className="nav-item" onClick={() => this.handleViewPagesList("correo")}><a href="#bottom-justified-divided-tab3" className="nav-link" data-toggle="tab">Correos</a></li>
                                                <li className="nav-item" onClick={() => this.handleViewPagesList("llamada")}><a href="#bottom-justified-divided-tab4" className="nav-link" data-toggle="tab">Llamadas</a></li>
                                                <li className="nav-item" onClick={() => this.handleViewPagesList("presentacion")}><a href="#bottom-justified-divided-tab5" className="nav-link" data-toggle="tab">Presentación</a></li>
                                                <li className="nav-item" onClick={() => this.handleViewPagesList("cotizacion")}><a href="#bottom-justified-divided-tab6" className="nav-link" data-toggle="tab">Cotización</a></li>
                                            </ul>
                                            <div className="tab-content">
                                                <div className="tab-pane fade active show" id="bottom-justified-divided-tab1">
                                                    {
                                                        this.state.skeleton ? (
                                                            <Skeleton></Skeleton>
                                                        ) :
                                                            (
                                                                <div>
                                                                    {this.state.viewActividad}
                                                                </div>
                                                            )
                                                    }

                                                </div>
                                                <div className="tab-pane fade" id="bottom-justified-divided-tab2">
                                                    {
                                                        this.state.skeleton ? (
                                                            <Skeleton></Skeleton>
                                                        ) :
                                                            (
                                                                <div>
                                                                    {this.state.viewNota}
                                                                </div>
                                                            )
                                                    }
                                                </div>
                                                <div className="tab-pane fade" id="bottom-justified-divided-tab3">
                                                    {
                                                        this.state.skeleton ? (
                                                            <Skeleton></Skeleton>
                                                        ) :
                                                            (
                                                                <div>
                                                                    {this.state.viewCorreo}
                                                                </div>
                                                            )
                                                    }
                                                </div>
                                                <div className="tab-pane fade" id="bottom-justified-divided-tab4">
                                                    {
                                                        this.state.skeleton ? (
                                                            <Skeleton></Skeleton>
                                                        ) :
                                                            (
                                                                <div>
                                                                    {this.state.viewLlamada}
                                                                </div>
                                                            )
                                                    }
                                                </div>
                                                <div className="tab-pane fade" id="bottom-justified-divided-tab5">
                                                    {
                                                        this.state.skeleton ? (
                                                            <Skeleton></Skeleton>
                                                        ) :
                                                            (
                                                                <div>
                                                                    {this.state.viewPresentacion}
                                                                </div>
                                                            )
                                                    }
                                                </div>
                                                <div className="tab-pane fade" id="bottom-justified-divided-tab6">
                                                    {
                                                        this.state.skeleton ? (
                                                            <Skeleton></Skeleton>
                                                        ) :
                                                            (
                                                                <div>
                                                                    {this.state.viewcotizacion}
                                                                </div>
                                                            )
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="_crea_nota_" className="modal fade" tabIndex="-1" aria-hidden="true" style={{ "display": "none" }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Crear Nota</h5>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>

                            {this.state.notas}
                        </div>
                    </div>
                </div>
                <div id="_crea_correo_" className="modal fade" tabIndex="-1" aria-hidden="true" style={{ "display": "none" }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Correo</h5>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>

                            {this.state.correos}
                        </div>
                    </div>
                </div>
                <div id="_crea_llamada_" className="modal fade" tabIndex="-1" aria-hidden="true" style={{ "display": "none" }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Registrar llamada</h5>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>

                            {this.state.llamadas}
                        </div>
                    </div>
                </div>
                <div id="_crea_presentacion_" className="modal fade" tabIndex="-1" aria-hidden="true" style={{ "display": "none" }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Registrar presentación</h5>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>

                            {this.state.presentacion}
                        </div>
                    </div>
                </div>
                <div id="_crea_cotizacion_" className="modal fade" tabIndex="-1" aria-hidden="true" style={{ "display": "none" }}>
                    <div className="modal-dialog modal-full">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Cotización</h5>
                                <button type="button" className="close" data-dismiss="modal" onClick={this.handleCloseModal}>×</button>
                            </div>

                            {this.state.cotizacion}
                        </div>
                    </div>
                </div>
                <div id="modaleliminar" className="modal fade" tabIndex="-1" aria-hidden="true" style={{ "display": "none" }}>
                    <div className="modal-dialog modal-sm">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Solo verificaba</h5>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>

                            <div className="modal-body">
                                ¿Está seguro de que desea eliminar esta actividad?
                                </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-link" data-dismiss="modal">Cancelar</button>
                                <button type="button" className="btn bg-primary" onClick={this.handleAceptarDeleteActividad}>Eliminar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modalpreviewsendcotizacion" className="modal fade" tabIndex="-1" aria-hidden="true" style={{ "display": "none" }}>
                    <div className="modal-dialog modal-full">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Previsualizar la cotización</h5>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>

                            <div className="modal-body">
                                {this.state.previewcotizacion}
                            </div>

                            <div className="modal-footer">
                                <a className="btn btn-default" data-dismiss="modal" onClick={this.handleViewPreviewCotizacionCancel}>Cancelar</a>
                                <a className="btn bg-primary" onClick={this.sendcotizacioncostumer}>Enviar cotización</a>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default MonitorActivities;