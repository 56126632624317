/*import React, { Component } from 'react';
import { Grid, Card, Badge, IconButton, CardHeader, CardActionArea, Avatar, Divider, Typography, Button, CardContent, CardActions, Backdrop, CircularProgress } from "@material-ui/core";
import api from '../../../app/Config/api';
import { red } from '@material-ui/core/colors';
class ViewPanelAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numeronolocalizados: 0,
            numeropresuntos: 0,
            numerodefinitivos: 0,
            numerodesvirtuados: 0,
            ejercicio: 0,
            fecha: '',
        }
    }
    componentDidMount() {
        const data = JSON.parse(localStorage.getItem("datavalidate"));
        if (data.tipo_validacion === 'EFIRMA') {
            var objects = {
                ejercicio: this.props.informacion.ejercicio,
                empresa_id: data.id
            };
            api.post('listadetailsmetadata', objects).then((response) => {
                var datoInformacion_ = response.data;
                var nolocalizadocont = (datoInformacion_.filter((k) => k.tipo === '001')).length;
                var presuntoscont = (datoInformacion_.filter((k) => k.tipo === '002')).length;
                var definitivoscont = (datoInformacion_.filter((k) => k.tipo === '003')).length;
                var desvirtuadoscont = (datoInformacion_.filter((k) => k.tipo === '004')).length;
                this.setState({
                    numeronolocalizados: nolocalizadocont,
                    numeropresuntos: presuntoscont,
                    numerodefinitivos: definitivoscont,
                    numerodesvirtuados: desvirtuadoscont,
                    ejercicio: this.props.informacion.ejercicio,
                    fecha: this.props.informacion.fecha,
                });
            });
        }
        else if (data.tipo_validacion === 'IMPORTACION') {
            var objects = {
                ejercicio: this.props.informacion.ejercicio,
                empresa_id: data.id
            };
            api.post('listadetailscfdisend', objects).then((response) => {
                var datoInformacion_ = response.data;
                var nolocalizadocont = (datoInformacion_.filter((k) => k.tipo === '001')).length;
                var presuntoscont = (datoInformacion_.filter((k) => k.tipo === '002')).length;
                var definitivoscont = (datoInformacion_.filter((k) => k.tipo === '003')).length;
                var desvirtuadoscont = (datoInformacion_.filter((k) => k.tipo === '004')).length;
                this.setState({
                    numeronolocalizados: nolocalizadocont,
                    numeropresuntos: presuntoscont,
                    numerodefinitivos: definitivoscont,
                    numerodesvirtuados: desvirtuadoscont,
                    ejercicio: this.props.informacion.ejercicio,
                    fecha: this.props.informacion.fecha,
                });
            });
        }
    }
    handleClickNolocalizado = () => {
        if (this.state.numeronolocalizados > 0)
            this.props.informacion.click({ ejercicio: this.state.ejercicio, tipo: '001', descripcion: 'No localizados' });
    }
    handleClickPresuntos = () => {
        if (this.state.numeropresuntos > 0)
            this.props.informacion.click({ ejercicio: this.state.ejercicio, tipo: '002', descripcion: 'Presuntos' });
    }
    handleClickDefinitivos = () => {
        if (this.state.numerodefinitivos > 0)
            this.props.informacion.click({ ejercicio: this.state.ejercicio, tipo: '003', descripcion: 'Definitivos' });
    }
    handleClickDesvirtuados = () => {
        if (this.state.numerodesvirtuados > 0)
            this.props.informacion.click({ ejercicio: this.state.ejercicio, tipo: '004', descripcion: 'Desvirtuado' });
    }
    handleViewCfdisModal = () => {
        console.log(this.state.ejercicio)
        this.props.informacion.preview({ ejercicio: this.state.ejercicio })
    }
    render() {
        return (<>
            <Grid item xs={6} sm={4}>
                <div className="card" style={{ color: 'white' }}>
                    <div className="card-header">
                        <div className="media">
                            <div className="mr-3">
                                <Avatar aria-label="recipe" style={{ backgroundColor: red[500] }}>69B</Avatar>
                            </div>

                            <div className="media-body">
                                <div className="font-weight-semibold">{"Ejercicio - " + this.state.ejercicio}</div>
                                <span className="text-muted">{this.state.fecha}</span>
                            </div>

                            <div className="ml-3 align-self-center">
                                <span className="badge badge-mark bg-success border-success"></span>
                            </div>
                        </div>
                    </div>
                    <div className="card card-body">
                        <div className="row text-center">

                            {this.state.numeronolocalizados > 0 ? (
                                <div className="col-4" onClick={this.handleClickNolocalizado} style={{ cursor: 'pointer' }}>
                                    <p><i className="icon-users2 icon-2x d-inline-block text-danger"></i></p>
                                    <h5 className="font-weight-semibold mb-0">{this.state.numeronolocalizados}</h5>
                                    <span className="text-muted font-size-sm">No localizados</span>
                                </div>
                            ) : (<div className="col-4" style={{ cursor: 'pointer' }}>
                                <p><i className="icon-users2 icon-2x d-inline-block text-success"></i></p>
                                <h5 className="font-weight-semibold mb-0">{this.state.numeronolocalizados}</h5>
                                <span className="text-muted font-size-sm">No localizados</span>
                            </div>)}

                            {this.state.numeropresuntos > 0 ? (
                                <div className="col-4" onClick={this.handleClickPresuntos} style={{ cursor: 'pointer' }}>
                                    <p><i className="icon-point-up icon-2x d-inline-block text-danger"></i></p>
                                    <h5 className="font-weight-semibold mb-0">{this.state.numeropresuntos}</h5>
                                    <span className="text-muted font-size-sm">Presuntos</span>
                                </div>
                            ) : (<div className="col-4">
                                <p><i className="icon-point-up icon-2x d-inline-block text-success"></i></p>
                                <h5 className="font-weight-semibold mb-0">{this.state.numeropresuntos}</h5>
                                <span className="text-muted font-size-sm">Presuntos</span>
                            </div>)}

                            {this.state.numerodefinitivos > 0 ? (<div className="col-4" onClick={this.handleClickDefinitivos} style={{ cursor: 'pointer' }}>
                                <p><i className="icon-user-cancel icon-2x d-inline-block text-danger"></i></p>
                                <h5 className="font-weight-semibold mb-0">{this.state.numerodefinitivos}</h5>
                                <span className="text-muted font-size-sm">Definitivos</span>
                            </div>) : (<div className="col-4">
                                <p><i className="icon-user-cancel icon-2x d-inline-block text-success"></i></p>
                                <h5 className="font-weight-semibold mb-0">{this.state.numerodefinitivos}</h5>
                                <span className="text-muted font-size-sm">Definitivos</span>
                            </div>)}

                        </div>
                        <div className="row text-center">
                            {this.state.numerodesvirtuados > 0 ? (<div className="col-4" onClick={this.handleClickDesvirtuados} style={{ cursor: 'pointer' }}>
                                <p><i className="icon-user-minus icon-2x d-inline-block text-danger"></i></p>
                                <h5 className="font-weight-semibold mb-0">{this.state.numerodesvirtuados}</h5>
                                <span className="text-muted font-size-sm">Desvirtuados</span>
                            </div>) : (<div className="col-4">
                                <p><i className="icon-user-minus icon-2x d-inline-block text-success"></i></p>
                                <h5 className="font-weight-semibold mb-0">{this.state.numerodesvirtuados}</h5>
                                <span className="text-muted font-size-sm">Desvirtuados</span>
                            </div>)}
                            <div className="col-3"></div>
                            <div className="col-5" onClick={this.handleViewCfdisModal} style={{ cursor: 'pointer', top: '34px' }}>
                                <div className="pt-0"></div>
                                <p><i className="icon-list2 icon-2x d-inline-block text-success"></i></p>
                                <span className="text-muted font-size-sm">Ver cfdis validados</span>
                            </div>
                        </div>
                    </div>
                </div>

            </Grid>
        </>);
    }
}

export default ViewPanelAdmin;
*/
import React, { Component } from 'react';
import { Grid, Avatar, } from "@material-ui/core";
import api from '../../Config/api';
import { red } from '@material-ui/core/colors';
function _filtro_sum_group_(k) {
    var result = [];
    k.reduce(function (res, value) {
        if (!res[value.rfc]) {
            res[value.rfc] = { rfc: value.rfc, total: 0, nombre: value.nombre, tipo: value.tipo };
            result.push(res[value.rfc])
        }
        res[value.rfc].total += parseFloat(value.total)//parseFloat(value.total.replace(/,/g, '').replace(/$/g, ''));
        return res;
    }, {});
    return result;
}
class ViewPanelAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numeronolocalizados: 0,
            numeropresuntos: 0,
            numerodefinitivos: 0,
            numerodesvirtuados: 0,
            ejercicio: 0,
            fecha: '',
        }
    }
    componentDidMount() {
        const data = JSON.parse(localStorage.getItem("_data_info_empresa_"));
        /* console.log(data)
         if (data.tipo_validacion === 'EFIRMA') {
             var objects = {
                 ejercicio: this.props.informacion.ejercicio,
                 empresa_id: data.id
             };
             api.post('listadetailsmetadata', objects).then((response) => {
                 var datoInformacion_ = response.data;
                 var nolocalizadocont = (datoInformacion_.filter((k) => k.tipo === '001')).length;
                 var presuntoscont = (datoInformacion_.filter((k) => k.tipo === '002')).length;
                 var definitivoscont = (datoInformacion_.filter((k) => k.tipo === '003')).length;
                 var desvirtuadoscont = (datoInformacion_.filter((k) => k.tipo === '004')).length;
 
                 this.setState({
                     numeronolocalizados: nolocalizadocont,
                     numeropresuntos: presuntoscont,
                     numerodefinitivos: definitivoscont,
                     numerodesvirtuados: desvirtuadoscont,
                     ejercicio: this.props.informacion.ejercicio,
                     fecha: this.props.informacion.fecha,
                 });
             });
         }
         else if (data.tipo_validacion === 'IMPORTACION') {*/
        var objects = {
            ejercicio: this.props.informacion.ejercicio,
            empresa_id: data.id,
            id: data.empresa_id
        };
        api.post('listadetailscfdisend', objects).then((response) => {
            console.log(response.data)
            var datoInformacion_ = response.data;
            datoInformacion_ = _filtro_sum_group_(datoInformacion_)
            console.log(datoInformacion_)
            var nolocalizadocont = (datoInformacion_.filter((k) => k.tipo === '001')).length;
            var presuntoscont = (datoInformacion_.filter((k) => k.tipo === '002')).length;
            var definitivoscont = (datoInformacion_.filter((k) => k.tipo === '003')).length;
            var desvirtuadoscont = (datoInformacion_.filter((k) => k.tipo === '004')).length;

            this.setState({
                numeronolocalizados: nolocalizadocont,
                numeropresuntos: presuntoscont,
                numerodefinitivos: definitivoscont,
                numerodesvirtuados: desvirtuadoscont,
                ejercicio: this.props.informacion.ejercicio,
                fecha: this.props.informacion.fecha,
            });
        });
        //}
    }
    handleClickNolocalizado = () => {
        if (this.state.numeronolocalizados > 0)
            this.props.informacion.click({ ejercicio: this.state.ejercicio, tipo: '001', descripcion: 'No localizados' });
    }
    handleClickPresuntos = () => {
        if (this.state.numeropresuntos > 0)
            this.props.informacion.click({ ejercicio: this.state.ejercicio, tipo: '002', descripcion: 'Presuntos' });
    }
    handleClickDefinitivos = () => {
        if (this.state.numerodefinitivos > 0)
            this.props.informacion.click({ ejercicio: this.state.ejercicio, tipo: '003', descripcion: 'Definitivos' });
    }
    handleClickDesvirtuados = () => {
        if (this.state.numerodesvirtuados > 0)
            this.props.informacion.click({ ejercicio: this.state.ejercicio, tipo: '004', descripcion: 'Desvirtuado' });
    }
    handleViewCfdisModal = () => {
        console.log(this.state.ejercicio)
        this.props.informacion.preview({ ejercicio: this.state.ejercicio })
    }
    render() {
        return (<>
            <Grid item xs={6} sm={4}>
                <div className="card" style={{ color: 'white' }}>
                    <div className="card-header">
                        <div className="media">
                            <div className="mr-3">
                                <Avatar aria-label="recipe" style={{ backgroundColor: red[500] }}>69B</Avatar>
                            </div>

                            <div className="media-body">
                                <div className="font-weight-semibold">{"Ejercicio - " + this.state.ejercicio}</div>
                                <span className="text-muted">{this.state.fecha}</span>
                            </div>

                            <div className="ml-3 align-self-center">
                                <span className="badge badge-mark bg-success border-success"></span>
                            </div>
                        </div>
                    </div>
                    <div className="card card-body">
                        <div className="row text-center">

                            {this.state.numeronolocalizados > 0 ? (
                                <div className="col-4" onClick={this.handleClickNolocalizado} style={{ cursor: 'pointer' }}>
                                    <p><i className="fas fa-tired fa-3x d-inline-block text-orange-300"></i></p>
                                    <h5 className="font-weight-semibold mb-0">{this.state.numeronolocalizados}</h5>
                                    <span className="text-muted font-size-sm">No localizados</span>
                                </div>
                            ) : (<div className="col-4" style={{ cursor: 'pointer' }}>
                                <p><i className="fas fa-grin-alt  fa-3x d-inline-block text-success"></i></p>
                                <h5 className="font-weight-semibold mb-0"><i className="icon-checkmark4 text-warning"></i></h5>
                                <span className="text-muted font-size-sm">No localizados</span>
                            </div>)}

                            {this.state.numeropresuntos > 0 ? (
                                <div className="col-4" onClick={this.handleClickPresuntos} style={{ cursor: 'pointer' }}>
                                    <p><i className="fas fa-grimace  fa-3x d-inline-block text-orange-300"></i></p>
                                    <h5 className="font-weight-semibold mb-0">{this.state.numeropresuntos}</h5>
                                    <span className="text-muted font-size-sm">Presuntos</span>
                                </div>
                            ) : (<div className="col-4">
                                <p><i className="fas fa-grin-alt  fa-3x d-inline-block text-success"></i></p>
                                <h5 className="font-weight-semibold mb-0"><i className="icon-checkmark4 text-warning"></i></h5>
                                <span className="text-muted font-size-sm">Presuntos</span>
                            </div>)}

                            {this.state.numerodefinitivos > 0 ? (<div className="col-4" onClick={this.handleClickDefinitivos} style={{ cursor: 'pointer' }}>
                                <p><i className="fas fa-angry  fa-3x d-inline-block text-danger"></i></p>
                                <h5 className="font-weight-semibold mb-0">{this.state.numerodefinitivos}</h5>
                                <span className="text-muted font-size-sm">Definitivos</span>
                            </div>) : (<div className="col-4">
                                <p><i className="fas fa-grin-alt  fa-3x d-inline-block text-success"></i></p>
                                <h5 className="font-weight-semibold mb-0"><i className="icon-checkmark4 text-warning"></i></h5>
                                <span className="text-muted font-size-sm">Definitivos</span>
                            </div>)}

                        </div>
                        <div className="row text-center">
                            {this.state.numerodesvirtuados > 0 ? (<div className="col-4" onClick={this.handleClickDesvirtuados} style={{ cursor: 'pointer' }}>
                                <p><i className="fas fa-frown-open  fa-3x d-inline-block text-warning-300"></i></p>
                                <h5 className="font-weight-semibold mb-0">{this.state.numerodesvirtuados}</h5>
                                <span className="text-muted font-size-sm">Desvirtuados</span>
                            </div>) : (<div className="col-4">
                                <p><i className="fas fa-grin-alt  fa-3x d-inline-block text-success"></i></p>
                                <h5 className="font-weight-semibold mb-0"><i className="icon-checkmark4 text-warning"></i></h5>
                                <span className="text-muted font-size-sm">Desvirtuados</span>
                            </div>)}
                            <div className="col-3"></div>
                            <div className="col-5" onClick={this.handleViewCfdisModal} style={{ cursor: 'pointer', top: '34px' }}>
                                <div className="pt-0"></div>
                                <p><i className="icon-list2 icon-2x d-inline-block text-success"></i></p>
                                <span className="text-muted font-size-sm">Ver cfdis validados</span>
                            </div>
                        </div>
                    </div>
                </div>

            </Grid>
        </>);
        /* return (<>
             <Grid item xs={6} sm={4} >
                 <Card>
                     <CardHeader avatar={<Avatar aria-label="recipe" style={{ backgroundColor: red[500] }}>69B</Avatar>}
                         title={"Ejercicio - " + this.state.ejercicio}
                         subheader={<>
                             {this.state.fecha}
                             <Divider></Divider>
                         </>} />
                     <CardContent>
                         <table style={{ width: "100%" }}>
                             <tbody>
                                 <tr>
                                     <td>
                                         <IconButton color="inherit" onClick={this.handleClickNolocalizado}>
                                             <Badge color="secondary" badgeContent={this.state.numeronolocalizados}>
                                                 <Typography>No localizados</Typography>
                                             </Badge>
                                         </IconButton>
                                     </td>
                                     <td>
                                         <IconButton color="inherit" onClick={this.handleClickPresuntos}>
                                             <Badge color="secondary" badgeContent={this.state.numeropresuntos}>
                                                 <Typography>Presuntos</Typography>
                                             </Badge>
                                         </IconButton>
                                     </td>
                                     <td>
                                         <IconButton color="inherit" onClick={this.handleClickDefinitivos}>
                                             <Badge color="secondary" badgeContent={this.state.numerodefinitivos}>
                                                 <Typography>Definitivos</Typography>
                                             </Badge>
                                         </IconButton>
                                     </td>
                                     <td>
                                         <IconButton color="inherit" onClick={this.handleClickDesvirtuados}>
                                             <Badge color="secondary" badgeContent={this.state.numerodesvirtuados}>
                                                 <Typography>Desvirtuados</Typography>
                                             </Badge>
                                         </IconButton>
                                     </td>
                                 </tr>
                             </tbody>
                         </table>
                     </CardContent>
                     <CardActions>
                         <Button size="small" color="primary" variant="contained" onClick={this.handleViewCfdisModal} >Ver cfdis validados</Button>
                     </CardActions>
                 </Card>
             </Grid>
         </>);*/
    }
}

export default ViewPanelAdmin;