import React, { Component } from 'react';
import Terminos from '../client/Client/teminoscondiciones';
import Skeleton from '@material-ui/lab/Skeleton';
import api from '../app/Config/api';
function mostrarSaludo() {
    var texto = '';
    var fecha = new Date();
    var hora = fecha.getHours();

    if (hora >= 0 && hora < 12) {
        texto = "Buenos Días";
    }

    if (hora >= 12 && hora < 18) {
        texto = "Buenas Tardes";
    }

    if (hora >= 18 && hora < 24) {
        texto = "Buenas Noches";
    }
    return texto;
}
class Financial extends Component {
    constructor(props) {
        super(props);
        var objec = JSON.parse(localStorage.getItem('id_token'));
        ///  console.log(objec)
        this.state = {
            nombre: objec.nombre + ' ' + objec.apellido_paterno + ' ' + objec.apellido_materno,
            fecha: objec.fecha,
            servicioauditor: true,
            serviciomaquila: true,
            serviciovalidador: true,
            serviciojuridico: true,
            servicioadminfiles: true,
            terminosycondiciones: false,
            check: false,
            skeleton: true,
            datacompany: {}
        }
    }
    componentDidMount() {
        console.log('porque no entra???..............................');
        this.handlegetdata();

    }
    handlegetdata = () => {
        localStorage.removeItem("terminosycondiciones");
        var objec = JSON.parse(localStorage.getItem('id_token'));
        ///let wsa = await api.post('terminosycondiciones', objec);
        // console.log(wsa);
        api.post('terminosycondiciones', objec).then((y) => y.data).then((i) => {
            console.log(i, '==================================>')
            if (i[0].ESTATUS === "ACEPTADO") {
                localStorage.setItem("terminosycondiciones", true);
                this.setState({ terminosycondiciones: true })
                api.post('validacionactivacionend', objec).then((o) => {
                    var serviicio_contratado = o.data;
                    console.log(serviicio_contratado)
                })

                setTimeout(async () => {
                    this.setState({ skeleton: false })
                    const response = await api.post('listcompanysistem', objec)
                    var data = await response.data;
                    localStorage.removeItem("datavalidate");
                    localStorage.setItem("datavalidate", JSON.stringify(data[0]));

                }, 1000);

            } else
                localStorage.setItem("terminosycondiciones", false);
        });
    }
    handledataterminos = (y) => {
        var objec = JSON.parse(localStorage.getItem('id_token'));
        this.setState({ check: !this.state.check });
        setTimeout(() => {
            api.post('aceptaterminosycondiciones', { cliente_id: objec.cliente_id, estatus: (this.state.check ? 'ACEPTADO' : 'NOACEPTADO') }).then((O) => {
                var cont = O.data;
                console.log(cont)
            }).catch((i) => {
                console.log(i)
            })
        }, 300);

    }
    render() {
        return (
            <>
                <div className="page-header border-bottom-0">
                    <div className="page-header-content header-elements-md-inline">
                        <div className="page-title d-flex">
                            <h4><i className="icon-home mr-2"></i> <span className="font-weight-semibold">Inicio</span></h4>
                            <button className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></button>
                        </div>
                    </div>
                </div>
                <div className="content pt-0" style={{ paddingTop: '0px !important' }}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">

                                <div className="card-body">
                                    <h5 className="card-title">{mostrarSaludo()}, {this.state.nombre} </h5>
                                    <h5> Bienvenid@ a E-xpertum.</h5>
                                    <p style={{ textAlign: 'right' }}>E-xpertum le desea que tenga un excelente día.</p>
                                    {this.state.skeleton ? (
                                        <div className="content pt-0">
                                            <Skeleton variant="text" height={40} />
                                            <div className="row">
                                                <div className="col-lg-6"><Skeleton variant="rect" height={150} /></div>
                                                <div className="col-lg-6"><Skeleton variant="rect" height={150} /></div>
                                            </div>
                                            <div style={{ height: 20 }}></div>
                                            <div className="row">
                                                <div className="col-lg-6"><Skeleton variant="rect" height={150} /></div>
                                                <div className="col-lg-6"><Skeleton variant="rect" height={150} /></div>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            {
                                                this.state.terminosycondiciones ? (<div className="content pt-0">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="card card-body text-center">
                                                                <div className="svg-center position-relative" id="progress_icon_one">
                                                                    <svg width={84} height={84}>
                                                                        <g transform="translate(42,42)">
                                                                            <path className="d3-progress-background" d="M0,42A42,42 0 1,1 0,-42A42,42 0 1,1 0,42M0,39.5A39.5,39.5 0 1,0 0,-39.5A39.5,39.5 0 1,0 0,39.5Z" style={{ fill: 'rgb(76, 182, 172)', opacity: '0.1' }} />
                                                                            <path className="d3-progress-foreground" filter="url(#blur)" d="M6.661338147750939e-16,-40.7308237088326A1.25,1.25 0 0,1 1.2883435582822094,-41.98023547904219A42,42 0 1,1 -37.43630272279338,19.040043026405503A1.25,1.25 0 0,1 -36.85435116097611,17.342341263608528L-36.85435116097611,17.342341263608528A1.25,1.25 0 0,1 -35.20795137024616,17.906707131976606A39.5,39.5 0 1,0 1.2116564417177922,-39.48141193862301A1.25,1.25 0 0,1 6.661338147750939e-16,-40.7308237088326Z" style={{ fill: 'rgb(76, 182, 172)', stroke: 'rgb(76, 182, 172)' }} />
                                                                            <path className="d3-progress-front" d="M6.661338147750939e-16,-40.7308237088326A1.25,1.25 0 0,1 1.2883435582822094,-41.98023547904219A42,42 0 1,1 -37.43630272279338,19.040043026405503A1.25,1.25 0 0,1 -36.85435116097611,17.342341263608528L-36.85435116097611,17.342341263608528A1.25,1.25 0 0,1 -35.20795137024616,17.906707131976606A39.5,39.5 0 1,0 1.2116564417177922,-39.48141193862301A1.25,1.25 0 0,1 6.661338147750939e-16,-40.7308237088326Z" style={{ fill: 'rgb(76, 182, 172)', fillOpacity: 1 }} />
                                                                        </g>
                                                                    </svg>
                                                                    <i className="icon-trophy3 counter-icon" style={{ color: 'rgb(76, 182, 172)', top: '26px' }} />
                                                                </div>
                                                                <h3 className="pt-1 mt-2 mb-1">Buró de cumplimiento fiscal</h3>
                                                                Validación de proveedores de calidad.
                                                                <div className="font-size-sm text-muted">Audita a los proveedores y clientes. Disminuye el riesgo de hacer negocios con empresas fraudulentas.</div>
                                                                <br />
                                                                <button className="btn bg-warning-400" onClick={event => window.location.href = '#/app/report/buro-v2'}>Acceder <i className="icon-arrow-right7 mr-3 "></i></button>
                                                                {/*<button className="btn bg-warning-400" onClick={event => window.location.href = '#/app/ui/69bgob'}>Acceder <i className="icon-arrow-right7 mr-3 "></i></button>*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>) : (
                                                    <>
                                                        <Terminos />
                                                        <br></br>
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-md-6">

                                                                        <div class="form-check">
                                                                            <label class="form-check-label" style={{ paddingLeft: 300 }}>
                                                                                <div class="uniform-checker border-primary text-primary" style={{ paddingLeft: 300 }}>
                                                                                    <span className={this.state.check ? "checked" : ""}>
                                                                                        <input type="checkbox" onChange={this.handledataterminos} className="form-check-input-styled-primary" data-fouc="" />
                                                                                    </span>
                                                                                </div> Aceptar Términos y condiciones </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div class="form-check">
                                                                            <button className="btn btn-primary" onClick={this.handlegetdata}>Enviar</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </>
                                    )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Financial;