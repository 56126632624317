import React, { Component } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, } from '@material-ui/core';
import api from '../../Config/api';

class datos_fiscalesedit extends Component {
    constructor(props) {
        super(props);
        //this.state = {}
        this.state = {
            pais: '',
            estado: '',
            municipio: '',
            localidad: '',
            PaisData: [],
            EstadoData: [],
            MuncipioData: [],
            LocalidadesData: [],
            razon_social: '',
            rfc: '',
            calle: '',
            colonia: '',
            numero_interior: '',
            numero_exterior: '',
            codigo_postal: '',
            telefono: '',
            id: 0
        };
    }
    componentDidMount() {
        this.setState(this.props.datofiscal);
    }
    handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });
    }
    handleSaveUpdateData = (event) => {
        api.post("UpdateDatosFicales", this.state).then((obj) => obj.data).then((y) => {
            console.log(y);
        });
    }
    render() {
        return (
            <div className="card">
                <div className="card-body">
                    <div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Denominación social / nombre</label>
                                    <input type="text" value={this.state.razon_social} className="form-control" autoComplete="none" placeholder="Razon social / Nombre" name="razon_social" id="razon_social" onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>RFC</label>
                                    <input type="text" value={this.state.rfc} className="form-control" maxLength={13} autoComplete="none" placeholder="RFC" name="rfc" id="rfc" onChange={this.handleChange} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Colonia</label>
                                    <input type="text" value={this.state.colonia} className="form-control" autoComplete="none" placeholder="Colonia" name="colonia" id="colonia" onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Calle</label>
                                    <input type="text" value={this.state.calle} className="form-control" autoComplete="none" placeholder="Calle" name="calle" id="calle" onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Código postal</label>
                                    <input type="text" value={this.state.codigo_postal} maxLength={7} required={true} className="form-control" autoComplete="none" placeholder="Código postal" name="codigo_postal" id="codigo_postal" onChange={this.handleChange} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Número exterior</label>
                                    <input type="text" value={this.state.numero_exterior} className="form-control" autoComplete="none" placeholder="Número exterior" name="numero_exterior" id="numero_exterior" onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Número interior</label>
                                    <input type="text" value={this.state.numero_interior} className="form-control" autoComplete="none" placeholder="Número interno" name="numero_interior" id="numero_interior" onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>País</label>
                                    <input type="text" value={this.state.pais} className="form-control" autoComplete="none" placeholder="Número interno" name="pais" id="pais" onChange={this.handleChange} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Estado</label>
                                    <input type="text" value={this.state.estado} className="form-control" autoComplete="none" placeholder="Estado" name="estado" disabled id="estado" onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Municipio</label>
                                    <input type="text" value={this.state.municipio} className="form-control" autoComplete="none" placeholder="Municipio" name="municipio" disabled id="municipio" onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Localidad</label>
                                    <input type="text" value={this.state.localidad} className="form-control" autoComplete="none" placeholder="Localidad" name="localidad" disabled id="localidad" onChange={this.handleChange} />
                                </div>
                            </div>
                        </div>
                        <div className="text-right">
                            <button type="submit" className="btn btn-primary" onClick={this.handleSaveUpdateData}>Actualizar<i className="icon-paperplane ml-2"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default datos_fiscalesedit;
