import React, { Component } from 'react';
import api from '../../Config/audit-data';

class CashFlow extends Component {
    constructor(props) {
        super(props);

        let objects = JSON.parse(localStorage.getItem("datacompanyall"));
        this.state = {
            empresa_id: objects.id,
            data: [],
            title: ''
        }
    }
    componentDidMount() {
        this.get_cash_flow();
    }
    get_cash_flow = async () => {
        let _data = await api.post('get-report-annual-clousure', { empresa_id: this.state.empresa_id });
        let _data_ = await _data.data;
        let title = "Estado de flujo de efectivo del 01 al 31 de diciembre de 2022";
        this.setState({
            data: _data_.jsonData_flujo_efectivo,
            title: title.charAt(0).toUpperCase() + title.slice(1)
        });
    }
    render() {
        return (
            <>
                <div className="content pt-0">
                    <div className="card">
                        <div className="card-header bg-transparent border-bottom header-elements-inline py-0">
                            <h6 className="card-title py-3">
                                {this.state.title}
                            </h6>
                            <div className="header-elements">
                                <div>
                                    <button type="button" className="btn btn-light" style={{ margin: '0 10px' }}
                                        onClick={() => {
                                            window.open("https://e-xpertum.com.mx/invoces/api/export-to-excel-data/" + this.state.empresa_id + "/CF");
                                        }}
                                    ><i className="icon-file-excel mr-2 text-success"></i> Excel</button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <table className='table'>
                                <tbody>
                                    {
                                        (this.state.data || []).map((tr, index) => (
                                            <tr key={index}>
                                                {
                                                    index === 0 ? null : (
                                                        index > 3 ?
                                                            (
                                                                <>
                                                                    <td>{tr.descripcion}</td>
                                                                    <td className='text-right'>{tr.ejercicio_2022 != "" ? ((!isNaN(tr.ejercicio_2022)) ? parseFloat(tr.ejercicio_2022).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : tr.ejercicio_2022) : ""}</td>
                                                                    <td className='text-right'>{tr.ejercicio_2021 != "" ? ((!isNaN(tr.ejercicio_2021)) ? parseFloat(tr.ejercicio_2021).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : tr.ejercicio_2021) : ""}</td>
                                                                </>
                                                            ) : (
                                                                index === 3 ? (
                                                                    <>
                                                                        <td colSpan={3} className='text-center'> {"Método que se utilizó para el estado de flujo de efectivo: " + tr.descripcion}</td>
                                                                    </>
                                                                ) : null)
                                                    )
                                                }
                                            </tr>

                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default CashFlow;