import React, { Component } from 'react';
import api from '../../../../app/Config/api';
import Skeleton from '@material-ui/lab/Skeleton';
class Auxiliar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            skeleton: true,
            recorset: [],
            viewauxiliar: true,
            detailspol: [],
            devengados: [],
            realizados: [],
            debe: 0,
            haber: 0,
            auxdebe: 0,
            auxhaber: 0,
        }
    }
    componentDidMount() {
        this.getdata();
    }
    getdata = async () => {
        let pareme = JSON.parse(localStorage.getItem("dataauxiliar"));
        let result_ = await api.post("auxiliarcuentas", pareme);
        let { data } = await result_;
        if (data.estatus == 200) {
            let _title_ = (data.info)[0].info;
            let resultsumdebe = (data.info).reduce((total, currentValue) => total = total + currentValue.debe, 0);
            let resultsumhaber = (data.info).reduce((total, currentValue) => total = total + currentValue.haber, 0);

            this.setState({ recorset: data.info, skeleton: false, title: _title_, auxdebe: resultsumdebe, auxhaber: resultsumhaber });
        } else {
            this.setState({ skeleton: false })
        }

    }

    handleviewpolices = async (i) => {
        //console.log(i)
        this.setState({ skeleton: true });
        let pareme = {
            poliza_id: i.id
        }
        let result_ = await api.post("getdetailspolizas", pareme);
        let { data } = await result_;
        let resultsumdebe = (data.info).reduce((total, currentValue) => total = total + currentValue.debe, 0);
        let resultsumhaber = (data.info).reduce((total, currentValue) => total = total + currentValue.haber, 0);
        //this.props.title('Auxiliar  del mes ' + convertName(pareme.periodo) + ' de la cuenta ' + pareme.numero_de_cuenta);
        this.props.titleprincipal("Detalle de la póliza # " + i.numero_poliza + " Concepto: " + i.concepto + " Fecha: " + i.date);
        let devengados_ = await api.post("getdetailspolizasdevengados", pareme);
        let realizados_ = await api.post("getdetailspolizasrealizados", pareme);

        if ((devengados_.data).estatus === 200) {
            this.setState({ devengados: (devengados_.data).info });
        }
        if ((realizados_.data).estatus === 200) {
            this.setState({ realizados: (realizados_.data).info });
        }
        this.setState({ detailspol: data.info, skeleton: false, viewauxiliar: false, debe: resultsumdebe, haber: resultsumhaber });
    }
    handleInit = () => {
        let pareme = JSON.parse(localStorage.getItem("dataauxiliar"));
        this.props.titleprincipal('Auxiliar  del mes ' + convertName(pareme.periodo) + ' de la cuenta ' + pareme.cuenta_contable);
        this.setState({ skeleton: true });
        setTimeout(() => {
            this.setState({ skeleton: false, detailspol: [], realizados: [], devengados: [], viewauxiliar: true });
        }, 500);

    }
    handleChangeTitleAuxiliar = () => {
        let pareme = JSON.parse(localStorage.getItem("dataauxiliar"));
        this.props.title('Auxiliar  del mes ' + convertName(pareme.periodo) + ' de la cuenta ' + pareme.numero_de_cuenta);
    }
    render() {
        return (

            <>
                {this.state.skeleton ? (
                    <div className="card">
                        <Skeleton />
                        <Skeleton animation={false} height={400} />
                        <Skeleton animation="wave" />
                    </div>
                ) : (
                    <>
                        {
                            (this.state.viewauxiliar) ? (
                                <div className="card">
                                    {
                                        (this.state.recorset.length > 0) ? (
                                            <div className="row">
                                                <div class="d-flex align-items-center" style={{ cursor: 'pointer' }} onClick={this.handleChangeTitleAuxiliar}>
                                                    <i class="icon-arrow-left15 mr-3 icon-2x text-warning"></i>
                                                    <div>Regresar <div class="opacity-50">Balanza de comprobación</div></div>
                                                </div>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>Fecha</th>
                                                            <th>Póliza</th>
                                                            <th>Debe</th>
                                                            <th>Haber</th>
                                                            <th>Saldo final</th>
                                                            <th>Concepto</th>
                                                        </tr>
                                                        <tr>
                                                            <th colSpan={6} className="text-warning">{this.state.title}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            (this.state.recorset || []).map((t, i) => (
                                                                <tr key={i}>
                                                                    <td>{t.date}</td>
                                                                    <td className="text-info" style={{ cursor: 'pointer' }} onClick={() => this.handleviewpolices(t)}>{t.numero_poliza}</td>
                                                                    <td className="text-right">{((t.debe) === null ? 0 : t.debe).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                                    <td className="text-right">{((t.haber) === null ? 0 : t.haber).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                                    <td>{t.saldo_final_calculado}</td>
                                                                    <td>{t.concepto}</td>
                                                                </tr>
                                                            ))
                                                        }
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td className="text-right text-success">{(this.state.auxdebe).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                            <td className="text-right text-success">{(this.state.auxdebe).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        ) :
                                            (
                                                <>
                                                    <div class="d-flex align-items-center" style={{ cursor: 'pointer' }} onClick={this.handleChangeTitleAuxiliar}>
                                                        <i class="icon-arrow-left15 mr-3 icon-2x text-warning"></i>
                                                        <div>Regresar <div class="opacity-50">Balanza de comprobación</div></div>
                                                    </div>
                                                    <br></br>
                                                    <div className='row'>


                                                        <div className='col-lg-1'></div>
                                                        <div className='col-lg-10'>
                                                            <div className="alert alert-warning alert-styled-left alert-dismissible">
                                                                <span className="font-weight-semibold">Atención!</span> No existen pólizas para mostrar los detalles de las cuentas.
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-1'></div>
                                                    </div>
                                                </>

                                            )
                                    }
                                </div>

                            ) :
                                (
                                    <div className="card">
                                        <div className="row">
                                            <div class="d-flex align-items-center" style={{ cursor: 'pointer' }} onClick={this.handleInit}>
                                                <i class="icon-arrow-left15 mr-3 icon-2x text-warning"></i>
                                                <div>Regresar <div class="opacity-50">Auxiliar de cuentas</div></div>
                                            </div>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Cuenta contable</th>
                                                        <th>Descripción</th>
                                                        <th className="text-center">Debe</th>
                                                        <th className="text-center">Haber</th>
                                                        <th>Concepto</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        (this.state.detailspol || []).map((d, o) => (
                                                            <tr key={o}>
                                                                <td>{d.cuenta}</td>
                                                                <td>{d.descripcion}</td>
                                                                <td className="text-right">{((d.debe) === null ? 0 : d.debe).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                                <td className="text-right">{((d.haber) === null ? 0 : d.haber).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                                <td>{d.concepto}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td className="text-right text-success">{(this.state.debe).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                        <td className="text-right text-success">{(this.state.haber).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="card" >
                                            <div class="card-header header-elements-inline">
                                                <h6 class="card-title font-weight-light text-info">Devengados</h6>
                                            </div>
                                            <div class="card-body">
                                                <div className="row" style={{ overflowX: 'auto', width: 1000, whiteSpace: 'nowrap' }}>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>Comprobante</th>
                                                                <th>Identificador comprobante</th>
                                                                <th>Contribuyente</th>
                                                                <th>Monto</th>
                                                                <th>Moneda</th>
                                                                <th>Cambio</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                (this.state.devengados || []).map((d, o) => (
                                                                    <tr key={o}>
                                                                        <td className="text-right">{d.TipoComprobante}</td>
                                                                        <td>{d.IdentificadorComprobante}</td>
                                                                        <td>{d.IdentificadorContribuyente}</td>
                                                                        <td className="text-right">{(d.monto_total).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                                        <td>{d.moneda}</td>
                                                                        <td>{d.TipoCambio}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="card-header header-elements-inline">
                                                <h6 class="card-title font-weight-light text-info">Realizados</h6>
                                            </div>
                                            <div class="card-body">
                                                <div className="row" style={{ overflowX: 'auto', width: 1100, whiteSpace: 'nowrap' }}>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Pago</th>
                                                                <th scope="col">Fecha</th>
                                                                <th scope="col">Cuenta banco</th>
                                                                <th scope="col">Banco origen</th>
                                                                <th scope="col">Monto</th>
                                                                <th scope="col">RFC</th>
                                                                <th scope="col">Beneficiario</th>
                                                                <th scope="col">Banco extranjero</th>
                                                                <th scope="col">Cuenta destino</th>
                                                                <th scope="col">Banco destino nacional</th>
                                                                <th scope="col">Banco destino extranjero</th>
                                                                <th scope="col">Núm. Cheque</th>
                                                                <th scope="col">Moneda</th>
                                                                <th scope="col">Cambio</th>
                                                            </tr>
                                                        </thead>
                                                    </table>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                )
                        }
                    </>
                )
                }
            </>
        );
    }
}

export default Auxiliar;
function convertName(key) {
    switch (key) {
        case "01":
            return "Enero"
            break;
        case "02":
            return "Febrero"
            break;
        case "03":
            return "Marzo"
            break;
        case "04":
            return "Abril"
            break;
        case "05":
            return "Mayo"
            break;
        case "06":
            return "Junio"
            break;
        case "07":
            return "Julio"
            break;
        case "08":
            return "Agosto"
            break;
        case "09":
            return "Septiembre"
            break;
        case "10":
            return "Octubre"
            break;
        case "11":
            return "Noviembre"
            break;
        case "12":
            return "Diciembre"
            break;

    }
}