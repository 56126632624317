import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import { Slide, Typography, IconButton, Toolbar, AppBar, Dialog, Container } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import Inicio from './detalles/index';
const useStyles = theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
        color: "white !important"
    },
});


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
class AuditorSystem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            value: props.value.datos
        }
    }
    componentDidMount() {
        if (this.props.value !== undefined)
            this.setState({ open: true });
    }
    handleClickOpen = () => {
        this.setState({ open: true });
    }

    handleClose = () => {
        this.setState({ open: false });
        this.props.value.clear('');
    }

    render() {
        const { classes } = this.props;
        return (
            <>
                <Dialog fullScreen open={this.state.open} onClose={this.handleClose} TransitionComponent={Transition}>
                    <AppBar style={{ position: 'relative' }}>
                        <Toolbar>
                            <Typography variant="h6" className={classes.title}>Notificaciones de la audítoria</Typography>
                            <IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                                <Icons.Close />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <Container maxWidth='xl'>
                        <Inicio value={{ info: this.state.value, close: this.handleClose }} />
                    </Container>
                </Dialog>
            </>
        );
    }
}

export default withStyles(useStyles)(AuditorSystem);