import React, { Component } from 'react';
import api from '../../Config/apifiles';
import ReactDatatable from '@ashvin27/react-datatable';
class DetailsNomina extends Component {
    constructor(props) {
        super(props);
        var objec = JSON.parse(localStorage.getItem('id_token'));
        this.state = {
            nombre: objec.nombre + " " + objec.apellido_materno + " " + objec.apellido_paterno,
            usuario: objec.usuario,
            correo: objec.email,
            usuario_id: objec.id,
            records: []
        }
        this.columns = [
            {
                key: "nombre",
                text: "Nombre, Denominación o razón social",
                className: "name",
                align: "left",
                sortable: true,
            },
            {
                key: "rfc",
                text: "RFC",
                className: "address",
                align: "left",
                sortable: true
            },
            {
                key: "action",
                text: "Acción",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <div>
                            <button
                                className="btn btn-info btn-sm"
                                onClick={() => this.navegationadmin(record)}>
                                <i className="fas fa-sign-in-alt"></i>
                            </button>
                        </div>
                    );
                }
            }
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,
            button: {
                excel: true,
                print: true,
            },
            language: {
                length_menu: "Mostrar _MENU_ registros por página",
                filter: "Buscar en registros ...",
                info: "Mostrando _START_ a _END_ de _TOTAL_ entradas",
                pagination: {
                    first: "Primero",
                    previous: "Previa",
                    next: "próximo",
                    last: "Última"
                }
            },
            no_data_text: "No se encontraron registros"
        }
    }
    componentDidMount() {
        this.getdata();
    }
    getdata = async () => {
        let data_ = await api.post('list-companys-nomina', { usuario_id: this.state.usuario_id });
        this.setState({ records: data_.data });
    }
    navegationadmin(record) {
        record.proviene = "USER";
        localStorage.removeItem("setdatanomina");
        localStorage.setItem("setdatanomina", JSON.stringify(record));
        const { history } = this.props;
        history.push('/app/ui/sydeyte93ijd248');
    }
    render() {
        return (
            <div className="content-inner">
                <div className="page-header border-bottom-0">
                    <div className="page-header-content header-elements-lg-inline">
                        <div className="page-title d-flex">
                            <a href="#/app/dashboard" style={{ color: 'white' }}> <h4><i className="icon-display4 mr-2" /> <span className="font-weight-semibold">Panel </span> - Nómina</h4></a>
                            <a href="#" className="header-elements-toggle text-body d-lg-none"><i className="icon-more" /></a>
                        </div>
                        <div className="header-elements d-none mb-3 mb-lg-0">
                            <div className="d-flex justify-content-center">
                                <a href="#" className="btn btn-link btn-float text-body" style={{ display: 'none' }}><i className="icon-bars-alt" /><span>Statistics</span></a>
                                <a href="#" className="btn btn-link btn-float text-body" style={{ display: 'none' }}><i className="icon-calculator" /> <span>Invoices</span></a>
                                <a href="#" className="btn btn-link btn-float text-body" style={{ display: 'none' }}><i className="icon-calendar5" /> <span>Schedule</span></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content pt-0">
                    <div className="card">
                        <div className="card-header header-elements-inline">
                            <h5 className="card-title">Administrador</h5>
                            <span className="text-muted d-block">{this.state.nombre}</span>
                        </div>
                        <div className="card-body">
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                            ///extraButtons={this.extraButtons}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DetailsNomina;
