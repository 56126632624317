import React, { Component } from 'react';
import { Tooltip, } from "@material-ui/core";
function diaSemana(x) {
    let date = new Date((x.substr(0, 10)).replace(/-+/g, '/'));
    let options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };
    return date.toLocaleDateString('es-MX', options);
}
class Activity extends Component {
    constructor(props) {
        super(props);
        let objectinfo = JSON.parse(localStorage.getItem("_data_contact_"));
        let user = JSON.parse(localStorage.getItem("id_token"));
        this.state = {
            list: [],
            nombre_corto: user.nombre,
            nombre_: user.nombre + " " + user.apellido_paterno + " " + user.apellido_materno,
        }
    }
    componentDidMount() {
        this.setState({ list: this.props.data })
    }
    handledelete = (j) => {
        this.props.delete(j)
    }
    render() {
        return (
            <>

                {
                    (this.state.list || []).map((i, j) =>
                    (
                        <div key={j}>
                            {
                                i.tipo === "nota" ?
                                    (
                                        <div className="card">
                                            <div className="card-header">
                                                <h5 className="card-title font-weight-semibold">
                                                    <a className="text-info"><i className="icon-pencil7"></i>&nbsp;&nbsp; Nota </a>
                                                    <Tooltip title="Eliminar la nota" placement="top" arrow onClick={() => this.handledelete(i)}><a style={{ float: 'right', cursor: 'pointer' }}><i className="icon-cross3"></i></a></Tooltip>
                                                </h5>
                                            </div>
                                            <div className="card-body">
                                                <p className="mb-3">{i.comentario}</p>
                                                <blockquote className="blockquote blockquote-bordered py-2 pl-3 mb-0">
                                                    <div className="mb-2 font-size-base">{this.state.nombre_}  <span>dejó una nota</span> </div>
                                                    <footer className="blockquote-footer">hora <cite title="Source Title">{i.fecha.substr(11, 5)} </cite></footer>
                                                </blockquote>
                                            </div>
                                            <div className="card-footer bg-transparent d-sm-flex justify-content-sm-between align-items-sm-center border-top-0 pt-0 pb-3">
                                                <ul className="list-inline list-inline-dotted text-muted mb-3 mb-sm-0">
                                                    <li className="list-inline-item">Creado por  <a href="#" className="text-muted">{this.state.nombre_corto} </a></li>
                                                    <li className="list-inline-item">{diaSemana(i.fecha)}</li>

                                                </ul>
                                                <a className="text-muted"> <img src="https://e-xpertum.com.mx/icon.ico" width={13}></img></a>
                                            </div>
                                        </div>
                                    ) : (
                                        i.tipo === "correo" ?
                                            (
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h5 className="card-title font-weight-semibold">
                                                            <a className="text-success"><i className="icon-envelop4"></i>&nbsp;&nbsp; Seguimiento al  correo </a>
                                                            <Tooltip title="Eliminar el correo" placement="top" arrow onClick={() => this.handledelete(i)}><a style={{ float: 'right', cursor: 'pointer' }}><i className="icon-cross3"></i></a></Tooltip>
                                                        </h5>
                                                    </div>
                                                    <div className="card-body">
                                                        <p className="mb-3">{i.asunto}</p>
                                                        <blockquote className="blockquote blockquote-bordered py-2 pl-3 mb-0">
                                                            <div className="mb-2 font-size-base">{i.correo}  </div>
                                                            <footer className="blockquote-footer">hora <cite title="Source Title">{i.fecha.substr(11, 5)} </cite></footer>
                                                        </blockquote>
                                                    </div>
                                                    <div className="card-footer bg-transparent d-sm-flex justify-content-sm-between align-items-sm-center border-top-0 pt-0 pb-3">
                                                        <ul className="list-inline list-inline-dotted text-muted mb-3 mb-sm-0">
                                                            <li className="list-inline-item">Creado por  <a href="#" className="text-muted">{this.state.nombre_corto} </a></li>
                                                            <li className="list-inline-item">{diaSemana(i.fecha)}</li>

                                                        </ul>
                                                        <a className="text-muted"> <img src="https://e-xpertum.com.mx/icon.ico" width={13}></img></a>
                                                    </div>
                                                </div>
                                            )
                                            : (
                                                i.tipo === "llamada" ?
                                                    (
                                                        <div className="card">
                                                            <div className="card-header">
                                                                <h5 className="card-title font-weight-semibold">
                                                                    <a className="text-warning"><i className="icon-phone2"></i>&nbsp;&nbsp; Llamada registrada </a>
                                                                    <Tooltip title="Eliminar llamada" placement="top" arrow onClick={() => this.handledelete(i)}><a style={{ float: 'right', cursor: 'pointer' }}><i className="icon-cross3"></i></a></Tooltip>
                                                                </h5>
                                                            </div>
                                                            <div className="card-body">
                                                                <p className="mb-3">{i.descripcion}</p>
                                                                <blockquote className="blockquote blockquote-bordered py-2 pl-3 mb-0">
                                                                    <div className="row">
                                                                        <div className="col-md-3">
                                                                            <div className="form-group">
                                                                                <label>Contactado</label>
                                                                                <br />
                                                                                <span style={{ fontSize: 12 }}>{this.state.nombre_corto}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <div className="form-group">
                                                                                <label>Resultado</label>
                                                                                <br />
                                                                                <span style={{ fontSize: 12 }}>{i.estatus}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <div className="form-group">
                                                                                <label>Fecha</label>
                                                                                <br />
                                                                                <span style={{ fontSize: 12 }}>{i.fecha_registro.substr(0, 10)}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <div className="form-group">
                                                                                <label>Hora</label>
                                                                                <br />
                                                                                <span style={{ fontSize: 12 }}>{i.hora}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>



                                                                </blockquote>
                                                            </div>
                                                            <div className="card-footer bg-transparent d-sm-flex justify-content-sm-between align-items-sm-center border-top-0 pt-0 pb-3">
                                                                <ul className="list-inline list-inline-dotted text-muted mb-3 mb-sm-0">
                                                                    <li className="list-inline-item">Creado por  <a href="#" className="text-muted">{this.state.nombre_corto} </a></li>
                                                                    <li className="list-inline-item">{diaSemana(i.fecha)}</li>

                                                                </ul>
                                                                <a className="text-muted"> <img src="https://e-xpertum.com.mx/icon.ico" width={13}></img></a>
                                                            </div>
                                                        </div>
                                                    )
                                                    : (
                                                        i.tipo === "cotizacion" ? (
                                                            <div className="card">
                                                                <div className="card-header">
                                                                    <h5 className="card-title font-weight-semibold">
                                                                        <a className="text-danger"><i className="icon-price-tag"></i>&nbsp;&nbsp; Cotización </a>
                                                                        <Tooltip title="Eliminar presentación" placement="top" arrow onClick={() => this.handledelete(i)}><a style={{ float: 'right', cursor: 'pointer' }}><i className="icon-cross3"></i></a></Tooltip>
                                                                    </h5>
                                                                </div>

                                                                <div className="card-body">
                                                                    <p className="mb-3">{i.descripcion}</p>
                                                                    <blockquote className="blockquote blockquote-bordered py-2 pl-3 mb-0">
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                {tabledinamic(JSON.parse(i.tablaprecios))}
                                                                            </div>


                                                                        </div>
                                                                    </blockquote>
                                                                </div>
                                                                <div className="card-footer bg-transparent d-sm-flex justify-content-sm-between align-items-sm-center border-top-0 pt-0 pb-3">
                                                                    <ul className="list-inline list-inline-dotted text-muted mb-3 mb-sm-0">
                                                                        <li className="list-inline-item">Creado por  <a href="#" className="text-muted">{this.state.nombre_corto} </a></li>
                                                                        <li className="list-inline-item">{diaSemana(i.fecha)}</li>

                                                                    </ul>
                                                                    <a className="text-muted"> <img src="https://e-xpertum.com.mx/icon.ico" width={13}></img></a>

                                                                </div>
                                                            </div>
                                                        ) :
                                                            (
                                                                <div className="card">
                                                                    <div className="card-header">
                                                                        <h5 className="card-title font-weight-semibold">
                                                                            <a className="text-danger"><i className="icon-calendar"></i>&nbsp;&nbsp; Presentación </a>
                                                                            <Tooltip title="Eliminar presentación" placement="top" arrow onClick={() => this.handledelete(i)}><a style={{ float: 'right', cursor: 'pointer' }}><i className="icon-cross3"></i></a></Tooltip>
                                                                        </h5>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p className="mb-3">{i.descripcion}</p>
                                                                        <blockquote className="blockquote blockquote-bordered py-2 pl-3 mb-0">
                                                                            <div className="row">
                                                                                <div className="col-md-3">
                                                                                    <div className="form-group">
                                                                                        <label>Plataforma</label>
                                                                                        <br />
                                                                                        <span style={{ fontSize: 12 }}>{i.plataforma}</span>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-md-3">
                                                                                    <div className="form-group">
                                                                                        <label>Fecha</label>
                                                                                        <br />
                                                                                        <span style={{ fontSize: 12 }}>{i.fecha_presentacion.substr(0, 10)}</span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-3">
                                                                                    <div className="form-group">
                                                                                        <label>Hora</label>
                                                                                        <br />
                                                                                        <span style={{ fontSize: 12 }}>{i.hora}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </blockquote>
                                                                    </div>
                                                                    <div className="card-footer bg-transparent d-sm-flex justify-content-sm-between align-items-sm-center border-top-0 pt-0 pb-3">
                                                                        <ul className="list-inline list-inline-dotted text-muted mb-3 mb-sm-0">
                                                                            <li className="list-inline-item">Creado por  <a href="#" className="text-muted">{this.state.nombre_corto} </a></li>
                                                                            <li className="list-inline-item">{diaSemana(i.fecha)}</li>

                                                                        </ul>
                                                                        <a className="text-muted"> <img src="https://e-xpertum.com.mx/icon.ico" width={13}></img></a>

                                                                    </div>
                                                                </div>
                                                            )
                                                    )
                                            )
                                    )
                            }
                        </div>
                    )
                    )
                }
            </>
        );
    }
}

export default Activity;
function tabledinamic(params) {
    if (params.body.length > 0) {
        let contenido_ = params.body;

        return (
            <table className="table table-lg"><thead>
                <tr><th>Servicios</th><th style={{ textAlign: 'center' }}>Costo mensual</th><th style={{ textAlign: 'center' }}>Costo anual</th></tr>
            </thead>
                <tbody>
                    {contenido_.map((f, h) => (
                        <tr key={h}>
                            <td><span className="text-muted">{f.servicio} </span></td>
                            <td className="text-right">{f.extra + "  "}{((f.importemensual)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                            <td className="text-right">{((f.importeanual)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td></td>
                        <td style={{ textAlign: "right" }}>Subtotal:</td>
                        <td style={{ textAlign: "right" }}>{params.subtotal.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} </td>
                    </tr>
                    <tr>
                        <td></td>
                        {
                            params.tipoCotiza === "FRONTERA" ?
                                (
                                    <>
                                        <td style={{ textAlign: "right" }}>IVA (0.8%):</td>
                                        <td style={{ textAlign: "right" }}> {(params.subtotal * 0.08).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} </td>
                                    </>
                                ) :
                                (
                                    <>
                                        <td style={{ textAlign: "right" }}>IVA (0.16%):</td>
                                        <td style={{ textAlign: "right" }}> {(params.subtotal * 0.16).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} </td>
                                    </>
                                )
                        }
                    </tr>
                    <tr>
                        <td></td>
                        {
                            params.tipoCotiza === "FRONTERA" ?
                                (
                                    <>
                                        <td style={{ textAlign: "right" }}>Total:</td>
                                        <td style={{ textAlign: "right" }}> {((params.subtotal * 0.08) + params.subtotal).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} </td>
                                    </>
                                ) :
                                (
                                    <>
                                        <td style={{ textAlign: "right" }}>Total:</td>
                                        <td style={{ textAlign: "right" }}> {((params.subtotal * 0.16) + params.subtotal).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} </td>
                                    </>
                                )
                        }
                    </tr>
                </tfoot>
            </table>
        )
    }
    else {
        return (
            <span>No existe información para mostrar</span>)
    }
}