import React, { Component } from 'react'
//import FilesExtension from '../adminfiles/extensions/extencion-files';
import FilesExtension from '../../client/Dashboard/adminfiles/extensions/extencion-files';
import Extens from '../../client/Dashboard/adminfiles/extensions/ext';
//import Extens from '../adminfiles/extensions/ext';

import { Tooltip } from '@material-ui/core';
import axconfig from '../../app/Config/nomina';
import { Link } from 'react-router-dom';
///import $ from "jquery";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
//import KeyboardDatePickerExample from './_details/date'
/*************************
 * view docs
 */
import PDF from '../../client/Dashboard/adminfiles/viewfiles/pdf';
import IMG from '../../client/Dashboard/adminfiles/viewfiles/img';
//import PDF from '../adminfiles/viewfiles/pdf';
//import IMG from '../adminfiles/viewfiles/img';
//import { Form } from 'react-final-form';
//import api from '../../../app/Config/api';
//import MaskedInput from 'react-text-mask'
import api from '../../app/Config/api';
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const months = ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO',
    'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE'];
var array_data = [];
let add_array_navegation = [];
function bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}
export default class MenuCorporativeLegalAudit extends Component {
    constructor(props) {
        super(props)
        var objectData = JSON.parse(localStorage.getItem("id_token"));
        var datacompany = JSON.parse(localStorage.getItem("setdatanomina"));
        //console.log(datacompany)
        this.state = {
            rfc: datacompany.rfc,
            namecompany: datacompany.nombre,
            data: [],
            breadcrumb: [],
            nombre_directory: '',
            nombre_directorysecond: '',
            directoryfind: [],
            directoryfindsecond: [],
            directoryraiz: true,
            directoryraizsecond: false,
            directoryimport: '',
            viewloading: false,
            open: false,
            openerror: false,
            nombrecarpeta: '',
            statusview: false,
            pro_name: '',
            pro_size: 0,
            pro_fecha: '',
            pro_fecha_actua: '',
            ext: '',
            vertical: 'top',
            horizontal: 'right',
            mensaje: '',
            rename_directory: '',
            level: 0,
            validateext: '',
            dataview: {},
            arraylist: [],
            email: '',
            emailError: false,
            comments: '',
            sizeutilized: 0,
            sizeaquid: 24000000,
            usuario_id: objectData.id,
            cliente_id: objectData.cliente_id,
            usuario: objectData.usuario,
            loading: true,
            /// datos control de fecha
            fecha_admin: '',
            nombredocupload: 'Nombre doc',
            namefile: '',
            empresa_id: datacompany.id,
            importfiles: false,
            alertCompartir: false,
            titlefile: ''
        }
    }
    componentDidMount() {
        this.getTreeInit();
    }
    async getTreeInit() {
        localStorage.removeItem("initdirecoty");
        const result = await axconfig.post("corporative-legal-audit", {
            rfc_user: this.state.rfc,
            usuario_id: this.state.usuario_id,
            usuario: this.state.usuario
        });
        const { data } = await result;
        var resultutilizado = parseInt((bytesToSize((data).size)).replace('Bytes', '').replace('KB', '').replace('MB', '').replace('GB', '').replace('TB', '').trim().trimRight().trimEnd());
        this.setState({
            directoryraiz: true,
            sizeutilized: resultutilizado,
            nombre_directory: data.name,
            data: data.children,
            directoryimport: data.path,
            loading: false
        })
        localStorage.setItem("initdirecoty", data.path);
        array_data = [];
    }
    handleChangeDirectory = (directory) => {
        this.setState({ loading: true })
        var count = array_data.length;

        let array_Data_ = []
        const ejerciciox = (new Date().getFullYear()).toString();
        //console.log(directory)

        axconfig.post("/navegationUrl", { path: directory.path + "/" }).then((t) => t.data)
            .then((k) => {
                if (directory.name === ejerciciox) {
                    array_Data_ = (k.children).sort((a, b) => {
                        return months.indexOf(a.name) - months.indexOf(b.name);
                    });
                }
                array_data.push({
                    name: k.name,
                    path: k.path,
                    mtime: k.mtime,
                    size: k.size,
                    type: k.type,
                    children: k.children,
                    contador: (count === 0 ? 1 : count + 1)
                });
                this.setState({
                    directoryimport: k.path,
                    directoryfind: k.children,
                    breadcrumb: array_data,
                    directoryraiz: false,
                    directoryraizsecond: true,
                    level: (count === 0 ? 1 : count + 1),
                    loading: false
                });
                /*if (directory.name === "1_Pre Nomina" || directory.name === "2_Nomina Autorizada" || directory.name === "3_Comprobantes de dispersion" || directory.name === "4_CFDI de Nomina" || directory.name === "6_AfiliacionDocsTrabajadores" || directory.name === "Comprobantes de pago IMSS" || directory.name === "Comprobantes de pago INFONAVIT" || directory.name === "Comprobantes de pago ISN" || directory.name === "Comprobantes de pago Retencion ISR")
                    this.setState({ importfiles: true })
                else*/
                this.setState({ importfiles: true });
            })

    }
    handleChangeHome = () => {
        array_data = [];
        this.setState({
            directoryfind: [],
            directoryraiz: true,
            directoryraizsecond: false,
            directoryimport: localStorage.getItem("initdirecoty"),
        });
        this.componentDidMount();
    }

    handleChangeFilterData = (m) => {
        this.setState({ loading: true })
        let array_Data_ = [];
        const ejerciciox = (new Date().getFullYear()).toString();
        axconfig.post("/navegationUrl", { path: m.path }).then((t) => t.data)
            .then((k) => {

                if (m.name === ejerciciox) {
                    array_Data_ = (k.children).sort((a, b) => {
                        return months.indexOf(a.name) - months.indexOf(b.name);
                    });
                }

                var _iteracion_resulta_ = [];
                for (let index = 0; index < array_data.length; index++) {
                    if (array_data[index].contador <= m.contador) {
                        _iteracion_resulta_.push(array_data[index])
                    }
                }
                if (m.name === "1_Pre Nomina" || m.name === "2_Nomina Autorizada" || m.name === "3_Comprobantes de dispersion" || m.name === "4_CFDI de Nomina" || m.name === "6_AfiliacionDocsTrabajadores" || m.name === "Comprobantes de pago IMSS" || m.name === "Comprobantes de pago INFONAVIT" || m.name === "Comprobantes de pago ISN" || m.name === "Comprobantes de pago Retencion ISR")
                    this.setState({ importfiles: true })
                else
                    this.setState({ importfiles: false });

                setTimeout(() => {
                    array_data = _iteracion_resulta_;
                    this.setState({
                        directoryfind: k.children,
                        breadcrumb: array_data,
                        directoryraiz: false,
                        directoryraizsecond: true,
                        loading: false
                    });
                }, 200);
            }
            )
    }
    handleChangeFile = (e) => {
        const file = e.target.files[0];
        this.setState({ viewloading: true, nombredocupload: file.name, namefile: file });
        const formData = new FormData();
        formData.append('file', file)
        formData.append('directory', this.state.directoryimport)
        formData.append('level', this.state.level)
        axconfig.post('uploadadminfiles', formData).then((e) => {
            var validacionimportacion = e.data;
            if (validacionimportacion.status === 200) {
                if (array_data.length === 0)
                    this.setState({ open: true, viewloading: false, mensaje: validacionimportacion.messaje, data: validacionimportacion.dir.children, nombredocupload: 'Nombre doc' });
                else
                    this.setState({ open: true, viewloading: false, mensaje: validacionimportacion.messaje, directoryfind: validacionimportacion.dir.children, nombredocupload: 'Nombre doc' });

                window.$('#modalsmallrename').modal('hide');
                window.$('#modal_iconified').modal('hide');

            }
            else if (validacionimportacion.status === 204)
                this.setState({ openerror: true, mensaje: validacionimportacion.messaje, viewloading: false, nombredocupload: 'Nombre doc' });

            window.$('#modalsmallrename').modal('hide');
            window.$('#modal_iconified').modal('hide');
        }).catch((ex) => {
            console.log(ex)
        });

    }
    handleClose = () => {
        window.$('#modal_form_vertical').modal('hide');
    }
    handleProperties = (i) => {

        var nombrefile = i.name.split('.').shift();
        this.setState({
            pro_name: nombrefile, //i.name,
            pro_size: bytesToSize(i.size),
            pro_fecha: i.mtime,
            pro_fecha_actua: i.mtime,
            ext: (i.extension === undefined ? '' : i.extension),
            rename_directory: i.path
        });

    }
    handleConfirmRenameFilesFolder = () => {
        var object_ = {
            directory: this.state.directoryimport + '/',
            dir: this.state.rename_directory,
            name: this.state.pro_name + this.state.ext
        }
        //console.log(object_)
        axconfig.post('renamedirectoryadmin', object_).then((e) => {
            var validacionimportacion = e.data;
            if (validacionimportacion.status === 200) {
                if (array_data.length === 0)
                    this.setState({ open: true, mensaje: validacionimportacion.messaje, data: validacionimportacion.dir.children, });
                else
                    this.setState({ open: true, mensaje: validacionimportacion.messaje, directoryfind: validacionimportacion.dir.children, });

                window.$('#modalsmallrename').modal('hide');
            }
            else if (validacionimportacion.status === 204)
                this.setState({ openerror: true, mensaje: validacionimportacion.messaje });

        }).catch((ex) => {
            console.log(ex)
        })
    }
    handleChangeNameFolder = (e) => {
        this.setState({ pro_name: e.target.value })
    }
    handleSubmitCreateFolder = () => {
        var object_ = {
            directory: this.state.directoryimport + '/',
            namefolder: this.state.pro_name
        }
        axconfig.post('createdirectoryadmin', object_).then((e) => {
            var validacionimportacion = e.data;
            if (validacionimportacion.status === 200) {
                if (array_data.length === 0)
                    this.setState({ open: true, pro_name: '', mensaje: validacionimportacion.messaje, data: validacionimportacion.dir.children, });
                else
                    this.setState({ open: true, pro_name: '', mensaje: validacionimportacion.messaje, directoryfind: validacionimportacion.dir.children, });

                window.$('#modal_form_vertical').modal('hide');
            }
            else if (validacionimportacion.status === 204)
                this.setState({ openerror: true, mensaje: validacionimportacion.messaje });

        }).catch((ex) => {
            console.log(ex)
        })
    }
    handleCloseAlert = () => {
        this.setState({ open: false, openerror: false })
    }
    handleChangeDeleteFileFolder = (j) => {
        this.setState({ nombre_directorysecond: j.path });
    }
    handleConfirmDelete = () => {
        var object_ = {
            directory: this.state.directoryimport,
            dir: this.state.nombre_directorysecond
        }
        axconfig.post('deletedirectoryadmin', object_).then((e) => {
            var validacionimportacion = e.data;
            if (validacionimportacion.status === 200) {
                if (array_data.length === 0)
                    this.setState({ open: true, mensaje: validacionimportacion.messaje, data: validacionimportacion.dir.children, });
                else
                    this.setState({ open: true, mensaje: validacionimportacion.messaje, directoryfind: validacionimportacion.dir.children, });

                window.$('#modalsmalldeletefile').modal('hide');
            }
            else if (validacionimportacion.status === 204)
                this.setState({ openerror: true, mensaje: validacionimportacion.messaje });

        }).catch((ex) => {
            console.log(ex)
        })
    }

    handleValidateExtFile = (i) => {
        if (i.extension === ".pdf" || i.extension === '.png' || i.extension === '.jpg') {
            this.setState({ validateext: i.extension, dataview: i, titlefile: i.name });
            api('https://e-xpertum.com.mx/adminfile/api/viewpdf?query=' + JSON.stringify(i), {
                method: 'GET',
                responseType: 'blob',
            }).then((res) => {
                let blob = new Blob([res.data], { type: 'application/pdf' });
                let url = window.URL.createObjectURL(blob);
                this.setState({ links: url });

            }).catch((ex) => {
                console.log(ex)
            })
            window.$("#modal_scrollable").modal("show")
        }
        else {
            if (i.type === "directory") {
                window.location.href = 'https://e-xpertum.com.mx/adminfile/api/downloadzip?query=' + JSON.stringify({ path: i.path, name: i.name });
            }
            else
                window.location.href = 'https://e-xpertum.com.mx/adminfile/api/download?query=' + JSON.stringify(i);
        }
    }

    handleValidateEmail = (i) => {
        if (i.target.name === "email") {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            const result = pattern.test(i.target.value);
            if (result === true) {
                this.setState({
                    emailError: false,
                    email: i.target.value
                })
            } else {
                this.setState({
                    emailError: true
                })
            }
        }
        else if (i.target.name === "descripcion") {
            this.setState({
                comments: i.target.value
            })
        }
    }
    handleShareSubmit(e) {
        e.preventDefault();
        if (this.refs.correoelectronico.value === '') {
            alert('Debe de agregar un correo valido');
        } else {
            this.setState(state => {
                const list = state.arraylist.push({
                    email: state.email,
                    usuario_id: this.state.usuario_id,
                    cliente_id: this.state.rfc
                });

                return {
                    list,
                    email: '',
                };
            });
        }
        this.refs.correoelectronico.value = '';
    }

    onRemoveItem = (i) => {
        var result = this.state.arraylist.filter(item => item.email !== i.email);
        this.setState({ arraylist: result });
    };

    handleSavesharefile = () => {
        var parameters = {
            directory: this.state.rename_directory,
            email: this.state.arraylist,
            comments: this.state.comments,
            k: this.state.pro_name
        }
        if (this.state.arraylist.length > 0) {
            //console.log(parameters)
            axconfig.post('sharefiles', parameters).then((f) => {
                if (f.data === "ok") {
                    this.setState({
                        comments: '',
                        arraylist: []
                    })
                    window.$("#modalshare").modal("hide");
                }
            }).catch((g) => {
                console.log(g)
            });
        }
        else {
            this.setState({ alertCompartir: true });
            setTimeout(() => {
                this.setState({ alertCompartir: false });
            }, 5000);
        }
    }
    handleDonwloadFiles = () => {
        window.location.href = 'https://e-xpertum.com.mx/adminfile/api/downloadrespaldo';
    }
    handleClearData = () => {
        this.setState({ pro_name: '' });
    }
    /***************************
     * Datos para el control de las fechas
     * 
     */
    handleAdminDate = (fecha) => {
        this.setState({ fecha_admin: fecha });
    }
    handleSaveDateAdmin = () => {
        const query = "INSERT INTO doc_nomina_dispersion (empresa_id,fecha,url,nombre_doc,status)VALUES(" + this.state.empresa_id + ",'" + this.state.fecha_admin + "','" + this.state.directoryimport + "','" + this.state.nombredocupload + "',1) SELECT 'OK' GOOD";
        api.post("configtabledinamyc", { query: query }).then((i) => i.data).then((j) => {
            if (j[0].GOOD === "OK") {
                window.$("#modal_iconified").modal("hide");
            }
        }).catch((y) => {
            console.log(y);
        });
    }
    handleNameFileRename = (i) => {
        this.setState({ namefile: i.target.value })
    }
    SaveDataRemane = () => {
        var object_ = {
            directory: this.state.directoryimport + '/',
            dir: this.state.rename_directory,
            name: 'Pre_Nomina_' + this.state.namecompany.replace('.', '_') + this.state.namefile + this.state.ext
        }

        axconfig.post('renamedirectoryadmin', object_).then((e) => {
            var validacionimportacion = e.data;
            if (validacionimportacion.status === 200) {
                if (array_data.length === 0)
                    this.setState({ open: true, namefile: '', pro_name: '', mensaje: validacionimportacion.messaje, data: validacionimportacion.dir.children, });
                else
                    this.setState({ open: true, namefile: '', pro_name: '', mensaje: validacionimportacion.messaje, directoryfind: validacionimportacion.dir.children, });

                window.$('#modalsmallrename').modal('hide');
            }
            else if (validacionimportacion.status === 204)
                this.setState({ openerror: true, mensaje: validacionimportacion.messaje });

        }).catch((ex) => {
            console.log(ex)
        })
    }
    render() {
        const { vertical, horizontal } = this.state;
        return (
            <>
                <Backdrop open={this.state.loading} style={{ color: "#fff", background: "#B9B6B6", zIndex: 400, opacity: "0.5" }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div className="content-wrapper" style={{ overflowX: 'hidden' }}>
                    <div className="page-header border-bottom-0">
                        <div className="page-header-content header-elements-md-inline">
                            <div className="page-title d-flex">
                                <a href="#/app/audit/corporate-legal-audit" style={{ color: '#fff' }}><h4><i className="icon-arrow-left52 mr-2"></i> <span className="font-weight-semibold">Listado</span> - empresas</h4></a>
                                <Link to="#" className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></Link>
                            </div>
                            <h5 className="title" style={{ paddingLeft: '25%' }}>{this.state.namecompany}</h5>
                            <div className="header-elements d-none mb-3 mb-md-0">

                            </div>
                        </div>
                    </div>

                    <div className="content pt-0">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="mb-3 border-top-1 border-top-primary">
                                        {this.state.viewloading ? (<div className="card card-body border-top-danger text-center">
                                            <div>
                                                <div className="pace-demo bg-dark ml-2">
                                                    <div className="theme_xbox_sm theme_xbox_with_text">
                                                        <div className="pace_progress" data-progress-text="99%" data-progress="100"></div>
                                                        <div className="pace_activity"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>) :
                                            (
                                                this.state.directoryraiz ? (
                                                    <div className="page-header page-header-light" >
                                                        <div className="breadcrumb-line header-elements-md-inline">
                                                            <div className="d-flex">
                                                                <div className="breadcrumb">
                                                                    <Link to="#" className="breadcrumb-item" onClick={this.handleChangeHome}><i className="icon-home2 mr-2"></i> {this.state.nombre_directory}</Link>
                                                                </div>
                                                                <Link to="#" className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></Link>
                                                            </div>

                                                            <div className="header-elements d-none">
                                                                <div className="breadcrumb justify-content-center">
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                    <Tooltip style={{ display: 'none' }} title={
                                                                        <table className="table">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td><strong>IMPORTANTE:</strong> Nombrar el archivo a subir. Todos los archivos (excel / pdf)</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    } placement="bottom" arrow>
                                                                        <label className="breadcrumb-elements-item" data-toggle="modal" data-target="#modal_iconified">
                                                                            <div className="uniform-uploader" style={{ cursor: 'pointer' }}>
                                                                                <i className="icon-folder-upload mr-2" style={{ userSelect: 'none', cursor: 'pointer' }}></i>
                                                                                Subir archivos
                                                                            </div>
                                                                        </label></Tooltip>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                    <input type="file" id="_upload_file_" className="form-control-uniform-custom" style={{ display: 'none' }} name="docs" />
                                                                    <div className="breadcrumb justify-content-center" style={{ display: 'none' }}>
                                                                        <div className="breadcrumb-elements-item" data-toggle="modal" data-target="#modal_form_vertical" style={{ cursor: 'pointer' }}>
                                                                            <i className="icon-folder-plus2 mr-2"></i>
                                                                            Nueva carpeta
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                {
                                                                    (this.state.data || []).map((op, u) => (
                                                                        <div className="col-sm-2 col-lg-2" key={u}>

                                                                            <div className="card">
                                                                                {op.type === "directory" ? (
                                                                                    <>
                                                                                        <div className="ml-3">
                                                                                            <div className="list-icons" style={{ float: 'right' }}>
                                                                                                <a className="list-icons-item dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="icon-menu7"></i></a>
                                                                                                <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end" style={{ "position": "absolute", willChange: "transform", "top": "0px", "left": "0px", "transform": "translate3d(305px, 82px, 0px)" }}>
                                                                                                    {op.type !== "directory" ? (
                                                                                                        <>
                                                                                                            <a className="dropdown-item" data-toggle="modal" data-target="#modalsmalldeletefile" onClick={() => this.handleChangeDeleteFileFolder(op)} ><i className="icon-cancel-square"></i> Eliminar</a>
                                                                                                            <a className="dropdown-item" data-toggle="modal" data-target="#modalsmallrename" onClick={() => this.handleProperties(op)}><i className="icon-pencil"></i> Renombrar</a>
                                                                                                        </>
                                                                                                    ) : null}

                                                                                                    <div className="dropdown-divider"></div>
                                                                                                    <a className="dropdown-item" onClick={() => this.handleValidateExtFile(op)}><i className="icon-file-download"></i> Descarga normal</a>

                                                                                                    <div className="dropdown-divider"></div>
                                                                                                    <a style={{ display: 'none' }} className="dropdown-item" data-toggle="modal" data-target="#modalshare" onClick={() => this.handleProperties(op)}><i className="icon-share2"></i> Compartir</a>
                                                                                                    <a className="dropdown-item" data-toggle="modal" data-target="#modal_small" onClick={() => this.handleProperties(op)}><i className="icon-notification2"></i> Información</a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="media-body">
                                                                                            <button type="button" className="btn btn-light border-light-alpha btn-block btn-float m-0 border-bottom-0 border-left-0" onClick={() => this.handleChangeDirectory(op)}>
                                                                                                <FilesExtension ext={op} />
                                                                                                <span>{op.name}</span>
                                                                                            </button>
                                                                                        </div>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <div className="ml-3">
                                                                                            <div className="list-icons" style={{ float: 'right' }}>
                                                                                                <a className="list-icons-item dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="icon-menu7"></i></a>
                                                                                                <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end" style={{ "position": "absolute", willChange: "transform", "top": "0px", "left": "0px", "transform": "translate3d(305px, 82px, 0px)" }}>
                                                                                                    {op.type !== "directory" ? (
                                                                                                        <>
                                                                                                            <a className="dropdown-item" data-toggle="modal" data-target="#modalsmalldeletefile" onClick={() => this.handleChangeDeleteFileFolder(op)} ><i className="icon-cancel-square"></i> Eliminar</a>
                                                                                                            <a className="dropdown-item" data-toggle="modal" data-target="#modalsmallrename" onClick={() => this.handleProperties(op)}><i className="icon-pencil"></i> Renombrar</a>
                                                                                                        </>
                                                                                                    ) : null}

                                                                                                    <div className="dropdown-divider"></div>
                                                                                                    <a className="dropdown-item" onClick={() => this.handleValidateExtFile(op)}><i className="icon-file-download"></i> Descarga normal</a>

                                                                                                    <div className="dropdown-divider"></div>
                                                                                                    <a style={{ display: 'none' }} className="dropdown-item" data-toggle="modal" data-target="#modalshare" onClick={() => this.handleProperties(op)}><i className="icon-share2"></i> Compartir</a>
                                                                                                    <a className="dropdown-item" data-toggle="modal" data-target="#modal_small" onClick={() => this.handleProperties(op)}><i className="icon-notification2"></i> Información</a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <button type="button" className="btn btn-light border-light-alpha btn-block btn-float m-0 border-bottom-0 border-left-0" onClick={() => this.handleValidateExtFile(op)}>
                                                                                            <FilesExtension ext={op} />
                                                                                            {(op.extension === ".pdf" || op.extension === ".jpg" || op.extension === ".png") ?
                                                                                                (
                                                                                                    <>
                                                                                                        <span>{op.name}</span>
                                                                                                    </>
                                                                                                ) :
                                                                                                <span onClick={() => this.handleValidateExtFile(op)}>{op.name}</span>
                                                                                            }

                                                                                        </button>
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) :
                                                    (
                                                        <>
                                                            {this.state.directoryraizsecond ? (
                                                                <div className="page-header page-header-light">
                                                                    <div className="breadcrumb-line header-elements-md-inline">
                                                                        <div className="d-flex">
                                                                            <div className="breadcrumb">
                                                                                <a style={{ cursor: 'pointer' }} className="breadcrumb-item" onClick={this.handleChangeHome}><i className="icon-home2 mr-2"></i> {this.state.nombre_directory}</a>
                                                                                {
                                                                                    (this.state.breadcrumb || []).map((y, ip) => (
                                                                                        <a style={{ cursor: 'pointer' }} className="breadcrumb-item" key={ip} onClick={() => this.handleChangeFilterData(y)}> {y.name}</a>
                                                                                    ))
                                                                                }
                                                                            </div>

                                                                            <Link to="#" className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></Link>
                                                                        </div>
                                                                        <div className="header-elements d-none">
                                                                            <div className="breadcrumb justify-content-center">
                                                                                <div className="breadcrumb justify-content-center">
                                                                                    {
                                                                                        this.state.importfiles ? (<Tooltip title={
                                                                                            <table className="table">
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td><strong>IMPORTANTE:</strong> Nombrar el archivo a subir. Todos los archivos (excel / pdf) requieren contener EL NOMBRE DE SU COMPAÑIA y FECHA DEL PERIODO NOMINA CORRESPONDIENTE.</td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        } placement="bottom" arrow>
                                                                                            <label className="breadcrumb-elements-item" data-toggle="modal" data-target="#modal_iconified">
                                                                                                <div className="uniform-uploader" style={{ cursor: 'pointer' }}>
                                                                                                    <i className="icon-folder-upload mr-2" style={{ userSelect: 'none', cursor: 'pointer', marginTop: 2 }}></i>
                                                                                                    Subir archivos
                                                                                                </div>
                                                                                            </label></Tooltip>) : null
                                                                                    }


                                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                    <input type="file" id="_upload_file_" className="form-control-uniform-custom" style={{ display: 'none' }} name="docs" onChange={this.handleChangeFile} />
                                                                                    <div className="breadcrumb justify-content-center">
                                                                                        <div className="breadcrumb-elements-item" data-toggle="modal" data-target="#modal_form_vertical" style={{ cursor: 'pointer' }}>
                                                                                            <i className="icon-folder-plus2 mr-2"></i>Nueva carpeta
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="card-body">
                                                                        <div className="row">
                                                                            {
                                                                                (this.state.directoryfind || []).map((op, u) => (

                                                                                    <div className="col-sm-2 col-lg-2" key={u}>

                                                                                        <div className="card">
                                                                                            {op.type === "directory" ? (
                                                                                                <>
                                                                                                    <div className="ml-3">
                                                                                                        <div className="list-icons" style={{ float: 'right' }}>
                                                                                                            <a className="list-icons-item dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="icon-menu7"></i></a>
                                                                                                            <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end" style={{ "position": "absolute", willChange: "transform", "top": "0px", "left": "0px", "transform": "translate3d(305px, 82px, 0px)" }}>
                                                                                                                {op.type !== "directory" ? (
                                                                                                                    <>

                                                                                                                    </>
                                                                                                                ) : null}
                                                                                                                <a className="dropdown-item" data-toggle="modal" data-target="#modalsmalldeletefile" onClick={() => this.handleChangeDeleteFileFolder(op)} ><i className="icon-cancel-square"></i> Eliminar</a>
                                                                                                                <a className="dropdown-item" data-toggle="modal" data-target="#modalsmallrename" onClick={() => this.handleProperties(op)}><i className="icon-pencil"></i> Renombrar</a>

                                                                                                                <div className="dropdown-divider"></div>
                                                                                                                <a className="dropdown-item" onClick={() => this.handleValidateExtFile(op)}><i className="icon-file-download"></i> Descarga normal</a>

                                                                                                                <div className="dropdown-divider"></div>
                                                                                                                <a style={{ display: 'none' }} className="dropdown-item" data-toggle="modal" data-target="#modalshare" onClick={() => this.handleProperties(op)}><i className="icon-share2"></i> Compartir</a>
                                                                                                                <a className="dropdown-item" data-toggle="modal" data-target="#modal_small" onClick={() => this.handleProperties(op)}><i className="icon-notification2"></i> Información</a>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <button type="button" className="btn btn-light border-light-alpha btn-block btn-float m-0 border-bottom-0 border-left-0" onClick={() => this.handleChangeDirectory(op)}>
                                                                                                        <FilesExtension ext={op} />
                                                                                                        <span>{op.name}</span>
                                                                                                    </button>
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    <div className="ml-3">
                                                                                                        <div className="list-icons" style={{ float: 'right' }}>
                                                                                                            <a className="list-icons-item dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="icon-menu7"></i></a>
                                                                                                            <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end" style={{ "position": "absolute", willChange: "transform", "top": "0px", "left": "0px", "transform": "translate3d(305px, 82px, 0px)" }}>
                                                                                                                {op.type !== "directory" ? (
                                                                                                                    <>
                                                                                                                        <a className="dropdown-item" data-toggle="modal" data-target="#modalsmalldeletefile" onClick={() => this.handleChangeDeleteFileFolder(op)} ><i className="icon-cancel-square"></i> Eliminar</a>
                                                                                                                        <a className="dropdown-item" data-toggle="modal" data-target="#modalsmallrename" onClick={() => this.handleProperties(op)}><i className="icon-pencil"></i> Renombrar</a>
                                                                                                                    </>
                                                                                                                ) : null}

                                                                                                                <div className="dropdown-divider"></div>
                                                                                                                <a className="dropdown-item" onClick={() => this.handleValidateExtFile(op)}><i className="icon-file-download"></i> Descarga normal</a>

                                                                                                                <div className="dropdown-divider"></div>
                                                                                                                <a style={{ display: 'none' }} className="dropdown-item" data-toggle="modal" data-target="#modalshare" onClick={() => this.handleProperties(op)}><i className="icon-share2"></i> Compartir</a>
                                                                                                                <a className="dropdown-item" data-toggle="modal" data-target="#modal_small" onClick={() => this.handleProperties(op)}><i className="icon-notification2"></i> Información</a>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <button type="button" className="btn btn-light border-light-alpha btn-block btn-float m-0 border-bottom-0 border-left-0" onClick={() => this.handleValidateExtFile(op)}>
                                                                                                        <FilesExtension ext={op} />
                                                                                                        {(op.extension === ".pdf" || op.extension === ".jpg" || op.extension === ".png") ?
                                                                                                            (
                                                                                                                <>
                                                                                                                    <span>{op.name}</span>
                                                                                                                </>
                                                                                                            ) :
                                                                                                            <span onClick={() => this.handleValidateExtFile(op)}>{op.name}</span>
                                                                                                        }
                                                                                                    </button>
                                                                                                </>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    <p>Error al cargar la información</p>
                                                                </>
                                                            )}
                                                        </>
                                                    )
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Snackbar open={this.state.open} autoHideDuration={6000} onClose={this.handleCloseAlert} anchorOrigin={{ vertical, horizontal }}>
                    <Alert onClose={this.handleCloseAlert} severity="success">
                        {this.state.mensaje}
                    </Alert>
                </Snackbar>
                <Snackbar open={this.state.openerror} autoHideDuration={6000} onClose={this.handleCloseAlert} anchorOrigin={{ vertical, horizontal }}>
                    <Alert onClose={this.handleCloseAlert} severity="error">
                        {this.state.mensaje}
                    </Alert>
                </Snackbar>



                <div id="modal_form_vertical" className="modal fade" tabIndex="-1" aria-hidden="true" style={{ display: 'none' }}>
                    <div className="modal-dialog modal-sm">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Nombre de la carpeta</h5>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>
                            <div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <input type="text" placeholder="Nombre carpeta / Julissa" value={this.state.pro_name} onChange={this.handleChangeNameFolder} className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <a className="btn btn-link" data-dismiss="modal" onClick={this.handleClose}>Cancelar</a>
                                    <a className="btn bg-primary" onClick={this.handleSubmitCreateFolder}>Crear carpeta</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="modal_small" className="modal fade" tabIndex="-1" aria-hidden="true" style={{ "display": "none" }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title"></h5>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>

                            <div className="modal-body">
                                <ul className="media-list">
                                    <li className="media">
                                        <div className="mr-3">
                                            <a className="icon-2x text-primary-300 border-primary-300 border-3 rounded-round p-3 mb-3 mt-1">
                                                <Extens ext={this.state.ext} />
                                            </a>
                                        </div>
                                        <div className="media-body">
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <th>Nombre:</th>
                                                            <td className="text-left">{this.state.pro_name}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Tamaño:</th>
                                                            <td className="text-left">{this.state.pro_size}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Última modificación:</th>
                                                            <td className="text-left">{this.state.pro_fecha_actua}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Fecha subida:</th>
                                                            <td className="text-left">{this.state.pro_fecha}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <th>Formato de renombre del archivo Pre-Nómina</th>
                                                        </tr>
                                                        <tr>
                                                            <td>Pre_Nómina_NombreCompania_fecha_version.xlxs</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Ejemplo: Pre_Nómina_{this.state.namecompany}_15_30_sept_ver_1.xlxs</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="modalshare" className="modal fade" tabIndex="-1" aria-hidden="true" style={{ "display": "none" }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title"></h5>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>

                            <div className="modal-body">
                                <ul className="media-list">
                                    <li className="media">
                                        <div className="mr-3">
                                            <a className="icon-2x text-primary-300 border-primary-300 border-3 rounded-round p-3 mb-3 mt-1">
                                                <Extens ext={this.state.ext} />
                                            </a>
                                        </div>
                                        <div className="media-body">
                                            <div className="table-responsive">
                                                {this.state.alertCompartir ? (<div className="alert alert-warning alert-styled-right alert-dismissible">
                                                    <button type="button" className="close" data-dismiss="alert"><span>×</span></button>
                                                    <span className="font-weight-semibold">Usuario!</span> Debe de ingresar al menos un correo para compartir la información.
                                                </div>) : null}
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <div className="input-group">
                                                                    <span className="input-group-prepend">
                                                                        <span className="input-group-text">
                                                                            <i className="icon-mailbox"></i>
                                                                        </span>
                                                                    </span>
                                                                    <input autoComplete="off" type="text" className="form-control" name="email" placeholder="Ingresa un correo eletrónico" ref="correoelectronico" onChange={this.handleValidateEmail} />
                                                                    <span className="input-group-append" style={{ cursor: 'pointer' }} onClick={this.handleShareSubmit.bind(this)}>
                                                                        <span className="input-group-text"><i className="icon-stack-plus"></i></span>
                                                                    </span>
                                                                </div>

                                                                {this.state.emailError ? <span style={{ color: "red" }}>Por favor ingrese una dirección de correo electrónico válida</span> : ''}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <ul>
                                                                    {this.state.arraylist.map((item, j) => (
                                                                        <li key={j}>{item.email}&nbsp;&nbsp;&nbsp;
                                                                            <i className="icon-cross2 warning" style={{ fontSize: 12 }} onClick={() => this.onRemoveItem(item)}></i>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <textarea rows="2" cols="2" className="form-control" value={this.state.comments} name="descripcion" placeholder="Agregar comentario a los destinatarios." onChange={this.handleValidateEmail}></textarea>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ float: 'right' }}>
                                                                <button className="btn btn-primary" onClick={this.handleSavesharefile}>Compartir</button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="modalsmallrename" className="modal fade" tabIndex="-1" style={{ "display": "none" }} aria-hidden="true">
                    <div className="modal-dialog modal-sm">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Renombrar archivo</h5>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>

                            <div className="modal-body">
                                <div className="row">

                                    <div className="col-sm-12">
                                        <input type="text" placeholder="Nombre carpeta / Julissa" value={this.state.pro_name || ""} onChange={this.handleChangeNameFolder} className="form-control" />
                                    </div>


                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-link" data-dismiss="modal" onClick={this.handleClearData}>Cancelar</button>
                                <button type="button" className="btn bg-primary" onClick={this.handleConfirmRenameFilesFolder}>Renombrar</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="modalsmalldeletefile" className="modal fade" tabIndex="-1" style={{ "display": "none" }} aria-hidden="true">
                    <div className="modal-dialog modal-sm">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Eliminar archivo</h5>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>

                            <div className="modal-body">
                                <p>¿Estás seguro de eliminar el archivo?</p>
                                <p>Aviso: El archivo o carpeta se eliminará definitivamente del directorio</p>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-link" data-dismiss="modal">No</button>
                                <button type="button" className="btn bg-primary" onClick={this.handleConfirmDelete}>Si</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="modal_scrollable" className="modal fade" tabIndex="-1">
                    <div className="modal-dialog modal-full">
                        <div className="modal-content">
                            <div className="modal-header pb-3">
                                <h5 className="modal-title">{this.state.titlefile}</h5>
                                <button type="button" className="close" data-dismiss="modal" onClick={() => this.setState({ validateext: '' })}>×</button>
                            </div>

                            <div className="modal-body py-0" >
                                {
                                    (this.state.validateext === ".png" || this.state.validateext === ".jpg") ?
                                        <IMG view={this.state.dataview} /> :
                                        (this.state.validateext == ".pdf" ? (
                                            <div className='containeriframe'>
                                                <iframe className='responsiveiframeall scroll scroll1'
                                                    src={this.state.links}
                                                    frameBorder={0} allowFullScreen
                                                />
                                            </div>

                                        ) : null)
                                }
                            </div>
                        </div>
                    </div>
                </div>


                <div id="modal_iconified" className="modal fade" tabIndex="-1" style={{ "display": "none" }} aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title"><i className="icon-menu7 mr-2"></i> &nbsp;Importación de archivos</h5>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-4">Archivo a importar</label>
                                        <div className="col-lg-8">
                                            <div className="uniform-uploader">
                                                <input type="file" className="form-control-uniform-custom" onChange={this.handleChangeFile} />
                                                <span className="filename" style={{ userSelect: 'none' }}>{this.state.nombredocupload}</span>
                                                <span className="action btn bg-warning" style={{ userSelect: 'none' }}>Seleccione el archivo</span>
                                            </div>
                                        </div>

                                    </div>

                                </form>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-link" data-dismiss="modal"><i className="icon-cross2 font-size-base mr-1"></i> Cancelar</button>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
