import React, { Component } from 'react';
///import api from '../../../../app/Config/apifiles';
import api from '../../app/Config/audit-data';
import { Tooltip } from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination'
import ReactDatatable from '@ashvin27/react-datatable';
import Skeleton from '@material-ui/lab/Skeleton';
import { PacmanLoader } from "react-spinners";
class CuentaContable extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "fecha",
                text: "Fecha",
                className: "fecha",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <div className="text-left">
                            {
                                <p style={{ fontSize: 12 }}>{String(record.fecha).slice(0, 10)}</p>
                            }</div>
                    );
                }
            },
            {
                key: "rfc",
                text: "RFC",
                className: "rfc",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <div className="text-left">
                            {
                                <p style={{ fontSize: 12 }}>{record.rfc}</p>
                            }</div>
                    );
                }
            },
            {
                key: "nombre",
                text: "Nombre",
                className: "nombre",
                sortable: true,
                cell: record => {
                    return (
                        <div className="text-left">
                            {
                                <p style={{ fontSize: 12 }}>{record.nombre}</p>
                            }</div>
                    );
                }
            },
            {
                key: "uuid",
                text: "UUID",
                className: "uuid",
                cell: record => {
                    return (
                        <div className="text-left">
                            {
                                <p style={{ fontSize: 12 }}>{record.uuid}</p>
                            }</div>
                    );
                }
            },
            {
                key: "tipo",
                text: "Tipo",
                className: "tipo",
                sortable: true,
                cell: record => {
                    return (
                        <div className="text-center">
                            {
                                <p className="text-succcess" style={{ fontSize: 14 }}>{record.tipo}</p>
                            }</div>
                    );
                }
            },
            {
                key: "monto",
                text: "Total",
                className: "total",
                align: "center",
                sortable: true,
                cell: record => {
                    return (
                        <div className="text-right">
                            {
                                <p className="text-danger" style={{ fontSize: 14 }}>{(record.monto) === 0 ? '-' : (record.monto).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                            }</div>
                    );
                }
            }
        ];
        this.extraButtons = [
            {
                className: "btn btn-primary",
                title: "Descargar reporte",
                children: [
                    <span>
                        <i className="icon-file-excel" aria-hidden="true" ></i>
                    </span>
                ],
                onClick: (event) => {
                    window.open("https://e-xpertum.com.mx/invoces/api/download-invoce-xml-faltante/" + this.state.empresa_id)
                },
            },
        ]
        this.config = {
            show_pagination: false,
            key_column: 'uuid',
            page_size: 100,
            length_menu: [100, 200, 500],
            button: {
                excel: false,
                print: false,
                extra: true,
            },
            "language": {
                "length_menu": "Mostrar _MENU_ registros por página",
                "filter": "Buscar en registros ...",
                "info": "Mostrando _START_ a _END_ de _TOTAL_ entradas",
                "pagination": {
                    "first": "Primero",
                    "previous": "Previa",
                    "next": "próximo",
                    "last": "Última"
                }
            }
        }

        this.state = {
            recorset: [],
            skeleton: true,
            loading: true,
            page: 1,
            lastPage: 40
        }
    }
    componentDidMount() {
        this.getPaginations();
        ///  this.getdata();
        ///this.setState({ recorset: [], skeleton: false, loading: false });
    }
    getPaginations = async () => {
        let pareme = JSON.parse(localStorage.getItem("parameterscompany"));
        let result_ = await api.post("pagination-polices-cfdis", { empresa_id: pareme.id });
        const { startPage, lastPage } = await result_.data;
        this.setState({
            page: startPage,
            lastPage: lastPage
        });
        ///console.log(result_)
        this.getdata(startPage);
    }
    getdata = async (page) => {
        let pareme = JSON.parse(localStorage.getItem("parameterscompany"));
        let result_ = await api.post("read-polices-list-validate", { empresa_id: pareme.id, page: page });
        let { data } = await result_;
        this.setState({ recorset: data, skeleton: false, empresa_id: pareme.id, loading: false, page: page });
    }
    handlePageChange = async (event, value) => {
        this.setState({ skeleton: true, loading: true });
        this.getdata(value)
    }
    render() {
        return (
            <>
                {
                    this.state.skeleton ? (
                        <div className="card">
                            <Skeleton />
                            <div style={{
                                margin: 40,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <PacmanLoader color={"#ff7043"} loading={this.state.loading} size={25} margin={2} />
                            </div>
                            <Skeleton variant="circle" animation="wave" width={40} height={40} />
                            <Skeleton variant="rect" animation="wave" width={"100%"} height={118} />
                            <Skeleton animation="wave" />
                        </div>
                    ) : (
                        <>
                            <ReactDatatable
                                config={this.config}
                                records={(this.state.recorset || [])}
                                columns={this.columns}
                                extraButtons={this.extraButtons}
                            />
                            <div style={{ float: 'right' }}>
                                <Pagination
                                    count={this.state.lastPage}
                                    page={this.state.page}
                                    siblingCount={1}
                                    boundaryCount={1}
                                    variant="text"
                                    shape="round"
                                    onChange={this.handlePageChange}
                                />
                            </div>


                        </>
                    )
                }
            </>
        );
    }
}

export default CuentaContable;