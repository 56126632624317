import React, { Component } from 'react';
import PropTypes from './Config/_date_view';
import WkF from './Config/daysweek';
import api from '../../app/Config/apifiles';
class ConfigNotificacion extends Component {
    constructor(props) {
        super(props);
        var objec = JSON.parse(localStorage.getItem('id_token'));
        //console.log(objec)
        this.state = {
            perfil: objec.perfil,
            viewWeek: false,
            viewDay: false,
            cliente_id: objec.cliente_id,
            datamails: [],
            tipo: '',
            config: '',
            messageError: '',
            correo: '',
            WEEK: false,
            DAYL: false,
            NOWL: false
        }
    }
    componentDidMount() {
        this.get_lits_();
    }
    get_lits_ = async () => {
        let data_ = await api.post('list-config-email-notification', { cliente_id: this.state.cliente_id });
        let f = data_.data;
        for (let z = 0; z < f.length; z++) {
            this.setState({
                tipo: f[z].tipo,
                config: f[z].config,
            })
        }


        if (this.state.tipo == "NOW")
            this.setState({ datamails: data_.data, NOWL: true, DAYL: false, WEEK: false });
        else if (this.state.tipo == "WEEK")
            this.setState({ datamails: data_.data, NOWL: false, DAYL: false, WEEK: true, viewWeek: true, viewDay: false, });
        else if (this.state.tipo == "DAY")
            this.setState({ datamails: data_.data, NOWL: false, DAYL: true, WEEK: false, viewWeek: false, viewDay: true, });

    }
    setChangeNotify = async (r) => {
        if (r.target.value == "WEEK")
            this.setState({ viewWeek: true, viewDay: false, NOWL: false, DAYL: false, WEEK: true, tipo: r.target.value });
        else if (r.target.value == "DAY")
            this.setState({ viewWeek: false, viewDay: true, NOWL: false, DAYL: true, WEEK: false, tipo: r.target.value });
        else {
            let d = {
                tipo: "NOW",
                config: "",
                cliente_id: this.state.cliente_id
            }
            let _de_P = await api.post('save-config-email-notificacion', d);
            this.get_lits_();
            this.setState({ viewWeek: false, viewDay: false, NOWL: true, DAYL: false, WEEK: false, tipo: "NOW" });
        }
    }
    setEmailCapture = (d) => {
        if ((d.target.value).length > 0) {
            let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!regEmail.test(d.target.value)) {
                this.setState({ messageError: 'El correo es invalido' });
            }
            else
                this.setState({ messageError: '', });

            this.setState({ correo: d.target.value });
        } else
            this.setState({ messageError: '', });
    }
    setSaveMail = async () => {
        if (this.state.correo != '') {
            let xp_ = {
                cliente_id: this.state.cliente_id,
                tipo: this.state.tipo,
                config: this.state.config,
                correo: this.state.correo,
            }
            let _d_ap_ = await api.post('new-config-email-notificacion', xp_)
            this.get_lits_();
            this.setState({ correo: '' });
        }
    }
    deletemail = async (f) => {
        let _de_P = await api.post('delete-config-email-notificacion', f);
        this.get_lits_();
    }
    setDayMonths = async (r) => {
        let d = {
            tipo: this.state.tipo,
            config: { dia: r },
            cliente_id: this.state.cliente_id
        }
        let _de_P = await api.post('save-config-email-notificacion', d);
        this.get_lits_();
    }
    setWeekFnd = async (r) => {
        let d = {
            tipo: this.state.tipo,
            config: r,
            cliente_id: this.state.cliente_id
        }
        let _de_P = await api.post('save-config-email-notificacion', d);
        console.log(_de_P)
        setTimeout(() => {
            this.get_lits_();
        }, 300);

    }
    render() {
        return (
            <div className="content-wrapper">
                <div className="page-header border-bottom-0">
                    <div className="page-header-content header-elements-md-inline">
                        <div className="page-title d-flex">
                            {this.state.perfil === "GOBIERNO" ? (<a href="#/app/ui/validadorgobierno" style={{ color: 'white' }}> <h4><i className="icon-arrow-left52 mr-2"></i> <span className="font-weight-semibold">Inicio</span></h4></a>) :
                                (<a href="#" style={{ color: 'white' }}> <h4>
                                    <i className="icon-arrow-left52 mr-2"></i>
                                    <span className="font-weight-semibold">Inicio</span></h4></a>)
                            }
                            <a href="#" className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></a>
                        </div>
                    </div>
                </div>
                <div className="content pt-0">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">Configuración de notificiones</h5>
                        </div>
                        <div className="card-body">
                            <fieldset>
                                <div className="row">
                                    <div className="col-lg-7">
                                        <form action="#">
                                            <div className="form-group row">
                                                <label className="col-lg-6 col-form-label">¿Cuando desea recibir las notificaciones? </label>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-lg-4">
                                                    <label className="custom-control custom-radio custom-control">
                                                        <input type="radio" className="custom-control-input" name="gender3" value="WEEK" onChange={this.setChangeNotify} checked={this.state.WEEK} />
                                                        <span className="custom-control-label">Día de la semana</span>
                                                    </label>
                                                    <label className="custom-control custom-radio custom-control">
                                                        <input type="radio" className="custom-control-input" name="gender3" value="DAY" onChange={this.setChangeNotify} checked={this.state.DAYL} />
                                                        <span className="custom-control-label">Día del mes</span>
                                                    </label>
                                                    <label className="custom-control custom-radio custom-control">
                                                        <input type="radio" className="custom-control-input" name="gender3" value="NOW" onChange={this.setChangeNotify} checked={this.state.NOWL} />
                                                        <span className="custom-control-label">Diario</span>
                                                    </label>
                                                </div>
                                                <div className="col-lg-8">
                                                    {
                                                        this.state.viewWeek ? (
                                                            <div className="form-group row">
                                                                <div className="col-lg-8">
                                                                    <WkF setdays={this.setWeekFnd} data={this.state} />
                                                                </div>
                                                            </div>
                                                        ) : null
                                                    }
                                                    {
                                                        this.state.viewDay ? (
                                                            <div className="form-group row">
                                                                <div className="col-lg-8">
                                                                    <PropTypes setDate={this.setDayMonths} />
                                                                    <br />
                                                                    {
                                                                        (this.state.config != null && JSON.parse(this.state.config) != '' && JSON.parse(this.state.config).dia != undefined) ? (
                                                                            (<span className="text-success text-center">
                                                                                {console.log((String(JSON.parse(this.state.config).dia).split('-'))[0])}
                                                                                Cada {(String(JSON.parse(this.state.config).dia).split('-'))[0]} de mes se enviará la notificación
                                                                            </span>)
                                                                        ) : null
                                                                    }</div>
                                                            </div>) : null
                                                    }
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="col-lg-5">
                                        <div className="form-group row">
                                            <label className="col-lg-12 col-form-label">Agregar más destinatarios </label>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-lg-12">
                                                <div className="input-group">
                                                    <span className="input-group-prepend">
                                                        <button className="btn btn-light btn-icon" type="button"><i className="icon-mail5" /></button>
                                                    </span>
                                                    <input type="text" className="form-control" placeholder="Ingrese el correo" onChange={this.setEmailCapture} value={this.state.correo} />
                                                    <span className="input-group-append">
                                                        <button className="btn btn-light" type="button" onClick={this.setSaveMail}>Agregar</button>
                                                    </span>
                                                </div>
                                                <br />
                                                <span style={{ color: '#bc2734' }}>{this.state.messageError}</span>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="card-body">
                                                {
                                                    (this.state.datamails || []).map((x, c) => (
                                                        <div className="media" key={c}>
                                                            <div className="mr-3">
                                                                <button className="btn btn-outline-success rounded-pill border-2 btn-icon"><i className="icon-mention" /></button>
                                                            </div>
                                                            <div className="media-body">
                                                                <h6 className="mb-0">{x.correo}</h6>
                                                                <span className="text-muted">Activo</span>
                                                            </div>
                                                            <div className="ml-3 align-self-center">
                                                                <button type="button" className="btn btn-icon" onClick={() => this.deletemail(x)}><i className="icon-trash text-danger" /></button>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ConfigNotificacion;