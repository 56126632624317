import React from 'react'
import {
  Table,
  Header,
  HeaderRow,
  Body,
  Row,
  HeaderCell,
  Cell,
} from "@table-library/react-table-library/table";
import { useTheme } from "@table-library/react-table-library/theme";

import {
  useTree,
  CellTree,
} from "@table-library/react-table-library/tree";
import { createTreeEdoResult, createTreeBalances } from './create-tree';
export default function ExportFinancial(props) {
  let nodes = (props.type === "er") ? createTreeEdoResult(props.data) : createTreeBalances(props.data);
  const data = nodes != undefined ? { nodes } : [];
  let titles = ["Descripción"];
  if (nodes != undefined && nodes.length > 0) {
    let header = (nodes != undefined && nodes.length > 0) ? data.nodes[0] : [];
    for (const [key, value] of Object.entries(header)) {
      if (key != "nodes" && value != undefined && key != "category" && key != "id" && key != "children")
        titles.push(key);
    }
  }
  const THEME = useTheme({
    Table: `
    height: 100%;
  `,
    Header: ``,
    Body: ``,
    CellTree: `width:900px`,
    BaseRow: `
    background-color: var(--theme-ui-colors-background);

    &.row-select-selected, &.row-select-single-selected {
      background-color: var(--theme-ui-colors-background-secondary);
      color: var(--theme-ui-colors-text);
    }

    height: 52px;
  `,
    HeaderRow: `
    font-size: 15px;
    color: var(--theme-ui-colors-text-light);

    border-bottom: 1px solid var(--theme-ui-colors-border);
  `,
    Row: `
    font-size: 12px;
    color: var(--theme-ui-colors-text);

    &:not(:last-of-type) {
      border-bottom: 1px solid var(--theme-ui-colors-border);
    }

    &.first {
      border-top: 1px solid var(--theme-ui-colors-border);
    }

    &:hover {
      color: var(--theme-ui-colors-text);
    }
  `,
    BaseCell: `
    border-bottom: 1px solid transparent;
    border-right: 1px solid transparent;
    padding: 8px 0;
    svg {
      fill: var(--theme-ui-colors-text);
    }
  `,
    HeaderCell: `text-align: right`,
    Cell: `
        text-align: right
    `,
  });
  const theme = useTheme(THEME);

  const tree = useTree(data, {

  }, {
    treeIcon: {
      margin: "4px",
      iconRight: <i className="icon-arrow-right32"></i>,
      iconDown: <i className="icon-arrow-down32"></i>,
    },
  });

  return (
    <Table data={data} theme={theme} tree={tree}>
      {(tableList) => (
        <>
          <Header>
            <HeaderRow>
              {
                titles.length > 0 ? (
                  titles.map((ds, i) => (
                    <HeaderCell key={i}>{ds}</HeaderCell>
                  ))
                ) : null
              }
            </HeaderRow>
          </Header>
          <Body>
            {tableList.map((item, index) => (
              <Row key={index} item={item}>
                {
                  titles.length > 0 ? (
                    titles.map((ds, i) => (
                      ds === "Descripción" ? (
                        <CellTree item={item} key={i}>{item.category}</CellTree>
                      ) : (
                        <>
                          <Cell Cell key={i} > {(item[ds]) === 0 ? '-' : (item[ds] === undefined ? '-' : (item[ds]).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</Cell>
                        </>)
                    ))
                  ) : null
                }

              </Row>
            ))}
          </Body>
        </>
      )}
    </Table>
  );
}



