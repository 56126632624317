import React, { Component } from 'react'
import { Dialog, DialogContent, AppBar, Toolbar, IconButton, Typography, Box } from '@material-ui/core';
import { Tooltip, } from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
import { PacmanLoader } from "react-spinners";
import TableView from './screen-pepers-works/table-view';
import TableViewFirts from './screen-pepers-works/table-view-firts';
import ReportCFDIAll from './screen-reports';
import ReportPaymentBills from './screen-reports/report-payment-bills';
import CedulaA from './screen-pepers-works/cedula-analitica';
import api from '../app/Config/apifiles';
import axios from 'axios';
const nameMonth = ["", "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
class IndexTraslado extends Component {
    constructor(props) {
        super(props)
        let objects = JSON.parse(localStorage.getItem("datacompanyall"));
        this.state = {
            data: [],
            dataToYear: [],
            viewyear: true,
            viewmonths: false,
            viewmonthsdetails: false,
            view_detail_cfdis_: false,
            skeletonyear: true,
            skeletonmonth: false,
            display_loading_: 0,
            empresa_id: objects.id,
            nombre: objects.nombre,
            rfc: objects.rfc,
            params_: { key: "", month: "", year: 0 },
            year: 0,
            title: "Comparativo anual de actos o actividades que causan el IVA trasladado: Facturado vs Declarado",
            titleinit: "Comparativo anual de actos o actividades que causan el IVA trasladado: Facturado vs Declarado",
            title_mont: "",
            title_level_one: "",
            title_level_two: "",
            title_level_three: "",
            title_level_four: "",
            url_pdf: "",
            sum_acuses_to_month_: 0,
            sum_cfdis_to_month_: 0,
            sum_diferencia_: 0,
            link_acuse: "",
            view_details_month: "",
            subview: "", month: "", level: 0, viewTypeReport: "", view_iva_trasladado: "", open_modal: false
        }
    }

    componentDidMount() {
        this.gethandleData();

    }
    gethandleData = async () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "empresa_id": this.state.empresa_id,
            "rfc": ""
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://e-xpertum.com.mx/adminfile/api/compare-iva-impuesto-al-valor-agregado-end", requestOptions)
            .then(response => response.text())
            .then(result => {
                this.setState({
                    data: JSON.parse(result),
                    skeletonyear: false
                });
            })
            .catch(error => console.log('error', error));
        /*     let data = await api.post("compare-iva-impuesto-al-valor-agregado", { empresa_id: this.state.empresa_id });
                                                                                                                                                                            //let data = await axios.post("http://localhost:3020/api/compare-iva-impuesto-al-valor-agregado", { empresa_id: this.state.empresa_id });
                                                                                                                                                                             */
    }
    handleGetDataToYear(y) {
        setTimeout(() => {
            this.setState({
                year: y.year,
                dataToYear: y.dt,
                skeletonmonth: false,
                view_detail_cfdis_: false,
            });
        }, 1000);

    }
    handleviewIvaTraslado = (x) => {
        if (x.dir != "") {
            this.setState({
                view_detail_cfdis_: false,
                link_acuse: 'https://e-xpertum.com.mx/adminfile/api/displaypdf?query={"directorio":"' + x.dir + '"}'
            });
            window.$("#_modal_app_").modal("show");
        }
    }
    handleViewDetailsMonths = async (d) => {
        this.setState({
            viewmonths: false,
            display_loading_: 1
        })
        let _params_ = { empresa_id: this.state.empresa_id, anio: this.state.year, mes: d.number };
        await api.post('report-iva-work-d', _params_).then((result) => {
            let { body, header, titles } = result.data;
            this.setState({ header: header, body: body, skeleton: false, level: 1, subtitle: "Inicio" });
            setTimeout(() => {
                this.setState({
                    month: d.number,
                    level: 0,
                    display_loading_: 0,
                    viewyear: false,
                    viewmonths: false,
                    viewmonthsdetails: true,
                    view_detail_cfdis_: false,
                    skeletonyear: false,
                    skeletonmonth: false,
                    params_: d,
                    handleViewDetailsMonths: "",
                    title_level_one: "Determinación del IVA trasladado de  " + d.title + " de " + this.state.year,
                    title: "Determinación del IVA trasladado de  " + d.title + " de " + this.state.year,
                    view_details_month: <TableView header={this.state.header} title={titles} body={this.state.body} handleviewDetails={this.handleDetailsFilter} />
                });
            }, 100);

        }).catch((e) => {
            console.log(e)
        });
    }
    handleDetailsFilter = async (y) => {
        this.setState({
            subview: '',
            viewmonthsdetails: false,
            display_loading_: 2,
        });
        let _params_ = { empresa_id: this.state.empresa_id, anio: this.state.year, mes: this.state.month }
        if (y.clave === "CEDULAA" || y.clave === "CEDULAB" || y.clave === "CEDULAC") {

            await api.post(y.endpoint, _params_).then((result) => {
                let { body, header, titles } = result.data;
                setTimeout(() => {
                    this.setState({
                        level: 0,
                        view_detail_cfdis_: true,
                        display_loading_: 0,
                        title_level_two: "Parcial de CFDIs emitidos " + y.descripcion + " de " + nameMonth[parseInt(this.state.month)] + " del " + this.state.year,
                        title: "Parcial de CFDIs emitidos " + y.descripcion + " de  " + nameMonth[parseInt(this.state.month)] + " del " + this.state.year,
                        subtitle: 'Regresar',
                        subview: <TableViewFirts header={header} body={body} titles={titles} viewreport={this.handleViewReport} />
                    });
                }, 100);
            }).catch((e) => {
                console.log(e)
            });
        }
    }
    handleViewReport = async (r) => {
        let object_params = {
            empresa_id: this.state.empresa_id,
            anio: this.state.year,
            month: this.state.month,
            clase: r.clave
        }
        this.setState({
            skeleton: true,
            loading: true,
            level: 0,
            display_loading_: 3,
            view_detail_cfdis_: false,
        })
        if (r.clave === "EI-0" || r.clave === "EI-4") {
            this.setState({
                skeleton: false,
                loading: false,
                level: 3,
                display_loading_: 0,
                title: "Cedula analítica de cfdis emitidos de ingreso del mes ",
                viewTypeReport: <ReportCFDIAll link={r.link} params={object_params} />
            })
        }
        else if (r.clave === "EE-0" || r.clave === "EE-A") {
            this.setState({
                skeleton: false,
                loading: false,
                level: 3,
                display_loading_: 0,
                title: "Cedula analítica de cfdis emitidos de egresos del mes ",
                viewTypeReport: <ReportCFDIAll link={r.link} params={object_params} />
            })
        }
        else if (r.clave === "EE-1") {
            await api.post(r.link, { empresa_id: this.state.empresa_id, anio: this.state.year, month: this.state.month, clase: r.clave }).then((result) => {
                // this.setState({ data: result.data });
                this.setState({
                    skeleton: false,
                    loading: false,
                    level: 3,
                    display_loading_: 0,
                    title: "Cedula analítica de cfdis " + r.descripcion + " del mes " + nameMonth[parseInt(this.state.month)] + " de " + this.state.year,
                    viewTypeReport: <ReportPaymentBills data={result.data} />
                })
            }).catch((error) => {
                console.log(error);
            })
        }
        else if (r.clave === "EP") {
            let params_det = {
                empresa_id: this.state.empresa_id, anio: this.state.year, month: this.state.month,
                clase: r.clave
            };
            await api.post(r.link, params_det).then((result) => {

                // this.setState({ data: result.data });
                this.setState({
                    skeleton: false,
                    loading: false,
                    level: 3,
                    display_loading_: 0,
                    title: "Cedula analítica de cfdis " + r.descripcion + " del mes " + nameMonth[parseInt(this.state.month)] + " de " + this.state.year,
                    viewTypeReport: <ReportPaymentBills data={result.data} />
                })
            }).catch((error) => {
                console.log(error);
            })
        }
        else {
            this.setState({
                skeleton: false,
                loading: false
            })
        }  /**/
    }
    handle_View_Report_IVA = async (r) => {
        this.setState({
            year: r.year,
            open_modal: true,
            view_iva_trasladado: <CedulaA anio={r.year} />
        })
    }

    render() {
        return (
            <div className="content-wrapper">
                <div className="content-inner">
                    <div className="content pt-0">
                        <div className="row">
                            <div className='col-lg-12'>
                                <div className="card">
                                    <div className="card-header bg-transparent border-bottom header-elements-inline py-0"
                                        style={{ height: "65px" }}>
                                        <h6 className="card-title">{this.state.title}</h6>
                                        <div className="header-elements">
                                            {
                                                (this.state.viewyear) ? (
                                                    <Tooltip title="Click para descargar el papel de trabajo" placement="top" arrow>
                                                        <button type="button" className="btn btn-outline-success"
                                                            onClick={() => window.open("https://e-xpertum.com.mx/adminfile/api/compare-iva-impuesto-al-valor-agregado-excel/" + this.state.empresa_id + "/" + this.state.rfc + "/" + this.state.nombre)}>
                                                            <i className="icon-file-excel" />
                                                        </button>
                                                    </Tooltip>
                                                ) : null
                                            }
                                            {
                                                (this.state.viewmonths) ? (
                                                    <>
                                                        <Tooltip title="Click para descargar el papel de trabajo" placement="top" arrow>
                                                            <button type="button" className="btn btn-outline-success"
                                                                onClick={() => window.open("https://e-xpertum.com.mx/adminfile/api/compare-iva-impuesto-al-valor-agregado-excel-to-year/" + this.state.empresa_id + "/" + this.state.year + "/" + this.state.rfc + "/" + this.state.nombre)}>
                                                                <i className="icon-file-excel" />
                                                            </button>
                                                        </Tooltip>
                                                        <button type="button" onClick={() => {
                                                            this.setState({
                                                                viewmonths: false,
                                                                viewyear: true, title: this.state.titleinit
                                                            })
                                                        }} className="btn btn-link"><i className="icon-arrow-left16 mr-3 icon-2x"></i>Inico</button>
                                                    </>
                                                ) : null
                                            }
                                            {
                                                (this.state.viewmonthsdetails) ? (
                                                    <>
                                                        <button type="button" onClick={() => {
                                                            window.open("https://e-xpertum.com.mx/adminfile/api/report-excel-iva-work-d/exports-files/" + this.state.empresa_id + "@" + this.state.year + "@" + this.state.month)
                                                        }} className="btn btn-outline-success"><i className="icon-file-excel" /></button>
                                                        <button type="button" onClick={() => {
                                                            this.setState({
                                                                viewmonths: true,
                                                                viewyear: false, title: this.state.title_mont,
                                                                viewmonthsdetails: false
                                                            })
                                                        }} className="btn btn-link"><i className="icon-arrow-left16 mr-3 icon-2x"></i> Regresar</button>
                                                    </>


                                                ) : null
                                            }
                                            {
                                                (this.state.view_detail_cfdis_) ? (
                                                    <button type="button" onClick={() => {
                                                        this.setState({
                                                            viewmonths: false,
                                                            viewyear: false,
                                                            viewmonthsdetails: true,
                                                            title: this.state.title_level_one,
                                                            view_detail_cfdis_: false
                                                        })
                                                    }} className="btn btn-link"><i className="icon-arrow-left16 mr-3 icon-2x"></i> Regresar</button>
                                                ) : null
                                            }
                                            {
                                                (this.state.level) ? (
                                                    <button type="button" onClick={() => {
                                                        this.setState({
                                                            //viewmonths: true,
                                                            viewyear: false,
                                                            view_detail_cfdis_: true,
                                                            viewmonths: false,
                                                            title: this.state.title_level_two,
                                                            level: 0
                                                        })
                                                    }} className="btn btn-link"><i className="icon-arrow-left16 mr-3 icon-2x"></i> Regresar</button>
                                                ) : null
                                            }
                                        </div>
                                    </div>

                                    <div className="card-body">
                                        {
                                            this.state.viewyear ? (
                                                <div>
                                                    {
                                                        this.state.skeletonyear ? (
                                                            <Loading />
                                                        ) : (
                                                            <div className="content" style={{ width: "100%" }}>
                                                                <div className='wrapper'>
                                                                    <table className='table' style={{ width: "2000px" }}>
                                                                        <thead>
                                                                            <tr key={"iweuierriere"}>
                                                                                <th rowSpan={2} className='text-center' style={{ width: "200px" }}>Ejercicio</th>
                                                                                <th colSpan={8} className='text-center'>VALOR DE ACTOS O ACTIVIDADES</th>
                                                                                <th colSpan={2} className='text-center'>IVA TRASLADADO</th>
                                                                            </tr>
                                                                            <tr key={"kjkjfdkfn393403"}>
                                                                                <th></th>
                                                                                <th style={{ width: "220px" }}>Tasa del 16%</th>
                                                                                <th style={{ width: "200px" }}>No objeto del impuesto</th>
                                                                                <th style={{ width: "200px" }}>Tasa exenta</th>
                                                                                <th style={{ width: "200px" }}>Tasa 0% (Otros)</th>
                                                                                <th style={{ width: "200px" }}>Tasa 8% (Zona fronteriza)</th>
                                                                                <th style={{ width: "200px" }}>Suma de los actos o actividades gravados</th>
                                                                                <th style={{ width: "200px" }}>Valor de los actos o actividades (Exentos)</th>
                                                                                <th style={{ width: "200px" }}>A tasa del 16%</th>
                                                                                <th style={{ width: "200px" }}>A tasa del 8%</th>
                                                                                <th style={{ width: "200px" }}>Total IVA Trasladado  a cargo</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                (this.state.data || []).map((d, index) => (
                                                                                    <>
                                                                                        <tr key={index}>
                                                                                            <td rowSpan={3}>
                                                                                                <Tooltip title="Clic para ver el comparativo por mes" placement="top" arrow>
                                                                                                    <button type="button" style={{ width: "80%" }} onClick={() => {
                                                                                                        this.setState({
                                                                                                            viewyear: false,
                                                                                                            viewmonths: true,
                                                                                                            skeletonmonth: true,
                                                                                                            title: "Comparativo mensual de actos o actividades que causan el IVA trasladado: Facturado vs Declarado " + d.year,
                                                                                                            title_mont: "Comparativo mensual de actos o actividades que causan el IVA trasladado: Facturado vs Declarado " + d.year
                                                                                                        })
                                                                                                        this.handleGetDataToYear(d)
                                                                                                    }} className="btn btn-outline-primary btn-lg">
                                                                                                        <i className="icon-file-xml mr-2"></i>{d.year}
                                                                                                    </button>
                                                                                                </Tooltip>
                                                                                            </td>
                                                                                            <td>Facturado</td>
                                                                                            <td className='text-right'>
                                                                                                {(d.sum_tasa_16_anual_cfdis === 0 ? "-" : parseFloat(d.sum_tasa_16_anual_cfdis).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}
                                                                                                {/*   <Tooltip title={"Clic para ver el reporte del ejercicio " + d.year} placement="top" arrow>
                                                                                                    <button type="button" style={{ width: "100%" }}
                                                                                                       // onClick={() => this.handle_View_Report_IVA(d)}
                                                                                                        className="btn btn-outline-primary btn-lg">
                                                                                                        <i className="icon-stack mr-2" style={{ float: 'left' }} />
                                                                                                        {(d.sum_tasa_16_anual_cfdis === 0 ? "-" : parseFloat(d.sum_tasa_16_anual_cfdis).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}
                                                                                                    </button>
                                                                                                </Tooltip> */}
                                                                                            </td>
                                                                                            <td className='text-right'>{(d.sum_no_objeto_impuest_cfdis === 0 ? "-" : parseFloat(d.sum_no_objeto_impuest_cfdis).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                                                                            <td className='text-right'>{(d.sum_tasa_0_exportacion_anual_cfdis === 0 ? "-" : parseFloat(d.sum_tasa_0_exportacion_anual_cfdis).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                                                                            <td className='text-right'>{(d.sum_tasa_0_otros_anual_cfdis === 0 ? "-" : parseFloat(d.sum_tasa_0_otros_anual_cfdis).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                                                                            <td className='text-right'>{(d.sum_tasa_8_anual_cfdis === 0 ? "-" : parseFloat(d.sum_tasa_8_anual_cfdis).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                                                                            <td className='text-right'>{(d.sum_suma_actividades_cfdis === 0 ? "-" : parseFloat(d.sum_suma_actividades_cfdis).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                                                                            <td className='text-right'>{(d.sum_valor_actos_actividades_exentos_cfdis === 0 ? "-" : parseFloat(d.sum_valor_actos_actividades_exentos_cfdis).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                                                                            <td className='text-right'>{(d.sum_tasa_16_iva_trasladado_cfdis === 0 ? "-" : parseFloat(d.sum_tasa_16_iva_trasladado_cfdis).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                                                                            <td className='text-right'>{(d.sum_tasa_8_iva_trasladado_cfdis === 0 ? "-" : parseFloat(d.sum_tasa_8_iva_trasladado_cfdis).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                                                                            <td className='text-right'>{((d.sum_tasa_16_iva_trasladado_cfdis + d.sum_tasa_8_iva_trasladado_cfdis) === 0 ? "-" : parseFloat((d.sum_tasa_16_iva_trasladado_cfdis + d.sum_tasa_8_iva_trasladado_cfdis)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                                                                        </tr>
                                                                                        <tr key={(index + 198989)}>
                                                                                            <td>Declarado</td>
                                                                                            <td className='text-right'>{(d.sum_tasa_16_anual_acuses === 0 ? "-" : parseFloat(d.sum_tasa_16_anual_acuses).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                                                                            <td className='text-right'>{(d.sum_no_objeto_impuesto_acuses === 0 ? "-" : parseFloat(d.sum_no_objeto_impuesto_acuses).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                                                                            <td className='text-right'>{(d.sum_tasa_0_exportacion_anual_acuses === 0 ? "-" : parseFloat(d.sum_tasa_0_exportacion_anual_acuses).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                                                                            <td className='text-right'>{(d.sum_tasa_0_otros_anual_acuses === 0 ? "-" : parseFloat(d.sum_tasa_0_otros_anual_acuses).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                                                                            <td className='text-right'>{(d.sum_tasa_8_anual_acuses === 0 ? "-" : parseFloat(d.sum_tasa_8_anual_acuses).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                                                                            <td className='text-right'>{(d.sum_suma_actividades_acuses === 0 ? "-" : parseFloat(d.sum_suma_actividades_acuses).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                                                                            <td className='text-right'>{(d.sum_valor_actos_actividades_exentos_acuses === 0 ? "-" : parseFloat(d.sum_valor_actos_actividades_exentos_acuses).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                                                                            <td className='text-right'>{(d.sum_tasa_16_iva_trasladado_acuses === 0 ? "-" : parseFloat(d.sum_tasa_16_iva_trasladado_acuses).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                                                                            <td className='text-right'>{(d.sum_tasa_8_iva_trasladado_acuses === 0 ? "-" : parseFloat(d.sum_tasa_8_iva_trasladado_acuses).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                                                                            <td className='text-right'>{((d.sum_tasa_16_iva_trasladado_acuses + d.sum_tasa_8_iva_trasladado_acuses) === 0 ? "-" : parseFloat((d.sum_tasa_16_iva_trasladado_acuses + d.sum_tasa_8_iva_trasladado_acuses)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                                                                        </tr>
                                                                                        <tr key={(index + 298998934)}>
                                                                                            <td><span className='text-warning'>Diferencia</span> </td>
                                                                                            <td className='text-right'>
                                                                                                {(d.sum_tasa_16_anual_cfdis - d.sum_tasa_16_anual_acuses) < 0 ?
                                                                                                    <span className='text-success'>
                                                                                                        {parseFloat((d.sum_tasa_16_anual_cfdis - d.sum_tasa_16_anual_acuses)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                    </span>
                                                                                                    : ((d.sum_tasa_16_anual_cfdis - d.sum_tasa_16_anual_acuses) === 0 ? ("-") :
                                                                                                        <span className='text-danger'>
                                                                                                            {parseFloat((d.sum_tasa_16_anual_cfdis - d.sum_tasa_16_anual_acuses)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                        </span>)
                                                                                                }
                                                                                            </td>
                                                                                            <td className='text-right'>
                                                                                                {(d.sum_no_objeto_impuest_cfdis - d.sum_no_objeto_impuesto_acuses) < 0 ?
                                                                                                    <span className='text-success'>
                                                                                                        {parseFloat((d.sum_no_objeto_impuest_cfdis - d.sum_no_objeto_impuesto_acuses)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                    </span>
                                                                                                    : ((d.sum_no_objeto_impuest_cfdis - d.sum_no_objeto_impuesto_acuses) === 0 ? "-" :
                                                                                                        <span className='text-danger'>
                                                                                                            {parseFloat((d.sum_no_objeto_impuest_cfdis - d.sum_no_objeto_impuesto_acuses)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                        </span>)
                                                                                                }
                                                                                            </td>
                                                                                            <td className='text-right'>
                                                                                                {(d.sum_tasa_0_exportacion_anual_cfdis - d.sum_tasa_0_exportacion_anual_acuses) < 0 ?
                                                                                                    <span className='text-success'>
                                                                                                        {parseFloat((d.sum_tasa_0_exportacion_anual_cfdis - d.sum_tasa_0_exportacion_anual_acuses)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                    </span>
                                                                                                    : ((d.sum_tasa_0_exportacion_anual_cfdis - d.sum_tasa_0_exportacion_anual_acuses) === 0 ? "-" :
                                                                                                        <span className='text-danger'>
                                                                                                            {parseFloat((d.sum_tasa_0_exportacion_anual_cfdis - d.sum_tasa_0_exportacion_anual_acuses)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                        </span>)
                                                                                                }
                                                                                            </td>
                                                                                            <td className='text-right'>
                                                                                                {(d.sum_tasa_0_otros_anual_cfdis - d.sum_tasa_0_otros_anual_acuses) < 0 ?
                                                                                                    <span className='text-success'>
                                                                                                        {parseFloat((d.sum_tasa_0_otros_anual_cfdis - d.sum_tasa_0_otros_anual_acuses)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                    </span>
                                                                                                    : ((d.sum_tasa_0_otros_anual_cfdis - d.sum_tasa_0_otros_anual_acuses) === 0 ? "-" :
                                                                                                        <span className='text-danger'>
                                                                                                            {parseFloat((d.sum_tasa_0_otros_anual_cfdis - d.sum_tasa_0_otros_anual_acuses)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                        </span>)
                                                                                                }
                                                                                            </td>
                                                                                            <td className='text-right'>
                                                                                                {(d.sum_tasa_8_anual_cfdis - d.sum_tasa_8_anual_acuses) < 0 ?
                                                                                                    <span className='text-success'>
                                                                                                        {parseFloat((d.sum_tasa_8_anual_cfdis - d.sum_tasa_8_anual_acuses)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                    </span>
                                                                                                    : ((d.sum_tasa_8_anual_cfdis - d.sum_tasa_8_anual_acuses) === 0 ? "-" :
                                                                                                        <span className='text-danger'>
                                                                                                            {parseFloat((d.sum_tasa_8_anual_cfdis - d.sum_tasa_8_anual_acuses)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                        </span>)
                                                                                                }
                                                                                            </td>
                                                                                            <td className='text-right'>
                                                                                                {(d.sum_suma_actividades_cfdis - d.sum_suma_actividades_acuses) < 0 ?
                                                                                                    <span className='text-success'>
                                                                                                        {parseFloat((d.sum_suma_actividades_cfdis - d.sum_suma_actividades_acuses)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                    </span>
                                                                                                    : ((d.sum_suma_actividades_cfdis - d.sum_suma_actividades_acuses) === 0 ? "-" :
                                                                                                        <span className='text-danger'>
                                                                                                            {parseFloat((d.sum_suma_actividades_cfdis - d.sum_suma_actividades_acuses)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                        </span>)
                                                                                                }
                                                                                            </td>
                                                                                            <td className='text-right'>
                                                                                                {(d.sum_valor_actos_actividades_exentos_cfdis - d.sum_valor_actos_actividades_exentos_acuses) < 0 ?
                                                                                                    <span className='text-success'>
                                                                                                        {parseFloat((d.sum_valor_actos_actividades_exentos_cfdis - d.sum_valor_actos_actividades_exentos_acuses)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                    </span>
                                                                                                    : ((d.sum_valor_actos_actividades_exentos_cfdis - d.sum_valor_actos_actividades_exentos_acuses) === 0 ? "-" :
                                                                                                        <span className='text-danger'>
                                                                                                            {parseFloat((d.sum_valor_actos_actividades_exentos_cfdis - d.sum_valor_actos_actividades_exentos_acuses)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                        </span>)
                                                                                                }
                                                                                            </td>
                                                                                            <td className='text-right'>
                                                                                                {(d.sum_tasa_16_iva_trasladado_cfdis - d.sum_tasa_16_iva_trasladado_acuses) < 0 ?
                                                                                                    <span className='text-success'>
                                                                                                        {parseFloat((d.sum_tasa_16_iva_trasladado_cfdis - d.sum_tasa_16_iva_trasladado_acuses)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                    </span>
                                                                                                    : ((d.sum_tasa_16_iva_trasladado_cfdis - d.sum_tasa_16_iva_trasladado_acuses) === 0 ? "-" :
                                                                                                        <span className='text-danger'>
                                                                                                            {parseFloat((d.sum_tasa_16_iva_trasladado_cfdis - d.sum_tasa_16_iva_trasladado_acuses)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                        </span>)
                                                                                                }
                                                                                            </td>
                                                                                            <td className='text-right'>
                                                                                                {(d.sum_tasa_8_iva_trasladado_cfdis - d.sum_tasa_8_iva_trasladado_acuses) < 0 ?
                                                                                                    <span className='text-success'>
                                                                                                        {parseFloat((d.sum_tasa_8_iva_trasladado_cfdis - d.sum_tasa_8_iva_trasladado_acuses)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                    </span>
                                                                                                    : ((d.sum_tasa_8_iva_trasladado_cfdis - d.sum_tasa_8_iva_trasladado_acuses) === 0 ? "-" :
                                                                                                        <span className='text-danger'>
                                                                                                            {parseFloat((d.sum_tasa_8_iva_trasladado_cfdis - d.sum_tasa_8_iva_trasladado_acuses)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                        </span>)
                                                                                                }
                                                                                            </td>
                                                                                            <td className='text-right'>
                                                                                                {((d.sum_tasa_16_iva_trasladado_cfdis + d.sum_tasa_8_iva_trasladado_cfdis) - (d.sum_tasa_16_iva_trasladado_acuses + d.sum_tasa_8_iva_trasladado_acuses)) < 0 ?
                                                                                                    <span className='text-success'>
                                                                                                        {parseFloat(((d.sum_tasa_16_iva_trasladado_cfdis + d.sum_tasa_8_iva_trasladado_cfdis) - (d.sum_tasa_16_iva_trasladado_acuses + d.sum_tasa_8_iva_trasladado_acuses))).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                    </span>
                                                                                                    : (((d.sum_tasa_16_iva_trasladado_cfdis + d.sum_tasa_8_iva_trasladado_cfdis) - (d.sum_tasa_16_iva_trasladado_acuses + d.sum_tasa_8_iva_trasladado_acuses)) === 0 ? "-" :
                                                                                                        <span className='text-danger'>
                                                                                                            {parseFloat(((d.sum_tasa_16_iva_trasladado_cfdis + d.sum_tasa_8_iva_trasladado_cfdis) - (d.sum_tasa_16_iva_trasladado_acuses + d.sum_tasa_8_iva_trasladado_acuses))).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                        </span>)
                                                                                                }
                                                                                            </td>
                                                                                        </tr>
                                                                                    </>
                                                                                ))
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                    <div style={{ height: 30 }}></div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            ) : null
                                        }

                                        {
                                            (this.state.viewmonths) ? (
                                                <>
                                                    {
                                                        this.state.skeletonmonth ? (
                                                            <div className="card">
                                                                <Skeleton />
                                                                <div style={{
                                                                    margin: 40,
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                }}>
                                                                    <PacmanLoader color={"#ff7043"} loading={this.state.skeletonmonth} size={25} margin={2} />
                                                                </div>
                                                                <Skeleton variant="circle" animation="wave" width={40} height={40} />
                                                                <Skeleton variant="rect" animation="wave" width={"100%"} height={118} />
                                                                <Skeleton animation="wave" />
                                                            </div>
                                                        ) : (
                                                            <div className="content" style={{ width: "100%" }}>
                                                                <div className='wrapper'>
                                                                    <div className='table' style={{ width: "2000px" }}>
                                                                        <table className='table'>
                                                                            <thead>
                                                                                <tr key={"198"}>
                                                                                    <th rowSpan={2} className='text-center' style={{ width: "200px" }}>Ejercicio</th>
                                                                                    <th colSpan={8} className='text-center'>VALOR DE ACTOS O ACTIVIDADES</th>
                                                                                    <th colSpan={2} className='text-center'>IVA TRASLADADO</th>
                                                                                </tr>
                                                                                <tr key={"9348"}>
                                                                                    <th></th>
                                                                                    <th style={{ width: "250px" }}>Tasa del 16%</th>
                                                                                    <th style={{ width: "200px" }}>No objeto del impuesto</th>
                                                                                    <th style={{ width: "200px" }}>Tasa exenta</th>
                                                                                    <th style={{ width: "200px" }}>Tasa 0% (Otros)</th>
                                                                                    <th style={{ width: "200px" }}>Tasa 8% (Zona fronteriza)</th>
                                                                                    <th style={{ width: "200px" }}>Suma de los actos o actividades gravados</th>
                                                                                    <th style={{ width: "200px" }}>Valor de los actos o actividades (Exentos)</th>
                                                                                    <th style={{ width: "200px" }}>A tasa del 16%</th>
                                                                                    <th style={{ width: "200px" }}>A tasa del 8%</th>
                                                                                    <th style={{ width: "200px" }}>Total IVA Trasladado  a cargo</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    (this.state.dataToYear || []).map((d, index) => (
                                                                                        <>
                                                                                            <tr key={index}>
                                                                                                <td rowSpan={3}>
                                                                                                    <Tooltip title="Clic para ver el origen de la determinación" placement="top" arrow>
                                                                                                        <button type="button" style={{ width: "100%" }}
                                                                                                            onClick={() => this.handleViewDetailsMonths(d)}
                                                                                                            className="btn btn-outline-primary btn-lg">
                                                                                                            <i className="icon-file-xml mr-2" style={{ float: 'left' }} />
                                                                                                            {d.title}
                                                                                                        </button>
                                                                                                    </Tooltip>
                                                                                                </td>
                                                                                                <td>Facturado</td>
                                                                                                <td className='text-right'>{(d.sum_tasa_16_anual_cfdis === 0 ? "-" : parseFloat(d.sum_tasa_16_anual_cfdis).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                                                                                <td className='text-right'>{(d.sum_no_objeto_impuest_cfdis === 0 ? "-" : parseFloat(d.sum_no_objeto_impuest_cfdis).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                                                                                <td className='text-right'>{(d.sum_tasa_0_exportacion_anual_cfdis === 0 ? "-" : parseFloat(d.sum_tasa_0_exportacion_anual_cfdis).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                                                                                <td className='text-right'>{(d.sum_tasa_0_otros_anual_cfdis === 0 ? "-" : parseFloat(d.sum_tasa_0_otros_anual_cfdis).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                                                                                <td className='text-right'>{(d.sum_tasa_8_anual_cfdis === 0 ? "-" : parseFloat(d.sum_tasa_8_anual_cfdis).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                                                                                <td className='text-right'>{(d.sum_suma_actividades_cfdis === 0 ? "-" : parseFloat(d.sum_suma_actividades_cfdis).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                                                                                <td className='text-right'>{(d.sum_valor_actos_actividades_exentos_cfdis === 0 ? "-" : parseFloat(d.sum_valor_actos_actividades_exentos_cfdis).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                                                                                <td className='text-right'>{(d.sum_tasa_16_iva_trasladado_cfdis === 0 ? "-" : parseFloat(d.sum_tasa_16_iva_trasladado_cfdis).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                                                                                <td className='text-right'>{(d.sum_tasa_8_iva_trasladado_cfdis === 0 ? "-" : parseFloat(d.sum_tasa_8_iva_trasladado_cfdis).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                                                                                <td className='text-right'>{((d.sum_tasa_16_iva_trasladado_cfdis + d.sum_tasa_8_iva_trasladado_cfdis) === 0 ? "-" : parseFloat((d.sum_tasa_16_iva_trasladado_cfdis + d.sum_tasa_8_iva_trasladado_cfdis)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                                                                            </tr>
                                                                                            <tr key={(index + 1)}>
                                                                                                <td>Declarado</td>
                                                                                                <td className='text-right'>
                                                                                                    <Tooltip title={(d.dir === "" ? "No existe acuse para mostrar" : "Clic para ver el acuse de la declaración")} placement="top" arrow>
                                                                                                        <button type="button" style={{ width: "100%" }}
                                                                                                            onClick={() => this.handleviewIvaTraslado(d)}
                                                                                                            className="btn btn-outline-primary btn-lg">
                                                                                                            <i className="icon-stack mr-2" style={{ float: 'left' }} />
                                                                                                            {parseFloat(d.sum_tasa_16_anual_acuses).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                        </button>
                                                                                                    </Tooltip>
                                                                                                </td>
                                                                                                <td className='text-right'>{(d.sum_no_objeto_impuesto_acuses === 0 ? "-" : parseFloat(d.sum_no_objeto_impuesto_acuses).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                                                                                <td className='text-right'>{(d.sum_tasa_0_exportacion_anual_acuses === 0 ? "-" : parseFloat(d.sum_tasa_0_exportacion_anual_acuses).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                                                                                <td className='text-right'>{(d.sum_tasa_0_otros_anual_acuses === 0 ? "-" : parseFloat(d.sum_tasa_0_otros_anual_acuses).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                                                                                <td className='text-right'>{(d.sum_tasa_8_anual_acuses === 0 ? "-" : parseFloat(d.sum_tasa_8_anual_acuses).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                                                                                <td className='text-right'>{(d.sum_suma_actividades_acuses === 0 ? "-" : parseFloat(d.sum_suma_actividades_acuses).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                                                                                <td className='text-right'>{(d.sum_valor_actos_actividades_exentos_acuses === 0 ? "-" : parseFloat(d.sum_valor_actos_actividades_exentos_acuses).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                                                                                <td className='text-right'>{(d.sum_tasa_16_iva_trasladado_acuses === 0 ? "-" : parseFloat(d.sum_tasa_16_iva_trasladado_acuses).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                                                                                <td className='text-right'>{(d.sum_tasa_8_iva_trasladado_acuses === 0 ? "-" : parseFloat(d.sum_tasa_8_iva_trasladado_acuses).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                                                                                <td className='text-right'>{((d.sum_tasa_16_iva_trasladado_acuses + d.sum_tasa_8_iva_trasladado_acuses) === 0 ? "-" : parseFloat((d.sum_tasa_16_iva_trasladado_acuses + d.sum_tasa_8_iva_trasladado_acuses)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</td>
                                                                                            </tr>
                                                                                            <tr key={(index + 2)}>
                                                                                                <td>Diferencia</td>
                                                                                                <td className='text-right'>
                                                                                                    {(d.sum_tasa_16_anual_cfdis - d.sum_tasa_16_anual_acuses) < 0 ?
                                                                                                        <span className='text-success'>
                                                                                                            {parseFloat((d.sum_tasa_16_anual_cfdis - d.sum_tasa_16_anual_acuses)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                        </span>
                                                                                                        : ((d.sum_tasa_16_anual_cfdis - d.sum_tasa_16_anual_acuses) === 0 ? "-" :
                                                                                                            <span className='text-danger'>
                                                                                                                {parseFloat((d.sum_tasa_16_anual_cfdis - d.sum_tasa_16_anual_acuses)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                            </span>)
                                                                                                    }
                                                                                                </td>
                                                                                                <td className='text-right'>
                                                                                                    {(d.sum_no_objeto_impuest_cfdis - d.sum_no_objeto_impuesto_acuses) < 0 ?
                                                                                                        <span className='text-success'>
                                                                                                            {parseFloat((d.sum_no_objeto_impuest_cfdis - d.sum_no_objeto_impuesto_acuses)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                        </span>
                                                                                                        : ((d.sum_no_objeto_impuest_cfdis - d.sum_no_objeto_impuesto_acuses) === 0 ? "-" :
                                                                                                            <span className='text-danger'>
                                                                                                                {parseFloat((d.sum_no_objeto_impuest_cfdis - d.sum_no_objeto_impuesto_acuses)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                            </span>)
                                                                                                    }
                                                                                                </td>
                                                                                                <td className='text-right'>
                                                                                                    {(d.sum_tasa_0_exportacion_anual_cfdis - d.sum_tasa_0_exportacion_anual_acuses) < 0 ?
                                                                                                        <span className='text-success'>
                                                                                                            {parseFloat((d.sum_tasa_0_exportacion_anual_cfdis - d.sum_tasa_0_exportacion_anual_acuses)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                        </span>
                                                                                                        : ((d.sum_tasa_0_exportacion_anual_cfdis - d.sum_tasa_0_exportacion_anual_acuses) === 0 ? "-" :
                                                                                                            <span className='text-danger'>
                                                                                                                {parseFloat((d.sum_tasa_0_exportacion_anual_cfdis - d.sum_tasa_0_exportacion_anual_acuses)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                            </span>)
                                                                                                    }
                                                                                                </td>
                                                                                                <td className='text-right'>
                                                                                                    {(d.sum_tasa_0_otros_anual_cfdis - d.sum_tasa_0_otros_anual_acuses) < 0 ?
                                                                                                        <span className='text-success'>
                                                                                                            {parseFloat((d.sum_tasa_0_otros_anual_cfdis - d.sum_tasa_0_otros_anual_acuses)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                        </span>
                                                                                                        : ((d.sum_tasa_0_otros_anual_cfdis - d.sum_tasa_0_otros_anual_acuses) === 0 ? "-" :
                                                                                                            <span className='text-danger'>
                                                                                                                {parseFloat((d.sum_tasa_0_otros_anual_cfdis - d.sum_tasa_0_otros_anual_acuses)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                            </span>)
                                                                                                    }
                                                                                                </td>
                                                                                                <td className='text-right'>
                                                                                                    {(d.sum_tasa_8_anual_cfdis - d.sum_tasa_8_anual_acuses) < 0 ?
                                                                                                        <span className='text-success'>
                                                                                                            {parseFloat((d.sum_tasa_8_anual_cfdis - d.sum_tasa_8_anual_acuses)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                        </span>
                                                                                                        : ((d.sum_tasa_8_anual_cfdis - d.sum_tasa_8_anual_acuses) === 0 ? "-" :
                                                                                                            <span className='text-danger'>
                                                                                                                {parseFloat((d.sum_tasa_8_anual_cfdis - d.sum_tasa_8_anual_acuses)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                            </span>)
                                                                                                    }
                                                                                                </td>
                                                                                                <td className='text-right'>
                                                                                                    {(d.sum_suma_actividades_cfdis - d.sum_suma_actividades_acuses) < 0 ?
                                                                                                        <span className='text-success'>
                                                                                                            {parseFloat((d.sum_suma_actividades_cfdis - d.sum_suma_actividades_acuses)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                        </span>
                                                                                                        : ((d.sum_suma_actividades_cfdis - d.sum_suma_actividades_acuses) === 0 ? "-" :
                                                                                                            <span className='text-danger'>
                                                                                                                {parseFloat((d.sum_suma_actividades_cfdis - d.sum_suma_actividades_acuses)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                            </span>)
                                                                                                    }
                                                                                                </td>
                                                                                                <td className='text-right'>
                                                                                                    {(d.sum_valor_actos_actividades_exentos_cfdis - d.sum_valor_actos_actividades_exentos_acuses) < 0 ?
                                                                                                        <span className='text-success'>
                                                                                                            {parseFloat((d.sum_valor_actos_actividades_exentos_cfdis - d.sum_valor_actos_actividades_exentos_acuses)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                        </span>
                                                                                                        : ((d.sum_valor_actos_actividades_exentos_cfdis - d.sum_valor_actos_actividades_exentos_acuses) === 0 ? "-" :
                                                                                                            <span className='text-danger'>
                                                                                                                {parseFloat((d.sum_valor_actos_actividades_exentos_cfdis - d.sum_valor_actos_actividades_exentos_acuses)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                            </span>)
                                                                                                    }
                                                                                                </td>
                                                                                                <td className='text-right'>
                                                                                                    {(d.sum_tasa_16_iva_trasladado_cfdis - d.sum_tasa_16_iva_trasladado_acuses) < 0 ?
                                                                                                        <span className='text-success'>
                                                                                                            {parseFloat((d.sum_tasa_16_iva_trasladado_cfdis - d.sum_tasa_16_iva_trasladado_acuses)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                        </span>
                                                                                                        : ((d.sum_tasa_16_iva_trasladado_cfdis - d.sum_tasa_16_iva_trasladado_acuses) === 0 ? "-" :
                                                                                                            <span className='text-danger'>
                                                                                                                {parseFloat((d.sum_tasa_16_iva_trasladado_cfdis - d.sum_tasa_16_iva_trasladado_acuses)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                            </span>)
                                                                                                    }
                                                                                                </td>
                                                                                                <td className='text-right'>
                                                                                                    {(d.sum_tasa_8_iva_trasladado_cfdis - d.sum_tasa_8_iva_trasladado_acuses) < 0 ?
                                                                                                        <span className='text-success'>
                                                                                                            {parseFloat((d.sum_tasa_8_iva_trasladado_cfdis - d.sum_tasa_8_iva_trasladado_acuses)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                        </span>
                                                                                                        : ((d.sum_tasa_8_iva_trasladado_cfdis - d.sum_tasa_8_iva_trasladado_acuses) === 0 ? "-" :
                                                                                                            <span className='text-danger'>
                                                                                                                {parseFloat((d.sum_tasa_8_iva_trasladado_cfdis - d.sum_tasa_8_iva_trasladado_acuses)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                            </span>)
                                                                                                    }
                                                                                                </td>
                                                                                                <td className='text-right'>
                                                                                                    {
                                                                                                        (((d.sum_tasa_16_iva_trasladado_cfdis + d.sum_tasa_8_iva_trasladado_cfdis) - (d.sum_tasa_16_iva_trasladado_acuses + d.sum_tasa_8_iva_trasladado_acuses)) === 0 ? "-" :
                                                                                                            (((d.sum_tasa_16_iva_trasladado_cfdis + d.sum_tasa_8_iva_trasladado_cfdis) - (d.sum_tasa_16_iva_trasladado_acuses + d.sum_tasa_8_iva_trasladado_acuses)) < 0 ?
                                                                                                                (
                                                                                                                    <span className='text-success'>
                                                                                                                        {parseFloat((d.sum_tasa_16_iva_trasladado_cfdis + d.sum_tasa_8_iva_trasladado_cfdis) - (d.sum_tasa_16_iva_trasladado_acuses + d.sum_tasa_8_iva_trasladado_acuses)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                                    </span>
                                                                                                                ) : <span className='text-danger'>
                                                                                                                    {parseFloat((d.sum_tasa_16_iva_trasladado_cfdis + d.sum_tasa_8_iva_trasladado_cfdis) - (d.sum_tasa_16_iva_trasladado_acuses + d.sum_tasa_8_iva_trasladado_acuses)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                                </span>)
                                                                                                        )}
                                                                                                </td>
                                                                                            </tr>
                                                                                        </>
                                                                                    ))
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                        <div style={{ height: 30 }}></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            ) : (this.state.skeletonmonth ? (
                                                <Loading />
                                            ) : null)
                                        }
                                        {
                                            this.state.viewmonthsdetails ? (
                                                <>{this.state.view_details_month} </>

                                            ) : (this.state.display_loading_ === 1 ? (
                                                <Loading />
                                            ) : null)}
                                        {
                                            this.state.view_detail_cfdis_ ? (
                                                <>{this.state.subview}</>
                                            ) : (this.state.display_loading_ === 2 ? (
                                                <Loading />
                                            ) : null)
                                        }
                                        {
                                            this.state.level === 3 ? (
                                                <>{this.state.viewTypeReport}</>
                                            ) : (this.state.display_loading_ === 3 ? (
                                                <Loading />
                                            ) : null)
                                        }
                                        <div id="_modal_app_" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                                            <div className="modal-dialog modal-full">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title">Declaración</h5>
                                                        <button type="button" className="close" data-dismiss="modal">×</button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <div className='embed-container'>
                                                            <iframe src={this.state.link_acuse} title='Ver el documento!!' width={"100%"} frameBorder="0"></iframe>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/**
                         * View reporte
                         */}
                        <Dialog fullScreen open={this.state.open_modal} onClose={() => {
                            this.setState({
                                open_modal: false,
                                view_iva_trasladado: ''
                            })
                        }}>
                            <AppBar style={{ position: 'relative' }}>
                                <Toolbar style={{ justifyContent: 'space-between' }}>
                                    <IconButton edge="start" color="inherit" aria-label="open drawer">
                                    </IconButton>
                                    <Typography variant="h1">
                                        <h5 style={{ color: 'white' }}>
                                            Cédula analítica del iva trasladado de {this.state.year}
                                        </h5>
                                    </Typography>
                                    <IconButton edge="end" color="inherit" onClick={() => {
                                        this.setState({
                                            open_modal: false,
                                            view_iva_trasladado: ''
                                        })
                                    }} aria-label="close">
                                        <i className="icon-cross" style={{ fontSize: 20 }}></i>
                                    </IconButton>
                                </Toolbar>
                            </AppBar>
                            <Box sx={{}}>
                                {this.state.view_iva_trasladado}
                            </Box>
                        </Dialog>
                        <div style={{ height: 30 }}></div>
                    </div>
                </div>
            </div >
        )
    }
}
export default IndexTraslado;
function Loading() {
    return (
        <div className="card">
            <Skeleton />
            <div style={{
                margin: 40,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <PacmanLoader color={"#ff7043"} loading={true} size={25} margin={2} />
            </div>
            <Skeleton variant="circle" animation="wave" width={40} height={40} />
            <Skeleton variant="rect" animation="wave" width={"100%"} height={118} />
            <Skeleton animation="wave" />
        </div>
    )
}