import React, { Component } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import api from '../../Config/api';
class AppFail extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "fecha",
                text: "Fecha",
                className: "fecha",
                align: "left",
                sortable: true,
            },
            {
                key: "rfc",
                text: "RFC",
                className: "rfc",
                align: "left",
                sortable: true
            }, {
                key: "nombre",
                text: "Razón social",
                className: "nombre",
                align: "left",
                sortable: true
            }, {
                key: "estatus",
                text: "Estatus",
                className: "estatus",
                align: "left",
                sortable: true
            },
            {
                key: "total",
                text: "Total",
                className: "total",
                cell: record => {
                    return (
                        <div className="text-right">
                            <p className="text-success" style={{ fontSize: 14 }}>{(record.total) === 0 ? '-' : (record.total).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                        </div>
                    );
                }
            },]
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,
            button: {
                excel: true,
                print: true,
                // extra: true,
            },
            language: {
                length_menu: "Mostrar _MENU_ registros por página",
                filter: "Buscar en registros ...",
                info: "Mostrando _START_ a _END_ de _TOTAL_ entradas",
                pagination: {
                    first: "Primero",
                    previous: "Previa",
                    next: "próximo",
                    last: "Última"
                }
            },
            no_data_text: "No se encontraron registros"
        }
        this.state = {
            data: props.data
        }

    }
    componentDidMount() {

    }
    render() {
        return (
            <ReactDatatable
                config={this.config}
                records={this.state.data}
                columns={this.columns}
            />
        );
    }
}

export default AppFail;