import React, { useState } from "react";
import { Snackbar, CircularProgress, Tooltip } from "@material-ui/core";
import { withRouter, Link } from "react-router-dom";
import MuiAlert from '@material-ui/lab/Alert';
import { useUserDispatch, loginUser } from "../../context/UserContext";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
var ejercicio = (new Date()).getFullYear();
function Login(props) {
  // global
  var userDispatch = useUserDispatch();
  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  const [loginValue, setLoginValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");

  var [notifycation, setNotifycate] = useState('');

  const [state, setNotifycateStatus] = useState({
    statusmesagge: false,
    vertical: 'top',
    horizontal: 'right',
  });
  const { vertical, horizontal, statusmesagge } = state;
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotifycateStatus({ statusmesagge: false, vertical: 'top', horizontal: 'right', });
  };
  return (
    <>
      {/*<div className="navbar navbar-expand-md navbar-light navbar-static" >
        <div className="navbar-brand">
          <Link to='/' className="d-inline-block">
            <img src="logos/logo_expertumb.png" alt="" />
          </Link>
        </div>

        <div className="d-md-none">
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-mobile">
            <i className="icon-tree5"></i>
          </button>
        </div>
      </div>*/}
      <div className="navbar navbar-expand-lg navbar-light navbar-static">
        <div className="navbar-brand ml-2 ml-lg-0">
          <a href="index.html" className="d-inline-block">
            <img src="logos/logo_expertumb.png" alt="" />
          </a>
        </div>
        <div className="d-flex justify-content-end align-items-center ml-auto">
          {/*<ul className="navbar-nav flex-row">
            <li className="nav-item">
              <a href="#" className="navbar-nav-link">
                <i className="icon-lifebuoy" />
                <span className="d-none d-lg-inline-block ml-2">Support</span>
              </a>
            </li>
            <li className="nav-item">
              <a href="#" className="navbar-nav-link">
                <i className="icon-user-plus" />
                <span className="d-none d-lg-inline-block ml-2">Registro</span>
              </a>
            </li>
            <li className="nav-item">
              <a href="#" className="navbar-nav-link">
                <i className="icon-user-lock" />
                <span className="d-none d-lg-inline-block ml-2">Login</span>
              </a>
            </li>
          </ul>*/}
        </div>
      </div>
      <div className="page-content" style={{ backgroundColor: "#262d3c" }}>
        <div className="content-wrapper">
          <div className="content d-flex justify-content-center align-items-center">
            <form className="login-form" >
              <div className="card mb-0">
                <div className="card-body">
                  <div className="text-center mb-3">
                    <i className="icon-reading icon-2x text-slate-300 border-slate-300 border-3 rounded-round p-3 mb-3 mt-1"></i>
                    <h5 className="mb-0">Ingrese a su cuenta</h5>
                    <span className="d-block text-muted"></span>
                  </div>

                  <Snackbar open={statusmesagge} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal}>
                    <Alert onClose={handleClose} severity="error">
                      {notifycation}
                    </Alert>
                  </Snackbar>
                  <div className="form-group form-group-feedback form-group-feedback-left">
                    <input type="text" className="form-control" placeholder="Nombre de usuario" onChange={e => setLoginValue(e.target.value)} />
                    <div className="form-control-feedback">
                      <i className="icon-user text-muted"></i>
                    </div>
                  </div>

                  <div className="form-group form-group-feedback form-group-feedback-left">
                    <input type="password" className="form-control" placeholder="Contraseña" onChange={e => setPasswordValue(e.target.value)} />
                    <div className="form-control-feedback">
                      <i className="icon-lock2 text-muted"></i>
                    </div>
                  </div>

                  <div className="form-group">
                    <div style={{
                      width: "100%",
                      marginTop: 4,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}>
                      {isLoading ? (<CircularProgress size={26} style={{ marginLeft: 4, }} style={{ color: 'white' }} />) : (<button
                        disabled={loginValue.length === 0 || passwordValue.length === 0}
                        onClick={() => loginUser(userDispatch, loginValue, passwordValue, props.history, setIsLoading, setError, setNotifycate, setNotifycateStatus)}
                        className="btn btn-primary btn-block"
                      >Iniciar <i className="icon-circle-right2 ml-2"></i></button>
                      )}
                    </div>
                  </div>
                  <div className="text-center">
                    <Link to="/recupere">Recuperar contraseña</Link>
                  </div>
                </div>
              </div>
            </form>
          </div>

        </div>
      </div>
      <div className="navbar navbar-expand-lg navbar-light">
        <div className="text-center d-lg-none w-100">
          <button type="button" className="navbar-toggler dropdown-toggle" data-toggle="collapse" data-target="#navbar-footer">
            <i className="icon-unfold mr-2"></i>
            Pie de página
          </button>
        </div>
        <div className="navbar-collapse collapse" id="navbar-footer">
          <span className="navbar-text"> <span style={{ fontSize: '15px' }}> &copy;</span> {ejercicio} Mercanetica estrategias S.A. de C.V.</span>
          <ul className="navbar-nav ml-lg-auto">
            <li className="nav-item"><a href="https://e-xpertum.com.mx/webadmin/read-android" className="navbar-nav-link"><Tooltip arrow placement="top" title="Click en el icono para descargar el APP para Android"><span style={{ color: 'green' }}><i style={{ fontSize: '20px' }} className="icon-android"></i></span></Tooltip> </a></li>
            <li className="nav-item"><a href="https://e-xpertum.com.mx/webadmin/read-ios" className="navbar-nav-link"><Tooltip arrow placement="top" title="Click en el icono para descargar el APP para IOS i-Phn"><span className="text-info-400"><i style={{ fontSize: '20px' }} className="icon-apple2"></i></span></Tooltip></a></li>
            <li className="nav-item"><a href="https://e-xpertum.com.mx/webadmin/api/documents/api/help/Manual_E_xpertum_INICIO_V1.pdf" className="navbar-nav-link" target="_blank"><span className="text-warning-400"><i className="icon-question6"></i>  Manual</span></a></li>
            <li className="nav-item"><a href="https://e-xpertum.com.mx/adminfile/preguntas-frecuentes" className="navbar-nav-link" target="_blank"><span className="text-warning-400"><i className="icon-lifebuoy mr-2"></i> Preguntas frecuentes</span></a></li>
            <li className="nav-item"><a href="https://e-xpertum.com.mx/adminfile/terminosycondiciones" className="navbar-nav-link" target="_blank"><span className="text-warning-400"><i className="icon-file-text2 mr-2"></i> Términos y condiciones</span></a></li>
            <li className="nav-item"><a href="https://e-xpertum.com.mx/adminfile/avisoprivacidad" className="navbar-nav-link" target="_blank"><span className="text-warning-400"><i className="icon-clipboard3 mr-2"></i> Aviso de privacidad</span></a></li>
            <li className="nav-item"><a href="https://e-xpertum.com/" className="navbar-nav-link" target="_blank"><span className="text-warning-400"><i className="icon-cart2 mr-2"></i> Comprar</span></a></li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default withRouter(Login);
