import React, { Component } from 'react';
class Exts extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        console.log(props)
    }
    render() {
        if (this.props.ext === ".xls" || this.props.ext === ".ods" || this.props.ext === ".xlsx" || this.props.ext === ".xlsx#" || this.props.ext === ".xls#")
            return (
                <>
                    <i className="icon-file-excel text-success-400 icon-2x"></i>
                </>
            )
        else if (this.props.ext === ".docx" || this.props.ext === ".docx#" || this.props.ext === ".doc")
            return (
                <i className="icon-file-word text-blue-400 icon-2x"></i>
            )
        else if (this.props.ext === ".pdf")
            return (
                <i className="icon-file-pdf text-danger-400 icon-2x"></i>
            )
        else if (this.props.ext === ".zip")
            return (
                <i className="icon-file-zip text-success-400 icon-2x"></i>
            )
        else if (this.props.ext === ".xml")
            return (
                <i className="icon-file-xml text-success-400 icon-2x"></i>
            )
        else if (this.props.ext === ".sql")
            return (
                <i className="icon-database text-blue-400 icon-2x"></i>
            )
        else if (this.props.ext === ".rar")
            return (
                <i className="icon-file-zip text-blue-400 icon-2x"></i>
            )
        else if (this.props.ext === ".txt")
            return (
                <i className="icon-file-text2 text-blue-400 icon-2x"></i>
            )
        else if (this.props.ext === ".cer" || this.props.ext === ".pfx" || this.props.ext === ".key")
            return (
                <i className="icon-file-empty2 text-blue-400 icon-2x"></i>
            )
        else if (this.props.ext === ".json")
            return (
                <i className="icon-file-css text-warning-400 icon-2x"></i>
            )
        else if (this.props.ext === ".png" || this.props.ext === ".jpg")
            return (
                <i className="icon-images3 text-blue-400 icon-2x"></i>
            )
        else
            return (
                <i className="icon-folder text-blue-400 icon-2x"></i>
            )
    }
}

export default Exts;