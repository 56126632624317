import React, { Component } from "react";
import {
    Button, TableFooter, TablePagination, Table, DialogContent,
    DialogActions, Dialog, TableRow, TableHead, DialogContentText, DialogTitle, TableBody,
    TableCell, Tooltip, Backdrop, CircularProgress,
} from "@material-ui/core";
import * as Icons from "@material-ui/icons";
import api from '../Config/api';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Details from './crud/details';
import Edit from './crud/edit';
import NewApp from './crud/new';
const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));
class SeriviciosAll extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modulos: [], page: 0,
            rowsPerPage: 8,
            edit: false,
            editar: false,
            detail: false,
            delete: false,
            loading: true
        }
        this.handleConfirmacionDelete = this.handleConfirmacionDelete.bind(this);
        this.closeModalDialog = this.closeModalDialog.bind(this);
    }
    componentDidMount() {
        api.get('listModulo')
            .then(response => {
                this.setState({
                    modulos: response.data,
                    loading: false,
                    editar: false,
                    detail: false,
                    delete: false
                });
            })
            .catch(function (error) {
                console.log(error);
            })
    }
    /****************
  * Paginación
 */
    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
        this.setState({ page: 0 });
    };
    /**********************
 * end paginación
 * */
    funcionEditar(id) {
        this.setState({ id: id.id, nombre: id.descripcion });
        setTimeout(() => {
            this.setState({
                editar: true,
                detail: false,
                delete: false
            });
        }, 500);

    }
    funcionEliminar(id) {
        this.setState({
            id: id.id, nombre: id.descripcion, editar: false, detail: false, delete: true
        });
    }
    funcionDetails(id) {
        this.setState({ id: id.id, nombre: id.descripcion, editar: false, detail: true, delete: false });
    }
    closeModalDialog() {
        if (this.state.editar)
            this.componentDidMount();

        this.setState({ nombre: '', editar: false, detail: false, delete: false });
    }
    handleConfirmacionDelete() {
        var producto = {
            id: this.state.id
        }
        api.post('DeleteModulo', producto)
            .then((result) => {
                this.componentDidMount();
            },
                (error) => {
                    this.setState({ error });
                }
            );
    }
    render() {
        return (<>
            <Backdrop style={{ color: "#fff", background: "#B9B6B6", zIndex: 400, opacity: "0.5" }} open={this.state.loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="page-header border-bottom-0">
                <div className="page-header-content header-elements-md-inline">
                    <div className="page-title d-flex">
                        <a href="#" style={{ color: 'white' }}>  <h4><i className="icon-arrow-left52 mr-2"></i> <span className="font-weight-semibold">E-xpertum</span> </h4></a>
                        <a href="#" className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></a>
                    </div>

                </div>
            </div>
            <div className="content pt-0">
                <div className="card">
                    <div className="card-header header-elements-inline">
                        <h5 className="card-title">Catálogo de Servicios</h5>

                    </div>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Descripción</th>
                                <th>Servicios</th>
                                <th className="text-center"><NewApp></NewApp></th>
                            </tr>
                        </thead>
                        <tbody>
                            {(this.state.rowsPerPage > 0 ? this.state.modulos.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                : this.state.modulos).map(({ id, sistema, descripcion }) => (
                                    <tr key={id}>
                                        <td className="pl-3 fw-normal">{descripcion}</td>
                                        <td>{sistema}</td>
                                        <td align="center">
                                            <Tooltip title="Modificar" placement="top"><Icons.Edit style={{ color: "orange", cursor: "pointer" }} onClick={() => this.funcionEditar({ id, descripcion })} /></Tooltip>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <Tooltip title="Detalle" placement="top"><Icons.List style={{ color: "green", cursor: "pointer" }} onClick={() => this.funcionDetails({ id, descripcion })} /></Tooltip>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <Tooltip title="Eliminar" placement="top"><Icons.DeleteOutline style={{ color: "red", cursor: "pointer" }} onClick={() => this.funcionEliminar({ id, descripcion })} /></Tooltip>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <TablePagination
                                    rowsPerPageOptions={[8, 10, 25, { label: 'Todo', value: -1 }]}
                                    colSpan={3}
                                    count={(this.state.modulos).length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'Filas por página' },
                                        native: true,
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions} />
                            </tr>
                        </tfoot>
                    </table>

                    <Dialog fullWidth={true} open={this.state.editar} onClose={this.handleCloseDetail} aria-labelledby="max-width-dialog-title" >
                        <DialogTitle id="max-width-dialog-title" style={{ textAlign: "center" }}>Modificar {this.state.nombre}</DialogTitle>
                        <DialogContent>
                            <Edit inf={{ id: this.state.id, descripcion: this.state.nombre }} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.closeModalDialog} color="primary"> Cerrar</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog fullWidth={true} open={this.state.detail} onClose={this.handleCloseDetail} aria-labelledby="max-width-dialog-title" >
                        <DialogTitle id="max-width-dialog-title" style={{ textAlign: "center" }}>Detalle {this.state.nombre}</DialogTitle>
                        <DialogContent>
                            <Details inf={{ id: this.state.id, descripcion: this.state.nombre }} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.closeModalDialog} color="primary"> Cerrar</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={this.state.delete} onClose={this.handleConfirmacionCancelar} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                        <DialogTitle id="alert-dialog-title">{"Confirmación de eliminación"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Está seguro de eliminar el siguiente registro {this.state.nombre}?
                    </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <button onClick={this.closeModalDialog} className="btn btn-info">Cancelar</button>
                            <button onClick={this.handleConfirmacionDelete} className="btn btn-primary" autoFocus>Aceptar</button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </>);
    }
}

export default SeriviciosAll;

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page" >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};