import React, { Component } from "react";
class TableRazonSocial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            result: props.data
        }
    }
    render() {
        return (
            <>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12">
                            <label className="col-form-label">Razón Social /  Nombre: {this.state.result.razon_social}</label>
                        </div>
                        <div className="col-md-6">
                            <label className="col-form-label">RFC: {this.state.result.rfc}</label>
                        </div>
                        <div className="col-md-6">
                            <label className="col-form-label">Colonia: {this.state.result.colonia}</label>
                        </div>
                        <div className="col-md-6">
                            <label className="col-form-label">Calle: {this.state.result.calle}</label>
                        </div>
                        <div className="col-md-6">
                            <label className="col-form-label">Código Postal: {this.state.result.codigo_postal}</label>
                        </div>
                        <div className="col-md-6">
                            <label className="col-form-label">Número Exterior: {this.state.result.numero_exterior}</label>
                        </div>
                        <div className="col-md-6">
                            <label className="col-form-label">Número Interior:{this.state.result.numero_interior}</label>
                        </div>
                        <div className="col-md-6">
                            <label className="col-form-label">País: {this.state.result.pais}</label>
                        </div>
                        <div className="col-md-6">
                            <label className="col-form-label">Estado: {this.state.result.estado}</label>
                        </div>
                        <div className="col-md-6">
                            <label className="col-form-label">Municipio: {this.state.result.municipio}</label>
                        </div>
                        <div className="col-md-6">
                            <label className="col-form-label">Localidad: {this.state.result.localidad}</label>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default TableRazonSocial;

