import { Tooltip } from "@material-ui/core";

function getDataRfc(params) {
    let dataRfcName_ = JSON.parse(params);
    return dataRfcName_.rfc;
}
function getDataName(params) {
    let dataRfcName_ = JSON.parse(params);
    return dataRfcName_.nombre;
}
function validatetipos_relaciones(key) {
    switch (key) {
        case "01":
            return "Nota de crédito de los documentos relacionados";
            break;
        case "02":
            return "Nota de débito de los documentos relacionados"
            break;
        case "03":
            return "Devolución de mercancía sobre facturas o traslados previos"
            break;
        case "04":
            return "Sustitución de los CFDI previos"
            break;
        case "05":
            return "Traslados de mercancías facturados previamente"
            break;
        case "06":
            return "Factura generada por los traslados previos"
            break;
        case "07":
            return "CFDI por aplicación de anticipo"
            break;
        default:
            break;
    }
}
export { getDataRfc, getDataName, validatetipos_relaciones }