import React, { Component } from 'react';
import RecibidosAPP from './details/recibido';
import EmitidosApp from './details/emitido';
import DeclaracionContribuyentes from './details/declaracionanual';
import SueldosSalarios from './details/sueldosysalarios';
import IMSSF from './details/imss';
class ListaReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewdeclaracionanual: '',
            viewemitidos: '',
            viewrecibidos: '',
            viewsueldossalarios: '',
            viewimss: ''
        }
    }
    componentDidMount() {
        this.setState({ viewdeclaracionanual: <DeclaracionContribuyentes /> });
    }
    onchangehandleEmitido = () => {
        this.setState({ viewemitidos: <EmitidosApp /> });
    }
    onchangehandle = () => {
        this.setState({ viewrecibidos: <RecibidosAPP /> });
    }
    onchangehandleSueldosYSalarios = () => {
        this.setState({ viewsueldossalarios: <SueldosSalarios /> });
    }
    onchangehandleImss = () => {
        this.setState({ viewimss: <IMSSF /> });
    }
    render() {
        return (
            <>
                <div className="content pt-0">
                    <div className="card">
                        <div className="card-body">
                            <ul className="nav nav-tabs nav-tabs-solid nav-tabs-solid-custom bg-secondary nav-justified">
                                <li className="nav-item"><a href="#colored-justified-tab0" className="nav-link active" data-toggle="tab">Declaraciones anuales</a></li>
                                <li className="nav-item"><a href="#colored-justified-tab1" onClick={this.onchangehandleEmitido} className="nav-link" data-toggle="tab">Comparativo de ingresos</a></li>
                                <li className="nav-item"><a href="#colored-justified-tab2" onClick={this.onchangehandle} className="nav-link" data-toggle="tab">Rentenciones de IVA</a></li>
                                <li className="nav-item"><a href="#colored-justified-tab3" onClick={this.onchangehandleSueldosYSalarios} className="nav-link" data-toggle="tab">Sueldos y salarios</a></li>
                                <li className="nav-item"><a href="#colored-justified-tab4" onClick={this.onchangehandleImss} className="nav-link" data-toggle="tab">IMSS</a></li>
                            </ul>
                            <div className="tab-content">
                                <div className="tab-pane fade active show" id="colored-justified-tab0">
                                    {this.state.viewdeclaracionanual}
                                </div>
                                <div className="tab-pane fade" id="colored-justified-tab1">
                                    {this.state.viewemitidos}
                                </div>
                                <div className="tab-pane fade" id="colored-justified-tab2">
                                    {this.state.viewrecibidos}
                                </div>
                                <div className="tab-pane fade" id="colored-justified-tab3">
                                    {this.state.viewsueldossalarios}
                                </div>
                                <div className="tab-pane fade" id="colored-justified-tab4">
                                    {this.state.viewimss}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ListaReport;