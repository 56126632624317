import React, { Component } from 'react';
import api from '../../../app/Config/apifiles';
import ReactDatatable from '@ashvin27/react-datatable';
import Skeleton from '@material-ui/lab/Skeleton';
import { Tooltip, Switch } from "@material-ui/core";
class ViewVendors extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "nombre",
                text: "Nombre, Denominación o razón social",
                className: "nombre",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <div className="text-letf" key={['name' + record.nombre]}>
                            {(record.nombre !== "") ?
                                record.nombre.substr(0, 60) : ""}
                        </div>
                    );
                }
            },
            {
                key: "rfc",
                text: "RFC",
                className: "rfc",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <div className="text-letf" key={['rfc-' + record.rfc]}>
                            {(record.rfc !== "") ? record.rfc : ""}
                        </div>
                    );
                }
            }, {
                key: "correo",
                text: "Correo",
                className: "correo",
                sortable: true,
                cell: record => {
                    return (
                        <div className="text-right" key={['div-' + record.rfc]}>
                            {
                                (record.estatus == null || record.estatus == "VIGENTE") ?
                                    (
                                        <input key={['input-' + record.rfc]} value={(record.correo == undefined || record.correo == null) ? '' : record.correo} className="form-control" onChange={(event, checked) => this.handleSetMails(record.rfc, event, checked)} onBlur={(event, checked) => this.handleOnblurInput(record.rfc, event, checked)} />
                                    ) :
                                    (
                                        <input key={['input-' + record.rfc]} value={(record.correo == undefined || record.correo == null) ? '' : record.correo} disabled className="form-control" onChange={(event, checked) => this.handleSetMails(record.rfc, event, checked)} onBlur={(event, checked) => this.handleOnblurInput(record.rfc, event, checked)} />
                                    )
                            }

                        </div>
                    );
                }
            }, {
                key: "estatus",
                text: "Estatus",
                className: "estatus",
                sortable: true,
                cell: i => {
                    return (
                        <div key={['estatus-' + i.rfc]} className="text-center" style={{ textAlign: 'center' }}>
                            {
                                (i.estatus == null || i.estatus == "VIGENTE") ? (
                                    <Switch key={['switch-' + i.rfc]}
                                        onChange={(event, checked) => this.handleChange(i.rfc, event, checked)}
                                        defaultChecked
                                        color="primary"
                                    />
                                ) :
                                    (
                                        <Switch key={['switch-' + i.rfc]}
                                            onChange={(event, checked) => this.handleChange(i.rfc, event, checked)}
                                            color="primary"
                                        />
                                    )
                            }

                        </div>
                    );
                }
            },
        ];
        this.config = {
            filename: "report_cat_proveedores",
            page_size: 50,
            length_menu: [70, 90, 100],
            show_filter: true,
            show_pagination: true,
            button: {
                excel: false,
                print: false,
                extra: true,
            },
            language: {
                length_menu: "Mostrar _MENU_ registros por página",
                filter: "Buscar en registros ...",
                info: "Mostrando _START_ a _END_ de _TOTAL_ entradas",
                pagination: {
                    first: "Primero",
                    previous: "Previa",
                    next: "próximo",
                    last: "Última"
                }
            },
            key_column: "rfc"
        }
        this.extraButtons = [
            {
                className: "btn btn-primary buttons-pdf",
                title: "Layout",
                children: [
                    <span>
                        <Tooltip title="Descargar Layout de proveedores" arrow placement="top">
                            <i className="icon-file-download" aria-hidden="true"></i>
                        </Tooltip>
                    </span>
                ],
                onClick: (event) => {
                    console.log(event);
                    window.open("https://e-xpertum.com.mx/adminfile/api/get-list-vendors-all-config/" + this.state.empresa_id + "/zpsoud29d3ssd33kj82f3ijd/23iu983287isdj9832ij30s038", "_blank")
                },
            },
            {
                className: "btn btn-primary buttons-pdf",
                title: "Import layout",
                children: [
                    <span>
                        <Tooltip title="Importar Layout" arrow placement="top">
                            <i className="icon-file-upload" aria-hidden="true"></i>
                        </Tooltip>
                    </span>
                ],
                onClick: (event) => {
                    console.log(event);
                    window.$("#modal_iconified").modal("show");
                }
            },
        ]
        let data_ = JSON.parse(localStorage.getItem("datavalidate"));
        this.state = {
            empresa_id: data_.id,
            rfc: data_.rfc,
            data: [],
            nombre: '',
            skeleton: true,
            title: 'Seleccione su archivo'
        }
    }
    componentDidMount() {
        this.getlistVendors();
    }
    getlistVendors = async () => {
        let _data_ = await api.post('/get-list-vendors-all-config', { empresa_id: this.state.empresa_id })
        this.setState({ data: _data_.data, skeleton: false })
    }

    handleChange = async (index, event, checked) => {
        if (!checked) {
            let xxxx = await api.post('status-list-vendors-config', { empresa_id: this.state.empresa_id, rfc: index, estatus: 'CANCELADO' });
        }
        else {
            let xxxx = await api.post('status-list-vendors-config', { empresa_id: this.state.empresa_id, rfc: index, estatus: 'VIGENTE' });
        }
        this.getlistVendors();
    }
    handleOnblurInput = async (d, event, t) => {
        if (event.target.value.length > 0) {
            let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!regEmail.test(event.target.value)) {
                this.setState({ messageError: 'El correo es invalido' });
            }
            else {
                let xxxx = await api.post('mail-list-vendors-config', { empresa_id: this.state.empresa_id, rfc: d, correo: event.target.value })
            }
        } else { let xxxx = await api.post('mail-list-vendors-config', { empresa_id: this.state.empresa_id, rfc: d, correo: event.target.value }) }
    }
    handleSetMails = async (d, event, t) => {
        let apk = this.state.data;
        for (let zp = 0; zp < apk.length; zp++) {
            if (apk[zp].rfc == d)
                apk[zp].correo = event.target.value;
        }
        this.setState({ data: apk });
    }
    onFileChange = async (event) => {
        let files = event.target.files[0];
        this.setState({ title: files.name, skeleton: true })
        setTimeout(async () => {
            var data = new FormData();
            data.append('file', files);
            data.append('empresa_id', this.state.empresa_id);
            data.append('rfc', this.state.rfc);
            var config = {
                method: 'post',
                url: 'upload-layout-vendors',
                data: data
            };
            api(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    ///setTimeout(async() => {
                    window.$("#modal_iconified").modal("hide");

                    ///}, 3000);

                })
                .catch(function (error) {
                    console.log(error);
                });
            this.getlistVendors();
        }, 2300);


    };
    render() {
        return (
            <div className="content pt-0">
                <div className="mb-3">
                    <h6 className="mb-0 font-weight-semibold">
                        Configuración de los proveedores
                    </h6>
                    <span className="text-muted d-block">Descarga he importa a los proveedores con las cuentas de correo</span>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        {
                            this.state.skeleton ? (
                                <div className="card">
                                    <Skeleton animation="wave" variant="text" height={40} />
                                    <Skeleton animation="wave" variant="circle" width={80} height={80} />
                                    <Skeleton animation="wave" variant="text" height={40} />
                                    <div className="row">
                                        <div className="col-lg-4"><Skeleton variant="rect" height={150} /></div>
                                        <div className="col-lg-4"><Skeleton variant="rect" height={150} /></div>
                                        <div className="col-lg-4"><Skeleton variant="rect" height={150} /></div>
                                    </div>
                                    <div style={{ height: 20 }}></div>
                                    <div className="row">
                                        <div className="col-lg-4"><Skeleton variant="rect" height={150} /></div>
                                        <div className="col-lg-4"><Skeleton variant="rect" height={150} /></div>
                                    </div>
                                </div>
                            ) : (
                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.data}
                                    columns={this.columns}
                                    extraButtons={this.extraButtons}
                                />
                            )
                        }

                        <div id="modal_iconified" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title"><i className="icon-menu7 mr-2" /> &nbsp;Importar layout</h5>
                                        <button type="button" className="close" data-dismiss="modal">×</button>
                                    </div>
                                    <div className="modal-body">
                                        {/*<div className="alert alert-info alert-dismissible alert-styled-left border-top-0 border-bottom-0 border-right-0">
                                            <span className="font-weight-semibold">Here we go!</span> Example of an alert inside modal.
                                            <button type="button" className="close" data-dismiss="alert">×</button>
                                        </div>*/}
                                        <h6 className="font-weight-semibold"><i className="icon-law mr-2" />Importe el layout con los datos de los proveedores</h6>
                                        <fieldset className="mb-3">
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-1"></label>
                                                <div className="col-lg-10">
                                                    <div className="custom-file">
                                                        <input type="file" className="custom-file-input" id="customFile" accept=".xlsx" onChange={this.onFileChange} />
                                                        <label className="custom-file-label" htmlFor="customFile">{this.state.title}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ViewVendors;