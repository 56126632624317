import React, { Component } from 'react';
import DataImport from './data_info_.json';
import Tooltip from '@material-ui/core/Tooltip';
import './style.css';
class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstSeccion: [],
            secondSeccion: [],
            trheeSeccion: []
        };
    }
    componentDidMount() {
        this.setState({
            firstSeccion: DataImport.array_first,
            secondSeccion: DataImport.array_sencond,
            trheeSeccion: DataImport.array_trhee,
        })
    }
    handleChangetitleurl = (y) => {
        this.setState(y)
    }
    render() {
        return (
            <>
                <div className="content-wrapper">
                    <div className="page-header border-bottom-0">
                        <div className="page-header-content header-elements-md-inline">
                            <div className="page-title d-flex">
                                <h4><i className="icon-list3 mr-2"></i> <span className="font-weight-semibold">Documentos a recabar para integrar el expediente fiscal</span></h4>
                            </div>
                            <div className="header-elements d-none mb-3 mb-md-0">
                                <div className="d-flex justify-content-center">
                                    <Tooltip title={
                                        <>
                                            <div className="container">
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <th>Configuración del sistema</th>
                                                        </tr>
                                                        <tr>
                                                            <td>Paso 1.- Clic en el menu lateral izquierdo, sobre el icono <i class="icon-point-right" style={{ color: 'white' }}></i> para seleccionar el servicio adquirido.</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>
                                    } placement="top" arrow><a className="btn btn-link btn-float text-default" href="https://e-xpertum.com.mx/webadmin/api/documents/api/help/Manual_E_xpertum_Audit_d_l_Conta_V1.pdf" target="_blank"><i className="icon-help"></i> <span>Manual</span></a></Tooltip>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content pt-0">
                        <div className="card-body">
                            <p>
                                <span style={{ fontFamily: "Tahoma, sans-serif", fontSize: "11pt", "color": "#800000" }}>
                                    <font size="3" color="white" style={{ fontSize: "11.000000pt" }}>Función:</font>
                                </span>
                            </p>
                            <ul style={{ listStyle: "decimal" }}>
                                <li>El Sistema e-xpertum también sirve como almacenaje de la información que se importó de su empresa</li>
                                <li>La información se encontrará disponible 24/7 para consulta del usuario.</li>
                                <li>El Sistema le indicará si el Check List de documentación que usted importó están completos, de lo contrario le señalará los documentos faltantes. El Sistema solo mostrará si los documentos están completos, pero NO indicará si la información es correcta.</li>
                            </ul>
                            <p style={{ "color": "white", fontSize: "11pt", fontFamily: "Tahoma, sans-serif" }}>Operación</p>

                            <p>El usuario ingresará los documentos necesarios para configurar la empresa y para operarla. Es requisito indispensable que dichos documentos se encuentren en el formato solicitado</p>
                            <p>(documentos no protegidos, no scanner y no fotos) debido a que el <span style={{ fontStyle: "italic" }}><i>Sistema</i></span> lee y analiza la información del documento importado.</p>
                        </div>
                        <div className="d-md-flex align-items-md-start">
                            <div className="flex-fill">
                                <h6 style={{ textAlign: 'center' }}> Para la configuración del Sistema (Configuración Fiscal Inicial) </h6>
                                <p>Para realizar la Configuración Fiscal Inicial en el Sistema, es necesario contar con los siguientes documentos correspondientes a la empresa a dar de alta.</p>
                                {
                                    (this.state.firstSeccion || []).map((i, j) => (
                                        <div className="card card-body" key={j}>
                                            <div className="media flex-column flex-sm-row">
                                                <div className="mr-sm-3 mb-2 mb-sm-0">
                                                    <Tooltip title="Click en la imagen para redimencionar la vista." placement="top" arrow>
                                                        <a href="#course_preview" data-toggle="modal" onClick={() => this.handleChangetitleurl(i)}>
                                                            <img src={i.url} className="rounded" width="44" height="44" alt="" />
                                                        </a>
                                                    </Tooltip>
                                                </div>

                                                <div className="media-body">
                                                    <h6 className="media-title font-weight-semibold">
                                                        <a style={{ color: 'white' }} target="_black">{i.title}</a>
                                                    </h6>
                                                    <ul className="list-inline list-inline-dotted text-muted mb-2">
                                                        <li className="list-inline-item"><a href="#" className="text-muted">e-xpertum</a></li>
                                                        <li className="list-inline-item">Datos fiscales</li>
                                                    </ul>
                                                    <p>{i.description}</p>
                                                    <p>{i.descriptionthow}</p>
                                                    <p>{i.descriptiontthree}</p>
                                                    <p> {i.note !== "" ? (<><strong>Nota:</strong> {i.note}</>) : null}</p>

                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                                <h6 style={{ textAlign: 'center' }}>Para la operación del Sistema (Documentos fiscales necesarios a importar de manera mensual)</h6>
                                <p>Una vez generada la Configuración Fiscal Inicial, el Sistema permitirá realizar la importación de manera mensual de los documentos correspondientes al cumplimiento de sus obligaciones, respecto a los ejercicios fiscales previamente seleccionados en el momento de la compra.</p>
                                {
                                    (this.state.secondSeccion || []).map((i, j) => (
                                        <div className="card card-body" key={j}>
                                            <div className="media flex-column flex-sm-row">
                                                <div className="mr-sm-3 mb-2 mb-sm-0">
                                                    <Tooltip title="Click en la imagen para redimencionar la vista." placement="top" arrow>
                                                        <a href="#course_preview" data-toggle="modal" onClick={() => this.handleChangetitleurl(i)}>
                                                            <img src={i.url} className="rounded" width="44" height="44" alt="" />
                                                        </a>
                                                    </Tooltip>
                                                </div>

                                                <div className="media-body">
                                                    <h6 className="media-title font-weight-semibold">
                                                        <a style={{ color: 'white' }} target="_black">{i.title}</a>
                                                    </h6>
                                                    <ul className="list-inline list-inline-dotted text-muted mb-2">
                                                        <li className="list-inline-item"><a href="#" className="text-muted">e-xpertum</a></li>
                                                        <li className="list-inline-item">Datos fiscales</li>
                                                    </ul>
                                                    <p>{i.description}</p>
                                                    <p>{i.descriptionthow}</p>
                                                    <p>{i.descriptiontthree}</p>
                                                    <p>{i.descriptionfour}</p>
                                                    <p> {i.note !== "" ? (<><strong>Nota:</strong> {i.note}</>) : null}</p>

                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                                <h6 style={{ textAlign: 'center' }}>Contribuciones federales, declaraciones informativas, contribuciones estatales.</h6>
                                <p></p>
                                {
                                    (this.state.trheeSeccion || []).map((i, j) => (
                                        <div className="card card-body" key={j}>
                                            <div className="media flex-column flex-sm-row">
                                                <div className="mr-sm-3 mb-2 mb-sm-0">
                                                    <Tooltip title="Click en la imagen para redimencionar la vista." placement="top" arrow>
                                                        <a href="#course_preview" data-toggle="modal" onClick={() => this.handleChangetitleurl(i)}>
                                                            <img src={i.url} className="rounded" width="44" height="44" alt="" />
                                                        </a>
                                                    </Tooltip>
                                                </div>

                                                <div className="media-body">
                                                    <h6 className="media-title font-weight-semibold">
                                                        <a target="_black" style={{ color: 'white' }}>{i.title}</a>
                                                    </h6>
                                                    <ul className="list-inline list-inline-dotted text-muted mb-2">
                                                        <li className="list-inline-item"><a className="text-muted">e-xpertum</a></li>
                                                        <li className="list-inline-item">Datos fiscales</li>
                                                    </ul>
                                                    <p>{i.description}</p>
                                                    <p>{i.descriptionthow}</p>
                                                    <p>{i.descriptiontthree}</p>
                                                    <p> {i.note !== "" ? (<><strong>Nota:</strong> {i.note}</>) : null}</p>

                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="course_preview" tabIndex="-1" style={{ display: "none" }} aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h6 className="modal-title">{this.state.title}</h6>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>

                            <div className="modal-body">
                                <div className="embed-responsive embed-responsive-16by9">
                                    <iframe className="embed-responsive-item" src={this.state.url} frameBorder="0" allowFullScreen=""></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default index;