import React, { Component } from 'react';
import DateDynamic from './calendar-date';
import apiFile from '../../Config/apifiles';
class AudiFiscal extends Component {
    constructor(props) {
        super(props);
        let fecha = new Date();
        let config = JSON.parse(localStorage.getItem("panelconfig"));
        this.state = {
            id: config.empresa_id,
            data: [],
            empresa_id: config.empres_id,
            heand: {},
            cancelado_anterior: {},
            cfdi_cancelado_mes: {},
            cfdi_faltante: {},
            sustituido: {},
            trasladado: {},
            ejercicio: fecha.getFullYear(),
            periodo: ((fecha.getMonth() + 1) < 10 ? ('0' + (fecha.getMonth() + 1)) : (fecha.getMonth() + 1)),
            cfdis_: []
        }
    }
    componentDidMount() {

        this.getDataConfig({
            id: this.state.id,
            empresa_id: this.state.empresa_id,
            ejercicio: this.state.ejercicio,
            periodo: this.state.periodo
        });
        //console.log(config)
    }
    getDataConfig = async (g) => {
        let _data_ = await apiFile.post("report-cfdi-admin-tablero", g);
        this.setState(_data_.data);
    }
    hendleSelectData = async (t) => {
        let configdate = t.split('-')
        this.getDataConfig({
            id: this.state.id,
            empresa_id: this.state.empresa_id,
            ejercicio: configdate[1],
            periodo: configdate[0]
        });
        this.setState({
            ejercicio: configdate[1],
            periodo: configdate[0]
        })
    }
    handleModalView = async (T) => {
        window.$("#modalfullapp").modal("show");
        let tps = {
            ejercicio: this.state.ejercicio,
            periodo: this.state.periodo,
            empresa_id: this.state.empresa_id,
            type: T
        }
        let _data_ = await apiFile.post("report-filter-emitido-recibido", tps);

        this.setState({ cfdis_: _data_.data });
    }
    render() {
        return (
            <div className="content-wrapper">
                {/* <div className="page-header border-bottom-0">
                    <div className="page-header-content header-elements-md-inline">
                        <div className="page-title d-flex">
                            <a href="#/app/ui/listempresas" style={{ color: 'white' }}><h4><i className="icon-arrow-left52 mr-2"></i> <span className="font-weight-semibold">Listado de empresas</span></h4></a>
                        </div>
                    </div>
                </div>*/}
                <div className="content pt-0">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="form-group">
                                <label>Seleccione la fecha: </label>
                                <DateDynamic setDate={this.hendleSelectData} />
                            </div>
                        </div>
                        <div className="col-lg-3">
                        </div>
                        <div className="col-lg-4">
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header bg-transparent border-bottom">
                                    <h6 className="card-title"> <i className="icon-cog3 mr-2"></i> Tablero de control de CFDIs a contabilizar</h6>
                                </div>
                                <div className="card-body">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Emitido</th>
                                                <th>Recibido</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{this.state.heand.descripcion}</td>
                                                <td style={{ textAlign: 'right' }}>
                                                    {this.state.heand.total_emitidos > 0 ?
                                                        (<div onClick={() => this.handleModalView('EMITIDO')}><i className="icon-hash text-success"></i>&nbsp;{this.state.heand.total_emitidos}</div>) :
                                                        (<><i className="icon-hash"></i>&nbsp;{this.state.heand.total_emitidos}</>)
                                                    }
                                                </td>
                                                <td style={{ textAlign: 'right' }}>
                                                    {this.state.heand.total_recibido > 0 ?
                                                        (<div onClick={() => this.handleModalView('RECIBIDO')}><i className="icon-hash text-success"></i>&nbsp;{this.state.heand.total_recibido}</div>) :
                                                        (<><i className="icon-hash"></i>&nbsp;{this.state.heand.total_recibido}</>)
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{this.state.cfdi_faltante.descripcion}</td>
                                                <td style={{ textAlign: 'right' }}>
                                                    {this.state.cfdi_faltante.total_emitidos > 0 ?
                                                        (<><i className="icon-hash text-success"></i>&nbsp;{this.state.cfdi_faltante.total_emitidos}</>) :
                                                        (<><i className="icon-hash"></i>&nbsp;{this.state.cfdi_faltante.total_emitidos}</>)
                                                    }
                                                </td>
                                                <td style={{ textAlign: 'right' }}>
                                                    {this.state.cfdi_faltante.total_emitidos > 0 ?
                                                        (<><i className="icon-hash text-success"></i>&nbsp;{this.state.cfdi_faltante.total_recibido}</>) :
                                                        (<><i className="icon-hash"></i>&nbsp;{this.state.cfdi_faltante.total_recibido}</>)
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{this.state.cfdi_cancelado_mes.descripcion}</td>
                                                <td style={{ textAlign: 'right' }}>
                                                    {this.state.cfdi_cancelado_mes.total_emitidos_cancelado !== "" ? (
                                                        <><i className="icon-hash text-success"></i>&nbsp;{this.state.cfdi_cancelado_mes.total_emitidos_cancelado}</>
                                                    ) : (
                                                        <><i className="icon-hash"></i>&nbsp;{this.state.cfdi_cancelado_mes.total_emitidos_cancelado}</>
                                                    )}
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    {this.state.cfdi_cancelado_mes.import_emitido !== '' ? (
                                                        <><i className="icon-coin-dollar text-success"></i>&nbsp;{this.state.cfdi_cancelado_mes.import_emitido}</>
                                                    ) : (
                                                        <><i className="icon-coin-dollar"></i>&nbsp;{this.state.cfdi_cancelado_mes.import_emitido}</>
                                                    )}

                                                </td>
                                                <td style={{ textAlign: 'right' }}>
                                                    {this.state.cfdi_cancelado_mes.total_recibido_cancelado !== "" ? (
                                                        <><i className="icon-hash text-success"></i>&nbsp;{this.state.cfdi_cancelado_mes.total_recibido_cancelado}</>
                                                    ) : (
                                                        <><i className="icon-hash"></i>&nbsp;{this.state.cfdi_cancelado_mes.total_recibido_cancelado}</>
                                                    )}
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    {this.state.cfdi_cancelado_mes.import_recibido !== '' ? (
                                                        <><i className="icon-coin-dollar text-success"></i>&nbsp;{this.state.cfdi_cancelado_mes.import_recibido}</>
                                                    ) : (
                                                        <><i className="icon-coin-dollar"></i>&nbsp;{this.state.cfdi_cancelado_mes.import_recibido}</>
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{this.state.cancelado_anterior.descripcion}</td>
                                                <td style={{ textAlign: 'right' }}><i className="icon-hash"></i>&nbsp;{this.state.cancelado_anterior.total_emitidos_cancelado} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i className="icon-coin-dollar"></i>&nbsp;{this.state.cancelado_anterior.import_emitido}</td>
                                                <td style={{ textAlign: 'right' }}><i className="icon-hash"></i>&nbsp;{this.state.cancelado_anterior.total_recibido_cancelado} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i className="icon-coin-dollar"></i>&nbsp;{this.state.cancelado_anterior.import_recibido}</td>
                                            </tr>
                                            <tr>
                                                <td>{this.state.sustituido.descripcion}</td>
                                                <td style={{ textAlign: 'right' }}>
                                                    {
                                                        (this.state.sustituido.total_emitidos > 0 ? (
                                                            <><i className="icon-hash text-success"></i>&nbsp;{this.state.sustituido.total_emitidos}</>
                                                        ) : (
                                                            <><i className="icon-hash"></i>&nbsp;{this.state.sustituido.total_emitidos}</>
                                                        ))
                                                    }
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    {
                                                        (this.state.sustituido.import_emitido !== '0.00' ? (
                                                            <><i className="icon-coin-dollar text-success"></i>&nbsp;{this.state.sustituido.import_emitido}</>
                                                        ) : (<><i className="icon-coin-dollar"></i>&nbsp;{this.state.sustituido.import_emitido}</>))
                                                    }
                                                </td>
                                                <td style={{ textAlign: 'right' }}>
                                                    {
                                                        (this.state.sustituido.total_recibido > 0 ? (
                                                            <><i className="icon-hash text-success"></i>&nbsp;{this.state.sustituido.total_recibido}</>
                                                        ) : (
                                                            <><i className="icon-hash"></i>&nbsp;{this.state.sustituido.total_recibido}</>
                                                        ))
                                                    }
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    {
                                                        (this.state.sustituido.import_recibido !== '0.00' ? (
                                                            <><i className="icon-coin-dollar text-success"></i>&nbsp;{this.state.sustituido.import_recibido}</>
                                                        ) : (<><i className="icon-coin-dollar"></i>&nbsp;{this.state.sustituido.import_recibido}</>))
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{this.state.trasladado.descripcion}</td>
                                                <td style={{ textAlign: 'right' }}>
                                                    {
                                                        (this.state.trasladado.total_emitidos > 0 ? (
                                                            <><i className="icon-hash text-success"></i>&nbsp;{this.state.trasladado.total_emitidos}</>
                                                        ) : (
                                                            <><i className="icon-hash"></i>&nbsp;{this.state.trasladado.total_emitidos}</>
                                                        ))
                                                    }
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    {
                                                        (this.state.trasladado.import_emitido !== '0.00' ? (
                                                            <><i className="icon-coin-dollar text-success"></i>&nbsp;{this.state.trasladado.import_emitido}</>
                                                        ) : (<><i className="icon-coin-dollar"></i>&nbsp;{this.state.trasladado.import_emitido}</>))
                                                    }
                                                </td>
                                                <td style={{ textAlign: 'right' }}>
                                                    {
                                                        (this.state.trasladado.total_recibido > 0 ? (
                                                            <><i className="icon-hash text-success"></i>&nbsp;{this.state.trasladado.total_recibido}</>
                                                        ) : (
                                                            <><i className="icon-hash"></i>&nbsp;{this.state.trasladado.total_recibido}</>
                                                        ))
                                                    }
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    {
                                                        (this.state.trasladado.import_recibido !== '0.00' ? (
                                                            <><i className="icon-coin-dollar text-success"></i>&nbsp;{this.state.trasladado.import_recibido}</>
                                                        ) : (<><i className="icon-coin-dollar"></i>&nbsp;{this.state.trasladado.import_recibido}</>))
                                                    }
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div id="modalfullapp" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                            <div className="modal-dialog modal-full">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Full width modal</h5>
                                        <button type="button" className="close" data-dismiss="modal">×</button>
                                    </div>
                                    <div className="modal-body">

                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>UUID</th>
                                                        <th>Rfc</th>
                                                        <th>Nombre</th>
                                                        <th>Fecha</th>
                                                        <th>monto</th>
                                                        <th>Tipo comprobante</th>
                                                        <th>Estatus</th>
                                                        <th>Fecha cancelación</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        (this.state.cfdis_ || []).map((r, t) => (
                                                            <tr key={t}>
                                                                <td>{r.uuid}</td>
                                                                <td>{r.rfc}</td>
                                                                <td>{r.nombre}</td>
                                                                <td>{r.fecha_emision}</td>
                                                                <td>{r.monto}</td>
                                                                <td>{r.efecto_comprobante}</td>
                                                                <td>{r.status}</td>
                                                                <td>{r.fecha_cancelacion}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AudiFiscal;