import React, { useEffect, useState } from "react";
import { Box } from '@material-ui/core';
import {
    BarChart,
    Bar, ReferenceLine,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    LabelList
} from "recharts";
import fastapi from "../../Config/fast_api";

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                {
                    payload[0].payload.type === "PERDIDA" ? (
                        <div className="card card-body bg-danger text-white">
                            <div className="d-flex align-items-center mb-3">
                                <div className="flex-fill">
                                    <h6 className="mb-0">{label}</h6>
                                    <span className="opacity-75" style={{ fontSize: '0.9rem' }}>Fecha de presentación: {String(payload[0].payload.date_presentation).slice(0, 10)}</span>
                                    <br></br>
                                    <span className="opacity-75" style={{ fontSize: '0.9rem' }}>Tipo de declaración: {String(payload[0].payload.declaration_type)}</span>
                                    <br></br>
                                    <span className="opacity-75" style={{ fontSize: '0.9rem' }}>Número de operacion: {String(payload[0].payload.operation_number)}</span>
                                </div>
                                <i className="ph-activity ph-2x ms-3" />
                            </div>
                            <div className="progress bg-danger mb-2" style={{ height: '0.125rem' }}>
                                <div className="progress-bar bg-white" style={{ width: (parseFloat(payload[0].payload.tasa_isr) + '%') }} />
                            </div>
                            <div style={{ width: '100%' }}>
                                <Box display="flex" p={1} width={"100%"}>
                                    <Box p={1} flexGrow={1}>
                                        Tasa efectiva ISR
                                    </Box>
                                    <Box p={1}>
                                        <span className="float-end">{(parseFloat(payload[0].payload.tasa_isr).toFixed(2) + '%')}</span>
                                    </Box>
                                </Box>
                                <Box display="flex" p={1} width={"100%"}>
                                    <Box p={1} flexGrow={1}>
                                        Tasa SAT
                                    </Box>
                                    <Box p={1}>
                                        <span className="float-end">{(parseFloat(payload[0].payload.tasa_sat_).toFixed(2) + '%')}</span>
                                    </Box>
                                </Box>
                            </div>
                        </div>
                    ) : (
                        <div className="card card-body bg-success text-white">
                            <div className="d-flex align-items-center mb-3">
                                <i className="ph-gear ph-2x me-3" />
                                <div className="flex-fill">
                                    <h6 className="mb-0">{label}</h6>
                                    <span className="opacity-75" style={{ fontSize: '0.9rem' }}>Fecha de presentación: {String(payload[0].payload.date_presentation).slice(0, 10)}</span>
                                    <br></br>
                                    <span className="opacity-75" style={{ fontSize: '0.9rem' }}>Tipo de declaración: {String(payload[0].payload.declaration_type)}</span>
                                    <br></br>
                                    <span className="opacity-75" style={{ fontSize: '0.9rem' }}>Número de operacion: {String(payload[0].payload.operation_number)}</span>
                                </div>
                            </div>
                            <div className="progress bg-success mb-2" style={{ height: '0.125rem' }}>
                                <div className="progress-bar bg-white" style={{ width: (parseFloat(payload[0].payload.tasa_isr) + '%') }} />
                            </div>
                            <div style={{ width: '100%' }}>
                                <Box display="flex" p={1} width={"100%"}>
                                    <Box p={1} flexGrow={1}>
                                        Tasa efectiva ISR
                                    </Box>
                                    <Box p={1}>
                                        <span className="float-end">{(parseFloat(payload[0].payload.tasa_isr).toFixed(2) + '%')}</span>
                                    </Box>
                                </Box>
                                <Box display="flex" p={1} width={"100%"}>
                                    <Box p={1} flexGrow={1}>
                                        Tasa SAT
                                    </Box>
                                    <Box p={1}>
                                        <span className="float-end">{(parseFloat(payload[0].payload.tasa_sat_).toFixed(2) + '%')}</span>
                                    </Box>
                                </Box>
                            </div>
                        </div>
                    )
                }

            </div>
        );
    }

    return null;
};
const CustomizedAxisTickY = (props) => {
    const { x, y, payload } = props;

    return (
        <g transform={`translate(${x},${y})`}>
            <text
                x={0}
                y={0}
                dy={16}
                textAnchor="end"
                fontSize={15}
                fill="#fff"
                transform="rotate(-20)"
            >
                {parseFloat(payload.value).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
            </text>
        </g>
    );
};
const CustomizedAxisTick = (props) => {
    const { x, y, payload } = props;

    return (
        <g transform={`translate(${x},${y})`}>
            <text
                x={0}
                y={0}
                dy={16}
                textAnchor="end"
                fontSize={12}
                fill="#fff"
                transform="rotate(-35)"
            >
                {(payload.value)}
            </text>
        </g>
    );
};
export default function IsrAppBarChart() {
    const [width, setWidth] = useState();
    const [height, setHeight] = useState();
    const [data, setData] = useState([])
    useEffect(() => {
        //setData(data_);
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
        geteffectiveisrrates();
    }, [])
    const geteffectiveisrrates = async () => {
        let objects = JSON.parse(localStorage.getItem("datacompanyall"));
        const FormData = require('form-data');
        let data = new FormData();
        data.append('id', objects.id);
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://exentus.net/api/comparison-effective-isr-rates',
            data: data
        };

        fastapi.request(config)
            .then((response) => {
                setData(response.data);
            })
            .catch((error) => {
                //console.log(error);
            });
    }
    return (
        <div className="card-group-control card-group-control-left" id="accordion-control">
            <div className="card">
                <div className="card-header">
                    <h6 className="card-title">
                        <a data-toggle="collapse" className="text-body collapsed" href="#accordion-control-group1" aria-expanded="false">Gráfica comparativa de ISR</a>
                    </h6>
                </div>
                <div id="accordion-control-group1" className="collapse show" data-parent="#accordion-control" style={{}}>
                    <div className="card-body">

                        <div className="row">

                            <div className="col-lg-12">
                                <div className="card border-bottom-success rounded-bottom-0">
                                    <div className="card-header">
                                        <h6 className="card-title">Tasa efectiva de ISR</h6>
                                    </div>
                                    <div className="card-body">
                                        <BarChart
                                            width={(width) - 320}
                                            height={(height / 1.5)}
                                            data={data}
                                            margin={{
                                                top: 5,
                                                right: 30,
                                                left: 20,
                                                bottom: 5
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="1 1" vertical={false} />
                                            <XAxis dataKey="year" stroke="#fff" tick={<CustomizedAxisTick />} />
                                            <YAxis stroke="#fff" tick={<CustomizedAxisTickY />} />
                                            <ReferenceLine y={0} stroke="#fff" />
                                            <Tooltip content={<CustomTooltip />} />
                                            <Legend />
                                            <Bar dataKey="tasa_isr" name="Tasa del ISR" fill="#82ca9d" />
                                            <Bar dataKey="tasa_sat_" name="Tasa SAT" fill="#8884d8" />
                                        </BarChart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header">
                    <h6 className="card-title">
                        <a className="collapsed text-body" data-toggle="collapse" href="#accordion-control-group2"><h6 className="card-title">Comparación cuantitativa de tasas efectivas de ISR</h6></a>
                    </h6>
                </div>
                <div id="accordion-control-group2" className="collapse" data-parent="#accordion-control">
                    <div className="card-body">
                        <div className="content pt-0">
                            <div className="card">

                                <div className="card-body">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Ingresos acumulables</th>
                                                <th>ISR causado</th>
                                                <th>Tasa ISR efectiva</th>
                                                <th>Tasa ISR mínima SAT</th>
                                                <th>Diferencia</th>
                                                <th>Riesgo tributario</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(data || []).map((x, index) => (
                                                <tr key={index}>
                                                    <td>{x.year}</td>
                                                    <td className="text-right">{parseFloat(x.total_cumulative_income).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                    <td className="text-right">{parseFloat(x.tax_incurred_year).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                    <td className="text-right">{parseFloat(x.tasa_isr).toFixed(2)}</td>
                                                    <td className="text-right">{parseFloat(x.tasa_sat_).toFixed(2)}</td>
                                                    <td className="text-right">{parseFloat(x.tasa_isr - x.tasa_sat_).toFixed(2)}</td>
                                                    <td>{validandoPorcentaje(x)}</td>
                                                </tr>
                                            ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    );
}
function validandoPorcentaje(g) {
    let _result_ = parseFloat(g.tasa_isr - g.tasa_sat_)
    if (_result_ < (g.tasa_sat_ - 3))
        return (
            <span className="badge bg-danger" style={{ width: "100%" }}>Muy alto</span>
        )
    else if (_result_ < (g.tasa_sat_ - 4) && _result_ < ((g.tasa_sat_ - 3)))
        return (
            <span className="badge" style={{ width: "100%", backgroundColor: "#f7736b" }}>Alto</span>
        )
    else if (_result_ < 0 && _result_ < ((g.tasa_sat_ - 1)))
        return (
            <span className="badge" style={{ width: "100%", backgroundColor: "#fd9187" }}>Medio</span>
        )
    else if (_result_ > 0 && _result_ < ((g.tasa_sat_ - 1)))
        return (
            <span className="badge" style={{ width: "100%", backgroundColor: "#9cd29a" }}>Moderado</span>

        )
    else if (_result_ > (g.tasa_sat_ + 1) && _result_ > g.tasa_isr)
        return (
            <span className="badge" style={{ width: "100%", backgroundColor: "#82c782" }}>Bajo</span>
        )
    else if (_result_ < g.tasa_isr)
        return (
            <span className="badge bg-success bg-opacity-10" style={{ width: "100%" }}>Muy bajo</span>
        )
    else
        return (
            <span className="badge bg-success bg-opacity-10" style={{ width: "100%" }}>Medio</span>
        )

}
/*
      RIESGO TRIBUTARIO (textos en colores)	 	 
              Puntos de % comparado con la tasa objetivo del SAT
      Muy alto	Si E < 3% menor	 	 
      Alto	Si E < 1% y hasta 3% menor	 	 
      Medio	Si E <  cero y hasta 1% menor	 
      Moderado	Si E es entre cero y < 1% que la tasa del SAT
      Bajo	Si E es mayor de 1% y hasta 2% mayor que la tasa del SAT
      Muy bajo	Si E es más de 2% mayor que la tasa del SAT

*/