import React, { Component } from 'react';
import { Tooltip, } from "@material-ui/core";
function diaSemana(x) {
    let date = new Date((x.substr(0, 10)).replace(/-+/g, '/'));
    let options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };
    return date.toLocaleDateString('es-MX', options);
}
class NotasV extends Component {
    constructor(props) {
        super(props);
        let user = JSON.parse(localStorage.getItem("id_token"));
        this.state = {
            list: [],
            nombre_corto: user.nombre,
            nombre_: user.nombre + " " + user.apellido_paterno + " " + user.apellido_materno,
        }
    }
    componentDidMount() {
        this.setState({ list: this.props.data })
    }
    handledelete = (j) => {
        this.props.delete(j)
    }
    render() {
        return (
            <>
                {
                    (this.state.list || []).map((i, j) => (
                        (
                            <div className="card" key={j}>
                                <div className="card-header">
                                    <h5 className="card-title font-weight-semibold">
                                        <a className="text-info"><i className="icon-pencil7"></i>&nbsp;&nbsp; Nota </a>
                                        <Tooltip title="Eliminar la nota" placement="top" arrow onClick={() => this.handledelete(i)}><a style={{ float: 'right', cursor: 'pointer' }}><i className="icon-cross3"></i></a></Tooltip>
                                    </h5>
                                </div>
                                <div className="card-body">
                                    <p className="mb-3">{i.comentario}</p>
                                    <blockquote className="blockquote blockquote-bordered py-2 pl-3 mb-0">
                                        <div className="mb-2 font-size-base">{this.state.nombre_}  <span>dejó una nota</span> </div>
                                        <footer className="blockquote-footer">hora <cite title="Source Title">{i.fecha.substr(11, 5)} </cite></footer>
                                    </blockquote>
                                </div>
                                <div className="card-footer bg-transparent d-sm-flex justify-content-sm-between align-items-sm-center border-top-0 pt-0 pb-3">
                                    <ul className="list-inline list-inline-dotted text-muted mb-3 mb-sm-0">
                                        <li className="list-inline-item">Creado por  <a href="#" className="text-muted">{this.state.nombre_corto} </a></li>
                                        <li className="list-inline-item">{diaSemana(i.fecha)}</li>

                                    </ul>
                                    <a className="text-muted"> <img src="https://e-xpertum.com.mx/icon.ico" width={13}></img></a>
                                </div>
                            </div>
                        )
                    ))
                }
            </>
        );
    }
}

export default NotasV;
