import React, { Component } from 'react';
import { Backdrop, CircularProgress } from "@material-ui/core";
import api from '../Config/api';
import { withRouter } from 'react-router-dom';
///Loagin
class Panel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            opcion: [],
            loading: true
        }
    }

    componentDidMount() {
        api.get('listModuloClients')
            .then(response => {
                this.setState({
                    opcion: response.data,
                    loading: false
                });
            }).catch(function (error) {
                console.log(error);
            });
    }
    handlerGetidService = (id) => {
        localStorage.removeItem("panelProduct")
        localStorage.setItem("panelProduct", JSON.stringify(id));
        this.props.history.push(id.linweb);
    }
    render() {
        return (
            <>
                <Backdrop style={{ color: "#fff", background: "#B9B6B6", zIndex: 400, opacity: "0.5" }} open={this.state.loading} >
                    <CircularProgress color="inherit" />
                </Backdrop>

                <div className="content-wrapper">
                    <div className="page-header border-bottom-0">
                        <div className="page-header-content header-elements-md-inline">
                            <div className="page-title d-flex">
                                <h4><i className="icon-home mr-2"></i> <span className="font-weight-semibold">Panel</span>  de servicios</h4>
                                <a href="#" className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></a>
                            </div>
                        </div>
                    </div>
                    <div className="content pt-0">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header bg-transparent border-bottom header-elements-inline">
                                        <h6 className="card-title">Panel</h6>
                                        <div className="header-elements">
                                            <div className="list-icons">
                                                <div className="dropdown d-inline-flex position-static">
                                                    <a href="#" className="list-icons-item dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="icon-menu7" /></a>
                                                    <div className="dropdown-menu dropdown-menu-right" style={{}}>
                                                        <div className="dropdown-divider" />
                                                        <a href="#/app/panel/config-user-company" className="dropdown-item">Agrupación de usuarios - empresas</a>
                                                        <a href="#" className="dropdown-item">Link</a>
                                                        <a href="#" className="dropdown-item">Link</a>
                                                        <div className="dropdown-divider" />
                                                        <a href="#" className="dropdown-item">Link</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            {(this.state.opcion || []).map((object, i) =>
                                                <div className="col-sm-3 col-lg-3" key={i}>
                                                    <div className="card">
                                                        <div className="card-body text-center">
                                                            <i className="icon-book icon-2x text-success-300 border-success-300 border-3 rounded-round p-3 mb-3 mt-1"></i>
                                                            <h5 className="card-title">{object.descripcion}</h5>
                                                            <p className="mb-3">Número de clientes: {object.num_clientes} </p>
                                                            <a style={{ cursor: 'pointer' }} onClick={() => this.handlerGetidService(object)} className="btn bg-success-400">Ir al listado de "{object.descripcion}"</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

}

export default withRouter(Panel);