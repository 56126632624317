import React from "react";

export default function Error() {
  const style = {

  }
  return (
    <div>
      <div className="navbar navbar-expand-lg navbar-light navbar-static">
        <div className="d-flex flex-1 d-lg-none">
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-mobile">
            <i className="icon-paragraph-justify3" />
          </button>
        </div>
        <div className="navbar-brand text-center text-lg-left">
          <a href="/" className="d-inline-block">
            <img src="logos/logo_expertumb.png" alt="" />
          </a>
        </div>
      </div>
      <div className="page-content">
        <div className="content-wrapper">
          <div className="content-inner">
            <div className="content d-flex justify-content-center align-items-center pt-5">
              <div className="flex-fill">
                <div className="text-center mb-4">
                  <img src="logos/error_bg.svg" className="img-fluid mb-3" height={230} alt />
                  <h1 className="display-2 font-weight-semibold line-height-1 mb-2">404</h1>
                  <h6 className="w-md-25 mx-md-auto">Oops, Se ha producido un error.. <br /> El recurso solicitado no se pudo encontrar en este servidor..</h6>
                </div>
                <div className="text-center">
                  <a href="/login" className="btn btn-warning"><i className="icon-home4 mr-2" /> Regresar al inicio</a>
                </div>
              </div>
            </div>
            <div className="navbar navbar-expand-lg navbar-light" style={{ position: 'fixed', left: 0, bottom: 0, width: "100%" }}>
              <div className="text-center d-lg-none w-100">
                <button type="button" className="navbar-toggler dropdown-toggle" data-toggle="collapse" data-target="#navbar-footer">
                  <i className="icon-unfold mr-2"></i>
                  Pie de página
                </button>
              </div>
              <div className="navbar-collapse collapse" id="navbar-footer">
                <span className="navbar-text"> <span style={{ fontSize: '15px' }}> &copy;</span> {(new Date().getFullYear())} Mercanetica estrategias S.A. de C.V.</span>
                <ul className="navbar-nav ml-lg-auto">
                  <li className="nav-item"><a href="https://e-xpertum.com.mx/adminfile/terminosycondiciones" className="navbar-nav-link" target="_blank"><span className="text-warning-400"><i className="icon-file-text2 mr-2"></i> Términos y condiciones</span></a></li>
                  <li className="nav-item"><a href="https://e-xpertum.com.mx/adminfile/avisoprivacidad" className="navbar-nav-link" target="_blank"><span className="text-warning-400"><i className="icon-clipboard3 mr-2"></i> Aviso de privacidad</span></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
