
import React, { Component } from 'react';
import api from '../../Config/apifiles';
import AutoComplete from './cat-service';
class PanelConfigAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            usersfilter: [],
            company: [],
            companyfilter: [],
            usuario_id: 0,
            mensaje: false,
        }
    }
    handleFilterServices = async (t) => {
        let user = await api.post("filter-users", { clave: t });
        this.setState({
            users: user.data,
            usersfilter: user.data,
        });
    }
    selectFilterUserCompany = async (j) => {
        this.setState({ usuario_id: j.target.value })
        let company = await api.post("filter-users-to-service", { usuario_id: j.target.value });
        this.setState({
            company: company.data,
            companyfilter: company.data,
        });
    }
    selectFilterUserCompanyUser = async (j) => {
        /// this.setState({ usuario_id: j.target.value })
        let company = await api.post("filter-users-to-service", { usuario_id: j });
        this.setState({
            company: company.data,
            companyfilter: company.data,
        });
    }
    handleInsertCompanyUser = async (j) => {
        let _op_ = JSON.parse(j.target.value);
        let company = await api.post("save-group-user-admin", { usuario_id: this.state.usuario_id, cliente_id: _op_.cliente_id, empresa_id: _op_.id });
        this.setState({ mensaje: true });
        setTimeout(() => {
            this.selectFilterUserCompanyUser(this.state.usuario_id);
            this.setState({ mensaje: false });
        }, 4000);
    }
    handleDeleteCompanyUser = async (h) => {

        let _op_ = JSON.parse(h.target.value);
        let company = await api.post("delete-companys-nomina", { id: parseInt(_op_.exist) });
        ///this.selectFilterUserCompany(this.state.usuario_id);
        this.setState({ mensajedelete: true });
        setTimeout(() => {
            this.selectFilterUserCompanyUser(this.state.usuario_id);
            this.setState({ mensajedelete: false });
        }, 4000);
    }
    handleFilterNameUser = async (k) => {
        let value = k.target.value;
        let users = (k.target.value === '' ? this.state.usersfilter : this.state.users),
            result = [];
        result = users.filter((user) => {
            return user.nombre.toLowerCase().search(value) != -1;
        });
        this.setState({ users: result });
    }
    handleFilterNameCompany = async (k) => {
        let value = k.target.value;
        let users = (k.target.value === '' ? this.state.companyfilter : this.state.company),
            result = [];
        result = users.filter((user) => {
            return user.nombre.toLowerCase().search(value) != -1;
        });
        this.setState({ company: result });
    }

    render() {
        const dataUser = (this.state.users || []).map((f, d) => {
            return (<tr key={d}>
                <td>
                    <div className="custom-control custom-radio mb-2">
                        <input type="radio" className="custom-control-input" name="cr-l" id={f.id} value={f.id} onChange={this.selectFilterUserCompany} />
                        <label className="custom-control-label" htmlFor={f.id}>{f.nombre}</label>
                    </div>
                </td>
            </tr>)
        });
        return (
            <div className="page-content">
                <div className="content-wrapper">
                    <div className="content-inner">
                        <div className="page-header border-bottom-0">
                            <div className="page-header-content header-elements-md-inline">
                                <div className="page-title d-flex">
                                    <a href="#/app/dashboard" style={{ color: 'white' }}>  <h4><i className="icon-arrow-left52 mr-2" /> <span className="font-weight-semibold">Dashboard</span> </h4></a>
                                    <a className="header-elements-toggle text-body d-lg-none"><i className="icon-more" /></a>
                                </div>
                                <div className="header-elements d-none mb-3 mb-lg-0">
                                    <div className="d-flex justify-content-center">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content pt-0">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">Configuración de usuarios con los servicios y empresas a administrar</h5>
                                    {this.state.mensaje ? (
                                        <div className="alert alert-success alert-styled-left alert-arrow-left alert-dismissible">
                                            <span className="font-weight-semibold">Información!</span> Se ha asignado correctamente la empresa.
                                        </div>
                                    ) : null}
                                    {this.state.mensajedelete ? (
                                        <div className="alert alert-success alert-styled-left alert-arrow-left alert-dismissible">
                                            <span className="font-weight-semibold">Información!</span> Se ha desasignado correctamente la empresa.
                                        </div>
                                    ) : null}
                                </div>
                                <div className="card-body">
                                    <p className="mb-3">Listado de servicios </p>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <AutoComplete filter={this.handleFilterServices} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <p className="font-weight-semibold">Listado de Administradores</p>
                                            <div className="border px-3 pt-3 pb-2 rounded">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th>
                                                                        <div className="form-group form-group-feedback form-group-feedback-right">
                                                                            <input type="text" className="form-control form-control-lg" placeholder="Filtrar administrador" onChange={this.handleFilterNameUser} />
                                                                            <div className="form-control-feedback form-control-feedback-lg">
                                                                                <i className="icon-users4" />
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {dataUser}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <p className="font-weight-semibold">Listado de empresas activas</p>
                                            <div className="border p-3 rounded">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th>
                                                                        <div className="form-group form-group-feedback form-group-feedback-right">
                                                                            <input type="text" className="form-control form-control-lg" placeholder="Filtrar empresas" onChange={this.handleFilterNameCompany} />
                                                                            <div className="form-control-feedback form-control-feedback-lg">
                                                                                <i className="icon-search4" />
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    (this.state.company || []).map((d, f) => (
                                                                        <tr key={f}>
                                                                            <td>
                                                                                {
                                                                                    d.exist !== "0" ? (
                                                                                        <label className="custom-control custom-checkbox-inverse custom-checkbox mb-2" >
                                                                                            <input type="checkbox" className="custom-control-input" value={JSON.stringify(d)} defaultChecked onChange={this.handleDeleteCompanyUser} />
                                                                                            <span className="custom-control-label text-white">{d.nombre + " - " + d.rfc}</span>
                                                                                        </label>
                                                                                    ) : (
                                                                                        <label className="custom-control custom-checkbox-inverse custom-checkbox mb-2" >
                                                                                            <input type="checkbox" className="custom-control-input" value={JSON.stringify(d)} onChange={this.handleInsertCompanyUser} />
                                                                                            <span className="custom-control-label text-white">{d.nombre + " - " + d.rfc}</span>
                                                                                        </label>
                                                                                    )
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PanelConfigAdmin;