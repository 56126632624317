
import React, { Component } from 'react';
import DetailsPerfil from './detailperfil';
import Details from './details';
import Sucripcion from './operation/suscripcion';
import Seguridad from './operation/seguridad';
class PerfilSystem extends Component {
    constructor(props) {
        super(props);
        const _data_ = JSON.parse(localStorage.getItem("id_token"));
        this.state = {
            perfil: _data_.perfil,
            suscrip: ''
        }
    }
    handleViewApp = () => {
        this.setState({ suscrip: <Sucripcion /> })
    }
    render() {
        return (
            <>
                <div className="content-wrapper">
                    <div className="page-header border-bottom-0">
                        <div className="page-header-content header-elements-md-inline">
                            <div className="page-title d-flex">
                                {this.state.perfil === "GOBIERNO" ? (<a href="#/app/ui/validadorgobierno" style={{ color: 'white' }}> <h4><i className="icon-arrow-left52 mr-2"></i> <span className="font-weight-semibold">Inicio</span></h4></a>) :
                                    (<a href="#" style={{ color: 'white' }}> <h4>
                                        <i className="icon-arrow-left52 mr-2"></i>
                                        <span className="font-weight-semibold">Inicio</span></h4></a>)
                                }
                                <a href="#" className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></a>
                            </div>
                        </div>
                    </div>
                    <div className="content pt-0">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header header-elements-inline">
                                        <h6 className="card-title">Perfil usuario</h6>
                                    </div>

                                    <div className="card-body">
                                        <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                                            <li className="nav-item"><a href="#justified-right-icon-tab1" className="nav-link active" data-toggle="tab">Información del usuario <i className="icon-profile ml-2"></i></a></li>
                                            <li className="nav-item"><a href="#justified-right-icon-tab2" className="nav-link " data-toggle="tab" onClick={this.handleViewApp}>Suscripción <i className="icon-mention ml-2"></i></a></li>
                                            <li className="nav-item"><a href="#justified-right-icon-tab3" className="nav-link" data-toggle="tab">Seguridad <i className="icon-make-group ml-2"></i></a></li>
                                        </ul>

                                        <div className="tab-content">
                                            <div className="tab-pane fade active show" id="justified-right-icon-tab1">
                                                <div className="row">
                                                    <div className="col-lg-5">
                                                        <DetailsPerfil />
                                                    </div>
                                                    <div className="col-lg-7">
                                                        <Details />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="justified-right-icon-tab2">
                                                {this.state.suscrip}
                                            </div>

                                            <div className="tab-pane fade" id="justified-right-icon-tab3">
                                                <Seguridad />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </>
        );
    }
}

export default PerfilSystem;