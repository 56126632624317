import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    color: { color: "#ffffff" }
}));

export default function DenseAppBar(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElAuditoria, setAnchorElAuditoria] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClickAuditoria = (event) => {
        setAnchorElAuditoria(event.currentTarget);
    };

    const handleCloseAuditoria = () => {
        setAnchorElAuditoria(null);
    };
    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar variant="dense" style={{ background: 'transparent' }}>
                    <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                        <MenuIcon />
                    </IconButton>
                    &nbsp;&nbsp;&nbsp;
                    <button className='btn btn-outline-primary my-1 me-2 text-white' onClick={() => props.url('OBLIGACION')}>
                        <span style={{ fontSize: '16px' }}>Inicio</span>
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button className='btn btn-outline-primary my-1 me-2 text-white' onClick={() => {
                        setAnchorEl(false);
                        props.url('CONSTANCIA');
                    }}>
                        <span style={{ fontSize: '16px' }}>Constancia de situación fiscal</span>
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button className='btn btn-outline-primary my-1 me-2 text-white' onClick={() => props.url('OPINION')}>
                        <span style={{ fontSize: '16px' }}>Opinión de cumplimiento</span>
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button className='btn btn-outline-primary my-1 me-2 text-white' onClick={handleClick}>
                        <span style={{ fontSize: '16px' }}>Estados financieros</span>
                        &nbsp;&nbsp;
                        <i className="icon-arrow-down32"></i>
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button className='btn btn-outline-primary my-1 me-2 text-white' onClick={handleClickAuditoria}>
                        <span style={{ fontSize: '16px' }}>Auditoría fiscal</span>
                        &nbsp;&nbsp;
                        <i className="icon-arrow-down32"></i>
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button className='btn btn-outline-primary my-1 me-2 text-white' onClick={() => props.url('SF')}>
                        <span style={{ fontSize: '16px' }}>Saldo a Favor</span>
                    </button>
                    {/*&nbsp;&nbsp;&nbsp;
                    <button className='btn btn-outline-primary my-1 me-2 text-white' onClick={() => props.url('IVACOBRADO')}>
                        <span style={{ fontSize: '16px' }}>IVA cobrado</span>
                    </button>*/}
                </Toolbar>

                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <div className="dropdown-menu" style={{ display: 'block', position: 'static', width: '100%', marginTop: 0, float: 'none', zIndex: 'auto' }}>
                        <div className="dropdown-divider" />
                        <button className="btn btn-link dropdown-item" onClick={() => {
                            setAnchorEl(false);
                            props.url('BALANCE')
                        }}>
                            <i className="icon-file-text2 me-3" />
                            Estado de posición financiera
                        </button>
                        <button className="btn btn-link dropdown-item" onClick={() => {
                            setAnchorEl(false);
                            props.url('INCOME');
                        }}>
                            <i className="icon-file-text2 me-3" />
                            Estado de resultados
                        </button>
                        <button className="btn btn-link dropdown-item" onClick={() => {
                            setAnchorEl(false);
                            props.url('FEFECTIVO')
                        }}>
                            <i className="icon-file-text2 me-3" />
                            Estado de flujo de efectivo
                        </button>
                        <button className="btn btn-link dropdown-item" onClick={() => {
                            setAnchorEl(false);
                            props.url('CAMBIOSCAPITAL');
                        }}>
                            <i className="icon-file-text2 me-3" />
                            Estado de cambios de capital
                        </button>
                        <button className="btn btn-link dropdown-item" onClick={() => {
                            setAnchorEl(false);
                            props.url('CONTABLEFISCAL')
                        }}>
                            <i className="icon-file-text2 me-3" />
                            Concilicación entre el resultado contable y fiscal
                        </button>
                        <div className="dropdown-divider" />
                    </div>
                </Menu>
                <Menu
                    id="menu-auditoria"
                    anchorEl={anchorElAuditoria}
                    keepMounted
                    open={Boolean(anchorElAuditoria)}
                    onClose={handleCloseAuditoria}
                >
                    <div className="dropdown-menu" style={{ display: 'block', position: 'static', width: '100%', marginTop: 0, float: 'none', zIndex: 'auto' }}>
                        <div className="dropdown-divider" />
                        <button className="btn btn-link dropdown-item" onClick={() => {
                            setAnchorElAuditoria(false);
                            props.url('ANUAL')
                        }}>
                            <i className="ph-user-circle me-3" />
                            Declaraciones anuales
                        </button>
                        <button className="btn btn-link dropdown-item" onClick={() => {
                            setAnchorElAuditoria(false);
                            props.url('EMITIDO')
                        }}>
                            <i className="ph-circles-four me-3" />
                            Comparativo de ingresos
                        </button>
                        <button className="btn btn-link dropdown-item" onClick={() => {
                            setAnchorElAuditoria(false);
                            props.url('IVACOBRADOR')
                        }}>
                            <i className="ph-dots-three-circle me-3" />
                            Comparativo de IVA Trasladado
                        </button>
                        <button className="btn btn-link dropdown-item" onClick={() => {
                            setAnchorElAuditoria(false);
                            props.url('RIVA')
                        }}>
                            <i className="ph-chat-circle-text me-3" />
                            Comparativo de IVA retenido
                        </button>
                        <button className="btn btn-link dropdown-item" onClick={() => {
                            setAnchorElAuditoria(false);
                            props.url('SUELDOS')
                        }}>
                            <i className="ph-dots-three-circle me-3" />
                            Comparativo de ISR retenido por sueldos y salarios
                        </button>
                        <button className="btn btn-link dropdown-item" onClick={() => {
                            setAnchorElAuditoria(false);
                            props.url('LEASE')
                        }}>
                            <i className="ph-dots-three-circle me-3" />
                            Comparativo de ISR por arrendamiento
                        </button>
                        <div className="dropdown-divider" />
                        {/*
                     
                        <button className="btn btn-link dropdown-item" onClick={() => props.url('IVATRASLADADO')}>
                            <i className="ph-dots-three-circle me-3" />
                            IVA Trasladado
                        </button>*/}
                        <button className="btn btn-link dropdown-item" onClick={() => {
                            setAnchorElAuditoria(false);
                            props.url('ACREDITABLE')
                        }}>
                            <i className="ph-dots-three-circle me-3" />
                            IVA Acreditable Beta
                        </button>
                    </div>
                </Menu>
            </AppBar>

        </div>
    );
}
