import React, { Component } from 'react';
import PropTypes from 'prop-types';
import apiFile from '../../Config/apifiles';
function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}
class ConfigDownload extends Component {
    constructor(props) {
        let _data_ = JSON.parse(localStorage.getItem("panelconfig"));
        console.log(_data_)
        super(props);
        this.state = {
            mensaje: '',
            mensajesucess: '',
            fecha_inicio: formatDate(new Date()),
            fecha_fin: formatDate(new Date()),
            emitido: '',
            recibido: '',
            declaraciones: '',
            rfc: _data_.rfc,
            dowloadfive: '',
            empresa_id: _data_.empresa_id,
            id: _data_.empres_id,
        }
        this.handleSubmit = this.handleSubmit.bind();
        this.handleSetCheckAll = this.handleSetCheckAll.bind();
    }
    handlesetdatainput = (t) => {
        if (this.state.dowloadfive === '')
            this.setState({ [t.target.name]: t.target.value });
        else {
            this.setState({ mensaje: 'Solo de puede hacer la descarga por ejercicio o personalizadas' });
            setTimeout(() => {
                this.setState({ mensaje: '' });
            }, 5000);
        }
    }
    handleSetCheckAll = (u) => {

        if (u.target.value === "EMITIDO")
            this.setState({ emitido: (u.target.checked ? 'EMITIDO' : '') });

        if (u.target.value === "RECIBIDO")
            this.setState({ recibido: (u.target.checked ? 'RECIBIDO' : '') });

        if (u.target.value === "DECLARACIONES")
            this.setState({ declaraciones: (u.target.checked ? 'DECLARACIONES' : '') });

        if (u.target.value === "SI")
            this.setState({ dowloadfive: 'SI' });

    }
    handleSubmit = async (k) => {
        k.preventDefault();
        if (this.state.emitido !== '' || this.state.recibido !== '' || this.state.declaraciones !== '') {


            k.target.reset();
            let dt = await apiFile.post('config-download-cfdis-declaraciones-all', this.state);

            if (dt.data.status === 200) {
                this.setState({
                    recibido: '', emitido: '', declaraciones: '', dowloadfive: '',
                    mensajesucess: 'Se ha confgiurado correctamente!!!.'
                });
                setTimeout(() => {
                    this.setState({ mensajesucess: '' });

                }, 4000);
            }
        }
        else {
            this.setState({
                mensaje: 'Debes de seleccionar el tipo de comprobantes que se van a descargar.'
            });
            setTimeout(() => {
                this.setState({ mensaje: '' });
            }, 3000);
        }
    }

    render() {
        return (
            <>
                <div className="modal-dialog modal-full">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Configuración de la descarga de los cfdis </h5>
                        </div>
                        <div className="modal-body">
                            <div className="card border-left-3 border-left-pink border-right-3 border-right-pink rounded-0">
                                <div className="card-body">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="card-title">Configuración manual / General descargas</h5>
                                        </div>
                                        <div className="card-body">
                                            <form onSubmit={this.handleSubmit}>
                                                {
                                                    this.state.mensaje !== '' ? (
                                                        <div className="alert alert-danger alert-styled-right alert-dismissible">
                                                            <span className="font-weight-semibold">{this.state.mensaje}!</span>.
                                                        </div>
                                                    ) : null
                                                }
                                                {
                                                    this.state.mensajesucess !== '' ? (
                                                        <div className="alert alert-success alert-styled-right alert-dismissible">
                                                            <span className="font-weight-semibold">{this.state.mensajesucess}!</span>.
                                                        </div>
                                                    ) : null
                                                }
                                                <fieldset>
                                                    <legend className="font-weight-semibold">
                                                        <i className="icon-trophy3 mr-2" />
                                                        Configuración general
                                                        <a className="float-right text-body collapsed" data-toggle="collapse" data-target="#demo3" aria-expanded="false">
                                                            <i className="icon-circle-down2" />
                                                        </a>
                                                    </legend>
                                                    <div className="collapse" id="demo3" style={{}}>
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label">Descargar los 5 años:</label>
                                                            <div className="col-lg-8">
                                                                <label className="custom-control custom-checkbox-inverse custom-checkbox mb-2">
                                                                    <input type="checkbox" className="custom-control-input" value="SI" name="dowloadfive" onChange={this.handleSetCheckAll} />
                                                                    <span className="custom-control-label text-white">Si</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label">Tipo:</label>
                                                            <div className="col-lg-8">
                                                                <table className="table">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td><label className="custom-control custom-checkbox-inverse custom-checkbox mb-2">
                                                                                <input type="checkbox" className="custom-control-input" value="EMITIDO" name="emitido" onChange={this.handleSetCheckAll} />
                                                                                <span className="custom-control-label text-white">EMITIDO</span>
                                                                            </label></td>
                                                                            <td><label className="custom-control custom-checkbox-inverse custom-checkbox mb-2">
                                                                                <input type="checkbox" className="custom-control-input" value="RECIBIDO" name="recibido" onChange={this.handleSetCheckAll} />
                                                                                <span className="custom-control-label text-white">RECIBIDO</span>
                                                                            </label></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                                <fieldset>
                                                    <legend className="font-weight-semibold">
                                                        <i className="icon-cogs mr-2" />
                                                        Configuración personalizada
                                                        <a className="float-right text-body collapsed" data-toggle="collapse" data-target="#demo1" aria-expanded="false">
                                                            <i className="icon-circle-down2" />
                                                        </a>
                                                    </legend>
                                                    <div className="collapse" id="demo1" style={{}}>
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label">Fecha inicio:</label>
                                                            <div className="col-lg-8">
                                                                <input type="date" name="fecha_inicio" onChange={this.handlesetdatainput} value={this.state.fecha_inicio} placeholder="Fecha de inicio" className="form-control" required />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label">Fecha Fin:</label>
                                                            <div className="col-lg-8">
                                                                <input type="date" name="fecha_fin" onChange={this.handlesetdatainput} value={this.state.fecha_fin} placeholder="Fecha Fin" className="form-control" required />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label">Tipo:</label>
                                                            <div className="col-lg-8">
                                                                <table className="table">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                <label className="custom-control custom-checkbox-inverse custom-checkbox mb-2">
                                                                                    <input type="checkbox" className="custom-control-input" value="EMITIDO" name="emitido" onChange={this.handleSetCheckAll} />
                                                                                    <span className="custom-control-label text-white">EMITIDO</span>
                                                                                </label>
                                                                            </td>
                                                                            <td>
                                                                                <label className="custom-control custom-checkbox-inverse custom-checkbox mb-2">
                                                                                    <input type="checkbox" className="custom-control-input" value="RECIBIDO" name="recibido" onChange={this.handleSetCheckAll} />
                                                                                    <span className="custom-control-label text-white">RECIBIDO</span>
                                                                                </label>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                                <fieldset>
                                                    <legend className="font-weight-semibold">
                                                        <i className="icon-reading mr-2" />
                                                        Agregar las declaraciones
                                                        <a className="float-right text-body collapsed" data-toggle="collapse" data-target="#demo2" aria-expanded="false">
                                                            <i className="icon-circle-down2" />
                                                        </a>
                                                    </legend>
                                                    <div className="collapse" id="demo2" style={{}}>
                                                        <div className="form-group row">

                                                            <div className="col-lg-8">
                                                                <div className="custom-control custom-checkbox mb-2">
                                                                    <input type="checkbox" className="custom-control-input" id="cc_ls_c" value="DECLARACIONES" name="declaraciones" onChange={this.handleSetCheckAll} />
                                                                    <label className="custom-control-label" htmlFor="cc_ls_c">Descargar declaraciones</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                                <div className="text-right">
                                                    <button type="submit" className="btn btn-primary">Enviar <i className="icon-paperplane ml-2" /></button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ConfigDownload;