import React, { Component, Fragment } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import Details from './screen-details/detailmodal'
import * as Icons from "@material-ui/icons";
import { Tooltip, } from "@material-ui/core";
import { Snackbar, Backdrop, CircularProgress } from '@material-ui/core';

import MuiAlert from '@material-ui/lab/Alert';
import api from '../Config/api'
import DatosFiscales from './screen-documents/datosfiscales';
import Contacto from './screen-documents/contacto';
import Suscripcion from './screen-documents/suscripcion';
import InformacionD from './screen-documents/informacion';
import Skeleton from '@material-ui/lab/Skeleton';
import View from './view-edit';
import { withRouter } from 'react-router-dom';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
class ListClients extends Component {
    constructor(props) {
        super(props);
        var objec = JSON.parse(localStorage.getItem('id_token'));
        this.columns = [
            {
                key: "razon_social",
                text: "Nombre, Denominación o razón social",
                className: "razon_social",
                align: "left",
                sortable: true, cell: record => {
                    return (
                        <div style={{ textAlign: 'left' }}>
                            {
                                record.numberdays < 0 ?
                                    (
                                        <Tooltip title="Ya se venció la suscripción" placement="top" ><span className="text-danger">{record.razon_social}</span></Tooltip>
                                    ) :
                                    (
                                        (record.numberdays >= 0 && record.numberdays <= 3) ?
                                            (
                                                <Tooltip title="Se encuentra próximo a vencer" placement="top" ><span className="text-warning">{record.razon_social}</span></Tooltip>
                                            ) : <span className="text-succes">{record.razon_social}</span>
                                    )
                            }

                        </div>
                    );
                }
            },
            {
                key: "rfc",
                text: "RFC",
                className: "rfc",
                align: "left",
                sortable: true
            },
            {
                key: "nombre",
                text: "Nombre(s)",
                className: "nombre",
                sortable: true
            }, {
                key: "apellido",
                text: "Apellido(s)",
                className: "apellido",
                sortable: true
            }, {
                key: "telefono",
                text: "Teléfono",
                className: "telefono",
                sortable: true
            }, {
                key: "correo",
                text: "Correo",
                className: "correo",
                sortable: true
            },
            {
                key: "action",
                text: "Acción",
                className: "action",
                width: 150,
                align: "center",
                sortable: false,
                cell: record => {
                    return (
                        <div style={{ textAlign: 'center' }}>
                            <Tooltip title="Modificar" placement="top" ><Icons.Edit style={{ color: "orange", cursor: "pointer" }} onClick={() => this.editData(record)} /></Tooltip>
                            <Tooltip title="Detalle" placement="top" ><Icons.List style={{ color: "green", cursor: "pointer" }} onClick={() => this.detailData(record)} ></Icons.List></Tooltip>
                            <Tooltip title="Eliminar" placement="top"><Icons.DeleteOutline style={{ color: "red", cursor: "pointer" }} onClick={() => this.handleDelete(record)} /></Tooltip>
                            <Tooltip title="Ver servicios" placement="top"><Icons.ArrowRightAltSharp style={{ color: "red", cursor: "pointer" }} onClick={() => this.handlenavigationestatus(record)} /></Tooltip>
                        </div>
                    );
                }
            }
        ];
        this.config = {
            filename: "report_cat_clientes",
            key_column: 'cliente_id',
            page_size: 10,
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,
            button: {
                excel: false,
                print: false,
                extra: true,
            },
            language: {
                length_menu: "Mostrar _MENU_ registros por página",
                filter: "Buscar en registros ...",
                info: "Mostrando _START_ a _END_ de _TOTAL_ entradas",
                pagination: {
                    first: "Primero",
                    previous: "Previa",
                    next: "próximo",
                    last: "Última"
                }
            },
            no_data_text: "No se encontraron registros"
        }
        this.extraButtons = [
            {
                className: "btn btn-light",
                title: "Export excel",
                children: [<i className="icon-file-excel text-success ml-2" aria-hidden="true" />],
                onClick: (event) => {
                    window.open("https://e-xpertum.com.mx/webadmin/api/export-list-excel-list-customer");
                }
            },
            {
                className: "btn btn-light",
                title: "Export TEst",
                children: [
                    <span>
                        Nuevo Cliente<i className="icon-play3 ml-2" aria-hidden="true"></i>
                    </span>
                ],
                onClick: (event) => {
                    window.$("#modal_full").modal("show");
                }
            },
        ]
        this.state = {
            info: [],
            loading: true,
            edit: false,
            detail: false,
            delete: false,
            id: 0,
            nombre: '',
            page: 0,
            rowsPerPage: 8,
            alert: false,
            alerterror: false,
            vertical: 'top',
            horizontal: 'right',
            message: '',
            view: '',
            datosfiscales: true,
            //datosfiscales: false,
            contacto: false,
            suscripcion: false,
            customer: '{}',
            customercontact: '{}',
            customersuscription: '{}',
            informacion: false,
            viewdetail: '',
            statussaveform: false,
            usuario_id: objec.id,
            perfil: objec.perfil,
            cliente_id: '',
        }
        this.handlerReload = this.handlerReload.bind(this);
    }
    componentDidMount() {
        api.post('listRazonPersonasFilter', { user_id: this.state.usuario_id, perfil: this.state.perfil }).then(response => response.data)
            .then(data => {
                console.log(data)
                this.setState({
                    info: data.data,
                    loading: false
                });
            });
    }
    handlerReload = (e) => {
        this.componentDidMount();
    }
    editData(id) {
        this.setState({ nombre: id.razon_social });
        var objexts = {
            id: id.id,
            cliente_id: id.cliente_id
        }
        api.get('listModuloClientsDetailsGroupFilter?id=' + JSON.stringify(objexts))
            .then(response => response.data)
            .then(data => {
                this.setState({ view: <View datosfiscales={data.dataRazon} contacto={data.dataPersona} suscripcion={data.dataSuscripcion} /> });
                window.$('#modal_large').modal('show');
            });
    }
    detailData(id) {
        if (id !== undefined) {
            var result = this.state.info.filter((i) => i.id === id.id);
            this.setState({ viewdetail: <Details detailData={id} />, detail: false, edit: false, id: id.id, nombre: result[0].nombre, delete: false });
            window.$("#modallargedetails").modal("show");
        }
    }
    handleCloseEdit = () => {
        this.setState({ detail: false, edit: false, delete: false });
    };
    handleCloseDetail = () => {
        this.setState({ detail: false, edit: false, delete: false });
    };
    handleDelete = (o) => {

        this.setState({ detail: false, edit: false, id: o.id, nombre: o.nombre, cliente_id: o.cliente_id });
        window.$("#modal_small").modal("show");
    }
    handleConfirmacionDelete = () => {
        var objt = {
            id: this.state.id,
            cliente_id: this.state.cliente_id
        }

        api.post('DeleteClientsAll', objt)
            .then(response => response.data)
            .then(data => {
                window.$("#modal_small").modal("hide");
                if (data.status === 200) {
                    this.componentDidMount();
                    this.setState({ detail: false, edit: false, delete: false, });
                }
                else if (data.status === 404) {
                    this.setState({ detail: false, edit: false, delete: false, });
                }
            });
    }
    handleConfirmacionCancelar = () => {
        this.setState({ detail: false, edit: false, delete: false });
    }
    handlenavigationestatus = (op) => {
        localStorage.setItem("data_costumer", JSON.stringify(op));
        this.props.history.push('/app/filter/liststatus');
    }
    /****************
     * Paginación
    */
    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
        this.setState({ page: 0 });
    };
    /********************** 
     * end paginación 
     * */

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({
            alert: false,
            alerterror: false
        });
    };
    handleClearView = () => {
        this.setState({ view: '' });
        this.componentDidMount();
    }
    /*************************
     * operaciones de wizard
     */
    handleChangeViewPosition = () => {
        if (this.state.datosfiscales === true && this.state.contacto === false && this.state.suscripcion === false && this.state.informacion === false) {
            //this.setState({ contacto: true, datosfiscales: false, suscripcion: false });
            if (this.state.customer !== '{}') {
                var container_ = this.state.customer;
                if (container_.razon_social !== '' && container_.rfc !== '' && container_.calle !== '' && container_.colonia !== '' &&
                    container_.externo !== '' && container_.codigo !== '' && container_.pais_id !== '' &&
                    container_.estado_id !== '' && container_.municipio_id !== '' && container_.localidad_id !== '')
                    this.setState({ contacto: true, datosfiscales: false, suscripcion: false });
                else
                    this.setState({ alerterror: true, message: 'Debe de capturar todos los campos requeridos', })
            }
            else
                this.setState({ alerterror: true, message: 'Debe de capturar todos los campos requeridos', })
        }
        else if (this.state.datosfiscales === false && this.state.contacto === true && this.state.suscripcion === false && this.state.informacion === false) {
            //this.setState({ contacto: false, datosfiscales: false, suscripcion: true, informacion: false });
            if (this.state.customercontact !== '{}') {
                var container_ = this.state.customercontact;
                if (container_.nombre !== '' && container_.ape_paterno !== '' && container_.ape_materno !== '' && container_.telefono !== '' && container_.correo !== '')
                    this.setState({ contacto: false, datosfiscales: false, suscripcion: true, informacion: false });
                else
                    this.setState({ alerterror: true, message: 'Debe de capturar todos los campos requeridos', })
            }
        }
        else if (this.state.datosfiscales === false && this.state.contacto === false && this.state.suscripcion === true && this.state.informacion === false) {
            //this.setState({ contacto: false, datosfiscales: false, suscripcion: false, informacion: true });
            if (this.state.customersuscription !== '{}') {
                var container_ = this.state.customersuscription;
                if (container_.fecha_fin !== '' && container_.fecha_inicio !== '' && container_.numero_usuario !== '' && container_.numero_empresa !== '' && container_.suscripcion_id !== '' && container_.usuario_tipo !== '')
                    this.setState({ contacto: false, datosfiscales: false, suscripcion: false, informacion: true });
                else
                    this.setState({ alerterror: true, message: 'Debe de capturar todos los campos requeridos', })
            }
        }
    }
    handleChangeViewDissmPosition = () => {
        if (this.state.datosfiscales === false && this.state.contacto === true && this.state.suscripcion === false && this.state.informacion === false)
            this.setState({ datosfiscales: true, contacto: false, suscripcion: false, informacion: false })
        else if (this.state.datosfiscales === false && this.state.contacto === false && this.state.suscripcion === true && this.state.informacion === false)
            this.setState({ datosfiscales: false, contacto: true, suscripcion: false, informacion: false })
        else if (this.state.datosfiscales === false && this.state.contacto === false && this.state.suscripcion === false && this.state.informacion === true)
            this.setState({ datosfiscales: false, contacto: false, suscripcion: true, informacion: false })
    }
    handleResetWizard = () => {
        this.setState({ datosfiscales: true, contacto: false, suscripcion: false, informacion: false })
    }
    /*******
     * 
    */
    setDataComponentDatosFiscales = (info) => {
        this.setState({ customer: info })
    }
    setDataComponentContacto = (info) => {
        this.setState({ customercontact: info })
    }
    setDataComponentSuscripcion = (info) => {
        this.setState({ customersuscription: info })
    }
    handleSendDataInfo = () => {
        this.setState({ statussaveform: true })
        setTimeout(() => {
            const form = new FormData();
            form.append("cliente", JSON.stringify(this.state.customer))
            form.append("contacto", JSON.stringify(this.state.customercontact))
            form.append("suscripcion", JSON.stringify(this.state.customersuscription))
            form.append("usuario_id", this.state.usuario_id)
            api.post('NewRazoClientLocal', form).then((j) => j.data).then((p) => {
                if (p.status === 200) {
                    this.componentDidMount();
                    window.$('#modal_full').modal('hide');
                    this.setState({ statussaveform: false, datosfiscales: true, contacto: false, suscripcion: false, informacion: false })
                }
            });
        }, 200);

    }
    handleClearClass = () => {
        this.setState({ viewdetail: '' })
    }
    render() {
        const { vertical, horizontal, message } = this.state
        return (
            <>
                <Backdrop style={{ color: "#fff", background: "#B9B6B6", zIndex: 400, opacity: "0.5" }} open={this.state.loading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Snackbar open={this.state.alert} autoHideDuration={6000} onClose={this.handleClose} anchorOrigin={{ vertical, horizontal }} key={vertical}>
                    <Alert onClose={this.handleClose} severity="success">{this.state.message}</Alert>
                </Snackbar>
                <Snackbar open={this.state.alerterror} autoHideDuration={6000} onClose={this.handleClose} anchorOrigin={{ vertical, horizontal }} key={horizontal}>
                    <Alert onClose={this.handleClose} severity="error">{this.state.message}</Alert>
                </Snackbar>
                <div className="page-header border-bottom-0">
                    <div className="page-header-content header-elements-md-inline">
                        <div className="page-title d-flex">
                            <a href="#" style={{ color: 'white' }}><h4><i className="icon-home mr-2"></i> <span className="font-weight-semibold">Inicio</span></h4></a>
                            <a className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></a>
                        </div>
                    </div>
                </div>
                <div className="content pt-0">
                    <div className="card">
                        <div className="card-header header-elements-inline">
                            <h5 className="card-title">Catálogo de clientes</h5>
                        </div>
                        <div className="card-body">
                            <ReactDatatable
                                config={this.config}
                                records={this.state.info}
                                columns={this.columns}
                                extraButtons={this.extraButtons}
                            />
                        </div>
                        <div id="modal_large" className="modal fade" tabIndex="-1" aria-hidden="true" style={{ display: "none" }}>
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">{this.state.nombre}</h5>
                                        <button type="button" className="close" data-dismiss="modal" onClick={this.handleClearView}>×</button>
                                    </div>

                                    <div className="modal-body">
                                        {this.state.view}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="modal_full" className="modal fade" tabIndex="-1" aria-hidden="true" style={{ display: "none" }}>
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" style={{ textAlign: 'center' }}>Nuevo cliente</h5>
                                        <button type="button" className="close" data-dismiss="modal" onClick={this.handleResetWizard}>×</button>
                                    </div>
                                    {
                                        this.state.statussaveform ? (
                                            <>
                                                <div className="modal-body">
                                                    <div className="row">
                                                        <div className="col-lg-2">
                                                            <Skeleton variant="text" />
                                                        </div>
                                                        <div className="col-lg-2">
                                                            <Skeleton variant="circle" width={40} height={40} />
                                                        </div>
                                                        <div className="col-lg-2">
                                                            <Skeleton variant="text" />
                                                        </div>
                                                        <div className="col-lg-2">
                                                            <Skeleton variant="circle" width={40} height={40} />
                                                        </div>
                                                        <div className="col-lg-2">
                                                            <Skeleton variant="text" />
                                                        </div>
                                                        <div className="col-lg-2">
                                                            <Skeleton variant="circle" width={40} height={40} />
                                                        </div>
                                                    </div>
                                                    <br></br>
                                                    <br></br>
                                                    <Skeleton variant="rect" height={118} />
                                                    <br></br>
                                                    <br></br>
                                                    <Skeleton variant="text" />
                                                    <br></br>
                                                    <br></br>
                                                    <Skeleton variant="rect" height={118} />
                                                </div>
                                            </>
                                        ) : (
                                            <>

                                                <div className="modal-body">
                                                    <form className="wizard-form steps-starting-step wizard clearfix">
                                                        <div className="steps clearfix">
                                                            <ul role="tablist">

                                                                {
                                                                    (this.state.datosfiscales === true && this.state.contacto === false && this.state.suscripcion === false && this.state.informacion === false) ?
                                                                        (<li role="tab" aria-disabled="false" className="first current" aria-selected="true">
                                                                            <a id="steps-uid-3-t-0" aria-controls="steps-uid-3-p-0">
                                                                                <span className="current-info audible">current step: </span>
                                                                                <span className="number">1</span>Datos de fiscales
                                                                            </a>
                                                                        </li>) : (<li role="tab" aria-disabled="false" className="done first" aria-selected="false">
                                                                            <a id="steps-uid-3-t-0" aria-controls="steps-uid-3-p-0">
                                                                                <span className="number">1</span>Datos de fiscales
                                                                            </a>
                                                                        </li>)
                                                                }
                                                                {
                                                                    (this.state.datosfiscales === false && this.state.contacto === true && this.state.suscripcion === false && this.state.informacion === false) ?
                                                                        (<li role="tab" aria-disabled="false" className="current" aria-selected="true">
                                                                            <a id="steps-uid-3-t-1" aria-controls="steps-uid-3-p-1">
                                                                                <span className="current-info audible">current step: </span>
                                                                                <span className="number">2</span> Contacto
                                                                            </a>
                                                                        </li>) :
                                                                        (<li role="tab" aria-disabled="false" className="done" aria-selected="false">
                                                                            <a id="steps-uid-3-t-1" aria-controls="steps-uid-3-p-1">
                                                                                <span className="number">2</span> Contacto
                                                                            </a>
                                                                        </li>)
                                                                }
                                                                {
                                                                    (this.state.datosfiscales === false && this.state.contacto === false && this.state.suscripcion === true && this.state.informacion === false) ?
                                                                        (<li role="tab" className="current" aria-disabled="false" aria-selected="true">
                                                                            <a id="steps-uid-3-t-2" aria-controls="steps-uid-3-p-2" className="">
                                                                                <span className="current-info audible">current step: </span>
                                                                                <span className="number">3</span> Suscripción
                                                                            </a>
                                                                        </li>) :
                                                                        (<li role="tab" className="done" aria-disabled="false" aria-selected="false">
                                                                            <a id="steps-uid-3-t-2" aria-controls="steps-uid-3-p-2" className="">
                                                                                <span className="number">3</span> Suscripción
                                                                            </a>
                                                                        </li>)
                                                                }

                                                                {
                                                                    (this.state.datosfiscales === false && this.state.contacto === false && this.state.suscripcion === false && this.state.informacion === true) ? (
                                                                        <li role="tab" className="current last" aria-disabled="false" aria-selected="true">
                                                                            <a id="steps-uid-3-t-3" aria-controls="steps-uid-3-p-3" className="">
                                                                                <span className="current-info audible">current step: </span>
                                                                                <span className="number">4</span> Información
                                                                            </a>
                                                                        </li>
                                                                    ) : (<li role="tab" className="done last" aria-disabled="false" aria-selected="false">
                                                                        <a id="steps-uid-3-t-3" aria-controls="steps-uid-3-p-3" className="">
                                                                            <span className="number">4</span> Información
                                                                        </a>
                                                                    </li>)}

                                                            </ul>
                                                        </div>
                                                        {
                                                            (this.state.datosfiscales === true && this.state.contacto === false && this.state.suscripcion === false) ?
                                                                (<DatosFiscales data={this.setDataComponentDatosFiscales} />) : (
                                                                    (this.state.datosfiscales === false && this.state.contacto === true && this.state.suscripcion === false) ?
                                                                        (<Contacto data={this.setDataComponentContacto} />) :
                                                                        ((this.state.datosfiscales === false && this.state.contacto === false && this.state.suscripcion === true) ?
                                                                            (<Suscripcion data={this.setDataComponentSuscripcion} />) :
                                                                            (<InformacionD data={this.state} />))
                                                                )

                                                        }
                                                    </form>
                                                </div>

                                                <div className="modal-footer">
                                                    {
                                                        (this.state.datosfiscales === true && this.state.contacto === false && this.state.suscripcion === false && this.state.informacion === false) ?
                                                            (<button type="button" className="btn btn-light disabled"><i className="icon-arrow-left13 mr-2"></i>  Previous</button>) :
                                                            (<button type="button" className="btn btn-light" onClick={this.handleChangeViewDissmPosition}><i className="icon-arrow-left13 mr-2"></i> Previo</button>)
                                                    }

                                                    {
                                                        (this.state.datosfiscales === false && this.state.contacto === false && this.state.suscripcion === false && this.state.informacion === true) ?
                                                            (<button type="button" className="btn bg-blue ml-3" onClick={this.handleSendDataInfo}>Enviar <i className="icon-paperplane ml-2"></i></button>) :
                                                            ((this.state.datosfiscales === false && this.state.contacto === false && this.state.suscripcion === true && this.state.informacion === false) ?
                                                                (<button type="button" className="btn bg-blue ml-3" onClick={this.handleChangeViewPosition}>Validar <i className="icon-clipboard5 ml-2"></i></button>) :
                                                                ((<button type="button" className="btn bg-blue ml-3" onClick={this.handleChangeViewPosition}>Continuar <i className="icon-arrow-right14 ml-2"></i></button>)))

                                                    }

                                                </div>

                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div id="modallargedetails" className="modal fade" tabIndex="-1" aria-hidden="true" style={{ "display": "none" }}>
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Información de  {this.state.nombre}</h5>
                                        <button type="button" className="close" data-dismiss="modal" onClick={this.handleClearClass}>×</button>
                                    </div>

                                    <div className="modal-body">
                                        {
                                            this.state.viewdetail
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="modal_small" className="modal fade" tabIndex="-1" aria-hidden="true" style={{ "display": "none" }}>
                            <div className="modal-dialog modal-sm">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Confirmación de eliminación</h5>
                                        <button type="button" className="close" data-dismiss="modal">×</button>
                                    </div>

                                    <div className="modal-body">
                                        Está seguro de eliminar el siguiente registro {this.state.nombre}?
                                    </div>

                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-link" data-dismiss="modal">Cancelar</button>
                                        <button type="button" className="btn bg-primary" onClick={this.handleConfirmacionDelete} >Aceptar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(ListClients);