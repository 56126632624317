import React, { Component } from 'react';
//import AuditorInstrucciones from '../../help/auditor_contable/index';
import Terminos from './teminoscondiciones';
import Skeleton from '@material-ui/lab/Skeleton';
import api from '../../app/Config/api';
function mostrarSaludo() {
    var texto = '', imagen = '';
    var fecha = new Date();
    var hora = fecha.getHours();

    if (hora >= 0 && hora < 12) {
        texto = "Buenos Días";
        imagen = "img/dia.png";
    }

    if (hora >= 12 && hora < 18) {
        texto = "Buenas Tardes";
        imagen = "img/tarde.png";
    }

    if (hora >= 18 && hora < 24) {
        texto = "Buenas Noches";
        imagen = "img/noche.png";
    }
    return texto;
}
class SistemIntial extends Component {
    constructor(props) {
        super(props);
        var objec = JSON.parse(localStorage.getItem('id_token'));
        this.state = {
            nombre: objec.nombre + ' ' + objec.apellido_paterno + ' ' + objec.apellido_materno,
            fecha: objec.fecha,
            servicioauditor: true,
            serviciomaquila: true,
            serviciovalidador: true,
            serviciojuridico: true,
            servicioadminfiles: true,
            servicio_corporate_legal_audit: true,
            terminosycondiciones: false,
            check: false,
            skeleton: true,
            servicealertauditor: false,
            servicealertvalidador: false,
            servicealertnomina: false,
            servicealertjuridico: false,
            servicealertadministrador: false,
            servicealert_corporate_legal_audit: false,
            mensajeauditor: '',
            mensajevalidador: '',
            mensajenomina: '',
            mensajejuridico: '',
            mensajeadministrador: '',
            mensaje_corporate_legal_audit: '',
            nvalidador: 0,
            nauditor: 0,
            nnomina: 0,
            njuridico: 0,
            nadministrador: 0,
            n_corporate_legal_audit: 0,
            link_corporate_legal_audit: ''
        }

    }
    componentDidMount() {
        this.handlegetdata();
    }
    handlegetdata = () => {
        localStorage.removeItem("terminosycondiciones");
        var objec = JSON.parse(localStorage.getItem('id_token'));
        api.post('terminosycondiciones', objec).then((y) => y.data).then((i) => {

            if (i[0].ESTATUS === "ACEPTADO") {
                localStorage.setItem("terminosycondiciones", true);
                this.setState({ terminosycondiciones: true })
                api.post('validacionactivacionend', objec).then((o) => {
                    var serviicio_contratado = o.data;
                    for (let index = 0; index < serviicio_contratado.length; index++) {
                        serviicio_contratado[index].estatus = (serviicio_contratado[index].estatus === null ? 0 : serviicio_contratado[index].estatus)
                        if (serviicio_contratado[index].link === "/app/client/sistem") {
                            ///console.log(parseInt(serviicio_contratado[index].dia))
                            this.setState({ nauditor: serviicio_contratado[index].estatus });
                            if (parseInt(serviicio_contratado[index].dia) === 0) {
                                this.setState({ servicioauditor: true, servicealertauditor: true, mensajeauditor: 'El servicio se ha bloqueado, ponerse en contacto con el equipo de ventas!' });
                            }
                            else if (parseInt(serviicio_contratado[index].dia) <= 3) {
                                console.log(this.state)
                                if (serviicio_contratado[index].dia < 0)
                                    this.setState({ servicealertauditor: true, mensajeauditor: 'Se ha vencido el servicio para seguir utilizando, le aconsejamos ponerse en contacto con el equipo de ventas!.' });
                                else {

                                    this.setState({ servicealertauditor: true, mensajeauditor: 'Le quedan ' + serviicio_contratado[index].dia + ' días para seguir utilizando el servicio, le aconsejamos ponerse en contacto con el equipo de ventas!.' });

                                    setTimeout(() => {
                                        this.setState({ servicioauditor: false, servicealertauditor: false, mensajeauditor: '' });
                                    }, 4000);
                                }
                            }
                            else if (parseInt(serviicio_contratado[index].dia) >= 4) {

                                this.setState({ servicioauditor: false });
                            }
                        }
                        else if (serviicio_contratado[index].link === "/app/ui/constitucion") {
                            this.setState({ njuridico: serviicio_contratado[index].estatus });
                            if (serviicio_contratado[index].dia === 0) {
                                this.setState({ servicealertjuridico: true, serviciojuridico: true, mensajejuridico: 'El servicio se ha bloqueado, ponerse en contacto con el equipo de ventas!' });
                            }
                            else if (serviicio_contratado[index].dia <= 3) {
                                if (serviicio_contratado[index].dia < 0)
                                    this.setState({ servicealertjuridico: true, mensajejuridico: 'Se ha vencido su servicio para seguir utilizando, le aconsejamos ponerse en contacto con el equipo de ventas!.' });
                                else {
                                    this.setState({ servicealertjuridico: true, mensajejuridico: 'Le quedan ' + serviicio_contratado[index].dia + ' días para seguir utilizando el servicio, le aconsejamos ponerse en contacto con el equipo de ventas!.' });
                                    setTimeout(() => {
                                        this.setState({ servicealertjuridico: false, serviciojuridico: false, mensajejuridico: '' });
                                    }, 4000);
                                }
                            }
                            else if (serviicio_contratado[index].dia >= 4) {
                                this.setState({ serviciojuridico: false });
                            }
                        }
                        else if (serviicio_contratado[index].link === "/app/ui/69b") {
                            this.setState({ nvalidador: serviicio_contratado[index].estatus });
                            if (serviicio_contratado[index].dia === 0) {
                                this.setState({ servicealertvalidador: true, serviciovalidador: true, mensajevalidador: 'El servicio se ha bloqueado, ponerse en contacto con el equipo de ventas!' });
                            }
                            else if (serviicio_contratado[index].dia <= 3) {

                                if (serviicio_contratado[index].dia < 0)
                                    this.setState({ servicealertvalidador: true, mensajevalidador: 'Se ha vencido su servicio para seguir utilizando , le aconsejamos ponerse en contacto con el equipo de ventas!.' });
                                else {
                                    this.setState({ servicealertvalidador: true, mensajevalidador: 'Le quedan ' + serviicio_contratado[index].dia + ' días para seguir utilizando el servicio, le aconsejamos ponerse en contacto con el equipo de ventas!.' });
                                    setTimeout(() => {
                                        this.setState({ servicealertvalidador: false, serviciovalidador: false, mensajevalidador: '' });
                                    }, 4000);
                                }
                            }
                            else if (serviicio_contratado[index].dia >= 4) {
                                this.setState({ serviciovalidador: false });
                            }
                        }
                        else if (serviicio_contratado[index].link === "/app/ui/nomina") {
                            this.setState({ nnomina: serviicio_contratado[index].estatus });
                            if (serviicio_contratado[index].dia === 0) {
                                this.setState({ servicealertnomina: true, serviciomaquila: true, mensajenomina: 'El servicio se ha bloqueado, ponerse en contacto con el equipo de ventas!' });
                            }
                            else if (serviicio_contratado[index].dia <= 3) {
                                if (serviicio_contratado[index].dia < 0)
                                    this.setState({ servicealertnomina: true, mensajenomina: 'Se ha vencido su servicio para seguir utilizando, le aconsejamos ponerse en contacto con el equipo de ventas!.' });
                                else {
                                    this.setState({ servicealertnomina: true, mensajenomina: 'Le quedan ' + serviicio_contratado[index].dia + ' días para seguir utilizando el servicio, le aconsejamos ponerse en contacto con el equipo de ventas!.' });
                                    setTimeout(() => {
                                        this.setState({ servicealertnomina: false, serviciomaquila: false, mensajenomina: '' });
                                    }, 4000);
                                }
                            }
                            else if (serviicio_contratado[index].dia >= 4) {
                                this.setState({ serviciomaquila: false });
                            }
                        }
                        else if (serviicio_contratado[index].link === "/app/ui/admin") {
                            this.setState({ nadministrador: serviicio_contratado[index].estatus });
                            if (serviicio_contratado[index].dia === 0) {
                                this.setState({ servicealert_corporate_legal_audit: true, servicioadminfiles: true, mensajeadministrador: 'El servicio se ha bloqueado, ponerse en contacto con el equipo de ventas!' });
                            }
                            else if (serviicio_contratado[index].dia <= 3) {
                                if (serviicio_contratado[index].dia < 0)
                                    this.setState({ servicealert_corporate_legal_audit: true, mensajeadministrador: 'Se ha vencido su servicio para seguir utilizando, le aconsejamos ponerse en contacto con el equipo de ventas!.' });
                                else {
                                    this.setState({ servicealert_corporate_legal_audit: true, mensajeadministrador: 'Le quedan ' + serviicio_contratado[index].dia + ' días para seguir utilizando el servicio, le aconsejamos ponerse en contacto con el equipo de ventas!.' });
                                    setTimeout(() => {
                                        this.setState({ servicealert_corporate_legal_audit: false, servicioadminfiles: false, mensajeadministrador: '' });
                                    }, 4000);
                                }
                            }
                            else if (serviicio_contratado[index].dia >= 4) {
                                this.setState({ servicioadminfiles: false });
                            }

                        }
                        else if (serviicio_contratado[index].link === "/app/audit/corporate-legal-audit") {
                            this.setState({ n_corporate_legal_audit: serviicio_contratado[index].estatus, link_corporate_legal_audit: serviicio_contratado[index].link });
                            if (serviicio_contratado[index].dia === 0) {
                                this.setState({ service: true, servicio_corporate_legal_audit: true, mensaje_corporate_legal_audit: 'El servicio se ha bloqueado, ponerse en contacto con el equipo de ventas!' });
                            }
                            else if (serviicio_contratado[index].dia <= 3) {
                                if (serviicio_contratado[index].dia < 0)
                                    this.setState({ servicealertadministrador: true, mensaje_corporate_legal_audit: 'Se ha vencido su servicio para seguir utilizando, le aconsejamos ponerse en contacto con el equipo de ventas!.' });
                                else {
                                    this.setState({ servicealertadministrador: true, mensaje_corporate_legal_audit: 'Le quedan ' + serviicio_contratado[index].dia + ' días para seguir utilizando el servicio, le aconsejamos ponerse en contacto con el equipo de ventas!.' });
                                    setTimeout(() => {
                                        this.setState({ servicealertadministrador: false, servicio_corporate_legal_audit: false, mensaje_corporate_legal_audit: '' });
                                    }, 4000);
                                }
                            }
                            else if (serviicio_contratado[index].dia >= 4) {
                                this.setState({ servicio_corporate_legal_audit: false });
                            }
                        }
                    }
                    this.setState({ skeleton: false });
                })

                var obje = {
                    query: "SELECT numero_empresa FROM activaciones where cliente_id='" + objec.cliente_id + "'"
                }
                api.post('configtabledinamyc', obje).then((r) => r.data)
                    .then((j) => {
                        localStorage.removeItem("_numero_empresas_");
                        var contador = 0;
                        for (let po = 0; po < j.length; po++) {
                            contador = contador + parseInt(j[po].numero_empresa)
                        }
                        localStorage.setItem("_numero_empresas_", contador);
                    }).catch((u) => {
                        console.log(u)
                    })
            } else {
                this.setState({ skeleton: false });
                localStorage.setItem("terminosycondiciones", false);
            }
        });
    }
    handledataterminos = (y) => {
        var objec = JSON.parse(localStorage.getItem('id_token'));
        this.setState({ check: !this.state.check });
        setTimeout(() => {
            api.post('aceptaterminosycondiciones', { cliente_id: objec.cliente_id, estatus: (this.state.check ? 'ACEPTADO' : 'NOACEPTADO') }).
                then((O) => {
                    var cont = O.data;
                }).catch((i) => {
                    console.log(i)
                })
        }, 300);
    }
    render() {
        return (
            <>
                <div className="page-header border-bottom-0">
                    <div className="page-header-content header-elements-md-inline">
                        <div className="page-title d-flex">
                            <h4><i className="icon-home mr-2"></i> <span className="font-weight-semibold">Inicio</span></h4>
                            <a href="#" className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></a>
                        </div>
                    </div>
                </div>
                <div className="content pt-0" style={{ paddingTop: '0px !important' }}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">

                                <div className="card-body">
                                    <h5 className="card-title">{mostrarSaludo()}, {this.state.nombre} </h5>
                                    <h5> Bienvenid@  E-xpertum.</h5>
                                    <p style={{ textAlign: 'right' }}>E-xpertum le desea que tenga un excelente día.</p>
                                    {this.state.skeleton ? (
                                        <div className="content pt-0">
                                            <Skeleton variant="text" height={40} />
                                            <div className="row">
                                                <div className="col-lg-4"><Skeleton variant="rect" height={150} /></div>
                                                <div className="col-lg-4"><Skeleton variant="rect" height={150} /></div>
                                                <div className="col-lg-4"><Skeleton variant="rect" height={150} /></div>
                                            </div>
                                            <div style={{ height: 20 }}></div>
                                            <div className="row">
                                                <div className="col-lg-4"><Skeleton variant="rect" height={150} /></div>
                                                <div className="col-lg-4"><Skeleton variant="rect" height={150} /></div>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            {
                                                this.state.terminosycondiciones ? (<div className="content pt-0">
                                                    <div className="row">
                                                        <div className="col-lg-4">
                                                            {
                                                                this.state.serviciovalidador ?
                                                                    (
                                                                        <div className="alert alert-warning alert-styled-left alert-dismissible">
                                                                            <span className="font-weight-semibold">Alerta!</span> Usuario {this.state.mensajevalidador}
                                                                        </div>
                                                                    ) : (
                                                                        <div className="card">
                                                                            <div className="card-body text-center">
                                                                                <i className="icon-bug2 icon-2x text-warning-400 border-warning-400 border-3 rounded-round p-3 mb-3 mt-1"></i>
                                                                                <h5 className="card-title">Validador</h5>
                                                                                <p className="mb-3 font-weight-light ml-1" style={{ textAlign: 'justify' }}>Audita a los proveedores y clientes. Disminuye el riesgo de hacer negocios con empresas fraudulentas.</p>
                                                                                {(this.state.serviciovalidador) ? (
                                                                                    <button target="_blank" className="btn bg-success-400">Adquirir</button>
                                                                                ) : (<button className="btn bg-warning-400" onClick={event => window.location.href = '#/app/ui/69b'}>Acceder {
                                                                                    (this.state.nvalidador > 0) ? (
                                                                                        <>
                                                                                            &nbsp;&nbsp;&nbsp;
                                                                                            <span className="badge badge-danger badge-pill">{this.state.nvalidador}</span>
                                                                                        </>
                                                                                    ) : null
                                                                                }  &nbsp;&nbsp;&nbsp;<i className="icon-arrow-right7 mr-3"></i></button>)
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                            }

                                                        </div>
                                                        <div className="col-lg-4">
                                                            {
                                                                this.state.servicealertauditor ?
                                                                    (
                                                                        <div className="alert alert-warning alert-styled-left alert-dismissible">
                                                                            <span className="font-weight-semibold">Alerta!</span> Usuario {this.state.mensajeauditor}
                                                                        </div>
                                                                    ) : (
                                                                        <div className="card">
                                                                            <div className="card-body text-center">
                                                                                <i className="icon-lifebuoy icon-2x text-warning-400 border-warning-400 border-3 rounded-round p-3 mb-3 mt-1"></i>
                                                                                <h5 className="card-title">Auditoría de cumplimiento fiscal</h5>
                                                                                <p className="mb-3 font-weight-light ml-1" style={{ textAlign: 'justify' }}>Verifica que la contabilidad electrónica no contenga errores antes de ser enviada a la autoridad.</p>
                                                                                {(this.state.servicioauditor) ? (
                                                                                    <button target="_blank" className="btn bg-success-400">Adquirir</button>
                                                                                ) : (<button className="btn bg-warning-400" onClick={event => window.location.href = '#/app/client/sistem'} style={{ textAlign: 'center' }}>Acceder
                                                                                    {
                                                                                        (this.state.nauditor > 0) ? (
                                                                                            <>
                                                                                                &nbsp;&nbsp;&nbsp;
                                                                                                <span className="badge badge-danger badge-pill">{this.state.nauditor}</span>
                                                                                            </>
                                                                                        ) : null
                                                                                    } &nbsp;&nbsp;&nbsp;<i className="icon-arrow-right7 mr-3"></i> </button>)
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                            }
                                                        </div>
                                                        <div className="col-lg-4">
                                                            {
                                                                this.state.servicealertnomina ?
                                                                    (
                                                                        <div className="alert alert-warning alert-styled-left alert-dismissible">
                                                                            <span className="font-weight-semibold">Alerta!</span> Usuario {this.state.mensajenomina}
                                                                        </div>
                                                                    ) : (
                                                                        <div className="card">
                                                                            <div className="card-body text-center">
                                                                                <i className="icon-reading icon-2x text-blue border-blue border-3 rounded-round p-3 mb-3 mt-1"></i>
                                                                                <h5 className="card-title">Expediente de cumplimiento laboral</h5>
                                                                                <p className="mb-3 font-weight-light ml-1" style={{ textAlign: 'justify' }}>Administra la documentación de los trabajadores, así como los comprobantes de pago. Para efectos de una revisión.</p>
                                                                                {(this.state.serviciomaquila) ? (
                                                                                    <button href="https://e-xpertum.com/" target="_blank" className="btn bg-success-400">Adquirir</button>
                                                                                ) : (<button onClick={event => window.location.href = '#/app/ui/nomina'} className="btn bg-warning-400">Acceder
                                                                                    {
                                                                                        (this.state.nnomina > 0) ? (
                                                                                            <>
                                                                                                &nbsp;&nbsp;&nbsp;
                                                                                                <span className="badge badge-danger badge-pill">{this.state.nnomina}</span>
                                                                                            </>
                                                                                        ) : null
                                                                                    } &nbsp;&nbsp;&nbsp;<i className="icon-arrow-right7 mr-3"></i> </button>)
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                            }
                                                        </div>

                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-4">
                                                            {this.state.servicealertjuridico ?
                                                                (
                                                                    <div className="alert alert-warning alert-styled-left alert-dismissible">
                                                                        <span className="font-weight-semibold">Alerta!</span> Usuario {this.state.mensajejuridico}
                                                                    </div>
                                                                ) : (
                                                                    <div className="card">
                                                                        <div className="card-body text-center">
                                                                            <i className="icon-book icon-2x text-success-400 border-success-400 border-3 rounded-round p-3 mb-3 mt-1"></i>
                                                                            <h5 className="card-title">Jurídico</h5>
                                                                            <p className="mb-3 font-weight-light ml-1" style={{ textAlign: 'justify' }}>Es una plataforma especializada en resolver cualquier acto jurídico que necesiten nuestros clientes.</p>
                                                                            <ul style={{ textAlign: 'left' }}>
                                                                                <li>Fiscal</li>
                                                                                <li>Laboral</li>
                                                                                <li>Corporativo</li>
                                                                            </ul>
                                                                            {(this.state.serviciojuridico) ? (
                                                                                <button href="https://e-xpertum.com/" target="_blank" className="btn bg-success-400">Adquirir</button>
                                                                            ) : (<button onClick={event => window.location.href = '#/app/ui/constitucion'} className="btn bg-warning-400">Acceder
                                                                                {
                                                                                    (this.state.njuridico > 0) ? (
                                                                                        <>
                                                                                            &nbsp;&nbsp;&nbsp;
                                                                                            <span className="badge badge-danger badge-pill">{this.state.njuridico}</span>
                                                                                        </>
                                                                                    ) : null
                                                                                } &nbsp;&nbsp;&nbsp;<i className="icon-arrow-right7 mr-3"></i></button>)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                        <div className="col-lg-4">
                                                            {
                                                                this.state.servicealertadministrador ?
                                                                    (
                                                                        <div className="alert alert-warning alert-styled-left alert-dismissible">
                                                                            <span className="font-weight-semibold">Alerta!</span> Usuario {this.state.mensajeadministrador}
                                                                        </div>
                                                                    ) : (
                                                                        <div className="card">
                                                                            <div className="card-body text-center">
                                                                                <i className="icon-folder6 icon-2x text-danger-300 border-danger-300 border-3 rounded-round p-3 mb-3 mt-1"></i>
                                                                                <h5 className="card-title">Administrador de archivos</h5>
                                                                                <p className="mb-3 font-weight-light ml-1" style={{ textAlign: 'justify' }}>Organiza y administra los documentos importantes para tu empresa o de forma personal.</p>
                                                                                {(this.state.servicioadminfiles) ? (
                                                                                    <button href="https://e-xpertum.com/" target="_blank" className="btn bg-success-400">Adquirir</button>
                                                                                ) : (<button onClick={event => window.location.href = '#/app/ui/admin'} className="btn bg-warning-400">Acceder
                                                                                    {
                                                                                        (this.state.nadministrador > 0) ? (
                                                                                            <>
                                                                                                &nbsp;&nbsp;&nbsp;
                                                                                                <span className="badge badge-danger badge-pill">{this.state.nadministrador}</span>
                                                                                            </>
                                                                                        ) : null
                                                                                    }&nbsp;&nbsp;&nbsp;<i className="icon-arrow-right7 mr-3"></i> </button>)
                                                                                }
                                                                            </div>
                                                                        </div>)
                                                            }
                                                        </div>
                                                        <div className="col-lg-4">
                                                            {
                                                                this.state.servicealert_corporate_legal_audit ?
                                                                    (
                                                                        <div className="alert alert-warning alert-styled-left alert-dismissible">
                                                                            <span className="font-weight-semibold">Alerta!</span> Usuario {this.state.mensaje_corporate_legal_audit}
                                                                        </div>
                                                                    ) : (
                                                                        <div className="card">
                                                                            <div className="card-body text-center">
                                                                                <i className="icon-hammer2 icon-2x text-teal-300 border-teal-300 border-3 rounded-round p-3 mb-3 mt-1"></i>
                                                                                <h5 className="card-title">Auditoria legal corporativa</h5>
                                                                                <p className="mb-3 font-weight-light ml-1" style={{ textAlign: 'justify' }}>Consiste en analizar cada área de la organización conforme a las diversas materias del derecho para lograr una radiografía de la situación de la organización.</p>
                                                                                {(this.state.servicio_corporate_legal_audit) ? (
                                                                                    <button href="https://e-xpertum.com/" target="_blank" className="btn bg-success-400">Adquirir</button>
                                                                                ) : (<button onClick={event => window.location.href = '#' + this.state.link_corporate_legal_audit} className="btn bg-warning-400">Acceder
                                                                                    {
                                                                                        (this.state.n_corporate_legal_audit > 0) ? (
                                                                                            <>
                                                                                                &nbsp;&nbsp;&nbsp;
                                                                                                <span className="badge badge-danger badge-pill">{this.state.n_corporate_legal_audit}</span>
                                                                                            </>
                                                                                        ) : null
                                                                                    }&nbsp;&nbsp;&nbsp;<i className="icon-arrow-right7 mr-3"></i> </button>)
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>) : (
                                                    <>
                                                        <Terminos />
                                                        <br></br>
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-md-6">

                                                                        <div className="form-check">
                                                                            <label className="form-check-label" style={{ paddingLeft: 300 }}>
                                                                                <div className="uniform-checker border-primary text-primary" style={{ paddingLeft: 300 }}>
                                                                                    <span className={this.state.check ? "checked" : ""}>
                                                                                        <input type="checkbox" onChange={this.handledataterminos} className="form-check-input-styled-primary" data-fouc="" />
                                                                                    </span>
                                                                                </div> Aceptar Términos y condiciones </label>
                                                                        </div>

                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-check">
                                                                            <a className="btn btn-primary" style={{ cursor: 'pointer' }} onClick={this.handlegetdata}>Enviar</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ height: 50 }}></div>
            </>
        );
    }
}

export default SistemIntial;