import React, { Component } from 'react'
//import Backdrop from "@material-ui/core/Backdrop";
import { Tooltip, } from "@material-ui/core";
import ReactDatatable from '@ashvin27/react-datatable';
import BlackListReport from './subdetails/black-lis-report';
export default class BalckListCompany extends Component {
    constructor(props) {
        super(props)
        this.columns = [
            {
                key: "nombre",
                text: "Nombre",
                className: "name",
                cell: record => {
                    return (
                        <div className="text-left">
                            {record.nombre}
                        </div>
                    );
                }
            },
            {
                key: "rfc",
                text: "Rfc",
                cell: record => {
                    return (
                        <div className="text-center">
                            {record.rfc}
                        </div>
                    );
                }
            },
            {
                key: "nolocalizado",
                text: "No localizados",
                className: "nolocalizado",
                cell: y => {
                    return (
                        <div className='text-center'>{
                            y.nolocalizado > 0 ? (
                                <a onClick={() => this.viewpdfcfdi_(y, "001")} style={{ cursor: 'pointer' }}> <Tooltip title="Click para ver el listado de facturas" placement="top" arrow><span className="badge badge-flat"><i className="fas fa-frown text-warning" /></span></Tooltip></a>
                            ) : (
                                <i className="icon-checkmark4 text-success"></i>
                            )
                        }
                        </div>
                    );
                }
            },
            {
                key: "presuntos",
                text: "Presuntos",
                className: "presuntos",
                cell: y => {
                    return (
                        <div className='text-center'>{
                            y.presuntos > 0 ? (
                                <a onClick={() => this.viewpdfcfdi_(y, "002")} style={{ cursor: 'pointer' }}> <Tooltip title="Click para ver el listado de facturas" placement="top" arrow><span className="badge badge-flat"><i className="fas fa-frown text-warning" /></span></Tooltip></a>
                            ) : (
                                <i className="icon-checkmark4 text-success"></i>
                            )
                        }
                        </div>
                    );
                }
            },
            {
                key: "definitivo",
                text: "Definitivo",
                className: "definitivo",
                cell: y => {
                    return (
                        <div className='text-center'>{
                            y.definitivo > 0 ? (
                                <a onClick={() => this.viewpdfcfdi_(y, "003")} style={{ cursor: 'pointer' }}> <Tooltip title="Click para ver el listado de facturas" placement="top" arrow><span className="badge badge-flat"><i className="fas fa-angry text-danger" /></span></Tooltip></a>
                            ) : (
                                <i className="icon-checkmark4 text-success"></i>
                            )
                        }
                        </div>
                    );
                }
            },
            {
                key: "desvirtuado",
                text: "Desvirtuado",
                className: "desvirtuado",
                cell: y => {
                    return (
                        <div className='text-center'>{
                            y.desvirtuado > 0 ? (
                                <a onClick={() => this.viewpdfcfdi_(y, "004")} style={{ cursor: 'pointer' }}> <Tooltip title="Click para ver el listado de facturas" placement="top" arrow><span className="badge badge-flat"><i className="fas fa-frown text-warning" /></span></Tooltip></a>
                            ) : (
                                <i className="icon-checkmark4 text-success"></i>
                            )
                        }
                        </div>
                    );
                }
            }
        ];
        this.config = {
            key_column: 'rfc',
            "page_size": 50,
            "length_menu": [50, 70, 90],
            "show_filter": true,
            "show_pagination": true,
            "button": {
                "excel": false,
                "print": false,
                "extra": false
            },
            "language": {
                "length_menu": "Mostrar _MENU_ registros por página",
                "filter": "Buscar en registros ...",
                "info": "Mostrando _START_ a _END_ de _TOTAL_ entradas",
                "pagination": {
                    "first": "Primero",
                    "previous": "Previa",
                    "next": "próximo",
                    "last": "Última"
                }
            }
        };
        this.state = {
            data: props.data,
            listinvoce: props.listblack,
            title: '',
            detailsinvoce: []
        }
    }
    viewpdfcfdi_ = async (s, tipo) => {
        let _ = (this.state.listinvoce).filter((wq) => wq.empresa_id == s.id && wq.tipo == tipo);
        this.setState({
            detailsinvoce: _
        })
        window.$("#detailsInvoce").modal("show");
    }
    render() {
        return (
            <div className="card">
                <div className="card-body">
                    <ReactDatatable
                        config={this.config}
                        records={this.state.data}
                        columns={this.columns}
                    />
                    <div id="detailsInvoce" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                        <div className="modal-dialog modal-full">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">{this.state.title}</h5>
                                    <button type="button" className="close" data-dismiss="modal">×</button>
                                </div>
                                <div className="modal-body">
                                    <BlackListReport dta={this.state.detailsinvoce} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
