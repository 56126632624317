import React, { Component } from 'react';
import api from '../../Config/apifiles';
import CharT from './graphist';
import CharTMes from './grapMonsth';
import TImmsCF from './papel-trabajo/imss-work-papers';
class IMSSE extends Component {
    constructor(props) {
        super(props);
        let objects = JSON.parse(localStorage.getItem("datacompanyall"));
        this.state = {
            empresa_id: objects.id,
            init_data: [],
            dataperiod: [],
            datafilterperiod: [],
            firts: true,
            second: false,
            aniox: ''
        }
    }
    componentDidMount() {
        this.getthis_();
    }
    getthis_ = async () => {
        let _d = await api.post("get-data-imss-exercise", this.state);
        let { data } = await _d;
        this.setState({
            init_data: data.sort(function (a, b) {
                return a.anio - b.anio;
            })
        });
    }
    filterexercise_ = async (d) => {
        let _d = await api.post("get-data-imss-exercise-period", { empresa_id: this.state.empresa_id, ejercicio: d.anio });
        let { data } = await _d;
        this.setState({ dataperiod: data, firts: false, second: true, aniox: d.anio });
    }
    _viewInit_ = () => {
        this.setState({ firts: true, second: false, });
    }
    _viewInit_cfdi = async (x) => {
        let _d = await api.post("get-data-imss-exercise-period-all", { empresa_id: this.state.empresa_id, ejercicio: x.anio, periodo: x.mes });
        let { data } = await _d;
        this.setState({ datafilterperiod: data, firts: false, second: false, });
        ////
    }
    returnSecondView = () => {
        this.setState({ firts: false, second: true, });
    }
    render() {
        return (
            <>

                <div className="content pt-0">
                    <div className="mb-3 mt-2">
                        <h6 className="mb-0 font-weight-semibold">
                            Pago IMSS
                        </h6>
                        <span className="text-muted d-block">Detalle de pago al IMSS</span>
                    </div>
                    {
                        this.state.firts ? (
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="card border-top-success rounded-top-0">
                                        <div className="card-header">
                                        </div>
                                        <div className="card-body">
                                            <table className='table'>
                                                <thead>
                                                    <tr>
                                                        <th>Ejercicio</th>
                                                        <th className='text-center'>Importe</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        (this.state.init_data || []).map((s, x) => (
                                                            <tr key={x}>
                                                                <td><a style={{ cursor: 'pointer', color: "#8dccff", textDecoration: "none", backgroundColor: 'transparent' }}
                                                                    onClick={() => this.filterexercise_(s)}>{s.anio}</a></td>
                                                                <td style={{ textAlign: 'right' }}> {s.total.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1, ')}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="card border-bottom-success rounded-bottom-0">
                                        <div className="card-header">
                                            <h6 className="card-title">Comportamiento del pagos del IMSS</h6>
                                        </div>
                                        <div className="card-body">
                                            {
                                                this.state.init_data.length > 0 ? (
                                                    <CharT d={this.state.init_data} />
                                                ) : null
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null
                    }
                    {
                        this.state.second ? (
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="card border-top-success rounded-top-0">
                                        <div className="card-header">
                                            <div className="d-flex align-items-center" style={{ cursor: 'pointer' }} onClick={this._viewInit_}>
                                                <i className="icon-arrow-left16 mr-3 icon-2x text-primary"></i>
                                                <div className="text-primary">Inicio</div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <table className='table'>
                                                <thead>
                                                    <tr>
                                                        <th>Mes</th>
                                                        <th className='text-center'>Importe</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        (this.state.dataperiod || []).map((s, x) => (
                                                            <tr key={x}>
                                                                <td><a style={{ color: "#8dccff", textDecoration: "none", backgroundColor: 'transparent', cursor: 'pointer' }}
                                                                    onClick={() => this._viewInit_cfdi(s)}>{s.name}</a></td>
                                                                <td style={{ textAlign: 'right' }}> {s.total.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1, ')}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="card border-bottom-success rounded-bottom-0">
                                        <div className="card-header">
                                            <h6 className="card-title">Comportamiento de pagos al IMSS de {this.state.aniox}</h6>
                                        </div>
                                        <div className="card-body">
                                            {
                                                this.state.dataperiod.length > 0 ? (
                                                    <CharTMes d={this.state.dataperiod} />
                                                ) : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <>{
                                (this.state.second === false && this.state.firts === false) ?
                                    (
                                        <div className="card border-bottom-success rounded-bottom-0">
                                            <div className="card-header bg-transparent border-bottom header-elements-inline">
                                                <h6 className="card-title">Listado de comprobantes del Seguro Social</h6>
                                                <div className="header-elements" onClick={this.returnSecondView} style={{ cursor: 'pointer' }}>
                                                    <span><i className="icon-arrow-left16 mr-3 icon-2x text-primary" />  <a>Regresar</a></span>
                                                </div>
                                            </div>

                                            <div className="card-body">
                                                <TImmsCF dt={this.state.datafilterperiod} />
                                            </div>
                                        </div>
                                    ) : null
                            }
                            </>
                        )
                    }
                </div>
            </>
        );
    }
}

export default IMSSE;