import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tablero from '../auditoria-fiscal-contable/index';
import CompareCM from './compare-cfdi-metadata';
class ViewCompare extends Component {
    constructor(props) {
        super(props);
        let config = JSON.parse(localStorage.getItem("panelconfig"));
        this.state = {
            tablero: '',
            compare: ''
        }
    }
    handlesetViewTablero = () => {
        this.setState({ tablero: <Tablero /> });
    }
    handlesetViewCompare = () => {
        this.setState({ compare: <CompareCM /> });
    }
    render() {
        return (
            <div className="card">
                <div className="card-body">
                    <ul className="nav nav-tabs nav-tabs-highlight nav-justified">
                        <li className="nav-item"><a href="#vertical-left-tab1" className="nav-link active" data-toggle="tab" onClick={this.handlesetViewCompare}><i className="icon-menu7 mr-2" /> Comparación</a></li>
                        <li className="nav-item"><a href="#vertical-left-tab2" className="nav-link" data-toggle="tab" onClick={this.handlesetViewTablero}><i className="icon-mention mr-2" /> Tablero</a></li>
                    </ul>
                    <div className="tab-content">
                        <div className="tab-pane fade active show" id="vertical-left-tab1">
                            {this.state.compare}
                        </div>
                        <div className="tab-pane fade" id="vertical-left-tab2">
                            {this.state.tablero}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ViewCompare;