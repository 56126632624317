import React, { Component } from 'react';
import { Grid, IconButton, Typography, Backdrop, CircularProgress } from "@material-ui/core";
import { Dialog, DialogTitle as MuiDialogTitle, DialogContent as MuiDialogContent, DialogActions as MuiDialogActions } from '@material-ui/core';
import * as Icons from "@material-ui/icons";
import { withStyles } from '@material-ui/core/styles';
/*******
 * CONFIG CONEXION
 */
import apiC from '../../../app/Config/apiconnect';
import api from '../../../app/Config/api';
import Config from '../../../app/Config/config.json';
import configapi from '../../../app/Config/apifiles';

import DatailsModal from './props/filter';
import ViewPanel from './viewpanel';

import Table from './props/list';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
/**************************
 * CREACIÓN MODAL WINDOW
 */
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other} style={{ textAlign: "center" }}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <Icons.Close />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        maxWidth: "100%"
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

class ValidadorSistema extends Component {
    constructor(props) {
        super(props);
        const data = JSON.parse(localStorage.getItem("datavalidate"));
        var objec = JSON.parse(localStorage.getItem('id_token'));
        this.state = {
            cliente_id: objec.cliente_id,
            perfil: objec.perfil,
            result: [],
            fecha: '',
            blockscreen: true,
            datafilter: [],
            modal: false,
            min: 0,
            max: 0,
            descripcion: '',
            tipo: '',
            ejercicio: 0,
            modalpreview: false,
            listadocfdis: [],
            firtslevel: [],
            excel: '',
            pdf: '',
            rfc: data.rfc,
            validacion: data.tipo_validacion,
            nombretxt: data.nombretxt,
            message: '',
            alert: false,
            empresa_id: data.id,
            description: '',
            companyname: data.nombre,
            urldocument: 'https://e-xpertum.com.mx/adminfile/pdf/dowloadpdf69bnot/' + data.id,
            linkexcel: '',
            suscripcion: '',
            mensajesuscription: false
        }
    }
    componentDidMount() {
        this.getinfodata();
        var url = (this.state.validacion === 'EFIRMA' ? 'validacion69Bsistem' : 'validacion69Bsistemcfdi');
        api.post(url, this.state).then(res => {
            if (res.data.status === 200) {
                var resultadofiltrado = (res.data);
                if (resultadofiltrado.max !== null && resultadofiltrado.min !== null) {
                    var contanier = [];
                    for (let index = resultadofiltrado.max; index >= resultadofiltrado.min; index--) {
                        contanier.push(index);
                    }

                    this.setState({ result: contanier, blockscreen: false, datafilter: (res.data).result, fecha: resultadofiltrado.fecha, max: resultadofiltrado.max, min: resultadofiltrado.min });
                }
                else
                    this.setState({ result: [], blockscreen: false, datafilter: [], fecha: resultadofiltrado.fecha, max: 0, min: 0 });
            }
            else {
                this.setState({ blockscreen: false, message: 'No, existe información para validar.', alert: true });
            }
        }).catch(err => console.log(err));
    }

    async getinfodata() {
        const _cifrado_ = await apiC.post("api/getdataadmindata", { empresa_id: this.state.empresa_id });
        const informacion = await api.post("datedownloadblacklist");
        const { data } = await informacion;

        this.setState({
            description: (data[0].descripcion),
            linkexcel: 'https://e-xpertum.com.mx/adminfile/api/export-excel-files/' + this.state.rfc + '/' + this.state.companyname + '/' + _cifrado_.data
        });

        let acceso = await configapi.post("status-activacion-service-admin", { cliente_id: this.state.cliente_id, modulo_id: 18 });
        let dt = await acceso.data;
        this.setState({ suscripcion: dt[0].acceso });

    }
    handleClickOpen = (i) => {
        if (this.state.suscripcion !== "DEMO") {
            this.setState({ modal: true, descripcion: i.descripcion, tipo: i.tipo, ejercicio: i.ejercicio });
        }
        else {
            this.setState({ mensajesuscription: true, message: 'Debe de aquirir el servicio para ver los detalles' });
            setTimeout(() => {
                this.setState({
                    mensajesuscription: false
                });
            }, 4000);
        }
    }
    handleClose = () => {
        this.setState({ modal: false, modalpreview: false });
    }
    hadlePreviewModalCfdis = (i) => {
        if (this.state.suscripcion !== "DEMO") {
            this.setState({ blockscreen: true });
            if (this.state.validacion === 'IMPORTACION') {
                var params = {
                    ejercicio: i.ejercicio, rfc: this.state.rfc,
                    empresa_id: this.state.empresa_id,
                }
                api.post('rfcsvalidadoscfdis', params).then(res => {
                    this.setState({ blockscreen: false, modalpreview: true, ejercicio: i.ejercicio, firtslevel: res.data.firtslevel, excel: res.data.excel, pdf: res.data.pdf });
                }).catch(err => console.log(err));
            }
            else {
                var data = {
                    ejercicio: i.ejercicio,
                    empresa_id: this.state.empresa_id,
                    rfc: this.state.rfc
                }
                api.post('rfcsvalidadosmetadata', data).then((res) => {
                    this.setState({ blockscreen: false, modalpreview: true, ejercicio: i.ejercicio, firtslevel: res.data.firtslevel, excel: res.data.excel, pdf: res.data.pdf });
                });
            }
        }
        else {
            this.setState({ mensajesuscription: true, message: 'Debe de aquirir el servicio para ver los detalles' });
            setTimeout(() => {
                this.setState({
                    mensajesuscription: false
                });
            }, 4000);
        }
        //}
    }
    handlePropXls = () => {
        window.location.href = Config.directoriodownload.download + '/getfilesproveedor?query=' + this.state.excel;
    }
    handlePropPdf = () => {
        window.location.href = Config.directoriodownload.download + '/getfilesproveedor?query=' + this.state.pdf;
    }
    handleClosemessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ alert: false });
    }
    render() {
        return (
            <>
                <Backdrop open={this.state.blockscreen} style={{ color: "#fff", background: "#B9B6B6", zIndex: 400, opacity: "0.5" }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Snackbar open={this.state.alert} autoHideDuration={6000} onClose={this.handleClosemessage} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                    <Alert onClose={this.handleClosemessage} severity="error">{this.state.message}</Alert>
                </Snackbar>
                <Grid container spacing={3}>
                    <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={this.state.modal} maxWidth={"lg"} fullWidth={true}>
                        <DialogTitle id="customized-dialog-title" onClose={this.handleClose}><span style={{ color: 'white' }}>{this.state.descripcion}</span> </DialogTitle>
                        <DialogContent dividers>
                            <DatailsModal info={{ data: this.state.datafilter, tipo: this.state.tipo, ejercicio: this.state.ejercicio }}></DatailsModal>
                        </DialogContent>
                    </Dialog>
                    <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={this.state.modalpreview} maxWidth={"lg"} fullWidth={true}>
                        <DialogTitle id="customized-dialog-title" onClose={this.handleClose}><span style={{ color: 'white' }}> {'Ejercicio validado ' + this.state.ejercicio}</span></DialogTitle>
                        <DialogContent dividers>
                            <div className="card" style={{ color: 'white' }}>
                                <div className="card-header bg-transparent border-bottom header-elements-inline">
                                    <h6 className="card-title" style={{ color: "white" }}>Listado de proveedores y otros acreedores</h6>
                                    <div className="header-elements">
                                        <div className="list-icons">
                                            <div className="dropdown">
                                                <a href="#" className="list-icons-item dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="icon-menu7"></i></a>
                                                <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end"
                                                    style={{ "position": "absolute", willChange: "transform", "top": "0px", "left": "0px", "transform": "translate3d(33px, 19px, 0px)" }}>
                                                    <span className="dropdown-item" onClick={this.handlePropPdf}><i className="icon-file-pdf text-warning-300 top-0"></i> Exportar PDF</span>
                                                    <span className="dropdown-item" onClick={this.handlePropXls}><i className="icon-file-excel text-warning-300 top-0"></i> Exportar XLS</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <Table data={this.state.firtslevel} />
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>
                </Grid>
                <div className="content-wrapper">
                    <div className="page-header border-bottom-0">
                        <div className="page-header-content header-elements-md-inline">
                            <div className="page-title d-flex">
                                {
                                    (this.state.perfil === "GOBIERNO") ? (<a href="#/app/ui/69bgob" style={{ color: 'white' }}> <h4><i className="icon-arrow-left52 mr-2"></i> <span className="font-weight-semibold">Listado </span> empresas</h4></a>) :
                                        (<a href="#/app/ui/69b" style={{ color: 'white' }}> <h4><i className="icon-arrow-left52 mr-2"></i> <span className="font-weight-semibold">Listado </span> empresas</h4></a>)
                                }
                                <a className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></a>
                            </div>
                            <h6 style={{ textAlign: 'center', paddingTop: '2%' }}>{this.state.companyname} </h6>
                            <div className="header-elements d-none mb-3 mb-md-0">
                                <div className="d-flex justify-content-center">
                                    <h6 className="text-center" style={{ paddingTop: '2%' }}>{this.state.description}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content pt-0">
                        <div className="card">
                            <div className="card-header header-elements-sm-inline">
                                <h6 className="card-title text-center" style={{ textAlign: 'center !important' }}>
                                    <i className="icon-cog3 mr-2"></i> Validación de CFDIs vs Listas negras del SAT. Art. 69B del CFF
                                </h6>
                                <div className="header-elements">
                                    <div className="list-icons ml-3">
                                        <div className="dropdown">
                                            <a href="#" className="list-icons-item dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="icon-menu7"></i></a>
                                            <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end"
                                                style={{ "position": "absolute", "transform": "translate3d(-147px, 19px, 0px)", "top": "0px", "left": "0px", willChange: "transform" }}>
                                                <div className="dropdown-divider"></div>
                                                <a href={this.state.urldocument} target="_blank" className="dropdown-item"><i className="icon-list-unordered"></i> PDF certificado</a>
                                                <a href={this.state.linkexcel} target="_blank" className="dropdown-item"><i className="icon-list-unordered"></i> Excel </a>
                                                <div className="dropdown-divider"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                {
                                    this.state.mensajesuscription ? (
                                        <div className="alert alert-primary alert-styled-left alert-dismissible">
                                            <span className="font-weight-semibold">Usuario!</span> {this.state.message}
                                        </div>
                                    ) : null
                                }

                                <Grid container spacing={3}>
                                    {
                                        (this.state.result || []).map(p => (
                                            <ViewPanel informacion={{ ejercicio: p, data: this.state.datafilter, fecha: this.state.fecha, click: this.handleClickOpen, preview: this.hadlePreviewModalCfdis }} key={p} />
                                        ))
                                    }
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
            </>);
    }
}

export default ValidadorSistema;
