import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Button, Dialog, IconButton } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import api from '../../../app/Config/api';
/****
 * url view document
 */
import "../style.css";
/**************
 * NOTIFICATIONS
 */
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#000',
    },
}));

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);


export default function CustomizedDialogs(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [fullWidth] = useState(true);
    const [maxWidth] = useState("xl");
    const [infos, setData] = useState([]);
    const [containerxml, setContainer] = useState('');
    const [statusblock, setOpenBlock] = useState(false);
    const [dataempresa, setDataEmpresa] = useState('');
    const [titlexml, setTitleXML] = useState('');
    const [viewrreport, setViewReport] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    /***************
 * CARGAR DATOS DEL PROPS
 */
    const [title, setTitle] = useState('');
    /*****************
     * CARGA DE DATOS
     */
    async function getData(i) {
        const response = await api.post('listDetailsDocumentViewXML', i)
        const data = await response.data;
        setData(data);
        setOpenBlock(false);
    }
    async function getDataxml(e) {
        console.log(e)
        const response = await api.post('/documents/xmljson', e).catch((t) => {
            setOpenBlock(false);
        })
        if (response !== undefined) {
            const data = await response.data;
            if (JSON.parse(data)["BCE:Balanza"] !== undefined) {
                let data_ = JSON.parse(data)["BCE:Balanza"];
                setTitleXML(
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="text-danger">Version</th>
                                <th className="text-danger">{data_._attributes.Version}</th>
                                <th className="text-danger">RFC</th>
                                <th className="text-danger">{data_._attributes.RFC}</th>
                            </tr>
                            <tr>
                                <th className="text-danger">Mes</th>
                                <th className="text-danger">{data_._attributes.Mes}</th>
                                <th className="text-danger">Año</th>
                                <th className="text-danger">{data_._attributes.Anio}</th>
                                <th className="text-danger">Tipo Envio</th>
                                <th className="text-danger">{data_._attributes.TipoEnvio}</th>
                            </tr>
                        </thead>
                    </table>
                )
                let _data_ieterate_ = data_["BCE:Ctas"];
                setContainer(
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Número de cuenta</th>
                                <th>Saldo Inicial</th>
                                <th>Debe</th>
                                <th>Haber</th>
                                <th>Saldo Final</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                _data_ieterate_.map((t, g) => (
                                    <tr>
                                        <td>{t._attributes.NumCta}</td>
                                        <td style={{ textAlign: 'right' }}>{parseFloat(t._attributes.SaldoIni).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                        <td style={{ textAlign: 'right' }}>{parseFloat(t._attributes.Debe).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                        <td style={{ textAlign: 'right' }}>{parseFloat(t._attributes.Haber).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                        <td style={{ textAlign: 'right' }}>{parseFloat(t._attributes.SaldoFin).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                )
                setViewReport(true);
            }
            else if (JSON.parse(data)["catalogocuentas:Catalogo"] !== undefined) {
                let data_ = JSON.parse(data)["catalogocuentas:Catalogo"];
                let _data_ieterate_ = data_["catalogocuentas:Ctas"];
                setTitleXML(
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="text-warning">Version</th>
                                <th className="text-warning">{data_._attributes.Version}</th>
                                <th className="text-warning">RFC</th>
                                <th className="text-warning">{data_._attributes.RFC}</th>

                            </tr>
                            <tr>
                                <th className="text-warning">Mes</th>
                                <th className="text-warning">{data_._attributes.Mes}</th>
                                <th className="text-warning">Año</th>
                                <th className="text-warning">{data_._attributes.Anio}</th>
                            </tr>
                        </thead>
                    </table>
                )
                setContainer(
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Descripción</th>
                                <th>Número de cuenta</th>
                                <th>Cuenta de control</th>
                                <th>Código agrupador</th>
                                <th>Nivel</th>
                                <th>Naturaleza</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                _data_ieterate_.map((t, g) => (
                                    <tr>
                                        <td>{t._attributes.Desc}</td>
                                        <td>{t._attributes.NumCta}</td>
                                        <td>{t._attributes.SubCtaDe}</td>
                                        <td>{t._attributes.CodAgrup}</td>
                                        <td>{t._attributes.Nivel}</td>
                                        <td>{t._attributes.Natur}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                )
                setViewReport(true);
            }
            else if (JSON.parse(data)["PLZ:Polizas"] !== undefined) {
                let data_ = JSON.parse(data)["PLZ:Polizas"];
                let _data_ieterate_ = data_["PLZ:Poliza"];
                setTitleXML(
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="text-success">Version</th>
                                <th className="text-success">{data_._attributes.Version}</th>
                                <th className="text-success">RFC</th>
                                <th className="text-success">{data_._attributes.RFC}</th>
                                <th className="text-success">Mes</th>
                                <th className="text-success">{data_._attributes.Mes}</th>
                            </tr>
                            <tr>
                                <th className="text-success">Año</th>
                                <th className="text-success">{data_._attributes.Anio}</th>
                                <th className="text-success">Tipo Solicitud</th>
                                <th className="text-success">{data_._attributes.TipoSolicitud}</th>
                                <th className="text-success"># Tramite</th>
                                <th className="text-success">{data_._attributes.NumTramite}</th>
                            </tr>
                        </thead>
                    </table>
                )
                setContainer(
                    <table className="table">
                        <tbody>
                            {
                                _data_ieterate_.map((t, g) => (
                                    <>
                                        <tr key={g}>
                                            <td className="text-info">{t._attributes.NumUnIdenPol}</td>
                                            <td className="text-info">{t._attributes.Fecha}</td>
                                            <td className="text-info">{t._attributes.Concepto}</td>
                                        </tr>
                                        {
                                            (t["PLZ:Transaccion"]) !== undefined ?
                                                (
                                                    <tr>
                                                        <td colSpan={3}>
                                                            <table className="table">
                                                                <tbody>
                                                                    {
                                                                        (t["PLZ:Transaccion"]).map((jk, h) => (
                                                                            <>
                                                                                <tr key={h}>
                                                                                    <td>{jk._attributes.NumCta}</td>
                                                                                    <td>{jk._attributes.DesCta}</td>
                                                                                    <td style={{ textAlign: 'right' }}>{jk._attributes.Debe}</td>
                                                                                    <td style={{ textAlign: 'right' }}>{jk._attributes.Haber}</td>
                                                                                </tr>
                                                                                {
                                                                                    (jk["PLZ:CompNal"]) !== undefined ? (
                                                                                        <>
                                                                                            {
                                                                                                (jk["PLZ:CompNal"]).length > 1 ? (
                                                                                                    <>
                                                                                                        <tr>
                                                                                                            <td colSpan={4}>
                                                                                                                <table className="table">
                                                                                                                    <tbody>
                                                                                                                        {(jk["PLZ:CompNal"]).map((ft, g) => (
                                                                                                                            <tr>
                                                                                                                                <td className="text-teal">{ft._attributes.UUID_CFDI}</td>
                                                                                                                                <td className="text-teal">{ft._attributes.RFC}</td>
                                                                                                                                <td className="text-teal">{ft._attributes.MontoTotal}</td>
                                                                                                                                <td className="text-teal">{ft._attributes.Moneda}</td>
                                                                                                                                <td className="text-teal">{ft._attributes.TipCamb}</td>
                                                                                                                            </tr>
                                                                                                                        ))
                                                                                                                        }
                                                                                                                    </tbody>
                                                                                                                </table>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <tr>
                                                                                                            <td colSpan={4}>
                                                                                                                <table className="table">
                                                                                                                    <tbody>
                                                                                                                        <tr>
                                                                                                                            <td className="text-teal">{(jk["PLZ:CompNal"])._attributes.UUID_CFDI}</td>
                                                                                                                            <td className="text-teal">{(jk["PLZ:CompNal"])._attributes.RFC}</td>
                                                                                                                            <td className="text-teal">{(jk["PLZ:CompNal"])._attributes.MontoTotal}</td>
                                                                                                                            <td className="text-teal">{(jk["PLZ:CompNal"])._attributes.Moneda}</td>
                                                                                                                            <td className="text-teal">{(jk["PLZ:CompNal"])._attributes.TipCamb}</td>
                                                                                                                        </tr>
                                                                                                                    </tbody>
                                                                                                                </table>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        </>
                                                                                    ) : null
                                                                                }
                                                                            </>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </td>

                                                    </tr>
                                                ) : null
                                        }
                                    </>
                                ))
                            }
                        </tbody>
                    </table>
                )
                setViewReport(true);
            }
        }
        setOpenBlock(false);
    }
    useEffect(() => {
        setOpenBlock(true);
        setTimeout(() => {
            setTitle('Documentos de ' + props.value.ejercicio + ' de ' + props.value.mes_descripcion);
            console.log(props)
            getData(props.value);
            setDataEmpresa(props.value);
        }, 500);
        setOpen(true);
        setContainer('');
        setTitleXML('');
        setViewReport(false);
    }, [props.value]);
    const getinformacion = (e) => {
        var info = {
            url: e,
            rfc: dataempresa.rfc,
            ejercicio: dataempresa.ejercicio
        }

        setOpenBlock(true);
        setTimeout(() => {
            getDataxml(info);
        }, 500);
    }
    return (
        <div>
            <Button variant="outlined" color="primary" onClick={handleClickOpen} style={{ display: "none" }}>Open dialog xmls</Button>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth={fullWidth} maxWidth={maxWidth}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose} style={{ textAlign: "center" }}><span style={{ color: 'white' }}>{title}</span> </DialogTitle>
                <DialogContent dividers>
                    <Backdrop className={classes.backdrop} open={statusblock}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <div className="content pt-0" style={{ color: 'white' }}>
                        <div className="row">
                            {
                                (!viewrreport) ? (
                                    <div className="col-md-12">
                                        <div className="card">
                                            <div className="card-header header-elements-inline">
                                                <h5 className="card-title">Listado de archivos</h5>
                                            </div>

                                            <div className="card-body">
                                                <ul className="media-list">
                                                    <li className="media font-weight-semibold py-1">Nombre de los archivos</li>

                                                    {
                                                        (infos || []).map((i, j) => (
                                                            <li className="media" key={j}>
                                                                {console.log(i)}
                                                                <div className="mr-3">
                                                                    <span className="btn bg-warning rounded-circle btn-icon btn-sm">
                                                                        <span className="letter-icon">{i.zip.substring(0, 1)}</span>
                                                                    </span>
                                                                </div>

                                                                <div className="media-body">
                                                                    <div className="media-title font-weight-semibold">{i.zip} </div>
                                                                </div>

                                                                <div className="align-self-center ml-3">
                                                                    <div className="list-icons list-icons-extended">
                                                                        <a className="list-icons-item" style={{ cursor: 'pointer' }} data-popup="tooltip" title="" data-toggle="modal" data-trigger="hover" data-target="#call" data-original-title="Call" onClick={() => getinformacion(i.directorio_zip)}>
                                                                            <i className="icon-file-xml mr-3 icon-2x text-success-300"></i>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="col-md-12">
                                        <div className="card">
                                            <div className="page-header">
                                                <div className="page-header-content header-elements-lg-inline">
                                                    <div className="page-title d-flex">
                                                        <a onClick={() => setViewReport(false)} style={{ color: 'white', cursor: 'pointer' }}> <h4><i className="icon-arrow-left52 mr-2"></i> <span className="font-weight-semibold">Listado de archivos</span></h4></a>
                                                        <a href="#" className="header-elements-toggle text-body d-lg-none"><i className="icon-more"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-header header-elements-inline">
                                                {titlexml}
                                            </div>
                                            <div className="card-body">
                                                {containerxml}
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}
