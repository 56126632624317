import React, { Component } from 'react';


class ViewRight extends Component {
    constructor(props) {
        super(props);
        this.state = {}

    }
    render() {
        return (
            <>
                <div className="timeline-row timeline-row-right">
                    <div className="timeline-icon">
                        <div className={"bg-danger-400"}>
                            <i className="icon-cross3  icon-2x"></i>
                        </div>
                    </div>
                    <div className="timeline-time" style={{ color: 'white' }}>{this.props.titulo}
                        <div className="text-muted"></div>
                    </div>

                    <div className="card border-left-3 border-left-danger rounded-left-0">
                        <div className="card-body">
                            <div className="d-sm-flex align-item-sm-center flex-sm-nowrap">
                                <div>
                                    <h6 className="font-weight-semibold" style={{ color: 'white' }}>{this.props.titulo}</h6>

                                    <ul className="media-list">
                                        {
                                            <li className="media">
                                                <div className="media-body">{this.props.details.obligacion} <br></br>
                                                    <a style={{ color: '#8dccff' }}>Estatus: {this.props.details.estatusobl}</a>
                                                    <br></br>
                                                    <span className="text-muted font-size-sm"> Fecha de presentación: {this.props.details.fecha_exacta.substr(0, 10)}</span>
                                                </div>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        );
    }
}

export default ViewRight;