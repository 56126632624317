import React, { Component } from 'react';
import api from '../../../../app/Config/api';
import { Tooltip, } from "@material-ui/core";
import ReactDatatable from '@ashvin27/react-datatable';
import Skeleton from '@material-ui/lab/Skeleton';

class PLZContables extends Component {
    constructor(props) {
        super(props);
        this.columns = [

            {
                key: "numero_unico_identificacion_poliza",
                text: "# Póliza",
                className: "numero_unico_identificacion_poliza",
                align: "left",
                sortable: true,
            }, {
                key: "fecha",
                text: "Fecha",
                className: "fecha",

            },
            {
                key: "concepto",
                text: "Concepto",
                className: "concepto",
                align: "left",
                sortable: true
            }
            ,
            {
                key: "debe",
                text: "Debe",
                className: "debe",
                cell: record => {
                    return (
                        <div className="text-right">

                            {
                                (record.type !== undefined) ? (
                                    <>
                                        {
                                            (record.debe === record.haber) ? (
                                                <p className="text-success" style={{ fontSize: 14 }}>{((record.debe) === null ? 0 : record.debe).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                                            ) :
                                                (
                                                    <p className="text-danger" style={{ fontSize: 14 }}>{((record.debe) === null ? 0 : record.debe).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                                                )
                                        }

                                    </>

                                ) :
                                    (
                                        <>
                                            {
                                                (record.debe === record.haber) ? (
                                                    <p style={{ fontSize: 14 }}>{((record.debe) === null ? 0 : record.debe).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                                                ) :
                                                    (<p className="text-danger" style={{ fontSize: 14 }}>{((record.debe) === null ? 0 : record.debe).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>)
                                            }

                                        </>
                                    )
                            }

                        </div>
                    );
                }
            },
            {
                key: "haber",
                text: "Haber",
                className: "haber",
                cell: record => {
                    return (
                        <div className="text-right">
                            {
                                (record.type !== undefined) ? (
                                    <>
                                        {
                                            (record.haber === record.debe) ? (
                                                <p className="text-success" style={{ fontSize: 14 }}>{((record.haber) === null ? 0 : record.haber).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                                            ) :
                                                (
                                                    <p className="text-danger" style={{ fontSize: 14 }}>{((record.haber) === null ? 0 : record.haber).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                                                )
                                        }

                                    </>
                                ) :
                                    (
                                        <>
                                            {
                                                (record.haber === record.debe) ? (
                                                    <p style={{ fontSize: 14 }}>{((record.haber) === null ? 0 : record.haber).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                                                ) :
                                                    (
                                                        <p className="text-danger" style={{ fontSize: 14 }}>{((record.haber) === null ? 0 : record.haber).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                                                    )
                                            }

                                        </>
                                    )
                            }

                        </div>
                    );
                }
            }];
        this.config = {
            key_column: 'numero_de_cuenta',
            page_size: 1000,
            length_menu: [1000, 2000, 5000],
            button: {
                excel: true,
                print: true,
                extra: false,
            },
            "language": {
                "length_menu": "Mostrar _MENU_ registros por página",
                "filter": "Buscar en registros ...",
                "info": "Mostrando _START_ a _END_ de _TOTAL_ entradas",
                "pagination": {
                    "first": "Primero",
                    "previous": "Previa",
                    "next": "próximo",
                    "last": "Última"
                }
            }
        }

        this.state = {
            recorset: [],
            skeleton: true,
            detailspol: [],
            viewdetail: false,
            debe: 0,
            haber: 0,
            devengados: [],
            realizados: []
        }
    }
    componentDidMount() {
        this.getdata();
    }
    getdata = async () => {
        let pareme = JSON.parse(localStorage.getItem("parameters"));
        let result_ = await api.post("getpolizascontables", pareme);
        let { data } = await result_;
        this.setState({ recorset: data.info, skeleton: false });
    }
    getdatadetails = async (i) => {
        let pareme = {
            poliza_id: i.poliza_id
        }
        let result_ = await api.post("getdetailspolizas", pareme);
        let { data } = await result_;
        let resultsumdebe = (data.info).reduce((total, currentValue) => total = total + currentValue.debe, 0);
        let resultsumhaber = (data.info).reduce((total, currentValue) => total = total + currentValue.haber, 0);

        this.props.title("Detalle de la póliza # " + i.numero_unico_identificacion_poliza);
        let devengados_ = await api.post("getdetailspolizasdevengados", pareme);
        let realizados_ = await api.post("getdetailspolizasrealizados", pareme);

        if ((devengados_.data).estatus === 200) {
            this.setState({ devengados: (devengados_.data).info });
        }
        if ((realizados_.data).estatus === 200) {
            this.setState({ realizados: (realizados_.data).info });
        }
        this.setState({ detailspol: data.info, skeleton: false, viewdetail: true, debe: resultsumdebe, haber: resultsumhaber });
    }
    rowClickedHandler = (event, data, rowIndex) => {
        this.setState({ skeleton: true });
        this.getdatadetails(data);
    }
    handleInit = () => {
        let pareme = JSON.parse(localStorage.getItem("parameters"));
        this.props.title("Póliza de  " + convertName(pareme.periodo) + " del " + pareme.ejercicio);
        this.setState({ skeleton: true });
        setTimeout(() => {
            this.setState({ skeleton: false, detailspol: [], realizados: [], devengados: [], viewdetail: false });
        }, 500);

    }
    render() {
        return (
            <>
                {
                    this.state.skeleton ? (
                        <div className="card">
                            <Skeleton />
                            <Skeleton animation={false} height={400} />
                            <Skeleton animation="wave" />
                        </div>
                    ) : (
                        <>
                            {
                                (this.state.viewdetail) ? (
                                    <div className="card">
                                        <div className="row">
                                            <div class="d-flex align-items-center" style={{ cursor: 'pointer' }} onClick={this.handleInit}>
                                                <i class="icon-arrow-left15 mr-3 icon-2x text-warning"></i>
                                                <div>Regresar <div class="opacity-50">Listado de pólizas</div></div>
                                            </div>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Cuenta contable</th>
                                                        <th>Descripción</th>
                                                        <th className="text-center">Debe</th>
                                                        <th className="text-center">Haber</th>
                                                        <th>Concepto</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        (this.state.detailspol || []).map((d, o) => (
                                                            <tr key={o}>
                                                                <td>{d.cuenta}</td>
                                                                <td>{d.descripcion}</td>
                                                                <td className="text-right">{((d.debe) === null ? 0 : d.debe).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                                <td className="text-right">{((d.haber) === null ? 0 : d.haber).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                                <td>{d.concepto}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td className="text-right text-success">{(this.state.debe).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                        <td className="text-right text-success">{(this.state.haber).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="card" >
                                            <div class="card-header header-elements-inline">
                                                <h6 class="card-title font-weight-light text-info">Devengados</h6>
                                            </div>
                                            <div class="card-body">
                                                <div className="row" style={{ overflowX: 'auto', width: 1000, whiteSpace: 'nowrap' }}>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>Comprobante</th>
                                                                <th>Identificador comprobante</th>
                                                                <th>Contribuyente</th>
                                                                <th>Monto</th>
                                                                <th>Moneda</th>
                                                                <th>Cambio</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                (this.state.devengados || []).map((d, o) => (
                                                                    <tr key={o}>
                                                                        <td className="text-right">{d.TipoComprobante}</td>
                                                                        <td>{d.IdentificadorComprobante}</td>
                                                                        <td>{d.IdentificadorContribuyente}</td>
                                                                        <td className="text-right">{(d.monto_total).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                                        <td>{d.moneda}</td>
                                                                        <td>{d.TipoCambio}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="card-header header-elements-inline">
                                                <h6 class="card-title font-weight-light text-info">Realizados</h6>
                                            </div>
                                            <div class="card-body">
                                                <div className="row" style={{ overflowX: 'auto', width: 1100, whiteSpace: 'nowrap' }}>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Pago</th>
                                                                <th scope="col">Fecha</th>
                                                                <th scope="col">Cuenta banco</th>
                                                                <th scope="col">Banco origen</th>
                                                                <th scope="col">Monto</th>
                                                                <th scope="col">RFC</th>
                                                                <th scope="col">Beneficiario</th>
                                                                <th scope="col">Banco extranjero</th>
                                                                <th scope="col">Cuenta destino</th>
                                                                <th scope="col">Banco destino nacional</th>
                                                                <th scope="col">Banco destino extranjero</th>
                                                                <th scope="col">Núm. Cheque</th>
                                                                <th scope="col">Moneda</th>
                                                                <th scope="col">Cambio</th>
                                                            </tr>
                                                        </thead>
                                                    </table>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                ) : (
                                    <ReactDatatable
                                        config={this.config}
                                        records={(this.state.recorset || [])}
                                        columns={this.columns}
                                        onRowClicked={this.rowClickedHandler}
                                    />
                                )
                            }

                        </>
                    )
                }
            </>
        );
    }
}

export default PLZContables;
function convertName(key) {
    switch (key) {
        case "01":
            return "Enero"
            break;
        case "02":
            return "Febrero"
            break;
        case "03":
            return "Marzo"
            break;
        case "04":
            return "Abril"
            break;
        case "05":
            return "Mayo"
            break;
        case "06":
            return "Junio"
            break;
        case "07":
            return "Julio"
            break;
        case "08":
            return "Agosto"
            break;
        case "09":
            return "Septiembre"
            break;
        case "10":
            return "Octubre"
            break;
        case "11":
            return "Noviembre"
            break;
        case "12":
            return "Diciembre"
            break;

    }
}