import React, { Component } from 'react';
import { Button, Snackbar, Typography, Card, CardContent, MenuItem, Grid, TextField } from '@material-ui/core';
import api from '../../Config/api';
import MuiAlert from '@material-ui/lab/Alert';
import { set } from 'date-fns';
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
class EditServicios extends Component {
    constructor(props) {
        super(props);
        var objectData = JSON.parse(localStorage.getItem("id_token"));
        this.state = {
            id: 0,
            descripcion: '',
            userRoles: [],
            data_info: [],
            usuario: objectData.usuario,
            success: false,
            error: false,
            message: '',
            vertical: 'top', horizontal: 'right'
        }
    }
    componentDidMount() {
        api.get('listProduct')
            .then(response => response.data)
            .then(data => {
                let teamsFromApi = data.map(team => {
                    return { value: team.id, label: team.nombre };
                });
                this.setState({ data_info: teamsFromApi });
            });
        api.post('listProductidModuloGroup', this.props.inf)
            .then(response => response.data)
            .then(data => {
                this.setState({
                    userRoles: data,
                    descripcion: this.props.inf.descripcion,
                    id: this.props.inf.id,
                })
                console.log(data);
            });
    }
    handleChange = (event) => {
        const value = event.target.value;
        this.setState({ descripcion: value });
    };
    handleClose = () => {
        api.post('UpdateSistemas', this.state)
            .then(response => response.data)
            .then(data => {
                if (data.status === 200) {
                    this.setState({
                        success: true,
                        message: data.message,
                        error: false,
                    });
                }
                else if (data.status === 404) {
                    this.setState({
                        success: false,
                        message: data.message,
                        error: true,
                    });
                }
            });
    }
    handleFieldChange = event => {
        console.log(event);
        event.persist();
        this.setState(formState => ({
            ...formState,
            [event.target.name]:
                event.target.type === "checkbox"
                    ? event.target.checked
                    : event.target.value
        }));
    };
    handleCloseMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({
            success: false,
            error: false,
        });
    };
    render() {
        const { vertical, horizontal } = this.state;
        return (<>
            <Card>
                <CardContent>
                    <Snackbar open={this.state.success} autoHideDuration={6000} onClose={this.handleCloseMessage} anchorOrigin={{ vertical, horizontal }}>
                        <Alert onClose={this.handleCloseMessage} severity="success">
                            {this.state.message}
                        </Alert>
                    </Snackbar>
                    <Snackbar open={this.state.error} autoHideDuration={6000} onClose={this.handleCloseMessage} anchorOrigin={{ vertical, horizontal }}>
                        <Alert onClose={this.handleCloseMessage} severity="error">
                            {this.state.message}
                        </Alert>
                    </Snackbar>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField id="descripcion" name="descripcion" onChange={this.handleChange} value={this.state.descripcion}
                                label="Descripción" type="text" margin="dense" fullWidth required variant="outlined" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField margin="dense"
                                fullWidth required variant="outlined"
                                select
                                name="userRoles"
                                id="userRoles"
                                variant="outlined"
                                label="Sistemas"
                                SelectProps={{
                                    multiple: true,
                                    value: this.state.userRoles,
                                    onChange: this.handleFieldChange
                                }}>
                                {(
                                    this.state.data_info).map((team, i) => (
                                        <MenuItem key={i} value={team.value}>{team.label}</MenuItem>
                                    ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={9}>
                        </Grid>
                        <Grid item xs={2}>
                            <Button autoFocus onClick={this.handleClose} variant="outlined" color="primary">Guardar</Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>);
    }
}

export default EditServicios;