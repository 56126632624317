import React, { Component } from "react";
import api from '../../../Config/api';
class Notes extends Component {
    constructor(props) {
        super(props)
        let objectinfo = JSON.parse(localStorage.getItem("_data_contact_"));
        this.state = {
            nota: '',
            prospecto_id: objectinfo.pros_id
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChangeSystem = (i) => {
        this.setState({ nota: i.target.value });
    }
    handleSubmit(event) {
        let form = new FormData();
        form.append("nota", this.state.nota)
        form.append("prospecto_id", this.state.prospecto_id)
        api.post("newnote", form).then((i) => {
            this.setState({ nota: '' });
            this.props.close(i.data);
        });

        event.preventDefault();
        event.target.reset();
    }
    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <div className="modal-body">
                    <div className="card">
                        <textarea className="form-control" required rows={3} value={this.state.nota} onChange={this.handleChangeSystem} name="nota" placeholder="Describe la nota"></textarea>
                    </div>
                </div>

                <div className="modal-footer">
                    <button type="button" className="btn btn-link" data-dismiss="modal">Cancelar</button>
                    <button type="submit" className="btn bg-primary">Guardar nota</button>
                </div>
            </form>
        );
    }
}

export default Notes;