import React, { Component } from 'react';
import api from '../../../Config/apifiles';
import { Tooltip, } from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
import ReactJson from 'react-json-view';
import ReactDatatable from '@ashvin27/react-datatable';
import { PacmanLoader } from "react-spinners";
function orderbydate(a, b) {
    return new Date(a.fecha).getTime() - new Date(b.fecha).getTime();
}
function validate_type(t) {
    switch (t) {
        case "003":
            return "Localizado en definitivo";
            break;
        case "002":
            return "Localizado en presuntos";
            break;
        case "001":
            return "Localizado la lista 'No localizados'";
            break;
        case "004":
            return "Localizado en desvirtuado'";
            break;
        default:
            break;
    }
}
///Emitido
class Cast_Work_Paper extends Component {
    constructor(props) {
        super(props);
        let objects = JSON.parse(localStorage.getItem("datacompanyall"));
        this.columns = [
            {
                key: "fecha",
                text: "Fecha",
                className: "name",
                cell: record => {
                    return (
                        <div>
                            {record.alert.length > 0 ? (
                                <>
                                    <Tooltip title={validate_type(record.alert[0].tipo)} placement="top" arrow>
                                        <div className={"text-center text-danger"} style={{ cursor: 'pointer' }}>
                                            {record.fecha}
                                        </div>
                                    </Tooltip>
                                </>
                            ) : <div className={"text-center"}>
                                {record.fecha}</div>
                            }
                        </div>
                    );
                }
            },
            {
                key: "tipo_de_comprobante",
                text: "Clase CFDI",
                cell: record => {
                    return (
                        <div>
                            {record.alert.length > 0 ? (
                                <>
                                    <Tooltip title={validate_type(record.alert[0].tipo)} placement="top" arrow>
                                        <div className={"text-center text-danger"} style={{ cursor: 'pointer' }}>
                                            {tipoComprobante(record.tipo_de_comprobante)}
                                        </div>
                                    </Tooltip>
                                </>
                            ) : <div className={"text-center"}>
                                {tipoComprobante(record.tipo_de_comprobante)}</div>
                            }
                        </div>
                    );
                }
            },

            /*{
                key: "isr",
                text: "ISR",
                className: "postcode",
                cell: y => {
                    return (
                        <div>
                            {y.alert.length > 0 ? (
                                <>
                                    <Tooltip title={validate_type(y.alert[0].tipo)} placement="top" arrow>
                                        <div className={"text-center text-danger"} style={{ cursor: 'pointer' }}>
                                            {y.isr.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                        </div>
                                    </Tooltip>
                                </>
                            ) : <div className={"text-center"}>
                                {y.isr.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</div>
                            }
                        </div>
                    );
                }
            },*/
            {
                key: "sub_total",
                text: "Importe neto",
                className: "postcode",
                cell: y => {
                    return (
                        <div>
                            {y.alert.length > 0 ? (
                                <>
                                    <Tooltip title={validate_type(y.alert[0].tipo)} placement="top" arrow>
                                        <div className={"text-center text-danger"} style={{ cursor: 'pointer' }}>
                                            {y.sub_total.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                        </div>
                                    </Tooltip>
                                </>
                            ) : <div className={"text-center"}>
                                {y.sub_total.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                            </div>
                            }
                        </div>
                    );
                }
            }, {
                key: "iva",
                text: "IVA",
                className: "postcode",
                cell: y => {
                    return (
                        <div>
                            {y.alert.length > 0 ? (
                                <>
                                    <Tooltip title={validate_type(y.alert[0].tipo)} placement="top" arrow>
                                        <div className={"text-center text-danger"} style={{ cursor: 'pointer' }}>
                                            {y.iva.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                        </div>
                                    </Tooltip>
                                </>
                            ) : <div className={"text-center"}>
                                {y.iva.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</div>
                            }
                        </div>
                    );
                }
            },
            {
                key: "total",
                text: "Total",
                className: "postcode",
                cell: y => {
                    return (
                        <div>
                            {y.alert.length > 0 ? (
                                <>
                                    <Tooltip title={validate_type(y.alert[0].tipo)} placement="top" arrow>
                                        <div className={"text-center text-danger"} style={{ cursor: 'pointer' }}>
                                            {y.total.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                        </div>
                                    </Tooltip>
                                </>
                            ) : <div className={"text-center"}>
                                {y.total.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                            </div>
                            }
                        </div>
                    );
                }
            },
            {
                key: "emisor",
                text: "RFC     -     Nombre",
                className: "action",
                cell: y => {
                    return (
                        <div>
                            {y.alert.length > 0 ? (
                                <>
                                    <Tooltip title={validate_type(y.alert[0].tipo)} placement="top" arrow>
                                        <div className={"text-center text-danger"} style={{ cursor: 'pointer' }}>
                                            {dataNameEmisor(y.emisor)}
                                        </div>
                                    </Tooltip>
                                </>
                            ) : <div className={"text-center"}>
                                {dataNameEmisor(y.emisor)}
                            </div>
                            }
                        </div>
                    );
                }
            },

            {
                cell: y => {
                    return (
                        <div>
                            {y.alert.length > 0 ? (
                                <>
                                    <div className="text-center text-danger">
                                        <a style={{ cursor: 'pointer' }} onClick={() => this.viewpdfcfdi_(y)}><Tooltip title="Click para ver cfdi completo" placement="top" arrow><span className="badge badge-flat  text-danger"><i className="icon-file-pdf"></i></span></Tooltip></a>
                                        &nbsp;
                                        <a style={{ cursor: 'pointer' }} onClick={() => this.handleLoadXML(y)}><Tooltip title="Click para ver el xml completo" placement="top" arrow><span className="badge badge-flat  text-danger"><i className="icon-file-xml"></i></span></Tooltip></a>
                                    </div>
                                </>
                            ) : <div className="text-center">
                                <a style={{ cursor: 'pointer' }} onClick={() => this.viewpdfcfdi_(y)}><Tooltip title="Click para ver cfdi completo" placement="top" arrow><span className="badge badge-flat  text-success"><i className="icon-file-pdf"></i></span></Tooltip></a>
                                &nbsp;
                                <a style={{ cursor: 'pointer' }} onClick={() => this.handleLoadXML(y)}><Tooltip title="Click para ver el xml completo" placement="top" arrow><span className="badge badge-flat  text-success"><i className="icon-file-xml"></i></span></Tooltip></a>
                            </div>
                            }
                        </div>

                    );
                }
            }
        ];
        this.config = {
            "key_column": '_id_',
            "page_size": 50,
            "length_menu": [50, 70, 90],
            "show_filter": true,
            "show_pagination": true,
            "button": {
                "excel": false,
                "print": false,
                "extra": false
            },
            "language": {
                "length_menu": "Mostrar _MENU_ registros por página",
                "filter": "Buscar en registros ...",
                "info": "Mostrando _START_ a _END_ de _TOTAL_ entradas",
                "pagination": {
                    "first": "Primero",
                    "previous": "Previa",
                    "next": "próximo",
                    "last": "Última"
                }
            }
        };
        this.state = {
            empresa_id: objects.id,
            rfc: objects.rfc,
            exercise: parseInt(this.props.data.ejercicio),
            period: this.props.data.periodo,
            data: [],
            link: '',
            uuid: '',
            loading: false,
            loadingfirst: true,
            subtotal: 0,
            total: 0,
            iva: 0,
            isr: 0,
            cfdi: '',
            filterall: []
        }
    }
    componentDidMount() {
        this.list_array_filter_exercise_period_();
    }
    list_array_filter_exercise_period_ = async () => {
        let _data_ = await api.post('cfdis-recibidos-ejercicio-periodo',
            { empresa_id: this.state.empresa_id, ejercicio: this.state.exercise, periodo: this.state.period });
        let _b69list_ = await api.post("app-relations-69b-exercise-period-recibidos",
            { empresa_id: this.state.empresa_id, ejercicio: this.state.exercise, periodo: this.state.period })
        let filter_b69list_ = _b69list_.data;
        /// console.log(filter_b69list_)
        let _result_data_ = await (_data_.data).filter((tf) => (tf.tipo_de_comprobante === 'I' || tf.tipo_de_comprobante === 'egreso' || tf.tipo_de_comprobante === 'E' || tf.tipo_de_comprobante === 'ingreso'));
        let sumsubtotal = 0, sumdescuento = 0, sumiva = 0, sumisr = 0;
        //console.log(_result_data_)
        for (let p = 0; p < _result_data_.length; p++) {
            _result_data_[p]._id_ = String('120192012901290' + p);
            /* if (_result_data_[p].tipo_de_comprobante === 'E' || _result_data_[p].tipo_de_comprobante === 'egreso') {
 
                 _result_data_[p].import = (_result_data_[p].sub_total * -1);
                 _result_data_[p].sub_total = (_result_data_[p].sub_total * -1);
                 _result_data_[p].descuento = (_result_data_[p].descuento * -1);
                 _result_data_[p].import_descuento = (_result_data_[p].descuento * -1);
             }
             else {
                 _result_data_[p].import = _result_data_[p].sub_total;
                 _result_data_[p].import_descuento = (_result_data_[p].descuento);
             }*/

            _result_data_[p].alert = ((filter_b69list_).filter((fds) => String(fds.uuid).toUpperCase() == String(_result_data_[p].uuid).toUpperCase()))

            sumsubtotal = sumsubtotal + _result_data_[p].sub_total;
            sumdescuento = sumdescuento + _result_data_[p].total;
            sumiva = sumiva + _result_data_[p].iva;
            sumisr = sumisr + _result_data_[p].isr;
        }
        setTimeout(async () => {
            let _reult_data_ = (_result_data_).filter((h) => h.tipo_de_comprobante !== 'P');

            this.setState({
                data: _reult_data_.sort(orderbydate), loadingfirst: false,
                subtotal: sumsubtotal.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
                total: sumdescuento.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
                iva: sumiva.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
                isr: sumisr.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
                filterall: _result_data_
            })
        }, 1900);

    }
    viewpdfcfdi_ = async (t) => {
        window.$('#viewpdfcfdi').modal('show');
        this.setState({
            loading: true
        })
        let _link_ = "";
        if (t.tipo_de_comprobante === "N")
            _link_ = 'https://e-xpertum.com.mx/adminfile/api/genera-pdf-cfdi-nomina/' + t.listado_cfdi_recibido_id + '/nomina';
        else
            _link_ = 'https://e-xpertum.com.mx/adminfile/api/genera_pdf_cfdi/' + t.listado_cfdi_recibido_id + '/RECIBIDO&' + this.state.rfc

        this.setState({
            link: _link_,
            uuid: t.uuid
        });
    }
    handleLoading = async () => {
        this.setState({
            loading: false
        })
    }
    handleLoadXML = async (t) => {
        window.$("#viewxmlcfdi").modal("show");
        let _data_ = await api.post('genera_xml_cfdi', { id: t.listado_cfdi_recibido_id, tipo: 'RECIBIDO' });
        this.setState({
            uuid: t.uuid,
            cfdi: <ReactJson src={JSON.parse(_data_.data)} enableClipboard={false} theme={"monokai"} displayDataTypes={false} displayObjectSize={false} />
        })

    }
    handleFilterretenciones = async () => {
        //console.log('okisssssssssssssss');
        let _result_data_ = (this.state.filterall).filter((t) => parseFloat(t.iva) > 0);
        ///console.log(_result_data_)
        if (_result_data_.length > 0) {
            let sumsubtotal = 0, sumdescuento = 0, sumiva = 0, sumisr = 0;
            for (let p = 0; p < _result_data_.length; p++) {
                sumsubtotal = sumsubtotal + _result_data_[p].sub_total;
                sumdescuento = sumdescuento + _result_data_[p].total;
                sumiva = sumiva + _result_data_[p].iva;
                sumisr = sumisr + _result_data_[p].isr;
            }
            this.setState({
                data: _result_data_,
                subtotal: sumsubtotal.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
                total: sumdescuento.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
                iva: sumiva.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
                isr: sumisr.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
            })
        }
        else
            this.setState({
                data: [],
                subtotal: 0.00,
                total: 0.00,
                iva: 0.00,
                isr: 0.00,
            })
    }
    viewalldata = async () => {
        let _result_data_ = this.state.filterall
        let sumsubtotal = 0, sumdescuento = 0, sumiva = 0, sumisr = 0;

        for (let p = 0; p < _result_data_.length; p++) {
            sumsubtotal = sumsubtotal + _result_data_[p].sub_total;
            sumdescuento = sumdescuento + _result_data_[p].total;
            sumiva = sumiva + _result_data_[p].iva;
            sumisr = sumisr + _result_data_[p].isr;
        }
        this.setState({
            data: _result_data_,
            subtotal: sumsubtotal.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
            total: sumdescuento.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
            iva: sumiva.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
            isr: sumisr.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
        });
    }
    handleexportexcel = async () => {
        window.open('https://e-xpertum.com.mx/adminfile/api/export-excel-cfdis-recibidos/' + this.state.empresa_id + '/' + this.state.exercise + '/' + this.state.period);
    }
    render() {
        return (
            <>
                {
                    (this.state.loadingfirst) ? (
                        <div>
                            <Skeleton variant="text" />
                            <div style={{
                                margin: 40,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <PacmanLoader color={"#ff7043"} loading={this.state.loadingfirst} size={25} margin={2} />
                            </div>
                            <Skeleton variant="circle" animation="wave" width={40} height={40} />
                            <Skeleton variant="rect" animation="wave" style={{ width: "100%" }} height={118} />
                        </div>
                    ) : (
                        <>
                            <div className="form-group">
                                <div className="border p-3 rounded">
                                    <div className="custom-control custom-radio custom-control-inline">
                                        <input type="radio" className="custom-control-input" name="cr-i-l" id="filterretencionesRecibidos" onClick={this.handleFilterretenciones} />
                                        <label className="custom-control-label" htmlFor="filterretencionesRecibidos">Solo CFDIs con IVA retenido</label>
                                    </div>
                                    <div className="custom-control custom-radio custom-control-inline">
                                        <input type="radio" className="custom-control-input" name="cr-i-l" id="filterallRecibidos" defaultChecked={true} onClick={this.viewalldata} />
                                        <label className="custom-control-label" htmlFor="filterallRecibidos">Todos los CFDIs</label>
                                    </div>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <button type="button" className="btn btn-light" onClick={this.handleexportexcel}><i className="icon-file-excel mr-2 text-success"></i> Excel</button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <a type="button" className="btn btn-light" href={this.props.functionHandle} onClick={this.props.functionHandle}><i className="icon-file-xml mr-2 text-success"></i>Metadata</a>
                                </div>
                            </div>
                            <table className="table" style={{ width: '90%' }}>
                                <thead>
                                    <tr>
                                        <td>Importe neto: {this.state.subtotal}</td>
                                        <td>IVA: {this.state.iva}</td>
                                        <td>Total: {this.state.total}</td>
                                    </tr>
                                </thead>
                            </table>
                            <ReactDatatable
                                config={this.config}
                                records={this.state.data}
                                columns={this.columns}
                            />
                        </>
                    )
                }

                <div id="viewpdfcfdi" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                    <div className="modal-dialog modal-full">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{this.state.uuid}</h5>
                                <button type="button" className="close" data-dismiss="modal" onClick={() => this.setState({ link: '' })}>×</button>
                            </div>
                            <div className="modal-body">
                                <div className="embed-container">
                                    {
                                        (this.state.loading) ?
                                            (
                                                <div>
                                                    <Skeleton variant="text" />
                                                    <Skeleton variant="circle" animation="wave" width={40} height={40} />
                                                    <Skeleton variant="rect" animation="wave" width={510} height={118} />
                                                </div>
                                            ) : null
                                    }
                                    <iframe src={this.state.link} onLoad={this.handleLoading}
                                        width="100%"
                                        frameBorder="0" allowFullScreen
                                    >
                                    </iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="viewxmlcfdi" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                    <div className="modal-dialog modal-full">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{this.state.uuid}</h5>
                                <button type="button" className="close" data-dismiss="modal" onClick={() => this.setState({ link: '' })}>×</button>
                            </div>
                            <div className="modal-body">
                                <div>
                                    {
                                        (this.state.loading) ?
                                            (
                                                <div>
                                                    <Skeleton variant="text" />
                                                    <Skeleton variant="circle" animation="wave" width={40} height={40} />
                                                    <Skeleton variant="rect" animation="wave" width={510} height={118} />
                                                </div>
                                            ) : null
                                    }
                                    {this.state.cfdi}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Cast_Work_Paper;

function tipoComprobante(key) {
    switch (key) {
        case 'I':
            return 'I - Ingreso'
            break;
        case 'E':
            return 'E - Egreso'
            break;
        case 'T':
            return 'T - Traslado'
            break;
        case 'P':
            return 'P - Recepción de Pagos'
            break;
        case 'N':
            return 'N - Nómina'
            break;
        case 'R':
            return 'Retenciones e Información de Pagos'
            break;
        default:
            return key
            break;
    }
}
function dataNameEmisor(op) {
    if (op != null && op != '') {
        let _infor_ = JSON.parse(op);
        return (
            <p style={{ fontSize: 10 }}>{(_infor_["@Rfc"] === undefined ? (_infor_["@rfc"] == undefined ? _infor_.rfc : _infor_["@rfc"]) : _infor_["@Rfc"])} - {(_infor_["@Nombre"] === undefined ? (_infor_["@nombre"] == undefined ? ((_infor_.name) != undefined ? (_infor_.name).substr(0, 40) : '') : _infor_["@nombre"]) : _infor_["@Nombre"])}</p>
            ///<p style={{ fontSize: 10 }}>{(_infor_["@Rfc"] === undefined ? _infor_["@rfc"] : _infor_["@Rfc"])} - {(_infor_["@Nombre"] === undefined ? _infor_["@nombre"] : _infor_["@Nombre"])}</p>
        )
    }
    else
        return (<p></p>)

}