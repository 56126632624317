import React, { useState, useEffect } from "react";
import { Document, Page } from "react-pdf";
import Documents from '../../../app/Config/config.json';
import { Scrollbars } from 'react-custom-scrollbars';
import ShadowScrollbars from './Scroll';
export default function AllPages(props) {
  const [numPages, setNumPages] = useState(null);
  const [urlfile, setUrlDirectory] = useState('')
  useEffect(() => {
    setUrlDirectory(Documents.directoriodownload.download + '/getfederales?query=' + props.url);

  }, [props.url]);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <ShadowScrollbars style={{ height: 500, color: 'white' }}>
      <Document file={urlfile} options={{ workerSrc: "/pdf.worker.js" }} onLoadSuccess={onDocumentLoadSuccess}
        loading={"Cargando documento PDF...."} >
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} width={800} />
        ))}
      </Document>
    </ShadowScrollbars>
  );
}
