import axios from 'axios';

const invoce = axios.create({
    //baseURL: 'http://localhost:3004/api'
    //baseURL: 'http://74.208.137.47:3004/api'
    baseURL: 'https://e-xpertum.com.mx/invoces/api'
});

export default invoce;

