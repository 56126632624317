import axios from 'axios';

const api = axios.create({
    //baseURL: 'http://localhost:3020/juridico'
    baseURL: 'https://e-xpertum.com.mx/adminfile/juridico/'
    //baseURL: 'http://74.208.137.47:3020/nomina'
});

export default api;

