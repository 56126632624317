function createTreeEdoResult(k) {
    let _result_ = [];
    let firts_iterate_ = 10000, second_iterate = 100000;
    let header = k.length > 0 ? k[0] : [];
    let titles = ['id'];
    if (k.length > 0) {
        for (const [key] of Object.entries(header)) {
            titles.push(key);
        }
        titles.push('nodes');
    }
    console.log(titles);
    if (titles.length > 2 && k.length > 0) {
        for (let w = 0; w < k.length; w++) {
            let dataLevelFirst = [];
            if (k[w].children !== undefined && k[w].children.length > 0) {
                let levelfirst = k[w].children;
                for (let z = 0; z < levelfirst.length; z++) {
                    let leveltwo = levelfirst[z].children;
                    let dataLevelTwo = [];
                    if (levelfirst[z].children !== undefined && leveltwo.length) {
                        for (let y = 0; y < leveltwo.length; y++) {
                            let resulta_ = _parsedata(titles, leveltwo[y], second_iterate);
                            dataLevelTwo.push(resulta_);
                            second_iterate++;
                        }
                    }
                    if (dataLevelTwo.length > 0) {
                        let lg_ = _setnodes_(titles, levelfirst[z], dataLevelTwo, firts_iterate_);
                        dataLevelFirst.push(lg_);
                    }
                    else {
                        let resulta_ = _parsedata(titles, levelfirst[z], firts_iterate_);
                        dataLevelFirst.push(resulta_);
                    }
                    firts_iterate_++;
                }
            }
            if (dataLevelFirst.length > 0) {
                let lg_ = _setnodes_(titles, k[w], dataLevelFirst, w);
                _result_.push(lg_);
            }
            else {
                let resulta_ = _parsedata(titles, k[w], w);
                _result_.push(resulta_)
            }
        }
        return _result_;
    } else
        return _result_;
}
const _parsedata = (titles, levelthree, i) => {
    const obj = titles.reduce((acc, key) => {
        if (key === "id") {
            acc[key] = i;
        }
        else { acc[key] = levelthree[key]; }
        return acc;
    }, {});
    return obj;
}
const _setnodes_ = (titles, data, nodes, i) => {
    const obj = titles.reduce((acc, key) => {
        if (key === "id") {
            acc[key] = i
        }
        else if (key === "nodes") {
            acc[key] = nodes;
        }
        else {
            acc[key] = data[key];
        }
        return acc;
    }, {});
    return obj;
}
function createTreeBalances(k) {
    console.log('no mamas aqui??');
    try {
        let _result_ = [];
        let firts_iterate_ = 10000, second_iterate = 100000, three_iterate = 1000000;
        let header = k.length > 0 ? k[0] : [];
        let titles = ['id'];
        if (k.length > 0) {
            for (const [key] of Object.entries(header)) {
                titles.push(key);
            }
            titles.push('nodes');
        }
        if (titles.length > 2 && k.length > 0) {
            for (let w = 0; w < k.length; w++) {
                let dataLevelFirst = [];
                if (k[w].children !== undefined && k[w].children.length > 0) {
                    let levelfirst = k[w].children;
                    for (let z = 0; z < levelfirst.length; z++) {
                        let leveltwo = levelfirst[z].children;
                        let dataLevelTwo = [];
                        if (levelfirst[z].children !== undefined && leveltwo.length) {
                            for (let y = 0; y < leveltwo.length; y++) {
                                let levelthree = leveltwo[y].children;
                                let datalevelthree = [];
                                if (leveltwo[y].children !== undefined && levelthree.length > 0) {
                                    for (let q = 0; q < levelthree.length; q++) {
                                        let resulta_ = _parsedata(titles, levelthree[q], three_iterate);
                                        datalevelthree.push(resulta_);
                                        three_iterate++;
                                    }
                                }
                                if (datalevelthree.length > 0) {
                                    let lg_ = _setnodes_(titles, leveltwo[y], datalevelthree, second_iterate);
                                    dataLevelTwo.push(lg_);
                                }
                                else {
                                    let resulta_ = _parsedata(titles, leveltwo[y], second_iterate);
                                    dataLevelTwo.push(resulta_);
                                }
                                second_iterate++;
                            }
                        }
                        if (dataLevelTwo.length > 0) {
                            let lg_ = _setnodes_(titles, levelfirst[z], dataLevelTwo, firts_iterate_);
                            dataLevelFirst.push(lg_);
                        }
                        else {
                            let resulta_ = _parsedata(titles, levelfirst[z], firts_iterate_);
                            dataLevelFirst.push(resulta_);
                        }
                        firts_iterate_++;
                    }
                }
                if (dataLevelFirst.length > 0) {
                    let lg_ = _setnodes_(titles, k[w], dataLevelFirst, w);
                    _result_.push(lg_);
                }
                else {
                    let resulta_ = _parsedata(titles, k[w], w);
                    _result_.push(resulta_);
                }
            }
            return _result_;
        }
        else
            return _result_;
    } catch (E) {
        console.log(E);
    }

}
export { createTreeEdoResult, createTreeBalances };