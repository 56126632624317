import React, { Component, Fragment } from "react";
import ReactDatatable from '@ashvin27/react-datatable';
import FilterPolices from './invoce-polices'
import { PacmanLoader } from "react-spinners";
import { Tooltip } from '@material-ui/core';
import api from '../../../../app/Config/api';
let _months_ = ["", "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
function getDate(params) {
    let _ = params.split('-');
    return (_[2] + ' de ' + _months_[parseInt(_[1])] + ' de ' + _[0]);
}
class listApp extends Component {
    constructor(props) {
        super(props)
        let companyr = JSON.parse(localStorage.getItem("_data_info_empresa_"));
        this.columns = [
            {
                key: "fecha",
                text: "Fecha",
                className: "fecha",
                align: "left",
                sortable: true,
            },
            {
                key: "nombre",
                text: "Nombre, Denominación o razón social",
                className: "name",
                align: "left",
                sortable: true,
            },
            {
                key: "rfc",
                text: "RFC",
                className: "address",
                align: "left",
                sortable: true
            },
            {
                key: "estatus",
                text: "Estatus",
                className: "text-center",
                align: "center",
                sortable: true,
                cell: (record, index) => {
                    return (
                        <Fragment>
                            <label>{(record.estatus)}</label>
                        </Fragment>
                    );
                }
            },
            {
                key: "total",
                text: "Total",
                className: "text-right",
                sortable: true,
                align: "center",
                cell: (record, index) => {
                    return (
                        <Fragment>
                            <label>{(record.total).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</label>
                        </Fragment>
                    );
                }
            },
            {
                key: "fecha_publicada",
                text: "Fecha de publicación",
                className: "text-center",
                sortable: true,
                align: "center",
                cell: (record, index) => {
                    return (
                        <Fragment>
                            {
                                (record.fecha_publicada) !== null ? (
                                    <label>{getDate(record.fecha_publicada.substr(0, 10))}</label>
                                ) : null
                                ///    console.log(record.fecha_publicada.length)
                            }
                        </Fragment>
                    );
                }
            },
            {
                key: "poliza",
                text: "Pólizas",
                className: "text-center",
                sortable: true,
                align: "center",
                cell: (record, index) => {
                    return (
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            {
                                record.poliza === "SI" ? (
                                    <Tooltip placement="top" arrow title={"Factura localizada en las pólizas, click para ver detalles"}>
                                        <div class="d-flex align-items-center" style={{ cursor: 'pointer' }} onClick={() => this.handleFilterpolices(record)}>
                                            <i class="icon-file-xml2 mr-3 text-danger"></i>
                                        </div>
                                    </Tooltip>
                                ) :
                                    (<Tooltip placement="top" arrow title={"No ha sido localizado en las pólizas"}>
                                        <div class="d-flex align-items-center" style={{ cursor: 'pointer' }} >
                                            <i class="icon-file-xml2 mr-3 text-success"></i>
                                        </div>
                                    </Tooltip>
                                    )
                            }
                        </div>
                    );
                }
            }

        ];
        this.config = {
            filename: companyr.cliente_id + "_" + companyr.rfc,
            page_size: 10,
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,
            button: {
                excel: true,
                print: true,
            },
            language: {
                length_menu: "Mostrar _MENU_ registros por página",
                filter: "Buscar en registros ...",
                info: "Mostrando _START_ a _END_ de _TOTAL_ entradas",
                pagination: {
                    first: "Primero",
                    previous: "Previa",
                    next: "próximo",
                    last: "Última"
                }
            },
            no_data_text: "No se encontraron registros"
        }

        this.state = {
            records: [],
            cliente_id: '',
            rfc: '',
            loading: true,
            detallepolices: false,
            viewfilter: ''
        }
        this.extraButtons = [
            {
                className: "btn btn-primary buttons-pdf",
                title: "Export TEst",
                children: [
                    <span>
                        <i className="glyphicon glyphicon-print fa fa-print" aria-hidden="true"></i>
                    </span>
                ],
                onClick: (event) => {
                    console.log(event);
                },
            },
            {
                className: "btn btn-primary buttons-pdf",
                title: "Export TEst",
                children: [
                    <span>
                        <i className="glyphicon glyphicon-print fa fa-print" aria-hidden="true"></i>
                    </span>
                ],
                onClick: (event) => {
                    console.log(event);
                },
                onDoubleClick: (event) => {
                    console.log("doubleClick")
                }
            },
        ]
    }
    componentDidMount() {
        let company = JSON.parse(localStorage.getItem("_data_info_empresa_"));
        this.setState({ cliente_id: company.cliente_id, rfc: company.rfc });
        this.getData();
    }
    async getData() {
        const data = JSON.parse(localStorage.getItem("_data_info_empresa_"));
        if (data.tipo_validacion === "EFIRMA") {
            var contenidoFilter = {
                ejercicio: this.props.info.ejercicio,
                tipo: this.props.info.tipo,
                empresa_id: data.id
            }

            let response = await api.post('listadetailsmetadatafiltro', contenidoFilter);
            ///console.log(response)
            var informacion_uuid = response.data;
            for (let index = 0; index < informacion_uuid.length; index++) {
                informacion_uuid[index].id = index;
            }
            setTimeout(() => {
                this.setState({
                    records: informacion_uuid,
                    loading: false
                });
            }, 3000);

            ///});
        }
        else if (data.tipo_validacion === "IMPORTACION") {
            var contenidoFilter = {
                ejercicio: this.props.info.ejercicio,
                tipo: this.props.info.tipo,
                empresa_id: data.id
            }
            let response = await api.post('listadetailscfdisfiltro', contenidoFilter);
            // api.post('listadetailscfdisfiltro', contenidoFilter).then((response) => {
            var informacion_uuid = response.data;
            for (let index = 0; index < informacion_uuid.length; index++) {
                informacion_uuid[index].id = index;
            }
            this.setState({
                records: informacion_uuid
            });
            //});
        }
    }
    handleViewListCompo = () => {
        this.setState({
            detallepolices: false,
            viewfilter: ''
        })
    }
    handleFilterpolices = async (d) => {
        this.setState({
            detallepolices: true,
            viewfilter: <FilterPolices data={d} hiddeview={this.handleViewListCompo} />
        })
    }

    render() {
        return (
            <>
                {
                    this.state.loading ?
                        (
                            <div style={{
                                margin: 40,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <PacmanLoader color={"#ff7043"} loading={this.state.loading} size={25} margin={2} />
                            </div>
                        ) :
                        (
                            <>{
                                this.state.detallepolices ? (
                                    <>
                                        {this.state.viewfilter}
                                    </>
                                ) :
                                    (
                                        <ReactDatatable
                                            config={this.config}
                                            records={this.state.records}
                                            columns={this.columns}
                                            extraButtons={this.extraButtons}
                                        />
                                    )
                            }
                            </>
                        )
                }
            </>
        );
    }
}

export default listApp;