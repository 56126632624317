import React, { useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Grid, Paper, Select, FormControl, InputLabel, MenuItem, DialogActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
/*******
 * LIBRARY UPLOAD FILES
 */
import * as ALLIcons from '@material-ui/icons';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
/*******
 * CONFIG CONEXION
 */
import api from '../../../app/Config/api';
/**************
 * NOTIFICATIONS
 */
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    cards: {
        minWidth: 275,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    }, paperop: {
        padding: theme.spacing(2),
        textAlign: 'right',
        color: theme.palette.text.secondary,
    },
    alinearencabezado: {
        textAlign: 'center',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 120,
        color: '#000',
    },
}));
var ejercicio_array = []
function getcreateanios() {
    ejercicio_array = [];
    var anio = new Date();
    for (let index = 2019; index <= anio.getFullYear(); index++) {
        ejercicio_array.push({ value: index })
    }
}
export default function ResponsiveDialog(obj) {
    getcreateanios();
    let year_ = new Date();
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [estatus, setAbrir] = useState(false);
    const [value, setAge] = useState((year_).getFullYear());
    const [messageall, setOpenMessage] = useState({
        success: false,
        vertical: 'top',
        horizontal: 'center',
        error: false,
        message: ''
    });
    const { vertical, horizontal, success, error, message } = messageall;
    //var contenido_ = obj.informacion;
    //var result_ = contenido_.infos;
    var objec = JSON.parse(localStorage.getItem('id_token'));
    const [info] = useState({
        empresa_id: 0,
        rfc: '',
        ejercicio: 0,
        cliente_id: objec.cliente_id
    });
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

    /*const handleClickOpen = () => {
        setOpen(true);
    };*/
    const handleClickOpenNueva = () => {
        setAbrir(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCerrar = () => {
        setAbrir(false);
    };
    /************
     * procesos para subir el archivo
     */
    const [datafile, setFile] = useState({
        file: '',
        namefile: 'Seleccione la Constancia de Situación Fiscal',
        open: false
    });
    const el = useRef();
    const handleChangeFile = (e) => {
        const file = e.target.files[0];
        if (file !== undefined) {
            const formData = new FormData();
            formData.append('file', file)
            formData.append('ejercicio', value)
            formData.append('cliente_id', info.cliente_id)
            api.post('/constancia', formData).then(res => {
                var containers = res.data;
                if (containers.status === 200) {
                    setFile({
                        open: false,
                        file: '',
                        namefile: 'Seleccione la Constancia de Situación Fiscal',
                    })
                    setOpenMessage({
                        success: true,
                        vertical: 'top',
                        horizontal: 'right',
                        error: false,
                        message: containers.message
                    });
                    el.current.value = "";
                    var contenido_ = obj.informacion;
                    contenido_.informacionObtenida(false);
                    //resultData.informacion('Hola mundo....');
                }
                else if (containers.status === 404) {
                    setFile({
                        open: false,
                        file: '',
                        namefile: 'Seleccione la Constancia de Situación Fiscal',
                    })
                    setOpenMessage({
                        success: false,
                        vertical: 'top',
                        horizontal: 'right',
                        error: true,
                        message: containers.message
                    });
                    el.current.value = "";
                    setTimeout(() => {
                        setOpenMessage({
                            error: false
                        });
                    }, 4000);
                }

            }).catch(err => console.log(err));
            /*setFile({ file: file, namefile: file.name, open: false });
            setTimeout(() => {
                uploadFileSend();
            }, 1400);*/
        }
    }

    const uploadFileSend = () => {
        //setOpenMessage(true);
        console.log(datafile.file)
        if (value !== '') {
            if (datafile.file !== '') {
                if (datafile.file.type === "application/pdf") {
                    setFile({ open: true })
                    const formData = new FormData();
                    formData.append('file', datafile.file)
                    formData.append('ejercicio', value)
                    formData.append('cliente_id', info.cliente_id)
                    api.post('/constancia', formData).then(res => {
                        var containers = res.data;
                        if (containers.status === 200) {
                            setFile({
                                open: false,
                                file: '',
                                namefile: 'Seleccione la Constancia de Situación Fiscal',
                            })
                            setOpenMessage({
                                success: true,
                                vertical: 'top',
                                horizontal: 'right',
                                error: false,
                                message: containers.message
                            });
                            el.current.value = "";
                            var contenido_ = obj.informacion;
                            contenido_.informacionObtenida(false);
                            //resultData.informacion('Hola mundo....');
                        }
                        else if (containers.status === 404) {
                            setFile({
                                open: false,
                                file: '',
                                namefile: 'Seleccione la Constancia de Situación Fiscal',
                            })
                            setOpenMessage({
                                success: false,
                                vertical: 'top',
                                horizontal: 'right',
                                error: true,
                                message: containers.message
                            });
                            el.current.value = "";
                            setTimeout(() => {
                                setOpenMessage({
                                    error: false
                                });
                            }, 4000);
                        }

                    }).catch(err => console.log(err));
                }
                else {
                    setFile({
                        open: false,
                        file: '',
                        namefile: 'Seleccione la Constancia de Situación Fiscal',
                    })
                    setOpenMessage({
                        success: false,
                        vertical: 'top',
                        horizontal: 'right',
                        error: true,
                        message: 'El archivo importado no corresponde al documento a importar.'
                    });
                    el.current.value = "";
                    setTimeout(() => {
                        setOpenMessage({
                            error: false
                        });
                    }, 4000);
                }
            }
            else {
                setOpenMessage({ success: false, vertical: 'top', horizontal: 'right', error: true, message: 'Para poder continuar debe de seleccionar el documento!!!' });
                setTimeout(() => {
                    setOpenMessage({
                        error: false
                    });
                }, 4000);
            }
        }
        else {
            setOpenMessage({ success: false, vertical: 'top', horizontal: 'right', error: true, message: 'Para poder continuar debe de seleccionar el ejercicio!!!' });
            setTimeout(() => {
                setOpenMessage({
                    error: false
                });
            }, 4000);
        }

    }

    const handleChangeselect = (event) => {
        setAge(event.target.value);
    };

    const handleCloseMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenMessage({
            success: false,
            vertical: 'top',
            horizontal: 'right',
            error: false,
            message: ''
        });
    };
    /*const pruebsenddata = () => {
        var contenido_ = obj.informacion;
      //  console.log(contenido_);
        contenido_.informacionObtenida('Hola mundo....');
    }*/

    return (
        <>


            <Snackbar open={success} autoHideDuration={6000} onClose={handleCloseMessage} anchorOrigin={{ vertical, horizontal }}>
                <Alert onClose={handleCloseMessage} severity="success">
                    {message}
                </Alert>
            </Snackbar>

            {
                error ? (<div className="alert alert-warning alert-styled-right alert-dismissible">
                    <span className="font-weight-semibold">Usuario!</span> {message}
                </div>) : null
            }
            <div className="card" style={{ color: 'white' }}>
                <div className="card-body">
                    <div className="form-group" style={{ display: 'none' }}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">Ejercicio Inicio</InputLabel>
                            <Select style={{ width: 400 }} labelId="demo-simple-select-label" id="demo-simple-select" value={value} onChange={handleChangeselect} >
                                {ejercicio_array.map((e, key) => {
                                    return <MenuItem key={key} value={e.value}>{e.value}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="form-group">
                        <div className="uniform-uploader">
                            <input type="file" ref={el} className="form-control-uniform" data-fouc="" onChange={handleChangeFile} accept=".pdf" />
                            <span className="filename" style={{ userSelect: "none" }}>{datafile.namefile} </span>
                            <span className="action btn bg-warning-400" style={{ userSelect: "none" }}>Seleccione archivo</span>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'none' }}>
                    <button style={{ visibility: 'hidden' }} >Cancelar</button>
                    <button className="btn btn-primary" onClick={uploadFileSend}> Enviar <i className="icon-paperplane ml-2"></i></button>
                </div>
            </div>

            <Backdrop className={classes.backdrop} open={datafile.open} onClick={handleClose}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );

}
