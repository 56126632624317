import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
});

export default function LinearBuffer(props) {
    const classes = useStyles();
    const [progress, setProgress] = React.useState(props.value.utilizado);
    const [buffer, setBuffer] = React.useState(props.value.porcentaje);

    const progressRef = React.useRef(() => { });
    React.useEffect(() => {
        progressRef.current = () => {
            if (progress > 100) {
                setProgress(0);
                setBuffer(10);
            } else {
                const diff = Math.random() * 10;
                const diff2 = Math.random() * 10;
                setProgress(progress + diff);
                setBuffer(progress + diff + diff2);
            }
        };
    });

    React.useEffect(() => {
        progressRef.current();
        /*const timer = setInterval(() => {
           
        }, 500);

        return () => {
            clearInterval(timer);
        };*/
    }, []);

    return (
        <div className={classes.root}>
            <LinearProgress variant="buffer" value={progress} valueBuffer={buffer} />
        </div>
    );
}