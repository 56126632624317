import React, { Component } from 'react';
import api from '../../../app/Config/api';
import B69 from './screen-69b';
import AuditorContable from './screen-auditor';
class NavegacionAdmin extends Component {
    constructor(props) {
        super(props);
        let data_costumer = JSON.parse(localStorage.getItem("data_costumer"));
        this.state = {
            recordset: [],
            cliente_id: data_costumer.cliente_id,
            razon_social: data_costumer.razon_social
        }
    }
    componentDidMount() {
        this.getinfo();
    }
    getinfo = async () => {
        let _resultado_consulta_ = await api.post("filterlistservicesactive", { cliente_id: this.state.cliente_id });
        let { data } = await _resultado_consulta_;
        this.setState({ recordset: data });
    }

    render() {
        return (
            <>
                <div className="content-wrapper">
                    <div className="page-header border-bottom-0">
                        <div className="page-header-content header-elements-md-inline">
                            <div className="page-title d-flex">
                                <a href="#/app/ui/clientsadmin" style={{ color: 'white' }}>  <h4><i className="icon-arrow-left52 mr-2"></i> Listado de empresas</h4></a>
                                <a href="#/app/ui/clientsadmin" className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></a>
                            </div>
                            <span>
                                {this.state.razon_social}
                            </span>
                            <div className="header-elements">
                                <div className="d-flex justify-content-center" >
                                    <a href="#" className="btn btn-link btn-float text-default" style={{ display: 'none !important' }}></a>
                                    <a href="#" className="btn btn-link btn-float text-default"></a>
                                    <a href="#" className="btn btn-link btn-float text-default"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content pt-0">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="accordion-sortable ui-sortable" id="accordion-controls">
                                    {
                                        (this.state.recordset || []).map((i, j) => (
                                            <div className="card" key={j}>
                                                <div className="card-header header-elements-inline">
                                                    <h6 className="card-title">
                                                        <a data-toggle="collapse" className="text-default collapsed" href={"#" + i.url} aria-expanded="false">{i.descripcion}</a>
                                                    </h6>
                                                </div>

                                                <div id={i.url} className="collapse" data-parent="#accordion-controls" style={{ "": "" }}>
                                                    <div className="card-body">
                                                        {
                                                            (i.url === "listcompany" ? (<B69 />) :
                                                                (i.url === "listcompanyauditoria" ? (<AuditorContable />) : <p>Existe configuración</p>))
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default NavegacionAdmin;

