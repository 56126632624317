import React, { Component } from 'react';
import api from '../../../app/Config/api';
import { Tooltip, } from "@material-ui/core";
class AuditorContable extends Component {
    constructor(props) {
        super(props);
        let data_costumer = JSON.parse(localStorage.getItem("data_costumer"));
        this.state = {
            recordset: [],
            cliente_id: data_costumer.cliente_id,
            razon_social: data_costumer.razon_social
        }
    }
    componentDidMount() {
        this.getinfo();
    }
    getinfo = async () => {
        let _resultado_consulta_ = await api.post("getfilterdataauditorcontable", { cliente_id: this.state.cliente_id });
        let { data } = await _resultado_consulta_;

        this.setState({ recordset: data });
    }
    render() {
        return (
            <>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Nombre / razon social</th>
                            <th style={{ textAlign: 'center' }}>Cuentas contables</th>
                            <th style={{ textAlign: 'center' }}>Balanza</th>
                            <th style={{ textAlign: 'center' }}>Pólizas</th>
                            <th style={{ textAlign: 'center' }}>Federales</th>
                            <th style={{ textAlign: 'center' }}>Informativas</th>
                            <th style={{ textAlign: 'center' }}>Estatales</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (this.state.recordset || []).map((i, j) => (
                                <tr key={j}>
                                    <td>
                                        {i.nombre}
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                        {(i.cuentas_contables === "NOT" ? (<Tooltip title="No ha importado algún archivo" placement="top" ><i className="icon-warning22 mr-3 icon-2x text-danger"></i></Tooltip>) : (<i className="icon-checkmark4 mr-3 icon-2x text-success"></i>))}
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                        {(i.balanza_comprobacion === "NOT" ? (<Tooltip title="No ha importado algún archivo" placement="top" ><i className="icon-warning22 mr-3 icon-2x text-danger"></i></Tooltip>) : (<i className="icon-checkmark4 mr-3 icon-2x text-success"></i>))}
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                        {(i.poliza === "NOT" ? (<Tooltip title="No ha importado algún archivo" placement="top" ><i className="icon-warning22 mr-3 icon-2x text-danger"></i></Tooltip>) : (<i className="icon-checkmark4 mr-3 icon-2x text-success"></i>))}
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                        {(i.impuestos_federales === "NOT" ? (<Tooltip title="No ha importado algún archivo" placement="top" ><i className="icon-warning22 mr-3 icon-2x text-danger"></i></Tooltip>) : (<i className="icon-checkmark4 mr-3 icon-2x text-success"></i>))}
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                        {(i.informativas === "NOT" ? (<Tooltip title="No ha importado algún archivo" placement="top" ><i className="icon-warning22 mr-3 icon-2x text-danger"></i></Tooltip>) : (<i className="icon-checkmark4 mr-3 icon-2x text-success"></i>))}
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                        {(i.impuestos_estatales === "NOT" ? (<Tooltip title="No ha importado algún archivo" placement="top" ><i className="icon-warning22 mr-3 icon-2x text-danger"></i></Tooltip>) : (<i className="icon-checkmark4 mr-3 icon-2x text-success"></i>))}
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </>
        );
    }
}

export default AuditorContable;