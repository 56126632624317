import React, { Component } from 'react'
import ReactDatatable from '@ashvin27/react-datatable';
import Skeleton from '@material-ui/lab/Skeleton';
import { PacmanLoader } from "react-spinners";
import { withRouter } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import api from '../../app/Config/api';
function makeid() {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < 20; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
}

class ListCompanyCorporative extends Component {
    constructor(props) {
        super(props)
        this.columns = [
            {
                key: "nombre",
                text: "Nombre",
                className: "nombre",
                sortable: true,
                cell: record => {
                    return (
                        <div className="text-left">
                            {
                                <p style={{ fontSize: 12 }}>{record.nombre}</p>
                            }</div>
                    );
                }
            },
            {
                key: "rfc",
                text: "RFC",
                className: "rfc",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <div className="text-left">
                            {
                                <p style={{ fontSize: 12 }}>{record.rfc}</p>
                            }</div>
                    );
                }
            },

            {
                key: "id",
                text: "",
                className: "",
                cell: record => {
                    return (
                        <div className="text-center">
                            <button type="button" className="btn btn-link" onClick={() => this.handelmodalDinamyc(record)}>
                                <Tooltip arrow title={"Click para ver la documentación"} placement="top">
                                    <span><i className="icon-list-unordered mr-2" /> Documentación</span></Tooltip> </button>
                        </div>
                    );
                }
            },

            {
                key: "key",
                text: "",
                className: "",
                align: "center",
                sortable: true,
                cell: record => {
                    return (
                        <div className="text-center" style={{ cursor: 'pointer' }}>
                            {this.state.perfil !== "CAPTURISTA" ? (
                                <Tooltip onClick={() => this.handlerDelete(record)} title="Eliminar empresa" placement="top" arrow>
                                    <span className="badge badge-flat border-danger-100 text-danger badge-pill p-1"><i className="icon-trash position-static"></i></span>
                                </Tooltip>
                            ) : null}
                        </div>
                    );
                }
            }
        ];

        this.config = {
            key_column: 'key',
            page_size: 100,
            length_menu: [100, 200, 500],
            button: {
                excel: false,
                print: false,
                extra: false,
            },
            "language": {
                "length_menu": "Mostrar _MENU_ registros por página",
                "filter": "Buscar en registros ...",
                "info": "Mostrando _START_ a _END_ de _TOTAL_ entradas",
                "pagination": {
                    "first": "Primero",
                    "previous": "Previa",
                    "next": "próximo",
                    "last": "Última"
                }
            }
        }
        let id_ = JSON.parse(localStorage.getItem('id_token'));
        this.state = {
            recorset: [],
            skeleton: true,
            loading: true,
            id: 0,
            perfil: id_.perfil
        }
    }
    componentDidMount() {
        if (this.props.data.length > 0) {
            let _array_data_ = this.props.data;
            for (let tr = 0; tr < _array_data_.length; tr++) {
                _array_data_[tr].key = makeid();
            }
            this.setState({
                recorset: _array_data_,
                skeleton: false,
                loading: false
            })
        }
    }
    handelmodalDinamyc(i) {
        localStorage.removeItem("setdatanomina");
        localStorage.setItem("setdatanomina", JSON.stringify(i));
        this.props.history.push('/app/audit/dashboard-corporate-legal-audit');
    }
    handlerDelete(id) {
        this.setState({ id: id });
        window.$("#modaldefaultdetele").modal("show");
    }
    handleConfirmacionDelete() {
        var object = {
            id: this.state.id.id
        }
        api.post('deleteconfirmcompany', object).then(r => {
            // getData();
            // updatelist();
        });
    }
    render() {

        return (
            <div>
                {
                    this.state.skeleton ? (
                        <div className="card">
                            <Skeleton />
                            <div style={{
                                margin: 40,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <PacmanLoader color={"#ff7043"} loading={this.state.loading} size={25} margin={2} />
                            </div>
                            <Skeleton variant="circle" animation="wave" width={40} height={40} />
                            <Skeleton variant="rect" animation="wave" width={"100%"} height={118} />
                            <Skeleton animation="wave" />
                        </div>
                    ) : (
                        <ReactDatatable
                            config={this.config}
                            records={(this.state.recorset || [])}
                            columns={this.columns}
                        //extraButtons={this.extraButtons}
                        />
                    )
                }
                <div id="modaldefaultdetele" className="modal fade" tabIndex="-1" style={{ "display": "none" }} aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Confirmar de eliminación</h5>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>

                            <div className="modal-body">
                                <h6 className="font-weight-semibold">Está seguro de eliminar el siguiente registro {this.state.id.nombre}?</h6>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-link" data-dismiss="modal">Cancelar</button>
                                <button type="button" className="btn bg-primary" onClick={this.handleConfirmacionDelete}>Aceptar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(ListCompanyCorporative);
