import React, { Component } from 'react'
import api from '../../app/Config/apifiles';
import { Tooltip } from "@material-ui/core";
export class TableView extends Component {
    constructor(props) {
        super(props)

        this.state = {
            header: [],
            body: [],
            title: []
        }
    }
    componentDidMount() {
        this.setState({
            header: this.props.header,
            body: this.props.body,
            title: this.props.title
        });
    }
    handleClickEventViewCedula = async (y) => {
        this.props.handleviewDetails(y);
    }
    render() {
        return (
            <div className="content" style={{ width: "100%" }}>
                <div className='wrapper' style={{ marginLeft: "300px" }}>
                    <table className='table' style={{ width: "1200px" }}>
                        <thead>
                            <tr>
                                {
                                    (this.state.title || []).map((tr, index) => (
                                        (index === 0) ? (
                                            <th className='fixed' style={{ left: "10px" }} key={index}></th>
                                        ) : (
                                            <>
                                                {
                                                    (tr.colpsan > 0) ? (
                                                            <th className='text-center' colSpan={tr.colpsan} key={index}>{tr.title}</th>
                                                    ) : null

                                                }
                                            </>
                                        )
                                    ))
                                }
                            </tr>
                            <tr>
                                {
                                    (this.state.title || []).map((tr, index) => (
                                        (index === 0) ? (
                                            <th className='fixed' style={{ left: "10px" }} key={index}>{tr.descripcion}</th>
                                        ) :
                                            <th key={index} className='text-center'>{tr.descripcion}</th>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (this.state.body || []).map((tbody, index) => (
                                    <tr key={index} style={{ height: (String(tbody.descripcion).length > 71 ? "60px" : 0) }}>
                                        {
                                            (tbody.type === "link") ? (
                                                <td className='fixed' style={{ left: "10px" }}>
                                                    <Tooltip title="Click para ver el detalle" placement="top" arrow>
                                                        <button style={{ textAlign: 'left' }} className='btn btn-link' onClick={() => this.handleClickEventViewCedula(tbody)}>{tbody.descripcion}</button>
                                                    </Tooltip>
                                                </td>
                                            ) :
                                                <td className='fixed' style={{ left: "10px" }}>{tbody.descripcion}</td>
                                        }
                                        <td className={(tbody.clave === "SUM001" ? 'text-right text-success' : 'text-right')}>{parseFloat(tbody.venta_iva_16).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                        <td className={(tbody.clave === "SUM001" ? 'text-right text-success' : 'text-right')}>{parseFloat(tbody.venta_iva_8).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                        <td className={(tbody.clave === "SUM001" ? 'text-right text-success' : 'text-right')}>{parseFloat(tbody.venta_iva_0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                        <td className={(tbody.clave === "SUM001" ? 'text-right text-success' : 'text-right')}>{parseFloat(tbody.ventas_exenta_importacion).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                        <td className={(tbody.clave === "SUM001" ? 'text-right text-success' : 'text-right')}>{parseFloat(tbody.venta_iva_no_objeto_impuesto).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                        <td className={(tbody.clave === "SUM001" ? 'text-right text-success' : 'text-right')}>{parseFloat(tbody.valor_actos_actividades_porlasno_sepagaimpuesto).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                        <td className={(tbody.clave === "SUM001" ? 'text-right text-success' : 'text-right')}>{parseFloat(tbody.anticipo_venta_16).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                        <td className='text-right text-right text-success'>{parseFloat(tbody.suma_total_ventas_cobradas_del_periodoo).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    <div style={{ height: 50 }}></div>
                </div>
            </div>
        )
    }
}

export default TableView;