import React, { useState } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Tooltip } from '@material-ui/core';
import { PacmanLoader, RingLoader } from "react-spinners";
import api from '../../../../app/Config/apifiles';
//import axios from 'axios';
export default function ValidateRFC() {
    const [statusrfc, setStatusRfc] = useState(false);
    const [rfc, setRfc] = useState('');
    const [validate, setValidate] = useState([]);
    const [loading, setLoading] = useState(false);
    const [view_details, setViewDetails] = useState(false);
    const [view_message, setViewMessage] = useState(false);
    const [details, setDetails] = useState({
        title: '', estatus: '', data: []
    });
    const validaterfc = (event) => {
        let rfc = event.target.value;
        setRfc(rfc);
        if (String(rfc).length > 11 && String(rfc).length < 14) {
            const regex = /^[A-ZÑ&]{3,4}\d{6}[A-Z0-9]{3}$/;
            if (regex.test(rfc)) {
                setStatusRfc(true);
            } else {
                setStatusRfc(false);
            };
        } else {
            setStatusRfc(false);

        }
    }
    const afterSubmission = async (event) => {
        event.preventDefault();
        if (statusrfc) {
            setViewDetails(false);
            setLoading(true);
            setTimeout(async () => {
                await api.post("validate-rfc-whit-69b", { rfc: rfc }).then(resultado => {
                    setValidate(resultado.data);
                    setLoading(false);
                })
            }, 300);
        }
        else {
            setViewMessage(true);
            setTimeout(() => {
                setViewMessage(false);
            }, 3000);
        }
    }
    const displayDetails = async (t) => {
        //console.log(t);
        if (t.estatus === "error") {
            setViewDetails(true);
            setDetails(t)
        }
    }
    return (
        <div className="card">
            <div className="card-body">
                Ingrese el rfc para validarla con las listas del 69B
            </div>
            <div className="card-body border-top">
                {
                    view_message ? (
                        <div className="alert alert-warning border-0 alert-dismissible fade show">
                            <span className="fw-semibold">Alerta!</span> Debe de ingresar un rfc valido para poder continuar
                        </div>
                    ) : null
                }

                <div className="row">
                    <div className="col-lg-6">
                        <form autoComplete='off' onSubmit={afterSubmission}>
                            <div className="row mb-3">
                                <label className="col-lg-2 col-form-label">RFC:</label>
                                <div className="col-lg-7">
                                    <input type="text" className="form-control" maxLength={13} minLength={12} value={rfc} placeholder="Ingrese el RFC" onChange={validaterfc} />
                                </div>
                                <div className="col-lg-3">
                                    <button type="submit" className="btn btn-primary">Validar<i className="ph-paper-plane-tilt ms-2" /></button>
                                </div>
                            </div>
                        </form>
                        {
                            view_details ? (
                                <div className='row mb-6'>
                                    <table className='table'>
                                        <thead>
                                            <tr>
                                                <td colSpan={2} className='text-center text-danger'>{details.title}</td>
                                            </tr>
                                            <tr>
                                                <td>Rfc:</td>
                                                <td>{details.data[0].RFC}</td>
                                            </tr>
                                            <tr>
                                                <td>Nombre:</td>
                                                <td>{details.data[0].RAZONSOCIAL}</td>
                                            </tr>
                                            <tr>
                                                <td>Fecha de publicación:</td>
                                                <td>{details.data[0].FECHA_PUBLICACION}</td>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>

                            ) : null
                        }
                    </div>
                    <div className='col-lg-6'>
                        {
                            loading ? (
                                <div className="card">
                                    <Skeleton />
                                    <div style={{
                                        margin: 40,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                        <RingLoader color={"#ff7043"} loading={loading} size={60} margin={2} />
                                    </div>
                                    <Skeleton variant="rect" animation="wave" width={"100%"} height={28} />
                                    <Skeleton animation="wave" />
                                </div>
                            ) : (
                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <th>Descripción</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (validate || []).map((xs, index) => (
                                                <tr key={index}>
                                                    <td>{xs.title}</td>
                                                    <td>
                                                        <Tooltip title={(xs.estatus === "good" ? "No se localizo en " + xs.title : "Clic para ver detalles ")} arrow placement='top'>
                                                            <button type='button' onClick={() => displayDetails(xs)} className='btn btn-link'> {(xs.estatus === "good" ? <i className="icon-checkmark2 text-success"></i> : <i className="icon-spam text-danger"></i>)}</button>
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            )
                        }

                    </div>
                </div>
            </div>
        </div>

    )
}
