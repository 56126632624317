import React, { useEffect } from 'react';

import { useState } from 'react';
import CFDI from './details/cfdis';
import EFIRMA from './details/efirma';
import api from '../../app/Config/api';



const ViewValidacion = (i) => {
    var resultado = JSON.parse(localStorage.getItem("datavalidate"));
    resultado.tipo_validacion = i;
    api.post('updatetypeimportvalidate', resultado).then((u) => {
        localStorage.removeItem("datavalidate");
        localStorage.setItem("datavalidate", JSON.stringify(u.data.result))
    });
};
export default function OutlinedCard() {
    const [objects, setChangeType] = useState('');
    const [checkimportacion, setChangeImportacion] = useState(false);
    const [checkefirma, setChangeEfirma] = useState(false);
    const handleRadioChange = (event) => {
        setChangeType(ViewValidacion(event.target.value))
    }
    const [statuscked, setChangeStatus] = useState(false);
    const [statusckednot, setChangeStatusNot] = useState(false);
    useEffect(() => {
        var resultado = JSON.parse(localStorage.getItem("datavalidate"));
        //console.log(resultado)
        if (resultado.tipo_validacion === "EFIRMA") {
            setChangeImportacion(false);
            setChangeEfirma(true);
            setChangeType(ViewValidacion("EFIRMA"))
        }
        else {
            setChangeImportacion(true);
            setChangeEfirma(false);
            setChangeType(ViewValidacion("IMPORTACION"))
        }
        //console.log(resultado)
    }, []);
    const validacion_ = (xd) => {
        //console.log(xd.target.value)
        ViewValidacion(xd.target.value)
        if (xd.target.value === "IMPORTACION") {
            setChangeStatus(true);
            setChangeStatusNot(false);
            setChangeImportacion(true);
            setChangeEfirma(false);
        }
        else if (xd.target.value === "EFIRMA") {
            setChangeStatus(false);
            setChangeStatusNot(true);
            setChangeImportacion(false);
            setChangeEfirma(true);
        }
    }
    return (
        <>
            <div className="content pt-0">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div style={{ zIndex: 300 }}>
                                {(checkimportacion === true && checkefirma === false) ? (<CFDI />) : null}
                                {(checkefirma === true && checkimportacion === false) ? (<EFIRMA />) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
