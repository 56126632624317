import React, { Component } from 'react';
import api from '../../Config/apifiles';
import { Tooltip, } from "@material-ui/core";
import EmitidosView from './papel-trabajo/cast-work-paper';
import Skeleton from '@material-ui/lab/Skeleton';
import { PacmanLoader } from "react-spinners";
import './style.css';
const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
const sorter = (a, b) => {
    if (a.ejercicio !== b.ejercicio) {
        return a.ejercicio - b.ejercicio;
    } else {
        return months.indexOf(a.periodo) - months.indexOf(b.periodo);
    };
};
const sorterOperationnumber = (a, b) => {
    if (a.numerooperacion !== b.numerooperacion) {
        return a.numerooperacion - b.numerooperacion;
    } else {
        return months.indexOf(a.periodo) - months.indexOf(b.periodo);
    };
};
function getMax(arr, prop) {
    var max;
    for (var i = 0; i < arr.length; i++) {
        if (max == null || parseInt(arr[i][prop]) > parseInt(max[prop]))
            max = arr[i];
    }
    return max;
}
class Emitido extends Component {
    constructor(props) {
        super(props);
        let objects = JSON.parse(localStorage.getItem("datacompanyall"));
        this.state = {
            empresa_id: objects.id,
            rfc: objects.rfc,
            nombrecompany: objects.nombre,
            data: [],
            viewheader: true,
            viewbody: false,
            viewdetails: false,
            title: 'Comparativo de ingresos anuales: Facturados vs Declarados',
            //title: 'Comparativo de ingresos anuales vs CFDI emitidos vigentes',
            details: [],
            total_retencion_iva_head_factura: 0,
            total_retencion_iva_head_declaracion: 0,
            total_diferencia_retencion_iva_head: 0,
            total_retencion_iva_body_factura: 0,
            total_retencion_iva_body_declaracion: 0,
            total_diferencia_retencion_iva_body: 0,
            viewpeperwork: '',
            loading: true,
            linkpdf: '',
            loadingfirts: false,
            clave: '',
            declaraciones: [],
            filterdeclaracion: [],
            subtitlemodal: '',
            periodos_filter_: [],
            filterdataanual: [],
            exerciseselect: 0,
            viewmetadata: false,
            urlmetadata: '',
            ejercicio: 0,
            periodo: '',
            nameperiod: ''
        }

    }
    componentDidMount() {
        this.getdataaudite();
    }
    getdataaudite = async () => {
        let _config_data_tmp_ = await api.post('getdataadmindata', { empresa_id: this.state.empresa_id });

        let _data_ejercicios_ = await api.post("app-relations-69b-costumer", { empresa_id: this.state.empresa_id });
        let data_ejercicios_all = (_data_ejercicios_.data).ejercicio;


        let data_ = await api.post('report-validacion-sat', { empresa_id: this.state.empresa_id });
        let { factura, declarado, egresos } = await data_.data;
        console.log(factura)
        let total_retencion_iva_head_factura = 0;
        let total_retencion_iva_head_declaracion = 0;
        let total_diferencia_retencion_iva_head = 0;
        if (factura.length > 0) {
            for (let t = 0; t < factura.length; t++) {
                let __result_ = (egresos).filter((xd) => xd.ejercicio == factura[t].ejercicio);
                ///console.log(__result_)
                //if(__result_.length>0)
                factura[t].alert = (data_ejercicios_all).filter((s) => s.ejercicio == factura[t].ejercicio);
                factura[t].ingresosc = (factura[t].ingresos_facturados + (__result_.length > 0 ? (__result_[0].egresos_facturados) : 0));
                factura[t].egresosc = ((__result_.length > 0 ? (__result_[0].egresos_facturados) : 0));
                total_retencion_iva_head_factura = total_retencion_iva_head_factura + factura[t].ingresos_facturados;
                total_retencion_iva_head_declaracion = total_retencion_iva_head_declaracion + factura[t].ingresos_declarados;
                //factura[t].diferencias = (factura[t].ingresosc - factura[t].ingresos_declarados)
                factura[t].diferencias = (factura[t].ingresos_facturados - factura[t].ingresos_declarados)
                if (factura[t].status_ != "OKNOT") {
                    total_diferencia_retencion_iva_head = total_diferencia_retencion_iva_head + factura[t].diferencias;
                }

            }
        }
        this.setState({
            viewmetadata: false,
            data: factura, total_retencion_iva_head_factura: total_retencion_iva_head_factura,
            total_retencion_iva_head_declaracion: total_retencion_iva_head_declaracion,
            total_diferencia_retencion_iva_head: total_diferencia_retencion_iva_head,
            loading: false,
            clave: _config_data_tmp_.data,
            periodos_filter_: (_data_ejercicios_.data).periodo
        });
    }
    viewdetailsbody = async (i) => {
        this.setState({ loading: true })
        let data_ = await api.post('report-validacion-sat-filter-exercise', { empresa_id: this.state.empresa_id, ejercicio: i.ejercicio });
        let { facturado, declarado } = await data_.data;
        console.log(facturado)
        let total_retencion_iva_body_factura = 0;
        let total_retencion_iva_body_declaracion = 0;
        let total_diferencia_retencion_iva_body = 0;
        facturado = (facturado).sort(sorter)
        if (facturado.length > 0) {
            for (let t = 0; t < facturado.length; t++) {
                facturado[t].aplicacion = (this.state.periodos_filter_).filter((df) => df.ejercicio == facturado[t].ejercicio && df.periodo == facturado[t].periodo)

                total_retencion_iva_body_factura = total_retencion_iva_body_factura + facturado[t].ingresos_facturados;
                total_retencion_iva_body_declaracion = total_retencion_iva_body_declaracion + facturado[t].ingresos_declarados;
                facturado[t].diferencias = (facturado[t].ingresos_facturados - facturado[t].ingresos_declarados)
                total_diferencia_retencion_iva_body = total_diferencia_retencion_iva_body + facturado[t].diferencias;

            }
        }
        //console.log(data_.data)
        this.setState({
            viewmetadata: false,
            title: 'Comparativo de ingresos mensuales: Facturados vs Declarados',
            details: facturado,
            viewheader: false,
            viewbody: true,
            viewdetails: false,
            declaraciones: declarado,
            total_retencion_iva_body_factura: total_retencion_iva_body_factura,
            total_retencion_iva_body_declaracion: total_retencion_iva_body_declaracion,
            total_diferencia_retencion_iva_body: total_diferencia_retencion_iva_body,
            loading: false
        });
    }
    _viewInit_ = async () => {
        this.setState({
            viewmetadata: false,
            title: 'Comparativo de ingresos anuales: Facturados vs Declarados',
            viewheader: true,
            viewbody: false,
            viewdetails: false,
        });
    }
    _view_data_filter_ = async (o) => {
        console.log(o)
        this.setState({
            ejercicio: o.ejercicio,
            periodo: o.periodo,
            viewmetadata: false,
            nameperiod: ' CFDIs emitidos de ' + o.namemonth + ' de ' + o.ejercicio,
            title: 'Efecto fiscal y contable de los CFDIs emitidos en ' + o.namemonth + ' de ' + o.ejercicio,
            viewheader: false,
            viewbody: false,
            viewdetails: true,
            viewpeperwork: <EmitidosView data={o} functionHandle={this.handleViewMetadata} />
        });
    }
    view_details_init_ = async (g) => {
        this.setState({
            viewmetadata: false,
            title: 'Comparativo de ingresos mensuales: Facturados vs Declarados',
            viewheader: false,
            viewbody: true,
            viewdetails: false,
            viewpeperwork: ''
        });
    }
    handleViewPDF = () => {
        let linkpdf = 'https://e-xpertum.com.mx/adminfile/api/export-pdf-report-validacion-sat/' + this.state.rfc + '/' + this.state.nombrecompany + '/' + this.state.clave;
        var win = window.open(linkpdf, '_blank');
        if (win) {
            win.focus();
        } else {
            alert('Please allow popups for this website');
        }
    }
    handleLoading = async () => {
        this.setState({
            loadingfirts: false
        })
    }
    handleFilterArrayData = async (t) => {
        window.$("#modallistdeclaracion").modal("show");
        let info_data = (this.state.declaraciones.filter((h) => h.ejercicio === t.ejercicio && h.period === t.periodo)).sort(function (a, b) {
            return a.numero_de_operacion - b.numero_de_operacion;
        });
        this.setState({ viewmetadata: false, subtitlemodal: (t.namemonth + ' de ' + t.ejercicio), filterdeclaracion: info_data });
    }
    handleViewPDFDeclaracion = (t) => {
        console.log(t);
        let linkpdf = 'https://e-xpertum.com.mx/adminfile/api/displayfilepdf/bd54bba3f9d85ea1426942775518b90b6/ecb868faf29054fedf1ac6cbac5fc63/' + t.directorio;
        var win = window.open(linkpdf, '_blank');
        if (win) {
            win.focus();
        } else {
            alert('Please allow popups for this website');
        }
    }
    handleViewDetailsView = async (f) => {
        let data_ = await api.post('filter-to-exercise-anual', { empresa_id: this.state.empresa_id, ejercicio: f.ejercicio });
        let result = await data_.data;

        this.setState({
            viewmetadata: false,
            exerciseselect: f.ejercicio,
            filterdataanual: (result).sort(sorterOperationnumber)
        });
        ///console.log(getMax(result, 'numerooperacion'))
        window.$("#detailsdeclarationsanual").modal("show")
    }
    handleViewMetadata = async () => {
        this.setState({
            title: this.state.nameperiod,
            viewmetadata: true,
            viewheader: false,
            viewbody: false,
            viewdetails: false,
            urlmetadata: 'https://e-xpertum.com.mx/invoces/report-metadata-x?rfc=' + this.state.rfc + '&empresa_id=' + this.state.empresa_id + '&ejercicio=' + this.state.ejercicio + '&periodo=' + this.state.periodo + '&tipo=EMITIDO',
        });
    }
    handledownloadfiles = () => {
        window.open("https://e-xpertum.com.mx/invoces/api/compare-income-excel/" + this.state.empresa_id);
    }
    render() {
        return (
            <>
                <div className="content pt-0">
                    <div className="card">
                        <div className="card-header header-elements-inline">
                            <h6 className="card-title">{this.state.title}</h6>
                            {(this.state.viewbody && this.state.viewpeperwork === '') ? (
                                <div className="d-flex align-items-center" style={{ cursor: 'pointer' }} onClick={this._viewInit_}>
                                    <i className="icon-arrow-left16 mr-3 icon-2x text-primary"></i>
                                    <div className="text-primary">Inicio</div>
                                </div>
                            ) : (this.state.viewbody === false && this.state.viewpeperwork !== '') ? (
                                <div className="d-flex align-items-center" style={{ cursor: 'pointer' }} onClick={this.view_details_init_}>
                                    <i className="icon-arrow-left16 mr-3 icon-2x text-primary"></i>
                                    <div className="text-primary">Detalle</div>
                                </div>
                            ) : (
                                <div>
                                    <button type="button" className="btn btn-light" onClick={this.handledownloadfiles} style={{ margin: '0 10px' }}><i className="icon-file-excel mr-2 text-success"></i> Excel</button>
                                    <button type="button" className="btn btn-light" onClick={this.handleViewPDF}><i className="icon-file-pdf mr-2 text-success"></i> PDF</button>
                                </div>

                            )}
                        </div>
                        <div className="card-body">
                            {
                                this.state.loading ? (
                                    <div>
                                        <Skeleton variant="text" />
                                        <div style={{
                                            margin: 40,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}>
                                            <PacmanLoader color={"#ff7043"} loading={this.state.loading} size={25} margin={2} />
                                        </div>
                                        <Skeleton variant="circle" animation="wave" width={40} height={40} />
                                        <Skeleton variant="rect" animation="wave" width={"100%"} height={118} />
                                    </div>
                                ) : (
                                    <>
                                        {
                                            (this.state.viewheader === true && this.state.viewbody === false && this.state.viewdetails === false) ?
                                                (
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th style={{ textAlign: 'center' }}>Ejercicio</th>
                                                                <th colSpan={3} style={{ textAlign: 'center' }}>Ingresos facturados</th>
                                                                <th style={{ textAlign: 'center' }}>Ingresos declarados</th>
                                                                <th style={{ textAlign: 'center' }}>Diferencias</th>
                                                            </tr>
                                                            <tr>
                                                                <th></th>
                                                                <th></th>
                                                                <th style={{ textAlign: 'center' }}>CFDIs Tipo Ingresos</th>
                                                                <th style={{ textAlign: 'center' }}>CFDIs Tipo Egresos</th>
                                                                <th style={{ textAlign: 'right' }}>Ingresos netos</th>
                                                                <th></th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                (this.state.data || []).map((h, l) => (
                                                                    <tr key={l}>
                                                                        <td style={{ textAlign: 'right' }}>{h.alert.length > 0 ? (<Tooltip title="Existen uno o más clientes tomados en cuenta para el ISR." placement="top" arrow><i className="icon-alert mr-3 text-danger"></i></Tooltip>) : null}</td>
                                                                        <td>
                                                                            <Tooltip title="Clic para ver comparativos mensuales" placement="top" arrow>
                                                                                <button onClick={() => this.viewdetailsbody(h)} type="button" className="btn btn-outline-primary btn-lg" style={{ width: '100%' }}><i className="icon-unlink mr-2"></i>{h.ejercicio}</button>
                                                                            </Tooltip>
                                                                        </td>
                                                                        <td style={{ textAlign: 'right' }}>
                                                                            {h.ingresos_facturados.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                        </td>
                                                                        <td style={{ textAlign: 'right' }}>{h.egresosc.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>

                                                                        <td style={{ textAlign: 'right' }}>{h.ingresos_facturados.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                                        <td style={{ textAlign: 'right' }}>
                                                                            {
                                                                                (h.status_ === "OKNOT") ? (
                                                                                    <a className="btn bg-transparent text-success-100 border-success ml-1" style={{ width: '100%' }}>N/A</a>
                                                                                ) : (
                                                                                    (h.status_ === "YES") ? (
                                                                                        <button type="button" className="btn btn-outline-primary btn-lg" style={{ width: '100%' }} onClick={() => this.handleViewDetailsView(h)}><i className="icon-unlink mr-2"></i>{h.ingresos_declarados.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</button>
                                                                                    ) : (
                                                                                        <button type="button" className="btn btn-outline-danger btn-lg" style={{ width: '100%' }}><i className="icon-unlink mr-2"></i>FALTA</button>
                                                                                    )
                                                                                )
                                                                            }

                                                                        </td>
                                                                        <td style={{ textAlign: 'right' }}>
                                                                            {
                                                                                (h.diferencias.toFixed(0) > 0) ?
                                                                                    (
                                                                                        <>
                                                                                            {
                                                                                                (h.status_ === "OKNOT") ? (
                                                                                                    <a className="btn bg-transparent text-success-100 border-success ml-1" style={{ width: '100%' }}>N/A</a>
                                                                                                ) : (
                                                                                                    <span className="text-danger" style={{ fontSize: 12, textAlign: 'right' }}>
                                                                                                        {h.diferencias.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                                    </span>

                                                                                                )}
                                                                                        </>

                                                                                    ) : (h.diferencias === 0 ? '' : (<span className="text-success" style={{ fontSize: 12, textAlign: 'right' }}>{h.diferencias.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>))
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td></td>
                                                                <td style={{ textAlign: 'right' }}>Totales: </td>
                                                                <td></td>
                                                                <td></td>
                                                                <td style={{ textAlign: 'right' }}>{this.state.total_retencion_iva_head_factura.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                                <td style={{ textAlign: 'right' }}>{this.state.total_retencion_iva_head_declaracion.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                                <td style={{ textAlign: 'right' }}>
                                                                    {this.state.total_diferencia_retencion_iva_head === 0 ? '' : (
                                                                        <>
                                                                            {
                                                                                (this.state.total_diferencia_retencion_iva_head < 0) ? (
                                                                                    <a className="btn bg-transparent text-danger-100 border-success ml-1" style={{ textAlign: 'right', width: '100%' }}>
                                                                                        <span className='text-success'>{(this.state.total_diferencia_retencion_iva_head * -1).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>
                                                                                    </a>
                                                                                ) : (
                                                                                    <a className="btn bg-transparent text-danger-100 border-warning ml-1" style={{ textAlign: 'right', width: '100%' }}>
                                                                                        <span className='text-danger'>{this.state.total_diferencia_retencion_iva_head.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>
                                                                                    </a>
                                                                                )
                                                                            }
                                                                        </>
                                                                    )
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                ) : null
                                        }
                                    </>
                                )
                            }
                            {
                                (this.state.viewheader === false && this.state.viewbody === true && this.state.viewdetails === false) ?
                                    (
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th style={{ textAlign: 'center' }}>Mes</th>
                                                    <th style={{ textAlign: 'center' }}>Efecto Fiscal en Ingresos</th>
                                                    <th style={{ textAlign: 'center' }}>Ingresos declarados</th>
                                                    <th style={{ textAlign: 'center' }}>Diferencias</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (this.state.details || []).map((h, l) => (
                                                        <tr key={l}>
                                                            <td style={{ textAlign: 'right' }}>{h.aplicacion.length > 0 ? (<Tooltip title="Existen uno o más clientes tomados en cuenta para el cálculo de ISR." placement="top" arrow><i className="icon-alert mr-3 text-danger"></i></Tooltip>) : null}</td>

                                                            <td style={{ textAlign: 'center' }}>
                                                                <Tooltip title="Clic para ver las facturas de ingreso" placement="top" arrow>
                                                                    <button type="button" className="btn btn-outline-primary btn-lg" style={{ width: '100%' }} onClick={() => this._view_data_filter_(h)}><i className="icon-file-xml mr-2" style={{ float: 'left' }}></i> {h.namemonth + " "}  {h.ejercicio}</button>
                                                                </Tooltip>
                                                            </td>
                                                            <td style={{ textAlign: 'right' }}>{h.ingresos_facturados.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                            <td style={{ textAlign: 'right' }}>
                                                                <Tooltip title="Clic para ver las declaraciones de ingreso" placement="top" arrow>
                                                                    <button type="button" className="btn btn-outline-primary btn-lg" style={{ width: '100%' }} onClick={() => this.handleFilterArrayData(h)}><i className="icon-stack mr-2"></i>{h.ingresos_declarados.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</button>

                                                                </Tooltip>
                                                            </td>
                                                            <td style={{ textAlign: 'right' }}>
                                                                {
                                                                    (h.diferencias.toFixed(0) > 0) ?
                                                                        (
                                                                            <span className="text-danger" style={{ fontSize: 12, textAlign: 'right' }}>{h.diferencias.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>
                                                                        ) : (h.diferencias === 0 ? '' : (<span className="text-success" style={{ fontSize: 12, textAlign: 'right' }}>{h.diferencias.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>))
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td></td>
                                                    <td style={{ textAlign: 'right' }}>Totales: </td>
                                                    <td style={{ textAlign: 'right' }}>{this.state.total_retencion_iva_body_factura.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                    <td style={{ textAlign: 'right' }}>{this.state.total_retencion_iva_body_declaracion.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                    <td>{this.state.total_diferencia_retencion_iva_body === 0 ? '' : (
                                                        <>
                                                            {
                                                                (this.state.total_diferencia_retencion_iva_body < 0) ? (
                                                                    <a className="btn bg-transparent text-danger-100 border-success ml-1" style={{ textAlign: 'right', width: '100%' }}>
                                                                        <span className='text-success'>{(this.state.total_diferencia_retencion_iva_body * -1).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>
                                                                    </a>
                                                                ) : (
                                                                    <a className="btn bg-transparent text-danger-100 border-warning ml-1" style={{ textAlign: 'right', width: '100%' }}>
                                                                        <span className='text-danger'>{this.state.total_diferencia_retencion_iva_body.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>
                                                                    </a>
                                                                )
                                                            }
                                                        </>
                                                    )}
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    ) : null
                            }
                            {
                                (this.state.viewheader === false && this.state.viewbody === false && this.state.viewdetails === true) ?
                                    (
                                        <>{this.state.viewpeperwork}</>
                                    ) : null
                            }
                            {
                                (this.state.viewmetadata) ? (
                                    <>
                                        <div className="embed-container">
                                            <iframe src={this.state.urlmetadata} onLoad={this.handleLoading}
                                                width="100%" title='Report de metadata'
                                                frameBorder="0" allowFullScreen
                                            />
                                        </div>
                                    </>
                                ) : null
                            }
                        </div>
                    </div>
                </div>
                <div id="modallargepdf" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                    <div className="modal-dialog modal-full">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Reporte de Ingresos</h5>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>
                            <div className="modal-body">
                                <div className="embed-container">
                                    {
                                        (this.state.loadingfirts) ?
                                            (
                                                <div>
                                                    <Skeleton variant="text" />
                                                    <Skeleton variant="circle" animation="wave" width={40} height={40} />
                                                    <Skeleton variant="rect" animation="wave" width={510} height={118} />
                                                </div>
                                            ) : null
                                    }
                                    <iframe src={this.state.linkpdf} onLoad={this.handleLoading} title="Reporte de ingresos"
                                        width="100%"
                                        frameBorder="0" allowFullScreen
                                    >
                                    </iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modallistdeclaracion" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                    <div className="modal-dialog modal-full">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Listado de ingresos declarados de {this.state.subtitlemodal} </h5>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>
                            <div className="modal-body">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Ingresos</th>
                                            <th>Número de operación</th>
                                            <th>Tipo declaración</th>
                                            <th>Fecha de presentación</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (this.state.filterdeclaracion || []).map((y, p) => (
                                                <tr key={p}>
                                                    <td>{y.ingresos.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                    <td>{y.numero_de_operacion}</td>
                                                    <td>{y.tipo_declaracion}</td>
                                                    <td>{y.fecha_de_presentacion}</td>
                                                    <td><button type="button" className="btn btn-light" onClick={() => this.handleViewPDFDeclaracion(y)}><i className="icon-file-pdf text-success"></i></button></td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="detailsdeclarationsanual" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                    <div className="modal-dialog modal-full">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Detalle de los ingresos declarados de {this.state.exerciseselect}</h5>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>
                            <div className="modal-body">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Ingresos</th>
                                            <th>Número de operación</th>
                                            <th>Tipo declaración</th>
                                            <th>Fecha de presentación</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (this.state.filterdataanual || []).map((y, p) => (
                                                <tr key={p}>
                                                    <td>{y.ingresos.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                    <td>{y.numero_de_operacion}</td>
                                                    <td>{y.tipo_declaracion}</td>
                                                    <td>{y.fecha_de_presentacion}</td>
                                                    <td><button type="button" className="btn btn-light" onClick={() => this.handleViewPDFDeclaracion(y)}><i className="icon-file-pdf text-success"></i></button></td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>

            </>
        );
    }
}

export default Emitido;