
import React, { useState, useEffect } from "react";
import {
    LineChart, AreaChart, BarChart, Bar,
    Area,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    LabelList
} from "recharts";
import fastapi from "../../Config/fast_api";

const CustomizedLabel = (props) => {
    const { x, y, stroke, value } = props;
    return (
        <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
            {parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
        </text>
    );
};

const CustomizedAxisTick = (props) => {
    const { x, y, payload } = props;

    return (
        <g transform={`translate(${x},${y})`}>
            <text
                x={0}
                y={0}
                dy={16}
                textAnchor="end"
                fontSize={12}
                fill="#fff"
                transform="rotate(-35)"
            >
                {(payload.value)}
            </text>
        </g>
    );
};
const CustomizedAxisTickY = (props) => {
    const { x, y, payload } = props;

    return (
        <g transform={`translate(${x},${y})`}>
            <text
                x={0}
                y={0}
                dy={16}
                textAnchor="end"
                fontSize={10}
                fill="#fff"
                transform="rotate(-35)"
            >
                {parseFloat(payload.value).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
            </text>
        </g>
    );
};
const CustomTooltip = (props) => {
    if (props.active && props.payload && props.payload.length) {
        //if (props.active && props.payload.length) {
        let dataf = props.payload[0];
        let datad = props.payload[1];
        return (
            <div className="card bg-primary text-white">
                <div className="card-body">
                    <div className="d-flex align-items-center">
                        <h3 className="mb-0">{`${props.label}`}</h3>
                        <div className="ms-auto">
                            <a className="text-white" data-card-action="reload">
                                <i className="ph-arrows-clockwise" />
                            </a>
                        </div>
                    </div>
                    <div>
                        Ingresos facturados
                        <div className="fs-sm opacity-75">{parseFloat(dataf.value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</div>
                    </div>
                    <div>
                        Ingresos declarados
                        <div className="fs-sm opacity-75">{parseFloat(datad.value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</div>
                    </div>
                </div>
            </div>
        )
    }
    else
        return null

}
export default function EvolutionIncome() {
    const [width, setWidth] = useState();
    const [height, setHeight] = useState();
    const [data, setData] = useState([]);
    const [data_month, setDataMonth] = useState([]);
    const [anual, setAnual] = useState(true);
    const [mensual, setMensual] = useState(false);
    const [title, setTitle] = useState('Clic sobre el punto para ver el detalle')
    const [subtitle, setSubTitle] = useState('Evolución de los ingresos')
    useEffect(() => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
        geteffectiveisrrates();
    }, [])
    const geteffectiveisrrates = async () => {
        let objects = JSON.parse(localStorage.getItem("datacompanyall"));
        const FormData = require('form-data');
        let data = new FormData();
        data.append('id', objects.id);
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://exentus.net/api/income-evolution-anual',
            data: data
        };

        fastapi.request(config)
            .then((response) => {
                setData(response.data);
                setAnual(true);
            })
            .catch((error) => {
                //console.log(error);
            });
    }
    const setDatils = async (d) => {
        let objects = JSON.parse(localStorage.getItem("datacompanyall"));
        const FormData = require('form-data');
        let data = new FormData();
        data.append('id', objects.id);
        data.append('exersice', d.ejercicio);
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://exentus.net/api/income-evolution',
            data: data
        };

        fastapi.request(config)
            .then((response) => {
                setAnual(false);
                setMensual(true);
                setDataMonth(response.data);
                setTitle('Evolución de los ingresos de ' + d.ejercicio);
                setSubTitle('')
            })
            .catch((error) => {
                //console.log(error);
            });
    }
    return (
        <div className="card-group-control card-group-control-left" id="accordion-control">
            <div className="card">
                <div className="card-header">
                    <h6 className="card-title">
                        {title}
                    </h6>
                </div>
                <div className="card-body">

                    <div className="row">

                        <div className="col-lg-12">
                            <div className="card border-bottom-success rounded-bottom-0">
                                <div className="card-header header-elements-inline">
                                    <h6 className="card-title">{subtitle}</h6>
                                    {anual ? (
                                        <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                                            <i className="icon-home  text-primary"></i>
                                            <div className="text-primary"></div>
                                        </div>
                                    ) : (
                                        <div className="d-flex align-items-center" style={{ cursor: 'pointer' }} onClick={() => {
                                            setMensual(false)
                                            setAnual(true)
                                            setTitle('Evolución de los ingresos');
                                            setSubTitle('Clic sobre el punto para ver el detalle')
                                        }}>
                                            <i className="icon-arrow-left16  text-primary" style={{ fontSize: "1.7em" }}></i>&nbsp;
                                            <div className="text-primary">Inicio</div>
                                        </div>
                                    )}
                                </div>
                                <div className="card-body">
                                    {
                                        anual ? (
                                            <BarChart
                                                width={(width - 300)}
                                                height={(height - 200)}
                                                data={data}
                                                margin={{
                                                    top: 5,
                                                    right: 30,
                                                    left: 20,
                                                    bottom: 5,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="1 1" vertical={false} />
                                                <XAxis dataKey="ejercicio" height={60} fill="#fff" tick={<CustomizedAxisTick />} />
                                                <YAxis tick={<CustomizedAxisTickY />} />
                                                <Tooltip content={<CustomTooltip />} />
                                                <Legend />
                                                <Bar dataKey="ingresos_facturados" fill="#8884d8" name="Ingresos facturados" onClick={setDatils}>
                                                    <LabelList content={<CustomizedLabel />} stroke="#fff" />
                                                </Bar>
                                                <Bar dataKey="ingresos_declarados" fill="#82ca9d" name="Ingresos declarados" onClick={setDatils}>
                                                    <LabelList content={<CustomizedLabel />} stroke="#fff" />
                                                </Bar>
                                            </BarChart>
                                        ) : null
                                    }
                                    {
                                        mensual ? (
                                            <BarChart
                                                width={(width - 300)}
                                                height={(height - 200)}
                                                data={data_month}
                                                margin={{
                                                    top: 5,
                                                    right: 30,
                                                    left: 20,
                                                    bottom: 5,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="1 1" vertical={false} />
                                                <XAxis dataKey="name" height={60} fill="#fff" tick={<CustomizedAxisTick />} />
                                                <YAxis tick={<CustomizedAxisTickY />} />
                                                <Tooltip content={<CustomTooltip />} />
                                                <Legend />
                                                <Bar dataKey="invoice" fill="#8884d8" name="Ingresos facturados">
                                                    <LabelList content={<CustomizedLabel />} stroke="#fff" />
                                                </Bar>
                                                <Bar dataKey="provisional_payment" fill="#82ca9d" name="Ingresos declarados">
                                                    <LabelList content={<CustomizedLabel />} stroke="#fff" />
                                                </Bar>
                                            </BarChart>
                                        ) : null
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
