import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import api from '../../app/Config/apifiles';
navigator.browserDetection = (function () {
    var ua = navigator.userAgent, tem,
        M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE ' + (tem[1] || '');
    }
    if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M.join(' ');
})();
function systemOperatin() {
    var OSName = "Unknown OS";
    if (navigator.appVersion.indexOf("Win") != -1)
        OSName = "Windows";
    if (navigator.appVersion.indexOf("Mac") != -1)
        OSName = "MacOS";
    if (navigator.appVersion.indexOf("X11") != -1) OSName = "UNIX";
    if (navigator.appVersion.indexOf("Linux") != -1) OSName = "Linux";
    return OSName;

}
class RecoverPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ejercicio: (new Date()).getFullYear(),
            email: '', ///'rchavarria@grupoasertax.mx',
            isValid: false,
            errorisValid: false,
            errorTex: '',
            systemoperating: '',
            browser: ''
        }
        this.sendMailReset = this.sendMailReset.bind();
    }
    componentDidMount() {
        this.setState({
            systemoperating: systemOperatin(),
            browser: navigator.browserDetection
        });
    }
    sendMailReset = async (e) => {
        if (this.state.errorTex.trim() == '') {
            let asp_ = await api.post("recuve-password", {
                email: this.state.email, systemoperating: this.state.systemoperating,
                browser: this.state.browser
            });

            let _ = asp_.data;
            if (_.status === 200) {
                this.setState({ isValid: true });
                setTimeout(() => {
                    this.setState({ isValid: false });
                    this.props.history.push('/login');
                }, 6000);
            }
            else if (_.status === 400) {
                this.setState({ errorisValid: true, errorTex: 'Ocurrio un error, "No existe el correo o favor de escribirlo correctamente!".' });
                setTimeout(() => {
                    this.setState({ errorisValid: false });
                }, 6000);
            }
        }
        else {
            this.setState({ errorisValid: true, errorTex: 'Ocurrio un error, "No se puede recuperar la contraseña si no ingresa un correo correcto!".' });
            setTimeout(() => {
                this.setState({ errorisValid: false, errorTex: '' });
            }, 6000);
        }

        e.preventDefault();
    }
    changeData = async (h) => {
        if (h.target.value.length > 0) {
            if (typeof (h.target.value) !== undefined) {
                var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                if (!pattern.test(h.target.value)) {
                    this.setState({ isValid: false, errorTex: "Por favor ingrese una dirección de correo electrónico válida." })
                }
                else
                    this.setState({ email: h.target.value, errorTex: '' });
            }
        }
        else
            this.setState({ email: '', errorTex: '' });
    }
    render() {
        return (
            <>
                <div className="navbar navbar-expand-md navbar-light navbar-static" style={{ backgroundColor: "#262d3c" }}>
                    <div className="navbar-brand">
                        <Link to='/' className="d-inline-block">
                            <img src="logos/logo_expertumb.png" alt="" />
                        </Link>
                    </div>

                    <div className="d-md-none">
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-mobile">
                            <i className="icon-tree5"></i>
                        </button>
                    </div>
                    <div className="collapse navbar-collapse" id="navbar-mobile">

                    </div>
                </div>
                <div className="page-content">
                    <div className="content-wrapper">
                        <div className='row'>
                            <div className="col-lg-4"></div>
                            <div className="col-lg-4">
                                {
                                    this.state.isValid ? (
                                        <div className="alert alert-success alert-styled-left alert-arrow-left alert-dismissible">
                                            <span className="font-weight-semibold">Genail!</span> Se ha enviado un correo electrónico para recuperar la contraseña.
                                        </div>
                                    ) : null
                                }
                                {
                                    this.state.errorisValid ? (
                                        <div className="alert alert-danger alert-styled-left alert-dismissible">
                                            <span className="font-weight-semibold">Ups!</span>{this.state.errorTex}
                                        </div>

                                    ) : null
                                }
                            </div>
                        </div>

                        <div className="content d-flex justify-content-center align-items-center pt-0">
                            <form className="login-form" onSubmit={this.sendMailReset}>
                                <div className="card mb-0">
                                    <div className="card-body">
                                        <div className="text-center mb-3">
                                            <i className="icon-spinner11 icon-2x text-warning border-warning border-3 rounded-round p-3 mb-3 mt-1"></i>
                                            <h5 className="mb-0">Recuperación de contraseña</h5>
                                            <span className="d-block text-muted">Te enviaremos instrucciones por correo electrónico</span>
                                        </div>
                                        <div className="form-group form-group-feedback form-group-feedback-right">
                                            <input type="email" className="form-control" placeholder="Ingrese el correo" onChange={this.changeData} />
                                            <div className="form-control-feedback">
                                                <i className="icon-mail5 text-muted"></i>
                                            </div>
                                        </div>
                                        <div className="text-danger">{this.state.errorTex}</div>
                                        <button type="submit" className="btn bg-blue btn-block"><i className="icon-spinner11 mr-2"></i> Restablecer la contraseña</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="navbar navbar-expand-lg navbar-light">
                            <div className="text-center d-lg-none w-100">
                                <button type="button" className="navbar-toggler dropdown-toggle" data-toggle="collapse" data-target="#navbar-footer">
                                    <i className="icon-unfold mr-2"></i>
                                    Pie de página
                                </button>
                            </div>
                            <div className="navbar-collapse collapse" id="navbar-footer">
                                <span className="navbar-text">
                                    <span style={{ fontSize: '15px' }}> &copy;</span> {this.state.ejercicio}  Mercanetica estrategias S.A de C.V.
                                </span>
                                <ul className="navbar-nav ml-lg-auto">
                                    <li className="nav-item"><a href="https://e-xpertum.com.mx/webadmin/read-android" className="navbar-nav-link"><Tooltip arrow placement="top" title="Click en el icono para descargar el APP para Android"><span style={{ color: 'green' }}><i style={{ fontSize: '20px' }} className="icon-android"></i></span></Tooltip> </a></li>
                                    <li className="nav-item"><a href="https://e-xpertum.com.mx/webadmin/read-ios" className="navbar-nav-link"><Tooltip arrow placement="top" title="Click en el icono para descargar el APP para IOS i-Phn"><span className="text-info-400"><i style={{ fontSize: '20px' }} className="icon-apple2"></i></span></Tooltip></a></li>
                                    <li className="nav-item"><a href="https://e-xpertum.com.mx/webadmin/api/documents/api/help/Manual_E_xpertum_INICIO_V1.pdf" className="navbar-nav-link" target="_blank"><span className="text-warning-400"><i className="icon-question6"></i>  Manual</span></a></li>
                                    <li className="nav-item"><a href="https://e-xpertum.com.mx/adminfile/preguntas-frecuentes" className="navbar-nav-link" target="_blank"><span className="text-warning-400"><i className="icon-lifebuoy mr-2"></i> Preguntas frecuentes</span></a></li>
                                    <li className="nav-item"><a href="https://e-xpertum.com.mx/adminfile/terminosycondiciones" className="navbar-nav-link" target="_blank"><span className="text-warning-400"><i className="icon-file-text2 mr-2"></i> Términos y condiciones</span></a></li>
                                    <li className="nav-item"><a href="https://e-xpertum.com.mx/adminfile/avisoprivacidad" className="navbar-nav-link" target="_blank"><span className="text-warning-400"><i className="icon-clipboard3 mr-2"></i> Aviso de privacidad</span></a></li>
                                    <li className="nav-item"><a href="https://e-xpertum.com/" className="navbar-nav-link" target="_blank"><span className="text-warning-400"><i className="icon-cart2 mr-2"></i> Comprar</span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default RecoverPassword;