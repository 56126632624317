import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import api from '../../app/Config/apifiles';
import { Tooltip } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import ReactJson from 'react-json-view';
import Skeleton from '@material-ui/lab/Skeleton';
import { getDataRfc, getDataName } from './columns'
function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const [loading, setLogin] = useState(false);
    const [link, setLink] = useState('');
    const [cfdi, setCfdi] = useState('');
    const [uuid, setUiid] = useState('');
    const viewpdfcfdi_ = async (r) => {
        props.viewpdf(r);
        /**/
    }
    const handleLoadXML = async (t) => {
        props.viewJson(t);
        /**/
    }
    return (
        <React.Fragment>
            <tr>
                <td>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <i className="icon-minus3" /> : <i className="icon-plus3" />}
                    </IconButton>
                </td>
                <td item={row}>{String(row.fecha).slice(0, 10)}</td>
                <td>{(row.metodo_pago)}</td>
                <td>{getDataName(row.receptor)}</td>
                <td>{getDataRfc(row.receptor)}</td>
                <td>{(row.base_0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                <td>{(row.base_8).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                <td>{(row.base_16).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                <td>{(row.total_impuesto_trasladado).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                <td>{(row.sub_total).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                <td>{(row.descuento).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                <td>{(row.total).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                <td className='sticky-col first-col'>
                    <div className="text-center">
                        <button className='btn btn-default btn-icon' onClick={() => viewpdfcfdi_(row)}><Tooltip title="Click para ver cfdi completo" placement="top" arrow><span className="badge badge-flat  text-success"><i className="icon-file-pdf"></i></span></Tooltip></button>
                        &nbsp;
                        <button className='btn btn-default btn-icon' onClick={() => handleLoadXML(row)}><Tooltip title="Click para ver el xml completo" placement="top" arrow><span className="badge badge-flat  text-success"><i className="icon-file-xml"></i></span></Tooltip></button>
                    </div>
                </td>
            </tr>
            <tr>
                <td style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={13}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <span variant="h6" gutterBottom component="div">
                                Facturas de pago relacionadas
                            </span>
                            <table style={{ width: "100%" }}>
                                <thead>
                                    <tr>
                                        <th>Fecha cfdi</th>
                                        <th>Método de pago</th>
                                        <th>Nombre</th>
                                        <th>RFC</th>
                                        <th>0%</th>
                                        <th>8%</th>
                                        <th>16%</th>
                                        <th>Total IVA Trasladado</th>
                                        <th>Subtotal</th>
                                        <th>Descuento</th>
                                        <th>Neto</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {row.invoice.map((historyRow) => (
                                        <tr key={historyRow.uuid}>
                                            <td item={historyRow}>{String(historyRow.fecha).slice(0, 10)}</td>
                                            <td>Cfdi de pago</td>
                                            <td>{getDataName(historyRow.receptor)}</td>
                                            <td>{getDataRfc(historyRow.receptor)}</td>
                                            <td>{(historyRow.tasa_calculo_0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                            <td>{(historyRow.tasa_calculo_8).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                            <td>{(historyRow.tasa_calculo_16).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                            <td>{(historyRow.tasa_calculo_0 + historyRow.tasa_calculo_8 + historyRow.tasa_calculo_16).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                            <td>{((historyRow.tasa_0 + historyRow.tasa_8 + historyRow.tasa_16 + historyRow.tasa_exenta)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                            <td>{(historyRow.descuento).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                            <td>{((historyRow.tasa_0 + historyRow.tasa_8 + historyRow.tasa_16 + historyRow.tasa_exenta) + (historyRow.tasa_calculo_0 + historyRow.tasa_calculo_8 + historyRow.tasa_calculo_16)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                            <td className='sticky-col first-col'>
                                                <div className="text-center">
                                                    <button className='btn btn-default btn-icon' onClick={() => viewpdfcfdi_(historyRow)}><Tooltip title="Click para ver cfdi completo" placement="top" arrow><span className="badge badge-flat  text-success"><i className="icon-file-pdf"></i></span></Tooltip></button>
                                                    &nbsp;
                                                    <button className='btn btn-default btn-icon' onClick={() => handleLoadXML(historyRow)}><Tooltip title="Click para ver el xml completo" placement="top" arrow><span className="badge badge-flat  text-success"><i className="icon-file-xml"></i></span></Tooltip></button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Box>
                    </Collapse>
                </td>
            </tr>

        </React.Fragment>
    );
}
class AcreditableReportPaymentBills extends Component {
    constructor(props) {
        super(props);
        this.state = {
            node: props.data.data,
            uuid: '',
            loading: false,
            link: '', cfdi: ''
        }
        // console.log(props.data);
    }
    componentDidMount() {
        this.setState({
            anticipo_ingreso: this.props.data.sumoperation.anticipo_ingreso,
            descuento: this.props.data.sumoperation.descuento,
            exenta_importacion: this.props.data.sumoperation.exenta_importacion,
            iva_trasladado_0: (this.props.data.sumoperation.iva_trasladado_0).toFixed(2),
            iva_trasladado_8: (this.props.data.sumoperation.iva_trasladado_8).toFixed(2),
            iva_trasladado_16: (this.props.data.sumoperation.iva_trasladado_16).toFixed(2),
            sub_total: (this.props.data.sumoperation.sub_total).toFixed(2),
            total_impuesto_trasladado: (this.props.data.sumoperation.total_impuesto_trasladado).toFixed(2),
            venta_iva_no_objeto_impuesto: (this.props.data.sumoperation.venta_iva_no_objeto_impuesto).toFixed(2),
        })
    }
    handleViewPdf = (r) => {
        console.log(r);
        window.$('#viewpdfcfdi').modal('show');
        this.setState({ loading: true });
        let _link_ = 'https://e-xpertum.com.mx/adminfile/api/genera_pdf_cfdi/' + r.listado_cfdi_recibido_id + '/RECIBIDO&admin';
        this.setState({ link: _link_, uuid: r.uuid, cfdi: '' });
    }
    handleViewCfdi = async (t) => {
        console.log(t);
        this.setState({ loading: true });
        window.$("#viewxmlcfdi").modal("show");
        setTimeout(async () => {
            let _data_ = await api.post('genera_xml_cfdi', { id: t.listado_cfdi_recibido_id, tipo: 'RECIBIDO' });
            this.setState({ loading: false, link: '', uuid: t.uuid, cfdi: <ReactJson src={JSON.parse(_data_.data)} enableClipboard={false} theme={"monokai"} displayDataTypes={false} displayObjectSize={false} /> });
        }, 900);
    }
    setLink = () => {
        this.setState({
            link: '', cfdi: ''
        })
    }
    handleLoading = async () => {
        this.setState({ loading: false });
    }
    render() {
        return (
            <div className="view-report">
                <div className="wrapper-report">  <table className='table' style={{ width: "50%" }}>
                    <thead>
                        <tr>
                            <th className='text-center' colSpan={3}>Tasa</th>
                            <th className='text-center'></th>
                            <th className='text-center'></th>
                            <th className='text-center'></th>
                        </tr>
                        <tr>
                            <th className='text-center'>0%</th>
                            <th className='text-center'>8%</th>
                            <th className='text-center'>16%</th>
                            <th className='text-center'>Subtotal</th>
                            <th className='text-center'>Descuento</th>
                            <th className='text-center'>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='text-right'>{parseFloat(this.state.iva_trasladado_0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                            <td className='text-right'>{parseFloat(this.state.iva_trasladado_8).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                            <td className='text-right'>{parseFloat(this.state.iva_trasladado_16).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                            <td className='text-right'>{parseFloat(this.state.sub_total).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                            <td className='text-right'>{parseFloat(this.state.descuento).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                            <td className='text-right'>{parseFloat(this.state.sub_total - this.state.descuento).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                        </tr>
                    </tbody>
                </table>
                    <br />
                    <table className='table'>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Fecha</th>
                                <th>Método de pago</th>
                                <th>Nombre</th>
                                <th>RFC</th>
                                <th>0%</th>
                                <th>8%</th>
                                <th>16%</th>
                                <th>Total IVA Trasladado</th>
                                <th>Subtotal</th>
                                <th>Descuento</th>
                                <th>Neto</th>
                                <th className='sticky-col first-col'></th>
                            </tr>
                        </thead>
                        <tbody>
                            {(this.state.node || []).map((row) => (
                                <Row key={row.uuid} row={row} viewpdf={this.handleViewPdf} viewJson={this.handleViewCfdi} />
                            ))}
                        </tbody>
                    </table>
                    <div id="viewpdfcfdi" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                        <div className="modal-dialog modal-full">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">{this.state.uuid}</h5>
                                    <button type="button" className="close" data-dismiss="modal" onClick={() => this.setLink('')}>×</button>
                                </div>
                                <div className="modal-body">
                                    <div className="embed-container">
                                        {
                                            (this.state.loading) ?
                                                (
                                                    <div>
                                                        <Skeleton variant="text" />
                                                        <Skeleton variant="circle" animation="wave" width={40} height={40} />
                                                        <Skeleton variant="rect" animation="wave" width={510} height={118} />
                                                    </div>
                                                ) : null
                                        }
                                        <iframe src={this.state.link} onLoad={this.handleLoading}
                                            width="100%" title='ok'
                                            frameBorder="0" allowFullScreen
                                        >
                                        </iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="viewxmlcfdi" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                        <div className="modal-dialog modal-full">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">{this.state.uuid}</h5>
                                    <button type="button" className="close" data-dismiss="modal" onClick={() => this.setLink('')}>×</button>
                                </div>
                                <div className="modal-body">
                                    <div>
                                        {
                                            (this.state.loading) ?
                                                (
                                                    <div>
                                                        <Skeleton variant="text" />
                                                        <Skeleton variant="circle" animation="wave" width={40} height={40} />
                                                        <Skeleton variant="rect" animation="wave" width={510} height={118} />
                                                    </div>
                                                ) : null
                                        }
                                        {this.state.cfdi}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
Row.propTypes = {
    row: PropTypes.shape({
        sub_total: PropTypes.number,
        total: PropTypes.number,
        iva_trasladado_16: PropTypes.number,
        total_impuesto_trasladado: PropTypes.number,
        iva_trasladado_8: PropTypes.number,
        invoice: PropTypes.arrayOf(
            PropTypes.shape({
                fecha: PropTypes.string,
                uuid: PropTypes.string,
                metodo_pago: PropTypes.string,
            }),
        ).isRequired,
        receptor: PropTypes.string,
        fecha: PropTypes.string,
        uuid: PropTypes.string,
        metodo_pago: PropTypes.string,
        iva_trasladado_0: PropTypes.number,
    }).isRequired,
};
export default AcreditableReportPaymentBills;