import React from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Card, CardContent, TextField, Grid, Typography } from '@material-ui/core';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import api from '../../../app/Config/api';
import Avatar from '@material-ui/core/Avatar';
class ResponsiveDialog extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            acccion: 'edit',
            id: '',
            nombre: '',
            descripcion: '',
            version: '',
            marca: '',
            mercado: '',
            nombre_corto: '',
            imagen: '',
            alert: false,
            alerterror: false,
            vertical: 'top',
            horizontal: 'right',
            mesagge: ''
        }
    }
    componentDidMount() {
        var obj = { id: this.props.identificador }
        api.post('listProductid', obj)
            .then(response => response.data)
            .then(data => {
                console.log(data)
                this.setState(data);
            });
    }

    render() {

        return (
            <div>
                <Card>
                    <CardContent>
                        <form>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Typography color="textPrimary">Nombre: {this.state.nombre}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography color="textPrimary">Descripción: {this.state.descripcion}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography color="textPrimary">Proveedor: {this.state.marca}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography color="textPrimary">Aplicación: {this.state.mercado}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography color="textPrimary">Versión: {this.state.version}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Avatar alt={this.state.nombre} src={'http://localhost:3005/' + this.state.nombre_corto} style={{ width: "60", height: "60" }} />
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </div>
        );
    }
}

ResponsiveDialog.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
};

export default withMobileDialog()(ResponsiveDialog);

