//onChange={(event) => props.value({ name: event.target.name, value: event.target.value })}
import React, { Component } from 'react';
import api from '../../../app/Config/api'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, } from '@material-ui/core';
import MaskedInput from 'react-text-mask'
// patron del RFC, persona Moral
const _rfc_pattern_pm = "^(([A-ZÑ&]{3})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\\d])|[3][01])([A-Z0-9]{3}))|" +
    "(([A-ZÑ&]{3})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\\d])|[3][0])([A-Z0-9]{3}))|" +
    "(([A-ZÑ&]{3})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\\d])([A-Z0-9]{3}))|" +
    "(([A-ZÑ&]{3})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$";
// patron del RFC, persona fisica
const _rfc_pattern_pf = "^(([A-ZÑ&]{4})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\\d])|[3][01])([A-Z0-9]{3}))|" +
    "(([A-ZÑ&]{4})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\\d])|[3][0])([A-Z0-9]{3}))|" +
    "(([A-ZÑ&]{4})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\\d])([A-Z0-9]{3}))|" +
    "(([A-ZÑ&]{4})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$";


function ValidaRFC(rfc) {
    if (rfc.match(_rfc_pattern_pm) || rfc.match(_rfc_pattern_pf)) {
        return true;
    } else {
        alert("La estructura de la clave de RFC es incorrecta.");
        return false;
    }
}

class datosfiscales extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pais_id: '',
            estado_id: '',
            municipio_id: '',
            localidad_id: 0,
            PaisData: [],
            EstadoData: [],
            MuncipioData: [],
            LocalidadesData: [],
            razon_social: '',
            rfc: '',
            calle: '',
            colonia: '',
            interno: '',
            externo: '',
            codigo: '',
            telefono: '',
            proviene: 'Cliente',
            isAddDatosFiscales: true, isAddContacto: false,
            namefile: 'Seleccione Constancia de Situación'
        }
    }
    componentDidMount() {
        api.get('catpais').then(response => {
            this.setState({
                PaisData: response.data,
                razon_social: '',
                rfc: '',
                calle: '',
                colonia: '',
                interno: '',
                externo: '',
                codigo: '',
                telefono: '',
            });
        });
    }
    ChangeteState = (e, values) => {
        if (values != null) {
            this.setState({ pais_id: values.clave });
            api.get('catestados?pais=' + values.clave).then(response => {
                this.setState({
                    EstadoData: response.data,
                });
            });
        }
    }
    ChangeCity = (e, values) => {
        if (values !== null) {
            this.setState({ estado_id: values.claves });
            api.get('catmunicipios?estado=' + values.claves).then(response => {
                this.setState({
                    MuncipioData: response.data
                });
            });
        }
    }
    ChangeMunicipio = (e, values) => {
        if (values !== null) {
            this.setState({ municipio_id: values.clave });
            api.get('catlocalidades?municipio=' + values.clave).then(response => {
                this.setState({
                    LocalidadesData: response.data
                });
            });
        }
    }
    handleInputChange = (e, values) => {
        this.setState({ localidad_id: values.id });
        setTimeout(() => {
            this.props.data(this.state)
        }, 200);
    }
    handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });
        setTimeout(() => {
            this.props.data(this.state)
        }, 200);

    }
    uploadfileChange = async (t) => {
        let file = t.target.files[0];
        this.setState({ namefile: file.name });
        let forms_ = new FormData();
        forms_.append("file", file);
        let ok = await api.post('constanciacostumer', forms_);
        let data_ = await ok.data;
        if (data_.status === 200)
            this.setState({
                razon_social: data_.nombre,
                rfc: data_.rfc,
                calle: data_.nombrevialidad,
                colonia: data_.colonia,
                externo: data_.numexterior,
                codigo: data_.codigopostal,
            });
    }
    render() {
        return (
            <div className="card">
                <div className="card-body">
                    <div autoComplete="off">
                        <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col-md-8">
                                <div className="form-group">
                                    <label className="custom-file">
                                        <input type="file" className="custom-file-input" onChange={this.uploadfileChange} />
                                        <span className="custom-file-label">{this.state.namefile}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input type="text" value={this.state.razon_social} className="form-control" autoComplete="off" placeholder="Razon social / Nombre" name="razon_social" id="razon_social" onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input type="text" value={this.state.rfc} className="form-control" onBlur={(e) => ValidaRFC(e.target.value)} style={{ textTransform: 'uppercase' }} maxLength={13} autoComplete="off" placeholder="RFC" name="rfc" id="rfc" onChange={this.handleChange} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <input type="text" value={this.state.colonia} className="form-control" autoComplete="off" placeholder="Colonia" name="colonia" id="colonia" onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <input type="text" value={this.state.calle} className="form-control" autoComplete="off" placeholder="Calle" name="calle" id="calle" onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <MaskedInput className="form-control" value={this.state.codigo} placeholder="Código postal" autoComplete="off" name="codigo" id="codigo" onChange={this.handleChange}
                                        mask={[/[1-9]/, /\d/, /\d/, /\d/, /\d/]}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <input type="text" value={this.state.externo} className="form-control" autoComplete="off" style={{ textTransform: 'uppercase' }} placeholder="Número exterior" name="externo" id="externo" onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <input type="text" className="form-control" autoComplete="off" style={{ textTransform: 'uppercase' }} placeholder="Número interno" name="interno" id="interno" onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <Autocomplete
                                        id="pais"
                                        options={this.state.PaisData}
                                        autoHighlight
                                        getOptionLabel={(option) => option.nombre}
                                        renderOption={(option) => (
                                            <React.Fragment>
                                                <span>{option.clave} - {option.nombre}</span>
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <div ref={params.InputProps.ref}>
                                                <TextField  {...params} label="Selecciona el país" variant="outlined" autoComplete="off" inputProps={{
                                                    ...params.inputProps, autoComplete: 'new-password'
                                                }} margin="dense" fullWidth required variant="outlined" />
                                            </div>
                                        )}
                                        onChange={this.ChangeteState} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <Autocomplete
                                        id="estado"
                                        options={this.state.EstadoData}
                                        autoHighlight
                                        getOptionLabel={(option) => option.nombre}
                                        renderOption={(option) => (
                                            <React.Fragment>
                                                <span>{option.claves} - {option.nombre}</span>
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Selecciona el estado" variant="outlined" inputProps={{ ...params.inputProps, autoComplete: 'new-password', }} margin="dense" fullWidth required variant="outlined" />

                                        )}
                                        onChange={this.ChangeCity} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <Autocomplete
                                        id="municipio"
                                        options={this.state.MuncipioData}
                                        autoHighlight
                                        getOptionLabel={(option) => option.nombre}
                                        renderOption={(option) => (
                                            <React.Fragment>
                                                <span>{option.clave} - {option.nombre}</span>
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Selecciona el Municipio" variant="outlined" inputProps={{ ...params.inputProps, autoComplete: 'new-password', }} margin="dense" fullWidth required variant="outlined" />
                                        )}
                                        onChange={this.ChangeMunicipio} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <Autocomplete
                                        id="localidad"
                                        options={this.state.LocalidadesData}
                                        autoHighlight
                                        getOptionLabel={(option) => option.nombre}
                                        renderOption={(option) => (
                                            <React.Fragment>
                                                <span> {option.nombre}</span>
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Selecciona el localidad" variant="outlined" inputProps={{ ...params.inputProps, autoComplete: 'new-password', }} margin="dense" fullWidth required variant="outlined" />
                                        )}
                                        onChange={this.handleInputChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default datosfiscales;