import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
function Sidebar({ location }) {
  var objec = JSON.parse(localStorage.getItem('id_token'));
  const [objects, setInformacion] = useState(objec)

  useEffect(function () { });
  const { perfil, id, img } = objec;

  return (
    <>
      <div className="sidebar sidebar-light sidebar-main sidebar-expand-md">
        <div className="sidebar-mobile-toggler text-center">
          <Link to="#" className="sidebar-mobile-main-toggle"><i className="icon-arrow-left8"></i></Link>
          Navigation
          <Link to="#" className="sidebar-mobile-expand"> <i className="icon-screen-full"></i><i className="icon-screen-normal"></i></Link>
        </div>
        <div className="sidebar-content">
          <div className="sidebar-user">
            <div className="card-body">
              <div className="media">
                <div className="mr-3">
                  <Link to="#"><img src={(img === null ? "global_assets_dark/images/face11.png" : img)} width="38" height="38" className="rounded-circle" alt="" /></Link>
                </div>
                <div className="media-body">
                  <div className="media-title font-weight-semibold">{objects.nombre}</div>
                  <div className="font-size-xs opacity-50">
                    <i className="icon-pin font-size-sm"></i> &nbsp;México
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-sidebar-mobile">
            <ul className="nav nav-sidebar" data-nav-type="accordion">
              {
                (perfil === "CLIENTE" || perfil === "GOBIERNO") ?
                  (
                    <li className="nav-item">
                      <Link to="/app/ui/users" className="nav-link"><i className="icon-users"></i> <span>Usuarios</span> </Link>
                    </li>
                  ) : (perfil === "VENTAS" ? (
                    <>
                      <li className="nav-item">
                        <Link to="/app/user/admin" className="nav-link"><i className="icon-users"></i> <span>Usuarios</span> </Link>
                      </li>
                      <li className="nav-item" style={{ display: 'none' }}>
                        <Link to="/app/filter/monitor" className="nav-link"><i className="icon-mailbox"></i> <span>Correos</span> </Link>
                      </li>

                    </>
                  ) : (perfil === "ADMIN" ? (
                    <li className="nav-item">
                      <Link to="/app/mail/mailsinbox" className="nav-link"><i className="icon-mailbox"></i> <span>Correos</span> </Link>
                    </li>
                  ) : null)
                  )
              }

              <li className="nav-item-header"><div className="text-uppercase font-size-xs line-height-xs"></div> <i className="icon-menu" title="Listado"></i></li>
              {
                (perfil === "GOBIERNO") ? (<li className="nav-item nav-item-submenu">
                  <Link to="#" className="nav-link"><i className="icon-point-right"></i> <span>Servicios</span></Link>
                  <ul className="nav nav-group-sub" data-submenu-title="Servicios">
                    <li className="nav-item"><Link to="/app/ui/69bgob" className="nav-link">Validación 69B</Link></li>
                  </ul>
                </li>) : (
                  (perfil === "CLIENTEP" ? (
                    <li className="nav-item nav-item-submenu">
                      <Link to="#" className="nav-link"><i className="icon-point-right"></i> <span>Servicios</span></Link>
                      <ul className="nav nav-group-sub" data-submenu-title="Servicios">
                        <li className="nav-item"><Link to="/app/ui/69b" className="nav-link">Validación 69B</Link></li>
                        <li className="nav-item"><Link to="/app/client/sistem" className="nav-link">Auditoría de la contabilidad</Link></li>
                        <li className="nav-item"><Link to="/app/ui/nomina" className="nav-link">Maquila de Nómina</Link></li>
                        <li className="nav-item"><Link to="/app/ui/constitucion" className="nav-link">Jurídico</Link></li>
                        <li className="nav-item"><Link to="/app/ui/admin" className="nav-link">Administrador de archivos</Link></li>
                      </ul>
                    </li>
                  ) : null)

                )
              }

              {
                (perfil === "ADMIN") ? (
                  <li className="nav-item nav-item-submenu">
                    <Link to="#" className="nav-link"><i className="icon-spell-check"></i> <span>Catálogos</span></Link>
                    <ul className="nav nav-group-sub" data-submenu-title="Catálogos">
                      <li className="nav-item"><Link to="/app/ui/clientsadmin" className="nav-link">Clientes admins</Link></li>
                      <li className="nav-item"><Link to="/app/ui/sistemas" className="nav-link">Sistemas</Link></li>
                      <li className="nav-item"><Link to="/app/ui/services" className="nav-link">Servicios</Link></li>
                      <li className="nav-item">
                        <Link to="/app/user/admin" className="nav-link"> <span>Usuarios</span> </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/app/filter/Sales" className="nav-link"> <span>Distribuidores</span> </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/app/config/serviceconfiguration" className="nav-link"> <span>Configuración cotización</span> </Link>
                      </li>
                    </ul>
                  </li>

                ) : null
              }

            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default withRouter(Sidebar);
