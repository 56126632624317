import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
//import { makeStyles } from '@material-ui/styles';
import { Card, CardHeader, CardContent, CardActions, Divider, Grid, Button, TextField } from '@material-ui/core';
import api from '../../app/Config/api';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

const AccountDetails = props => {
    const { className, ...rest } = props;
    const classes = useStyles();
    const user = JSON.parse(localStorage.getItem("id_token"));
    const [values, setChangeData] = useState(user);
    const [state, setState] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'right',
    })
    const [message, setMessage] = React.useState('');


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setState({ ...state, open: false });
    };
    const handleChange = (e) => setChangeData({
        ...values,
        [e.currentTarget.name]: e.currentTarget.value
    })

    const handleSendSaveUpdate = () => {
        api.post('UpdateContactoSystem', values).then((response) => {
            if (response.data.status === 200) {
                localStorage.removeItem("id_token")
                localStorage.setItem('id_token', JSON.stringify(response.data.result));
                setState({ ...state, open: true });
                setMessage(response.data.message);
                window.location.reload();
            }
            else {

            }
        })
    }
    const { vertical, horizontal, open } = state;
    return (
        <>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical, horizontal }}>
                <Alert onClose={handleClose} severity="success">
                    {message}
                </Alert>
            </Snackbar>
            <div className="card">
                <div className="card-header header-elements-inline">
                    <h5 className="card-title">Perfil</h5>
                    <span>La información puede ser modificada.</span>
                </div>

                <div className="card-body">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group form-group-float">
                                <label className="form-group-float-label animate">Text input</label>
                                <input type="text" className="form-control" placeholder="Text input" name="nombre" onChange={handleChange} required value={values.nombre} />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group form-group-float">
                                <label className="form-group-float-label animate">Text input</label>
                                <input type="text" className="form-control" placeholder="Text input" name="apellido_paterno" onChange={handleChange} required value={values.apellido_paterno} />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group form-group-float">
                                <label className="form-group-float-label animate">Text input</label>
                                <input type="text" className="form-control" placeholder="Text input" name="apellido_materno" onChange={handleChange} required value={values.apellido_materno} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group form-group-float">
                                <label className="form-group-float-label animate">Text input</label>
                                <input type="text" className="form-control" placeholder="Text input" name="email" onChange={handleChange} required value={values.email} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group form-group-float">
                                <label className="form-group-float-label animate">Text input</label>
                                <input type="text" className="form-control" placeholder="Text input" name="telefono" onChange={handleChange} type="text" value={values.telefono} />
                            </div>
                        </div>
                    </div>
                    <div className="text-right">
                        <button type="submit" className="btn btn-primary" onClick={handleSendSaveUpdate}>Guardar cambios<i className="icon-paperplane ml-2"></i></button>
                    </div>
                </div>
            </div>
        </>
    );
};

AccountDetails.propTypes = {
    className: PropTypes.string
};

export default AccountDetails;