import React, { Component } from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import api from '../../../Config/api';
function arrayHour() {
    let _hour_ = [];
    for (let hour = 0; hour <= 23; hour++) {
        let minutos = 0
        while (minutos <= 45) {
            _hour_.push({ descripcion: (hour + ':' + (minutos === 0 ? '00' : minutos)), value: (hour + ':' + (minutos === 0 ? '00' : minutos)).toString() });
            minutos = minutos + 15;
        }
    }
    return _hour_;
}
class Presentacion extends Component {
    constructor(props) {
        super(props)
        let objectinfo = JSON.parse(localStorage.getItem("_data_contact_"));
        let user = JSON.parse(localStorage.getItem("id_token"));

        this.state = {
            nombre_: user.nombre + " " + user.apellido_paterno + " " + user.apellido_materno + " (" + user.email + ")",
            plataforma: '',
            fecha: '',
            hora: '',
            prospecto_id: objectinfo.pros_id,
            hours: [],
            descripcion: ''
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
        this.setState({
            hours: arrayHour()
        })
    }
    handleChangeSystem = (i) => {
        this.setState({ [i.target.name]: i.target.value });
    }
    handleSetHours = (e, values) => {
        this.setState({ hora: values.value })
    }
    handleSubmit(event) {
        let form = new FormData();
        form.append("descripcion", this.state.descripcion)
        form.append("plataforma", this.state.plataforma)
        form.append("hora", this.state.hora)
        form.append("fecha", this.state.fecha)
        form.append("prospecto_id", this.state.prospecto_id)
        api.post("newpresentacion", form).then((i) => {
            this.setState({ descripcion: '', plataforma: '', hora: '', fecha: '' })
            this.props.close(i.data);
        });
        event.preventDefault();
    }
    render() {
        return (
            <form onSubmit={this.handleSubmit} autoComplete="off">
                <div className="modal-body">
                    <div className="card">
                        <div className="card-body">

                            <div className="form-group row">
                                <label className="col-lg-3 col-form-label text-right">Fecha:</label>
                                <div className="col-lg-9">
                                    <input type="date" required className="form-control" value={this.state.fecha} onChange={this.handleChangeSystem} name="fecha" placeholder="Origen" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-lg-3 col-form-label text-right">Hora:</label>
                                <div className="col-lg-9">
                                    <Autocomplete
                                        id="hours"
                                        autoComplete="off"
                                        options={this.state.hours}
                                        autoHighlight
                                        clearText="Clear"
                                        getOptionLabel={(option) => option.descripcion}
                                        renderOption={(option) => (
                                            <div>
                                                <span> {option.descripcion}</span>
                                            </div>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Seleccina la hora" value={this.state.hora} variant="outlined" autoComplete="off" inputProps={{ ...params.inputProps, autoComplete: 'new-password', }} margin="dense" fullWidth required variant="outlined" />

                                        )}
                                        onChange={this.handleSetHours} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-lg-3 col-form-label text-right">Plataforma:</label>
                                <div className="col-lg-9">

                                    <select className="form-control" required value={this.state.plataforma} onChange={this.handleChangeSystem} name="plataforma">
                                        <option value="">Seleccione la plataforma</option>
                                        <option value="TeamViwer">TeamViwer</option>
                                        <option value="Anydesk">Anydesk</option>
                                        <option value="Zoom">Zoom</option>
                                        <option value="Meet">Meet</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-lg-2 col-form-label text-right"></label>
                                <div className="col-lg-10">
                                    <textarea className="form-control" required rows={3} value={this.state.descripcion} onChange={this.handleChangeSystem} name="descripcion" placeholder="Descripción de la presentación"></textarea>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="modal-footer">
                    <button type="button" className="btn btn-link" data-dismiss="modal">Cancelar</button>
                    <button type="submit" className="btn bg-primary">Guardar presentación</button>
                </div>
            </form>
        );
    }
}

export default Presentacion;