import React, { Component } from 'react';
import api from '../../app/Config/apifiles';
import sendmail from '../../app/Config/audit-data';
import ReactDatatable from '@ashvin27/react-datatable';
import Skeleton from '@material-ui/lab/Skeleton';
import { Tooltip, Switch } from "@material-ui/core";
class ConfigVendors extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "nombre",
                text: "Nombre, Denominación o razón social",
                className: "nombre",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <div className="text-letf" key={['name' + record.nombre]}>
                            {(record.nombre !== "") ?
                                record.nombre.substr(0, 60) : ""}
                        </div>
                    );
                }
            },
            {
                key: "rfc",
                text: "RFC",
                className: "rfc",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <div className="text-letf" key={['rfc-' + record.rfc]}>
                            {(record.rfc !== "") ? record.rfc : ""}
                        </div>
                    );
                }
            }, {
                key: "correo",
                text: "Correo",
                className: "correo",
                sortable: true,
                cell: record => {
                    return (
                        <div className="text-right" key={['div-' + record.rfc]}>
                            {
                                (record.estatus == null || record.estatus == "VIGENTE") ?
                                    (
                                        <input key={['input-' + record.rfc]} value={(record.correo == undefined || record.correo == null) ? '' : record.correo} className="form-control" onChange={(event, checked) => this.handleSetMails(record.rfc, event, checked)} onBlur={(event, checked) => this.handleOnblurInput(record.rfc, event, checked)} />
                                    ) :
                                    (
                                        <input key={['input-' + record.rfc]} value={(record.correo == undefined || record.correo == null) ? '' : record.correo} disabled className="form-control" onChange={(event, checked) => this.handleSetMails(record.rfc, event, checked)} onBlur={(event, checked) => this.handleOnblurInput(record.rfc, event, checked)} />
                                    )
                            }

                        </div>
                    );
                }
            }, /*{
                key: "estatus",
                text: "Estatus",
                className: "estatus",
                sortable: true,
                cell: i => {
                    return (
                        <div key={['estatus-' + i.rfc]} className="text-center" style={{ textAlign: 'center' }}>
                            {
                                (i.estatus == null || i.estatus == "VIGENTE") ? (
                                    <Switch key={['switch-' + i.rfc]}
                                        onChange={(event, checked) => this.handleChange(i.rfc, event, checked)}
                                        defaultChecked
                                        color="primary"
                                    />
                                ) :
                                    (
                                        <Switch key={['switch-' + i.rfc]}
                                            onChange={(event, checked) => this.handleChange(i.rfc, event, checked)}
                                            color="primary"
                                        />
                                    )
                            }

                        </div>
                    );
                }
            },*/
        ];
        this.config = {
            filename: "report_cat_proveedores",
            page_size: 50,
            length_menu: [70, 90, 100],
            show_filter: true,
            show_pagination: true,
            button: {
                excel: false,
                print: false,
                extra: true,
            },
            language: {
                length_menu: "Mostrar _MENU_ registros por página",
                filter: "Buscar en registros ...",
                info: "Mostrando _START_ a _END_ de _TOTAL_ entradas",
                pagination: {
                    first: "Primero",
                    previous: "Previa",
                    next: "próximo",
                    last: "Última"
                }
            },
            key_column: "rfc"
        }
        this.extraButtons = [
            {
                className: "btn btn-primary buttons-pdf",
                title: "Layout",
                children: [
                    <span>
                        <Tooltip title="Descargar Layout de proveedores" arrow placement="top">
                            <i className="icon-file-download" aria-hidden="true"></i>
                        </Tooltip>
                    </span>
                ],
                onClick: (event) => {
                    console.log(event);
                    window.open("https://e-xpertum.com.mx/adminfile/api/get-list-vendors-all-config/" + this.state.empresa_id + "/zpsoud29d3ssd33kj82f3ijd/23iu983287isdj9832ij30s038", "_blank")
                },
            },
            {
                className: "btn btn-primary buttons-pdf",
                title: "Import layout",
                children: [
                    <span>
                        <Tooltip title="Importar Layout" arrow placement="top">
                            <i className="icon-file-upload" aria-hidden="true"></i>
                        </Tooltip>
                    </span>
                ],
                onClick: (event) => {
                    console.log(event);
                    window.$("#modal_iconified").modal("show");
                }
            },
        ]
        let data_ = JSON.parse(localStorage.getItem("datavalidate"));
        this.state = {
            empresa_id: data_.id,
            link: 'https://e-xpertum.com.mx/#/buro-register/' + data_.cliente_id + "/compliance",
            rfc: data_.rfc,
            data: [],
            nombre: '',
            skeleton: true,
            title: 'Seleccione su archivo',
            howsendmail: '',
            viewbutton: false,
            messagesend: '',
            loading: false
        }
        this.handleSendMail = this.handleSendMail.bind(this);
    }
    componentDidMount() {
        this.getlistVendors();
    }
    getlistVendors = async () => {
        let _data_ = await api.post('/get-list-vendors-all-config', { empresa_id: this.state.empresa_id });
        let j = await _data_.data;
        this.setState({
            data: j[0], howsendmail: (j[1].length > 0 ? j[1][0].proveedor : ''),
            viewbutton: (j[1].length > 0 ? true : false),
            skeleton: false
        });
    }

    handleChange = async (index, event, checked) => {
        if (!checked) {
            let xxxx = await api.post('status-list-vendors-config', { empresa_id: this.state.empresa_id, rfc: index, estatus: 'CANCELADO' });
        }
        else {
            let xxxx = await api.post('status-list-vendors-config', { empresa_id: this.state.empresa_id, rfc: index, estatus: 'VIGENTE' });
        }
        this.getlistVendors();
    }
    handleOnblurInput = async (d, event, t) => {
        if (event.target.value.length > 0) {
            let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!regEmail.test(event.target.value)) {
                this.setState({ messageError: 'El correo es invalido' });
            }
            else {
                let xxxx = await api.post('mail-list-vendors-config', { empresa_id: this.state.empresa_id, rfc: d, correo: event.target.value })
            }
        } else { let xxxx = await api.post('mail-list-vendors-config', { empresa_id: this.state.empresa_id, rfc: d, correo: event.target.value }) }
    }
    handleSetMails = async (d, event, t) => {
        let apk = this.state.data;
        for (let zp = 0; zp < apk.length; zp++) {
            if (apk[zp].rfc == d)
                apk[zp].correo = event.target.value;
        }
        this.setState({ data: apk });
    }
    onFileChange = async (event) => {
        let files = event.target.files[0];
        this.setState({ title: files.name, skeleton: true })
        setTimeout(async () => {
            var data = new FormData();
            data.append('file', files);
            data.append('empresa_id', this.state.empresa_id);
            data.append('rfc', this.state.rfc);
            var config = {
                method: 'post',
                url: 'upload-layout-vendors',
                data: data
            };
            api(config)
                .then(function (response) {
                    console.log(response.data);
                    window.$("#modal_iconified").modal("hide");
                })
                .catch(function (error) {
                    console.log(error);
                });
            setTimeout(() => {
                this.getlistVendors();
            }, 1000);

        }, 2300);
    };
    handleSetVendors = async (event) => {
        //console.log()
        let _ok_ = event.target.value;
        this.setState({
            howsendmail: _ok_,
            viewbutton: (_ok_ === "EXPERTUM" ? true : false)
        });
        let _ft = await api.post('how-to-send-mail-vendors', { empresa_id: this.state.empresa_id, howsendmail: _ok_ });
        //console.log(_ok_);
        //console.log(_ft)
    }
    handleSendMail = async (event) => {
        event.preventDefault();
        this.setState({
            skeleton: true
        });
        setTimeout(async () => {
            let oksx = await sendmail.post('send-mail-to-vendors-all-config', { empresa_id: this.state.empresa_id });
            const { status } = await oksx.data;
            if (status === 200) {
                this.setState({
                    messagesend: 'Se han enviado correctamente los correos!'
                });
                setTimeout(() => {
                    this.setState({
                        messagesend: '',
                        skeleton: false
                    });
                }, 4000);
            }
        }, 400);

    }
    render() {
        return (
            <div className="content pt-0">
                <div className='card'>
                    <div className="card-header bg-transparent border-bottom header-elements-sm-inline">
                        <h6 className="card-title text-muted d-block">Descarga he importa a los proveedores con las cuentas de correo</h6>
                        <div className="header-elements">
                            <ClipboardCopy copyText={this.state.link}></ClipboardCopy>
                            <span className='text-muted d-block' style={{ marginRight: 10 }}>Enviar los correos a los proveedores: </span>
                            <form>
                                <label className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" className="custom-control-input" name="group-radio" value={"CLIENT"} checked={(this.state.howsendmail === "CLIENT" ? true : false)} onChange={this.handleSetVendors} />
                                    <span className="custom-control-label">Cliente</span>
                                </label>
                                <label className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" className="custom-control-input" name="group-radio" value={"EXPERTUM"} checked={this.state.howsendmail === "EXPERTUM" ? true : false} onChange={this.handleSetVendors} />
                                    <span className="custom-control-label">E-xpertum</span>
                                </label>
                                {
                                    this.state.viewbutton ? (
                                        <button type="button" onClick={this.handleSendMail} className="btn btn-light"><i className="icon-make-group mr-2"></i> Enviar</button>
                                    ) : null
                                }
                            </form>
                        </div>
                    </div>

                    <div className='card-body'>
                        <div className="row">
                            <div className="col-lg-12">
                                {
                                    (this.state.messagesend != '') ? (
                                        <div className="alert alert-success alert-styled-left alert-arrow-left alert-dismissible">
                                            <span className="font-weight-semibold">Excelente!</span> {this.state.messagesend}.
                                        </div>
                                    ) : null
                                }


                                {
                                    this.state.skeleton ? (
                                        <div className="card">
                                            <Skeleton animation="wave" variant="text" height={40} />
                                            <Skeleton animation="wave" variant="circle" width={80} height={80} />
                                            <Skeleton animation="wave" variant="text" height={40} />
                                            <div className="row">
                                                <div className="col-lg-4"><Skeleton variant="rect" height={150} /></div>
                                                <div className="col-lg-4"><Skeleton variant="rect" height={150} /></div>
                                                <div className="col-lg-4"><Skeleton variant="rect" height={150} /></div>
                                            </div>
                                            <div style={{ height: 20 }}></div>
                                            <div className="row">
                                                <div className="col-lg-4"><Skeleton variant="rect" height={150} /></div>
                                                <div className="col-lg-4"><Skeleton variant="rect" height={150} /></div>
                                            </div>
                                        </div>
                                    ) : (
                                        <ReactDatatable
                                            config={this.config}
                                            records={this.state.data}
                                            columns={this.columns}
                                            extraButtons={this.extraButtons}
                                        />
                                    )
                                }

                                <div id="modal_iconified" className="modal fade" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title"><i className="icon-menu7 mr-2" /> &nbsp;Importar layout</h5>
                                                <button type="button" className="close" data-dismiss="modal">×</button>
                                            </div>
                                            <div className="modal-body">
                                                <h6 className="font-weight-semibold"><i className="icon-law mr-2" />Importe el layout con los datos de los proveedores</h6>
                                                <fieldset className="mb-3">
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-1"></label>
                                                        <div className="col-lg-10">
                                                            <div className="custom-file">
                                                                <input type="file" className="custom-file-input" id="customFile" accept=".xlsx" onChange={this.onFileChange} />
                                                                <label className="custom-file-label" htmlFor="customFile">{this.state.title}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ConfigVendors;

function ClipboardCopy({ copyText }) {
    const [isCopied, setIsCopied] = React.useState(false);
    async function copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }
    const handleCopyClick = () => {
        copyTextToClipboard(copyText)
            .then(() => {
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 1500);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <div>
            <button type="button" onClick={handleCopyClick} class="btn btn-link">{isCopied ? 'Copied!' : 'Copiar link personalizado'}</button>
        </div>
    );
}